import React from "react";

export default function Edit(props) {
  return (
    <svg
      className="cursor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z"
        fill={props.color || "#26CD61"}
      />
      <path
        d="M8.49999 17.69C7.88999 17.69 7.32999 17.47 6.91999 17.07C6.42999 16.58 6.21999 15.87 6.32999 15.12L6.75999 12.11C6.83999 11.53 7.21999 10.78 7.62999 10.37L15.51 2.49C17.5 0.499998 19.52 0.499998 21.51 2.49C22.6 3.58 23.09 4.69 22.99 5.8C22.9 6.7 22.42 7.58 21.51 8.48L13.63 16.36C13.22 16.77 12.47 17.15 11.89 17.23L8.87999 17.66C8.74999 17.69 8.61999 17.69 8.49999 17.69ZM16.57 3.55L8.68999 11.43C8.49999 11.62 8.27999 12.06 8.23999 12.32L7.80999 15.33C7.76999 15.62 7.82999 15.86 7.97999 16.01C8.12999 16.16 8.36999 16.22 8.65999 16.18L11.67 15.75C11.93 15.71 12.38 15.49 12.56 15.3L20.44 7.42C21.09 6.77 21.43 6.19 21.48 5.65C21.54 5 21.2 4.31 20.44 3.54C18.84 1.94 17.74 2.39 16.57 3.55Z"
        fill={props.color || "#26CD61"}
      />
      <path
        d="M19.85 9.83003C19.78 9.83003 19.71 9.82003 19.65 9.80003C17.02 9.06003 14.93 6.97003 14.19 4.34003C14.08 3.94003 14.31 3.53003 14.71 3.41003C15.11 3.30003 15.52 3.53003 15.63 3.93003C16.23 6.06003 17.92 7.75003 20.05 8.35003C20.45 8.46003 20.68 8.88003 20.57 9.28003C20.48 9.62003 20.18 9.83003 19.85 9.83003Z"
        fill={props.color || "#26CD61"}
      />
    </svg>
  );
}
