import { Skeleton } from "@mui/material";
import React from "react";
import Table from "./Table";
import classes from "./Table.module.scss";

export default function LoadingTable() {
  return (
    <>
      <Table
        options={[
          {
            title: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            icon: false,
          },
          {
            title: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            icon: false,
          },
          {
            title: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            icon: false,
          },
          {
            title: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            icon: false,
          },
          {
            title: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            icon: false,
          },
          {
            title: <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            icon: false,
          },
        ]}
        data={[
          [
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
          ],
          [
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
          ],
          [
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
          ],
          [
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
          ],
          [
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
          ],
        ]}
        customStyleIndex={[]}
        customStyleFn={[false, false, false, false, false, false]}
      />
      <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
        <div className="w-auto d-inline ms-auto">
          <Skeleton variant="text" sx={{ width: "200px", fontSize: "1rem" }} />
        </div>
      </div>
    </>
  );
}
