import React from 'react';

export default function PeopleActiveL() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9999 10.047C23.9199 10.0337 23.8266 10.0337 23.7466 10.047C21.9066 9.98033 20.4399 8.47366 20.4399 6.60699C20.4399 4.70033 21.9733 3.16699 23.8799 3.16699C25.7866 3.16699 27.3199 4.71366 27.3199 6.60699C27.3066 8.47366 25.8399 9.98033 23.9999 10.047Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6264 19.7539C24.4531 20.0605 26.4664 19.7405 27.8798 18.7939C29.7598 17.5405 29.7598 15.4872 27.8798 14.2339C26.4531 13.2872 24.4131 12.9672 22.5864 13.2872"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.96014 10.047C8.04014 10.0337 8.13347 10.0337 8.21347 10.047C10.0535 9.98033 11.5201 8.47366 11.5201 6.60699C11.5201 4.70033 9.98681 3.16699 8.08014 3.16699C6.17347 3.16699 4.64014 4.71366 4.64014 6.60699C4.65347 8.47366 6.12014 9.98033 7.96014 10.047Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33325 19.7539C7.50659 20.0605 5.49326 19.7405 4.07992 18.7939C2.19992 17.5405 2.19992 15.4872 4.07992 14.2339C5.50659 13.2872 7.54659 12.9672 9.37325 13.2872"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9999 20.007C15.9199 19.9936 15.8266 19.9936 15.7466 20.007C13.9066 19.9403 12.4399 18.4336 12.4399 16.567C12.4399 14.6603 13.9733 13.127 15.8799 13.127C17.7866 13.127 19.3199 14.6736 19.3199 16.567C19.3066 18.4336 17.8399 19.9536 15.9999 20.007Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.12 24.2065C10.24 25.4598 10.24 27.5131 12.12 28.7665C14.2533 30.1931 17.7466 30.1931 19.88 28.7665C21.76 27.5131 21.76 25.4598 19.88 24.2065C17.76 22.7932 14.2533 22.7932 12.12 24.2065Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
