import React from 'react';

export default function ProductActiveL() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6668 29.3333H21.3334C26.6934 29.3333 27.6534 27.1867 27.9334 24.5733L28.9334 13.9067C29.2934 10.6533 28.3601 8 22.6668 8H9.33343C3.64009 8 2.70676 10.6533 3.06676 13.9067L4.06676 24.5733C4.34676 27.1867 5.30676 29.3333 10.6668 29.3333Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6665 8.00002V6.93335C10.6665 4.57335 10.6665 2.66669 14.9332 2.66669H17.0665C21.3332 2.66669 21.3332 4.57335 21.3332 6.93335V8.00002"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6668 17.3333V18.6667C18.6668 18.68 18.6668 18.68 18.6668 18.6933C18.6668 20.1467 18.6535 21.3333 16.0002 21.3333C13.3602 21.3333 13.3335 20.16 13.3335 18.7067V17.3333C13.3335 16 13.3335 16 14.6668 16H17.3335C18.6668 16 18.6668 16 18.6668 17.3333Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8665 14.6667C25.7865 16.9067 22.2665 18.24 18.6665 18.6934"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.49219 15.0261C6.49219 17.0794 9.87885 18.3194 13.3322 18.7061"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
