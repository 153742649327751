import { reportService } from '../../services/reportService';
import { serverErrorCatcher } from '../ServerErrorCatcher';

export const reportDownloadHelper = {
  downloadCSV,
};

function downloadFile(file) {
  const element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', file);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
async function downloadCSV(id, downloadOption, type, setServerError, setServerErrorMsg) {
  let response = await reportService.downloadService(
    id,
    (downloadOption === 'Download Financial CSV' && 'financial') ||
      (downloadOption === 'Download Reports CSV' && 'compliants') ||
      (downloadOption === 'Download Orders CSV' && 'orders'),
    type,
  );
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
  // console.log(response);
  if (response.data && response.data.messages) {
    setServerErrorMsg(response.data.messages);
    setServerError(true);
    Valid = false;
  }
  Valid === true && downloadFile(response.data.excel);
}
