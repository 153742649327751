import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const authService = {
  eshtreanaSignin,
};

async function eshtreanaSignin(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/eshtarena-auth/sign-in`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
