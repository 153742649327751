import { useDispatch } from 'react-redux';
import classes from './Voucher.module.scss';
import { useParams } from 'react-router-dom';
import { Avatar, Skeleton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { errorActions } from '../../store/reducers/errorSlice';
import Table from '../../components/Reusable/Common/Table/Table';
import { CircularProgressbar } from 'react-circular-progressbar';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { vouchersSupplierService } from '../../services/VoucherSuppliers';
import { vouchersEshtreanaService } from '../../services/VouchersEshtreana';
import VoucherActieveL from '../../components/Reusable/SVG/VoucherActieveL';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import TermsSections from '../../components/Reusable/Common/TermsSections/TermsSections';
import UploadImageGroup2 from '../../components/Reusable/UI/InputGroup/UploadImageGroup2';
import ShowAreaDistrict from '../../components/Reusable/Common/AddMemberCard/ShowAreaDistrict';
export default function SupplierVoucherDetails(props) {
  //--------------------Declaration----------------------
  let timeinterval;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [doc, setDoc] = useState([]);
  const [page, setPage] = useState(1);
  const [type, setType] = useState(false);
  const [voucher, setVoucher] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [option, setOption] = useState('Main Data');
  const [serverError, setServerError] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [voucherOrders, setvOucherOrders] = useState([]);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [purchasingDone, setPurchasingDoen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDistrict, setShowDistrict] = useState(false);
  const [showIndexArea, setShowIndexArea] = useState(null);
  const [showAreaDistrict, setShowAreaDistrict] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  //--------------------Functions----------------------
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) + 1 * 22 * 60 * 60 * 1000 - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  function initializeClock(endtime) {
    const clock = document.getElementsByClassName(`clock`);
    timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      clock[0].innerHTML =
        t.total > 0
          ? (t.days > 0 ? t.days : 0) +
            'D: ' +
            (t.hours > 0 ? t.hours : 0) +
            'H: ' +
            (t.minutes > 0 ? t.minutes : 0) +
            'M: ' +
            (t.seconds > 0 ? t.seconds : 0) +
            'S '
          : 'Finished';
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  }
  let getVoucherOrders = async () => {
    let response = await vouchersEshtreanaService.getVoucherOrders(id, page, 5);
    let arr = [];
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      arr = response.data.orders.map((item) => [
        item.personal ? item.personal.uuid : item.organization.uuid,
        item.personal ? 'Personal' : 'organization',
        item.personal ? item.personal.name : item.organization.commercialName,
        item.personal ? item.personal.phone : 'NAN',
        item.status,
      ]);

      setvOucherOrders(arr);
      setTotalPages(response.data.totalPages);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoadingTable(false);
  };
  let getSingleVoucher = async () => {
    let response = await vouchersSupplierService.getSingleVoucher(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      response.data.voucher.type === 'On going' ? setType('On Going') : setType(response.data.voucher.type);
      setVoucher(response.data.voucher);

      setPurchasingDoen(response.data.voucher.isPurchasingDone);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  let sendPO = async () => {
    let fd = new FormData();
    fd.append('doc', doc[0], doc[0].name);
    let response = await vouchersEshtreanaService.sendPoInvoice(id, fd);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setDoc([]);
      setVoucher((prev) => ({
        ...prev,
        invoice: doc[0],
      }));
      setShowConfirmation(true);

      setLoading(true);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  //--------------------UseEffects----------------------
  useEffect(() => {
    if (doc.length > 0) sendPO();
  }, [doc]);
  useEffect(() => {
    showIndexArea !== null && setShowDistrict(true);
  }, [showIndexArea]);

  useEffect(() => {
    if (voucher && voucher.type !== 'Scheduled' && voucher.type !== 'History' && voucher.type !== 'On going') {
      if (voucher.endDate) {
        if (option !== 'Main Data') {
          clearInterval(timeinterval);
        } else {
          initializeClock(voucher.endDate);
        }
      }

      return () => {
        clearInterval(timeinterval);
      };
    }
  }, [voucher.endDate, option]);

  useEffect(() => {
    getSingleVoucher();
    getVoucherOrders();
    setFirstLoad(false);
  }, [id]);

  useEffect(() => {
    if (!firstLoad) {
      setLoadingTable(true);
      getVoucherOrders();
    }
  }, [page]);
  useEffect(() => {
    !showConfirmation && voucher.title_en && setLoading(false);
  }, [showConfirmation]);
  return (
    <div className={`w-100 ${classes['voucher']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={`Agreements Files Sent Successfully`} />
        </Modal>
      )}
      {showAreaDistrict && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowAreaDistrict(false)}>
          <ShowAreaDistrict setShowForm={setShowAreaDistrict} />
        </Modal>
      )}
      {showDistrict && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowDistrict(false);
            setShowIndexArea(null);
          }}
        >
          <ShowAreaDistrict
            setShowForm={() => {
              setShowDistrict(false);
              setShowIndexArea(null);
            }}
            area={voucher.areas[showIndexArea]}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle text="Voucher Details" icon={<VoucherActieveL />} />
        </div>
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
              <div className="row px-0 mx-0">
                {[0, 1, 2].map((index) => (
                  <div key={index} className="col-xl-2 col-lg-4">
                    <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                  </div>
                ))}
              </div>
            </div>

            <>
              {[0, 1, 2, 3, 4].map((item, index) => (
                <div key={index} className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </section>
        ) : (
          <section className={`${classes['main-section']} mb-5`}>
            <div className={`row  ${classes.options}`}>
              <SelectOption
                onSelect={(val) => setOption(val)}
                default={option}
                options={
                  ((type === 'On Going' || type === 'Scheduled') && ['Main Data', 'Payment Terms', 'Voucher Usage Terms']) ||
                  (type === 'History' && ['Main Data', 'Payment Terms', 'Voucher Usage Terms', 'Orders', 'Agreements']) ||
                  []
                }
              />
            </div>
            {option === 'Main Data' && (
              <>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Voucher Code</p>
                      <span>{voucher.uuid}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Customer Type</p>
                      <span>{voucher.customerType}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Voucher Title</p>
                      <span>{voucher.title_en}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div className={classes['single-item']}>
                      <p>عنوان الكوبون</p>
                      <span>{voucher.title_ar}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Quantity</p>
                      <span>{voucher.quantity}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-md-6 col-12 px-0">
                    <div className={classes['single-item']}>
                      <p>Voucher Status</p>
                      <span>{voucher.status}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Eshtreana Buying Price</p>
                      <span>{voucher.buyingPrice}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-md-6 col-12 px-0">
                    <div className={classes['single-item']}>
                      <p>Voucher Value</p>
                      <span>{voucher.voucherPrice}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Voucher Expiry Date</p>
                      <span>{voucher?.expireDate?.slice(0, 10)}</span>
                    </div>
                  </div>
                </div>

                <div className="row px-0 mx-0 mb-3 ">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p className="mb-4">Areas</p>
                      {voucher.areas.length > 0 ? (
                        voucher.areas.map((item, index) => (
                          <span
                            onClick={() => {
                              item.district.length > 0 && setShowIndexArea(index);
                            }}
                            key={index}
                            className={`${classes['area']} w-auto  d-inline`}
                          >
                            {item.city.city_en}
                          </span>
                        ))
                      ) : (
                        <span
                          // onClick={() => setShowIndexArea(index)}
                          // key={index}
                          className={`${classes['area']} w-auto  d-inline`}
                        >
                          All KSA
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {type === 'Scheduled' && (
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-md-6 col-12 px-0">
                      <div dir="auto" className={classes['single-item']}>
                        <p>Start Date</p>
                        <span>{voucher.startDate}</span>
                      </div>
                    </div>
                    <div dir="auto" className="col-md-6 col-12 px-0">
                      <div className={classes['single-item']}>
                        <p>End Date</p>
                        <span>{voucher.endDate}</span>
                      </div>
                    </div>
                  </div>
                )}
                {type !== 'Scheduled' && (
                  <div className={`row  px-0 mx-0 mt-4 ${classes['company-profile--data']}`}>
                    <div className="col-12 px-0">
                      <div dir="auto" className={classes['single-item']}>
                        <p>Progress</p>
                        <div className="row  px-0 mx-0">
                          <div className="col-lg-2 col-6">
                            <CircularProgressbar
                              value={Math.ceil((voucher.sold / voucher.quantity) * 100)}
                              text={Math.ceil((voucher.sold / voucher.quantity) * 100)}
                            />
                          </div>
                          <div className="col-lg-6 mt-lg-0 mt-4  d-flex flex-column  justify-content-center ms-auto d-inline">
                            <div className="row px-0 mx-0">
                              <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                <Avatar
                                  sx={{
                                    bgcolor: '#3e98c7',
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></Avatar>
                              </div>
                              <div className={`w-auto d-inline pe-5 ${classes['single-item']}`}>
                                <p>Available Vouchers</p>
                              </div>
                              <div className={`w-auto d-inline  ${classes['single-item']}`}>
                                <p>{voucher.quantity - voucher.sold}</p>
                              </div>
                            </div>
                            <div className="row px-0 mx-0">
                              <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                <Avatar
                                  sx={{
                                    bgcolor: '#949494',
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></Avatar>
                              </div>
                              <div className={`w-auto d-inline pe-5 ${classes['single-item']}`}>
                                <p>Sold vouchers</p>
                              </div>
                              <div className={`w-auto d-inline text-center ${classes['single-item']}`}>
                                <p>{voucher.sold}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {option === 'Payment Terms' && (
              <>
                <TermsSections terms={voucher.supplierPaymentTerms} border={true} />
              </>
            )}
            {option === 'Voucher Usage Terms' && (
              <>
                <TermsSections terms={voucher.terms || []} border={true} />
              </>
            )}

            {option === 'Orders' &&
              (loadingTable ? (
                <loadingTable />
              ) : (
                <div className="row px-0 mx-0 position-relative">
                  <Table
                    options={[
                      {
                        title: 'Customer ID',
                        icon: false,
                      },
                      {
                        title: 'Customer Type',
                        icon: false,
                      },
                      {
                        title: 'Customer Name',
                        icon: false,
                      },
                      {
                        title: 'Customer Phone',
                        icon: false,
                      },
                      {
                        title: 'Status',
                        icon: false,
                      },
                    ]}
                    data={voucherOrders}
                    customStyleIndex={[5]}
                    filterOption={[]}
                    customStyleFn={[
                      false,
                      false,
                      false,
                      false,
                      (val) => {
                        return (
                          (val === 'Used' && {
                            borderRadius: '10px',
                            color: '#134074',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: '#134074BD',
                            textAlign: 'center',
                          }) ||
                          (val === 'Valid' && {
                            borderRadius: '10px',
                            color: '#2CD6C2',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: '#E3FDF8',
                            textAlign: 'center',
                          }) ||
                          (val === 'Expired' && {
                            borderRadius: '10px',
                            color: '#F2055C',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: '#F2055C69',
                            textAlign: 'center',
                          })
                        );
                      },
                    ]}
                  />
                  {totalPages > 1 && (
                    <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                      <div className="w-auto d-inline ms-auto">
                        <UsePagination
                          page={page}
                          pages={totalPages}
                          onChangePage={(page) => {
                            setLoadingTable(true);
                            setPage(page);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}

            {option === 'Agreements' && (
              <>
                {!purchasingDone && (
                  <div className="row px-0 mx-0">
                    <div className="col-12 ">
                      <div className={classes.input}>
                        <UploadImageGroup2
                          default={doc}
                          type="supplier"
                          title={'Purshacing Order'}
                          onChange={(e) => setDoc([e])}
                          errorMsg="Provide a Profile Picture"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row px-0 mx-0">
                  <div className="col-12 ">
                    <div className={classes.input}>
                      <UploadImageGroup2
                        noDeleteAll={true}
                        default={
                          (voucher.purchasingOrder && voucher.invoice && [voucher.purchasingOrder, voucher.invoice]) ||
                          (voucher.purchasingOrder && !voucher.invoice && [voucher.purchasingOrder]) ||
                          (!voucher.purchasingOrder && voucher.invoice && [voucher.invoice])
                        }
                        imageType="invoice/po"
                        errorMsg="Provide a Profile Picture"
                        title={'Invoice'}
                        subTitle="click on invoice to download"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
//Done
