import { Policy } from "@mui/icons-material";
import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const shareDealService = {
  getColdDeal,
  getOriginalDeal,
  getVoucher,
};

async function getVoucher(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/deal/voucher/${id}`,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getOriginalDeal(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/deal/original/${id}`,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getColdDeal(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/deal/cold/${id}`,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
