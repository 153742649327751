import React from "react";
import classes from "./InputFeild.module.scss";
import { useState } from "react";
import { useEffect } from "react";
export default function TextArea(props) {
  const [value, setValue] = useState("");
  useEffect(() => {
    props.default && setValue(props.default);
  }, [props.default]);
  return (
    <textarea
      value={value}
      id={props.name}
      name={props.name}
      disabled={props.disable}
      style={{ height: "97px" }}
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange(e);
      }}
      //   type={props.type ? (showPassword ? 'text' : props.type) : 'text'}
      className={`${classes["input-feild"]} ${props.error && classes.error}`}
      placeholder={props.placeholder}
    />
  );
}
