import React, { useEffect, useState } from 'react';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import DocsActiveL from '../../components/Reusable/SVG/DocsActiveL';
import Plus from '../../components/Reusable/SVG/Plus';
import Section from '../../components/Reusable/Common/Section/Section';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import AddSection from '../../components/Reusable/Common/AddMemberCard/AddSection';
import classes from './PolicyPage.module.scss';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import Edit from '../../components/Reusable/SVG/Edit';
import Recycle from '../../components/Reusable/SVG/Recycle';
import RemoveCard from '../../components/Reusable/Common/AddMemberCard/RemoveMember';
import { policiesService } from '../../services/policiesService';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { Skeleton } from '@mui/material';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function PolicyPage(props) {
  const dispatch = useDispatch();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [data, setData] = useState([]);
  const [option, setOption] = useState('Consumer');
  const [loading, setLoading] = useState(true);

  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(false);
  const [editData, setEditData] = useState(null);
  let getPolicies = async () => {
    let response =
      (props.type === 'Terms & Conditions' && (await policiesService.getAllPolicies('terms-and-conditions'))) ||
      (props.type === 'Privacy Policy' &&
        (await policiesService.getAllPolicies('privacy-policy', option === 'Consumer' ? 'consumer' : 'business'))) ||
      (props.type === 'Delivery Terms' && (await policiesService.getAllPolicies('delivery-terms'))) ||
      (props.type === 'Payment Policy' && (await policiesService.getAllPolicies('payment-policy', option === 'Consumer' ? 'consumer' : 'business')));

    setData(response.data.terms || response.data.policies || response.data.deliveryTerms);
    setLoading(false);
  };

  let handleDelete = async (id) => {
    let response = await policiesService.deletePolicies(
      (props.type === 'Terms & Conditions' && 'terms-and-conditions') ||
        (props.type === 'Privacy Policy' && 'privacy-policy') ||
        (props.type === 'Delivery Terms' && 'delivery-terms') ||
        (props.type === 'Payment Policy' && 'payment-policy'),
      editData._id,
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setConfirmationMsg(`Section Deleted Successfully`);
      setShowConfirmation(true);
      setShowDeleteForm(false);
      setLoading(true);
      getPolicies();
      setLoading(false);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
      getPolicies();
    });
    return () => {
      clearTimeout(timer);
    };
  }, [option, props.type]);
  return (
    <div className={`w-100 ${classes['polices-page']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowAddForm(false)}>
          <SuccessPopup message1={confirmationMsg} />
        </Modal>
      )}

      {(showAddForm || showEditForm) && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            showAddForm && setShowAddForm(false);
            showEditForm && setShowEditForm(false);
          }}
        >
          <AddSection
            add={showAddForm}
            setShowForm={() => {
              showAddForm && setShowAddForm(false);
              showEditForm && setShowEditForm(false);
            }}
            edit={showEditForm}
            formType={
              (props.type === 'Terms & Conditions' && 'terms-and-conditions') ||
              (props.type === 'Privacy Policy' && 'privacy-policy') ||
              (props.type === 'Delivery Terms' && 'delivery-terms') ||
              (props.type === 'Payment Policy' && 'payment-policy')
            }
            option={option}
            onSuccess={() => {
              setConfirmationMsg(`Section ${showAddForm ? 'Added' : 'Edited'} Successfully`);
              setShowConfirmation(true);
              setShowAddForm(false);
              setShowEditForm(false);
              setLoading(true);
              getPolicies();
              setLoading(false);
              setTimeout(() => {
                setShowConfirmation(false);
              }, 2000);
            }}
            policy={showEditForm && editData}
          />
        </Modal>
      )}

      {showDeleteForm && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowDeleteForm(false)}>
          <RemoveCard
            onClick={handleDelete}
            setShowForm={setShowDeleteForm}
            message1="Are you sure you want to remove this section?"
            message2={`By removing this section, it won’t show on the ${props.type} anymore.`}
            buttonText="Remove Section"
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          onClick={() => {
            setShowAddForm(true);
          }}
          text={props.type}
          icon={<DocsActiveL />}
          buttonText={`Add Section`}
          buttonType="normal"
          buttonIcon={<Plus />}
        />
        <section className={`${classes['main-section']}`}>
          {(props.type === 'Privacy Policy' || props.type === 'Payment Policy') && (
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption onSelect={(val) => setOption(val)} default="Consumer" options={['Consumer', 'Business']} />
            </div>
          )}
          {loading ? (
            [0, 1, 2, 3, 4, 5, 6].map((item, index) => (
              <div key={index} className="row px-0 mx-0 mb-3">
                <div className="col-5 px-0">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </div>

                <div className="col-5 px-0  ms-auto">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </div>

              </div>
            ))
          ) : data&&data.length > 0 ? (
            data.map((item, index) => (
              <div key={index} className="row px-0 mx-0">
                <div className="col-5 px-0">
                  <Section title={item.title_en} text={item.content_en} />
                </div>
                <div className="col-2 justify-content-center">
                  <div className="row px-0 mx-0 mt-4 pt-2">
                    <div
                      onClick={() => {
                        setEditData(item);
                        setShowEditForm(true);
                      }}
                      className="w-auto mx-auto"
                    >
                      <Edit />
                    </div>
                  </div>
                  <div className="row px-0 mx-0 mt-3">
                    <div
                      onClick={() => {
                        setEditData(item);
                        setShowDeleteForm(true);
                      }}
                      className="w-auto mx-auto"
                    >
                      <Recycle />
                    </div>
                  </div>
                </div>
                <div className="col-5 px-0  ms-auto">
                  <Section title={item.title_ar} text={item.content_ar} />
                </div>
              </div>
            ))
          ) : (
            <div className="w-100 row justify-content-center pt-5 pb-4">
              <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Data Found</div>
            </div>
          )}
        </section>
      </section>
    </div>
  );
}
