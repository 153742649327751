import React from 'react';
import classes from './AddMember.module.scss';
import Close from '../../SVG/Close';
import Success from '../../SVG/Success';
import Error from '../../SVG/Error';
export default function FailPop(props) {
  return (
    <>
      <div className={`row mx-0 justify-content-cenetr text-cenetr px-0 mb-2 ${classes.remove}`}>
        <div className="row mx-0 px-0 ">
          <div
            onClick={() => 
              props.setShowForm(false)
            }
            className="cursor col-2 ms-auto   px-0 text-end"
          >
            <Close />
          </div>
        </div>
        <div className="row mx-0  px-0 mt-4">
          <Error />
        </div>
        <div style={{display:"flex",flexWrap:"wrap"}} className="row px-0 mx-0 justify-content-center">

        <p  className="w-auto mx-auto text-center ">{props.message1}</p>
        </div>
     
      </div>
    </>
  );
}
