import React from 'react';

export default function PersonActiveL() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2134 14.9927C16.0801 14.9793 15.9201 14.9793 15.7734 14.9927C12.6001 14.886 10.0801 12.286 10.0801 9.08602C10.0801 5.81935 12.7201 3.16602 16.0001 3.16602C19.2667 3.16602 21.9201 5.81935 21.9201 9.08602C21.9067 12.286 19.3867 14.886 16.2134 14.9927Z"
        stroke="#134074"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.54671 19.914C6.32004 22.074 6.32004 25.594 9.54671 27.7407C13.2134 30.194 19.2267 30.194 22.8934 27.7407C26.12 25.5807 26.12 22.0607 22.8934 19.914C19.24 17.474 13.2267 17.474 9.54671 19.914Z"
        stroke="#134074"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
