import React from 'react';

export default function Download(props) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 22.5H15C20 22.5 22 20.5 22 15.5V9.5C22 4.5 20 2.5 15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5Z"
        stroke={props.color || '#0D0D0D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 12.0098L12 15.0098L15 12.0098" stroke={props.color || '#0D0D0D'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 15.0098V7.00977" stroke={props.color || '#0D0D0D'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6 17.0098C9.89 18.3098 14.11 18.3098 18 17.0098"
        stroke={props.color || '#0D0D0D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
