import React, { useState } from 'react';
import classes from './InputFeild.module.scss';
import UploadImageSVG from '../../SVG/UploadImage';
import { useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import Close from '../../SVG/Close';
import Img from '../../Common/Img';
import video from '../../../../assests/video.png';
export default function UploadImage(props) {
  const [images, setImages] = useState(props.default ? props.default : []);
  const [image, setImage] = useState(props.default ? props.default : false);
  let handleChange = (e) => {
    setImage(e.target.files[0]);
    props.onChange(e);
  };
  let handleChangeMultiple = (e) => {
    setImages((prev) => [...prev, e.target.files[0]]);
    props.onChange(e);
  };
  useEffect(() => {
    props.default && props.multiple ? setImages(props.default) : setImage(props.default);
  }, []);

  let handleRemoveImage = (index) => {
    if (props.multiple) {
      props.onRemove(index);
      let arr = [];
      arr = images.filter((item, ind) => index !== ind);
      setImages(arr);
    } else {
      props.onRemove(null);
      setImage(null);
    }
  };
  return (
    <>
      {props.multiple ? (
        <div div className="row px-0 mx-0">
          <Swiper
            slidesPerView={5}
            // width={props.type === 'Product' && 500}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: props.type === 'product' || props.type === 'yardSale' ? 3 : 4,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: props.type === 'product' || props.type === 'yardSale' ? 3 : 4,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: props.type === 'product' || props.type === 'yardSale' ? 3 : 4,
                spaceBetween: 10,
              },
              1025: {
                slidesPerView: props.type === 'product' || props.type === 'yardSale' ? 5 : 5,
                spaceBetween: 10,
              },
              1441: {
                slidesPerView: props.type === 'product' || props.type === 'yardSale' ? 5 : 5,
                spaceBetween: 10,
              },
            }}
            loop={false}
            loopFillGroupWithBlank={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            style={{
              height: props.type === 'product' && '100px',
              width: '100%',
            }}
            className="mySwiper  justify-content-start    align-items-center d-flex p-0 "
          >
            {images.map((item, index) => (
              <SwiperSlide key={index} className={` text-start px-0 pt-0 mt-0 me-3   ${classes['input-feild-image']} px-0`}>
                <div className={`w-100 px-0 mx-0 align-items-center  d-flex  ${props.error && classes.error}`} key={index}>
                  <div className="position-relative px-0 overflow-hidden  rounded-3">
                    <div
                      onClick={() => handleRemoveImage(index)}
                      style={{ zIndex: '1', top: '4px', right: '4px' }}
                      className={` w-auto bg-white   position-absolute ${classes['close-btn']}`}
                    >
                      <Close />
                    </div>
                    <Img
                      className="bg-dark"
                      // style={{ width: '100%' }}
                      // className="w-100  border border-danger"
                      src={
                        images[index].name
                          ? URL.createObjectURL(images[index])
                          : (props.type === 'logo' && `${process.env.REACT_APP_IMAGE_BASE_URL}/advice/${image}`) ||
                            (props.type === 'Category' && `${process.env.REACT_APP_IMAGE_BASE_URL}/category/${image}`) ||
                            (props.type === 'cashier' && `${process.env.REACT_APP_IMAGE_BASE_URL}/cashier/${image}`) ||
                            (props.type === 'supplier' && `${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${image}`) ||
                            (props.type === 'Sub Category' && `${process.env.REACT_APP_IMAGE_BASE_URL}/subcategory/${image}`) ||
                            (props.type === 'member' && `${process.env.REACT_APP_IMAGE_BASE_URL}/company-staff/${image}`) ||
                            (props.type === 'ads' && `${process.env.REACT_APP_IMAGE_BASE_URL}/ads/${image}`) ||
                            (props.type === 'advice' && `${process.env.REACT_APP_IMAGE_BASE_URL}/advice/${image}`)
                      }
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <SwiperSlide
              style={{ height: props.type === 'product' && '100px' }}
              // onClick={() => setShowImages(true)}
              className={` text-start px-0 pt-0 mt-0  w-auto ${classes['unlimit-img']}`}
            >
              <div
                style={{ width: '200px', height: '160px' }}
                className={`col-2 px-0 mx-0 align-items-center d-flex ${classes['input-feild-image']} ${props.error && classes.error}`}
                // key={index}
              >
                <div className={classes.upload}>
                  <input
                    name={props.name}
                    type="file"
                    onChange={(e) => handleChangeMultiple(e)}
                    accept={
                      props.video
                        ? props.png
                          ? `video/*,image/jpg, image/jpeg,image/png`
                          : `video/*,image/jpg, image/jpeg`
                        : props.png
                        ? `image/jpg, image/jpeg,image/png`
                        : `image/jpg, image/jpeg`
                    }
                  />
                </div>
                {/* <div className="position-absolute"> */}
                <div className="w-auto text-center h-auto">
                  <UploadImageSVG />
                  <p className=" mb-auto ">Upload {props.text ? props.text : 'Logo'}</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      ) : (
        <div
          className={`row px-0 mx-0 align-items-center d-flex ${classes['input-feild-image']} ${props.error && classes.error} ${
            props.error && classes['image-error']
          }`}
        >
          {image ? (
            <div className="position-relative px-0 overflow-hidden rounded-3">
              <div
                onClick={() => handleRemoveImage()}
                style={{ zIndex: '1', top: '4px', right: '4px' }}
                className={` w-auto bg-white  position-absolute ${classes['close-btn']}`}
              >
                <Close />
              </div>
              {(!image.name && image.includes('.mp4')) || (image.name && image.name.includes('.mp4')) ? (
                <Img src={video} alt="" />
              ) : (
                <Img
                  className="bg-dark"
                  src={
                    image.name
                      ? URL.createObjectURL(image)
                      : (props.type === 'logo' && `${process.env.REACT_APP_IMAGE_BASE_URL}/advice/${image}`) ||
                        (props.type === 'Category' && `${process.env.REACT_APP_IMAGE_BASE_URL}/category/${image}`) ||
                        (props.type === 'cashier' && `${process.env.REACT_APP_IMAGE_BASE_URL}/cashier/${image}`) ||
                        (props.type === 'supplier' && `${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${image}`) ||
                        (props.type === 'Sub Category' && `${process.env.REACT_APP_IMAGE_BASE_URL}/subcategory/${image}`) ||
                        (props.type === 'member' && `${process.env.REACT_APP_IMAGE_BASE_URL}/company-staff/${image}`) ||
                        (props.type === 'ads' && `${process.env.REACT_APP_IMAGE_BASE_URL}/ads/${image}`) ||
                        (props.type === 'advice' && `${process.env.REACT_APP_IMAGE_BASE_URL}/advice/${image}`)
                  }
                  alt=""
                />
              )}
            </div>
          ) : props.multiple ? null : (
            <>
              <div className={`${classes.upload}  position-absolute`}>
                <input
                  name={props.name}
                  type="file"
                  // className="h-100"
                  // style={{ left: "0", top: "0" }}
                  onChange={(e) => handleChange(e)}
                  accept={
                    props.video
                      ? props.png
                        ? `video/*,image/jpg, image/jpeg,image/png`
                        : `video/*,image/jpg, image/jpeg`
                      : props.png
                      ? `image/jpg, image/jpeg,image/png`
                      : `image/jpg, image/jpeg`
                  }
                />
              </div>
              {/* <div className="position-absolute"> */}
              <div className={`w-auto text-center h-auto `}>
                <UploadImageSVG />
                <p className=" mb-auto ">Upload Media</p>
              </div>
            </>
          )}

          {/* </div> */}
        </div>
      )}
    </>
  );
}
