import { useDispatch } from 'react-redux';
import classes from './Voucher.module.scss';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Plus from '../../components/Reusable/SVG/Plus';
import Edit from '../../components/Reusable/SVG/Edit';
import Img from '../../components/Reusable/Common/Img';
import Close from '../../components/Reusable/SVG/Close';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { Checkbox, Fab, Radio, Skeleton } from '@mui/material';
import { errorActions } from '../../store/reducers/errorSlice';
import Button from '../../components/Reusable/UI/Button/Button';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import Section from '../../components/Reusable/Common/Section/Section';
import { vouchersEshtreanaService } from '../../services/VouchersEshtreana';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import VoucherActieveL from '../../components/Reusable/SVG/VoucherActieveL';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import AddSection from '../../components/Reusable/Common/AddMemberCard/AddSection';
import ManagerCard from '../../components/Reusable/Common/ManagerCard/ManagerCard';
import { supplierTopManagerService } from '../../services/supplierTopManagerService';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import UploadImageGroup2 from '../../components/Reusable/UI/InputGroup/UploadImageGroup2';

export default function VoucherForm() {
  //------------------------Declarations-------------------------------
  const dispatch = useDispatch();
  const history = useNavigate();
  const [areas, setAreas] = useState([]);
  const [cities, setCitites] = useState([1]);
  const [loading, setLoading] = useState(true);
  const [districts, setDistrict] = useState([]);
  const [changed, setChanged] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [showError, setShowError] = useState(false);
  const [option, setOption] = useState('Main Data');
  const [searchValue, setSearchValue] = useState('');
  const [serverError, setServerError] = useState(false);
  const [supplierTerms, setSupplierTerms] = useState([]);
  const [customerTerms, setCustomerTerms] = useState([]);
  const [additionalTerms, setAdditionalTerms] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedDisrictFinal, setSelectedDistrict] = useState([[]]);
  const [addSupplierSectionForm, setAddSupplierSectionForm] = useState(false);
  const [addCustomerSectionForm, setAddCustomerSectionForm] = useState(false);
  const [showEditFormSupplier, setShowEditFormSupplier] = useState(false);
  const [addAdditionalTermsSectionForm, setAddAdditionalTermsSectionForm] = useState(false);
  const [showEditAdditionalTermsForm, setShowEditAdditionalTermsForm] = useState(false);
  const [showEditFormCustomer, setShowEditFormCustomer] = useState(false);
  const [sectionData, setSectionData] = useState({
    title_en: null,
    title_ar: null,
    content_en: null,
    content_ar: null,
  });
  const [data, setData] = useState({
    customerType: 'Both',
    dealPhoto: [],
    title_en: null,
    title_ar: null,
    quantitiy: null,
    buyingPrice: null,
    voucherValue: null,
    sellingPrice: null,
    dealTermination: 'By Duration',
    noWeeks: null,
    noDays: null,
    noTimes: null,
    startDate: null,
    endDate: null,
    workingAreas: 'All KSA',
    dealExpireDate: null,
  });
  const [dataErr, setDataErr] = useState({
    dealPhoto: false,
    title_en: false,
    title_ar: false,
    quantitiy: false,
    buyingPrice: false,
    voucherValue: false,
    sellingPrice: false,
    dealTermination: false,
    noWeeks: false,
    noDays: false,
    noTimes: false,
    startDate: false,
    endDate: false,
    workingAreas: false,
    dealExpireDate: false,
    supplierTerms: false,
    customerTerms: false,
    additionalTerms: false,
  });

  //------------------------Functions-------------------------------
  let getSuppliers = async () => {
    let response = await supplierTopManagerService.getAllSuppliers(false, false, `search=${searchValue}`);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      let checked = false;
      //console.log(response.data.suppliers);
      let arr = [];
      response.data.suppliers.map((item, index) => {
        checked = false;
        selectedSuppliers.filter((i, ind) => {
          if (item._id === i._id) checked = true;
        });
        if (!checked) {
          arr.push(item);
        }
      });
      setSuppliers(arr);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  let handleChange = (e) => {
    //console.log(e.target.value);
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    e.target.value.replace(/\s/g, '').length === 0
      ? setDataErr((prev) => ({ ...prev, [e.target.name]: false }))
      : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === 'dealExpireDate') {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000) setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
    if (e.target.name === 'buyingPrice' || e.target.name === 'voucherValue' || e.target.name === 'sellingPrice') {
      if (
        (e.target.name === 'buyingPrice' &&
          parseFloat(data.voucherValue) > parseFloat(data.sellingPrice) &&
          parseFloat(data.sellingPrice) > parseFloat(e.target.value)) ||
        (e.target.name === 'voucherValue' &&
          parseFloat(e.target.value) > parseFloat(data.sellingPrice) &&
          parseFloat(data.sellingPrice) > parseFloat(data.buyingPrice)) ||
        (e.target.name === 'sellingPrice' &&
          parseFloat(data.voucherValue) > parseFloat(e.target.value) &&
          parseFloat(e.target.value) > parseFloat(data.buyingPrice))
      )
        setDataErr((prev) => ({
          ...prev,
          buyingPrice: true,
          sellingPrice: true,
          voucherValue: true,
        }));
      else
        setDataErr((prev) => ({
          ...prev,
          buyingPrice: false,
          sellingPrice: false,
          voucherValue: false,
        }));

      if (e.target.name === 'buyingPrice' || e.target.name === 'voucherValue' || e.target.name === 'sellingPrice') {
        var isANumber = isNaN(e.target.value) === false;

        isANumber ? setDataErr((prev) => ({ ...prev, [e.target.name]: true })) : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

        (parseFloat(e.target.value) <= 0 || e.target.value.startsWith('-') || e.target.value === '' || e.target.value.startsWith('+')) &&
          setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      }
    }

    if (e.target.name === 'quantitiy') {
      var isANumber = isNaN(e.target.value) === false;

      isANumber ? setDataErr((prev) => ({ ...prev, [e.target.name]: true })) : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      ((e.target.value.startsWith('0') && e.target.value === '0') ||
        e.target.value.startsWith('-') ||
        e.target.value === '' ||
        e.target.value.includes('.') ||
        e.target.value.startsWith('+')) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(0);
  let handleAddSection = (data) => {
    if (option === 'Supplier Payment Terms') {
      if (sectionData.title_en) {
        let arr = [];
        supplierTerms.map((item1, index1) => {
          if (selectedIndex === index1) {
            arr.push({
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: sectionData.selected,
            });
          } else
            arr.push({
              title_en: item1.title_en,
              title_ar: item1.title_ar,
              content_en: item1.content_en,
              content_ar: item1.content_ar,
              selected: item1.selected,
            });
        });
        setSupplierTerms(arr);
      } else {
        setSupplierTerms((prev) => [
          ...prev,
          {
            title_en: data.titleEN,
            title_ar: data.titleAR,
            content_en: data.descriptionEN,
            content_ar: data.descriptionAR,
            selected: true,
          },
        ]);
      }
      setDataErr((prev) => ({ ...prev, supplierTerms: true }));
    } else if (option === 'Customer Payment Terms') {
      if (sectionData.title_en) {
        let arr = [];
        customerTerms.map((item1, index1) => {
          if (selectedIndex === index1) {
            arr.push({
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: sectionData.selected,
            });
          } else
            arr.push({
              title_en: item1.title_en,
              title_ar: item1.title_ar,
              content_en: item1.content_en,
              content_ar: item1.content_ar,
              selected: item1.selected,
            });
        });
        setCustomerTerms(arr);
      } else {
        setCustomerTerms((prev) => [
          ...prev,
          {
            title_en: data.titleEN,
            title_ar: data.titleAR,
            content_en: data.descriptionEN,
            content_ar: data.descriptionAR,
            selected: true,
          },
        ]);
      }
      setDataErr((prev) => ({ ...prev, customerTerms: true }));
    } else {
      if (sectionData.title_en) {
        let arr = [];
        additionalTerms.map((item1, index1) => {
          if (selectedIndex === index1) {
            arr.push({
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: sectionData.selected,
            });
          } else
            arr.push({
              title_en: item1.title_en,
              title_ar: item1.title_ar,
              content_en: item1.content_en,
              content_ar: item1.content_ar,
              selected: item1.selected,
            });
        });
        setAdditionalTerms(arr);
      } else {
        setAdditionalTerms((prev) => [
          ...prev,
          {
            title_en: data.titleEN,
            title_ar: data.titleAR,
            content_en: data.descriptionEN,
            content_ar: data.descriptionAR,
            selected: true,
          },
        ]);
      }
      setDataErr((prev) => ({ ...prev, additionalTerms: true }));
    }
  };

  let handleSubmit = async () => {
    setShowError(true);
    let finalArea = [];
    let finalSupplierTerms = [];
    let finalCustomerTerms = [];
    let finalAdditionalTerms = [];
    let temp = {
      city: '',
      district: [],
    };
    let ind = -1;
    if (data.workingAreas !== 'All KSA') {
      // //console.log(selectedDisrictFinal);
      areas.map((item, index) => {
        if (selectedCities.includes(item.city_en)) {
          ind++;
          temp.city = item._id;
          finalArea[ind] = [];
          item.district &&
            item.district.map((district, i) => {
              if (selectedDisrictFinal[ind]) {
                if (selectedDisrictFinal[ind][0] === 'all') {
                  temp.district.push(district._id);
                } else if (selectedDisrictFinal[ind].includes(district.district_en)) {
                  temp.district.push(district._id);
                }
              }
            });
          finalArea[ind] = temp;
          temp = {
            city: '',
            district: [],
          };
        }
      });
      // //console.log(finalArea);
    }

    supplierTerms.map((item) => {
      if (item.selected) {
        finalSupplierTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });
    additionalTerms.map((item) => {
      if (item.selected) {
        finalAdditionalTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });

    customerTerms.map((item) => {
      if (item.selected) {
        finalCustomerTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });
    finalCustomerTerms.length > 0
      ? setDataErr((prev) => ({ ...prev, customerTerms: true }))
      : setDataErr((prev) => ({ ...prev, customerTerms: false }));
    finalSupplierTerms.length > 0
      ? setDataErr((prev) => ({ ...prev, supplierTerms: true }))
      : setDataErr((prev) => ({ ...prev, supplierTerms: false }));
    finalAdditionalTerms.length > 0
      ? setDataErr((prev) => ({ ...prev, additionalTerms: true }))
      : setDataErr((prev) => ({ ...prev, additionalTerms: false }));

    if (data.voucherValue < data.sellingPrice && data.voucherValue > data.buyingPrice)
      setDataErr((prev) => ({
        ...prev,
        buyingPrice: true,
        sellingPrice: true,
        voucherValue: true,
      }));
    else
      setDataErr((prev) => ({
        ...prev,
        buyingPrice: false,
        sellingPrice: false,
        voucherValue: false,
      }));

    if (
      dataErr.buyingPrice &&
      dataErr.dealExpireDate &&
      dataErr.dealPhoto &&
      dataErr.quantitiy &&
      dataErr.sellingPrice &&
      dataErr.title_en &&
      dataErr.voucherValue &&
      finalCustomerTerms.length > 0 &&
      finalSupplierTerms.length > 0 &&
      // finalAdditionalTerms.length > 0 &&
      ((data.workingAreas !== 'All KSA' && selectedCities.length > 0) || data.workingAreas === 'All KSA')
    ) {
      let fd = new FormData();

      fd.append('customerType', data.customerType);
      fd.append('title_en', data.title_en);
      fd.append('allKsa', data.workingAreas === 'All KSA' ? true : false);
      fd.append('title_ar', data.title_ar);
      for (let i = 0; i < data.dealPhoto.length; i++) fd.append('pic', data.dealPhoto[i], data.dealPhoto[i].name);
      fd.append('quantity', data.quantitiy);
      fd.append('voucherPrice', data.voucherValue);
      fd.append('buyingPrice', data.buyingPrice);
      fd.append('sellingPrice', data.sellingPrice);
      fd.append('expireDate', data.dealExpireDate);
      fd.append('customerPaymentTerms[]', JSON.stringify(finalCustomerTerms));
      fd.append('terms[]', JSON.stringify(finalAdditionalTerms));
      data.workingAreas !== 'All KSA' && fd.append('cities[]', JSON.stringify(finalArea));
      fd.append('supplierPaymentTerms[]', JSON.stringify(finalSupplierTerms));
      selectedSuppliers.map((item) => fd.append('supplier', item._id));
      let response = await vouchersEshtreanaService.addVoucherRequest(fd);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          history('/account/voucher', { replace: true });
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
      }
    } else {
      let arr = [];
      //     Object.keys(dataErr)
      // .forEach(function eachKey(key) {
      //   if(dataErr[key]===false)
      //   arr.push(key);
      // });
      !dataErr.dealPhoto && arr.push('Deal Photo');
      !dataErr.title_en && arr.push('English Title ');
      !dataErr.title_ar && arr.push('Arabic Title');
      !dataErr.quantitiy && arr.push('Quantity');
      !dataErr.buyingPrice && arr.push('Buying Price');
      !dataErr.sellingPrice && arr.push('Selling Price');
      !dataErr.voucherValue && arr.push('Voucher Value');
      data.workingAreas !== 'All KSA' && !dataErr.workingArease && arr.push('Working Areas');
      !dataErr.dealExpireDate && arr.push('Expiry Date');
      finalCustomerTerms.length === 0 && arr.push('Cusomer Payment Terms');
      finalSupplierTerms.length === 0 && arr.push('Supplier Payment Terms');
      selectedSuppliers.length === 0 && arr.push('Supplier Payment Terms');

      // setServerErrorMsg(`There a Missing / Incorrect Data are Required Must be Filled , Please Check Your Data .`);
      setServerErrorMsg(`There a Incorrect / Missing Data are Required [${[...arr]}]`);

      setServerError(true);
      setTimeout(() => {
        setServerError(false);
      }, 4000);
    }
    setLoadingButton(false);
  };
  //------------------------UseEffects-------------------------------

  useEffect(() => {
    if (parseFloat(data.voucherValue) > parseFloat(data.sellingPrice) && parseFloat(data.sellingPrice) > parseFloat(data.buyingPrice)) {
      setDataErr((prev) => ({
        ...prev,
        buyingPrice: true,
        sellingPrice: true,
        voucherValue: true,
      }));
    } else {
      setDataErr((prev) => ({
        ...prev,
        buyingPrice: false,
        sellingPrice: false,
        voucherValue: false,
      }));
    }

    [data.buyingPrice, data.voucherValue, data.sellingPrice].map((item, index) => {
      if (item) {
        (parseFloat(item) <= 0 || item.startsWith('-') || item === '' || item.startsWith('+')) &&
          setDataErr((prev) => ({
            ...prev,
            [(index === 0 && 'buyingPrice') || (index === 1 && 'voucherValue') || (index === 2 && 'sellingPrice')]: false,
          }));
      }
      var isANumber = isNaN(item) === false;
      //console.log(isANumber);
      !isANumber &&
        setDataErr((prev) => ({
          ...prev,
          [(index === 0 && 'buyingPrice') || (index === 1 && 'voucherValue') || (index === 2 && 'sellingPrice')]: false,
        }));
    });
  }, [dataErr.buyingPrice, dataErr.voucherValue, dataErr.sellingPrice]);

  useEffect(() => {
    mainDataHelper.getCities(setServerError, setServerErrorMsg, setAreas);
    mainDataHelper.getSupplierTerms(setServerError, setServerErrorMsg, setSupplierTerms);
    mainDataHelper.getCustomerTerms(setServerError, setServerErrorMsg, setCustomerTerms);
  }, []);
  useEffect(() => {
    let arr = [];
    selectedCities.map((item) => {
      areas.map((item1) => item1.city_en === item && arr.push(item1.district));
    });
    setDistrict(arr);
  }, [changed]);
  useEffect(() => {
    getSuppliers();
  }, [searchValue, loading]);
  return (
    <div style={{ overflowX: 'hidden' }} className={`w-100  ${classes['voucher']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={`Voucher Request Sent Successfully`} />
        </Modal>
      )}

      {(addSupplierSectionForm ||
        addCustomerSectionForm ||
        showEditFormCustomer ||
        showEditAdditionalTermsForm ||
        addAdditionalTermsSectionForm ||
        showEditFormSupplier) && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            addSupplierSectionForm && setAddSupplierSectionForm(false);
            addCustomerSectionForm && setAddCustomerSectionForm(false);
            addAdditionalTermsSectionForm && setAddAdditionalTermsSectionForm(false);
            showEditAdditionalTermsForm && setShowEditAdditionalTermsForm(false);
            showEditFormCustomer && setShowEditFormCustomer(false);
            showEditFormSupplier && setShowEditFormSupplier(false);
          }}
        >
          <AddSection
            data={(showEditFormCustomer || showEditFormSupplier || showEditAdditionalTermsForm) && sectionData}
            handleSubmit={(data) => handleAddSection(data)}
            add={addCustomerSectionForm || addSupplierSectionForm || addAdditionalTermsSectionForm}
            edit={showEditFormCustomer || showEditFormSupplier || showEditAdditionalTermsForm}
            setShowForm={() => {
              addAdditionalTermsSectionForm && setAddAdditionalTermsSectionForm(false);
              showEditAdditionalTermsForm && setShowEditAdditionalTermsForm(false);
              addSupplierSectionForm && setAddSupplierSectionForm(false);
              addCustomerSectionForm && setAddCustomerSectionForm(false);
              showEditFormCustomer && setShowEditFormCustomer(false);
              showEditFormSupplier && setShowEditFormSupplier(false);
            }}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle text="Voucher Preparation Request" icon={<VoucherActieveL />} />
        </div>
        {false ? (
          <section className={`${classes['main-section']}`}>
            <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
              <div className="row px-0 mx-0">
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
              </div>
            </div>

            <>
              {[0, 1, 2, 3, 4].map((item, index) => (
                <div key={index} className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['input']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ height: '4rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['input']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ height: '4rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </section>
        ) : (
          <section className={`${classes['main-section']}`}>
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={['Main Data', 'Supplier Payment Terms', 'Customer Payment Terms', 'Terms', 'Supplier']}
              />
            </div>
            {option === 'Main Data' && (
              <>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 pe-2">
                    <div className={classes.input}>
                      <p>Customer Type</p>
                      <div className={`row p-0 m-0 ${classes['input--options']}`}>
                        {['B2C', 'B2B', 'Both'].map((item, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setData((prev) => ({
                                ...prev,
                                customerType: item,
                              }));
                            }}
                            className="w-auto d-inline d-flex align-items-center "
                          >
                            <Radio checked={data.customerType === item ? true : false} className="h-auto px-0 py-0" />
                            <label className="h-auto ">&nbsp; {item}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.input}>
                  <UploadImageGroup2
                    default={data.dealPhoto}
                    imageType={'voucher'}
                    formType={'voucher'}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        dealPhoto: [...data.dealPhoto, e],
                      }));
                      setDataErr((prev) => ({
                        ...prev,
                        dealPhoto: true,
                      }));
                    }}
                    onRemove={(data) => {
                      setData((prev) => ({ ...prev, dealPhoto: data }));

                      setDataErr((prev) => ({
                        ...prev,
                        dealPhoto: false,
                      }));
                    }}
                    error={showError && data.dealPhoto.length === 0}
                    errorMsg="Provide Deal Photos"
                    title="Deal Photo"
                    type="Photo"
                  />
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-4 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.title_en}
                        name="title_en"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.title_en}
                        errorMsg="provide a Voucher Title"
                        value={'Voucher Title'}
                        underLineText="voucher title for users"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 px-0 ps-lg-2 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.title_ar}
                        name="title_ar"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.title_ar}
                        errorMsg="من فضلك سجل اسم الكوبون"
                        value="اسم الكوبون"
                        underLineText={'اسم الكوبون الذي سيظهر للمستخدم'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.quantitiy}
                        name="quantitiy"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.quantitiy}
                        errorMsg="provide a Voucher Quantity"
                        value="Quantity"
                        underLineText={'Number of vouchers in deal'}
                      />
                    </div>
                  </div>
                </div>

                <div className="row px-0 mx-0">
                  <div className="col-lg-4 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.buyingPrice}
                        name="buyingPrice"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.buyingPrice}
                        errorMsg={data.buyingPrice ? 'provide a Valid Buying Price' : 'provide a Buying Price'}
                        value={'Buying Price'}
                        underLineText="Voucher cost on eshtarena"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 px-0 ps-lg-2 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.voucherValue}
                        name="voucherValue"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.voucherValue}
                        errorMsg={data.voucherValue ? 'provide a Valid Voucher Value' : 'provide a Voucher Value'}
                        value="Voucher Value"
                        underLineText={'Discount money value for users'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.sellingPrice}
                        name="sellingPrice"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.sellingPrice}
                        errorMsg={data.sellingPrice ? 'provide a Valid Selling Price' : 'provide a Selling Price'}
                        value="Selling Price"
                        underLineText={'Voucher Price for users'}
                      />
                    </div>
                  </div>
                </div>

                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <p>Working Areas</p>
                      <div className={`row p-0 m-0 ${classes['input--options']}`}>
                        {['All KSA', 'Different Areas'].map((item, index) => (
                          <div className="w-auto d-inline d-flex align-items-center ">
                            <Radio
                              onClick={() => {
                                setData((prev) => ({
                                  ...prev,
                                  workingAreas: item,
                                }));
                              }}
                              checked={data.workingAreas === item ? true : false}
                              className="h-auto px-0 py-0"
                            />
                            <label
                              onClick={() => {
                                setData((prev) => ({
                                  ...prev,
                                  workingAreas: item,
                                }));
                              }}
                              className="h-auto cursor "
                            >
                              &nbsp; {item}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {data.workingAreas === 'Different Areas' &&
                  (areas.length > 0 ? (
                    cities.map((item, index) => (
                      <div key={index} className="row px-0 mx-0">
                        <div className="col-lg-6 px-0 pe-lg-2">
                          <div className={classes.input}>
                            <InputGroup
                              type="dropdown"
                              default={selectedCities[index] ? selectedCities[index] : 'select City'}
                              options={['select City', ...areas]}
                              city={true}
                              error={showError && selectedCities.length === 0}
                              errorMsg="Provide a City"
                              onChange={(e) => {
                                let arr = selectedCities;
                                let arr2 = [];
                                if (selectedCities.includes(e.target.value) || e.target.value === 'select City') {
                                  arr.map((item, ind) => {
                                    if (index !== ind) {
                                      arr2.push(item);
                                    }
                                  });
                                  setSelectedCities(arr2);
                                  let arr1 = selectedDisrictFinal;
                                  arr1[index] = [];
                                  setSelectedDistrict(arr1);
                                } else {
                                  arr[index] = e.target.value;
                                  setSelectedCities((prev) => arr);
                                  let arr1 = selectedDisrictFinal;
                                  arr1[index] = [];
                                  setSelectedDistrict(arr1);
                                }
                                setChanged((prev) => !prev);
                              }}
                              value={'Cities'}
                            />
                            {cities.length === index + 1 && (
                              <p
                                onClick={() => {
                                  setSelectedCities((prev) => [...prev, []]);

                                  setCitites((prev) => [...prev, cities.length]);
                                }}
                                className={`${classes['input--link']}`}
                              >
                                <Plus color="#949494" /> Add City
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 px-0 ps-lg-2">
                          <div className={classes.input}>
                            <InputGroup
                              type="dropdown"
                              default={'select District'}
                              options={districts[index] && ['select District', ...districts[index]]}
                              district={true}
                              error={showError && selectedCities.length === 0}
                              errorMsg="Provide a District"
                              selected={selectedDisrictFinal[index]}
                              onChange={(e) => {
                                if (e.target.value !== 'select District' && !selectedDisrictFinal[index].includes(e.target.value)) {
                                  let arr = selectedDisrictFinal;
                                  arr[index] = arr[index] ? [...arr[index], e.target.value] : [e.target.value];
                                  setSelectedDistrict(arr);
                                  setChanged((prev) => !prev);
                                }
                              }}
                              value={'Districts'}
                            />
                            <p className={`${classes['input--options']}`}>
                              <Checkbox
                                checked={
                                  selectedDisrictFinal[index] && selectedDisrictFinal[index][0] && selectedDisrictFinal[index][0] === 'all'
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let arr = selectedDisrictFinal;
                                  arr[index] = ['all'];
                                  setSelectedDistrict(arr);
                                  setChanged((prev) => !prev);
                                }}
                              />
                              All districts
                            </p>
                          </div>
                        </div>
                        <div className="row px-0 mx-0 mb-3 ">
                          {selectedDisrictFinal[index] &&
                            selectedDisrictFinal[index].length > 0 &&
                            selectedDisrictFinal[index].map((item, ind) => (
                              <div key={ind} className="w-auto d-inline mt-2 pe-lg-2 position-relative">
                                <div
                                  onClick={() => {
                                    let arr = [];

                                    if (selectedDisrictFinal[index][0] === 'all') {
                                      let arr = selectedDisrictFinal;
                                      arr[index] = [];
                                      //console.log(arr);
                                      setSelectedDistrict(arr);
                                      setChanged((prev) => !prev);
                                    } else {
                                      arr = [];
                                      arr = selectedDisrictFinal[index].filter((item1, index) => {
                                        if (item1 !== item) return item1;
                                      });
                                      if (arr.length > 0) {
                                        selectedDisrictFinal[index] = arr;
                                        setSelectedDistrict(selectedDisrictFinal);
                                      } else {
                                        selectedDisrictFinal[index] = [];
                                        setSelectedDistrict(selectedDisrictFinal);
                                      }
                                      setChanged((prev) => !prev);
                                    }
                                  }}
                                  style={{
                                    right: '10px',
                                    zIndex: 2,
                                    // top: "10px",
                                  }}
                                  className="text-end  cursor  h-100 align-items-center d-flex position-absolute"
                                >
                                  <Close />
                                </div>
                                <Fab style={{ zIndex: 1 }} variant="extended" className=" pe-5">
                                  {item}
                                </Fab>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-100 row justify-content-center pt-5 pb-4">
                      <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Citites Added Yet</div>
                    </div>
                  ))}

                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.dealExpireDate}
                        name="dealExpireDate"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.dealExpireDate}
                        errorMsg={data.dealExpireDate ? 'Provide a Valid Expiry Date' : 'provide an Expiry Date'}
                        type="date"
                        value={'Deal Expiry Date'}
                        underLineText="when user cant use voucher anymore"
                      />
                    </div>
                  </div>
                </div>

                <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                  {/* <div className="w-auto  ms-auto"> */}

                  <div className="w-lg-auto  ms-auto d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Supplier Payment Terms');
                        }}
                        // icon={<Plus />}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-lg-auto  d-inline px-5  ms-auto '}
                        type="normal"
                        text={'Next'}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            )}

            {option === 'Supplier Payment Terms' && (
              <>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 mb-0 pe-lg-2">
                    <div className={`${classes.input} row d-flex align-items-center`}>
                      <p className="w-auto d-inline">Terms & Conditions</p>

                      <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => {
                              setSectionData({
                                title_en: null,
                                title_ar: null,
                                content_en: null,
                                content_ar: null,
                              });
                              setAddSupplierSectionForm(true);
                            }}
                            color={'#D1E6FF'}
                            borderColor={'#134074'}
                            fontColor={'#134074'}
                            icon={<Plus />}
                            className={'w-auto px-2 ms-auto text-end'}
                            type="normal"
                            text={'Add Section'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {supplierTerms.length > 0 ? (
                  supplierTerms.map((item, index) => (
                    <div key={index} className="row mt-0 px-0 mx-0">
                      <div className="col-lg-5 col-10 px-0">
                        <Section
                          checkBox={true}
                          onClick={() => {
                            let arr = [];
                            supplierTerms.map((item1, index1) => {
                              if (index === index1) {
                                arr.push({
                                  title_en: item1.title_en,
                                  title_ar: item1.title_ar,
                                  content_en: item1.content_en,
                                  content_ar: item1.content_ar,
                                  selected: !item1.selected,
                                });
                              } else
                                arr.push({
                                  title_en: item1.title_en,
                                  title_ar: item1.title_ar,
                                  content_en: item1.content_en,
                                  content_ar: item1.content_ar,
                                  selected: item1.selected,
                                });
                            });
                            setSupplierTerms(arr);
                            setDataErr((prev) => ({
                              ...prev,
                              supplierTerms: true,
                            }));
                          }}
                          checked={item.selected}
                          title={item.title_en}
                          text={item.content_en}
                        />
                      </div>
                      <div className="col-lg-2 col-2 justify-content-center">
                        <div className="row px-0 mx-0 mt-4 pt-2">
                          <div
                            onClick={() => {
                              setSectionData({ ...item });
                              setSelectedIndex(index);
                              setShowEditFormSupplier(true);
                            }}
                            className="w-auto mx-auto"
                          >
                            <Edit />
                          </div>
                        </div>
                      </div>
                      <div className="d-lg-none d-block col-1"></div>
                      <div className="col-lg-5 col-10  px-0  ms-lg-auto me-auto">
                        <Section title={item.title_ar} text={item.content_ar} />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-100 row justify-content-center  pb-4">
                    <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Terms Found.</div>
                  </div>
                )}
                {showError && !dataErr.supplierTerms > 0 && (
                  <div className="w-100 row justify-content-center">
                    <span className="errorMsg text-cenetr w-auto mx-auto">Provide Supplier Terms & Conditions.</span>
                  </div>
                )}
                <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                  {/* <div className="w-auto  ms-auto"> */}
                  <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Main Data');
                        }}
                        // icon={<Plus />}

                        className={'w-lg-auto  d-inline px-5   '}
                        type="reverse"
                        text={'Back'}
                      />
                    </div>
                  </div>
                  <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Customer Payment Terms');
                        }}
                        // icon={<Plus />}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-lg-auto  d-inline px-5  ms-auto '}
                        type="normal"
                        text={'Next'}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            )}
            {option === 'Customer Payment Terms' && (
              <>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 mb-0 pe-lg-2">
                    <div className={`${classes.input} row d-flex align-items-center`}>
                      <p className="w-auto d-inline">Terms & Conditions</p>

                      <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => {
                              setSectionData({
                                title_en: null,
                                title_ar: null,
                                content_en: null,
                                content_ar: null,
                              });
                              setAddCustomerSectionForm(true);
                            }}
                            color={'#D1E6FF'}
                            borderColor={'#134074'}
                            fontColor={'#134074'}
                            icon={<Plus />}
                            className={'w-auto px-2 ms-auto text-end'}
                            type="normal"
                            text={'Add Section'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {customerTerms.length > 0 ? (
                  customerTerms.map((item, index) => (
                    <div key={index} className="row mt-0 px-0 mx-0">
                      <div className="col-lg-5 col-10 px-0">
                        <Section
                          checkBox={true}
                          onClick={() => {
                            let arr = [];
                            customerTerms.map((item1, index1) => {
                              if (index === index1) {
                                arr.push({
                                  title_en: item1.title_en,
                                  title_ar: item1.title_ar,
                                  content_en: item1.content_en,
                                  content_ar: item1.content_ar,
                                  selected: !item1.selected,
                                });
                              } else
                                arr.push({
                                  title_en: item1.title_en,
                                  title_ar: item1.title_ar,
                                  content_en: item1.content_en,
                                  content_ar: item1.content_ar,
                                  selected: item1.selected,
                                });
                            });
                            setCustomerTerms(arr);
                            setDataErr((prev) => ({
                              ...prev,
                              customerTerms: true,
                            }));
                            // setChanged(!changed);
                          }}
                          checked={item.selected}
                          title={item.title_en}
                          text={item.content_en}
                        />
                      </div>
                      <div className="col-lg-2 col-2 justify-content-center">
                        <div className="row px-0 mx-0 mt-4 pt-2">
                          <div
                            onClick={() => {
                              setSectionData({ ...item });
                              setSelectedIndex(index);
                              setShowEditFormCustomer(true);
                            }}
                            className="w-auto mx-auto"
                          >
                            <Edit />
                          </div>
                        </div>
                      </div>
                      <div className="d-lg-none d-block col-1"></div>
                      <div className="col-lg-5 col-10  px-0  ms-lg-auto me-auto">
                        <Section title={item.title_ar} text={item.content_ar} />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-100 row justify-content-center  pb-4">
                    <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Terms Found.</div>
                  </div>
                )}

                {showError && !dataErr.customerTerms > 0 && (
                  <div className="w-100 row justify-content-center">
                    <span className="errorMsg text-cenetr w-auto mx-auto">Provide Customer Terms & Conditions.</span>
                  </div>
                )}

                <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                  {/* <div className="w-auto  ms-auto"> */}
                  <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Supplier Payment Terms');
                        }}
                        // icon={<Plus />}

                        className={'w-lg-auto  d-inline px-5   '}
                        type="reverse"
                        text={'Back'}
                      />
                    </div>
                  </div>
                  <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Terms');
                        }}
                        // icon={<Plus />}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-lg-auto  d-inline px-5  ms-auto '}
                        type="normal"
                        text={'Next'}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            )}
            {option === 'Terms' && (
              <>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 mb-0 pe-lg-2">
                    <div className={`${classes.input} row d-flex align-items-center`}>
                      <p className="w-auto d-inline">Voucher Usage Terms</p>

                      <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => {
                              setSectionData({
                                title_en: null,
                                title_ar: null,
                                content_en: null,
                                content_ar: null,
                              });
                              setAddAdditionalTermsSectionForm(true);
                            }}
                            color={'#D1E6FF'}
                            borderColor={'#134074'}
                            fontColor={'#134074'}
                            icon={<Plus />}
                            className={'w-auto px-2 ms-auto text-end'}
                            type="normal"
                            text={'Add Section'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {additionalTerms.length > 0 ? (
                  additionalTerms.map((item, index) => (
                    <div key={index} className="row mt-0 px-0 mx-0">
                      <div className="col-lg-5 col-10 px-0">
                        <Section
                          checkBox={true}
                          onClick={() => {
                            let arr = [];
                            additionalTerms.map((item1, index1) => {
                              if (index === index1) {
                                arr.push({
                                  title_en: item1.title_en,
                                  title_ar: item1.title_ar,
                                  content_en: item1.content_en,
                                  content_ar: item1.content_ar,
                                  selected: !item1.selected,
                                });
                              } else
                                arr.push({
                                  title_en: item1.title_en,
                                  title_ar: item1.title_ar,
                                  content_en: item1.content_en,
                                  content_ar: item1.content_ar,
                                  selected: item1.selected,
                                });
                            });
                            setAdditionalTerms(arr);
                            setDataErr((prev) => ({
                              ...prev,
                              additionalTerms: true,
                            }));
                            // setChanged(!changed);
                          }}
                          checked={item.selected}
                          title={item.title_en}
                          text={item.content_en}
                        />
                      </div>
                      <div className="col-lg-2 col-2 justify-content-center">
                        <div className="row px-0 mx-0 mt-4 pt-2">
                          <div
                            onClick={() => {
                              setSectionData({ ...item });
                              setSelectedIndex(index);
                              setShowEditAdditionalTermsForm(true);
                            }}
                            className="w-auto mx-auto"
                          >
                            <Edit />
                          </div>
                        </div>
                      </div>
                      <div className="d-lg-none d-block col-1"></div>
                      <div className="col-lg-5 col-10  px-0  ms-lg-auto me-auto">
                        <Section title={item.title_ar} text={item.content_ar} />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-100 row justify-content-center  pb-4">
                    <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Terms Found.</div>
                  </div>
                )}

                {/* {showError && !dataErr.additionalTerms > 0 && (
                  <div className="w-100 row justify-content-center">
                    <span className="errorMsg text-cenetr w-auto mx-auto">
                      Provide Voucher Terms & Conditions.
                    </span>
                  </div>
                )} */}

                <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                  {/* <div className="w-auto  ms-auto"> */}
                  <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Customer Payment Terms');
                        }}
                        // icon={<Plus />}

                        className={'w-lg-auto  d-inline px-5   '}
                        type="reverse"
                        text={'Back'}
                      />
                    </div>
                  </div>
                  <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Supplier');
                        }}
                        // icon={<Plus />}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-lg-auto  d-inline px-5  ms-auto '}
                        type="normal"
                        text={'Next'}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            )}

            {option === 'Supplier' && (
              <>
                {showError && selectedSuppliers.length === 0 && (
                  <div className="text-center mb-3">
                    <span className="errorMsg text-cenetr">Provide Supplier you Want to send the Voucher to him .</span>
                  </div>
                )}
                {selectedSuppliers.length > 0 && (
                  <div div className="row px-0 mx-0">
                    <div className={classes['input']}>
                      <p>Choosen Supplier</p>
                    </div>

                    <div className="col-xl-3 col-lg-6 h-100">
                      <ManagerCard
                        delete={true}
                        profilePicture={selectedSuppliers[0].pic}
                        type="supplier"
                        onRemove={() => {
                          let arr = [];
                          selectedSuppliers.map((i) => {
                            if (i._id !== selectedSuppliers[0]._id) {
                              arr.push(i);
                            }
                          });
                          setSelectedSuppliers(arr);
                          setLoading(true);
                        }}
                        options={[
                          {
                            title: 'Supplier Name',
                            value: selectedSuppliers[0].name_en,
                          },
                          {
                            title: 'Category',
                            value: selectedSuppliers[0].categories,
                            list: true,
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
                <div className="row px-0 mx-0 pt-4">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p>Choose Suppliers</p>
                      <SearchInput onChange={(e) => setSearchValue(e.target.value)} placeholder="Search for Suppliers" />

                      <div className={classes['supplier-result']}>
                        {suppliers.map((item, index) => (
                          <div key={index} className={`row px-0 mx-0 d-flex align-items-center ${classes['supplier-option']}`}>
                            <div className="w-auto d-inline">
                              <Img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${item.pic}`} />
                            </div>
                            <div className="w-auto d-inline">
                              <div className={`row px-0 mx-0 ${classes['supplier-name']}`}>{item.name_en}</div>
                              <div className={`row px-0 mx-0 ${classes['supplier-category']}`}>
                                {item.categories.map((item1, index1) => (
                                  <span key={index1} className="w-auto px-0 d-inline pe-lg-2">
                                    {item1}
                                  </span>
                                ))}
                              </div>
                            </div>
                            {selectedSuppliers[0]?._id !== item._id && (
                              <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                                <div className={`  mb-pb-0`}>
                                  <Button
                                    onClick={() => setSelectedSuppliers([item])}
                                    color={'#D1E6FF'}
                                    borderColor={'#134074'}
                                    fontColor={'#134074'}
                                    className={'w-auto px-2  ms-auto text-end'}
                                    type="normal"
                                    text={'Choose Supplier'}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                  {/* <div className="w-auto  ms-auto"> */}
                  <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Customer Payment Terms');
                        }}
                        // icon={<Plus />}

                        className={'w-lg-auto  d-inline px-5   '}
                        type="reverse"
                        text={'Back'}
                      />
                    </div>
                  </div>
                  <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      {loadingButton ? (
                        <div className="text-center">
                          <span className="spinner-border spinner-border-sm"></span>
                        </div>
                      ) : (
                        <Button
                          onClick={() => {
                            setLoadingButton(true);
                            handleSubmit();
                          }}
                          // icon={<Plus />}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          className={'w-lg-auto  d-inline px-5  ms-auto '}
                          type="normal"
                          text={'Send Request'}
                        />
                      )}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
//Done
