import ReactDOM from 'react-dom';
import { Checkbox } from '@mui/joy';
import { Avatar, Skeleton } from '@mui/material';
import classes from './OriginalDeals.module.scss';
import React, { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Swiper, SwiperSlide } from 'swiper/react';
import Edit from '../../components/Reusable/SVG/Edit';
import Plus from '../../components/Reusable/SVG/Plus';
import Img from '../../components/Reusable/Common/Img';
import { Pagination, Navigation } from 'swiper/modules';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import Recycle1 from '../../components/Reusable/SVG/Recycle1';
import Button from '../../components/Reusable/UI/Button/Button';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import Table from '../../components/Reusable/Common/Table/Table';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import DealsAvtiveL from '../../components/Reusable/SVG/DealsAvtiveL';
import Section from '../../components/Reusable/Common/Section/Section';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import LoadingTable from '../../components/Reusable/Common/Table/LoadingTable';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import TextAreaGroup from '../../components/Reusable/UI/InputGroup/TextAreaGroup';
import AddSection from '../../components/Reusable/Common/AddMemberCard/AddSection';
import { originalDealsSupplierService } from '../../services/OriginalDealsSuppliers';
import { originalDealsEshtreanaService } from '../../services/OriginalDealsEshtreana';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import TermsSections from '../../components/Reusable/Common/TermsSections/TermsSections';
import DownloadPopUp from '../../components/Reusable/Common/DownloadPopUp/DownloadPopUp';
import UploadImageGroup2 from '../../components/Reusable/UI/InputGroup/UploadImageGroup2';
import ShowAreaDistrict from '../../components/Reusable/Common/AddMemberCard/ShowAreaDistrict';
import StoreLocationCard from '../../components/Reusable/Common/StoreLocationCard/StoreLocationCard';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
import Recycle from '../../components/Reusable/SVG/Recycle';
export default function SupplierDealsDetails() {
  //----------------------Declarations---------------------------------
  let timeinterval;
  const dispatch = useDispatch();
  const history = useNavigate();
  const { type, id } = useParams();
  const [doc, setDoc] = useState([]);
  const [data, setData] = useState(null);
  const [branches, setBranches] = useState([]);
  const [hasSize, setHasSize] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageOrder, setPageOrder] = useState(1);
  const [loading1, setLoading1] = useState(true);
  const [hasColor, setHasColor] = useState(true);
  const [showError, setShowError] = useState(true);
  const [dealOrders, setDealOrders] = useState([]);
  const [option, setOption] = useState('Main Data');
  const [colorChange, setColorChange] = useState(0);
  const [wholeOrders, setWholeOrders] = useState([]);
  const [showError1, setShowError1] = useState(false);
  const [wholeOrders1, setWholeOrders1] = useState([]);
  const [sectionData, setSectionData] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [leftQuantity, setLeftQuantity] = useState([0]);
  const [otherPolicies, setOtherPolicies] = useState([]);
  const [deliveryTerms, setDeliveryTerms] = useState([]);
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [showDownload, setShowDownload] = useState(false);
  const [loadingColor, setLoadingColor] = useState(false);
  const [showDistrict, setShowDistrict] = useState(false);
  const [quantitesData, setQuantitiesData] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showIndexArea, setShowIndexArea] = useState(null);
  const [totalPagesOrder, setTotalPagesOrder] = useState(1);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [ValidColorQuantity, setValidColorQuantity] = useState([false]);
  const [showAddFormDelivery, setShowAddFormDelivery] = useState(false);
  const [showEditFormDelivery, setShowEditFormDelivery] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationAgreement, setShowConfirmationAgreement] = useState(false);
  const [showAddFormOtherPolicies, setShowAddFormOtherPolicies] = useState(false);
  const [showEditFormOtherPolicies, setShowEditFormOtherPolicies] = useState(false);
  const [ValidColorSizeQuantity, setValidColorSizeQuantity] = useState([[true]]);
  const [responseData, setResponseData] = useState({
    pricing: [],
    expireDate: null,
    differeniateYourDeal: null,
    acceptSupplierPaymentTerms: true,
    acceptCustomerPaymentTerms: true,
    deliveryFees: null,
  });
  const [responseDataErr, setResponseDataErr] = useState({
    pricing: [],
    expireDate: false,
    differeniateYourDeal: false,
    deliveryFees: false,
  });
  //----------------------Functions---------------------------------

  let checkValid = (value) => {
    var isANumber = isNaN(value) === false;
    if (!isANumber || parseFloat(value) < 0 || value.startsWith('-') || value === '' || value.startsWith('+')) return false;
    else return true;
  };
  let sendPO = async () => {
    let fd = new FormData();
    fd.append('doc', doc[0], doc[0].name);
    let response = await originalDealsEshtreanaService.sendPoInvoice(id, fd);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setDoc([]);
      setData((prev) => ({
        ...prev,
        purchasingOrder: doc[0],
      }));
      setShowConfirmationAgreement(true);
      setTimeout(() => {
        setShowConfirmationAgreement(false);
        setLoading1(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading1(false);
  };

  let handleAddSection = (data) => {
    let arr = [];
    if (option === 'Delivery') {
      if (sectionData && sectionData.title_en) {
        let arr = [];
        deliveryTerms.map((item1, index1) => {
          if (selectedIndex === index1) {
            arr.push({
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: sectionData.selected,
            });
          } else
            arr.push({
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: item1.selected,
            });
        });
        setDeliveryTerms(arr);
      } else {
        setDeliveryTerms((prev) => [
          ...prev,
          {
            title_en: data.titleEN,
            title_ar: data.titleAR,
            content_en: data.descriptionEN,
            content_ar: data.descriptionAR,
            selected: true,
          },
        ]);
      }
    } else {
      if (showEditFormOtherPolicies) {
        otherPolicies.map((item, index) => {
          if (selectedIndex !== index) {
            arr.push(item);
          } else {
            arr.push({
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: true,
            });
          }
          setOtherPolicies(arr);
        });
      } else {
        setOtherPolicies((prev) => [
          ...prev,
          {
            title_en: data.titleEN,
            title_ar: data.titleAR,
            content_en: data.descriptionEN,
            content_ar: data.descriptionAR,
            selected: true,
          },
        ]);
      }
    }
  };

  let getDealDetails = async () => {
    let response =
      type === 'request' ? await originalDealsEshtreanaService.getOfferDetails(id) : await originalDealsEshtreanaService.getDealDetails(id);

    if (type === 'request') {
      setHasColor(response.data.deal.product.variants.includes('Colors') || response.data.deal.product.variants.includes('colors'));
      setHasSize(response.data.deal.product.variants.includes('sizes') || response.data.deal.product.variants.includes('Sizes'));
      let arr = [];
      let arr1 = [];
      let arr2 = [];
      setResponseDataErr({
        pricing: response.data.deal.quantities,
        expireDate: false,
        differeniateYourDeal: false,
        deliveryFees: false,
      });

      if (response.data.deal.product.variants.includes('Colors') || response.data.deal.product.variants.includes('colors')) {
        response.data.deal.quantities.map((item) => {
          arr.push([
            {
              color: '#ffffff',
              totalQuantity: null,
              sizes: [{ title: null, quantity: null }],
            },
          ]);
          arr1.push([false]);
          arr2.push([true]);
        });
      } else {
        response.data.deal.quantities.map((item, index) => {
          arr.push([
            {
              title: 'No Color',
              totalQuantity: parseInt(item),
              sizes: [{ title: null, quantity: null }],
            },
          ]);
          arr1.push([false]);
          arr2.push([true]);
        });
      }
      setQuantitiesData(arr);
      setValidColorQuantity(arr1);
      setValidColorSizeQuantity(arr2);
    }
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setData(response.data.deal);
      setLoading1(false);
      console.log(response);
      if (type !== 'request') {
        if (response.data.deal.deliveryResponsibility === 'Pick From supplier location') setBranches(response.data.deal.branches);
      }
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
      setLoading1(false);
    }
  };

  let handleSendResponse = async () => {
    setShowError1(true);
    let Valid = true;
    data.quantities.map((item, index) => {
      quantitesData[index] &&
        quantitesData[index].map((i, ind) => {
          hasSize &&
            i.sizes.map((ii, ind2) => {
              if (!ValidColorSizeQuantity[index][ind]) Valid = false;
            });
        });
      if (!ValidColorQuantity[index] || !responseData.pricing[index]) {
        Valid = false;
      }
    });

    let priceValid = true;
    data.quantities.map((item, index) => {
      if (!responseDataErr[index]) priceValid = false;
    });
    //console.log(data);
    if (
      Valid &&
      priceValid &&
      (((data.product.variants.includes('ExpireDate') || data.product.variants.includes('expireDate')) && responseData.expireDate) ||
        (!data.product.variants.includes('ExpireDate') && !data.product.variants.includes('expireDate'))) &&
      responseData.differeniateYourDeal &&
      responseData.acceptSupplierPaymentTerms &&
      responseData.acceptCustomerPaymentTerms &&
      (data.deliveryResponsibility === 'Eshtarena' ||
        (data.deliveryResponsibility === 'Supplier' && deliveryTerms.length > 0 && responseDataErr.deliveryFees) ||
        (data.deliveryResponsibility === 'Pick From supplier location' && selectedBranches.length > 0))
    ) {
      let finalQuantities = [];
      let sizes = [];
      if (hasColor || hasSize) {
        data.quantities.map((item, index) => {
          let obj = {};
          obj = { quantity: item, price: responseData.pricing[index] };
          let colors = [];
          quantitesData[index].map((item1, index1) => {
            sizes = [];
            item1.sizes.map((ii, ind) => {
              sizes.push({ title: ii.title, quantity: parseInt(ii.quantity) });
            });

            let obj1 = {};
            obj1.title = hasColor ? item1.color : 'No Color';
            obj1.quantity = parseInt(item1.totalQuantity);
            if (hasSize) obj1.size = sizes;
            colors.push({
              ...obj1,
            });
          });
          if (hasColor) obj['color'] = colors;
          else if (!hasColor && hasSize) {
            obj['size'] = sizes;
          }
          finalQuantities.push(obj);
        });
      } else {
        data.quantities.map((item, index) => {
          let obj = {};
          obj = { quantity: parseInt(item), price: parseInt(responseData.pricing[index]) };

          finalQuantities.push(obj);
        });
      }
      let finalDeliveryTerms = [];
      let finalOtherPolicies = [];
      otherPolicies.map((item) => {
        if (item.selected) {
          finalOtherPolicies.push({
            title_en: item.title_en,
            title_ar: item.title_ar,
            desc_en: item.content_en,
            desc_ar: item.content_ar,
          });
        }
      });
      deliveryTerms.map((item) => {
        if (item.selected) {
          finalDeliveryTerms.push({
            title_en: item.title_en,
            title_ar: item.title_ar,
            desc_en: item.content_en,
            desc_ar: item.content_ar,
          });
        }
      });

      let finalBranches = [];
      selectedBranches.map((item) => {
        finalBranches.push({ branch: item });
      });
      let res = {};
      if (otherPolicies.length > 0) res.otherPolices = finalOtherPolicies;
      res.quantities = finalQuantities;
      if (data.deliveryResponsibility === 'Pick From supplier location') res.branches = finalBranches;
      if (data.deliveryResponsibility === 'Supplier') {
        res.deliveryTerms = finalDeliveryTerms;
        res.deliveryFees = responseData.deliveryFees;
      }
      if (data.product.variants.includes('ExpireDate') || data.product.variants.includes('expireDate')) res.expireDate = responseData.expireDate;
      res.differentiateYourOffer = responseData.differeniateYourDeal;
      //console.log(res);

      let response = await originalDealsSupplierService.createDealResponse(res, data._id);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          history('/supplier/original-deal', { replace: true });
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
      }
    } else {
      setServerErrorMsg(`There a Missing / Incorrect Data are Required Must be Filled , Please Check Your Data .`);
      setServerError(true);
      setTimeout(() => {
        setServerError(false);
      }, 2000);
    }
  };
  let getDealOrders = async () => {
    let response = await originalDealsEshtreanaService.getDealOrders(id, pageOrder, 5, false);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      let arr = [];
      response.data.orders.map((item) => {
        arr.push([
          item.uuid,
          item.customer.customerID,
          item.customer.customerType,
          item.customer.customerName,
          item.customer.customerPhone || '-',
          item.quantity,
          item.status,
        ]);
      });
      setDealOrders(arr);
      setTotalPagesOrder(response.data.totalPages);
      setWholeOrders(response.data.orders);
      setWholeOrders1(response.data);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoadingOrder(false);
  };
  useEffect(() => {
    // console.log(branches);
  }, [branches]);
  //TODO:helper
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) + 1 * 22 * 60 * 60 * 1000 - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  //TODO:helper
  function initializeClock(endtime) {
    const clock = document.getElementsByClassName(`clock`);
    timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      clock[0].innerHTML =
        t.total > 0
          ? (t.days > 0 ? t.days : 0) +
            'D: ' +
            (t.hours > 0 ? t.hours : 0) +
            'H: ' +
            (t.minutes > 0 ? t.minutes : 0) +
            'M: ' +
            (t.seconds > 0 ? t.seconds : 0) +
            'S '
          : 'Finished';
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  }
  let handleAddColor = () => {
    let temp = quantitesData;
    temp[selectedQuantity] = [
      ...temp[selectedQuantity],
      {
        color: '#ffffff',
        totalQuantity: null,
        sizes: [{}],
      },
    ];
    let temp1 = ValidColorSizeQuantity;
    temp1[selectedQuantity] = [...temp1[selectedQuantity], false];
    setValidColorSizeQuantity(temp1);
    setQuantitiesData(temp);
    setLoadingColor(true);
  };
  //----------------------Use Effects---------------------------------
  useEffect(() => {
    if (type === 'request') mainDataHelper.getDeliveryTerms(setServerError, setServerErrorMsg, setDeliveryTerms);
  }, []);

  useEffect(() => {
    showIndexArea !== null && setShowDistrict(true);
  }, [showIndexArea]);
  useEffect(() => {
    if (data && data.quantites && data.quantities.length > 0) {
      let total = 0;
      quantitesData[selectedQuantity].map((item) => {
        total += Number(item.totalQuantity) ? parseInt(item.totalQuantity) : 0;
      });

      let arr = leftQuantity;
      arr[selectedQuantity] = data.quantities[selectedQuantity] - total;
      setLeftQuantity(arr);
      loading && setLoading(false);
      loadingColor && setLoadingColor(false);
    }
  }, [loading]);
  useEffect(() => {
    if (data && data.quantities) {
      let total = 0;
      quantitesData[selectedQuantity].map((item) => {
        total += Number(item.totalQuantity) ? parseInt(item.totalQuantity) : 0;
      });

      let arr = leftQuantity;
      arr[selectedQuantity] = data.quantities[selectedQuantity] - total;
      setLeftQuantity(arr);
      loading && setLoading(false);
    }
    setLoadingColor(false);
  }, [loadingColor, quantitesData]);

  useEffect(() => {
    if (type === 'onGoing') {
      if (data && data.endDate) {
        if (option !== 'Main Data') {
          clearInterval(timeinterval);
        } else {
          initializeClock(new Date(data.endDate));
        }
      }

      return () => {
        clearInterval(timeinterval);
      };
    }
  }, [data?.endDate, option]);

  useEffect(() => {
    type === 'request' && mainDataHelper.getSupplierBranches(setServerError, setServerErrorMsg, setBranches, setLoading);
    getDealDetails();
    getDealOrders();
    setFirstLoad(false);
  }, [type]);

  useEffect(() => {
    if (doc.length > 0) {
      setLoading1(true);
      sendPO();
    }
  }, [doc]);
  useEffect(() => {
    if (!firstLoad) {
      setLoadingOrder(true);
      getDealOrders();
    }
  }, [pageOrder]);
  return (
    <div className={`w-100 ${classes['original-deals']}`}>
      {showDownload &&
        ReactDOM.createPortal(<div className={classes.backdrop} onClick={() => setShowDownload(false)}></div>, document.getElementById('overlays'))}
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup message1={'Your Response Sent Successfully'} />
        </Modal>
      )}
      {showConfirmationAgreement && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup message1={'Your Agreement File Sent Successfully'} />
        </Modal>
      )}
      {(showAddFormOtherPolicies || showEditFormOtherPolicies || showAddFormDelivery || showEditFormDelivery) && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            showAddFormOtherPolicies && setShowAddFormOtherPolicies(false);
            showEditFormOtherPolicies && setShowEditFormOtherPolicies(false);
            showAddFormDelivery && setShowAddFormDelivery(false);
            showEditFormDelivery && setShowEditFormDelivery(false);
          }}
        >
          <AddSection
            data={(showAddFormOtherPolicies || showEditFormOtherPolicies || showAddFormDelivery || showEditFormDelivery) && sectionData}
            handleSubmit={(data) => handleAddSection(data)}
            add={showAddFormOtherPolicies || showAddFormDelivery}
            edit={showEditFormOtherPolicies || showEditFormDelivery}
            setShowForm={() => {
              showAddFormOtherPolicies && setShowAddFormOtherPolicies(false);

              showEditFormOtherPolicies && setShowEditFormOtherPolicies(false);
              showAddFormDelivery && setShowAddFormDelivery(false);
              showEditFormDelivery && setShowEditFormDelivery(false);
            }}
          />
        </Modal>
      )}

      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showDistrict && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowDistrict(false);
            setShowIndexArea(null);
          }}
        >
          <ShowAreaDistrict
            setShowForm={() => {
              setShowDistrict(false);
              setShowIndexArea(null);
            }}
            area={data.areas[showIndexArea]}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle text="Deal Details" icon={<DealsAvtiveL />} />
        </div>
        {loading1 ? (
          <div className={classes['main-section']}>
            <div className={`row px-0 mx-0  mb-3  ${classes.options} mb-0`}>
              <div className="row px-0 mx-0 mb-2">
                {[0, 1, 2, 3].map((index) => (
                  <div key={index} className="col-xl-2 col-lg-4">
                    <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                  </div>
                ))}
              </div>
            </div>
            <div className="row px-0 mx-0">
              <div className="col-lg-8 order-lg-1 order-2 px-0 mx-0">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                  <div key={index} className="row mx-0 px-0">
                    <div className={`w-auto d-inline px-0 ${classes.info}`}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-4 order-lg-2 order-1 p-5 pt-0 mx-0">
                <div className={`${classes.info}`}>
                  <Skeleton variant="rounded" sx={{ height: '223px', width: '284px' }} />
                </div>
              </div>
            </div>
            <>
              {[0, 1, 2].map((item, index) => (
                <div key={index} className={`row w-100 px-0 mx-0 mt-2 `}>
                  {[0, 1].map((index) => (
                    <div key={index} className="col-lg-6 col-12 px-0">
                      <div dir="auto" className={classes['single-item']}>
                        <p>
                          <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                        </p>
                        <span>
                          <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </>
          </div>
        ) : (
          <section className={`${classes['main-section']}`}>
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={
                  (type === 'onGoing' && ['Main Data', 'Additional Info', 'Terms and Condition', 'Other Policies', 'Delivery', 'Customers']) ||
                  (type === 'history' && [
                    'Main Data',
                    'Additional Info',
                    'Terms and Condition',
                    'Other Policies',
                    'Delivery',
                    'Customers',
                    'Agreements',
                  ]) ||
                  (type === 'scheduled' && ['Main Data', 'Terms and Condition', 'Special Specification', 'Other Policies', 'Delivery']) ||
                  (type === 'request' && ['Main Data', 'Additional Info', 'Terms and Condition', 'Payment Methods', 'Other Policies', 'Delivery']) ||
                  []
                }
              />
            </div>
            {option === 'Main Data' && (
              <>
                <div className="row  px-0 mx-0">
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-6 col-12 px-0">
                      {type !== 'request' && (
                        <div className={`${classes['single-item']}  row px-0 mx-0 row-cols-2 align-items-center `}>
                          <p style={{ fontSize: '20px', width: '200px' }} className="col">
                            Deal Code :
                          </p>
                          <span className="col d-flex flex-grow-1 justify-content-center">{data.uuid}</span>
                        </div>
                      )}
                    </div>
                    {type !== 'scheduled' && type !== 'request' && type !== 'history' && (
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row align-items-center px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px', fontSize: '20px' }} className="col">
                            Time Left :
                          </p>
                          <span className="clock"></span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-9 px-0">
                    {type !== 'request' && (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                            <p style={{ width: '200px' }} className="col">
                              Deal Name :
                            </p>
                            <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.title_en}</span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                            <p style={{ width: '200px' }} className="col">
                              اسم العرض :
                            </p>
                            <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.title_ar}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Product Name :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.product.name_en}</span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Model :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.product.model}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            اسم المنتج :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.product.name_ar}</span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Country of Origin :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.product.country_en}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Product Code :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.product.barcode}</span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            بلد المنشأ :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.product.country_ar}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Category :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">
                            {data.product.category.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Factory Name :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.product.factory_en}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Sub Category :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">
                            {data.product.subcategory.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            اسم المصنع :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-end">{data.product.factory_ar}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Market Price :
                          </p>
                          <span className="col  justify-content-md-start justify-content-end d-flex flex-grow-1">{data.product.price}</span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Batch Number :
                          </p>
                          <span className="col  justify-content-md-start justify-content-end d-flex flex-grow-1">{data.product.patchNumber}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Unit :
                          </p>
                          <span className=" justify-content-md-start justify-content-end d-flex flex-grow-1">{data.unit}</span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Customer Type :
                          </p>
                          <span className=" justify-content-md-start justify-content-end d-flex flex-grow-1">{data.customerType}</span>
                        </div>
                      </div>
                    </div>

                    {type === 'scheduled' && (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Starts At:</p>
                            <span>{new Date(data.startDate).toDateString()}</span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Ends At:</p>
                            <span>{new Date(data.endDate).toLocaleDateString()}</span>
                          </div>
                        </div>
                      </div>
                    )}

                    {data.deposite ? (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={`${classes['single-item']} ${classes['green']}`}>
                            <p>Deposite:</p>
                            <span>{data.deposite} SAR</span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-3 px-0">
                    <div className={`${classes.info}`}>
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                          clickable: true,
                        }}
                        loop={false}
                        loopFillGroupWithBlank={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper    align-items-center d-flex p-0 "
                      >
                        {data.product.productImgs.map((item, index) => (
                          <SwiperSlide key={index}>
                            <Img
                              style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                              }}
                              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/product/${item}`}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-xl-6 pe-xl-2 px-0">
                    <Section title="Description" text={data.product.description_en} />
                  </div>

                  <div className="col-xl-6 ps-xl-2 px-0  ms-auto">
                    <Section title="الوصف" text={data.product.description_ar} />
                  </div>
                </div>
                <div className="row px-0 mx-0 mb-3 ">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p className="mb-4">Areas</p>

                      {data.areas.length > 0 ? (
                        data.areas.map((item, index) => (
                          <span
                            onClick={() => {
                              item.district.length > 0 && setShowIndexArea(index);
                            }}
                            key={index}
                            className={`${classes['area']} w-auto  d-inline`}
                          >
                            {item.city.city_en}
                          </span>
                        ))
                      ) : (
                        <span
                          // onClick={() => setShowIndexArea(index)}
                          // key={index}
                          className={`${classes['area']} w-auto  d-inline`}
                        >
                          All KSA
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {type === 'history' && (
                  <div className="row px-0 mx-0">
                    <div className="col-12  ">
                      <div className={`${classes['input']} pb-0 mb-0  row`}>
                        <p style={{ fontSize: '20px' }} className="px-0">
                          Pricing
                        </p>
                        <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                          <div className="col-lg-6 col-12 px-0">
                            <div className={classes['single-item']}>
                              <p>Quantity</p>
                              <span className="d-block text-black">{data.quantity}</span>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12 px-0">
                            <div className={classes['single-item']}>
                              <p>Unit Price</p>
                              <span className="d-block text-black">{data.price} SAR</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {type === 'request' && (
                  <div className="row px-0 mx-0">
                    <div className="col-12 px-0 ">
                      <div className={`${classes['input']} pb-0 mb-0 px-0 row`}>
                        <p>Pricing Table</p>
                        <div className="col-lg-6">
                          <div className={classes.input}>
                            <p>Quantity</p>
                            <span className="text-black">This the total quantity we want from you</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className={classes.input}>
                            <p>Unit Price</p>
                            <span className="text-black">here you should provide us with the price of unit price not all the quantity </span>
                          </div>
                        </div>
                      </div>
                      {data.quantities.map((item, index) => (
                        <div className="row px-0 mx-0 pt-0 mt-0">
                          <div className="col-lg-6 pe-lg-2 px-0">
                            <InputGroup default={item} disable={true} linkText={{ text: 'Unit', fn: () => {} }} value={'Quantity'} />
                          </div>
                          <div className="col-lg-6 ps-lg-2 px-0">
                            <InputGroup
                              default={responseData.pricing[index]}
                              error={showError1 && (!responseData.pricing[index] || !responseDataErr.pricing[index])}
                              errorMsg={responseData.pricing[index] ? 'Provide a Valid Quantity Price' : 'Provide Valid Quantity Price'}
                              onChange={(e) => {
                                let arr = responseData.pricing;
                                arr[index] = e.target.value;
                                let v = checkValid(e.target.value);
                                let temp = responseDataErr;
                                temp[index] = v;
                                setResponseDataErr((prev) => ({
                                  ...prev,
                                  pricing: temp,
                                }));
                                setResponseData((prev) => ({
                                  ...prev,
                                  pricing: arr,
                                }));
                              }}
                              value={'Unit Price'}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {type === 'request' && (
                  <div className={`row mx-0 px-0  mb-pb-0 ${classes['input']}`}>
                    <div className="w-lg-auto d-inline  ms-auto px-0 ps-2 mb-pb-0">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            type === 'request' && setOption('Additional Info');
                          }}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          className={'w-lg-auto d-inline px-5  ms-auto '}
                          type="normal"
                          text={'Next'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {option === 'Delivery' && (
              <>
                {(data.deliveryResponsibility === 'Pick From supplier location' && (
                  <div className="">
                    {type === 'request' && (
                      <div className="row px-0 mx-0">
                        <div className="col-12 px-0 pe-2">
                          <div className={`${classes.input} row d-flex align-items-center`}>
                            <p className="w-auto d-inline">Pick From The Supplier</p>
                          </div>
                        </div>
                      </div>
                    )}

                    {showError1 && selectedBranches?.length === 0 && (
                      <div className="text-center mb-2">
                        <span className="errorMsg text-cenetr">Provide a Delivery Branches</span>
                      </div>
                    )}
                    {branches &&
                      branches?.length >= 0 &&
                      branches.map((item, index) => (
                        <div key={index} className="row px-0 d-flex justify-content-center align-items-cenetr">
                          {type === 'request' && (
                            <div
                              onClick={() => {
                                if (!selectedBranches.includes(item._id)) setSelectedBranches((prev) => [...prev, item._id]);
                                else {
                                  let arr = [];
                                  selectedBranches.map((item1) => {
                                    if (item._id !== item1) arr.push(item1);
                                  });
                                  setSelectedBranches(arr);
                                }
                              }}
                              className=" col-1 align-items-center h-auto d-flex mb-4 justify-content-center"
                            >
                              <Checkbox checked={selectedBranches.includes(item._id)} />
                            </div>
                          )}

                          <div className={` ${type === 'request' ? 'col-11' : 'col-12'}`}>
                            <StoreLocationCard item={item} />
                          </div>
                        </div>
                      ))}
                    {branches?.length === 0 && (
                      <div className="w-100 row justify-content-center pt-5 pb-4">
                        <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                          There is No Branchs attached to your Account. Please Edit your account and Add Your Branches
                        </div>
                      </div>
                    )}
                  </div>
                )) ||
                  (data.deliveryResponsibility === 'Eshtarena' && (
                    <>
                      <TermsSections title="Eshtarena Delivery Terms" terms={data.deliveryTerms} border={true} />
                      {/* {showError && deliveryTerms.length > 0 && (
                        <div className="text-center mb-3">
                          <span className="errorMsg text-cenetr">
                            Provide Delivery Terms & Conditions.
                          </span>
                        </div>
                      )} */}
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-md-6 col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Delivery Fees :</p>
                            <span>{data.deliveryFees} SAR</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )) ||
                  (data.deliveryResponsibility === 'Supplier' && (
                    <>
                      <div className="row px-0 mx-0">
                        <div className="col-12 px-0 pe-2">
                          <div className={`${classes.input} row d-flex align-items-center`}>
                            <p className="w-auto d-inline">Supplier delivery terms</p>

                            {type === 'request' && (
                              <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                                <div className={`  mb-pb-0`}>
                                  <Button
                                    onClick={() => {
                                      setSectionData(null);
                                      setShowAddFormDelivery(true);
                                    }}
                                    icon={<Plus />}
                                    color={'#D1E6FF'}
                                    borderColor={'#134074'}
                                    fontColor={'#134074'}
                                    className={'w-auto px-2 ms-auto text-end'}
                                    type="normal"
                                    text={'Add Section'}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {type === 'request' ? (
                        deliveryTerms.length > 0 ? (
                          deliveryTerms.map((item, index) => (
                            <div key={index} className="row mt-0 px-0 mx-0">
                              <div className="col-lg-5 col-10 px-0">
                                <Section title={item.title_en} text={item.content_en} />
                              </div>
                              <div className="col-lg-2 col-2 justify-content-center">
                                <div className="row px-0 mx-0 mt-4 pt-2">
                                  <div
                                    onClick={() => {
                                      setSectionData({ ...item });
                                      setSelectedIndex(index);
                                      setShowEditFormDelivery(true);
                                    }}
                                    className="w-auto mx-auto"
                                  >
                                    <Edit />
                                  </div>
                                </div>
                              </div>
                              <div className="d-lg-none d-block col-1"></div>
                              <div className="col-lg-5 col-10  px-0  ms-lg-auto me-auto">
                                <Section title={item.title_ar} text={item.content_ar} />
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="w-100 row justify-content-center pt-5 pb-4">
                            <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Delivery Terms Added yet.</div>
                          </div>
                        )
                      ) : (
                        <>
                          <TermsSections terms={data.deliveryTerms} border={true} />
                          <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                            <div className="col-md-6 col-12 px-0">
                              <div className={classes['single-item']}>
                                <p>Delivery Fees :</p>
                                <span>{data.deliveryFees} SAR</span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {type === 'request' && showError && deliveryTerms.length > 0 && (
                        <div className="text-center mb-3">
                          <span className="errorMsg text-cenetr">Provide Delivery Terms & Conditions.</span>
                        </div>
                      )}

                      {type !== 'history' && (
                        <div className="row px-0 mx-0">
                          <div className="col-6 px-0 ">
                            <div className={classes.input}>
                              <InputGroup
                                linkText={{ text: 'SAR', fn: () => {} }}
                                default={responseData.deliveryFees}
                                error={showError1 && !responseData.deliveryFees}
                                errorMsg={responseData.deliveryFees ? 'Provide a Valid Delivery Fees' : 'Provide a Delivery Fees'}
                                onChange={(e) => {
                                  let v = checkValid(e.target.value);
                                  setResponseDataErr((prev) => ({
                                    ...prev,
                                    deliveryFees: v,
                                  }));

                                  setResponseData((prev) => ({
                                    ...prev,
                                    deliveryFees: e.target.value,
                                  }));
                                }}
                                value={'Delivery Fees'}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                {type === 'request' && (
                  <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                    <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            type === 'request' && setOption('Other Policies');
                          }}
                          className={'w-lg-auto  d-inline px-5   '}
                          type="reverse"
                          text={'Back'}
                        />
                      </div>
                    </div>
                    {type === 'request' && (
                      <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={handleSendResponse}
                            color={'#D1E6FF'}
                            borderColor={'#134074'}
                            fontColor={'#134074'}
                            className={'w-lg-auto  d-inline px-5  ms-auto '}
                            type="normal"
                            text={type === 'request' ? 'Save and Send Reply' : 'Next'}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {option === 'Special Specification' && (
              <>
                {
                  <>
                    <div className="row  pt-0 px-0 mx-0">
                      <div className="col-12 px-0 pe-2 ">
                        <div className={`${classes.input1}   row d-flex align-items-center`}>
                          <p style={{ fontSize: '20px' }} className="w-auto d-inline">
                            Offer Details
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-lg-4 col-md-6 col-12 px-0">
                        <div className={classes['single-item']}>
                          <p>Quantity</p>
                          <span className="d-block">{data.quantity}</span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 px-0">
                        <div className={classes['single-item']}>
                          <p>Unit</p>
                          <span className="d-block">{data.unit}</span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 px-0">
                        <div className={classes['single-item']}>
                          <p>Unit Price</p>
                          <span className="d-block">{data.price}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-12 px-0">
                        <div className={classes['single-item']}>
                          <p>Special Specification</p>
                          <span className="d-block">{data.specialSpecification}</span>
                        </div>
                      </div>
                    </div>
                    {((data.color && data.color.length > 0) || (data.size && data.size.length > 0)) && (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Colors & Sizes</p>
                          </div>
                        </div>
                      </div>
                    )}

                    {data.color && data.color.length > 0 ? (
                      data.color.map((item, index) => (
                        <div key={index} className={`row  d-flex px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                          <div
                            style={{
                              background: `${item.title}`,
                              width: '5px',
                              borderRadius: '10px',
                              boxSizing: 'content-box',
                              marginInlineEnd: '8px',
                            }}
                            className="w-auto d-inline"
                          ></div>
                          <div style={{ minWidth: '200px' }} className="w-auto d-inline  px-0 pt-3">
                            <div className={classes['single-item']}>
                              <p className="text-black ">{item.title}</p>
                              <span className="d-block text-black ">
                                &nbsp; &nbsp;{item.quantity} {data.unit}
                              </span>
                            </div>
                          </div>
                          <div className="w-auto d-inline">
                            <Swiper
                              spaceBetween={10}
                              pagination={{
                                clickable: true,
                              }}
                              breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                1024: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                                1025: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                                1441: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                              }}
                              loop={false}
                              loopFillGroupWithBlank={true}
                              navigation={true}
                              modules={[Pagination, Navigation]}
                              className="mySwiper    align-items-center d-flex p-0 "
                            >
                              {item.size.map((item1, index1) => (
                                <SwiperSlide
                                  key={index1}
                                  style={{ minWidth: '200px' }}
                                  className={` text-start px-0 pt-0 mt-0 me-3  w-auto px-0 pt-3`}
                                >
                                  <div className="  px-0">
                                    <div className={classes['single-item']}>
                                      <p>{item.title}</p>
                                      <span className="d-block">
                                        {item.quantity} {data.unit}
                                      </span>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-auto d-inline">
                        <Swiper
                          spaceBetween={10}
                          pagination={{
                            clickable: true,
                          }}
                          breakpoints={{
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                            1025: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                            1441: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                          }}
                          loop={false}
                          loopFillGroupWithBlank={true}
                          navigation={true}
                          modules={[Pagination, Navigation]}
                          className="mySwiper    align-items-center d-flex p-0 "
                        >
                          {data.size.map((item1, index1) => (
                            <SwiperSlide key={index1} style={{ minWidth: '200px' }} className={` text-start px-0 pt-0 mt-0 me-3  w-auto px-0 pt-3`}>
                              <div className="  px-0">
                                <div className={classes['single-item']}>
                                  <p>{item1.title}</p>
                                  <span className="d-block">
                                    {item1.quantity} {data.unit}
                                  </span>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    )}
                  </>
                }
              </>
            )}
            {option === 'Terms and Condition' && (
              <>
                <TermsSections title="Supplier Terms & Conditions" terms={data.supplierPaymentTerms} border={true} />
                {type === 'request' && (
                  <div className={`${classes.input}  row  d-flex align-items-center `}>
                    <div className="w-auto  align-items-center d-flex h-100 d-inline">
                      <Checkbox
                        className=" w-auto d-inline py-0 h-100"
                        checked={responseData.acceptSupplierPaymentTerms ? true : false}
                        onClick={() => {
                          setResponseData((prev) => ({
                            ...prev,
                            acceptSupplierPaymentTerms: !responseData.acceptSupplierPaymentTerms,
                          }));
                        }}
                      />
                    </div>
                    <div className="w-auto  d-inline h-100">
                      <span
                        onClick={() => {
                          setResponseData((prev) => ({
                            ...prev,
                            acceptSupplierPaymentTerms: !responseData.acceptSupplierPaymentTerms,
                          }));
                        }}
                        className="text-black-50 h-100  w-auto d-inline"
                      >
                        &nbsp;I accept all terms and conditions
                      </span>
                    </div>
                    {showError && !responseData.acceptSupplierPaymentTerms && (
                      <div className=" mb-3">
                        <span className="errorMsg text-start">You Must Accept Supplier Terms & Conditions.</span>
                      </div>
                    )}
                  </div>
                )}

                {type == 'request' && (
                  <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                    <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            type === 'request' && setOption('Additional Info');
                          }}
                          className={'w-lg-auto  d-inline px-5   '}
                          type="reverse"
                          text={'Back'}
                        />
                      </div>
                    </div>
                    <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            type === 'request' && setOption('Payment Methods');
                          }}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          className={'w-lg-auto  d-inline px-5  ms-auto '}
                          type="normal"
                          text={'Next'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {option === 'Payment Methods' && (
              <>
                {data.supplierPaymentTerms ? (
                  <TermsSections terms={data.supplierPaymentTerms} border={true} />
                ) : (
                  <TermsSections terms={data.customerPaymentTerms} border={true} />
                )}
                {type === 'request' && (
                  <div className={`${classes.input}  row  d-flex align-items-center `}>
                    <div className="w-auto  align-items-center d-flex h-100 d-inline">
                      <Checkbox
                        className=" w-auto d-inline py-0 h-100"
                        checked={responseData.acceptCustomerPaymentTerms ? true : false}
                        onClick={() => {
                          setResponseData((prev) => ({
                            ...prev,
                            acceptCustomerPaymentTerms: !responseData.acceptCustomerPaymentTerms,
                          }));
                        }}
                      />
                    </div>
                    <div className="w-auto  d-inline h-100">
                      <span
                        onClick={() => {
                          setResponseData((prev) => ({
                            ...prev,
                            acceptCustomerPaymentTerms: !responseData.acceptCustomerPaymentTerms,
                          }));
                        }}
                        className="text-black-50 h-100  w-auto d-inline"
                      >
                        &nbsp;I accept all terms and conditions
                      </span>
                    </div>
                    {showError && !responseData.acceptCustomerPaymentTerms && (
                      <div className="mb-3">
                        <span className="errorMsg text-start">You Must Accept Customer Terms & Conditions.</span>
                      </div>
                    )}
                  </div>
                )}

                {type === 'request' && (
                  <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                    <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            type === 'request' && setOption('Terms and Condition');
                          }}
                          className={'w-lg-auto  d-inline px-5   '}
                          type="reverse"
                          text={'Back'}
                        />
                      </div>
                    </div>
                    <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            type === 'request' && setOption('Other Policies');
                          }}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          className={'w-lg-auto  d-inline px-5  ms-auto '}
                          type="normal"
                          text={'Next'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {option === 'Other Policies' &&
              (type === 'request' ? (
                <>
                  <div className="row px-0 mx-0">
                    <div className="col-12 px-0 pe-2">
                      <div className={`${classes.input} row d-flex align-items-center`}>
                        <p className="w-auto d-inline">Other Policies</p>

                        {type === 'request' && (
                          <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                            <div className={`  mb-pb-0`}>
                              <Button
                                onClick={() => {
                                  setSectionData(null);
                                  setShowAddFormOtherPolicies(true);
                                }}
                                icon={<Plus />}
                                color={'#D1E6FF'}
                                borderColor={'#134074'}
                                fontColor={'#134074'}
                                className={'w-auto px-2 ms-auto text-end'}
                                type="normal"
                                text={'Add Section'}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {otherPolicies.length > 0 ? (
                    otherPolicies.map((item, index) => (
                      <div key={index} className="row mt-0 mb-5 px-0 mx-0">
                        <div className="col-lg-5 col-10 px-0">
                          <Section
                            Checkbox={true}
                            onClick={() => {
                              let arr = [];
                              otherPolicies.map((item1, index1) => {
                                if (index === index1) {
                                  arr.push({
                                    title_en: item1.title_en,
                                    title_ar: item1.title_ar,
                                    content_en: item1.content_en,
                                    content_ar: item1.content_ar,
                                    selected: !item1.selected,
                                  });
                                } else
                                  arr.push({
                                    title_en: item1.title_en,
                                    title_ar: item1.title_ar,
                                    content_en: item1.content_en,
                                    content_ar: item1.content_ar,
                                    selected: item1.selected,
                                  });
                              });
                              setOtherPolicies(arr);
                            }}
                            checked={item.selected}
                            title={item.title_en}
                            text={item.content_en}
                          />
                        </div>
                        <div className="col-lg-2 col-2 justify-content-center">
                          <div className="row px-0 mx-0  ">
                            <div
                              onClick={() => {
                                setSectionData({ ...item });
                                setSelectedIndex(index);
                                setShowEditFormOtherPolicies(true);
                              }}
                              className="w-auto mx-auto"
                            >
                              <Edit />
                            </div>
                          </div>
                          <div className="row px-0 mx-0 mt-3">
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                let arr = [];
                                otherPolicies.map((item1) => {
                                  if (item.title_en !== item1.title_en && item.title_ar !== item1.title_ar) {
                                    arr.push(item1);
                                  }
                                  setOtherPolicies(arr);
                                });
                              }}
                              className="w-auto mx-auto"
                            >
                              <Recycle />
                            </div>
                          </div>
                        </div>
                        <div className="d-lg-none d-block col-1"></div>
                        <div className="col-lg-5 col-10  px-0  ms-lg-auto me-auto">
                          <Section title={item.title_ar} text={item.content_ar} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-100 row justify-content-center pt-5 pb-4">
                      <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Other Policies Added yet.</div>
                    </div>
                  )}
                  {type === 'request' && (
                    <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                      <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => type === 'request' && setOption('Payment Methods')}
                            className={'w-lg-auto  d-inline px-5   '}
                            type="reverse"
                            text={'Back'}
                          />
                        </div>
                      </div>
                      <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => type === 'request' && setOption('Delivery')}
                            color={'#D1E6FF'}
                            borderColor={'#134074'}
                            fontColor={'#134074'}
                            className={'w-lg-auto  d-inline px-5  ms-auto '}
                            type="normal"
                            text={'Next'}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <TermsSections title="Other Policies" terms={data.otherPolices} border={true} />
              ))}
            {option === 'Additional Info' && (
              <>
                {(!loading && type === 'request' && (
                  <>
                    {(hasSize || hasColor) && (
                      <div className="row px-0 mx-0 mb-3 ">
                        <div className="col-12 px-0 ">
                          <div className={classes.input}>
                            <p style={{ fontSize: '20px' }} className="mb-4">
                              Select Quantity
                            </p>

                            {data.quantities.map((item, index) => (
                              <span
                                key={index}
                                onClick={() => {
                                  setSelectedQuantity(index);
                                  setLoading(true);
                                  setLoadingColor(true);
                                }}
                                className={`${classes['quantity']}  ${selectedQuantity === index && classes['quantity-selected']} w-auto  d-inline`}
                              >
                                {item}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {(hasSize || hasColor) && (
                      <div className="row px-0 mx-0 mb-3 ">
                        <div className="col-12 px-0 ">
                          <div className={classes.input}>
                            <p style={{ fontSize: '20px' }} className="mb-4">
                              {hasColor ? (hasSize ? 'Color / Size Quantitirs' : 'Color Quantities') : 'Size Quantities'}
                            </p>
                            {quantitesData[selectedQuantity].map((item, index1) => (
                              <div key={index1}>
                                {hasColor ? (
                                  <div className="row px-0 mx-0">
                                    <div className={index1 === 0 ? 'col-12' : 'col-lg-11 col-10'}>
                                      <div className="row px-0">
                                        <div className="col-xl-6 pe-xl-2 px-0">
                                          <InputGroup
                                            default={quantitesData[selectedQuantity][index1].color}
                                            type="color"
                                            onChange={(e) => {
                                              let temp = quantitesData;
                                              temp[selectedQuantity][index1].color = `${e.target.value}`;
                                              setQuantitiesData(temp);
                                              setColorChange((prev) => prev + 1);
                                            }}
                                            value={'Color'}
                                          />
                                        </div>

                                        <div className="col-xl-6 ps-xl-2 px-0  ms-auto">
                                          <InputGroup
                                            default={quantitesData[selectedQuantity][index1].totalQuantity}
                                            onChange={(e) => {
                                              let isNumber = true;
                                              let temp = quantitesData;
                                              temp[selectedQuantity][index1].totalQuantity = e.target.value;
                                              setQuantitiesData(temp);
                                              if (e.target.value && !Number(e.target.value)) {
                                                isNumber = false;
                                                let arr2 = ValidColorSizeQuantity;
                                                arr2[selectedQuantity][index1] = !isNumber && false;
                                                setValidColorSizeQuantity(arr2);
                                                arr2 = ValidColorQuantity;
                                                ValidColorQuantity[selectedQuantity] = false;
                                                setValidColorQuantity(arr2);
                                                setLoadingColor(true);
                                              }

                                              if (!e.target.value || e.target.value === '') {
                                                isNumber = false;
                                                let arr2 = ValidColorSizeQuantity;
                                                arr2[selectedQuantity][index1] = !isNumber && false;

                                                setValidColorSizeQuantity(arr2);

                                                arr2 = ValidColorQuantity;
                                                ValidColorQuantity[selectedQuantity] = false;
                                                setValidColorQuantity(arr2);
                                                setLoadingColor(true);
                                              }
                                              if (isNumber) {
                                                let res = 0;
                                                let continue1 = true;
                                                let Valid = ValidColorQuantity;
                                                temp[selectedQuantity].map((item) => {
                                                  res += item.totalQuantity ? parseInt(item.totalQuantity) : 0;
                                                });
                                                if (res > data.quantities[selectedQuantity]) {
                                                  Valid[selectedQuantity] = false;
                                                } else {
                                                  Valid[selectedQuantity] = true;
                                                }
                                                setValidColorQuantity(Valid);
                                                let arr2 = ValidColorSizeQuantity;
                                                res = 0;
                                                temp[selectedQuantity][index1].sizes.map((item) => {
                                                  res += item.quantity ? parseInt(item.quantity) : 0;

                                                  if (!Number(item.quantity)) {
                                                    arr2[selectedQuantity][index1] = false;
                                                    setValidColorSizeQuantity(arr2);
                                                    continue1 = false;
                                                    return;
                                                  }
                                                });
                                                if (continue1) {
                                                  if (res !== parseInt(temp[selectedQuantity][index1].totalQuantity)) {
                                                    arr2[selectedQuantity][index1] = false;
                                                  } else {
                                                    arr2[selectedQuantity][index1] = true;
                                                  }
                                                  setValidColorSizeQuantity(arr2);
                                                }
                                                setLoadingColor(true);
                                                setColorChange((prev) => prev + 1);
                                              }
                                            }}
                                            error={
                                              quantitesData[selectedQuantity][index1].totalQuantity &&
                                              showError &&
                                              !ValidColorQuantity[selectedQuantity]
                                            }
                                            errorMsg={'provide Valid Quantites'}
                                            value={'Quantity'}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {index1 !== 0 && (
                                      <div
                                        onClick={() => {
                                          let temp = quantitesData;
                                          let arr = [];
                                          temp[selectedQuantity].map((i, ind) => {
                                            if (index1 !== ind) {
                                              arr.push(i);
                                            }
                                          });
                                          temp[selectedQuantity] = arr;
                                          setQuantitiesData(temp);
                                          let res = 0;
                                          arr.map((item) => {
                                            res += item.totalQuantity ? parseInt(item.totalQuantity) : 0;
                                          });
                                          let Valid = ValidColorQuantity;
                                          if (res > data.quantities[selectedQuantity]) {
                                            Valid[selectedQuantity] = false;
                                          } else {
                                            Valid[selectedQuantity] = true;
                                          }
                                          setValidColorQuantity(Valid);
                                          setLoadingColor(true);
                                          setLoading(true);
                                        }}
                                        className={`w-auto d-inline  cursor ${
                                          !ValidColorQuantity[selectedQuantity]
                                            ? quantitesData[selectedQuantity][index1].totalQuantity && 'align-self-lg-center'
                                            : 'align-self-lg-end'
                                        }  ms-auto mt-lg-0 mt-4 pt-lg-0 pt-2`}
                                      >
                                        <Recycle1 />
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                                {hasSize &&
                                  quantitesData[selectedQuantity][index1].sizes &&
                                  quantitesData[selectedQuantity][index1].sizes.map((item2, index2) => (
                                    <div key={index2} className={`${classes.input} `}>
                                      <div className={`mt-2 row  px-0 mx-0 `}>
                                        {hasColor && colorChange ? (
                                          <>
                                            <div
                                              style={{
                                                background: `${quantitesData[selectedQuantity][index1].color}`,
                                                width: '8px',
                                                borderRadius: '10px',
                                                boxSizing: 'content-box',
                                                marginInlineEnd: '8px',
                                              }}
                                              className="col-1"
                                            ></div>
                                          </>
                                        ) : null}

                                        <div className={`${hasColor ? 'col-lg-11' : 'col-12'} px-0`}>
                                          <div className="row px-0 mb-2  mx-0">
                                            <div className={`${index2 === 0 && index1 === 0 ? 'col-12' : 'col-lg-11 col-10'}  `}>
                                              <div className="row px-0">
                                                <div className="col-xl-6 pe-xl-2 px-0">
                                                  <InputGroup
                                                    default={quantitesData[selectedQuantity][index1].sizes[index2].title}
                                                    onChange={(e) => {
                                                      let temp = quantitesData;
                                                      temp[selectedQuantity][index1].sizes[index2].title = e.target.value;
                                                      setQuantitiesData(temp);
                                                    }}
                                                    error={
                                                      quantitesData[selectedQuantity][index1].sizes[index2].quantity &&
                                                      showError &&
                                                      !ValidColorSizeQuantity[selectedQuantity][index1]
                                                    }
                                                    // errorMsg={'Provide Valid Quantites'}
                                                    value={'Size'}
                                                  />
                                                </div>

                                                <div className={`col-xl-6 ps-xl-2 px-0  ms-auto  `}>
                                                  <InputGroup
                                                    default={quantitesData[selectedQuantity][index1].sizes[index2].quantity}
                                                    onChange={(e) => {
                                                      let isNumber = true;
                                                      if (e.target.value && !Number(e.target.value)) {
                                                        let temp = quantitesData;
                                                        temp[selectedQuantity][index1].sizes[index2].quantity = e.target.value;
                                                        setQuantitiesData(temp);
                                                        isNumber = false;
                                                        let arr2 = ValidColorSizeQuantity;
                                                        arr2[selectedQuantity][index1] = !isNumber && false;
                                                        setValidColorSizeQuantity(arr2);
                                                        setLoadingColor(true);
                                                      }

                                                      if (isNumber) {
                                                        let temp = quantitesData;
                                                        temp[selectedQuantity][index1].sizes[index2].quantity = e.target.value;
                                                        setQuantitiesData(temp);

                                                        let res = 0;
                                                        temp[selectedQuantity][index1].sizes.map((item) => {
                                                          res += item.quantity ? parseInt(item.quantity) : 0;
                                                        });
                                                        let arr2 = ValidColorSizeQuantity;
                                                        if (hasColor) {
                                                          if (res !== parseInt(temp[selectedQuantity][index1].totalQuantity || 0)) {
                                                            arr2[selectedQuantity][index1] = false;
                                                          } else {
                                                            arr2[selectedQuantity][index1] = true;
                                                          }
                                                        } else {
                                                          if (res !== parseInt(data.quantities[selectedQuantity] || 0)) {
                                                            arr2[selectedQuantity][index1] = false;
                                                          } else {
                                                            arr2[selectedQuantity][index1] = true;
                                                          }
                                                        }
                                                        setValidColorSizeQuantity(arr2);

                                                        setLoadingColor(true);
                                                      }
                                                    }}
                                                    value={'Quantity'}
                                                    error={
                                                      quantitesData[selectedQuantity][index1].sizes[index2].quantity &&
                                                      showError &&
                                                      !ValidColorSizeQuantity[selectedQuantity][index1]
                                                    }
                                                    errorMsg={'Provide Valid Quantites'}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {!(index2 === 0) && (
                                              <div
                                                onClick={() => {
                                                  let temp1 = quantitesData;
                                                  let temp = quantitesData[selectedQuantity][index1].sizes;
                                                  let arr = [];
                                                  temp.map((i, ind) => {
                                                    if (index2 != ind) {
                                                      arr.push(i);
                                                    }
                                                  });
                                                  temp1[selectedQuantity][index1].sizes = arr;
                                                  setQuantitiesData(temp1);
                                                  let res = 0;
                                                  temp1[selectedQuantity][index1].sizes.map((item) => {
                                                    res += item.quantity ? parseInt(item.quantity) : 0;
                                                  });
                                                  let arr2 = ValidColorSizeQuantity;
                                                  if (res !== parseInt(temp1[selectedQuantity][index1].totalQuantity)) {
                                                    arr2[selectedQuantity][index1] = false;
                                                  } else {
                                                    arr2[selectedQuantity][index1] = true;
                                                  }
                                                  setValidColorSizeQuantity(arr2);
                                                  setLoadingColor(true);
                                                }}
                                                className={`w-auto cursor   ${
                                                  !ValidColorSizeQuantity[selectedQuantity][index1]
                                                    ? quantitesData[selectedQuantity][index1].sizes[index2].quantity
                                                      ? 'align-self-lg-center'
                                                      : 'align-self-lg-end'
                                                    : 'align-self-lg-end '
                                                } d-inline ms-auto mt-lg-0 mt-4 pt-lg-0 pt-2`}
                                              >
                                                <Recycle1 />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                {hasSize && (
                                  <div
                                    onClick={() => {
                                      if (
                                        ValidColorSizeQuantity[selectedQuantity][index1] ||
                                        quantitesData[selectedQuantity][index1].sizes.length === 0 ||
                                        true
                                      ) {
                                        let temp = quantitesData;
                                        temp[selectedQuantity][index1].sizes = [
                                          ...temp[selectedQuantity][index1].sizes,
                                          { title: null, quantity: null },
                                        ];
                                        setQuantitiesData(temp);
                                        setLoadingColor(true);
                                      }
                                    }}
                                    className={`w-auto align-self-end d-inline mt-2`}
                                  >
                                    <p className={`${quantitesData[selectedQuantity][index1].sizes.length === 0 && 'pt-4'}`}>
                                      <Plus /> Add Size
                                    </p>
                                  </div>
                                )}
                                {hasColor && index1 === quantitesData[selectedQuantity].length - 1 && (
                                  <div onClick={handleAddColor} className={`w-auto align-self-end d-inline mt-2 ${classes['has-color']}`}>
                                    <p>
                                      <Plus color="#a50182" /> Add Color
                                    </p>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          {hasColor && leftQuantity[selectedQuantity] > 0 && (
                            <div className={`${classes['error']}`}>
                              {leftQuantity[selectedQuantity]}
                              item left
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {(data.product.variants.includes('ExpireDate') || data.product.variants.includes('expireDate')) && (
                      <div className={classes.input}>
                        <InputGroup
                          type="date"
                          default={responseData.expireDate}
                          name="expireDate"
                          error={showError1 && !responseData.expireDate}
                          errorMsg={'Provide Expiry Date'}
                          onChange={(e) =>
                            setResponseData((prev) => ({
                              ...prev,
                              expireDate: e.target.value,
                            }))
                          }
                          value={'Expiry Date'}
                        />
                      </div>
                    )}
                    <div className={classes.input}>
                      <TextAreaGroup
                        error={showError1 && !responseData.differeniateYourDeal}
                        errorMsg={'Provide Description of Your Deal'}
                        onChange={(e) =>
                          setResponseData((prev) => ({
                            ...prev,
                            differeniateYourDeal: e.target.value,
                          }))
                        }
                        default={responseData.differeniateYourDeal}
                        value={'Differentiate your offer'}
                      />
                    </div>
                    {type === 'request' && (
                      <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                        <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                          <div className={`  mb-pb-0`}>
                            <Button
                              onClick={() => type === 'request' && setOption('Main Data')}
                              className={'w-lg-auto  d-inline px-5   '}
                              type="reverse"
                              text={'Back'}
                            />
                          </div>
                        </div>
                        <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                          <div className={`  mb-pb-0`}>
                            <Button
                              onClick={() => type === 'request' && setOption('Terms and Condition')}
                              color={'#D1E6FF'}
                              borderColor={'#134074'}
                              fontColor={'#134074'}
                              className={'w-lg-auto  d-inline px-5  ms-auto '}
                              type="normal"
                              text={'Next'}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )) ||
                  ((type === 'history' || type === 'onGoing') && (
                    <>
                      <div className="row  pt-0 px-0 mx-0">
                        <div className="col-12 px-0 pe-2 ">
                          <div className={`${classes.input1}   row d-flex align-items-center`}>
                            <p style={{ fontSize: '20px' }} className="w-auto d-inline">
                              Offer Details
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-lg-4 col-md-6 col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Quantity</p>
                            <span className="d-block">{data.quantity}</span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Unit</p>
                            <span className="d-block">{data.unit}</span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Unit Price</p>
                            <span className="d-block">{data.price}</span>
                          </div>
                        </div>
                      </div>
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Special Specification</p>
                            <span className="d-block">{data.specialSpecification}</span>
                          </div>
                        </div>
                      </div>

                      {data.color.map((item, index) => (
                        <div key={index} className={`row  d-flex px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                          <div
                            style={{
                              background: `${item.title}`,
                              width: '5px',
                              borderRadius: '10px',
                              boxSizing: 'content-box',
                              marginInlineEnd: '8px',
                            }}
                            className="w-auto d-inline"
                          ></div>
                          <div style={{ minWidth: '200px' }} className="w-auto d-inline  px-0 pt-3">
                            <div className={classes['single-item']}>
                              <p className="text-black ">{item.title}</p>
                              <span className="d-block text-black ">
                                &nbsp; &nbsp;{item.quantity} {data.unit}
                              </span>
                            </div>
                          </div>
                          <div className="w-auto d-inline">
                            <Swiper
                              spaceBetween={10}
                              pagination={{
                                clickable: true,
                              }}
                              breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                1024: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                                1025: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                                1441: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                              }}
                              loop={false}
                              loopFillGroupWithBlank={true}
                              navigation={true}
                              modules={[Pagination, Navigation]}
                              className="mySwiper    align-items-center d-flex p-0 "
                            >
                              {item.size.map((item1, index1) => (
                                <SwiperSlide
                                  key={index1}
                                  style={{ minWidth: '200px' }}
                                  className={` text-start px-0 pt-0 mt-0 me-3  w-auto px-0 pt-3`}
                                >
                                  <div className="  px-0">
                                    <div className={classes['single-item']}>
                                      <p>{item.title}</p>
                                      <span className="d-block">
                                        {item.quantity} {data.unit}
                                      </span>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
              </>
            )}
            {option === 'Agreements' && (
              <>
                {!data.purchasingDone && (
                  <div className="row px-0 mx-0">
                    <div className="col-12 ">
                      <div className={classes.input}>
                        <UploadImageGroup2
                          default={doc}
                          type="supplier"
                          onChange={(e) => setDoc([e])}
                          errorMsg="Provide a Profile Picture"
                          title={'Purshacing Order'}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row px-0 mx-0">
                  <div className="col-12 ">
                    <div className={classes.input}>
                      <UploadImageGroup2
                        noDeleteAll={true}
                        default={
                          (data.purchasingOrder && data.invoice && [data.purchasingOrder, data.invoice]) ||
                          (data.purchasingOrder && !data.invoice && [data.purchasingOrder]) ||
                          (!data.purchasingOrder && data.invoice && [data.invoice])
                        }
                        imageType="invoice/po"
                        errorMsg="Provide a Profile Picture"
                        title={'Invoice'}
                        subTitle="click on invoice to download"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === 'Customers' &&
              (loadingOrder ? (
                <LoadingTable />
              ) : (
                <>
                  <Table
                    type="DealOrder"
                    options={[
                      {
                        title: 'Order ID',
                        icon: false,
                      },
                      {
                        title: 'Customer ID',
                        icon: false,
                      },
                      {
                        title: 'Customer Type',
                        icon: false,
                      },
                      {
                        title: 'Customer Name',
                        icon: false,
                      },
                      {
                        title: 'Customer Phone',
                        icon: false,
                      },
                      {
                        title: 'Quantity',
                        icon: false,
                      },
                      {
                        title: 'Status',
                        icon: false,
                      },
                    ]}
                    wholeData={wholeOrders}
                    data={dealOrders}
                    deliveryResponsibility={data.deliveryResponsibility}
                    customStyleIndex={[7]}
                    updateData={() => {
                      setPageOrder(1);
                      getDealOrders();
                    }}
                    filterOption={[]}
                    customStyleFn={[
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      (val) => {
                        return (
                          (val === 'Delivered' && {
                            borderRadius: '10px',
                            background: '#E3FDE5',
                            color: '#2CD63D',
                            textAlign: 'center',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px ',
                            padding: '8px',
                          }) ||
                          (val === 'Shipping' && {
                            borderRadius: '10px',
                            color: '#7A7E00',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: '#FCFFAB',
                            textAlign: 'center',
                          }) ||
                          (val === 'Canceled' && {
                            borderRadius: '10px',
                            color: '#F2055C',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: '#F2055C69',
                            textAlign: 'center',
                          }) ||
                          (val === 'On going' && {
                            borderRadius: '10px',
                            color: 'blue',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: 'lightBlue',
                            textAlign: 'center',
                          }) ||
                          (val === 'Preparing' && {
                            borderRadius: '10px',
                            color: 'black',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: 'lightGray',
                            textAlign: 'center',
                          })
                        );
                      },
                    ]}
                  />
                  {totalPagesOrder > 1 && (
                    <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                      <div className="w-auto d-inline ms-auto">
                        <UsePagination
                          page={pageOrder}
                          onChangePage={(page) => {
                            setLoadingOrder(true);
                            setPageOrder(page);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {dealOrders.length !== 0 &&
                    (wholeOrders1.shipping !== 0 ||
                      wholeOrders1.delivered !== 0 ||
                      wholeOrders1.canceled !== 0 ||
                      wholeOrders1.onGoing !== 0 ||
                      wholeOrders1.preparing !== 0) && (
                      <div className={`row  px-0 mx-0 mt-4 ${classes['company-profile--data']}`}>
                        <div className="col-12 px-0">
                          <div dir="auto" className={classes['single-item']}>
                            <p>Progress</p>
                            <div className="row justify-content-center pt-3 px-0 mx-0">
                              <div className="col-lg-2 col-6">
                                <PieChart
                                  data={[
                                    {
                                      title: 'Shipping',
                                      value: wholeOrders1.shipping,
                                      color: 'yellow',
                                    },
                                    {
                                      title: 'delivered',
                                      value: wholeOrders1.delivered,
                                      color: '#2DA840',
                                    },
                                    {
                                      title: 'Canceled',
                                      value: wholeOrders1.canceled,

                                      color: 'red',
                                    },
                                    {
                                      title: 'On Going',
                                      value: wholeOrders1.onGoing,
                                      color: 'lightBlue',
                                    },
                                    {
                                      title: 'Preparing',
                                      value: wholeOrders1.preparing,
                                      color: 'lightGray',
                                    },
                                  ]}
                                />
                              </div>
                              <div className="col-lg-6 mt-lg-0 mt-4  d-flex flex-column  justify-content-center ms-auto d-inline">
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: '#2DA840',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div className={`col-6  ${classes['single-item']}`}>
                                    <p>Delivered</p>
                                  </div>
                                  <div className={`w-auto d-inline mx-auto  ${classes['single-item']}`}>
                                    <p>{wholeOrders1.delivered}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: 'lightBlue',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div className={`col-6 ${classes['single-item']}`}>
                                    <p>On Going</p>
                                  </div>
                                  <div className={`w-auto d-inline mx-auto  ${classes['single-item']}`}>
                                    <p>{wholeOrders1.onGoing}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: 'tla',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div className={`col-6 ${classes['single-item']}`}>
                                    <p>PreParing</p>
                                  </div>
                                  <div className={`w-auto d-inline mx-auto  ${classes['single-item']}`}>
                                    <p>{wholeOrders1.preparing}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: 'yellow',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div className={`col-6 ${classes['single-item']}`}>
                                    <p>Shipping</p>
                                  </div>
                                  <div className={`w-auto d-inline mx-auto text-center ${classes['single-item']}`}>
                                    <p>{wholeOrders1.shipping}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: 'red',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div className={`col-6 ${classes['single-item']}`}>
                                    <p>Canceled</p>
                                  </div>
                                  <div className={`w-auto mx-auto d-inline text-center ${classes['single-item']}`}>
                                    <p>{wholeOrders1.canceled}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              ))}
            {option === 'Supplier Payment Terms' && (
              <TermsSections title="Supplier Terms & Conditions" terms={data.supplierPaymentTerms} border={true} />
            )}
          </section>
        )}
      </section>
    </div>
  );
}
//Done
// 3420
