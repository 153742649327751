import { Policy } from "@mui/icons-material";
import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const originalDealsEshtreanaService = {
  createDealRequest,
  createOrigialDeal,
  getDealDetails,
  getDealOffers,
  getDeals,
  getDealsResponse,
  getOfferDetails,
  getSupplierDealOffer,
  sendPoInvoice,
  toggleAgreements,
  getDealOrders,
  changeOrderStatus,
};

async function createDealRequest(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/original-deal/new-request`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getDealsResponse(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/original-deal/eshtarena/responses?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getDeals(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/original-deal/eshtarena/all-deals?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getDealOrders(id, page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/original-deal/deal-orders/${id}?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getDealOffers(id, page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/original-deal/offer-details/all-suppliers/${id}?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSupplierDealOffer(id, page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/original-deal/offer/details/single-supplier/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function createOrigialDeal(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/original-deal/new-deal`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function toggleAgreements(id) {
  try {
    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/original-deal/toggle-agreements/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getOfferDetails(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/original-deal/offer-details/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getDealDetails(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/original-deal/deal-details/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function sendPoInvoice(id, data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/original-deal/docs/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function changeOrderStatus(id, status) {
  try {
    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/deal/change-status/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: { status },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
