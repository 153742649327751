import React from 'react';
import AndoridBtn from './AndoridBtn';
import IosBtn from './IosBtn';
import { Link } from 'react-scroll';
import EshtreanaMainLogo2 from '../Reusable/SVG/EshtarenaMainLogo2';
import { useNavigate } from 'react-router-dom';

export default function Footer(props) {
  const history = useNavigate();
  return (
    <div className={'footer'}>
      <div className="row px-xl-0 px-3">
        <div
          onClick={() => history('/')}
          className="col-xl-3 col-12 justify-content-xl-start justify-content-center align-items-xl-start align-items-center  d-flex flex-column my-3 my-xl-0"
        >
          <EshtreanaMainLogo2 />
          <div className="d-flex justify-content-xl-start justify-content-center">
            {props.social.map((item, index) => (
              <div key={index} className={`pe-2 link}`}>
                <a target="_blank" href={item.link} rel="noreferrer">
                  <img
                    style={{ height: '20px', width: '20px' }}
                    alt="advice"
                    show={false}
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/advice/${item.logo}`}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="col-xl-4 col-12 d-flex my-3 my-xl-0">
          <div className="row">
            <div className="col-xl-6">
              <p className={`footer-title`}>Company</p>
              <div>
                <Link className={`link`} to="aboutUs">
                  About Us
                </Link>
              </div>
              <div>FAQs</div>
            </div>
            <div className="col-xl-6 mt-xl-0 mt-3">
              <p className={`footer-title text-nowrap`}>Get to now us</p>
              <div>Careers</div>
              <div className="text-nowrap">
                <a
                  style={{ color: 'unset', textDecoration: 'none' }}
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_API_BASE_URL}/terms-conditions`}
                  target="_blank"
                >
                  Terms and Conditions
                </a>
              </div>
              <div>
                <a
                  style={{ color: 'unset', textDecoration: 'none' }}
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_API_BASE_URL}/privacy-policy`}
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 ms-auto col-12 my-3 my-xl-0">
          <div className="row  text-end justify-content-end">
            <div className={` m-0 mb-3 text-center downlod`}>Download the app now and get started</div>
            <div className="  px-0 mx-0 text-center    ">
              <a style={{ cursor: 'pointer' }} className="w-auto px-0 d-inline pe-md-1" href={props?.data?.apple} rel="noreferrer" target="_blank">
                <div className="w-auto d-inline px-0 mx-0 mb-xl-0 mb-3 ">
                  <IosBtn />
                </div>
              </a>
              <a style={{ cursor: 'pointer' }} className="w-auto px-0 d-inline pe-md-1" href={props?.data?.google} rel="noreferrer" target="_blank">
                <div className={`w-auto d-inline mx-0  px-0 ps-xl-3 `}>
                  <AndoridBtn />
                </div>
              </a>
            </div>
            <div className={` m-0 pt-3 text-center downlod`}>© Copyright 2023, All Rights Reserved by Eshtarena</div>
          </div>
        </div>
      </div>
    </div>
  );
}
