import React from 'react';
import classes from './Area.module.scss';
import { useState } from 'react';
// import Modal from "../../components/UI/Modal/Modal";
import axios from 'axios';
import { useEffect } from 'react';
// import { useHistory } from "react-router-dom";
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import AddNewCity from '../../components/Reusable/Common/AddMemberCard/AddNewCity';
import RemoveMember from '../../components/Reusable/Common/AddMemberCard/RemoveMember';
import Recycle from '../../components/Reusable/SVG/Recycle';
import Edit from '../../components/Reusable/SVG/Edit';
import Dropdown from '../../components/Reusable/SVG/Dropdown';
export default function MainDataOption(props) {
  const [showData, setShowData] = useState(false);
  const [addCity, setAddCity] = useState(false);
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [removedCity, setRemovedCity] = useState([]);
  const [showEditCity, setShowEditCity] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    setCities(props.cities);
  }, []);
  let handleClick = () => {
    if (props.navigate) {
      props.title === 'Emairates' && history('/controlPanel/mainData/emirates');
      props.title === 'E-Commerce' && history('/controlPanel/mainData/e-commerce');
      props.title === 'Workers' && history('/controlPanel/mainData/worker');
      props.title === 'Reserved names' && history('/mainDataNames/reservedNames');
    }
    if (props.type === 'emirates') {
      setShowData((prev) => !prev);
    }
  };

  let handleAddCity = () => {
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL_IMAGE}/v2/admin/city`,
      headers: {
        authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiSGFsYUphcnlfQWRtaW4iLCJhZG1pbklkIjoiNjQ3ODRiNjRmZDQyZGUxNjY2ZDMxMTdkIiwidXNlcklkIjoiNjQ3ODRiNjRmZDQyZGUxNjY2ZDMxMTdkIiwiaWF0IjoxNjg1NjA1MjIwfQ.YdeLMQDpijrJugh6cTVO3n-5Mw_mqAfVoYJ19lSXws0`,
      },
      data: { emirate: props.title, city },
    };

    axios(config)
      .then(function (response) {
        ////console.log(JSON.stringify(response.data));
        setCities((prev) => [...prev, city]);
        setAddCity(false);
        setCity('');
        // setEmirates(response.data.data);
      })
      .catch(function (error) {
        ////console.log(error);
      });
  };

  let handleDeleteCity = () => {
    var config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL_IMAGE}/v2/admin/city`,
      headers: {
        authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiSGFsYUphcnlfQWRtaW4iLCJhZG1pbklkIjoiNjQ3ODRiNjRmZDQyZGUxNjY2ZDMxMTdkIiwidXNlcklkIjoiNjQ3ODRiNjRmZDQyZGUxNjY2ZDMxMTdkIiwiaWF0IjoxNjg1NjA1MjIwfQ.YdeLMQDpijrJugh6cTVO3n-5Mw_mqAfVoYJ19lSXws0`,
      },
      data: { emirate: props.title, city: [...removedCity] },
    };

    axios(config)
      .then(function (response) {
        ////console.log(removedCity);
        ////console.log(JSON.stringify(response.data));
        // setCities((prev) => [...prev, city]);
        let arr = cities.filter((item) => !removedCity.includes(item));
        // setAddCity(false);
        setCities(arr);
        setRemovedCity([]);
        // setCity('');
        // setEmirates(response.data.data);
        setShowDeleteConfirm(false);
      })
      .catch(function (error) {
        ////console.log(error);
      });
  };

  let handleRemovedCities = (city) => {
    let arr = [];
    let added = false;
    arr = removedCity.filter((item) => {
      if (item !== city) return item;
      else added = true;
    });
    !added && arr.push(city);
    setRemovedCity(arr);
  };

  let editCity = (city) => {};
  return (
    <div className={`${classes['area']}`}>
      {showEditCity && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowEditCity(false)}>
          <AddNewCity />
        </Modal>
      )}
      {showDeleteConfirm && (
        <Modal close={() => setShowDeleteConfirm(false)}>
          <RemoveMember
            setShowForm={setShowDeleteConfirm}
            message1="Are you sure you want to remove this City?"
            message2="By removing this City , it won’t show on the City List"
            buttonText="Remove City"
          />
        </Modal>
      )}
      {addCity && (
        <Modal onClose={() => setAddCity(false)}>
          <AddNewCity />
        </Modal>
      )}
      <div onClick={handleClick} className={`row mx-0 align-items-center  ${showData ? classes['main-data-option1'] : classes['main-data-option']}`}>
        <div className="w-auto">
          {props.titleEN} - {props.titleAR}
        </div>
        {props.demographics && <div className="w-auto">(45454)</div>}
        <div
          onClick={(e) => {
            e.stopPropagation();
            // setEditData(item);
            // setShowEditForm(true);
            props.setShowEditCity();
            // history(`/product/${item._id}/edit`);
          }}
          className="w-auto  d-inline"
        >
          <Edit />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            // setShowDeleteForm(true);
            props.setShowDeleteCity();
          }}
          className="w-auto px-0 d-inline"
        >
          <Recycle />
        </div>
        <div className="w-auto text-end ms-auto text-end">
          <Dropdown />
        </div>
      </div>
      {showData && (
        <div className={`row mx-0 p-0 ${classes['main-data-shown']}`}>
          <div className="row pt-3 align-items-center m-0 p-0 px-2 my-2">
            <div className={`col-6 ${classes['main-data-shown-title']} ${classes['main-data-shown-title1']}`}>Districts</div>
            {props.demographics ? (
              <div className={`col-6 ${classes['main-data-shown-title']} ${classes['main-data-shown-title1']}`}>Population</div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  props.setShowAddDistrict();
                }}
                className={`col-lg-4 cursor ms-auto text-end col-6 mt-2 close-btn  ${classes['main-data-shown-btn']}`}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 12H16" stroke="#00425F" storke-width="1.5" storke-linecap="round" storke-linejoin="round" />
                  <path d="M12 16V8" stroke="#00425F" storke-width="1.5" storke-linecap="round" storke-linejoin="round" />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#00425F"
                    storke-width="1.5"
                    storke-linecap="round"
                    storke-linejoin="round"
                  />
                </svg>
                &nbsp; Add New District
              </div>
            )}

            <div className="row mx-0 px-0 mx-auto justify-content-center">
              <hr
                style={{
                  margin: 0,
                  marginBottom: '24px',
                  marginTop: '24px',
                  color: '#7297A9',
                  width: '97%',
                }}
              />
            </div>

            {cities.length > 0 ? (
              cities.map((item, index) => (
                <div key={index} className={`row m-0 p-0 mb-2 px-2 ${classes['main-data-shown-subtitle']}`}>
                  <label htmlFor={`${index}`} className="col-6">
                    {item.district_en} - {item.district_ar}
                  </label>
                  {props.demographics ? (
                    <div className={`col-6 ${classes['pop-count']}`}> &nbsp; &nbsp; &nbsp;455</div>
                  ) : (
                    <div className="col-6 text-end">
                      <div
                        onClick={() => {
                          // setEditData(item);
                          // setShowEditForm(true);
                          props.setShowEditDistrict(item);
                          // history(`/product/${item._id}/edit`);
                        }}
                        className="w-auto cursor pe-2 d-inline"
                      >
                        <Edit />
                      </div>
                      <div
                        onClick={(e) => {
                          // setShowDeleteForm(true);
                          props.setShowDeleteDistrict(item);
                        }}
                        className="w-auto cursor d-inline"
                      >
                        <Recycle />
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="w-100 row justify-content-center pt-5 pb-4">
                <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No District Found</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
