import axios from 'axios';

export const policiesService = {
  getAllPolicies,
  addPolicies,
  editPolicies,
  deletePolicies,
};

async function getAllPolicies(PolicyName, type) {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/${PolicyName}${type ? `?type=${type}` : ` `}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function addPolicies(policyName, data) {
  try {
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/${policyName}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };
    let response = await axios(config);
    //console.log(response);
    return response;
  } catch (e) {
    return e;
  }
}

async function editPolicies(policyName, data, id) {
  try {
    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/${policyName}/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function deletePolicies(policyName, id) {
  try {
    var config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/${policyName}/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
