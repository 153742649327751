import React, { useEffect, useState } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import SwiperImages from '../../components/Sections/SwiperImages';
import Section1 from '../../components/Sections/Section1';
import Section2 from '../../components/Sections/Section2';
import Section3 from '../../components/Sections/Section3';
import Section4 from '../../components/Sections/Section4';
import Section5 from '../../components/Sections/Section5';
import Section6 from '../../components/Sections/Section6';
import Footer from '../../components/Sections/Footer';
import Section42 from '../../components/Sections/Section42';
import Loading from '../../components/Sections/Loading';
import ReactDOM from 'react-dom';
import { MainDataService } from '../../services/mainData';
export default function Landing() {
  const [formData, setFormData] = useState({
    id: null,
    google: null,
    apple: null,
  });

  const [socail, setSocail] = useState([]);

  let getAboutData = async () => {
    let response = await MainDataService.getData();
    setFormData({
      id: response.data.about._id,
      google: response.data.about.google,
      apple: response.data.about.apple,
    });
    setSocail(response.data.about.social);
  };

  useEffect(() => {
    getAboutData();
  }, []);

  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2500);
  }, []);
  return (
    <div style={{ overflow: showLoading && 'overflow-hidden' }}>
      {showLoading &&
        ReactDOM.createPortal(
          <div style={{ width: `${window.innerWidth}px`, height: `${window.innerHeight}px` }}>
            <Loading />
          </div>,
          document.getElementById('overlays'),
        )}
      <div style={{ opacity: showLoading ? 0 : 1 }}>
        <NavBar />
        <div className="overflow-hidden">
          <SwiperImages />
          <section id="about-us">
            <Section1 data={formData} />
          </section>
          <section id="deals">
            <Section2 />
            <Section3 />
            <Section4 />
            <Section42 />
          </section>
          <section id="partners" className="mb-5 pb-5">
            <Section5 />
          </section>
          <section id="contact-us">
            <Section6 />
          </section>
          <Footer social={socail} data={formData} />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
