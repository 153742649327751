import React, { useEffect, useState } from 'react';
import p1 from '../../assests/3f0d6784aaf304bbf92e0a669634a63c.png';
import p2 from '../../assests/0426e86ee73b073c3b4ea7cc10006a45.png';
import p3 from '../../assests/035ad90a96f63f2dbffaa45dbcf49046.png';
import p4 from '../../assests/Rectangle.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';
export default function Section5() {
  const [width, setWidth] = useState(1400);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);
  return (
    <div>
      <div className="container">
        <div
          style={{
            height: `${window.innerHeight - 90}px`,
          }}
          className="row  px-0 mx-0 align-items-xl-center align-items-center  d-flex"
        >
          <h1 className="title-partners text-cenetr">Our Partners</h1>
          <div className="row mx-auto justify-content-cenetr">
            <Swiper
              slidesPerView={1}
              spaceBetween={50}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1025: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1441: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              loop={true} // Set loop to true to enable looping
              loopFillGroupWithBlank={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="text-center w-auto mx-auto"
            >
              {[p1, p2, p3, p4, p1, p2, p3, p4].map((item1, index1) => (
                <SwiperSlide
                  key={index1}
                  style={{
                    cursor: 'pointer',
                  }}
                  className={`text-xl-start text-center px-0 pt-0 mt-0 mx-xl-5 w-xl-auto  px-0 `}
                >
                  <img
                    style={{ width: 'fit-content', minHeight: '150px', minWidth: '150px', maxHeight: '150px', maxWidth: '150px' }}
                    src={item1}
                    alt="feature_image"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <button className="w-auto  btn-partners mx-auto">Join Eshtarena Partners</button>
        </div>
      </div>
    </div>
  );
}
