import Close from '../../SVG/Close';
import Button from '../../UI/Button/Button';
import classes from './AddMember.module.scss';
import React, { useState, useEffect } from 'react';
import InputGroup from '../../UI/InputGroup/InputGroup';
import UploadImageGroup from '../../UI/InputGroup/UploadImageGroup';
import { errorActions } from '../../../../store/reducers/errorSlice';
import { mainDataHelper } from '../../../../Helper/MainData/MainData';
import { categoryService } from '../../../../services/categoryService';
import { subcategoryService } from '../../../../services/subCategoryService';
import { serverErrorCatcher } from '../../../../Helper/ServerErrorCatcher';
import { useDispatch } from 'react-redux';
export default function AddCategory(props) {
  //---------------------------Declarations-----------------------------
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showError, setShowError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [categoriesName, setCategoriesName] = useState([]);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [data, setData] = useState({
    titleEN: '',
    titleAR: '',
    parent: '',
    pic: '',
  });

  //---------------------------Functions-----------------------------
  let handleSubmit = async () => {
    setShowError(true);
    if (props.formType === 'Category') {
      if (data.titleEN && data.titleAR && data.pic) {
        let fd = new FormData();
        fd.append('name_en', data.titleEN);
        fd.append('name_ar', data.titleAR);
        data.pic.name && fd.append('categoryImg', data.pic, data.pic.name);
        let response = props.type === 'Add' ? await categoryService.addCategory(fd) : await categoryService.editCategory(fd, props.data._id);

        let Valid = false;
        Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

        if (Valid === true) {
          props.onSuccess();
          setLoading(false);
        } else {
          dispatch(
            errorActions.setError({
              error: true,
              errorMsg: localStorage.getItem('errorMsg'),
            }),
          );
          setLoading(false);
        }
      }
      setLoading(false);
    } else {
      if (data.titleEN && data.titleAR && data.pic && data.parent) {
        let fd = new FormData();
        fd.append('name_en', data.titleEN);
        fd.append('name_ar', data.titleAR);
        fd.append('category', data.parent);
        data.pic.name && fd.append('subcategoryImg', data.pic, data.pic.name);
        let response =
          props.type === 'Add' ? await subcategoryService.addSubcategory(fd) : await subcategoryService.editSubcategory(fd, props.data._id);
        let Valid = false;
        //console.log(data.parent)
        //console.log(response)
        Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
        if (Valid === true) {
          props.onSuccess();
          setLoading(false);
        } else {
          dispatch(
            errorActions.setError({
              error: true,
              errorMsg: localStorage.getItem('errorMsg'),
            }),
          );
          setLoading(false);
        }
        setLoading(false);
      }
      setLoading(false);
    }
    setLoading(false);
  };
  //---------------------------Use Effects-----------------------------
  useEffect(() => {
    props.formType === 'Sub Category' && mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);

    props.data && props.formType === 'Category'
      ? setData({
          titleEN: props.data.name_en,
          titleAR: props.data.name_ar,
          pic: props.data.categoryImg,
        })
      : setData({
          titleEN: props.data.name_en,
          titleAR: props.data.name_ar,
          pic: props.data.subcategoryImg,
        });
  }, []);

  useEffect(() => {
    if (props.type === 'Edit' && props.formType === 'Sub Category') {
      categories.map((item) => {
        if (item.name_en === props.data.category) {
          setSelectedCategory(item.name_en);
          setData((prev) => ({ ...prev, parent: item._id }));
        }
      });
    }
  }, [categories]);

  return (
    <>
      {((props.type === 'Edit' && data.pic && props.formType === 'Category') ||
        (props.type === 'Edit' && selectedCategory && props.formType === 'Sub Category') ||
        props.type === 'Add') && (
        <>
          <div className="row mx-0 px-0 ">
            <div className={`col-10 px-0 ${classes.title}`}>
              {props.type} {props.formType}
            </div>
            <div onClick={() => props.setShowForm(false)} className="cursor col-2 px-0 text-end">
              <Close color="#134074" />
            </div>
          </div>

          <div className="row px-0 mx-0">
            <div className="col-6 px-0 pe-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.titleEN}
                  name="titleEN"
                  onChange={(e) => setData((prev) => ({ ...prev, titleEN: e.target.value }))}
                  error={showError && !data.titleEN}
                  errorMsg="Provide an English Title"
                  value={'title'}
                />
              </div>
            </div>
            <div className="col-6 px-0 ps-2">
              <div className={classes.input}>
                <InputGroup
                  name="titleAR"
                  value={'الأسم'}
                  default={data.titleAR}
                  error={showError && !data.titleAR}
                  errorMsg="من فضلك سجل الأسم باللغة العربية"
                  onChange={(e) => setData((prev) => ({ ...prev, titleAR: e.target.value }))}
                />
              </div>
            </div>
          </div>
          {props.formType === 'Sub Category' && (
            <div className={classes.input}>
              <InputGroup
                type="dropdown"
                name="industry"
                value={`Parent Category`}
                default={selectedCategory || 'No Selection'}
                error={showError && !data.parent}
                errorMsg="Select a Parent Category"
                options={['No Selection', ...categoriesName]}
                onChange={(e) => {
                  if (e.target.value !== 'No Selection') {
                    categories.map((item) => {
                      if (item.name_en === e.target.value) {
                        setSelectedCategory(e.target.value);
                        setData((prev) => ({ ...prev, parent: item._id }));
                      }
                    });
                  } else {
                    setData((prev) => ({ ...prev, parent: false }));
                    setSelectedCategory(e.target.value);
                    // setSelectedCategory(""/);
                  }
                }}
              />
            </div>
          )}
          <div className="row px-0 mx-0">
            <div className={classes.input}>
              <UploadImageGroup
                png={true}
                name="pic"
                default={data.pic}
                type={props.formType}
                error={showError && !data.pic}
                value={`${props.formType} Picture`}
                errorMsg={`Provide an ${props.formType} image  `}
                onRemove={() => setData((prev) => ({ ...prev, pic: null }))}
                onChange={(e) => setData((prev) => ({ ...prev, pic: e.target.files[0] }))}
              />
            </div>
          </div>
          {serverError && (
            <div className="text-center">
              <span className="errorMsg text-cenetr">{serverErrorMsg}</span>
            </div>
          )}
          <div className="row mx-0 px-0  mb-pb-0">
            <div className="col-4 ms-auto px-0  mb-pb-0">
              <div className={`  mb-pb-0`}>
                {loading ? (
                  <div className="text-center">
                    <span className="spinner-border spinner-border-sm"></span>
                  </div>
                ) : (
                  <Button
                    type="normal"
                    color={'#D1E6FF'}
                    fontColor={'#134074'}
                    borderColor={'#134074'}
                    className={'w-auto px-2 ms-auto text-end'}
                    text={
                      props.type === 'Add'
                        ? `Add ${props.formType === 'Sub Category' ? 'Sub Category' : 'Category'}`
                        : `Edit ${props.formType === 'Sub Category' ? 'Sub Category' : 'Category'}`
                    }
                    onClick={() => {
                      setLoading(true);
                      handleSubmit();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
//Done
