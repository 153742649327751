import React from "react";
import classes from "./AddMember.module.scss";
import Close from "../../SVG/Close";
export default function ShowAreaDistrict(props) {
  return (
    <>
      <div className="row mx-0 px-0 mb-2">
        <div className={`col-10 px-0 ${classes.title}`}>Area District</div>
        <div
          onClick={() => {
            props.setShowForm(false);
          }}
          className="cursor col-2 px-0 text-end"
        >
          <Close color="#134074" />
        </div>
      </div>

      <div className={`px-0 ${classes.title}`}>{props.area.city.city_en}</div>
      <div className="row px-0 mx-0">
        {props.area.district.map((item, index) => (
          <span
            key={index}
            className={`w-auto px-0 pe-3 d-inline ${classes["district"]}`}
          >
            {item.district_en}
          </span>
        ))}
      </div>
    </>
  );
}
