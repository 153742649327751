import React, { useEffect, useState } from 'react';
import classes from './Manager.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import PeopleActiveL from '../../components/Reusable/SVG/PeopleActiveL';
import Plus from '../../components/Reusable/SVG/Plus';
import Img from '../../components/Reusable/Common/Img';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import ManagerCard from '../../components/Reusable/Common/ManagerCard/ManagerCard';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import AddMemberManager from '../../components/Reusable/Common/AddMemberCard/AddMemberManager';
import Table from '../../components/Reusable/Common/Table/Table';
import { Radio, Skeleton } from '@mui/material';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import Dropdown from '../../components/Reusable/SVG/Dropdown';
import SingleAd from '../../components/Reusable/Common/AddMemberCard/SingleAd';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import { useNavigate } from 'react-router-dom';
import AdActiveL from '../../components/Reusable/SVG/AdActiveL';
import AdviceActiveL from '../../components/Reusable/SVG/AdviceActiveL';
import { managerAccountsService } from '../../services/managerAccountsService';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import { adsService } from '../../services/adsService';
import AdsCard from '../../components/Reusable/Common/Ads/AdsCard';
import { adviceService } from '../../services/adviceService';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import { subcategoryService } from '../../services/subCategoryService';
import { categoryService } from '../../services/categoryService';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function Advice(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [option, setOption] = useState('Advices');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  const [showAddMemberForm, setShowAddMemberForm] = useState(false);
  const [roleFilter, setRoleFilter] = useState('Default');
  const [showSingleAd, setShowSingleAd] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [wholeAdvice, setWholwAdvice] = useState([]);
  const [ads, setAds] = useState([]);
  const [advices, setAdvices] = useState([]);
  const [sort, setSort] = useState('default');
  const [role, setRole] = useState('role');
  const [status, setStatus] = useState('select Status');
  const [searchValue, setSearchValue] = useState('');
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('select category');
  const [selectedSubCategory, setSelectedSubCategory] = useState('select subCategory');

  useEffect(() => {
    mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
  }, []);
  useEffect(() => {
    selectedCategory !== 'select category' &&
      mainDataHelper.getAllSubCategories(setServerError, setServerErrorMsg, selectedCategory, categories, setSubCategories, setSubCategoriesName);
  }, [selectedCategory]);

  useEffect(() => {
    //console.log(roleFilter);
  }, [roleFilter]);
  let getAds = async () => {
    let response = await adsService.getAds(page, 8);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      //console.log(response);
      setTotalPages(response.data.totalPages);
      setAds(response.data.ads);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };
  let getAdvices = async () => {
    let query = '';
    query += `${searchValue ? `&search=${searchValue}` : ''}`;
    query += `${
      selectedCategory && selectedSubCategory !== ' ' && selectedCategory !== 'select category'
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ''
    }`;
    query += `${
      selectedSubCategory && selectedSubCategory !== ' ' && selectedSubCategory !== 'select subCategory'
        ? `&subcategory=${selectedSubCategory.toLowerCase()}`
        : ''
    }`;

    query += `${role && role !== ' ' && role !== 'role' ? `&role=${role}` : ''}`;
    query += `${status && status !== ' ' && status !== 'select Status' ? `&status=${status}` : ''}`;
    query += `${sort && sort !== ' ' && sort !== 'default' ? `&date=${sort.toLowerCase()}` : ''}`;

    let response = await adviceService.getAdvices(page, 8, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      //console.log(response);
      setTotalPages(response.data.totalPages);

      let arr = [];
      setWholwAdvice(response.data.advices);
      response.data.advices.map((item) => {
        let categories = item.categories.map((item) => item.name_en);
        arr.push([
          item.uuid,
          `${item.topManager ? item.topManager.name : `${item.marketingManager.firstName} ${item.marketingManager.lastName}`}`,
          item.role,
          categories,
          item.status,
          new Date(item.startDate).toLocaleDateString(),
          new Date(item.endDate).toLocaleDateString(),
        ]);
      });
      setAdvices(arr);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };
  let getAllManagers = async () => {
    let query = '';
    let response = await managerAccountsService.getAllManagers(
      props.type === 'Category Managers' ? 'category-managers' : 'marketing-managers',
      page,
      8,
      query,
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      //console.log(response);
      setTotalPages(response.data.totalPages);
      setData(props.type === 'Category Managers' ? response.data.categoryManagers : response.data.marketingManagers);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    // setLoading(true);
  });
  useEffect(() => {
    setPage(1);
    setLoading(true);
    setData([]);

    option === 'Member' && getAllManagers();
    option === 'Ads' && getAds();
    option === 'Advice' && getAdvices();
    // setLoading(false);
  }, [props.type, option]);
  useEffect(() => {
    // setPage(1);
    setLoading(true);
    setData([]);

    option === 'Member' && getAllManagers();
    option === 'Ads' && getAds();
    option === 'Advices' && getAdvices();
    // setLoading(false);
  }, [page]);

  useEffect(() => {
    setPage(1);
    setLoading(true);
    setData([]);

    // option === "Member" && getAllManagers();
    // option === "Ads" && getAds();
    option === 'Advices' && getAdvices();
    // setLoading(false);
  }, [role, sort, searchValue, selectedCategory, selectedSubCategory, status]);

  return (
    <div className={`w-100 ${classes['managers']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={confirmationMsg} />
        </Modal>
      )}

      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle
            onClick={() => {
              // setShowAddForm(true);
              history(`/account/advice/new-advice`);
              // option==="Ad"&&setShowAddMemberForm(true);
              // option==="Advice"&&setShowAddMemberForm(true);
            }}
            text={'Advices'}
            icon={<AdviceActiveL />}
            buttonText={`Add Advice`}
            buttonType="normal"
            buttonIcon={<Plus />}
          />
        </div>
        <section className={`${classes['main-section']}`}>
          {(option === 'Advices' || option === 'Advice') && (
            <div className="">
              <div className={`row  align-items-end d-flex mx-0 px-0  ${classes['table-option']}`}>
                <div className="col-xl-3 px-0 mb-xl-0 ">
                  <SearchInput onChange={(val) => setSearchValue(val.target.value)} placeholder={`Search for a Advice`} />
                </div>
                <div className="col-xl-9 px-0 ps-xl-2">
                  <div className="row px-0 mx-0  mb-xl-0 mb-2">
                    <div className="col-xl-2   col-6 pe-1 px-0">
                      <InputGroup
                        options={['select category', ...categoriesName]}
                        filter={true}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        type="dropdown"
                        value={'Category'}
                        default={selectedCategory}
                      />
                    </div>
                    {selectedCategory && (
                      <div className="col-xl-2 col-6 ps-1 pe-1 px-0">
                        <InputGroup
                          options={['select subCategory', ...subCategoriesName]}
                          filter={true}
                          onChange={(e) => setSelectedSubCategory(e.target.value)}
                          type="dropdown"
                          value={'Sub Category'}
                          default={selectedSubCategory}
                        />
                      </div>
                    )}
                    <div className="col-xl-2 col-6 ps-1 pe-1 px-0">
                      <InputGroup
                        options={['select Status', 'Scheduled', 'Running', 'Ended']}
                        filter={true}
                        onChange={(e) => setStatus(e.target.value)}
                        type="dropdown"
                        value={'Status'}
                        default={status}
                      />
                    </div>
                    <div className="col-xl-2 col-6 ps-1 pe-1 px-0">
                      <InputGroup
                        options={['role', 'Top Manager', 'Marketing Manager']}
                        filter={true}
                        onChange={(e) => setRole(e.target.value)}
                        type="dropdown"
                        value={'role'}
                        default={role}
                      />
                    </div>
                    <div className="col-xl-2 col-6 px-0 ps-1">
                      <InputGroup
                        options={['Latest', 'Earliest', 'Default']}
                        filter={true}
                        onChange={(e) => setSort(e.target.value)}
                        type="dropdown"
                        value="Order By"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <Table
                  type="Advice"
                  options={[
                    {
                      title: 'ID',
                      icon: false,
                    },
                    {
                      title: 'Advice Owner',
                      icon: false,
                    },
                    {
                      title: 'Owner Role',
                      icon: false,
                      filterOptions: ['option1', 'option2', 'Default'],
                      setOption: (val) => {
                        setRoleFilter(val);
                      },
                      selectedOption: roleFilter,
                    },
                    {
                      title: 'Category',
                      icon: false,
                    },

                    {
                      title: 'Status',
                      icon: false,
                    },

                    {
                      title: 'Start Date',
                      icon: false,
                    },
                    {
                      title: 'End Date',
                      icon: false,
                    },
                  ]}
                  // wholeData={wholeAdvice}
                  data={[
                    [
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    ],
                    [
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    ],
                    [
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    ],
                    [
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    ],
                    [
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    ],
                  ]}
                  onSuccess={() => {
                    setLoading(true);
                    getAdvices();
                  }}
                  customStyleIndex={[]}
                  filterOption={[]}
                  customStyleFn={[false, false, false, false, false, false]}
                />
              ) : (
                <>
                  <Table
                    type="Advice"
                    options={[
                      {
                        title: 'ID',
                        icon: false,
                      },
                      {
                        title: 'Advice Owner',
                        icon: false,
                      },
                      {
                        title: 'Owner Role',
                        icon: false,
                        filterOptions: ['option1', 'option2', 'Default'],
                        setOption: (val) => setRoleFilter(val),
                        selectedOption: roleFilter,
                      },
                      {
                        title: 'Category',
                        icon: false,
                      },

                      {
                        title: 'Status',
                        icon: false,
                      },

                      {
                        title: 'Start Date',
                        icon: false,
                      },
                      {
                        title: 'End Date',
                        icon: false,
                      },
                    ]}
                    wholeData={wholeAdvice}
                    data={advices}
                    onSuccess={() => {
                      setLoading(true);
                      getAdvices();
                    }}
                    customStyleIndex={[]}
                    filterOption={[]}
                    customStyleFn={[false, false, false, false, false, false]}
                  />
                  {totalPages > 1 && (
                    <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                      <div className="w-auto d-inline mx-auto">
                        <UsePagination page={page} pages={totalPages} onChangePage={(page) => setPage(page)} />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </section>
      </section>
    </div>
  );
}
