import { Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import classes from './Voucher.module.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { errorActions } from '../../store/reducers/errorSlice';
import Button from '../../components/Reusable/UI/Button/Button';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { vouchersSupplierService } from '../../services/VoucherSuppliers';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import VoucherActieveL from '../../components/Reusable/SVG/VoucherActieveL';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import SendResponseForm from '../../components/Reusable/Common/AddMemberCard/SendResponseFrom';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import TermsSections from '../../components/Reusable/Common/TermsSections/TermsSections';
export default function VoucherRequestDetails() {
  //--------------------Declarations------------------------
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [formType, setFormType] = useState();
  const [loading, setLoading] = useState(true);
  const [voucher, setVoucher] = useState(false);
  const [option, setOption] = useState('Main Data');
  const [serverError, setServerError] = useState(false);
  const [loadingButton1, setLoadingButton1] = useState(false);
  const [loadingButton2, setLoadingButton2] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [suggestQuantity, setSuggestQuantity] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showResponseForm, setShowResponseFrom] = useState(false);
  const [suggestVoucherValue, setSuggestVoucherValue] = useState(false);
  const [suggestBuyingPrice, setSuggestBuyingPrice] = useState(false);
  const [suggestExpireDate, setSuggestExpireDate] = useState(false);
  const [data, setData] = useState({
    quantity: null,
    voucherPrice: null,
    buyingPrice: null,
    expireDate: null,
  });
  const [dataErr, setDataErr] = useState({
    quantity: true,
    voucherPrice: true,
    buyingPrice: true,
    expireDate: true,
  });
  //--------------------Functions------------------------
  let handleChange = (e) => {
    if (e.target.name === 'quantity' || e.target.name === 'voucherPrice' || e.target.name === 'buyingPrice') {
      var isANumber = isNaN(e.target.value) === false;

      if (!isANumber || parseFloat(e.target.value) <= 0 || e.target.value.startsWith('-') || e.target.value === '' || e.target.value.startsWith('+'))
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      else setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
    }
    if (e.target.name === 'expireDate') {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000) setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      else {
        setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
      }
    }
  };
  let getSingleVoucher = async () => {
    let response = await vouchersSupplierService.getSingleVoucher(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setVoucher(response.data.voucher);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  let handleSendResponse = async () => {
    setShowError(true);
    //console.log(dataErr);
    if (dataErr.buyingPrice && dataErr.quantity && dataErr.voucherPrice && dataErr.expireDate) {
      if (data.quantity && data.buyingPrice && data.expireDate && data.voucherPrice) {
        let response = await vouchersSupplierService.sendVoucherResponse({
          quantity: data.quantity,
          buyingPrice: data.buyingPrice,
          voucherPrice: data.voucherPrice,
          expireDate: data.expireDate,
          voucherId: id,
        });
        let Valid = false;
        Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

        if (Valid === true) {
          setShowConfirmation(true);
          setTimeout(() => {
            setShowConfirmation(false);
            history('/supplier/voucher', { replace: true });
          }, 2000);
        } else {
          dispatch(
            errorActions.setError({
              error: true,
              errorMsg: localStorage.getItem('errorMsg'),
            }),
          );
        }
      }
    }

    setLoadingButton1(false);
    setLoadingButton2(false);
  };
  let handleSendResponseAccepted = async () => {
    let response = await vouchersSupplierService.sendVoucherResponse({
      quantity: 'Accepted',
      buyingPrice: 'Accepted',
      voucherPrice: 'Accepted',
      expireDate: 'Accepted',
      voucherId: id,
    });

    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
        history('/supplier/voucher', { replace: true });
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }

    setLoadingButton1(false);
    setLoadingButton2(false);
  };
  //--------------------UseEffects------------------------
  useEffect(() => {
    getSingleVoucher();
  }, [id]);

  return (
    <div className={`w-100 ${classes['voucher']}`}>
      {serverError && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showConfirmation && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup message1={'Response Sent Successufully'} />
        </Modal>
      )}

      {showResponseForm && (
        <Modal
          onClose={() => {
            setShowResponseFrom(false);
          }}
          style={{ padding: '24px' }}
        >
          <SendResponseForm data={formType === 'Response' ? data : null} type={formType} id={id} setShowForm={setShowResponseFrom} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle
            onClick={() => {
              if (!loading) {
                setFormType('Rejection');
                setShowResponseFrom(true);
              }
            }}
            text="Voucher Preparation Response"
            icon={<VoucherActieveL />}
            buttonText={`Reject voucher request`}
            buttonType="reverse"
            borderColor="red"
            fontColor="red"
            color="transparent"
          />
        </div>
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <>
              {[0, 1, 2, 3, 4].map((item, index) => (
                <div key={index} className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </section>
        ) : (
          <section className={`${classes['main-section']}`}>
            <div className={`row  ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={['Main Data', 'Supplier Payment Terms', 'Customer Payment Terms']}
              />
            </div>
            {option === 'Main Data' && (
              <>
                <div className={`row   mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-lg-6">
                    <div dir="auto" className={`${classes['single-item']} row  align-items-center`}>
                      <p className="w-auto d-inline pe-3">Voucher Title</p>
                      <span className="w-auto d-inline">{voucher.title_en}</span>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div dir="auto" className={`${classes['single-item']} row text-lg-end px-0 align-items-center`}>
                      <div className={classes['single-item']}>
                        <p className="w-auto d-inline pe-3">Voucher Code</p>
                        <span className="w-auto d-inline">{voucher.uuid}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-3 col-6 px-0 pe-2">
                    <div className={classes.input}>
                      <p>Quantity</p>
                    </div>
                  </div>
                  {suggestQuantity ? (
                    <div className="col-lg-3 px-0 col-6 pe-2">
                      <div className={classes.input}>
                        <InputGroup
                          onChange={(e) => {
                            handleChange(e);
                            setData((prev) => ({
                              ...prev,
                              quantity: e.target.value,
                            }));
                          }}
                          noWrap={true}
                          default={data.quantity}
                          name="quantity"
                          error={showError && !dataErr.quantity}
                          errorMsg={data.quantity ? 'Provide a Valid Quantity' : 'Provide a Quantity'}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-3 px-0 col-6 pe-2">
                      <p>{voucher.quantity}</p>
                    </div>
                  )}
                  <div
                    className={`w-auto d-inline ${
                      showError && !dataErr.quantity ? 'align-self-center pb-2' : 'align-self-end'
                    } mx-lg-0 ms-auto px-0 pe-2`}
                  >
                    <div>
                      <p>
                        <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                          <Button
                            onClick={() => {
                              if (!suggestQuantity) {
                                if (data.quantity !== 'Accepted') {
                                  setData((prev) => ({
                                    ...prev,
                                    quantity: 'Accepted',
                                  }));
                                  setDataErr((prev) => ({
                                    ...prev,
                                    quantity: true,
                                  }));
                                } else {
                                  setDataErr((prev) => ({
                                    ...prev,
                                    quantity: false,
                                  }));
                                }
                              }
                              setSuggestQuantity(false);
                            }}
                            className={`w-auto d-inline ${data.quantity === 'Accepted' ? 'px-2' : 'px-3 '} ms-auto text-end`}
                            type={data.quantity === 'Accepted' ? 'reverse' : 'normal'}
                            text={suggestQuantity ? 'Cancel' : data.quantity === 'Accepted' ? 'Accepted ' : 'Accept'}
                            color={data.quantity === 'Accepted' ? 'transparent' : '#D1E6FF'}
                            borderColor={data.quantity === 'Accepted' ? 'transparent' : '#134074'}
                            fontColor={'#134074'}
                          />
                        </div>
                        <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                          <Button
                            onClick={() => {
                              if (data.quantity === 'Accepted') {
                                setData((prev) => ({
                                  ...prev,
                                  quantity: null,
                                }));
                                setDataErr((prev) => ({
                                  ...prev,
                                  quantity: false,
                                }));
                                setSuggestQuantity(false);
                              } else {
                                setDataErr((prev) => ({
                                  ...prev,
                                  quantity: false,
                                }));
                                setSuggestQuantity(true);
                              }
                            }}
                            className={'w-auto d-inline px-3   '}
                            type="reverse"
                            text={data.quantity === 'Accepted' ? 'Cancel' : 'Suggest'}
                          />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-3 col-6 px-0 pe-2">
                    <div className={classes.input}>
                      <p>Voucher value</p>
                    </div>
                  </div>
                  {suggestVoucherValue ? (
                    <div className="col-lg-3 px-0 col-6 pe-2">
                      <div className={classes.input}>
                        <InputGroup
                          onChange={(e) => {
                            handleChange(e);
                            setData((prev) => ({
                              ...prev,
                              voucherPrice: e.target.value,
                            }));
                          }}
                          noWrap={true}
                          name="voucherPrice"
                          error={showError && !dataErr.voucherPrice}
                          errorMsg={data.voucherPrice ? 'Provide a Valid Voucher Value' : 'Provide a Voucher Value'}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-3 px-0 col-6 pe-2">
                      <p>{voucher.voucherPrice}</p>
                    </div>
                  )}
                  <div
                    className={`w-auto d-inline ${
                      showError && !dataErr.voucherPrice ? 'align-self-center pb-2' : 'align-self-end'
                    } mx-lg-0 ms-auto px-0 pe-2`}
                  >
                    <div>
                      <p>
                        <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                          <Button
                            onClick={() => {
                              if (!suggestVoucherValue) {
                                if (data.voucherPrice !== 'Accepted') {
                                  setData((prev) => ({
                                    ...prev,
                                    voucherPrice: 'Accepted',
                                  }));
                                  setDataErr((prev) => ({
                                    ...prev,
                                    voucherPrice: true,
                                  }));
                                } else {
                                  setDataErr((prev) => ({
                                    ...prev,
                                    voucherPrice: false,
                                  }));
                                }
                              }
                              setSuggestVoucherValue(false);
                            }}
                            className={`w-auto d-inline ${data.voucherPrice === 'Accepted' ? 'px-2' : 'px-3 '} ms-auto text-end`}
                            type={data.voucherPrice === 'Accepted' ? 'reverse' : 'normal'}
                            text={suggestVoucherValue ? 'Cancel' : data.voucherPrice === 'Accepted' ? 'Accepted ' : 'Accept'}
                            color={data.voucherPrice === 'Accepted' ? 'transparent' : '#D1E6FF'}
                            borderColor={data.voucherPrice === 'Accepted' ? 'transparent' : '#134074'}
                            fontColor={'#134074'}
                          />
                        </div>
                        <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                          <Button
                            onClick={() => {
                              if (data.voucherPrice === 'Accepted') {
                                setData((prev) => ({
                                  ...prev,
                                  voucherPrice: null,
                                }));
                                setDataErr((prev) => ({
                                  ...prev,
                                  voucherPrice: false,
                                }));
                              } else {
                                setDataErr((prev) => ({
                                  ...prev,
                                  voucherPrice: false,
                                }));
                                setSuggestVoucherValue(true);
                              }
                            }}
                            className={'w-auto d-inline px-3   '}
                            type="reverse"
                            text={data.voucherPrice === 'Accepted' ? 'Cancel' : 'Suggest'}
                          />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-3 col-6 px-0 pe-2">
                    <div className={classes.input}>
                      <p>Eshtreana Buying Price </p>
                    </div>
                  </div>
                  {suggestBuyingPrice ? (
                    <div className="col-lg-3 col-6 px-0 pe-2">
                      <div className={classes.input}>
                        <InputGroup
                          onChange={(e) => {
                            handleChange(e);
                            setData((prev) => ({
                              ...prev,
                              buyingPrice: e.target.value,
                            }));
                          }}
                          name="buyingPrice"
                          error={showError && !dataErr.buyingPrice}
                          errorMsg={data.buyingPrice ? 'Provide a Valid Buying Price' : 'Provide a Buying Price'}
                          noWrap={true}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-3 col-6 px-0 pe-2">
                      <p>{voucher.buyingPrice}</p>
                    </div>
                  )}
                  <div
                    className={`w-auto d-inline ${
                      showError && !dataErr.buyingPrice ? 'align-self-center pb-2' : 'align-self-end'
                    } mx-lg-0 ms-auto px-0 pe-2`}
                  >
                    <div>
                      <p>
                        <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                          <Button
                            onClick={() => {
                              if (!suggestBuyingPrice) {
                                if (data.buyingPrice !== 'Accepted') {
                                  setData((prev) => ({
                                    ...prev,
                                    buyingPrice: 'Accepted',
                                  }));
                                  setDataErr((prev) => ({
                                    ...prev,
                                    buyingPrice: true,
                                  }));
                                } else {
                                  setDataErr((prev) => ({
                                    ...prev,
                                    buyingPrice: false,
                                  }));
                                }
                              }
                              setSuggestBuyingPrice(false);
                            }}
                            className={`w-auto d-inline ${data.buyingPrice === 'Accepted' ? 'px-2' : 'px-3 '} ms-auto text-end`}
                            type={data.buyingPrice === 'Accepted' ? 'reverse' : 'normal'}
                            text={suggestBuyingPrice ? 'Cancel' : data.buyingPrice === 'Accepted' ? 'Accepted ' : 'Accept'}
                            color={data.buyingPrice === 'Accepted' ? 'transparent' : '#D1E6FF'}
                            borderColor={data.buyingPrice === 'Accepted' ? 'transparent' : '#134074'}
                            fontColor={'#134074'}
                          />
                        </div>
                        <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                          <Button
                            onClick={() => {
                              if (data.buyingPrice === 'Accepted') {
                                setData((prev) => ({
                                  ...prev,
                                  buyingPrice: null,
                                }));
                                setDataErr((prev) => ({
                                  ...prev,
                                  buyingPrice: false,
                                }));
                              } else {
                                setDataErr((prev) => ({
                                  ...prev,
                                  buyingPrice: false,
                                }));
                                setSuggestBuyingPrice(true);
                              }
                            }}
                            className={'w-auto d-inline px-3   '}
                            type="reverse"
                            text={data.buyingPrice === 'Accepted' ? 'Cancel' : 'Suggest'}
                          />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-3 col-6 px-0 pe-2">
                    <div className={classes.input}>
                      <p>Expiry Date</p>
                    </div>
                  </div>
                  {suggestExpireDate ? (
                    <div className="col-lg-3 col-6 px-0 pe-2">
                      <div className={classes.input}>
                        <InputGroup
                          type="date"
                          onChange={(e) => {
                            handleChange(e);
                            setData((prev) => ({
                              ...prev,
                              expireDate: e.target.value,
                            }));
                          }}
                          noWrap={true}
                          name={'expireDate'}
                          error={showError && !dataErr.expireDate}
                          errorMsg={data.expireDate ? 'Provide a Valid Expire Data' : 'Provide a Expiry Date'}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-3 col-6 px-0 pe-2">
                      <p>{voucher.expireDate.slice(0, 10)}</p>
                    </div>
                  )}
                  <div
                    className={`w-auto d-inline ${
                      showError && !dataErr.expireDate ? 'align-self-center pb-2' : 'align-self-end'
                    } mx-lg-0 ms-auto px-0 pe-2`}
                  >
                    <div>
                      <p>
                        {
                          <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                            <Button
                              onClick={() => {
                                if (!suggestExpireDate) {
                                  if (data.expireDate !== 'Accepted') {
                                    setData((prev) => ({
                                      ...prev,
                                      expireDate: 'Accepted',
                                    }));
                                    setDataErr((prev) => ({
                                      ...prev,
                                      expireDate: true,
                                    }));
                                  } else {
                                    setDataErr((prev) => ({
                                      ...prev,
                                      expireDate: false,
                                    }));
                                  }
                                }
                                setSuggestExpireDate(false);
                              }}
                              className={`w-auto d-inline ${data.expireDate === 'Accepted' ? 'px-2' : 'px-3 '} ms-auto text-end`}
                              type={data.expireDate === 'Accepted' ? 'reverse' : 'normal'}
                              text={suggestExpireDate ? 'Cancel' : data.expireDate === 'Accepted' ? 'Accepted ' : 'Accept'}
                              color={data.expireDate === 'Accepted' ? 'transparent' : '#D1E6FF'}
                              borderColor={data.expireDate === 'Accepted' ? 'transparent' : '#134074'}
                              fontColor={'#134074'}
                            />
                          </div>
                        }
                        <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                          <Button
                            onClick={() => {
                              if (data.expireDate === 'Accepted') {
                                setData((prev) => ({
                                  ...prev,
                                  expireDate: null,
                                }));
                                setDataErr((prev) => ({
                                  ...prev,
                                  expireDate: false,
                                }));
                              } else {
                                setSuggestExpireDate(true);
                                setDataErr((prev) => ({
                                  ...prev,
                                  expireDate: false,
                                }));
                              }
                            }}
                            className={'w-auto d-inline   px-3   '}
                            type="reverse"
                            text={data.expireDate === 'Accepted' ? 'Cancel' : 'Suggest'}
                          />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                <div className={`row px-0 mx-0  my-4 justify-content-end`}>
                  {data.quantity && data.buyingPrice && data.voucherPrice && data.expireDate && (
                    <div className="col-lg-3 col-12 d-inline my-lg-0 my-2   pe-lg-2">
                      {loadingButton2 ? (
                        <Button
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          className={'col-12'}
                          type="normal"
                          text={
                            <div className="text-center px-5 my-0 py-0">
                              <span className="spinner-border text-white spinner-border-sm"></span>
                            </div>
                          }
                        />
                      ) : (
                        <Button
                          onClick={() => {
                            setLoadingButton2(true);
                            handleSendResponse();
                          }}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          className={'col-12'}
                          type="normal"
                          text={'Send Response'}
                        />
                      )}
                    </div>
                  )}
                  <div className="col-xl-3 col-lg-6 col-12 d-inline my-lg-0 my-2   pe-lg-2 ps-2">
                    {loadingButton1 ? (
                      <div className="text-center px-5 my-0 py-0">
                        <span className="spinner-border text-white spinner-border-sm"></span>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setDataErr({
                            quantity: true,
                            buyingPrice: true,
                            voucherPrice: true,
                            expireDate: true,
                          });
                          setData({
                            quantity: 'Accepted',
                            buyingPrice: 'Accepted',
                            voucherPrice: 'Accepted',
                            expireDate: 'Accepted',
                          });
                          setLoadingButton1(true);
                          handleSendResponseAccepted();
                        }}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        type="normal"
                        text={'Accept All and Send Response'}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {option === 'Supplier Payment Terms' && <TermsSections terms={voucher.supplierPaymentTerms} border={true} />}
            {option === 'Customer Payment Terms' && <TermsSections terms={voucher.customerPaymentTerms} border={true} />}
          </section>
        )}
      </section>
    </div>
  );
}
//Done
