import React from 'react';

export default function Error() {
  return (
    <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33 20.9897V35.2085" stroke="#F14949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M57.5914 23.2374V41.7623C57.5914 44.7957 55.9664 47.6124 53.3393 49.1562L37.2518 58.4458C34.6247 59.9624 31.3746 59.9624 28.7205 58.4458L12.633 49.1562C10.0059 47.6395 8.38086 44.8228 8.38086 41.7623V23.2374C8.38086 20.2041 10.0059 17.3873 12.633 15.8436L28.7205 6.554C31.3476 5.03734 34.5976 5.03734 37.2518 6.554L53.3393 15.8436C55.9664 17.3873 57.5914 20.177 57.5914 23.2374Z"
        stroke="#F14949"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M33 43.875V44.1458" stroke="#F14949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
