import React from 'react';
import classes from './AddMember.module.scss';
import Close from '../../SVG/Close';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function SingleDealOffer(props) {
  return (
    <div style={{ maxHeight: `${window.innerHeight - 112}px`, overflow: 'scroll' }}>
      <div className="row px-0 mx-0">
        <div className={`col-10 px-0 ${classes.title}`}>Offer Details</div>
        <div onClick={() => props.setShowForm(false)} className="cursor col-2 px-0 text-end">
          <Close color="#134074" />
        </div>
      </div>
      <>
        <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
          <div className="col-lg-4 col-md-6 col-12 px-0">
            <div className={classes['single-item']}>
              <p style={{ color: '#134074' }}>Quantity</p>
              <span className="d-block">{props.data.quantity}</span>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 px-0">
            <div className={classes['single-item']}>
              <p style={{ color: '#134074' }}>Unit</p>
              <span className="d-block">{props.unit}</span>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 px-0">
            <div className={classes['single-item']}>
              <p style={{ color: '#134074' }}>Unit Price</p>
              <span className="d-block">{props.data.price}</span>
            </div>
          </div>
        </div>
        {props.specialSpecification && (
          <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
            <div className="col-12 px-0">
              <div className={classes['single-item']}>
                <p style={{ color: '#134074' }}>Special Specification</p>
                <span className="d-block">{props.specialSpecification}</span>
              </div>
            </div>
          </div>
        )}

        {props.data.color.length > 0 ? (
          props.data.color.map((item, index) => (
            <div key={index} className={`row   px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
              <div
                style={{
                  background: `${item.title}`,
                  width: '5px',
                  borderRadius: '10px',
                  boxSizing: 'content-box',
                  marginInlineEnd: '8px',
                }}
                className="col-1"
              ></div>
              <div style={{ minWidth: '150px' }} className="col-2  px-0 pt-3">
                <div className={classes['single-item']}>
                  <p style={{ color: '#134074' }} className="text-black ">
                    {item.title}
                  </p>
                  <span className="d-block text-black ">
                    &nbsp; &nbsp;{item.quantity} {props.unit}
                  </span>
                </div>
              </div>
              <div className="col-8 cursor">
                <Swiper
                  slidesPerView={2}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1025: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1441: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                  }}
                  loop={false}
                  loopFillGroupWithBlank={true}
                  className="mySwiper    align-items-center  p-0 "
                >
                  {item.size.map((item1, index1) => (
                    <SwiperSlide key={index1} style={{ minWidth: '150px' }} className={` text-start px-0 pt-0 mt-0 me-3  w-auto px-0 pt-3`}>
                      <div className="  px-0">
                        <div className={classes['single-item']}>
                          <p style={{ color: '#134074' }}>{item1.title}</p>
                          <span className="text-black d-block">
                            {item1.quantity} {props.unit}
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12 cursor">
            <Swiper
              slidesPerView={2}
              // width={props.type === 'Product' && 500}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1025: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1441: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}
              loop={false}
              loopFillGroupWithBlank={true}
              //   navigation={true}
              //   modules={[Pagination, Navigation]}
              style={
                {
                  // height: props.type === "product" && "100px",
                  // width: "100%",
                }
              }
              className="mySwiper    align-items-center  p-0 "
            >
              {props.data.size.map((item1, index1) => (
                <SwiperSlide
                  key={index1}
                  // onClick={() => setShowImages(true)}
                  // key={index}
                  style={{ minWidth: '150px' }}
                  // className="col-12 px-0"
                  className={` text-start px-0 pt-0 mt-0 me-3  w-auto px-0 pt-3`}
                >
                  <div className="  px-0">
                    <div className={classes['single-item']}>
                      <p style={{ color: '#134074' }}>{item1.title}</p>
                      <span className="text-black d-block">
                        {item1.quantity} {props.unit}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </>
    </div>
  );
}
