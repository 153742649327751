import axios from "axios";

export const supplierTopManagerService = {
  addBranch,
  addNewSupplier,
  getSingleSupplierBranches,
  getSingleSupplierData,
  getSingleSupplierMedia,
  toggelActivateSupplier,
  updateBranch,
  updateSupplier,
  getAllSuppliers,
  deleteBranch,
  getSuppliersColdDeal,
  getSuppliersOriginalDeal,
  getSuppliersVoucher,
};

async function getAllSuppliers(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/top-manger/suppliers?${
        page ? `page=${page}` : ""
      }${size ? `&size=${size}` : ""}${query ? query : ""}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

async function addNewSupplier(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/accounts/new-supplier`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function updateSupplier(data, id) {
  try {
    var config = {
      method: "put",
      maxBodyLength: Infinity,
      url:
        localStorage.getItem("side") === "Supplier"
          ? `${process.env.REACT_APP_API_BASE_URL}/supplier`
          : `${process.env.REACT_APP_API_BASE_URL}/accounts/supplier/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

async function getSingleSupplierData(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        localStorage.getItem("side") === "Supplier"
          ? `${process.env.REACT_APP_API_BASE_URL}/supplier/data`
          : `${process.env.REACT_APP_API_BASE_URL}/top-manger/supplier-data/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSingleSupplierMedia(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        localStorage.getItem("side") === "Supplier"
          ? `${process.env.REACT_APP_API_BASE_URL}/supplier/media`
          : `${process.env.REACT_APP_API_BASE_URL}/top-manger/supplier-media/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSingleSupplierBranches(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        localStorage.getItem("side") === "Supplier"
          ? `${process.env.REACT_APP_API_BASE_URL}/supplier/branchs`
          : `${process.env.REACT_APP_API_BASE_URL}/top-manger/supplier-branches/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function addBranch(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/new-branch`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function updateBranch(id, data) {
  try {
    var config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/branch/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function deleteBranch(id) {
  try {
    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/branch/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function toggelActivateSupplier(id) {
  try {
    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/top-manger/supplier/activation/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

async function getSuppliersOriginalDeal(id, page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/supplier/original-deals/${id}?${page ? `page=${page}` : ""}${
        size ? `&size=${size}` : ""
      }${query ? query : ""}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSuppliersColdDeal(id, page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/cold-deals/${id}?${
        page ? `page=${page}` : ""
      }${size ? `&size=${size}` : ""}${query ? query : ""}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSuppliersVoucher(id, page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/supplier/voucher-deals/${id}?${page ? `page=${page}` : ""}${
        size ? `&size=${size}` : ""
      }${query ? query : ""}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
