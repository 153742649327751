import React, { useState } from "react";
import classes from "./InputFeild.module.scss";
import Eye1 from "../../SVG/Eye1";
import Eye2 from "../../SVG/Eye2";
import Button from "@mui/material/Button/Button";
// import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from "@mui/icons-material/Send";
export default function InputFeild(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(props.default ? props.default : null);
  useState(() => {
    props.default && props.type === "date"
      ? setValue(props.default)
      : setValue(props.default);

    if (props.type === "date")
      document.getElementsByClassName("input").value = new Date(props.default);
  }, [props.default]);
  return (
    <>
    
    <div style={{height:"40px !important"}} className={`${classes.eye}  pb-0 h-auto position-relative `}>
      {props.type === "password" && (
        <div
          onClick={() => {
            setShowPassword((prev) => !prev);
          }}
          style={{ position: "absolute", right: "12px", top: "10px" }}
          className="position-absolute"
        >
          {showPassword ? <Eye2 /> : <Eye1 />}
        </div>
      )}
      {props.clear && (
        <div
          onClick={() => {
            setValue("");
            props.handleClear();
          }}
          style={{ right: "5px", top: "9px" }}
          className="position-absolute"
        >
          <SendIcon />
        </div>
      )}
      {props.type === "File" ? (
        <div className=" position-relative">
          <input
            disabled={props.disable}
            value={value}
            onChange={(e) => {
              props.onChange(e);
              setValue(e.target.value);
            }}
            id={props.name}
            name={props.name}
            type={props.type ? (showPassword ? "text" : props.type) : "text"}
            className={`${classes["input-feild"]}  position-absolute ${
              props.error && classes.error
            } ${props.className}`}
            placeholder={props.placeholder}
          />
          <input
            disabled={props.disable}
            value={value}
            onChange={(e) => {
              props.onChange(e);
              setValue(e.target.value);
            }}
            id={props.name}
            name={props.name}
            type={"text"}
            className={`${classes["input-feild"]} ${
              props.error && classes.error
            }`}
            placeholder={props.placeholder}
          />
        </div>
      ) : (
        <input
          disabled={props.disable}
          value={value}
          onChange={(e) => {
            props.onChange(e);
            setValue(e.target.value);
          }}
          id={props.name}
          name={props.name}
          // minDate={Date.now()}
          type={props.type ? (showPassword ? "text" : props.type) : "text"}
          className={`input ${classes["input-feild"]} ${
            props.error && classes.error
          }`}
          placeholder={props.placeholder}
        />
      )}
    </div>
    </>
  );
}
