import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducers/authSlice";
import { errorReducer } from "./reducers/errorSlice";
import { scanReducer } from "./reducers/scanSlice";
export const store = configureStore({
  reducer: {
    user: authReducer,
    error: errorReducer,
    scan: scanReducer,
  },
});
