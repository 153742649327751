import axios from 'axios';

export const contactUsService = {
  getContactRequest,
  getProductRequest,
};

async function getContactRequest(page, size, query) {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/contact-us?page=${page}&size=${size}${query ? query : ''}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

async function getProductRequest(page, size, query) {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/product/user/requests?page=${page}&size=${size}${query ? query : ''}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
