import { areaService } from '../../services/areaService';
import { categoryService } from '../../services/categoryService';
import { policiesService } from '../../services/policiesService';
import { subcategoryService } from '../../services/subCategoryService';
import { supplierTopManagerService } from '../../services/supplierTopManagerService';
import { serverErrorCatcher } from '../ServerErrorCatcher';

export const mainDataHelper = {
  getCities,
  getDeliveryTerms,
  getSupplierTerms,
  getCustomerTerms,
  getAllCategories,
  getAllCities,
  getAllDistrict,
  getAllSubCategories,
  getSupplierBranches,
};
async function getCities(setServerError, setServerErrorMsg, setAreas) {
  let response = await areaService.getAllCities();
  //console.log(response);
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

  if (Valid === true) {
    setAreas(response.data.areas);
  }
}

async function getDeliveryTerms(setServerError, setServerErrorMsg, setDeliveyryTerms) {
  let response = await policiesService.getAllPolicies('delivery-terms');
  //console.log(response);
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

  if (Valid === true) {
    let arr = [];
    response.data.deliveryTerms.map((item) => {
      arr.push({
        title_en: item.title_en,
        title_ar: item.title_ar,
        content_en: item.content_en,
        content_ar: item.content_ar,
        selected: true,
      });
    });
    setDeliveyryTerms(arr);
  }
}

async function getSupplierTerms(setServerError, setServerErrorMsg, setSupplierTerms) {
  let response = await policiesService.getAllPolicies('payment-policy', 'business');
  //console.log(response);
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

  if (Valid === true) {
    let arr = [];
    response.data.policies.map((item) => {
      arr.push({
        title_en: item.title_en,
        title_ar: item.title_ar,
        content_en: item.content_en,
        content_ar: item.content_ar,
        selected: true,
      });
    });
    setSupplierTerms(arr);
  }
}

async function getCustomerTerms(setServerError, setServerErrorMsg, setCustomerTerms) {
  let response = await policiesService.getAllPolicies('payment-policy', 'consumer');
  //console.log(response);
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

  if (Valid === true) {
    let arr = [];
    response.data.policies.map((item) => {
      arr.push({
        title_en: item.title_en,
        title_ar: item.title_ar,
        content_en: item.content_en,
        content_ar: item.content_ar,
        selected: true,
      });
    });
    setCustomerTerms(arr);
  }
}

async function getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName) {
  let response = await categoryService.getAllCategoriesForSubcategory();
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
  if (Valid === true) {
    setCategories(response.data.categories);
    let arr1 = [];
    response.data.categories.map((item) => arr1.push(item.name_en));
    setCategoriesName(arr1);
  }
}
async function getAllCities(setServerError, setServerErrorMsg, setCities) {
  let response = await areaService.getAllCities();
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
  //console.log(response);
  if (Valid === true) {
    let arr1 = [];
    response.data.areas.map((item) => {
      //console.log(item.city_en);
      arr1.push(item.city_en);
    });
    setCities(arr1);
  }
}
async function getAllDistrict(setServerError, setServerErrorMsg, selectedArea, setDistrict) {
  let response = await areaService.getAllCities();
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
  //console.log(response);
  if (Valid === true) {
    let arr1 = [];
    response.data.areas.map((item) => {
      if (item.city_en === selectedArea)
        item.district.map((i) => {
          arr1.push(i.district_en);
        });
    });
    setDistrict(arr1);
  }
}

async function getAllSubCategories(setServerError, setServerErrorMsg, selectedCategory, categories, setSubCategories, setSubCategoriesName) {
  let id;
  //console.log(categories);
  categories.map((item) => {
    if (item.name_en === selectedCategory) id = item._id;
  });
  let response = await subcategoryService.getAllSubcategoriesOfCategory(id);
  //console.log(response);
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

  if (Valid === true) {
    setSubCategories(response.data.subcategories);
    let arr = response.data.subcategories.map((item) => item.name_en);
    setSubCategoriesName(arr);
  }
}

async function getSupplierBranches(setServerError, setServerErrorMsg, setBranches, setLoading) {
  setLoading(true);
  let response = await supplierTopManagerService.getSingleSupplierBranches(localStorage.getItem('id'));
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
  if (Valid === true) {
    // console.log(response.data.branches);
    setBranches(response.data.branches);
  }
  setLoading(false);
}
