import { Policy } from "@mui/icons-material";
import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const coldDealsEshtreanaService = {
  createColdDeal,
  getDealDetails,
  getDeals,
  changeColdOrderStatus,
  getColdDealOrders,
};

async function createColdDeal(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/cold-deal/new`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

async function getDealDetails(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/cold-deal/details/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getDeals(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/cold-deal/eshtarena?page=${page}&size=${size}${query ? query : ""}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

async function changeColdOrderStatus(id, status) {
  try {
    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/cold-deal/change-status/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: { status },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

async function getColdDealOrders(id, page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/cold-deal/deal-orders/${id}?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
