import React from "react";

export default function Success() {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M142.023 87.423L141.98 87.4447C136.838 91.3878 134.66 98.0976 136.49 104.307L136.511 104.35C139.431 114.219 132.198 124.175 121.915 124.437H121.871C115.379 124.611 109.671 128.75 107.515 134.872V134.894C104.072 144.61 92.3519 148.422 83.8772 142.562C78.6149 138.969 71.6584 138.781 66.1218 142.562H66.1002C57.6257 148.4 45.905 144.61 42.4845 134.872C41.4288 131.884 39.4912 129.286 36.9278 127.423C34.3644 125.56 31.2959 124.518 28.1279 124.436H28.0842C17.8016 124.175 10.5685 114.219 13.488 104.35L13.5096 104.307C15.3392 98.0974 13.1607 91.3875 8.0197 87.4444L7.97605 87.4228C-0.193677 81.1482 -0.193677 68.8614 7.97605 62.5872L8.0197 62.5655C13.1607 58.6225 15.3392 51.9123 13.488 45.7034V45.6598C10.5466 35.7911 17.8013 25.8349 28.0842 25.5735H28.1279C34.5981 25.3992 40.3277 21.2599 42.4845 15.1383V15.1166C45.9047 5.40028 57.6257 1.58788 66.1002 7.44843H66.1218C71.4591 11.1302 78.5176 11.1302 83.8772 7.44843C92.4372 1.5372 104.092 5.45858 107.515 15.1166V15.1383C109.671 21.2382 115.379 25.3995 121.871 25.5735H121.915C132.198 25.8349 139.431 35.7911 136.511 45.6598L136.49 45.7034C134.66 51.9123 136.838 58.6225 141.98 62.5655L142.023 62.5872C150.193 68.8614 150.193 81.1485 142.023 87.423Z"
        fill="#134074"
      />
      <path
        d="M75 115.581C97.4123 115.581 115.581 97.4123 115.581 75C115.581 52.5876 97.4123 34.4188 75 34.4188C52.5876 34.4188 34.4188 52.5876 34.4188 75C34.4188 97.4123 52.5876 115.581 75 115.581Z"
        fill="#91C8E4"
      />
      <path
        opacity="0.1"
        d="M106.159 49.0233C99.1395 43.2489 90.1591 39.7781 80.3693 39.7781C57.9571 39.7781 39.7784 57.9568 39.7784 80.3689C39.7784 90.1588 43.2492 99.1391 49.0234 106.158C40.1051 98.719 34.4244 87.5285 34.4244 74.9994C34.4244 52.587 52.5876 34.4241 74.9997 34.4241C87.5288 34.4241 98.7194 40.1048 106.159 49.0233Z"
        fill="#377FD8"
      />
      <path
        d="M65.3452 90.8865L56.3713 81.3392C54.0211 78.8385 54.1424 74.9065 56.6423 72.5563C59.1422 70.2032 63.0759 70.3295 65.4241 72.8285L69.7078 77.3842L87.9167 56.5723C90.1738 53.9894 94.0995 53.7275 96.6844 55.9878C99.2672 58.248 99.528 62.1726 97.2689 64.7555L74.5477 90.7227C72.1272 93.4863 67.8554 93.5587 65.3452 90.8865Z"
        fill="#FCFCFC"
      />
    </svg>
  );
}
