import React from "react";

export default function Deal(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1113 6.46799L14.6113 2.34299C14.5005 2.28207 14.3762 2.25012 14.2498 2.25012C14.1234 2.25012 13.999 2.28207 13.8883 2.34299L6.38827 6.46799C6.27068 6.53274 6.17262 6.62785 6.10432 6.74342C6.03603 6.85898 6 6.99076 6 7.12499C6 7.25923 6.03603 7.39101 6.10432 7.50657C6.17262 7.62213 6.27068 7.71725 6.38827 7.78199L13.4998 11.6932V19.7325L11.2228 18.48L10.4998 19.7932L13.8883 21.657C13.999 21.7181 14.1233 21.7501 14.2498 21.7501C14.3762 21.7501 14.5006 21.7181 14.6113 21.657L22.1113 17.532C22.2289 17.4673 22.327 17.3722 22.3953 17.2566C22.4637 17.141 22.4997 17.0093 22.4998 16.875V7.12499C22.4997 6.99073 22.4637 6.85894 22.3953 6.74338C22.327 6.62781 22.2289 6.53271 22.1113 6.46799ZM14.2498 3.85649L20.1935 7.12499L14.2498 10.3935L8.30602 7.12499L14.2498 3.85649ZM20.9998 16.4317L14.9998 19.7317V11.6925L20.9998 8.39249V16.4317Z"
        fill={props.color || "#134074"}
      />
      <path
        d="M7.5 12H1.5V10.5H7.5V12ZM9 18H3V16.5H9V18ZM10.5 15H4.5V13.5H10.5V15Z"
        fill={props.color || "#134074"}
      />
    </svg>
  );
}
