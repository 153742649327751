import React, { useState } from "react";
import notFound from "../../../assests/images.png";
import Modal from "../UI/Modal/Modal";
export default function Img(props) {
  const [showImage, setShowImage] = useState(false);
  return (
    <>
      {!props.noShow && showImage && (
        <Modal
          style={{
            zIndex: "55555555555555555",
            padding: "0",
            width: `90%`,
            left: "5%",
            top: 90,
            background: "#ffffff2b",
          }}
          onClose={() => setShowImage(false)}
        >
          <div
            style={{ background: "#ffffff2b" }}
            className="row mx-0 px-0  position-relative"
          >
            <Img
              style={{
                height: `${window.innerHeight - 145}px`,
                width: "scale-down",
                objectFit: "scale-down",
              }}
              onClick={() => {}}
              className=" mx-auto bg-transparent"
              src={props.src}
            />
          </div>
        </Modal>
      )}
      <img
        // className="w-100"
        style={props.style}
        className={`  bg-dark  ${props.className}`}
        width={props.width}
        height={props.height}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick ? props.onClick(e) : setShowImage(true);
        }}
        src={props.src}
        onError={(e) => {
          e.currentTarget.src = notFound;
        }}
      />
    </>
  );
}
