import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classes from './ColdDeals.module.scss';
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PieChart } from 'react-minimal-pie-chart';
import Img from '../../components/Reusable/Common/Img';
import { Avatar, Radio, Skeleton } from '@mui/material';
import { Pagination, Navigation } from 'swiper/modules';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import Download from '../../components/Reusable/SVG/Download';
import { errorActions } from '../../store/reducers/errorSlice';
import Button from '../../components/Reusable/UI/Button/Button';
import Table from '../../components/Reusable/Common/Table/Table';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import DealsAvtiveL from '../../components/Reusable/SVG/DealsAvtiveL';
import Section from '../../components/Reusable/Common/Section/Section';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import { coldDealsEshtreanaService } from '../../services/ColdDealsEshtreana';
import LoadingTable from '../../components/Reusable/Common/Table/LoadingTable';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import { originalDealsEshtreanaService } from '../../services/OriginalDealsEshtreana';
import ManagerHeader from '../../components/Reusable/Common/MemberCard/ManagerHeader';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import TermsSections from '../../components/Reusable/Common/TermsSections/TermsSections';
import DownloadPopUp from '../../components/Reusable/Common/DownloadPopUp/DownloadPopUp';
import UploadImageGroup2 from '../../components/Reusable/UI/InputGroup/UploadImageGroup2';
import ShowAreaDistrict from '../../components/Reusable/Common/AddMemberCard/ShowAreaDistrict';
export default function ColdDealsDetails() {
  //----------------------Declarations---------------------------------
  let timeinterval = () => {};
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const [doc, setDoc] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageOrder, setPageOrder] = useState(1);
  const [dealOrders, setDealOrders] = useState([]);
  const [option, setOption] = useState('Main Data');
  const [wholeOrders, setWholeOrders] = useState([]);
  const [wholeOrders1, setWholeOrders1] = useState([]);
  const [closeOption, setCloseOption] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [showDistrict, setShowDistrict] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showIndexArea, setShowIndexArea] = useState(null);
  const [joinedSuppliers, setJoinedSupplier] = useState([]);
  const [totalPagesOrder, setTotalPagesOrder] = useState(1);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [purchasingDone, setPurchasingDoen] = useState(false);
  const [showAreaDistrict, setShowAreaDistrict] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationDealOrder, setShowConfirmationDealOrder] = useState(false);
  //----------------------Functions---------------------------------

  let getDealOrders = async () => {
    let response = await coldDealsEshtreanaService.getColdDealOrders(id, pageOrder, 5, false);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      let arr = [];
      response.data.orders.map((item) => {
        arr.push([
          item.uuid,
          item.customer.customerID,
          item.customer.customerType,
          item.customer.customerName,
          item.customer.customerPhone || '-',
          item.quantity,
          item.status,
        ]);
      });
      setDealOrders(arr);
      setTotalPagesOrder(response.data.totalPages);
      setWholeOrders(response.data.orders);
      setWholeOrders1(response.data);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoadingOrder(false);
  };
  let getDealDetails = async () => {
    let response = await coldDealsEshtreanaService.getDealDetails(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    console.log(response);
    if (Valid === true) {
      setData(response.data.deal);
      type === 'history' && setPurchasingDoen(response.data.deal.purchasingDone);
      let arr = [];
      type === 'onGoing' &&
        response.data.deal.suppliers.joinedSuppliers.length > 0 &&
        response.data.deal.suppliers.joinedSuppliers.map((item) => {
          arr.push([item.supplier, item.supplierName, item.supplierPrice, item.sellingPrice, item.joiningDate.slice(0, 10), item.joiningTime]);
        });
      setJoinedSupplier(arr.reverse());
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  //TODO:helper
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) + 1 * 22 * 60 * 60 * 1000 - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  //TODO:helper
  function initializeClock(endtime) {
    const clock = document.getElementsByClassName(`clock`);
    timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      clock[0].innerHTML =
        t.total > 0
          ? (t.days > 0 ? t.days : 0) +
            'D: ' +
            (t.hours > 0 ? t.hours : 0) +
            'H: ' +
            (t.minutes > 0 ? t.minutes : 0) +
            'M: ' +
            (t.seconds > 0 ? t.seconds : 0) +
            'S '
          : 'Finished';
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  }

  //TODO:to helper
  let sendPO = async () => {
    let fd = new FormData();
    fd.append('doc', doc[0], doc[0].name);
    let response = await originalDealsEshtreanaService.sendPoInvoice(id, fd);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setDoc([]);
      setData((prev) => ({
        ...prev,
        purchasingOrder: doc[0],
      }));
      setShowConfirmation(true);
      setLoading(true);

      setTimeout(() => {
        setShowConfirmation(false);
        setLoading(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  let handleToggleAgreement = async () => {
    let response = await originalDealsEshtreanaService.toggleAgreements(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    Valid === true
      ? setPurchasingDoen((prev) => !prev)
      : dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
  };
  //----------------------useEffects---------------------------------

  useEffect(() => {
    if (showConfirmationDealOrder) {
      setTimeout(() => {
        setShowConfirmationDealOrder(false);
      }, 2000);
    }
  }, [showConfirmationDealOrder]);
  useEffect(() => {
    showIndexArea !== null && setShowDistrict(true);
  }, [showIndexArea]);
  useEffect(() => {
    setCloseOption(true);
  }, [closeOption]);

  useEffect(() => {
    if (type === 'onGoing') {
      if (data && data.mainData.endDate) {
        if (option !== 'Main Data') {
          clearInterval(timeinterval);
        } else {
          initializeClock(new Date(data.mainData.endDate));
        }
      }

      return () => clearInterval(timeinterval);
    }
  }, [data?.mainData?.endDate, option]);

  useEffect(() => {
    getDealOrders();
    getDealDetails();
  }, [type, id]);

  useEffect(() => {
    doc.length > 0 && sendPO();
  }, [doc]);

  return (
    <div className={`w-100 ${classes['cold-deals']}`}>
      {showConfirmationDealOrder && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup message1={'The Deal Status is Changed Successfully'} />
        </Modal>
      )}
      {showDownload &&
        ReactDOM.createPortal(<div className={classes.backdrop} onClick={() => setShowDownload(false)}></div>, document.getElementById('overlays'))}
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup message1={'The Agreement File Sent Successfully'} />
        </Modal>
      )}

      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}
      {showAreaDistrict && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowAreaDistrict(false)}>
          <ShowAreaDistrict
            area={data.mainData.areas[showIndexArea]}
            setShowForm={() => {
              setShowDistrict(false);
              setShowIndexArea(null);
            }}
          />
        </Modal>
      )}
      {showDistrict && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowDistrict(false);
            setShowIndexArea(null);
          }}
        >
          <ShowAreaDistrict
            setShowForm={() => {
              setShowDistrict(false);
              setShowIndexArea(null);
            }}
            area={data.mainData.areas[showIndexArea]}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className=" custom-container ">
        <div className={classes['title']}>
          <PageTitle text="Deal Details" icon={<DealsAvtiveL />} />
        </div>
        {loading ? (
          <div className={classes['main-section']}>
            {type === 'history' && <ManagerHeader loading={true} />}
            <div className={`row px-0 mx-0  mb-3  ${classes.options} mb-0`}>
              <div className="row px-0 mx-0 mb-2">
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
              </div>
            </div>
            <div className="row px-0 mx-0">
              <div className="col-lg-8 order-lg-1 order-2 px-0 mx-0">
                <div className={`row mx-0 px-0 ${classes.info}`}>
                  <h1 className="w-auto d-inline px-0">
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                  </h1>
                  <h1 className="w-auto d-inline px-0 ms-auto">
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                  </h1>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>

                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-lg-2 order-1 p-5 pt-0 mx-0">
                <div className={`${classes.info}`}>
                  <Skeleton variant="rounded" sx={{ height: '223px', width: '284px' }} />
                </div>
              </div>
            </div>
            <>
              {[0, 1, 2].map((index) => (
                <div key={index} className={`row w-100 px-0 mx-0 mt-2 `}>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto">
                      <p style={{ fontSize: '2rem', width: '250px' }}>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
        ) : (
          <section className={`${classes['main-section']} pb-5`}>
            {type === 'history' && data?.supplier && (
              <ManagerHeader
                data={{
                  name: data?.supplier?.name_en,
                  categories: data?.supplier?.categories,
                }}
                button={
                  option === 'Orders' && (
                    <Button
                      icon={<Download color={'#134074'} />}
                      color={'#D1E6FF'}
                      borderColor={'#D1E6FF'}
                      fontColor={'#134074'}
                      className={' px-2 ms-auto text-center'}
                      type="normal"
                      text={'Download'}
                    />
                  )
                }
                closeOption={closeOption}
                optionComponent={
                  option === 'Orders' && (
                    <DownloadPopUp
                      options={['Download Financial CSV', 'Download Reports CSV', 'Download Orders CSV']}
                      id={id}
                      type={'cold'}
                      setServerError={setServerError}
                      setServerErrorMsg={setServerErrorMsg}
                    />
                  )
                }
              />
            )}
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={
                  (type === 'onGoing' && [
                    'Main Data',
                    'Suppliers',
                    'Delivery Terms',
                    'Supplier Payment Terms',
                    'Customer Payment Terms',
                    'Orders',
                  ]) ||
                  (type === 'history' && [
                    'Main Data',
                    'Delivery Terms',
                    'Supplier Payment Terms',
                    'Customer Payment Terms',
                    'Orders',
                    'Agreements',
                  ]) ||
                  []
                }
              />
            </div>

            {option === 'Main Data' && (
              <>
                <div className="row  px-0 mx-0">
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-6 col-12 px-0">
                      <div className={`${classes['single-item']}  row px-0 mx-0 row-cols-2 align-items-center `}>
                        <p style={{ fontSize: '20px', width: '200px' }} className="col">
                          Deal Code :
                        </p>
                        <span className="col d-flex flex-grow-1 justify-content-cenetr">{data.mainData.uuid}</span>
                      </div>
                    </div>
                    {type !== 'scheduled' && type !== 'response' && type !== 'history' && (
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row align-items-center px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px', fontSize: '20px' }} className="col">
                            Time Left :
                          </p>
                          <span className="clock"></span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-9 px-0">
                    {type !== 'response' && (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                            <p style={{ width: '200px' }} className="col">
                              Deal Name :
                            </p>
                            <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">{data.mainData.title_en}</span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                            <p style={{ width: '200px' }} className="col">
                              اسم العرض :
                            </p>
                            <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">{data.mainData.title_ar}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Product Name :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Model :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.model}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            اسم المنتج :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.name_ar}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Country of Origin :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.country_en}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Product Code :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.uuid}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            بلد المنشأ :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.country_ar}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Category :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.category.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Factory Name :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.factory_en}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Sub Category :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product?.subcategory?.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            اسم المصنع :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.factory_ar}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Market Price :
                          </p>
                          <span className="col  justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.mainData.product.price}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Batch Number :
                          </p>
                          <span className="col  justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.mainData.product.patchNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Unit :
                          </p>
                          <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.mainData.unit}</span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                          <p style={{ width: '200px' }} className="col">
                            Customer Type :
                          </p>
                          <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.mainData.customerType}</span>
                        </div>
                      </div>
                    </div>

                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      {(data.mainData.product.variants.includes('colors') || data.mainData.product.variants.includes('Colors')) && (
                        <div className="col-xl-6 col-12 px-0">
                          <div className={`${classes['single-item']} d-flex`}>
                            <p>Color:</p>
                            <div
                              className="col-2"
                              style={{
                                backgroundColor: `${data.mainData.color}`,
                                borderRadius: '10px',
                                width: '100px',
                                height: '40px',
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                      {(data.mainData.product.variants.includes('sizes') || data.mainData.product.variants.includes('Sizes')) && (
                        <div className="col-xl-6 col-12 px-0">
                          <div className={classes['single-item']}>
                            <p>Size:</p>
                            <span>{data.mainData.size}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    {data.mainDatadeposit && (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={`${classes['single-item']} ${classes['green']}`}>
                            <p>Deposite:</p>
                            <span>{data.mainData.deposit} SAR</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-3 mb-lg-0 mb-3 px-0">
                    <div className={`${classes.info}`}>
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                          clickable: true,
                        }}
                        loop={false}
                        loopFillGroupWithBlank={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper    align-items-center d-flex p-0 "
                      >
                        {data.mainData.product.productImgs.map((item, index) => (
                          <SwiperSlide key={index}>
                            <Img
                              style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                              }}
                              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/product/${item}`}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p>Description</p>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-xl-6 pe-xl-2 px-0">
                    <Section border={true} text={data.mainData.product.description_en} />
                  </div>

                  <div className="col-xl-6 ps-xl-2 px-0  ms-auto">
                    <Section border={true} text={data.mainData.product.description_ar} />
                  </div>
                  {/* <div className="col-6 px-0"></div> */}
                </div>
                <div className="row px-0 mx-0 mb-3 ">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p className="mb-4">Areas</p>

                      {data.mainData.allKsa ? (
                        <span className={`${classes['area']} w-auto  d-inline`}>All KSA</span>
                      ) : data.mainData.areas.length > 0 ? (
                        data.mainData.areas.map((item, index) => (
                          <span
                            onClick={() => {
                              item.district.length > 0 && setShowIndexArea(index);
                            }}
                            key={index}
                            className={`${classes['area']} w-auto  d-inline`}
                          >
                            {item.city.city_en}
                          </span>
                        ))
                      ) : (
                        <div className="w-100 row justify-content-center pt-5 pb-4">
                          <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Citites Selected</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === 'Suppliers' &&
              (data.joined ||
              (data.suppliers?.joinedSuppliers && data.suppliers?.joinedSuppliers?.length) ||
              (type === 'history' && data.supplier) ? (
                <>
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-6 col-12 px-0">
                      <div className={`${classes['single-item']}  row px-0 mx-0 row-cols-2 align-items-center `}>
                        <p style={{ fontSize: '20px', color: 'black' }} className="col">
                          Current Supplier :
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p className="col">Supplier Name :</p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.suppliers?.supplier}</span>
                      </div>
                    </div>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p style={{ width: '200px' }} className="col">
                          Profit Percentage :
                        </p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.suppliers.profitPercentage}</span>
                      </div>
                    </div>
                  </div>
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p className="col">Supplier Price :</p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.suppliers.supplierPrice}</span>
                      </div>
                    </div>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p className="col">Decrease Percentage :</p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">
                          {data.suppliers.decreasePercentage}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p className="col">Selling Price :</p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.suppliers.sellingPrice}</span>
                      </div>
                    </div>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p className="col">Starting Price :</p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.suppliers.startingPrice}</span>
                      </div>
                    </div>
                  </div>
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p className="col">Market Price :</p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.mainData.product.price}</span>
                      </div>
                    </div>
                  </div>
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p className="col">Joining Date :</p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.suppliers.joiningDate}</span>
                      </div>
                    </div>
                  </div>
                  <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    <div className="col-xl-5 col-12 px-0">
                      <div className={`${classes['single-item']} row px-0 mx-0 row-cols-2 `}>
                        <p className="col">Joing Time :</p>
                        <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">{data.suppliers.joiningTime}</span>
                      </div>
                    </div>
                  </div>
                  {joinedSuppliers && joinedSuppliers.length > 0 && (
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-xl-6 col-12 px-0">
                        <div className={`${classes['single-item']}  row px-0 mx-0 row-cols-2 align-items-center `}>
                          <p style={{ fontSize: '20px', color: 'black' }} className="col">
                            Previous Supplier :
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {joinedSuppliers &&
                    joinedSuppliers.length > 0 &&
                    type !== 'history' &&
                    (loading ? (
                      <LoadingTable />
                    ) : (
                      <>
                        <Table
                          type="Cold Deal Suppliers"
                          options={[
                            {
                              title: 'Supplier ID',
                              icon: false,
                            },
                            {
                              title: 'Supplier Name',
                              icon: false,
                            },
                            {
                              title: 'Supplier Price',
                              icon: false,
                            },
                            {
                              title: 'Selling Price',
                              icon: false,
                            },
                            {
                              title: 'Joining Date',
                              icon: false,
                            },
                            {
                              title: 'Joinig Time',
                              icon: false,
                            },
                          ]}
                          data={joinedSuppliers}
                          customStyleIndex={[]}
                          customStyleFn={[false, false, false, false, false, false]}
                        />
                      </>
                    ))}
                </>
              ) : (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Suppliers Joined Yet</div>
                </div>
              ))}

            {option === 'Delivery Terms' && (
              <>
                <TermsSections title="Eshtarena Terms" terms={data.deliveryTerms} border={true} />

                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div className={classes['single-item']}>
                      <p>Delivery Fees :</p>
                      <span>{data.deliveryFees} SAR</span>
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === 'Supplier Payment Terms' && (
              <TermsSections title="Payment Terms & Condition" terms={data.supplierPaymentTerms} border={true} />
            )}
            {option === 'Customer Payment Terms' && (
              <TermsSections title="Payment Terms & Condition" terms={data.customerPaymentTerms} border={true} />
            )}

            {option === 'Agreements' && (
              <>
                {!purchasingDone && (
                  <div className="row px-0 mx-0">
                    <div className="col-12 ">
                      <div className={classes.input}>
                        <UploadImageGroup2 type="supplier" onChange={(e) => setDoc([e])} errorMsg="Provide a File" title={'Purshacing Order'} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row px-0 mx-0">
                  <div className="col-12 ">
                    <div className={classes.input}>
                      <UploadImageGroup2
                        noDeleteAll={true}
                        default={
                          (data.purchasingOrder && data.invoice && [data.purchasingOrder, data.invoice]) ||
                          (data.purchasingOrder && !data.invoice && [data.purchasingOrder]) ||
                          (!data.purchasingOrder && data.invoice && [data.invoice])
                        }
                        imageType="invoice/po"
                        errorMsg="Provide a File"
                        title={'Invoice'}
                        subTitle="click on invoice to download"
                      />
                    </div>
                  </div>
                </div>

                <div className={`row mx-0 px-0  justify-content-end mb-pb-0  `}>
                  <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={handleToggleAgreement}
                        className={'w-auto d-inline px-5  ms-auto text-end'}
                        type="normal"
                        text={!purchasingDone ? 'Stop Agreements' : 'Start Agreements'}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {option === 'Orders' &&
              (loadingOrder ? (
                <LoadingTable />
              ) : (
                <>
                  <Table
                    type="ColdDealOrder"
                    options={[
                      {
                        title: 'Order ID',
                        icon: false,
                      },
                      {
                        title: 'Customer ID',
                        icon: false,
                      },
                      {
                        title: 'Customer Type',
                        icon: false,
                      },
                      {
                        title: 'Customer Name',
                        icon: false,
                      },
                      {
                        title: 'Customer Phone',
                        icon: false,
                      },
                      {
                        title: 'Quantity',
                        icon: false,
                      },
                      {
                        title: 'Status',
                        icon: false,
                      },
                    ]}
                    wholeData={wholeOrders}
                    data={dealOrders}
                    deliveryResponsibility={data.deliveryResponsibility}
                    customStyleIndex={[7]}
                    updateData={() => {
                      setPageOrder(1);
                      setShowConfirmationDealOrder(true);
                      getDealOrders();
                    }}
                    filterOption={[]}
                    customStyleFn={[
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      (val) => {
                        return (
                          (val === 'Delivered' && {
                            borderRadius: '10px',
                            background: '#E3FDE5',
                            color: '#2CD63D',
                            textAlign: 'center',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px ',
                            padding: '8px',
                          }) ||
                          (val === 'Shipping' && {
                            borderRadius: '10px',
                            color: '#7A7E00',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: '#FCFFAB',
                            textAlign: 'center',
                          }) ||
                          (val === 'Canceled' && {
                            borderRadius: '10px',
                            color: '#F2055C',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: '#F2055C69',
                            textAlign: 'center',
                          }) ||
                          (val === 'On going' && {
                            borderRadius: '10px',
                            color: 'blue',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: 'lightBlue',
                            textAlign: 'center',
                          }) ||
                          (val === 'Preparing' && {
                            borderRadius: '10px',
                            color: 'black',
                            fontWeight: '600',
                            width: 'auto',
                            marginTop: '8px',
                            padding: '8px',
                            background: 'lightGray',
                            textAlign: 'center',
                          })
                        );
                      },
                    ]}
                  />
                  {totalPagesOrder > 1 && (
                    <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                      <div className="w-auto d-inline ms-auto">
                        <UsePagination
                          page={pageOrder}
                          onChangePage={(page) => {
                            setLoadingOrder(true);
                            setPageOrder(page);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {dealOrders.length !== 0 &&
                    (wholeOrders1.shipping !== 0 ||
                      wholeOrders1.delivered !== 0 ||
                      wholeOrders1.canceled !== 0 ||
                      wholeOrders1.onGoing !== 0 ||
                      wholeOrders1.preparing !== 0) && (
                      <div className={`row  px-0 mx-0 mt-4 ${classes['company-profile--data']}`}>
                        <div className="col-12 px-0">
                          <div dir="auto" className={classes['single-item']}>
                            <p>Progress</p>
                            <div className="row  px-0 mx-0">
                              <div className="col-xl-2 me-xl-5 col-md-4">
                                <PieChart
                                  data={[
                                    {
                                      title: 'Shipping',
                                      value: wholeOrders1.shipping,
                                      color: 'yellow',
                                    },
                                    {
                                      title: 'delivered',
                                      value: wholeOrders1.delivered,
                                      color: '#2DA840',
                                    },
                                    {
                                      title: 'Canceled',
                                      value: wholeOrders1.canceled,

                                      color: 'red',
                                    },
                                    {
                                      title: 'On Going',
                                      value: wholeOrders1.onGoing,
                                      color: 'lightBlue',
                                    },
                                    {
                                      title: 'preparing',
                                      value: wholeOrders1.preparing,
                                      color: 'lightGray',
                                    },
                                  ]}
                                />
                              </div>
                              <div className="col-xl-4 col-md-8 ms-xl-5   mt-lg-0 mt-4  d-flex flex-column  justify-content-center  d-inline">
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: '#2DA840',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    >
                                      {''}
                                    </Avatar>
                                  </div>
                                  <div className={`col-6  ${classes['single-item']}`}>
                                    <p>Delivered</p>
                                  </div>
                                  <div className={`w-auto d-inline mx-auto  ${classes['single-item']}`}>
                                    <p>{wholeOrders1.delivered}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: 'lightBlue',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    >
                                      {''}
                                    </Avatar>
                                  </div>
                                  <div className={`col-6 ${classes['single-item']}`}>
                                    <p>On Going</p>
                                  </div>
                                  <div className={`w-auto d-inline mx-auto  ${classes['single-item']}`}>
                                    <p>{wholeOrders1.onGoing}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: 'tla',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    >
                                      {''}
                                    </Avatar>
                                  </div>
                                  <div className={`col-6 ${classes['single-item']}`}>
                                    <p>PreParing</p>
                                  </div>
                                  <div className={`w-auto d-inline mx-auto  ${classes['single-item']}`}>
                                    <p>{wholeOrders1.preparing}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: 'yellow',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    >
                                      {''}
                                    </Avatar>
                                  </div>
                                  <div className={`col-6 ${classes['single-item']}`}>
                                    <p>Shipping</p>
                                  </div>
                                  <div className={`w-auto d-inline mx-auto text-center ${classes['single-item']}`}>
                                    <p>{wholeOrders1.shipping}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                    <Avatar
                                      sx={{
                                        bgcolor: 'red',
                                        width: '30px',
                                        height: '30px',
                                      }}
                                    >
                                      {''}
                                    </Avatar>
                                  </div>
                                  <div className={`col-6 ${classes['single-item']}`}>
                                    <p>Canceled</p>
                                  </div>
                                  <div className={`w-auto mx-auto d-inline text-center ${classes['single-item']}`}>
                                    <p>{wholeOrders1.canceled}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              ))}
          </section>
        )}
      </section>
    </div>
  );
}
//1741
//Done
