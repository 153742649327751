import axios from 'axios';
// import verifyAccount from '../common/VerifyAccount';

export const categoryService = {
  getAllCategories,
  addCategory,
  editCategory,
  deleteCategory,
  getAllCategoriesForSubcategory,
};

async function getAllCategoriesForSubcategory(page, size, query) {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/category/all`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getAllCategories(page, size, query) {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/category?page=${page}&size=${size}${query.length > 0 ? query : ''}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function addCategory(data) {
  try {
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/category`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };
    let response = await axios(config);
    //console.log(response);
    return response;
  } catch (e) {
    return e;
  }
}

async function editCategory(data, id) {
  try {
    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/category/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function deleteCategory(id) {
  try {
    var config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/category/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
