import React from 'react';
import classes from './AddMember.module.scss';
import Close from '../../SVG/Close';
import InputGroup from '../../UI/InputGroup/InputGroup';
import Button from '../../UI/Button/Button';
import { useState } from 'react';
import { originalDealsEshtreanaService } from '../../../../services/OriginalDealsEshtreana';
import { serverErrorCatcher } from '../../../../Helper/ServerErrorCatcher';
import { useNavigate } from 'react-router-dom';
import Modal from '../../UI/Modal/Modal';
import FailPop from './FailPop';
import SuccessPopup from './SuccessPopup';
import { useEffect } from 'react';
import { errorActions } from '../../../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';

export default function CreateDeal(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [data, setData] = useState({
    title_en: null,
    title_ar: null,
    quantity: null,
    sellingPrice: null,
    startDate: null,
    endDate: null,
  });
  const [dataErr, setDataErr] = useState({
    title_en: false,
    title_ar: false,
    quantity: false,
    sellingPrice: false,
    startDate: false,
    endDate: false,
  });

  let handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
    e.target.value.replace(/\s/g, '').length === 0
      ? setDataErr((prev) => ({ ...prev, [e.target.name]: false }))
      : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === 'sellingPrice') {
      var isANumber = isNaN(e.target.value) === false;

      isANumber ? setDataErr((prev) => ({ ...prev, [e.target.name]: true })) : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      (parseFloat(e.target.value) <= 0 || e.target.value.startsWith('-') || e.target.value === '' || e.target.value.startsWith('+')) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
    if (e.target.name === 'startDate') {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000) setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      if (data.endDate)
        if (new Date(e.target.value) > new Date(data.endDate)) {
          setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
        } else {
          if (new Date(data.endDate) > new Date(e.target.value)) setDataErr((prev) => ({ ...prev, endDate: true }));
        }
    } else if (e.target.name === 'endDate') {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000) setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      if (data.startDate) {
        if (new Date(e.target.value) < new Date(data.startDate)) {
          setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
        } else {
          if (new Date(data.startDate) > Date.now() - 1 * 24 * 60 * 60 * 1000) setDataErr((prev) => ({ ...prev, startDate: true }));
        }
      }
    } else {
    }
  };

  let handleSubmit = async () => {
    setShowError(true);
    if (dataErr.endDate && dataErr.startDate && dataErr.quantity && dataErr.sellingPrice && dataErr.title_ar && dataErr.title_en) {
      let response = await originalDealsEshtreanaService.createOrigialDeal({
        title_en: data.title_en,
        title_ar: data.title_ar,
        quantity: data.quantity,
        price: data.sellingPrice,
        startDate: data.startDate,
        endDate: data.endDate,
        _id: props.id,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          history(-1);
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
      }
    }
    setLoadingButton(false);
  };

  useEffect(() => {
    serverError &&
      setTimeout(() => {
        setServerError(false);
      }, 2000);
  }, [serverError]);
  return (
    <>
      {serverError && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop
            message1={
              serverErrorMsg === 'Please select a valid quantity'
                ? 'You already Selected this Quantity before,select another Quantity'
                : serverErrorMsg
            }
            setShowForm={setServerError}
          />
        </Modal>
      )}

      {showConfirmation && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup message1={'Your Deal is Created Successfully'} />
        </Modal>
      )}
      <div className="row mx-0 px-0 mb-2">
        <div className={`col-10 px-0 ${classes.title}`}>Manage Deal with {props.supplier}</div>
        <div
          onClick={() => {
            // //console.log('colsing');
            props.setShowForm(false);
          }}
          className="cursor col-2 px-0 text-end"
        >
          <Close coloe="#134074" />
        </div>
      </div>

      <div className="row mx-0 px-0">
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 pe-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.title_en}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.title_en}
                  errorMsg="provide a Deal Title"
                  name="title_en"
                  value="Deal Title"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 ps-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.title_ar}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.title_ar}
                  errorMsg="سجل اسم العرض"
                  name="title_ar"
                  value="اسم العرض"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0 px-0">
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 pe-2">
              <div className={classes.input}>
                <InputGroup
                  name="quantity"
                  default={data.quantity}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.quantity}
                  errorMsg="provide a Deal Quantity"
                  options={['Select Quantity', ...props.quantities]}
                  type="dropdown"
                  value="Choose Quantity"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 ps-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.sellingPrice}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.sellingPrice}
                  errorMsg="provide a Deal Selling Price"
                  name="sellingPrice"
                  value="Selling Price"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0 px-0">
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 pe-2">
              <div className={classes.input}>
                <InputGroup
                  underLineText={'Date of Start Selling the Deal'}
                  default={data.startDate}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.startDate}
                  errorMsg="provide a Deal Start Date"
                  name="startDate"
                  type="date"
                  value="StartDate"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 ps-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.endDate}
                  underLineText={'Date of End Selling the Deal'}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.endDate}
                  errorMsg="provide a Deal End Date"
                  name="endDate"
                  type="date"
                  value="End Date"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-0 px-0  mb-pb-0">
        <div className="col-4 ms-auto px-0  mb-pb-0">
          <div className={`  mb-pb-0`}>
            {loadingButton ? (
              <div className="text-center">
                <span className="spinner-border spinner-border-sm"></span>
              </div>
            ) : (
              <Button
                onClick={() => {
                  setLoadingButton(true);
                  handleSubmit();
                }}
                color={'#D1E6FF'}
                borderColor={'#134074'}
                fontColor={'#134074'}
                className={'w-auto px-2 ms-auto text-end'}
                type="normal"
                text={'Publish Deal'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
