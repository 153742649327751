import pdf from '../../assests/pdf.png';
import { Skeleton } from '@mui/material';
import classes from './Suppliers.module.scss';
import React, { useEffect, useState } from 'react';
import Edit from '../../components/Reusable/SVG/Edit';
import Img from '../../components/Reusable/Common/Img';
import Close from '../../components/Reusable/SVG/Close';
import { useNavigate, useParams } from 'react-router-dom';
import Map from '../../components/Reusable/Common/Map/Map';
import Twitter from '../../components/Reusable/SVG/Twitter';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import LinkedIn from '../../components/Reusable/SVG/LinkedIn';
import Facebook from '../../components/Reusable/SVG/Facebook';
import Instagram from '../../components/Reusable/SVG/Instagram';
import Button from '../../components/Reusable/UI/Button/Button';
import NoBranches from '../../components/Reusable/SVG/NoBranches';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import AddBranche from '../../components/Reusable/Common/AddMemberCard/AddBranche';
import RemoveCard from '../../components/Reusable/Common/AddMemberCard/RemoveMember';
import { supplierTopManagerService } from '../../services/supplierTopManagerService';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function SupplierDataDetails(props) {
  //----------------------Declarations---------------------------------
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [option, setOption] = useState('Data');
  const [data, setData] = useState(null);
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [loadingBranch, setLoadingBranch] = useState(true);
  const [showBranchMap, setShowBranchMap] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAddBranchForm, setShowAddBranchForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [media, setMedia] = useState({
    contract: [],
    goverment: [],
    taxCard: [],
  });
  //----------------------Functions---------------------------------
  let getSupplierData = async () => {
    let response = await supplierTopManagerService.getSingleSupplierData(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setData(response.data.supplier);
      setActive(response.data.supplier.activated);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };
  let handleDeleteBranch = async () => {
    let response = await supplierTopManagerService.deleteBranch(selectedBranch._id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setLoading(true);
      getSupplierBranches();
      setConfirmationMsg('Branch Deleted Successfully');
      setShowConfirmation(true);
      setTimeout(() => {
        setLoading(false);
        setShowDeleteWarning(false);
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };
  let getSupplierMedia = async () => {
    let response = await supplierTopManagerService.getSingleSupplierMedia(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setMedia({
        contract: response.data.supplier.contract,
        goverment: response.data.supplier.governmentDocumentsAndCertificates,
        taxCard: response.data.supplier.taxCard,
      });
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };
  let getSupplierBranches = async () => {
    // setLoading(true);
    let response = await supplierTopManagerService.getSingleSupplierBranches(id);
    let Valid = false;

    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setBranches(response.data.branches);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoadingBranch(false);
  };
  //----------------------Use Effects---------------------------------
  useEffect(() => {
    getSupplierMedia();
    getSupplierBranches();
    getSupplierData();
  }, [id]);
  return (
    <>
      {showDeleteWarning && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowDeleteWarning(false)}>
          <RemoveCard
            setShowForm={setShowDeleteWarning}
            message1="Are you sure you want to remove this Branch?"
            message2="By removing this Branch, it won’t show anymore."
            buttonText="Remove Branch"
            onClick={handleDeleteBranch}
          />
        </Modal>
      )}
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup message1={confirmationMsg} />
        </Modal>
      )}
      {showBranchMap && (
        <Modal
          onClose={() => setShowBranchMap(false)}
          style={{
            // position: "absolute",
            left: '7%',
            padding: 0,
            top: '75px',
            width: '87%',
            height: `${window.innerHeight - 150}px`,
          }}
        >
          <Map type={'show'} location={{ lng: selectedBranch.lng, lat: selectedBranch.lat }} setShowForm={setShowBranchMap} />
        </Modal>
      )}

      {showAddBranchForm && (
        <Modal
          style={{
            padding: '24px',
            height: `${window.innerHeight - 64}px`,
          }}
          onClose={() => setShowAddBranchForm(false)}
        >
          <AddBranche
            id={id}
            onSucess={() => {
              setLoadingBranch(true);
              getSupplierBranches();

              setConfirmationMsg('Branch Added Successfully');
              setShowConfirmation(true);
              setTimeout(() => {
                setShowAddBranchForm(false);
                setShowConfirmation(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
      {loadingBranch || loading ? (
        <div className={`w-100 ${classes['suppliers']}`}>
          <CompanyNav />

          <section className="custom-container">
            <div className={classes['title']}>
              <PageTitle
                onClick={() => history(`/account/suppliers/${id}/data/edit`)}
                text="Supplier Profile Data"
                buttonText={`Edit Profile`}
                buttonType="normal"
                buttonIcon={<Edit color="#134074" />}
              />
            </div>
            <section className={`${classes['main-section']}`}>
              <div className={`row px-0 mx-0   ${classes.options}`}>
                {[0, 1, 2].map((index) => (
                  <div key={index} className="col-xl-2 col-lg-4">
                    <Skeleton variant="text" sx={{ fontSize: '2rem', width: '95%' }} />
                  </div>
                ))}
              </div>

              <>
                {[0, 1, 2, 3].map((item, index) => (
                  <div key={index} className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                    {[0, 1].map((index) => (
                      <div key={index} className="col-lg-6 px-0">
                        <div dir="auto" className={classes['single-item']}>
                          <p>
                            <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                          </p>
                          <span>
                            <Skeleton variant="rounded" sx={{ height: '5rem', width: '95%' }} />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            </section>
          </section>
        </div>
      ) : (
        <div className={`w-100 ${classes['suppliers']}`}>
          <CompanyNav />
          <section className="custom-container">
            <div className={classes['title']}>
              <PageTitle
                onClick={() => history(`/account/suppliers/${id}/data/edit`)}
                text="Supplier Profile Data"
                buttonText={`Edit Profile`}
                buttonType="normal"
                buttonIcon={<Edit color="#134074" />}
              />
            </div>
            <div className={`row px-0 mx-0 align-items-center  ${classes.options}`}>
              <div className="col-xl-9 col-lg-8 px-0">
                <SelectOption onSelect={(val) => setOption(val)} default={option} options={['Data', 'Branches', 'Media']} />
              </div>
              <div className="col-xl-3 col-xl-2 col-lg-4 mt-lg-0 mt-4 text-end px-0">
                {option === 'Branches' && (
                  <Button
                    type="normal"
                    color={'#D1E6FF'}
                    text={'Add Branch'}
                    fontColor={'#134074'}
                    borderColor={'#134074'}
                    onClick={() => setShowAddBranchForm(true)}
                    className={'w-100 d-inline   ms-auto text-center'}
                  />
                )}
              </div>
            </div>
            {loading ? (
              <div className={classes['main-section']}>
                <div className={`row px-0 mx-0   ${classes.options}`}>
                  <div className="row px-0 mx-0">
                    {[0, 1, 2].map((index) => (
                      <div key={index} className="col-xl-2 col-lg-4">
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '95%' }} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-8 px-0 mx-0">
                    {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                      <div key={index} className="row mx-0 px-0">
                        <div className={`w-auto d-inline px-0 ${classes.info}`}>
                          <p>
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                          </p>
                        </div>
                        <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                          <p>
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-4 p-5 mx-0">
                    <div className={`${classes.info}`}>
                      <Skeleton variant="rounded" sx={{ height: '223px', width: '284px' }} />
                    </div>
                  </div>
                </div>
                <div className="br"></div>
                <div className="row mx-0 px-0">
                  <div className={`row px-0 mx-0   ${classes.options}`}>
                    <div className="w-auto d-inline">
                      <Skeleton variant="text" sx={{ fontSize: '3rem', width: '200px' }} />
                    </div>
                    <div className="w-auto d-inline">
                      <Skeleton variant="text" sx={{ fontSize: '3rem', width: '200px' }} />
                    </div>
                  </div>
                  {option === 'Description' && (
                    <div className="row px-0 mx-0">
                      <div className="col-lg-6 px-0">
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                      </div>

                      <div className="col-lg-6 px-0  ms-auto">
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <section className={`${classes['main-section']}`}>
                {option === 'Data' && (
                  <>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-lg-6 px-0">
                        <div dir="auto" className={classes['single-item']}>
                          <p>Supplier Commercial Name</p>
                          <span>{data.name_en}</span>
                        </div>
                      </div>
                      <div dir="auto" className="col-lg-6 px-0">
                        <div className={classes['single-item']}>
                          <p>الأسم التجاري للمورد</p>
                          <span>{data.name_ar}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-lg-6 px-0">
                        <div dir="auto" className={classes['single-item']}>
                          <p>Supplier CR Name</p>
                          <span>{data.supplierCR_en}</span>
                        </div>
                      </div>
                      <div dir="auto" className="col-lg-6 px-0">
                        <div className={classes['single-item']}>
                          <p>اسم السجل التجاري للمورد </p>
                          <span>{data.supplierCR_ar}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-12 px-0">
                        <div dir="auto" className={classes['single-item']}>
                          <p>VAT registration No</p>
                          <span>{data.vatRegistrationNo}</span>
                        </div>
                      </div>
                    </div>

                    {data.allKsa ? (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-12 px-0">
                          <div dir="auto" className={classes['single-item']}>
                            <p className="pb-2">Cities</p>
                            <div className={`w-auto d-inline  ${classes['category-option']}`}>All KSA</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-lg-6 px-0">
                          <div dir="auto" className={classes['single-item']}>
                            <p>Cities</p>
                            <span>
                              {data.cities &&
                                data.cities.map((item, index) => (
                                  <div key={index} className="d-block">
                                    {item.city.city_en}
                                  </div>
                                ))}
                            </span>
                          </div>
                        </div>
                        {data.cities && data.cities.districts && data.cities.districts.length > 0 && (
                          <div dir="auto" className="col-lg-6 px-0">
                            <div className={classes['single-item']}>
                              <p>Districts </p>
                              <span>
                                {data.cities.map((item) => {
                                  item.districts.length > 0 &&
                                    item.districts.map((i, ind) => (
                                      <div key={ind} className="d-block">
                                        {i.district_en}
                                      </div>
                                    ));
                                })}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-12 px-0">
                        <div dir="auto" className={classes['single-item']}>
                          <p>Founding year</p>
                          <span>{data.founded}</span>
                        </div>
                      </div>
                    </div>

                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-12 px-0">
                        <div dir="auto" className={classes['single-item']}>
                          <p className="pb-2">Categories</p>
                          {data.categories.map((item, index) => (
                            <div key={index} className={`w-auto d-inline  ${classes['category-option']}`}>
                              {item?.item?.name_en}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-lg-6 px-0">
                        <div dir="auto" className={classes['single-item']}>
                          <p>Overview</p>
                          <span>{data.overview_en}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 px-0">
                        <div dir="auto" className={classes['single-item']}>
                          <p>نبذة عامه</p>
                          <span>{data.overview_ar}</span>
                        </div>
                      </div>
                    </div>
                    {data.phone.length > 0 && data.phone[0] !== '' && (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-12 px-0">
                          <div dir="auto" className={classes['single-item']}>
                            <p className="pb-2">Phone Numbers</p>

                            {data.phone.length > 0 ? (
                              data.phone.map((item, index) => (
                                <span key={index} className="d-block">
                                  {item}
                                </span>
                              ))
                            ) : (
                              <div className="w-100 row justify-content-center pt-5 pb-4">
                                <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Phone Numbers Added Yet</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {data.website && (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-12 px-0">
                          <div dir="auto" className={classes['single-item']}>
                            <p className="pb-2">Website</p>
                            <span>{data.website}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {(data.instagram || data.facebook || data.twitter || data.linkedIn) && (
                      <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                        <div className="col-12 px-0">
                          <div dir="auto" className={classes['single-item']}>
                            <p className="pb-2">Social Media Links</p>
                            <span className={classes.icon}>
                              {data.instagram && (
                                <a
                                  href={data.instagram.toLowerCase().startsWith('http') ? data.instagram : `https://${data.instagram}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Instagram color="#949494" />
                                </a>
                              )}
                              {data.facebook && (
                                <a
                                  href={data.facebook.toLowerCase().startsWith('http') ? data.facebook : `https://${data.facebook}`}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <Facebook color="#949494" />
                                </a>
                              )}
                              {data.linkedIn && (
                                <a
                                  href={data.linkedIn.toLowerCase().startsWith('http') ? data.linkedIn : `https://${data.linkedIn}`}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <LinkedIn color="#949494" />
                                </a>
                              )}
                              {data.twitter && (
                                <a
                                  href={data.twitter.toLowerCase().startsWith('http') ? data.twitter : `https://${data.twitter}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Twitter color="#949494" />
                                </a>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="row mx-0 px-0">
                  {option === 'Branches' && (
                    <>
                      {branches.length == 0 ? (
                        <NoBranches />
                      ) : (
                        branches.map((item, index) => (
                          // TODO:**component
                          <div key={index} className="col-lg-6 px-0">
                            <div className="row px-0 mx-0 mb-3">
                              <div className={`col-lg-11 mx-auto ${classes['address-card']}`}>
                                <div className="position-relative">
                                  {(data._id === localStorage.getItem('id') || localStorage.getItem('side') === 'Top Manager') && (
                                    <div
                                      onClick={() => {
                                        setSelectedBranch(item);
                                        setShowDeleteWarning(true);
                                      }}
                                      style={{
                                        right: '5px',
                                        top: '5px',
                                        background: 'white',
                                        borderRadius: '50%',
                                      }}
                                      className="position-absolute justify-content-center align-items-center d-flex"
                                    >
                                      <Close />
                                    </div>
                                  )}
                                  <div
                                    className="cursor"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      //console.log(item);
                                      setSelectedBranch(item);
                                      setShowBranchMap(true);
                                    }}
                                  >
                                    <Img
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        //console.log(item);
                                        setSelectedBranch(item);
                                        setShowBranchMap(true);
                                        // setShowBranchMap(true);
                                      }}
                                      noShow={true}
                                      src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
                                    />
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <h1
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="right"
                                    title={item.title_en}
                                    style={{ overflowX: 'hidden' }}
                                    className="col-6"
                                  >
                                    {item.title_en}
                                  </h1>
                                  <h1
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="right"
                                    title={item.title_ar}
                                    style={{ overflowX: 'hidden' }}
                                    dir="rtl"
                                    className="col-6"
                                  >
                                    {item.title_ar}
                                  </h1>
                                  <p>{item.location_en} </p>
                                  <p>{item.location_ar} </p>
                                  <p>
                                    From {item.openAt} To {item.closeAt}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  )}

                  {option === 'Media' && (
                    <div className={`${classes['company-profile--data']}`}>
                      {media.contract && media.contract.length > 0 && (
                        <div className="row mx-0 px-0 mb-4">
                          <div className={classes['single-item']}>
                            <p className="mb-3">Contract</p>
                            <span>
                              {media.contract &&
                                media.contract.map((item, index) =>
                                  item.includes('.pdf') || item.includes('.docx') || item.includes('.xlsx') ? (
                                    <a target="_blank" href={`${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${item}`}>
                                      <Img
                                        noShow={true}
                                        // style={{ width: "181px" }}
                                        key={index}
                                        src={pdf}
                                        className="mb-2 w-auto me-2 bg-transparent"
                                      />
                                    </a>
                                  ) : (
                                    <Img
                                      style={{
                                        background: '#91c8e4',
                                      }}
                                      key={index}
                                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${item}`}
                                      className="mb-2 me-2"
                                    />
                                  ),
                                )}
                            </span>
                          </div>
                        </div>
                      )}
                      {media.goverment && media.goverment.length > 0 && (
                        <div className="row mx-0 px-0 mb-4">
                          <div className={classes['single-item']}>
                            <p className="mb-3">Government Documents and certificates</p>
                            <span>
                              {media.goverment &&
                                media.goverment.map((item, index) =>
                                  item.includes('.pdf') || item.includes('.docx') || item.includes('.xlsx') ? (
                                    <a target="_blank" href={`${process.env.REACT_APP_IMAGE_BASE_URL}/${'supplier'}/${item}`}>
                                      <Img noShow={true} key={index} src={pdf} className="mb-2 w-auto me-2 bg-transparent" />
                                    </a>
                                  ) : (
                                    <Img
                                      style={{
                                        background: '#91c8e4',
                                      }}
                                      key={index}
                                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${item}`}
                                      className="mb-2 me-2"
                                    />
                                  ),
                                )}
                            </span>
                          </div>
                        </div>
                      )}
                      {media.taxCard && media.taxCard.length > 0 && (
                        <div className="row mx-0 px-0 mb-4">
                          <div className={classes['single-item']}>
                            <p className="mb-3">Tax Card</p>
                            <span>
                              {media.taxCard &&
                                media.taxCard.map((item, index) =>
                                  item.includes('.pdf') || item.includes('.docx') || item.includes('.xlsx') ? (
                                    <a target="_blank" href={`${process.env.REACT_APP_IMAGE_BASE_URL}/${'supplier'}/${item}`}>
                                      <Img noShow={true} key={index} src={pdf} className="mb-2 w-auto me-2 bg-transparent" />
                                    </a>
                                  ) : (
                                    <Img
                                      style={{
                                        background: '#91c8e4',
                                      }}
                                      key={index}
                                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${item}`}
                                      className="mb-2 me-2"
                                    />
                                  ),
                                )}
                            </span>
                          </div>
                        </div>
                      )}

                      {media.contract.length === 0 && media.taxCard.length === 0 && media.goverment.length === 0 && (
                        <div className="w-100 row justify-content-center pt-5 pb-4">
                          <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Media Found</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </section>
            )}
          </section>
        </div>
      )}
    </>
  );
}
//1004
//Done
