import React, { useState } from "react";
import classes from "./InputFeild.module.scss";
import Eye1 from "../../SVG/Eye1";
import Eye2 from "../../SVG/Eye2";
import Button from "@mui/material/Button/Button";
// import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from "@mui/icons-material/Send";
import Search from "../../SVG/Search";
import Label from "../Label/Label";
export default function SearchInput(props) {
  return (
    <div  data-bs-toggle="tooltip"
    data-bs-placement="right"
    title={props.placeholder}
   >
      {/* <Label value="Search" /> */}
      <p
        className="pb-2 mb-0"
        style={{
          // marginBottom: "8px !important",
          fontSize: "14px",
          color: "#134074",
          fontWeight: "300",
        }}
      >
        Search
      </p>
      <div className={`${classes.search}   position-relative `}>
        <div
          style={{ left: "7px", top: "7px" }}
          onClick={() => {}}
          className="cursor position-absolute"
        >
          <Search color={"#949494"} />
        </div>
        <input
          // value={"Search"}
          // style={{ paddingInlineStart: "40px" }}
          onChange={(e) => {
            props.onChange(e);
            //   setValue(e.target.value);
          }}
          id={props.name}
          name={props.name}
          type="text"
          // type={props.type ? (showPassword ? 'text' : props.type) : 'text'}
          className={`${classes["select-feild-filter"]} ${
            props.error && classes.error
          }`}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}
