import React from 'react';

export default function AdActiveL() {
  return (
    <div className="mb-1">
      {' '}
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.6666 29.8333H21.3333C26.6933 29.8333 27.6533 27.6867 27.9333 25.0733L28.9333 14.4067C29.2933 11.1533 28.36 8.5 22.6666 8.5H9.33331C3.63997 8.5 2.70664 11.1533 3.06664 14.4067L4.06664 25.0733C4.34664 27.6867 5.30664 29.8333 10.6666 29.8333Z"
          stroke="#6E7980"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6666 8.49935V7.43268C10.6666 5.07268 10.6666 3.16602 14.9333 3.16602H17.0666C21.3333 3.16602 21.3333 5.07268 21.3333 7.43268V8.49935"
          stroke="#6E7980"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6667 17.8333V19.1667C18.6667 19.18 18.6667 19.18 18.6667 19.1933C18.6667 20.6467 18.6534 21.8333 16 21.8333C13.36 21.8333 13.3334 20.66 13.3334 19.2067V17.8333C13.3334 16.5 13.3334 16.5 14.6667 16.5H17.3334C18.6667 16.5 18.6667 16.5 18.6667 17.8333Z"
          stroke="#6E7980"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.8666 15.166C25.7866 17.406 22.2666 18.7393 18.6666 19.1927"
          stroke="#6E7980"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.49219 15.5254C6.49219 17.5787 9.87885 18.8187 13.3322 19.2054"
          stroke="#6E7980"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
