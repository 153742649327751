import React from 'react';
import classes from './Cashier.module.scss';
import PeopleActiveL from '../../components/Reusable/SVG/PeopleActiveL';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import Plus from '../../components/Reusable/SVG/Plus';
import Img from '../../components/Reusable/Common/Img';
import ManagerCard from '../../components/Reusable/Common/ManagerCard/ManagerCard';
import { useState } from 'react';
import { supplierTopManagerService } from '../../services/supplierTopManagerService';
import { useEffect } from 'react';
import Map from '../../components/Reusable/Common/Map/Map';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import { cashierService } from '../../services/CashierService';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function Cashiers() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [cashiers, setCashiers] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);

  let getCashiers = async () => {
    let query = '';
    query += `${searchValue && `&search=${searchValue}`}`;
    let response = await cashierService.getCashiers(page, 12, query);
    //console.log(response);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      setCashiers(response.data.cashiers);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    getCashiers();
    setFirstLoad(false);
  }, [page]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstLoad) {
        setLoading(true);
        page === 1 ? getCashiers() : setPage(1);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);
  return (
    <div className={`w-100 ${classes['cashier']}`}>
      {serverError && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle
            onClick={() => {
              // setShowAddForm(true);
              history('/supplier/cashiers/new');
            }}
            text="Cashier"
            icon={<PeopleActiveL />}
            buttonText={`Add Cashier`}
            buttonType="normal"
            buttonIcon={<Plus />}
          />
        </div>
        <section className={`${classes['main-section']}`}>
          {loading ? (
            <div className="row mx-0 px-0 ">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => (
                <div key={item} className="col-xl-2 col-lg-3 mb-4">
                  <ManagerCard loading={true} />
                </div>
              ))}
            </div>
          ) : (
            <div className="row mx-0 px-0 h-auto ">
              {!firstLoad && (
                <div className={`row   mx-0 px-0  ${classes['table-option']}`}>
                  <div className="col-xl-3 col-lg-6  px-0 mb-xl-0 ">
                    <SearchInput onChange={(val) => setSearchValue(val.target.value)} placeholder={`Search by Name or ID or Category`} />
                  </div>
                </div>
              )}
              {cashiers.map((item, index) => (
                <div key={index} className="col-xl-2 col-lg-3 mb-4 h-auto">
                  <ManagerCard
                    show={true}
                    key={index}
                    type="cashier"
                    id={item._id}
                    profilePicture={item.pic}
                    options={[
                      { value: item.name },
                      {
                        // title: "Category",
                        // list: true,
                        value: item.branchTitle,
                      },
                      // { title: "Number of Deal", value: "5" },
                    ]}
                  />
                </div>
              ))}
            </div>
          )}
          {totalPages > 1 && (
            <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
              <div className="w-auto d-inline mx-auto">
                <UsePagination
                  page={page}
                  // selected={page1}
                  pages={totalPages}
                  onChangePage={(page) => {
                    setLoading(true);
                    setPage(page);
                  }}
                />
              </div>
            </div>
          )}

          {!loading && cashiers.length == 0 && (
            <div className="w-100 row justify-content-center pt-5 pb-4">
              <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Cashier Found</div>
            </div>
          )}
        </section>
      </section>
    </div>
  );
}
