/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React from 'react';
import classes from './Suppliers.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import PeopleActiveL from '../../components/Reusable/SVG/PeopleActiveL';
import { useState } from 'react';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import { Checkbox, Fab, Radio, Skeleton } from '@mui/material';
import Plus from '../../components/Reusable/SVG/Plus';
import TextAreaGroup from '../../components/Reusable/UI/InputGroup/TextAreaGroup';
import UploadImageGroup from '../../components/Reusable/UI/InputGroup/UploadImageGroup';
import Button from '../../components/Reusable/UI/Button/Button';
import PhoneGroup from '../../components/Reusable/UI/InputGroup/PhoneGroup';
import Close from '../../components/Reusable/SVG/Close';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import UploadImageGroup2 from '../../components/Reusable/UI/InputGroup/UploadImageGroup2';
import { useEffect } from 'react';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { supplierTopManagerService } from '../../services/supplierTopManagerService';
import { useNavigate, useParams } from 'react-router-dom';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
import { PhoneNumber } from 'react-phone-number-input';
export default function SuppliersForm() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [option, setOption] = useState('Main Data');
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState('');
  const [addPhoneNumber, setAddPhoneNumber] = useState([1]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [cities, setCitites] = useState([1]);
  const [areas, setAreas] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedDisrictFinal, setSelectedDistrict] = useState([[]]);
  const [showError, setShowError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState([]);
  const [government, setGovernment] = useState([]);
  const [showMissingDataError, setShowMissingDataError] = useState(false);
  const [taxCard, setTaxCard] = useState([]);
  const [supplierData, setSupplierData] = useState();
  const [data, setData] = useState({
    commercailName_en: null,
    commercailName_ar: null,
    cRname_en: null,
    cRname_ar: null,
    vatRegistration: null,
    cRNo: null,
    email: null,
    password: null,
    founded: null,
    serveCoverageArea: 'Different Areas',
    overview_en: null,
    overview_ar: null,
    profilePicture: null,
    linkedin: null,
    twitter: null,
    facebook: null,
    instagram: null,
    contactMail: null,
    website: null,
    phone: [],
  });
  const [dataErr, setDataErr] = useState({
    commercailName_en: false,
    commercailName_ar: false,
    cRname_en: false,
    cRname_ar: false,
    vatRegistration: false,
    cRNo: false,
    email: false,
    password: 'Provide Password',
    founded: false,
    serveCoverageArea: true,
    overview_en: false,
    overview_ar: false,
    profilePicture: false,
    linkedin: true,
    twitter: true,
    facebook: true,
    instagram: true,
    contactMail: true,
    website: true,
    phone: true,
    phone1: true,
  });

  let getSupplierData = async () => {
    let arr = [];
    let response = await supplierTopManagerService.getSingleSupplierData(id);
    setData({
      commercailName_en: response.data.supplier.name_en,
      commercailName_ar: response.data.supplier.name_ar,
      cRname_en: response.data.supplier.supplierCR_en,
      cRname_ar: response.data.supplier.supplierCR_ar,
      vatRegistration: response.data.supplier.vatRegistrationNO,
      cRNo: response.data.supplier.CrNo,
      email: response.data.supplier.email,
      password: null,
      founded: response.data.supplier.founded,
      serveCoverageArea: response.data.supplier.allKsa ? 'All KSA' : 'Different Areas',
      overview_en: response.data.supplier.overview_en,
      overview_ar: response.data.supplier.overview_ar,
      profilePicture: response.data.supplier.pic,
      linkedIn: response.data.supplier.linkedIn,
      twitter: response.data.supplier.twitter,
      facebook: response.data.supplier.facebook,
      instagram: response.data.supplier.instagram,
      contactMail: response.data.supplier.contactMail,
      website: response.data.supplier.website,
    });
    setSupplierData(response.data.supplier);
    let phones = {};
    let arrPhone = [];
    for (let i = 0; i < response.data.supplier.phone.length; i++) {
      phones[`phone${i + 1}`] = response.data.supplier.phone[i];
      arrPhone.push(i + 1);
    }
    setData((prev) => ({ ...prev, ...phones }));
    setAddPhoneNumber(arrPhone);
    let arr1 = [];
    let arr2 = [];
    for (let i = 0; i < response.data.supplier.cities.length; i++) {
      arr1.push(response.data.supplier.cities[i].city.city_en);
      arr2.push(i + 1);
    }
    arr2.length === 0 ? setCitites([1]) : setCitites(arr2);
    setSelectedCities(arr1);
    for (let i = 0; i < response.data.supplier.cities.length; i++) {
      arr = [];
      for (let y = 0; y < response.data.supplier.cities[i].districts.length; y++) arr.push(response.data.supplier.cities[i].districts[y].district_en);
      arr.length === 0 ? setSelectedDistrict((prev) => [...prev, ['all']]) : setSelectedDistrict((prev) => [...prev, arr]);
    }
    arr = [];
    for (let i = 0; i < response.data.supplier.categories.length; i++) arr.push(response.data.supplier.categories[i].item.name_en);
    setSelectedCategories(arr);
    setDataErr({
      commercailName_en: true,
      commercailName_ar: true,
      cRname_en: true,
      cRname_ar: true,
      vatRegistration: true,
      cRNo: true,
      email: true,
      password: false,
      founded: true,
      serveCoverageArea: true,
      overview_en: true,
      overview_ar: true,
      profilePicture: true,
      linkedIn: true,
      twitter: true,
      facebook: true,
      instagram: true,
      contactMail: true,
      website: true,
      phone: true,
      phone1: true,
    });
    setLoading(false);
  };

  let getSupplierMedia = async () => {
    let response = await supplierTopManagerService.getSingleSupplierMedia(id);
    setContract(response.data.supplier.contract);
    setGovernment(response.data.supplier.governmentDocumentsAndCertificates);
    setTaxCard(response.data.supplier.taxCard);
  };
  useEffect(() => {
    serverError &&
      setTimeout(() => {
        setServerError(false);
      }, 2000);
  }, [serverError]);
  useEffect(() => {
    if (id && areas.length > 0) {
      getSupplierData();
      getSupplierMedia();
    } else {
      !id && setLoading(false);
    }
  }, [id, areas]);

  let handelChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    e.target.value === '' ? setDataErr((prev) => ({ ...prev, [e.target.name]: false })) : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === 'password') {
      if (!e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
        setDataErr((prev) => ({
          ...prev,
          [e.target.name]: `The Password must be eight characters or longer and must contain atleast:
      [ 1 lower case character
     ,  1 upper case character
     ,  1 numeber
     ,  1 special character ]`,
        }));
      } else {
        setDataErr((prev) => ({
          ...prev,
          [e.target.name]: null,
        }));
      }

      if (e.target.value === '' && id)
        setDataErr((prev) => ({
          ...prev,
          [e.target.name]: null,
        }));
    }
    if (e.target.name.startsWith('phone')) {
      if (e.target.value) {
        //console.log('here');
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
        setDataErr((prev) => ({ ...prev, Phone: true }));
      } else {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
        setDataErr((prev) => ({ ...prev, phone: false }));
      }

      var isANumber = isNaN(e.target.value) === false;

      isANumber ? setDataErr((prev) => ({ ...prev, [e.target.name]: true })) : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      (parseFloat(e.target.value) <= 0 ||
        e.target.value.startsWith('-') ||
        // e.target.value === "" ||
        e.target.value.startsWith('+')) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      if (e.target.value === '') setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
    }

    if (e.target.name === 'cRNo' || e.target.name === 'founded' || e.target.name === 'vatRegistration')
      (isNaN(e.target.value) || e.target.value.startsWith('-') || e.target.value === '' || e.target.value.startsWith('+')) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

    if (e.target.name === 'founded') {
      if (!(e.target.value > 1000 && e.target.value <= new Date(Date.now()).getFullYear())) {
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      }
    }
    if (e.target.name === 'email' || e.target.name === 'contactMail')
      if (!e.target.value.match(/^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/)) {
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      } else {
        setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
      }
    if (e.target.name === 'contactMail' && e.target.value === '') setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
  };
  let handleSubmit = async () => {
    //console.log(dataErr);
    //console.log(data);
    setShowError(true);
    let finalArea = [];
    let temp = {
      city: '',
      districts: [],
    };
    let ind = -1;
    if (data.serveCoverageArea !== 'All KSA') {
      areas.map((item, index) => {
        if (selectedCities.includes(item.city_en)) {
          ind++;
          temp.city = item._id;
          finalArea[ind] = [];
          item.district &&
            item.district.map((district, i) => {
              if (selectedDisrictFinal[ind]) {
                if (selectedDisrictFinal[ind][0] === 'all') {
                  temp.districts.push(district._id);
                } else if (selectedDisrictFinal[ind].includes(district.district_en)) {
                  temp.districts.push(district._id);
                }
              }
            });
          finalArea[ind] = temp;
          temp = {
            city: '',
            districts: [],
          };
        }
      });
    }

    let ValidPhone = true;
    for (const key in dataErr) {
      if (key.startsWith('phone') && key !== 'phone') {
        if (!dataErr[key]) ValidPhone = false;
      }
    }

    if (
      dataErr.cRNo &&
      dataErr.cRname_ar &&
      dataErr.cRname_en &&
      dataErr.commercailName_ar & dataErr.commercailName_en &&
      dataErr.email &&
      dataErr.founded &&
      dataErr.overview_ar &&
      dataErr.overview_en &&
      !dataErr.password &&
      ValidPhone &&
      (!data.facebook || (data.facebook && dataErr.facebook)) &&
      (!data.twitter || (data.twitter && dataErr.twitter)) &&
      (!data.linkedIn || (data.linkedIn && dataErr.linkedIn)) &&
      (!data.instagram || (data.instagram && dataErr.instagram)) &&
      (!data.website || (data.website && dataErr.website)) &&
      dataErr.contactMail &&
      dataErr.profilePicture &&
      selectedCategories.length > 0 &&
      ((data.serveCoverageArea !== 'All KSA' && selectedCities.length > 0) || true)
    ) {
      let fd = new FormData();
      !id && fd.append('email', data.email);
      (!id || data.password) && fd.append('password', data.password);
      fd.append('name_en', data.commercailName_en);
      fd.append('name_ar', data.commercailName_ar);
      fd.append('supplierCR_en', data.cRname_en);
      fd.append('supplierCR_ar', data.cRname_ar);
      (!id || (supplierData && supplierData.CrNo !== data.cRNo)) && fd.append('CrNo', data.cRNo);
      fd.append('founded', data.founded);
      fd.append('overview_en', data.overview_en);
      fd.append('overview_ar', data.overview_ar);

      (!id || (supplierData && supplierData.vatRegistrationNO !== data.vatRegistration)) && fd.append('vatRegistrationNO', data.vatRegistration);

      (!id || data.profilePicture.name) && fd.append('pic', data.profilePicture, data.profilePicture.name);

      if (contract.length > 0) {
        for (let i = 0; i < contract.length; i++) {
          contract[i].name ? fd.append('contract', contract[i], contract[i].name) : fd.append('contractExists', contract[i]);
        }
      }
      if (taxCard.length > 0) {
        for (let i = 0; i < taxCard.length; i++) {
          taxCard[i].name ? fd.append('taxCard', taxCard[i], taxCard[i].name) : fd.append('taxCardExists', taxCard[i]);
        }
      }
      if (government.length > 0) {
        for (let i = 0; i < government.length; i++) {
          government[i].name
            ? fd.append('governmentDocumentsAndCertificates', government[i], government[i].name)
            : fd.append('governmentDocumentsAndCertificatesExists', government[i]);
        }
      }

      let arr = [];
      for (let i = 0; i < categories.length; i++) {
        if (selectedCategories.includes(categories[i].name_en)) {
          arr.push({ item: categories[i]._id });
        }
      }
      fd.append('categories[]', JSON.stringify(arr));

      data.serveCoverageArea !== 'All KSA' && fd.append('cities[]', JSON.stringify(finalArea));
      let arr1 = [];
      for (const property in data) {
        if (property.startsWith('phone') && data[property] !== '') {
          if (property !== 'phone') {
            //console.log(`${property}`);
            //console.log(`${data[property]}`);
            arr1.push(data[property]);
          }
        }
      }
      //console.log(arr);
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== '' && addPhoneNumber[i]) fd.append('phone', arr1[i]);
      }
      if (arr1.length === 0) {
        fd.append('phone', '');
      }
      data.linkedIn && fd.append('linkedIn', data.linkedIn);
      data.twitter && fd.append('twitter', data.twitter);
      data.facebook && fd.append('facebook', data.facebook);
      data.instagram && fd.append('instagram', data.instagram);
      data.contactMail && fd.append('companyEmail', data.contactMail);
      data.website && fd.append('website', data.website);

      data.serveCoverageArea === 'All KSA' ? fd.append('allKsa', true) : fd.append('allKsa', false);

      let response = id ? await supplierTopManagerService.updateSupplier(fd, id) : await supplierTopManagerService.addNewSupplier(fd);
      //console.log(response);

      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);

          history(`/account/suppliers`, { replace: true });
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
      }
    } else {
      let arr = [];
      !dataErr.commercailName_en && arr.push('English Supplier Commercial Name');
      !dataErr.commercailName_ar && arr.push('Arabic Supplier Commercial Name');
      !dataErr.cRname_en && arr.push('English Supplier CR Name');
      !dataErr.cRname_ar && arr.push('Arabic Supplier CR Name');
      !dataErr.vatRegistration && arr.push('VAT Registration Number');
      !dataErr.cRNo && arr.push('CR Number');
      !dataErr.email && arr.push('Email');
      dataErr.password && arr.push('Password');
      !dataErr.founded && arr.push('Founding Year');
      selectedCities.length === 0 && arr.push('Coverage Areas');
      !dataErr.overview_en && arr.push('English OverView');
      !dataErr.overview_ar && arr.push('Arabic OverView');
      selectedCategories.length === 0 && arr.push('Categories');
      !dataErr.profilePicture && arr.push('Profile Picture');
      !ValidPhone && arr.push('Phone');
      data.linkedIn && !dataErr.linkedIn && arr.push('LinkedIn');
      data.facebook && !dataErr.facebook && arr.push('Facebook');
      data.twitter && !dataErr.twitter && arr.push('Twitter');
      data.instagram && !dataErr.instagram && arr.push('Instagram');
      data.contactMail && !dataErr.contactMail && arr.push('ContactMail');
      data.website && !dataErr.website && arr.push('Website');
      setServerErrorMsg(`There a Incorrect/Missing Data are Required [${[...arr]}]`);
      setShowMissingDataError(true);
    }
    setButtonLoading(false);
  };

  const [changed, setChanged] = useState(false);
  useEffect(() => {}, []);
  useEffect(() => {
    //console.log(selectedCities);
    let arr1 = selectedDisrictFinal;
    let arr = [];
    selectedCities.map((item, ind) => {
      areas.map((item1, ind1) => {
        if (item1.city_en === item) {
          //console.log(item1);
          arr.push(item1.district);
        }
      });
    });

    setDistrict(arr);
  }, [changed]);

  useEffect(() => {
    mainDataHelper.getCities(setServerError, setServerErrorMsg, setAreas);
    mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
    setChanged((prev) => !prev);
  }, []);
  useEffect(() => {
    if (serverError || showMissingDataError) {
      setTimeout(() => {
        setServerError(false);
        setShowMissingDataError(false);
      }, 4000);
    }
  }, [serverError, showMissingDataError]);
  return (
    <div style={{ overflowX: 'hidden' }} className={`w-100 row px-0 mx-0  ${classes['suppliers']}`}>
      {(showMissingDataError || serverError) && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setServerError(false);
            setShowMissingDataError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={showMissingDataError ? setShowMissingDataError : setServerError} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup
            setShowForm={setShowConfirmation}
            message1={`Supplier ${id ? 'Edited' : 'Added'} Successfully`}
            button={<Button text="Back to Suppliers" color="#134074" bordeColor="#134074" fontColor="white" type="normal" className="w-auto px-4" />}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          onClick={() => {
            // setShowAddForm(true);
          }}
          text={`${id ? 'Edit' : 'Create'} Supplier Profile`}
          icon={<PeopleActiveL />}
          //   buttonText={`Add Section`}
          //   buttonType="normal"
          //   buttonIcon={<Plus />}
        />
        <section className={`${classes['main-section']}`}>
          <div className={`row px-0 mx-0   ${classes.options}`}>
            <SelectOption
              onSelect={(val) => {
                setOption(val);
              }}
              default={option}
              options={['Main Data', 'Communication Data', 'Media']}
            />
          </div>

          {option === 'Main Data' &&
            (loading ? (
              <>
                <div className={`row px-0 mx-0   ${classes.options}`}>
                  <div className="row px-0 mx-0">
                    <div className="col-xl-2 col-lg-4">
                      <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                    </div>
                    <div className="col-xl-2 col-lg-4">
                      <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                    </div>
                    <div className="col-xl-2 col-lg-4">
                      <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                    </div>
                  </div>
                </div>

                {[0, 1, 2, 3, 4].map((item, index) => (
                  <div key={index} className="row px-0 mx-0">
                    <div className="col-lg-6 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
                        <Skeleton variant="text" sx={{ height: '4rem', width: '100%' }} />
                      </div>
                    </div>
                    <div className="col-lg-6 px-0 ps-lg-2">
                      <div className={classes.input}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
                        <Skeleton variant="text" sx={{ height: '4rem', width: '100%' }} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.commercailName_en}
                        name="commercailName_en"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.commercailName_en}
                        errorMsg="Provide a Supplier Commercail Name"
                        value={'Supplier Commercial Name'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.commercailName_ar}
                        name="commercailName_ar"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.commercailName_ar}
                        errorMsg="من فضلك سجل الأسم التجاري للمورد"
                        value={'الأسم التجاري للمورد'}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.cRname_en}
                        name="cRname_en"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.cRname_en}
                        errorMsg="Provide a Supplier CR Name"
                        value={'Supplier CR Name'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.cRname_ar}
                        name="cRname_ar"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.cRname_ar}
                        errorMsg="من فضلك سجل الأسم التجاري للمورد"
                        value={'اسم السجل التجاري للمورد'}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.vatRegistration}
                        name="vatRegistration"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.vatRegistration}
                        errorMsg={data.vatRegistration ? 'Provide a Valid VAT Registration NO' : 'Provide a VAT Registration NO'}
                        value={'VAT Registration NO'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.cRNo}
                        name="cRNo"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.cRNo}
                        errorMsg={data.cRNo ? 'Provide a Valid CR. NO' : 'Provide a  CR. NO'}
                        value={'CR. NO'}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        disable={id ? true : false}
                        default={data.email}
                        name="email"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.email}
                        errorMsg={data.email ? 'Provide a Valid / Un Used Supplier Email' : 'Provide a Supplier Email'}
                        value={'Email Address'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.password}
                        name="password"
                        onChange={(e) => handelChange(e)}
                        error={showError && dataErr.password}
                        errorMsg={dataErr.password}
                        type="password"
                        value={'Password'}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.founded}
                        name="founded"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.founded}
                        errorMsg={data.founded ? 'Provide a Valid Founding Year' : 'Provide a Founding Year'}
                        value={'Founding Year'}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <p>Service coverage areas</p>
                      <div className={`row p-0 m-0 ${classes['input--options']}`}>
                        {['All KSA', 'Different Areas'].map((item, index) => (
                          <div key={index} className="w-auto d-inline d-flex align-items-center ">
                            <Radio
                              onClick={() => {
                                setData((prev) => ({
                                  ...prev,
                                  serveCoverageArea: item,
                                }));
                              }}
                              checked={data.serveCoverageArea === item ? true : false}
                              className="h-auto px-0 py-0"
                            />
                            <label
                              onClick={() => {
                                setData((prev) => ({
                                  ...prev,
                                  serveCoverageArea: item,
                                }));
                              }}
                              style={{ cursor: 'pointer' }}
                              className="h-auto "
                            >
                              &nbsp; {item}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {data.serveCoverageArea === 'Different Areas' &&
                  (areas.length > 0 ? (
                    cities.map((item, index) => (
                      <div key={index} className="row px-0 mx-0">
                        <div className="col-lg-6 px-0 pe-lg-2">
                          <div className={classes.input}>
                            <InputGroup
                              type="dropdown"
                              default={selectedCities[index] ? selectedCities[index] : 'select City'}
                              city={true}
                              options={['select City', ...areas]}
                              error={showError && selectedCities.length === 0}
                              errorMsg="Provide a City"
                              onChange={(e) => {
                                let arr = selectedCities;
                                let arr2 = [];
                                if (selectedCities.includes(e.target.value) || e.target.value === 'select City') {
                                  arr.map((item, ind) => {
                                    if (index !== ind) {
                                      arr2.push(item);
                                    }
                                  });
                                  setSelectedCities(arr2);
                                  let arr1 = selectedDisrictFinal;
                                  arr1[index] = [];
                                  setSelectedDistrict(arr1);
                                } else {
                                  arr[index] = e.target.value;
                                  setSelectedCities((prev) => arr);
                                  let arr1 = selectedDisrictFinal;
                                  arr1[index] = [];
                                  setSelectedDistrict(arr1);
                                }
                                setChanged((prev) => !prev);
                              }}
                              value={'Cities'}
                            />
                            {cities.length === index + 1 && (
                              <p
                                onClick={() => {
                                  setSelectedCities((prev) => [...prev, []]);

                                  setCitites((prev) => [...prev, cities.length]);
                                }}
                                className={`${classes['input--link']}`}
                              >
                                <Plus color="#949494" /> Add City
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 px-0 ps-lg-2">
                          <div className={classes.input}>
                            <InputGroup
                              type="dropdown"
                              default={'select District'}
                              options={districts[index] && ['select District', ...districts[index]]}
                              district={true}
                              error={showError && selectedCities.length === 0}
                              errorMsg="Provide a District"
                              selected={selectedDisrictFinal[index]}
                              onChange={(e) => {
                                if (e.target.value !== 'select District' && !selectedDisrictFinal[index].includes(e.target.value)) {
                                  let arr = selectedDisrictFinal;
                                  arr[index] = arr[index] ? [...arr[index], e.target.value] : [e.target.value];
                                  setSelectedDistrict(arr);
                                  setChanged((prev) => !prev);
                                }
                              }}
                              value={'Districts'}
                            />
                            <p className={`${classes['input--options']}`}>
                              <Checkbox
                                checked={
                                  selectedDisrictFinal[index] && selectedDisrictFinal[index][0] && selectedDisrictFinal[index][0] === 'all'
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let arr = selectedDisrictFinal;
                                  arr[index] = ['all'];
                                  setSelectedDistrict(arr);
                                  setChanged((prev) => !prev);
                                }}
                              />
                              All districts
                            </p>
                          </div>
                        </div>
                        <div className="row px-0 mx-0 mb-3 ">
                          {selectedDisrictFinal[index] &&
                            selectedDisrictFinal[index].length > 0 &&
                            selectedDisrictFinal[index].map((item, ind) => (
                              <div key={ind} className="w-auto d-inline mt-2 pe-lg-2 position-relative">
                                <div
                                  onClick={() => {
                                    let arr = [];

                                    if (selectedDisrictFinal[index][0] === 'all') {
                                      let arr = selectedDisrictFinal;
                                      arr[index] = [];
                                      //console.log(arr);
                                      setSelectedDistrict(arr);
                                      setChanged((prev) => !prev);
                                    } else {
                                      arr = [];
                                      arr = selectedDisrictFinal[index].filter((item1, index) => {
                                        if (item1 !== item) return item1;
                                      });
                                      if (arr.length > 0) {
                                        selectedDisrictFinal[index] = arr;
                                        setSelectedDistrict(selectedDisrictFinal);
                                      } else {
                                        selectedDisrictFinal[index] = [];
                                        setSelectedDistrict(selectedDisrictFinal);
                                      }
                                      setChanged((prev) => !prev);
                                    }
                                  }}
                                  style={{
                                    right: '10px',
                                    zIndex: 2,
                                    // top: "10px",
                                  }}
                                  className="text-end  cursor  h-100 align-items-center d-flex position-absolute"
                                >
                                  <Close />
                                </div>
                                <Fab style={{ zIndex: 1 }} variant="extended" className=" pe-5">
                                  {item}
                                </Fab>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-100 row justify-content-center pt-5 pb-4">
                      <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Citites Added Yet</div>
                    </div>
                  ))}
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <TextAreaGroup
                        default={data.overview_en}
                        name="overview_en"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.overview_en}
                        errorMsg="Provide an Overview"
                        value={'OvierView'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <TextAreaGroup
                        default={data.overview_ar}
                        name="overview_ar"
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr.overview_ar}
                        errorMsg="من فضلك سجل نبذة عن المورد"
                        value={'عن المورد'}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        type="dropdown"
                        options={['select Catgeory', ...categoriesName]}
                        default={'select Category'}
                        name="category"
                        onChange={(e) => {
                          if (e.target.value !== 'select Category') {
                            setSelectedCategories((prev) => [...prev, e.target.value]);
                          }
                        }}
                        error={showError && selectedCategories.length === 0}
                        // name="sectionTitle"
                        errorMsg="Provide a Category"
                        value={'Categories'}
                      />
                    </div>
                  </div>
                  <div className="row px-0 mx-0 mb-3 ">
                    {selectedCategories.length > 0 &&
                      selectedCategories.map((item, index) => (
                        <div key={index} className="w-auto align-items-center d-inline mt-2 pe-lg-2 position-relative">
                          <div
                            onClick={() => {
                              let arr = [];
                              arr = selectedCategories.filter((item1, index) => {
                                //console.log(item1);

                                if (item1.toLowerCase() !== item.toLowerCase()) return item1;
                              });
                              if (arr.length > 0) {
                                setSelectedCategories(arr);
                              } else {
                                setSelectedCategories([]);
                                setDataErr((prev) => ({
                                  ...prev,
                                  categories: false,
                                }));
                              }
                            }}
                            style={{
                              right: '10px',
                              zIndex: 2,
                              // top: "10px",
                            }}
                            className="text-end  cursor  h-100 align-items-center d-flex position-absolute"
                          >
                            <Close />
                          </div>
                          <Fab style={{ zIndex: 1 }} variant="extended" className="w-auto d-inline  pe-5">
                            {item}
                          </Fab>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-12 ">
                    <div className={classes.input}>
                      <UploadImageGroup
                        default={data.profilePicture}
                        type="supplier"
                        onChange={(e) => {
                          setDataErr((prev) => ({
                            ...prev,
                            profilePicture: true,
                          }));
                          setData((prev) => ({
                            ...prev,
                            profilePicture: e.target.files[0],
                          }));
                        }}
                        onRemove={() => {
                          setData((prev) => ({
                            ...prev,
                            profilePicture: null,
                          }));
                          setDataErr((prev) => ({
                            ...prev,
                            profilePicture: false,
                          }));
                        }}
                        name="profilePicture"
                        error={showError && !dataErr.profilePicture}
                        errorMsg="Provide a Profile Picture"
                        value={'Profile Picture'}
                      />
                    </div>
                  </div>
                </div>
                <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                  {/* <div className="w-auto  ms-auto"> */}

                  <div className="w-lg-auto  d-inline ms-auto  px-0  ps-lg-2 mb-pb-0 my-3">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          setOption('Communication Data');
                        }}
                        // icon={<Plus />}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-lg-auto  d-inline px-5  ms-auto '}
                        type="normal"
                        text={'Next'}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            ))}

          {option === 'Communication Data' && (
            <>
              <div className="row mx-0 px-0">
                <div className="col-lg-6 px-0 mx-0">
                  <div className="row  px-0 mx-0">
                    <div className="col-12 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.linkedIn}
                          onChange={(e) => handelChange(e)}
                          error={showError && data.linkedIn && !dataErr.linkedIn}
                          errorMsg="provide Company Linkedin"
                          name="linkedIn"
                          value="Linkedin"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-0 mx-0">
                  <div className="row  px-0 mx-0">
                    <div className="col-12 px-0 ps-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.twitter}
                          onChange={(e) => handelChange(e)}
                          error={showError && data.twitter && !dataErr.twitter}
                          errorMsg="provide Company Twitter"
                          name="twitter"
                          value="Twitter"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 px-0">
                <div className="col-lg-6 px-0 mx-0">
                  <div className="row  px-0 mx-0">
                    <div className="col-12 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.facebook}
                          onChange={(e) => handelChange(e)}
                          error={showError && data.facebook && !dataErr.facebook}
                          errorMsg="provide Company Facebook "
                          name="facebook"
                          value="Facebook"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-0 mx-0">
                  <div className="row  px-0 mx-0">
                    <div className="col-12 px-0 ps-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.instagram}
                          onChange={(e) => handelChange(e)}
                          error={showError && data.instagram && !dataErr.instagram}
                          errorMsg="provide Company Instagram "
                          name="instagram"
                          value="Instagram"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 px-0">
                <div className="col-lg-6 px-0 mx-0">
                  <div className="row  px-0 mx-0">
                    <div className="col-12 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.contactMail}
                          onChange={(e) => handelChange(e)}
                          error={showError && data.contactMail && !dataErr.contactMail}
                          errorMsg="provide a Valid Company Contact Email"
                          name="contactMail"
                          value="Contact Email"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-0 mx-0">
                  <div className="row  px-0 mx-0">
                    <div className="col-12 px-0 ps-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.website}
                          onChange={(e) => handelChange(e)}
                          error={showError && data.website && !dataErr.website}
                          errorMsg="provide Company Web Site"
                          name="website"
                          value="website"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {addPhoneNumber.map((item, index) => (
                <div key={index} className="row mx-0 px-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <PhoneGroup
                        default={data[`phone${index + 1}`]}
                        onChange={(e) => handelChange(e)}
                        error={showError && !dataErr[`phone${index + 1}`]}
                        errorMsg="provide Valid  Phone Number "
                        onDelete={() => {
                          let arr = addPhoneNumber.filter((item, index1) => index1 !== index);
                          setAddPhoneNumber(arr);
                        }}
                        name={`phone${index + 1}`}
                        remove={index !== 0 && true}
                        value="Phone number"
                      />
                    </div>
                  </div>
                </div>
              ))}

              <div className="row mx-0 px-0 align-items-center">
                <div className={`${classes.input} px-0`}>
                  <div
                    onClick={() => {
                      dataErr[`phone${addPhoneNumber.length + 1}`] = true;
                      setAddPhoneNumber((prev) => [...prev, prev.length + 1]);
                    }}
                    className={`px-0  cursor  ${classes.link} w-auto`}
                  >
                    <Plus />
                    &nbsp; Add Phone Number
                  </div>
                </div>
              </div>
              {/* {serverError && (
                <div className="text-center">
                  <span className="errorMsg text-cenetr">{serverErrorMsg}</span>
                </div>
              )} */}

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                {/* <div className="w-auto  ms-auto"> */}
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Main Data');
                      }}
                      // icon={<Plus />}

                      className={'w-lg-auto  d-inline px-5   '}
                      type="reverse"
                      text={'Back'}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Media');
                      }}
                      // icon={<Plus />}
                      color={'#D1E6FF'}
                      borderColor={'#134074'}
                      fontColor={'#134074'}
                      className={'w-lg-auto  d-inline px-5  ms-auto '}
                      type="normal"
                      text={'Next'}
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </>
          )}

          {option === 'Media' && (
            <>
              <div className={`${classes.input} px-0`}>
                <UploadImageGroup2
                  default={contract}
                  // error={showError && contract.length === 0}
                  // errorMsg={"Provide a Contract Files / Images"}
                  imageType={'supplier'}
                  onChange={(e) => {
                    setContract((prev) => [...prev, e]);
                  }}
                  onRemove={(data) => setContract(data)}
                  title="Contract"
                  type="Both"
                />
              </div>
              <div className={`${classes.input} px-0`}>
                <UploadImageGroup2
                  default={taxCard}
                  imageType={'supplier'}
                  // error={showError && taxCard.length === 0}
                  // errorMsg={"Provide a Tax Card Files / Images"}
                  onChange={(e) => {
                    setTaxCard((prev) => [...prev, e]);
                  }}
                  onRemove={(data) => setTaxCard(data)}
                  title="Tax Card"
                  type="Both"
                />
              </div>
              <div className={`${classes.input} px-0`}>
                <UploadImageGroup2
                  // error={showError && government.length === 0}
                  // errorMsg={
                  //   "Provide a Government Documents and certificates Files / Images"
                  // }
                  default={government}
                  imageType={'supplier'}
                  onChange={(e) => {
                    setGovernment((prev) => [...prev, e]);
                  }}
                  onRemove={(data) => setGovernment(data)}
                  title="Government Documents and certificates"
                  type="Both"
                />
              </div>

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                {/* <div className="w-auto  ms-auto"> */}
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption('Communication Data');
                      }}
                      // icon={<Plus />}

                      className={'w-lg-auto  d-inline px-5   '}
                      type="reverse"
                      text={'Back'}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    {buttonLoading ? (
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setButtonLoading(true);
                          handleSubmit();
                        }}
                        // icon={<Plus />}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-lg-auto  d-inline px-5  ms-auto '}
                        type="normal"
                        text={'Save Changes'}
                      />
                    )}
                  </div>
                </div>
                {/* </div> */}
              </div>
            </>
          )}
        </section>
      </section>
    </div>
  );
}
