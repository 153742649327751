import React from 'react';

export default function LinkedIn(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.44444 17.0556V9.27778M22 7.05556V15.9444C22 17.4179 21.4147 18.8309 20.3728 19.8728C19.3309 20.9147 17.9179 21.5 16.4444 21.5H7.55556C6.08213 21.5 4.66905 20.9147 3.62718 19.8728C2.58532 18.8309 2 17.4179 2 15.9444V7.05556C2 5.58213 2.58532 4.16905 3.62718 3.12718C4.66905 2.08532 6.08213 1.5 7.55556 1.5H16.4444C17.9179 1.5 19.3309 2.08532 20.3728 3.12718C21.4147 4.16905 22 5.58213 22 7.05556Z"
        stroke={props.color || '#26CD61'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16.501V13.251M11 13.251V9.501M11 13.251C11 9.501 17 9.501 17 13.251V16.501M7 6.511L7.01 6.5"
        stroke={props.color || '#26CD61'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
