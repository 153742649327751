import { supplierTopManagerService } from '../../services/supplierTopManagerService';
import { serverErrorCatcher } from '../ServerErrorCatcher';

export const suppliersHelper = {
  getSuppliers,
};
async function getSuppliers(setServerError, setServerErrorMsg, setSuppliers, selectedSuppliers, searchValue, setLoading) {
  let response = await supplierTopManagerService.getAllSuppliers(false, false, searchValue ? `search=${searchValue}` : false);
  let Valid = false;
  Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

  if (Valid === true) {
    let checked = false;
    let arr = [];
    response.data.suppliers.map((item, index) => {
      checked = false;
      selectedSuppliers.filter((i, ind) => {
        if (item._id === i._id) {
          checked = true;
        }
      });
      if (!checked) arr.push(item);
    });
    setSuppliers(arr);
  } else {
  }
  setLoading(false);
}
