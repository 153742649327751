import React from "react";
import classes from "./Label.module.scss";
export default function Label(props) {
  return (
    <label
      className={props.filter ? classes["label-filter"] : classes.label}
      for={props.for}
    >
      {props.value}
    </label>
  );
}
