import React from 'react';
import classes from './Voucher.module.scss';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import VoucherActieveL from '../../components/Reusable/SVG/VoucherActieveL';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import dayjs from 'dayjs';
import { Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
import Scan from '../../components/Reusable/SVG/Scan';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { errorActions } from '../../store/reducers/errorSlice';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { vouchersSupplierService } from '../../services/VoucherSuppliers';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import VoucherCard from '../../components/Reusable/Common/Voucher/VoucherCard';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
export default function SupplierVoucher() {
  //---------------------Declaration---------------------
  dayjs.extend(relativeTime);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [cities, setCities] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [district, setDistrict] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [option, setOption] = useState('On Going');
  const [searchValue, setSearchValue] = useState('');
  const [serverError, setServerError] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [selectedArea, setSelectedArea] = useState('select city');
  const [selectedDistrict, setSelectedDistrict] = useState('select district');
  const [firstLoad, setFirstLoad] = useState(true);

  //---------------------Functions---------------------
  let getVouchers = async () => {
    let query = '';
    query += `${`&type=${
      option.toLocaleLowerCase() === 'responses' ? 'response' : option.toLocaleLowerCase() === 'requests' ? 'request' : option.toLocaleLowerCase()
    }`}`;
    query += `${
      selectedDistrict && selectedDistrict !== ' ' && selectedDistrict !== 'select district' ? `&district=${selectedDistrict.toLowerCase()}` : ''
    }`;
    query += `${selectedArea && selectedArea !== ' ' && selectedArea !== 'select city' ? `&area=${selectedArea}` : ''}`;
    query += `${searchValue ? `&search=${searchValue}` : ''}`;

    let response = await vouchersSupplierService.getVouchers(page, 6, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setVouchers(response.data.vouchers);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
    setLoadingSearch(false);
  };
  //---------------------UseEffects---------------------
  useEffect(() => {
    mainDataHelper.getAllCities(setServerError, setServerErrorMsg, setCities);
  }, []);
  useEffect(() => {
    if (!firstLoad) {
      if (selectedArea !== 'select city') mainDataHelper.getAllDistrict(setServerError, setServerErrorMsg, selectedArea, setDistrict);
      else {
        setSelectedDistrict('select district');
        setDistrict([]);
      }
    }
  }, [selectedArea]);

  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
      page === 1 ? getVouchers() : setPage(1);
    }
  }, [option, selectedArea, selectedDistrict]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstLoad) {
        setLoadingSearch(true);
        page === 1 ? getVouchers() : setPage(1);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);
  useEffect(() => {
    getVouchers();
    setFirstLoad(false);
  }, [page]);

  return (
    <div className={`w-100 ${classes['voucher']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={`${classes['title']} `}>
          <PageTitle
            onClick={() => {
              history(`/cashier`);
            }}
            text="Vouchers"
            icon={<VoucherActieveL />}
            buttonText={`Scan Voucher`}
            buttonType="normal"
            buttonIcon={<Scan />}
          />
        </div>
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
              {/* <div className={`row px-0 mx-0   ${classes.options}`}> */}
              <div className="row px-0 mx-0">
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
              </div>
              {/* </div> */}
            </div>

            <div className={`row   mx-0 px-0  mt-0  align-items-end  ${classes['table-option']}`}>
              <div className="col-xl-4  col-12 px-0 mb-xl-0 mb-2 ">
                <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
              </div>
              <div className="col-xl-8 px-0 ps-xl-2 pe-xl-4">
                <div className="row px-0 mx-0 mb-xl-0 mb-2">
                  <div className="col-xl-4  col-4 pe-1 px-0">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>

                  <div className="col-xl-4 col-4 px-0 ps-1">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>
                  <div className="col-xl-4 col-4 px-0 ps-1">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="row px-0 mx-0">
                {[0, 1, 2, 3, 4, 5].map((item, index) => (
                  <div key={index} className={`col-xl-4 col-lg-6 px-0 pe-lg-3`}>
                    <Skeleton
                      variant="rounded"
                      sx={{
                        borderRaduis: '10px',
                        fontSize: '2rem',
                        width: '100%',
                        height: '400px',
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          </section>
        ) : (
          <section className={`${classes['main-section']} mt-0`}>
            {!firstLoad && (
              <>
                {' '}
                <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
                  <SelectOption
                    onSelect={(val) => {
                      // setLoading(true);
                      setSearchValue('');

                      setOption(val);
                    }}
                    default={option}
                    options={['On Going', 'Scheduled', 'Requests', 'History']}
                  />
                </div>
                <div className={`row   mx-0 px-0  mt-0  align-items-end  ${classes['table-option']}`}>
                  <div className="col-xl-4  col-12 px-0 mb-xl-0 mb-2 ">
                    <SearchInput
                      onChange={(val) => {
                        setLoadingSearch(true);
                        setSearchValue(val.target.value);
                      }}
                      placeholder={`Search by Voucher Name or Voucher Code`}
                    />
                  </div>
                  <div className="col-xl-8 px-0 ps-xl-2 pe-xl-4">
                    <div className="row px-0 mx-0 mb-xl-0 mb-2">
                      {cities.length > 0 && (
                        <div className="col-xl-4 col-4 px-0 ps-1">
                          <InputGroup
                            options={['select city', ...cities]}
                            filter={true}
                            onChange={(e) => setSelectedArea(e.target.value)}
                            default={selectedArea}
                            type="dropdown"
                            value="Area"
                          />
                        </div>
                      )}
                      {district.length > 0 && (
                        <div className="col-xl-4 col-4 px-0 ps-1">
                          <InputGroup
                            options={['select District', ...district]}
                            filter={true}
                            onChange={(e) => setSelectedDistrict(e.target.value)}
                            default={selectedDistrict}
                            type="dropdown"
                            value="District"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === 'On Going' && (
              <>
                <div className="row px-0 mx-0">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                      <VoucherCard
                        id={item._id}
                        data={[
                          {
                            title: 'عنوان العرض',
                            value: item.title_ar,
                          },
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },

                          {
                            title: 'Voucher Time',
                            // value: dayjs(new Date(item.endDate) - Date.now()),
                            // value: getTimeRemaining(item.endDate),
                            // value: dayjs(item.endDate).fromNow(true),
                          },
                          {
                            title: 'Voucher Value',
                            value: item.voucherPrice,
                          },

                          {
                            title: 'Progress',
                            value: item.sold,
                            total: item.quantity,
                          },
                        ]}
                        type="On Going"
                        side={'Supplier'}
                        timeLeft={item.endDate}
                        progress={true}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {option === 'Requests' && (
              <>
                <div className="row px-0 mx-0">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                      <VoucherCard
                        id={item._id}
                        data={[
                          {
                            title: 'عنوان العرض',
                            value: item.title_ar,
                          },
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },
                          {
                            title: 'Voucher Value',
                            value: item.voucherPrice,
                          },

                          {
                            title: 'Quantity',
                            value: item.quantity,
                          },
                          {
                            title: 'Expiry Date',
                            value: item.expireDate,
                          },
                        ]}
                        type="Requests"
                        side={'Supplier'}
                        // timeLeft={item.endDate}
                        // progress={true}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {option === 'History' && (
              <>
                <div className="row px-0 mx-0">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                      <VoucherCard
                        id={item._id}
                        data={[
                          {
                            title: 'عنوان العرض',
                            value: item.title_ar,
                          },
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },
                          {
                            title: 'Voucher Value',
                            value: item.voucherPrice,
                          },

                          {
                            title: 'Quantity',
                            value: item.quantity,
                          },
                          {
                            title: 'Expiry Date',
                            value: item.expireDate,
                          },
                          {
                            title: 'Status',
                            value: item.status,
                          },
                        ]}
                        type="History"
                        side={'Supplier'}
                        // timeLeft={item.endDate}
                        // progress={true}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {option === 'Scheduled' && (
              <>
                <div className="row px-0 mx-0">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                      <VoucherCard
                        id={item._id}
                        data={[
                          {
                            title: 'عنوان العرض',
                            value: item.title_ar,
                          },
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },
                          {
                            title: 'Expiry Date',
                            value: item.expireDate,
                          },
                          {
                            title: 'Start Date',
                            value: item.startDate,
                          },
                          {
                            title: 'End Date',
                            value: item.endDate,
                          },
                        ]}
                        type="Scheduled"
                        side={'Supplier'}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {loadingSearch ? (
              <div className="w-100 row justify-content-center pt-5 pb-4">
                <div className="no-found-data mx-auto w-auto  text-center w-100 ">Searching</div>
              </div>
            ) : (
              vouchers.length === 0 && (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Data Found</div>
                </div>
              )
            )}

            {totalPages > 1 && (
              <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                <div className="w-auto d-inline mx-auto">
                  <UsePagination
                    page={page}
                    pages={totalPages}
                    onChangePage={(page) => {
                      setLoading(true);
                      setPage(page);
                    }}
                  />
                </div>
              </div>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
//Done
