import React, { useState } from "react";
import classes from "./User.module.scss";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import ProductActiveL from "../../components/Reusable/SVG/ProductActiveL";
import Plus from "../../components/Reusable/SVG/Plus";
import SearchInput from "../../components/Reusable/UI/InputFeild/SearchInput";
import Table from "../../components/Reusable/Common/Table/Table";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import { productService } from "../../services/ProductServices";
import { useEffect } from "react";
import Edit from "../../components/Reusable/SVG/Edit";
import Recycle from "../../components/Reusable/SVG/Recycle";
import UsePagination from "../../components/Reusable/UI/Pagination/Pagination";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import RemoveCard from "../../components/Reusable/Common/AddMemberCard/RemoveMember";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import Eye2 from "../../components/Reusable/SVG/Eye2";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import { mainDataHelper } from "../../Helper/MainData/MainData";
import { errorActions } from "../../store/reducers/errorSlice";
import { useDispatch } from "react-redux";
import PeopleActiveL from "../../components/Reusable/SVG/PeopleActiveL";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import { UserService } from "../../services/UserService";
import Download from "../../components/Reusable/SVG/Download";
import Button from "../../components/Reusable/UI/Button/Button";

export default function Users() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [sort, setSort] = useState("Default");
  const [option, setOption] = useState("Personal");
  const [searchValue, setSearchValue] = useState("");

  const handleDownload = async () => {
    const response =
      option === "Personal"
        ? await UserService.downloadPesonalUsers()
        : await UserService.downloadOrganztionalUsers();
    const valid = serverErrorCatcher(
      response,
      setServerError,
      setServerErrorMsg
    );
    if (valid) window.open(response.data.excel);

    setLoadingButton(false);
  };
  let getPersonalUsers = async () => {
    let query = "";
    query += `${searchValue ? `&search=${searchValue}` : ""}`;
    const response = await UserService.getPesonalUsers(page, 20, query);
    const valid = serverErrorCatcher(
      response,
      setServerError,
      setServerErrorMsg
    );
    if (valid) {
      setTotalPages(response.data.totalPages);
      let arr = [];
      response.data.users.map((item) => {
        arr.push([item.uuid, item.name, item.email, item.phone]);
      });
      setUsers(arr);
    }

    setLoading(false);
  };
  let getOrganizationUsers = async () => {
    let query = "";
    query += `${searchValue ? `&search=${searchValue}` : ""}`;
    const response = await UserService.getOrganztionalUsers(page, 20, query);
    const valid = serverErrorCatcher(
      response,
      setServerError,
      setServerErrorMsg
    );
    if (valid) {
      setTotalPages(response.data.totalPages);
      let arr = [];
      response.data.users.map((item) => {
        arr.push([
          item.uuid,
          item.name,
          item.email,
          item.contactPersonName || "-",
          item.contactPersonPhone || "-",
        ]);
      });
      setUsers(arr);
    }

    console.log(response);
    setLoading(false);
  };

  useEffect(() => {
    if (!firstLoad) {
      !loading && setLoading(true);
      setPage(1);
      option === "Personal" ? getPersonalUsers() : getOrganizationUsers();
    }
  }, [sort, option]);
  useEffect(() => {
    if (!firstLoad) {
      !loading && setLoading(true);
      let timer = setTimeout(() => {
        page === 1
          ? option === "Personal"
            ? getPersonalUsers()
            : getOrganizationUsers()
          : setPage(1);
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchValue]);

  useEffect(() => {
    if (serverError) {
      setTimeout(() => {
        setServerError(false);
      }, 2000);
    }
  }, [serverError]);
  useEffect(() => {
    !loading && setLoading(true);
    option === "Personal" ? getPersonalUsers() : getOrganizationUsers();
    setFirstLoad(false);
  }, [page]);
  return (
    <div className={`w-100 ${classes["product-page"]}`}>
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className={"backdrop"}
            onClick={() => {
              setShowFilters(false);
            }}
          ></div>,
          document.getElementById("overlays")
        )}
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}

      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          text={"Users"}
          icon={
            <div className=" w-auto d-inline">
              <PeopleActiveL />
            </div>
          }
        />
        <section className={`${classes["main-section"]}`}>
          <div className={`row px-0 mx-0   ${classes.options}`}>
            <SelectOption
              onSelect={(val) => setOption(val)}
              default={option}
              options={["Personal", "Organization"]}
            />
          </div>
          {!firstLoad && (
            <div
              className={`row   mx-0 px-0  align-items-end  ${classes["table-option"]}`}
            >
              <div className="col-xl-3  col-12 px-0 mb-xl-0 mb-2 ">
                <SearchInput
                  onChange={(val) => setSearchValue(val.target.value)}
                  placeholder={`Search for an User`}
                />
              </div>
              <div className="col-xl-3 mt-xl-0 mt-3 ms-auto">
                {loadingButton ? (
                  <Button
                    icon={<Download color={"#134074"} />}
                    color={"#D1E6FF"}
                    borderColor={"#D1E6FF"}
                    fontColor={"#134074"}
                    className={" px-2 ms-auto py-1 text-center"}
                    type="normal"
                    text={
                      <div className="text-center">
                        <span className="spinner-border text-white spinner-border-sm"></span>
                      </div>
                    }
                  />
                ) : (
                  <Button
                    onClick={() => {
                      setLoadingButton(true);
                      handleDownload();
                    }}
                    icon={<Download color={"#134074"} />}
                    color={"#D1E6FF"}
                    borderColor={"#D1E6FF"}
                    fontColor={"#134074"}
                    className={" px-2 ms-auto text-center"}
                    type="normal"
                    text={"Download"}
                  />
                )}
              </div>
            </div>
          )}
          {loading ? (
            <>
              <Table
                options={[
                  {
                    title: "User ID",
                    icon: false,
                  },
                  {
                    title: "Name",
                    icon: false,
                  },
                  {
                    title: "Email",
                    icon: false,
                  },
                  {
                    title: "Phone",
                    icon: false,
                  },
                ]}
                data={[
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                ]}
                customStyleIndex={[]}
                customStyleFn={[false, false, false]}
              />
              <div
                className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
              >
                <p className="w-auto d-inline me-auto px-0">
                  <Skeleton
                    variant="text"
                    sx={{ width: "200px", fontSize: "1rem" }}
                  />
                </p>
                <div className="w-auto d-inline ms-auto">
                  <Skeleton
                    variant="text"
                    sx={{ width: "200px", fontSize: "1rem" }}
                  />
                </div>
              </div>
            </>
          ) : option === "Personal" ? (
            <>
              <Table
                options={[
                  {
                    title: "User ID",
                    icon: false,
                  },
                  {
                    title: "Name",
                    icon: false,
                  },
                  {
                    title: "Email",
                    icon: false,
                  },
                  {
                    title: "Phone",
                    icon: false,
                  },
                ]}
                data={users}
                customStyleIndex={[]}
                customStyleFn={[false, false, false, false, false, false]}
              />
              {totalPages > 1 && (
                <div
                  className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                >
                  <div className="w-auto d-inline ms-auto">
                    <UsePagination
                      page={page}
                      pages={totalPages}
                      onChangePage={(page) => {
                        setLoading(true);
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Table
                options={[
                  {
                    title: "User ID",
                    icon: false,
                  },
                  {
                    title: "Name",
                    icon: false,
                  },
                  {
                    title: "Email",
                    icon: false,
                  },
                  {
                    title: "Contact Person Name",
                    icon: false,
                  },
                  {
                    title: "Contact Person phone",
                    icon: false,
                  },
                ]}
                data={users}
                customStyleIndex={[]}
                customStyleFn={[false, false, false, false, false, false]}
              />
              {totalPages > 1 && (
                <div
                  className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                >
                  <div className="w-auto d-inline ms-auto">
                    <UsePagination
                      page={page}
                      pages={totalPages}
                      onChangePage={(page) => {
                        setLoading(true);
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </section>
      </section>
    </div>
  );
}
