import Close from '../../SVG/Close';
import { Fab } from '@mui/material';
import Button from '../../UI/Button/Button';
import classes from './AddMember.module.scss';
import React, { useState, useEffect } from 'react';
import InputGroup from '../../UI/InputGroup/InputGroup';
import TextAreaGroup from '../../UI/InputGroup/TextAreaGroup';
import { errorActions } from '../../../../store/reducers/errorSlice';
import { mainDataHelper } from '../../../../Helper/MainData/MainData';
import { serverErrorCatcher } from '../../../../Helper/ServerErrorCatcher';
import { managerAccountsService } from '../../../../services/managerAccountsService';
import { useDispatch } from 'react-redux';
export default function AddMemberManager(props) {
  //--------------------------Declarations--------------------
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [showError, setShowError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [categoriesName, setCategoriesName] = useState([]);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [data, setData] = useState({
    firstName: null,
    lastName: null,
    phone: null,
    address: null,
    nationalId: null,
    birthDate: null,
    password: null,
    email: null,
    jobDescription: null,
  });
  const [dataErr, setDataErr] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    address: false,
    nationalId: false,
    birthDate: false,
    password: 'Provide a Password',
    jobDescription: false,
    email: false,
  });
  //--------------------------Functions--------------------

  let handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    e.target.value.replace(/\s/g, '').length === 0
      ? setDataErr((prev) => ({ ...prev, [e.target.name]: false }))
      : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === 'password') {
      if (!e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
        setDataErr((prev) => ({
          ...prev,
          [e.target.name]: `The Password must be eight characters or longer and must contain atleast:
        [ 1 lower case character
       ,  1 upper case character
       ,  1 numeric 
       ,  1 special character ]`,
        }));
      } else {
        setDataErr((prev) => ({
          ...prev,
          [e.target.name]: null,
        }));
      }
    }
    if (e.target.name === 'email' || e.target.name === 'contactMail')
      if (!e.target.value.match(/^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/)) {
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      } else {
        setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
      }

    if (e.target.name === 'birthDate') {
      if (new Date(Date.now()).getTime() < new Date(e.target.value).getTime()) {
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      }
    }
    if (e.target.name === 'nationalId') {
      var isANumber = isNaN(e.target.value) === false;

      isANumber ? setDataErr((prev) => ({ ...prev, [e.target.name]: true })) : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      (e.target.value.startsWith('0') ||
        e.target.value === '0' ||
        e.target.value.startsWith('-') ||
        e.target.value === '' ||
        e.target.value.startsWith('+')) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      // if (e.target.value.length !== 10)
      //   setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
  };

  //--------------------------UseEffects--------------------
  useEffect(() => {
    mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
  }, []);
  let handleSubmit = async () => {
    //console.log(data);
    setShowError(true);
    if (
      dataErr.address &&
      dataErr.birthDate &&
      dataErr.firstName &&
      dataErr.jobDescription &&
      dataErr.lastName &&
      dataErr.nationalId &&
      dataErr.phone &&
      ((dataErr.categories && props.formType === 'Category Managers') || props.formType === 'Marketing Managers') &&
      !dataErr.password
    ) {
      let data1;
      if (props.formType === 'Category Managers') {
        let categoriesSelected = [];
        categories.map((item) => {
          if (selectedCategories.includes(item.name_en)) {
            categoriesSelected.push({ item: item._id });
          }
        });

        data1 = {
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          nationalId: data.nationalId,
          address: data.address,
          jobDescription: data.jobDescription,
          birthDate: data.birthDate,
          phone: data.phone,
          categories: [...categoriesSelected],
        };
      } else {
        data1 = {
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          nationalId: data.nationalId,
          address: data.address,
          jobDescription: data.jobDescription,
          birthDate: data.birthDate,
          phone: data.phone,
        };
      }
      let response = await managerAccountsService.addNewManagers(
        props.formType === 'Marketing Managers' ? 'marketing-manager' : 'category-manager',
        data1,
      );
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) props.onSuccess();
      else {
        if (serverErrorMsg.includes('Email exists')) setDataErr((prev) => ({ ...prev, email: false }));
        else if (serverErrorMsg.includes('nationalId')) setDataErr((prev) => ({ ...prev, nationalId: false }));

        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
      }
    }
  };
  return (
    <div
      style={{
        overflowX: 'hidden',
        overflowY: 'scroll',
        height: `${window.innerHeight - (64 + 24 + 24)}px`,
      }}
    >
      <div className="row mx-0 px-0 mb-2">
        <div className={`col-10 px-0 ${classes.title}`}>Add Member - {props.formType}</div>
        <div onClick={() => props.setShowForm(false)} className="cursor col-2 px-0 text-end">
          <Close />
        </div>
      </div>

      <div className="row mx-0 px-0">
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 pe-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.firstName}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.firstName}
                  errorMsg="provide a First Name"
                  name="firstName"
                  value="First Name"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 ps-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.lastName}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.lastName}
                  errorMsg="provide a Last Name"
                  name="lastName"
                  value="Last Name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-0 px-0">
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 pe-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.phone}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.phone}
                  errorMsg="provide a Valid Phone Number"
                  name="phone"
                  value="Phone Number"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 ps-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.address}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.address}
                  errorMsg="provide an Address"
                  name="address"
                  value="Address"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-0 px-0">
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 pe-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.nationalId}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.nationalId}
                  errorMsg={data.nationalId ? 'provide a Valid National ID' : 'provide an National ID'}
                  name="nationalId"
                  value="National ID"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0 ps-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.birthDate}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.birthDate}
                  errorMsg={data.birthDate ? 'Provide a Valid Birthdate' : 'provide a Birthdate'}
                  name="birthDate"
                  value="Birth Date"
                  type="date"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0 px-0">
        <div className="col-6 px-0 mx-0">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0  pe-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.email}
                  onChange={(e) => handleChange(e)}
                  error={showError && !dataErr.email}
                  errorMsg={data.email ? 'Provide a Valid Email' : 'Provide an Email'}
                  name="email"
                  value="E-Mail"
                  // type="password"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-6 px-0 mx-0 ">
          <div className="row  px-0 mx-0">
            <div className="col-12 px-0  ps-2">
              <div className={classes.input}>
                <InputGroup
                  default={data.password}
                  onChange={(e) => handleChange(e)}
                  error={showError && dataErr.password}
                  errorMsg={dataErr.password}
                  name="password"
                  value="Password"
                  type="password"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {props.formType === 'Category Managers' && (
        <div className={classes.input}>
          <InputGroup
            type="dropdown"
            default={'No Selection'}
            name="category"
            options={['No Selection', ...categoriesName]}
            onChange={(e) => {
              if (!selectedCategories.includes(e.target.value) && e.target.value !== 'No Selection') {
                categories.map((item) => {
                  if (item.name_en === e.target.value) {
                    setSelectedCategories((prev) => [...prev, e.target.value]);

                    // setData((prev) => ({ ...prev, parent: item._id }));
                    setDataErr((prev) => ({ ...prev, categories: true }));
                  }
                });
              }
            }}
            error={showError && selectedCategories.length === 0}
            errorMsg="Select a  Category"
            value={`Category`}
          />

          <div className="row px-0 mx-0 ">
            {selectedCategories.map((item, index) => (
              <div key={index} className="w-auto d-inline mt-2 pe-2 position-relative">
                <div
                  onClick={() => {
                    let arr = [];
                    arr = selectedCategories.filter((item1, index) => {
                      if (item1.toLowerCase() !== item.toLowerCase()) return item1;
                    });
                    if (arr.length > 0) {
                      setSelectedCategories(arr);
                    } else {
                      setSelectedCategories([]);
                      setDataErr((prev) => ({ ...prev, categories: false }));
                    }
                  }}
                  style={{
                    right: '10px',
                    zIndex: 2,
                    // top: "10px",
                  }}
                  className="text-end  cursor  h-100 align-items-center d-flex position-absolute"
                >
                  <Close />
                </div>
                <Fab style={{ zIndex: 1 }} variant="extended" className=" pe-5">
                  {item}
                </Fab>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="row mx-0 px-0">
        <div className="col-12 px-0 mx-0">
          <div className={classes.input}>
            <TextAreaGroup
              default={data.jobDescription}
              onChange={(e) => handleChange(e)}
              error={showError && !dataErr.jobDescription}
              errorMsg="provide a Job Description"
              name="jobDescription"
              value="Job Description"
            />
          </div>
        </div>
      </div>
      {serverError && (
        <div className="text-center">
          <span className="errorMsg text-cenetr">{serverErrorMsg}</span>
        </div>
      )}
      <div className="row mx-0 px-0 ">
        <div className="col-4 px-0 ms-auto">
          {/* <div className={classes.input}> */}
          {false ? (
            <div className="text-center">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
          ) : (
            <Button
              onClick={() => {
                //   setLoading(true);
                handleSubmit();
                // props.onSuccess();
              }}
              color={'#D1E6FF'}
              borderColor={'#134074'}
              fontColor={'#134074'}
              className={'w-auto px-2 ms-auto text-end'}
              type="normal"
              text={`Save Manager`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

//Done
