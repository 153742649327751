import React from "react";

export default function DealsAvtiveL() {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4817 9.12401L19.4817 3.62401C19.334 3.54278 19.1682 3.50018 18.9997 3.50018C18.8312 3.50018 18.6654 3.54278 18.5177 3.62401L8.51769 9.12401C8.3609 9.21034 8.23016 9.33716 8.1391 9.49124C8.04804 9.64533 8 9.82103 8 10C8 10.179 8.04804 10.3547 8.1391 10.5088C8.23016 10.6629 8.3609 10.7897 8.51769 10.876L17.9997 16.091V26.81L14.9637 25.14L13.9997 26.891L18.5177 29.376C18.6653 29.4574 18.8311 29.5001 18.9997 29.5001C19.1683 29.5001 19.3341 29.4574 19.4817 29.376L29.4817 23.876C29.6385 23.7897 29.7693 23.6629 29.8605 23.5088C29.9516 23.3547 29.9997 23.179 29.9997 23V10C29.9997 9.821 29.9516 9.64528 29.8605 9.49119C29.7693 9.3371 29.6385 9.2103 29.4817 9.12401ZM18.9997 5.64201L26.9247 10L18.9997 14.358L11.0747 10L18.9997 5.64201ZM27.9997 22.409L19.9997 26.809V16.09L27.9997 11.69V22.409Z"
        fill="#6E7980"
      />
      <path
        d="M10 16.5H2V14.5H10V16.5ZM12 24.5H4V22.5H12V24.5ZM14 20.5H6V18.5H14V20.5Z"
        fill="#6E7980"
      />
    </svg>
  );
}
