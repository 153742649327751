import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import classes from './Requests.module.scss';
import React, { useState, useEffect } from 'react';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { errorActions } from '../../store/reducers/errorSlice';
import { contactUsService } from '../../services/contactRequest';
import Table from '../../components/Reusable/Common/Table/Table';
import RequestsSVG from '../../components/Reusable/SVG/RequestsSVG';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import LoadingTable from '../../components/Reusable/Common/Table/LoadingTable';
export default function Requests() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [data, setDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wholeData, setWholeData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [serverError, setServerError] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  let getContactRequest = async () => {
    let query = '';
    query += `${searchValue ? `&search=${searchValue}` : ''}`;
    let response = await contactUsService.getContactRequest(page, 5, query);
    setTotalPages(response.data.totalPages);
    setLoading(false);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setWholeData(response.data.contactUs);
      let arr = [];
      response.data.contactUs.map((item) => {
        arr.push([item._id, item.name, item.email, item.createdAt.slice(0, 10)]);
      });
      setDate(arr);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  useEffect(() => {
    if (!firstLoad) getContactRequest();
  }, [page]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstLoad) {
        setLoading(true);
        page === 1 ? getContactRequest() : setPage(1);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
      
    };
  }, [searchValue]);

  useEffect(() => {
    getContactRequest();
    setFirstLoad(false);
  }, []);
  return (
    <div className={`w-100 ${classes['request-page']}`}>
      {showFilters &&
        ReactDOM.createPortal(<div className={'backdrop'} onClick={() => setShowFilters(false)}></div>, document.getElementById('overlays'))}
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          text={'Users Requests'}
          icon={
            <div className="w-auto d-inline">
              <RequestsSVG color="gray" />
            </div>
          }
          buttonText={false}
        />
        <section className={`${classes['main-section']}`}>
          {!firstLoad && (
            <div className={`row   mx-0 px-0  align-items-end  ${classes['table-option']}`}>
              <div className="col-xl-3  col-12 px-0 mb-xl-0 mb-2 ">
                <SearchInput onChange={(val) => setSearchValue(val.target.value)} placeholder={`Search for a Request`} />
              </div>
            </div>
          )}
          {loading ? (
            <LoadingTable />
          ) : (
            <>
              <Table
                type="Client Request"
                options={[
                  {
                    title: 'User ID',
                    icon: false,
                  },
                  {
                    title: 'User Name',
                    icon: false,
                  },
                  {
                    title: 'Email',
                    icon: false,
                  },

                  {
                    title: 'Date',
                    icon: false,
                  },
                ]}
                wholeData={wholeData}
                data={data}
                customStyleIndex={[]}
                customStyleFn={[false, false, false, false, false, false]}
              />
              {totalPages > 1 && (
                <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                  <div className="w-auto d-inline ms-auto">
                    <UsePagination
                      page={page}
                      pages={totalPages}
                      onChangePage={(page) => {
                        setLoading(true);
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </section>
      </section>
    </div>
  );
}
