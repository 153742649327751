import React from "react";
import classes from "./AddMember.module.scss";
import InputGroup from "../../UI/InputGroup/InputGroup";
import Button from "../../UI/Button/Button";
import Close from "../../SVG/Close";
export default function AddNewCity() {
  return (
    <>
      {" "}
      <div className="row mx-0 px-0 align-items-center d-flex ">
        <div className={`col-11 px-0 ${classes.title}`}>Add New City</div>
        <div className="w-auto d-inline ms-auto close-btn">
          <Close color="#134074" />
        </div>
      </div>
      <div className={`${classes["input"]} `}>
        <InputGroup value="City Name" />
      </div>
      <div className="row mx-0 px-0">
        <Button
          type="normal"
          text="Save Changes"
          className="w-auto ms-auto px-2"
        />
      </div>
    </>
  );
}
