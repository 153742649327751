import React from 'react';

export default function AdviceActiveL() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3334 25.3327H10.6667C5.33341 25.3327 2.66675 23.9993 2.66675 17.3327V10.666C2.66675 5.33268 5.33341 2.66602 10.6667 2.66602H21.3334C26.6667 2.66602 29.3334 5.33268 29.3334 10.666V17.3327C29.3334 22.666 26.6667 25.3327 21.3334 25.3327H20.6667C20.2534 25.3327 19.8534 25.5327 19.6001 25.866L17.6001 28.5327C16.7201 29.706 15.2801 29.706 14.4001 28.5327L12.4001 25.866C12.1867 25.5727 11.6934 25.3327 11.3334 25.3327Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21.3285 14.6667H21.3405" stroke="#6E7980" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.9941 14.6667H16.006" stroke="#6E7980" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.6593 14.6667H10.6713" stroke="#6E7980" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
