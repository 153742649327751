import axios from 'axios';
// import verifyAccount from '../common/VerifyAccount';

export const subcategoryService = {
  getAllSubcategories,
  getAllSubcategoriesOfCategory,
  addSubcategory,
  editSubcategory,
  deleteSubcategory,
};

async function getAllSubcategories(page, size, query) {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/subcategory?page=${page}&size=${size}${query.length > 0 ? query : ''}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getAllSubcategoriesOfCategory(id) {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/subcategory/all/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function addSubcategory(data) {
  try {
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/subcategory`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

async function editSubcategory(data, id) {
  try {
    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/subcategory/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function deleteSubcategory(id) {
  try {
    var config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/subcategory/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
