import React from 'react';
import classes from './AddMember.module.scss';
import Close from '../../SVG/Close';
import InputGroup from '../../UI/InputGroup/InputGroup';
import Button from '../../UI/Button/Button';
import TextAreaGroup from '../../UI/InputGroup/TextAreaGroup';
import { useState } from 'react';
// import { TermsAndConditionservice } from "../../../../services/MainData/TermsAndConditions";
import { useEffect } from 'react';
import { aboutService } from '../../../../services/aboutService';
import { serverErrorCatcher } from '../../../../Helper/ServerErrorCatcher';
import { policiesService } from '../../../../services/policiesService';
import { errorActions } from '../../../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
// import { PrivacyPolicyervice } from "../../../../services/MainData/PrivacyPolicy";
// import { errorActions } from "../../../../store/reducers/errorSlice";
// import { serverErrorCatcher } from "../../../../Helper/ServerErrorCatcher";
// import { useDispatch } from "react-redux";

export default function AddSection(props) {
  //console.log(props.policy);
  const [data, setData] = useState({
    titleEN: null,
    titleAR: null,
    descriptionEN: null,
    descriptionAR: null,
  });
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState('');
  useEffect(() => {
    //console.log(props.data);
    if (props.about) {
      setData({
        descriptionAR: props.about.content_ar,
        descriptionEN: props.about.content_en,
      });
    } else {
      if (props.data) {
        setData({
          titleEN: props.data.title_en,
          titleAR: props.data.title_ar,
          descriptionEN: props.data.content_en,
          descriptionAR: props.data.content_ar,
        });
      } else if (props.policy) {
        setData({
          descriptionEN: props.policy.content_en,
          descriptionAR: props.policy.content_ar,
          titleEN: props.policy.title_en,
          titleAR: props.policy.title_ar,
        });
      }
    }
  }, []);
  useEffect(() => {
    if (props.item) {
      setData({ title: props.item.title, description: props.item.description });
    }
  }, []);
  const handleSubmit = async () => {
    setShowError(true);
    if (props.handleSubmit) {
      if (data.descriptionEN && data.descriptionAR && data.titleEN && data.titleAR) {
        props.setShowForm();
        props.handleSubmit(data);
      }
    } else {
      if (props.formType === 'About') {
        if (data.descriptionEN && data.descriptionAR) {
          let response = props.add
            ? await aboutService.addAbout({
                content_en: data.descriptionEN,
                content_ar: data.descriptionAR,
                type:
                  (props.option === 'Eshtarena' && 'eshtarena') ||
                  (props.option === 'Original Deal' && 'original_deal') ||
                  (props.option === 'Cold Deal' && 'cold_deal') ||
                  (props.option === 'Big Deal' && 'big_deal') ||
                  (props.option === 'Vouchers Deal' && 'voucher'),
              })
            : await aboutService.editAbout(
                {
                  content_en: data.descriptionEN,
                  content_ar: data.descriptionAR,
                },
                props.about._id,
              );
          let Valid = false;
          Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
          if (Valid === true) {
            props.onSuccess();
          } else {
            dispatch(
              errorActions.setError({
                error: true,
                errorMsg: localStorage.getItem('errorMsg'),
              }),
            );
          }
        }
      } else {
        if (data.descriptionEN && data.descriptionAR && data.titleEN && data.titleAR) {
          let data1;
          if (props.formType === 'terms-and-conditions' || props.formType === 'delivery-terms')
            data1 = {
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
            };
          else if (props.edit)
            data1 = {
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
            };
          else
            data1 = {
              type: props.option === 'Consumer' ? 'consumer' : 'business',
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
            };

          let response = props.add
            ? await policiesService.addPolicies(props.formType, data1)
            : await policiesService.editPolicies(props.formType, data1, props.policy._id);
          let Valid = false;
          Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
          props.onSuccess();
          if (Valid === true) {
          } else {
            dispatch(
              errorActions.setError({
                error: true,
                errorMsg: localStorage.getItem('errorMsg'),
              }),
            );
          }
        }
      }
      setLoading(false);
    }

    setLoading(false);
  };
  return (
    <>
      <div className="row mx-0 px-0 ">
        <div className={`col-10 px-0 ${classes.title}`}>{props.add ? 'Add' : 'Edit'} Section</div>
        <div
          onClick={() => {
            // //console.log('colsing');
            props.setShowForm(false);
          }}
          className="cursor col-2 px-0 text-end"
        >
          <Close color="#134074" />
        </div>
      </div>

      {((props.about && data.descriptionAR && data.descriptionEN) ||
        (props.policy && data.descriptionAR && data.descriptionEN && data.titleAR && data.titleEN) ||
        (props.data && data.descriptionAR && data.descriptionEN && data.titleAR && data.titleEN) ||
        (!props.about && !props.policy && !props.data)) && (
        <>
          {props.formType !== 'About' && (
            <div className="row px-0 mx-0">
              <div className="col-6 px-0 pe-2">
                <div className={classes.input}>
                  <InputGroup
                    default={data.titleEN}
                    onChange={(e) => setData((prev) => ({ ...prev, titleEN: e.target.value }))}
                    error={showError && !data.titleEN}
                    errorMsg="Provide a Title"
                    name="sectionTitle"
                    value="Section title"
                  />
                </div>
              </div>
              <div className="col-6 px-0 ps-2">
                <div className={classes.input}>
                  <InputGroup
                    default={data.titleAR}
                    onChange={(e) => setData((prev) => ({ ...prev, titleAR: e.target.value }))}
                    error={showError && !data.titleAR}
                    errorMsg="من فضلك سجل العنوان"
                    name="sectionTitleAr"
                    value="العنوان"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row px-0 mx-0">
            <div className="col-6 px-0 pe-2">
              <div className={classes.input}>
                <TextAreaGroup
                  default={data.descriptionEN}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      descriptionEN: e.target.value,
                    }))
                  }
                  error={showError && !data.descriptionEN}
                  errorMsg="Provide a Description"
                  name="description"
                  value="Description"
                />
              </div>
            </div>
            <div className="col-6 px-0 ps-2">
              <div className={classes.input}>
                <TextAreaGroup
                  default={data.descriptionAR}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      descriptionAR: e.target.value,
                    }))
                  }
                  error={showError && !data.descriptionAR}
                  errorMsg="من فضلك سجل الوصف"
                  name="description"
                  value="الوصف"
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div className="row mx-0 px-0 mb-0 pb-0">
        <div className="col-4 ms-auto px-0">
          <div>
            {loading ? (
              <div className="text-center">
                <span className="spinner-border spinner-border-sm"></span>
              </div>
            ) : (
              <Button
                onClick={() => {
                  setLoading(true);
                  handleSubmit();
                }}
                // icon={<Plus />}

                color={'#D1E6FF'}
                borderColor={'#134074'}
                fontColor={'#134074'}
                // fontColor="white"
                // color="#134074"
                // borderColor="#134074"
                className={'w-auto px-2 ms-auto text-end'}
                type="normal"
                text={`${props.add ? 'Add' : 'Edit'} Section`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
