import axios from "axios";

export const UserService = {
  getOrganztionalUsers,
  getPesonalUsers,
  downloadOrganztionalUsers,
  downloadPesonalUsers,
};

async function getPesonalUsers(page, size, query) {
  try {
    var config = {
      method: "get",
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/top-manger/users/personal-accounts?page=${page}&size=${size}${
        query.length > 0 ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getOrganztionalUsers(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/top-manger/users/organization-accounts?page=${page}&size=${size}${
        query.length > 0 ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function downloadPesonalUsers() {
  try {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/top-manger/users/personal-accounts/sheet`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function downloadOrganztionalUsers() {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/top-manger/users/organization-accounts/sheet`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
