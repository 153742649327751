import React from "react";
import classes from "./AddMember.module.scss";
import Close from "../../SVG/Close";
import Success from "../../SVG/Success";
export default function SuccessPopup(props) {
  return (
    <>
      <div
        className={`row mx-0 justify-content-cenetr text-cenetr px-0 mb-2 ${classes.remove}`}
      >
        <div className="row mx-0 px-0 ">
          {/* <div className={`col-10 px-0 ${classes.title}`}>{props.type} Member</div> */}
          <div
            onClick={() => {
              props.setShowForm(false);
            }}
            className="cursor col-2 ms-auto   px-0 text-end"
          >
            <Close color="#134074" />
          </div>
        </div>
        <div className="row mx-0 px-0 mt-4">
          <Success />
        </div>
        <p className="text-center">{props.message1}</p>
        <div className="row mx-0 px-0 justify-content-center">
          {props.button && props.button}
          {/* <span className="text-center">
          {props.message2
            ? props.message2
            : 'By removing this member, they won’t show on the company profile'}
        </span> */}
        </div>
      </div>
    </>
  );
}
