import React from 'react';
import { useState } from 'react';
import classes from './Ads.module.scss';
import Img from '../Img';
import { Skeleton } from '@mui/material';
import Modal from '../../UI/Modal/Modal';
import SingleAd from '../../Common/AddMemberCard/SingleAd';
import video from '../../../../assests/video.png';
import SuccessPopup from '../AddMemberCard/SuccessPopup';
export default function AdsCard(props) {
  const [showSingleAd, setShowSingleAd] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);

  return (
    <>
      {showVideo && (
        <Modal
          onClose={() => {
            setShowVideo(false);
          }}
        >
          <video style={{ width: '100%', height: '100%' }} controls>
            <source src={`${process.env.REACT_APP_IMAGE_BASE_URL}/ads/${props.item.pic}`} type="video/mp4"></source>
          </video>
        </Modal>
      )}

      {showConfirmationDelete && (
        <Modal
          onClose={() => {
            setShowConfirmationDelete(false);
          }}
        >
          <SuccessPopup setShowForm={setShowConfirmationDelete} message={'The Ad is Deleted Successfully'} />
        </Modal>
      )}
      {showSingleAd && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowSingleAd(false);
          }}
        >
          <SingleAd
            setShowForm={setShowSingleAd}
            onSuccess={() => {
              props.onSuccess();
              setShowConfirmationDelete(true);
              setTimeout(() => {
                setShowConfirmationDelete(false);
              }, 2000);
              setShowSingleAd(false);
            }}
            id={props.item._id}
          />
          {/* <SigleAd /> */}
        </Modal>
      )}
      {props.loading ? (
        <div
          onClick={() => {
            // !props.loading && setShowSingleAd(true);
          }}
          className="col-xl-3 col-lg-6 mb-4 h-auto cursor"
        >
          <div className="row px-0 mx-0 ">
            <div className={`col-12 ${classes['ad-card']}`}>
              <Skeleton variant="rounded" width={'100%'} height={123} />

              <h1>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </h1>
              <h1>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </h1>
              <p>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </p>
              <p>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-xl-3 col-lg-6 mb-4  cursor">
          <div className="row px-0  mx-0 h-100">
            <div className={`col-12  ${classes['ad-card']}`}>
              {!props.item.pic.includes('.mp4') ? (
                <>
                  <Img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/ads/${props.item.pic}`} />
                </>
              ) : (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowVideo(true);
                  }}
                >
                  <Img style={{ objectFit: 'contain' }} src={video} />
                </div>
              )}
              <div onClick={() => setShowSingleAd(true)}>
                <h1>{props.item.title_en}</h1>
                <h1>
                  {props.item.categories.map((item, index) => (
                    <>{item?.item?.name_en}&nbsp;</>
                  ))}
                </h1>
                <p>From : {props.item.startDate}</p>
                <p>To : {props.item.endDate}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
