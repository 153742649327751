import React from 'react';
import classes from './AddMember.module.scss';
import Close from '../../SVG/Close';
export default function ContactRequest(props) {
  return (
    <div
      className="row px-0 mx-0"
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      <div className="row mx-0 px-0 align-items-center ">
        <div className={`col-10 px-0 ${classes.title}`}>Request Details</div>
        <div onClick={() => props.setShowForm(false)} className="cursor col-2 px-0 text-end">
          <Close color="#134074" />
        </div>
      </div>
      <div className="row px-0 mx-0">
        <div className={`${classes['single-item']} col-12 px-0`}>
          <p>
            User Name: <span>{props.data.name ? props.data.name : props.data.user.name} </span>
          </p>
        </div>
        <div className={`${classes['single-item']} col-12 px-0`}>
          <p>
            User Email : <span> {props.data.email ? props.data.email : props.data.user.email} </span>
          </p>
        </div>
      </div>
      <div className="row px-0 mx-0">
        <div className={`${classes['single-item']} col-12 px-0`}>
          <p>
            Date : <span> {props.data.createdAt} </span>
          </p>
        </div>
      </div>

      <div className="row px-0 mx-0">
        <div className={`${classes['single-item']} col-12 px-0`}>
          <p>Message</p>
          <div className={`${classes.content} `}>{props.data.message}</div>
        </div>
      </div>
    </div>
  );
}
