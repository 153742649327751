import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const vouchersEshtreanaService = {
  addVoucherRequest,
  getVouchers,
  startVocherDeal,
  toggleAgreements,
  updateVoucherRequest,
  getSingleVoucher,
  getVoucherOrders,
  sendPoInvoice,
};

async function getVouchers(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/voucher/eshtarena?page=${page}&size=${size}${query ? query : ""}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function addVoucherRequest(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/new-voucher-request`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function updateVoucherRequest(data) {
  try {
    var config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/update-voucher-request`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function startVocherDeal(id, data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/start-voucher-deal/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function toggleAgreements(id) {
  try {
    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/toggle-agreements/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSingleVoucher(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/single/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getVoucherOrders(id, page, size) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/all-orders/${id}?page=${page}&size=${size}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function sendPoInvoice(id, data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/docs/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
