import React from "react";

export default function Dropdown() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.07998 8.19992C4.26998 8.19992 4.45998 8.26992 4.60998 8.41992L11.13 14.9399C11.61 15.4199 12.39 15.4199 12.87 14.9399L19.39 8.41992C19.68 8.12992 20.16 8.12992 20.45 8.41992C20.74 8.70992 20.74 9.18992 20.45 9.47992L13.93 15.9999C12.87 17.0599 11.14 17.0599 10.07 15.9999L3.54998 9.47992C3.25998 9.18992 3.25998 8.70992 3.54998 8.41992C3.69998 8.27992 3.88998 8.19992 4.07998 8.19992Z"
        fill="#134074"
      />
    </svg>
  );
}
