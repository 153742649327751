import React, { useEffect, useState } from "react";
import classes from "./SelectOption.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
export default function SelectOption(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    props.default && setSelectedOption(props.default);
  }, [props.default]);
  return (
    <div
      className={`row px-0 justify-content-md-start d-flex flex-lg-row flex-column justify-content-center ${classes.options} ${props.className}`}
    >
      <Swiper
        slidesPerView={4}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1025: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1441: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        loop={false}
        style={{
          width: "100%",
        }}
        className="  justify-content-start    align-items-center d-flex p-0 "
      >
        {props.options.map((item, index) => (
          <SwiperSlide
            key={index}
            onClick={() => {
              props.onSelect(item);
              if (props.type !== "process") {
                setSelectedOption(item);
              }
            }}
            className={`w-auto d-inline text-lg-start text-center algin-items-center  ${
              selectedOption === item && classes["selected-option"]
            } ${classes.option}   `}
          >
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
