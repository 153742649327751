import React from "react";
import classes from "./StoreLocationCard.module.scss";
import Img from "../Img";
import { useState } from "react";
import Map from "../Map/Map";
import Modal from "../../UI/Modal/Modal";

export default function StoreLocationCard(props) {
  const [showMap, setShowMap] = useState(false);
  return (
    <div className={`${classes["branch-card"]} row px-0`}>
      {showMap && (
        <Modal
          onClose={() => {
            setShowMap(false);
          }}
          style={{
            // position: "absolute",
            left: "7%",
            padding: 0,
            top: "75px",
            width: "87%",
            height: `${window.innerHeight - 150}px`,
          }}
        >
          <Map
            type={"show"}
            location={{ lng: props.item.lng, lat: props.item.lng }}
            setShowForm={setShowMap}
          />
        </Modal>
      )}

      <div
        onClick={() => setShowMap(true)}
        className="col-lg-3 cursor mb-lg-0 mb-2"
      >
        <Img
          noShow={true}
          onClick={(e) => {
            e.stopPropagation();
            setShowMap(true);
          }}
          style={{ width: "100%" }}
          src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
        />
      </div>
      <div className="col-lg-9 mb-0 pb-0">
        <h1>{props.item.title_en} </h1>
        <p>{props.item.location_en}</p>
        <p className="pb-0 mb-0">
          From {props.item.openAt} To {props.item.closeAt}
        </p>
      </div>
    </div>
  );
}
