import React from "react";
import classes from "./Error.module.scss";
import Button from "../../components/Reusable/UI/Button/Button";
import Svg500 from "../../components/Reusable/SVG/500";
import Svg401 from "../../components/Reusable/SVG/401";
import Svg4041 from "../../components/Reusable/SVG/4041";
import Svg4042 from "../../components/Reusable/SVG/4042";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import { errorActions } from "../../store/reducers/errorSlice";
// import { useDispatch } from "react-redux";
import NotFound from "../../components/Reusable/SVG/NotFound";
import CommingSoon from "../../components/Reusable/SVG/CommingSoon";
import Offline from "../../components/Reusable/SVG/Offline";
export default function ErrorPage(props) {
  // const dispatch = useDispatch();
  useEffect(() => {
    //ss
    // dispatch(errorActions.setError({ error: false, errorMsg: "" }));
  }, []);
  const history = useNavigate();
  let target =
    localStorage.getItem("side") === "company" ||
    localStorage.getItem("side") === "admin"
      ? "account"
      : "haai-team";
  return (
    <div
      style={{ height: `${window.innerHeight - 64} px`, overflow: "hidden" }}
      className={`row h-100 ${classes.error}`}
    >
      <div className="col-lg-6 order-lg-1 order-2    d-flex flex-column text-lg-start text-center justify-content-center">
        <h1>{props.title}</h1>
        <p>{props.text}</p>
        <div className="d-flex justify-content-lg-start justify-content-center">
          <Button
            onClick={() => {
              history(-1);
            }}
            className={"w-auto px-5  text-center"}
            type="normal"
            text="Back"
          />
        </div>
      </div>
      {/* ... */}
      {/* <div */}
      {/* // style={{ height: `${window.innerHeight}px` }} */}
      <div className="col-lg-6   order-lg-2 order-1 d-flex flex-column  text-lg-start text-center justify-content-center">
        {/* > */}
        <div className="w-auto d-inline  justify-content-center  align-items-center  d-flex   mx-auto ">
          {(props.type === "500" && <Svg500 />) ||
            (props.type === "401" && <Svg401 />) ||
            (props.type === "not found" && <NotFound />) ||
            (props.type === "comming soon" && <CommingSoon />) ||
            (props.type === "offline" && <Offline />) ||
            // (props.type === '505' && <Svg500 />) ||
            (props.type === "4041" && <Svg4041 />) ||
            (props.type === "4042" && <Svg4042 />) ||
            (props.type === "503" && <Svg4042 />)}
        </div>
      </div>
    </div>
  );
}
