import classes from './Suppliers.module.scss';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Plus from '../../components/Reusable/SVG/Plus';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import PeopleActiveL from '../../components/Reusable/SVG/PeopleActiveL';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import ManagerCard from '../../components/Reusable/Common/ManagerCard/ManagerCard';
import { supplierTopManagerService } from '../../services/supplierTopManagerService';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function Suppliers() {
  //----------------------Declarations---------------------------------
  const history = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  //----------------------Functions---------------------------------
  let getSuppliers = async () => {
    let response = await supplierTopManagerService.getAllSuppliers(page, 8);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      setSuppliers(response.data.suppliers);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };
  //----------------------Use Effects---------------------------------
  useEffect(() => {
    setLoading(true);
    getSuppliers();
  }, [page]);
  return (
    <div className={`w-100 ${classes['suppliers']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle
            onClick={() => history('/account/suppliers/new')}
            text="Suppliers"
            icon={<PeopleActiveL />}
            buttonText={`Add Supplier`}
            buttonType="normal"
            buttonIcon={<Plus />}
          />
        </div>
        <section className={`${classes['main-section']}`}>
          {loading ? (
            <div className="row mx-0 px-0 ">
              {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                <div key={index} className="col-xl-3 col-6 mb-4">
                  <ManagerCard loading={true} key={index} />
                </div>
              ))}
            </div>
          ) : (
            <div className="row mx-0 px-0 h-auto ">
              {suppliers.length > 0 ? (
                suppliers.map((item) => (
                  <div key={item._id} className="col-xl-3 col-lg-6 mb-4 h-auto  ">
                    <ManagerCard
                      key={item._id}
                      show={true}
                      type="supplier"
                      id={item._id}
                      profilePicture={item.pic}
                      options={[
                        { title: 'Supplier Name', value: item.name_en },
                        {
                          title: 'Category',
                          list: true,
                          value: [...item.categories],
                        },
                      ]}
                    />
                  </div>
                ))
              ) : (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Suppliers Found</div>
                </div>
              )}

              {totalPages > 1 && (
                <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                  <div className="w-auto d-inline mx-auto">
                    <UsePagination
                      page={page}
                      pages={totalPages}
                      onChangePage={(page) => {
                        setLoading(true);
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </section>
      </section>
    </div>
  );
}
//Done
