import React from 'react';

export default function Recycle1() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#FFE4E4" />
      <path
        d="M29 13.9805C25.67 13.6505 22.32 13.4805 18.98 13.4805C17 13.4805 15.02 13.5805 13.04 13.7805L11 13.9805"
        stroke="#F44336"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 12.97L16.72 11.66C16.88 10.71 17 10 18.69 10H21.31C23 10 23.13 10.75 23.28 11.67L23.5 12.97"
        stroke="#F44336"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8499 17.1406L26.1999 27.2106C26.0899 28.7806 25.9999 30.0006 23.2099 30.0006H16.7899C13.9999 30.0006 13.9099 28.7806 13.7999 27.2106L13.1499 17.1406"
        stroke="#F44336"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18.3301 24.5H21.6601" stroke="#F44336" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 20.5H22.5" stroke="#F44336" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
