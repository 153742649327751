import { useEffect, useRef, useState } from "react";
import classes from "./DropDownSearch.module.scss";
import Label from "../Label/Label";
const DropDownSearch = (props) => {
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState("");
  const resultRef = useRef(null);

  const getDropDownData = async () => {
    const results = await props.getData(searchValue);
    setResult(results);
    setShowResult(true);
  };
  useEffect(() => {
    let timer;
    if (searchValue) {
      timer = setTimeout(() => {
        getDropDownData();
      }, 500);
    } else {
      setResult([]);
    }

    return () => clearTimeout(timer);
  }, [searchValue]);
  const handleClickOutside = (event) => {
    if (resultRef.current && !resultRef.current.contains(event.target)) {
      setShowResult(false);
    }
  };
  useEffect(() => {
    if (showResult) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showResult]);

  return (
    <>
      <div className={`${classes.search}   !tw-mb-0 `}>
        <div className="row px-0 mx-0 ">
          <div className="w-auto px-0 d-inline">
            <Label
              textColor={props.textColor}
              for={props.title}
              value={props.title}
            />
          </div>
          {props.linkText && (
            <div
              onClick={() => props.linkText && props.linkText.fn()}
              className={`w-auto d-inline text-end ms-auto ${classes["main-color"]}`}
            >
              {props.linkText && props.linkText.text}
            </div>
          )}
        </div>
        <div className="position-relative">
          <input
            onChange={(e) => setSearchValue(e.target.value)}
            type="text"
            value={searchValue ? searchValue : props.clear ? "" : selected}
            placeholder={props.placeholder}
            className={`${props.error && classes.error}`}
          />
          {showResult && (
            <div
              style={{
                backgroundColor: "white !important",
                zIndex: "5",
                cursor: "pointer",
                top: "45px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              ref={resultRef}
              className="position-absolute    flex align-items-center w-100 rounded  "
            >
              {result.length > 0 ? (
                result.map((item) => (
                  <p
                    onClick={() => {
                      setSelected(item);
                      setSearchValue("");
                      setShowResult(false);
                      props.setResult(item);
                    }}
                    style={{ background: "#fff" }}
                    onMouseOver={(e) => (e.target.style.background = "#f0f0f0")}
                    onMouseOut={(e) => (e.target.style.background = "#fff")}
                    className="d-block h-auto justify-content-center flex-column pt-1 flex  text-black  px-5   mb-0 "
                  >
                    {item}
                  </p>
                ))
              ) : (
                <span className="d-block text-center text-black font-semibold px-5   mb-0">
                  There is No Matching
                </span>
              )}
            </div>
          )}
        </div>{" "}
        {props.error && (
          <div className="mt-2">
            <span className="errorMsg pt-[15px]">{props.errorMsg}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default DropDownSearch;
