// import useCheckResponse from './UseCheckResponse';

export let serverErrorCatcher = (response, setServerError, setServerErrorMsg) => {
  //console.log(response);
  if (response.response) {
    setServerErrorMsg(response.response.data.error || response.response.data.message);
    setServerError(true);
    localStorage.setItem('errorMsg', response.response.data.error || response.response.data.message);
    return -1;
  } else {
    if (response.message === 'Network Error') {
      // throw new Error('Required');
      setServerErrorMsg("You're Offline Now ,Please check Your Internet Connection");
      setServerError(true);
      localStorage.setItem('errorMsg', 'Offline');
      return -1;
    } else if (response.message === 'canceled') {
      // setServerErrorMsg('The Request has been cancelled');
      // setServerError(true);
      // localStorage.setItem('errorMsg', 'cancelled');
      return -1;
    }
    return true;
  }
};
