import axios from 'axios';

export const MainDataService = {
  getData,
  addSocial,
  deleteSocial,
  updateSocial,
  updateAppLinks,
};

async function getData() {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/about/links`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    let response = await axios(config);

    return response;
  } catch (e) {
    return e;
  }
}
async function addSocial(data) {
  try {
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/about/social`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };

    let response = await axios(config);

    return response;
  } catch (e) {
    // verifyAccount(e);
    return e;
  }
}
async function updateSocial(data, id) {
  try {
    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/about/social/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };

    let response = await axios(config);

    return response;
  } catch (e) {
    // verifyAccount(e);
    return e;
  }
}
async function updateAppLinks(data) {
  try {
    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/about/app/links`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };

    let response = await axios(config);

    return response;
  } catch (e) {
    // verifyAccount(e);
    return e;
  }
}
async function deleteSocial(id) {
  try {
    var config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/main-data/about-social/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      //   data,
    };

    let response = await axios(config);

    return response;
  } catch (e) {
    // verifyAccount(e);
    return e;
  }
}
