import React, { useState } from 'react';
import { reportDownloadHelper } from '../../../../Helper/ReportDownload/Download';
import Button from '../../UI/Button/Button';
import { Radio } from '@mui/material';
import classes from './Download.module.scss';
export default function DownloadPopUp(props) {
  const [downloadOption, setDownloadOption] = useState(null);

  return (
    <>
      <div className="row px-0 mx-0 mb-2">
        <div className={`w-auto d-inline ${classes['title']} px-0`}>Download</div>
      </div>

      {props.options.map((item, index) => (
        <div key={index} className={`row mx-0 mb-2   rounded-3 mx-0 py-0 align-items-center ${classes['header-option']}`}>
          <div className="w-auto h-auto align-items-center  d-inline">
            <div className=" w-auto py-0 my-0 h-auto">
              <Radio
                checked={downloadOption === item ? true : false}
                onClick={() => {
                  setDownloadOption(item);
                }}
                className="h-auto py-0 my-0"
              />
            </div>
          </div>
          <div
            onClick={() => {
              setDownloadOption(item);
            }}
            className="w-auto d-inline h-100"
          >
            {item}
          </div>
        </div>
      ))}

      <div className="row px-0 mx-0 mt-5">
        <Button
          onClick={() => {
            reportDownloadHelper.downloadCSV(props.id, downloadOption, props.type, props.setServerError, props.setServerErrorMsg);
          }}
          color={'#D1E6FF'}
          borderColor={'#D1E6FF'}
          fontColor={'#134074'}
          className={'w-auto px-5 mx-auto'}
          type="normal"
          text={'Download'}
        />
      </div>
    </>
  );
}
