import React from 'react';

export default function AreaActiveL(props) {
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0002 18.3547C21.6602 19.06 22.6668 19.9853 22.6668 21C22.6668 23.2093 17.8908 25 12.0002 25C6.1095 25 1.3335 23.2093 1.3335 21C1.3335 19.9867 2.34016 19.0587 4.00016 18.3547"
        stroke={props.color || '#134074'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6668 8.25867C18.6668 12.3827 12.0002 19.6667 12.0002 19.6667C12.0002 19.6667 5.3335 12.3827 5.3335 8.25867C5.3335 4.136 8.31883 1 12.0002 1C15.6815 1 18.6668 4.136 18.6668 8.25867Z"
        stroke={props.color || '#134074'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0003 8.99998C12.7367 8.99998 13.3337 8.40303 13.3337 7.66665C13.3337 6.93027 12.7367 6.33331 12.0003 6.33331C11.2639 6.33331 10.667 6.93027 10.667 7.66665C10.667 8.40303 11.2639 8.99998 12.0003 8.99998Z"
        stroke={props.color || '#134074'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
