import { Policy } from "@mui/icons-material";
import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const originalDealsSupplierService = {
  createDealResponse,
  getAllDeals,
  getDealRequests,
};

async function getDealRequests(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/original-deal/supplier/requests?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getAllDeals(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/original-deal/supplier/all-deals?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function createDealResponse(data, id) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/original-deal/new-response/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
