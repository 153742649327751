import React from "react";
import classes from "./Voucher.module.scss";
import VoucherActieveL from "../../components/Reusable/SVG/VoucherActieveL";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import PeopleActiveL from "../../components/Reusable/SVG/PeopleActiveL";
import PersonActiveL from "../../components/Reusable/SVG/PersonActiveL";
import Button from "../../components/Reusable/UI/Button/Button";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
export default function ScanedVoucher() {
  return (
    <div className={`w-100 ${classes["voucher"]}`}>
      <CompanyNav />
      <section className="custom-container">
        <div className={classes["title"]}>
          <PageTitle text="Voucher Details" icon={<VoucherActieveL />} />
        </div>
        <section className={`${classes["main-section"]}`}>
          <div
            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
          >
            <div className="col-md-6 col-12 px-0">
              <div dir="auto" className={classes["single-item"]}>
                <p>Voucher Code</p>
                <span>+54545 84878 44545</span>
              </div>
            </div>
            <div className="col-md-6 col-12 px-0">
              <div dir="auto" className={classes["single-item"]}>
                <p>Voucher Status</p>
                <span>+54545 84878 44545</span>
              </div>
            </div>
          </div>
          <div
            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
          >
            <div className="col-md-6 col-12 px-0">
              <div dir="auto" className={classes["single-item"]}>
                <p>Voucher Title</p>
                <span>+54545 84878 44545</span>
              </div>
            </div>
            <div className="col-md-6 col-12 px-0">
              <div className={classes["single-item"]}>
                <p>عنوان الكوبون</p>
                <span>العنوان</span>
              </div>
            </div>
          </div>
          <div
            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
          >
            <div className="col-md-6 col-12 px-0">
              <div dir="auto" className={classes["single-item"]}>
                <p>Voucher Value Left</p>
                <span>+54545 84878 44545</span>
              </div>
            </div>
            <div dir="auto" className="col-md-6 col-12 px-0">
              <div className={classes["single-item"]}>
                <p>Voucher Total Value</p>
                <span>Mostaafa@gmail.com</span>
              </div>
            </div>
          </div>
          <div
            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
          >
            <div className="col-md-6 col-12 px-0">
              <div dir="auto" className={classes["single-item"]}>
                <p>Voucher Expiry Date</p>
                <span>+54545 84878 44545</span>
              </div>
            </div>
          </div>
          <PageTitle text="Customer Data" icon={<PersonActiveL />} />
          <div
            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
          >
            <div className="col-md-6 col-12 px-0">
              <div dir="auto" className={classes["single-item"]}>
                <p>Customer Name</p>
                <span>+54545 84878 44545</span>
              </div>
            </div>
            <div dir="auto" className="col-md-6 col-12 px-0">
              <div className={classes["single-item"]}>
                <p>Customer Phone</p>
                <span>123456</span>
              </div>
            </div>
          </div>

          <div className="row px-0 mx-0 align-items-center">
            <div className="col-6 px-0 pe-2">
              <div className={classes.input}>
                <InputGroup
                  //   default={props.data && props.data.title}
                  //   name="industry"
                  //   onChange={(e) => setindustry(e.target.value)}
                  // name="sectionTitle"
                  //   type="date"
                  value={"Required Value"}
                  //   underLineText="when users cant join anymore"
                />
              </div>
            </div>

            <div className="w-auto mt-3 d-flex px-0">
              {/* <div
                      className={`w-auto d-inline ms-auto mx-0 px-0  justify-content-end mb-pb-0 `}
                    > */}
              {/* <div className="w-auto  ms-auto"> */}
              <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                <div className={`  mb-pb-0`}>
                  <Button
                    onClick={() => {
                      // setOption("Media");
                    }}
                    // icon={<Plus />}

                    className={"w-auto d-inline px-3  ms-auto text-end"}
                    type="normal"
                    text={"Use Voucher"}
                  />
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </section>
      </section>
    </div>
  );
}
