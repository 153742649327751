import React from "react";

export default function Docs(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1102 15.6844C15.0928 15.6602 15.0699 15.6406 15.0434 15.6271C15.0169 15.6137 14.9875 15.6068 14.9578 15.607H13.9219C13.7695 15.607 13.6805 15.7805 13.7695 15.9047L15.4992 18.2977C15.5742 18.4008 15.7266 18.4008 15.8016 18.2977L18.4781 14.5945C18.5672 14.4703 18.4781 14.2969 18.3258 14.2969H17.2875C17.2266 14.2969 17.1703 14.325 17.1352 14.3742L15.6469 16.432L15.1102 15.6844ZM16.125 7.17188V6.04688C16.125 5.94375 16.0406 5.85938 15.9375 5.85938H6.9375C6.83437 5.85938 6.75 5.94375 6.75 6.04688V7.17188C6.75 7.275 6.83437 7.35938 6.9375 7.35938H15.9375C16.0406 7.35938 16.125 7.275 16.125 7.17188ZM6.9375 9.23438C6.83437 9.23438 6.75 9.31875 6.75 9.42188V10.5469C6.75 10.65 6.83437 10.7344 6.9375 10.7344H11.25C11.3531 10.7344 11.4375 10.65 11.4375 10.5469V9.42188C11.4375 9.31875 11.3531 9.23438 11.25 9.23438H6.9375ZM11.25 19.9688H4.875V3.46875H18V10.4062C18 10.5094 18.0844 10.5938 18.1875 10.5938H19.5C19.6031 10.5938 19.6875 10.5094 19.6875 10.4062V2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H11.25C11.3531 21.6562 11.4375 21.5719 11.4375 21.4688V20.1562C11.4375 20.0531 11.3531 19.9688 11.25 19.9688ZM20.6859 12.45L16.1859 10.8867C16.1648 10.8797 16.1461 10.8773 16.125 10.8773C16.1039 10.8773 16.0828 10.8797 16.0641 10.8867L11.5641 12.45C11.5273 12.4625 11.4953 12.4861 11.4727 12.5176C11.45 12.5492 11.4377 12.587 11.4375 12.6258V18.5109C11.4375 18.5695 11.4633 18.6234 11.5102 18.6586L16.0102 22.1789C16.043 22.2047 16.0852 22.2188 16.125 22.2188C16.1648 22.2188 16.207 22.2047 16.2398 22.1789L20.7398 18.6586C20.7844 18.6234 20.8125 18.5695 20.8125 18.5109V12.6258C20.8125 12.5461 20.7609 12.4758 20.6859 12.45ZM19.3594 17.8992L16.125 20.4281L12.8906 17.8992V13.5234L16.125 12.3984L19.3594 13.5234V17.8992Z"
        fill={props.color || "#134074"}
      />
    </svg>
  );
}
