import React, { useEffect } from 'react';
import classes from './AddMember.module.scss';
import Close from '../../SVG/Close';
import Img from '../Img';
import { useState } from 'react';
import { originalDealsEshtreanaService } from '../../../../services/OriginalDealsEshtreana';
import { serverErrorCatcher } from '../../../../Helper/ServerErrorCatcher';
import SuccessPopup from './SuccessPopup';
import Modal from '../../UI/Modal/Modal';
import FailPop from './FailPop';
import Map from '../Map/Map';
import { coldDealsEshtreanaService } from '../../../../services/ColdDealsEshtreana';
import { errorActions } from '../../../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';

export default function SingleDealOrder(props) {
  const [selectedOption, setSelectedOption] = useState('');

  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showMap, setShowMap] = useState(false);
  useEffect(() => {
    setSelectedOption(props.data.status);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      serverError && setServerError(false);
    }, 2000);
  }, [serverError]);

  let handleChangeStatus = async (status) => {
    let response = await originalDealsEshtreanaService.changeOrderStatus(props.data._id, status);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      props.updateData();
      setShowConfirmation(true);
      setSelectedOption(status);
      setTimeout(() => {
        setShowConfirmation(false);
        props.setShowForm(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  return (
    <div style={{ height: `${window.innerHeight - 112}px`, overflow: 'scroll' }}>
      {serverError && (
        <Modal
          style={{ padding: '24px', zIndex: '50000000' }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup message1={'The Deal Status is Changed Successfully'} />
        </Modal>
      )}

      {showMap && (
        <Modal
          onClose={() => {
            setShowMap(false);
          }}
          style={{
            // position: "absolute",
            left: '7%',
            padding: 0,
            top: '75px',
            width: '87%',
            height: `${window.innerHeight - 150}px`,
          }}
        >
          <Map
            type={'show'}
            location={{
              lng: props.data.address.lng,
              lat: props.data.address.lat,
            }}
            setShowForm={setShowMap}
          />
        </Modal>
      )}
      <div className="row px-0 mx-0">
        <div className={`col-10 px-0 ${classes.title}`}>Order #{props.data.uuid}</div>
        <div onClick={() => props.setShowForm(false)} className="cursor col-2 px-0 text-end">
          <Close color="#134074" />
        </div>
      </div>

      <div className="row px-0 mx-0">
        <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Customer ID <span className="d-block">{props.data.customer.customerID} </span>
          </p>
        </div>
        <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Customer Name <span className="d-block">{props.data.customer.customerName} </span>
          </p>
        </div>
        <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Customer Phone <span className="d-block">{props.data.customer.customerPhone}</span>
          </p>
        </div>
      </div>
      {props.data.customer.customerType === 'Organization' && (
        <div className="row px-0 mx-0">
          <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
            <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
              Contact Person Name <span className="d-block">5555 </span>
            </p>
          </div>
          <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
            <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
              Contact Person Email <span className="d-block">5555 </span>
            </p>
          </div>
          <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
            <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
              Contact Person Phone <span className="d-block">5555 </span>
            </p>
          </div>
        </div>
      )}
      <div className="row px-0 mx-0">
        <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Product Code <span className="d-block">{props.data.product.uuid} </span>
          </p>
        </div>
        <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Product Name <span className="d-block">{props.data.product.name_en}</span>
          </p>
        </div>
        <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Product Model <span className="d-block">{props.data.product.model} </span>
          </p>
        </div>
      </div>
      <div className="row px-0 mx-0">
        {props.data.color && (
          <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
            <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
              Product Color <span className="d-block">{props.data.color} </span>
            </p>
          </div>
        )}
        {props.data.size && (
          <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
            <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
              Product Size <span className="d-block">{props.data.size}</span>
            </p>
          </div>
        )}
        <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Quantity <span className="d-block">{props.data.quantity} </span>
          </p>
        </div>
      </div>
      <div className="row px-0 mx-0">
        {props.data.product.unit && (
          <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
            <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
              Unit <span className="d-block">{props.data.product.unit} </span>
            </p>
          </div>
        )}
        <div className={`${classes['single-item']} col-xl-4 col-md-6 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Unit Price <span className="d-block">{props.data.unitPrice} </span>
          </p>
        </div>
      </div>

      <div className="row px-0 mx-0">
        <div className={`${classes['single-item']} col-12 px-0 `}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Status :
            {(props.deliveryResponsibility === 'Eshtarena' && localStorage.getItem('side') === 'Top Manager') ||
            (props.deliveryResponsibility !== 'Eshtarena' && localStorage.getItem('side') === 'Supplier') ||
            props.type !== 'DealOrder' ? (
              ['Delivered', 'Shipping', 'Preparing'].map((item, index) => (
                <span
                  onClick={() => {
                    handleChangeStatus(item);
                  }}
                  key={index}
                  className={`w-auto d-inline ${item === props.data.status ? classes[item] : classes.status}`}
                >
                  {item}
                </span>
              ))
            ) : (
              <span className={`w-auto d-inline ${classes[props.data.status]}`}>{props.data.status}</span>
            )}
          </p>
        </div>
      </div>
      <div className="row px-0 mx-0">
        <div className={`${classes['single-item']} 12 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Customer Location Description <span className="d-block">{props.data.address.locationDetails}</span>
          </p>
        </div>
      </div>
      <div className="row px-0 mx-0">
        <div className={`${classes['single-item']} 12 px-0`}>
          <p style={{ fontWeight: '600', fontSize: '14px', color: '#134074' }}>
            Location on Map
            <span
              onClick={() => {
                setShowMap(true);
              }}
              className="d-block"
            >
              <Img
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMap(true);
                }}
                noShow="true"
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '115px',
                  cursor: 'pointer',
                }}
                src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
              />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
