import React from "react";
import classes from "./TermsSections.module.scss";
import Section from "../Section/Section";

export default function TermsSections(props) {
  return (
    <>
      {props.title && (
        <div className="row  pt-0 px-0 mx-0">
          <div className="col-12 px-0 pe-2 ">
            <div
              className={`${classes.input1}   row d-flex align-items-center`}
            >
              <p style={{ fontSize: "20px" }} className="w-auto d-inline">
                {props.title}
              </p>
            </div>
          </div>
        </div>
      )}
      {props.terms.length > 0 ? (
        props.terms.map((item, index) => (
          <div key={index} className="row mt-0 px-0 mx-0">
            <div className="col-lg-6 pe-lg-3 px-0">
              <Section
                border={props.border}
                title={item.title_en}
                text={item.desc_en}
              />
            </div>

            <div className="col-lg-6 px-0 ps-lg-2 ms-auto">
              <Section
                border={props.border}
                title={item.title_ar}
                text={item.desc_ar}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="w-100 row justify-content-center pt-5 pb-4">
          <div className="no-found-data mx-auto w-auto  text-center w-100 ">
            There are No Terms Found.
          </div>
        </div>
      )}
    </>
  );
}
