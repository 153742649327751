import React from 'react';

export default function RequestsSVG(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 8C20.6569 8 22 6.65685 22 5C22 3.34315 20.6569 2 19 2C17.3431 2 16 3.34315 16 5C16 6.65685 17.3431 8 19 8Z"
        stroke={props.color || '#134074'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 13H12" stroke={props.color || '#134074'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 17H16" stroke={props.color || '#134074'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
        stroke={props.color || '#134074'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
