import React, { useState } from 'react';
import classes from './MemberCard.module.scss';
import Img from '../Img';
import Eye2 from '../../SVG/Eye2';
import FilterDots from '../../SVG/FilterDots';
import ReactDOM from 'react-dom';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import avatar from '../../../../assests/avatar.jpg';

export default function ManagerHeader(props) {
  const history = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  return (
    <>
      {showOptions &&
        ReactDOM.createPortal(
          <div
            className={classes.backdrop}
            onClick={() => {
              setShowOptions(false);
            }}
          ></div>,
          document.getElementById('overlays'),
        )}
      {props.loading ? (
        <div className={`row px-0 mx-0 align-items-center ${classes['header']}`}>
          <div className="w-auto d-inline px-0 mx-0">
            <Skeleton variant="circular" width={100} height={100} />
          </div>
          <div className="w-auto d-inline px-0">
            <div className={`row px-3 mx-0 ${props.response && 'mb-3'}`}>
              <h1 className="w-auto d-inline">
                {' '}
                <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
              </h1>
              {props.response && (
                <div
                  className={`w-auto d-inline ${
                    props.response === 'Accepted' ? classes['Accepted'] : props.response === 'Rejected' ? classes['Rejected'] : classes['Review']
                  }`}
                >
                  {props.response}
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              // setShowOptions(true);
            }}
            className="w-auto cursor d-inline px-0 ms-auto position-relative"
          >
            <FilterDots color="#134074" />
          </div>
        </div>
      ) : (
        <div className={`row row-cols-2 px-0 mx-0 align-items-center ${classes['header']}`}>
          <div className="w-auto col d-inline cursor  align-self-start d-flex px-0 mx-0">
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
                (props.type === 'Marketing Manager' && 'marketing-manager') ||
                (props.type === 'Category Manager' && 'category-manager') ||
                (props.type === 'cashier' && 'cashier') ||
                (props.type === 'supplier' && 'supplier')
              }/${props.profilePicture}`}
              onError={(e) => {
                e.currentTarget.src = avatar;
              }}
              alt={'profile_image'}
            />
          </div>
          <div className="d-flex flex-1 flex-grow-1  flex-grow  flex-column col">
            <div className="row row-cols-2 align-items-center px-0 mx-0">
              <div className="w-auto col d-flex flex-column  d-inline px-0 mx-0">
                <div className={`row px-0 mx-0  ${props.response && 'mb-3'}`}>
                  <h1
                    onClick={() => {
                      props.id && history(props.type === 'supplier' && `/account/suppliers/${props.id}`);
                    }}
                    className="w-auto cursor h-auto d-inline"
                  >
                    {props.data.name}
                  </h1>
                  {props.response && (
                    <div
                      className={`w-auto d-inline ${
                        props.response === 'Accepted' ? classes['Accepted'] : props.response === 'Rejected' ? classes['Rejected'] : classes['Review']
                      }`}
                    >
                      {props.response}
                    </div>
                  )}
                </div>
                {props.data.categories && (
                  <div className="row px-0 text-wrap mx-0">
                    {props.data.categories.map((item, index) => (
                      <div key={index} className={`w-auto d-inline d-flex align-items-center mb-1 ${classes['category-option']}`}>
                        {item.item ? item.item.name_en : item.name_en ? item.name_en : item}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {props.optionComponent && !props.button && (
                <div
                  onClick={() => {
                    setShowOptions(true);
                    props.setClose && props.setClose(true);
                  }}
                  className="w-auto cursor col d-inline px-0 ms-auto position-relative"
                >
                  <FilterDots color="#134074" />

                  {showOptions && props.closeOption && (
                    <div style={{ zIndex: '50000' }} className={`position-absolute  ${classes['options-filter']}`}>
                      {props.optionComponent}
                    </div>
                  )}
                </div>
              )}
              {props.button && (
                <div
                  onClick={() => {
                    setShowOptions(true);
                    props.setClose && props.setClose(true);
                  }}
                  className="w-lg-auto  mt-lg-0 col d-flex d-lg-inline d-none flex-column align-self-end   cursor d-inline px-0 d-flex   ms-auto position-relative"
                  style={{ height: 'auto' }}
                >
                  {props.button}
                  {showOptions && props.closeOption && (
                    <div style={{ zIndex: '50000' }} className={`position-absolute  ${classes['options-filter']}`}>
                      {props.optionComponent}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {props.button && (
        <div
          onClick={() => {
            setShowOptions(true);
            props.setClose && props.setClose(true);
          }}
          className="w-100 d-lg-none mt-2 col d-flex flex-column align-self-center   cursor d-inline px-0 d-flex   ms-auto position-relative"
          style={{ height: 'auto' }}
        >
          {props.button}
          {showOptions && props.closeOption && (
            <div style={{ zIndex: '50000' }} className={`position-absolute  ${classes['options-filter']}`}>
              {props.optionComponent}
            </div>
          )}
        </div>
      )}
      <div className="br"></div>
    </>
  );
}
