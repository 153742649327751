import React, { useState } from 'react';

import classes from './AddMember.module.scss';
import Error from '../../SVG/Error';
import Button from '../../UI/Button/Button';
import Close from '../../SVG/Close';
import Modal from '../../UI/Modal/Modal';
import SuccessPopup from './SuccessPopup';
export default function RemoveCard(props) {
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(false);
      props.onClick();
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
        props.setShowForm(false);
      }, 2000);
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <>
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={`Deleted Successfully`} />
        </Modal>
      )}
      <div className={`row mx-0 justify-content-cenetr text-cenetr px-0 mb-2 ${classes.remove}`}>
        <div className="row mx-0 px-0 ">
          <div onClick={() => props.setShowForm(false)} className="cursor col-2 ms-auto   px-0 text-end">
            <Close color="#134074" />
          </div>
        </div>
        <div className="row mx-0 px-0 mt-4">
          <Error />
        </div>
        <p className="text-center">{props.message1 ? props.message1 : 'Are you sure you want to remove this member?'}</p>
        <span className="text-center">{props.message2 ? props.message2 : 'By removing this member, they won’t show on the company profile'}</span>

        <div className="row mx-0 px-0 justify-content-center">
          <div className={`w-auto ${classes.button}  order-3 d-inline text-end position-relative px-0 `}>
            <Button onClick={() => props.setShowForm(false)} text="Cancel" type="normal" fontColor={'#134074'} color={'transparent'} />
          </div>
          {/* <div className="row mx-0 px-0 justify-content-center"> */}
          <div className="w-auto  order-3 d-inline text-end position-relative px-0 ">
            {loading ? (
              <div className="text-center">
                <span className="spinner-border spinner-border-sm"></span>
              </div>
            ) : (
              <Button
                onClick={() => {
                  setLoading(true);
                  handleSubmit();
                }}
                text={props.buttonText ? props.buttonText : 'Remove Member'}
                type="normal"
                fontColor={'white'}
                color={'Red'}
              />
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
