import React, { useState } from 'react';
import classes from './OriginalDeals.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import DealsAvtiveL from '../../components/Reusable/SVG/DealsAvtiveL';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import { styled } from '@mui/material/styles';
import ProgressBar from '@ramonak/react-progress-bar';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import Plus from '../../components/Reusable/SVG/Plus';
import { Radio } from '@mui/joy';
import Filters from '../../components/Reusable/SVG/Filters';
import ReactDOM from 'react-dom';
import Close from '../../components/Reusable/SVG/Close';
import Button from '../../components/Reusable/UI/Button/Button';
import Filter from '../../components/Reusable/UI/Filter/Filter';
import VoucherCard from '../../components/Reusable/Common/Voucher/VoucherCard';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { originalDealsSupplierService } from '../../services/OriginalDealsSuppliers';
import { useEffect } from 'react';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { subcategoryService } from '../../services/subCategoryService';
import { areaService } from '../../services/areaService';
import { categoryService } from '../../services/categoryService';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
export default function SupplierDeals() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [option, setOption] = useState('On Going');
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [selectedDealType, setSelectedDealType] = useState('select type');
  const [selectedCategory, setSelectedCategory] = useState('select category');
  const [selectedSubCategory, setSelectedSubCategory] = useState('select sub-category');
  const [selectedArea, setSelectedArea] = useState('select city');
  const [selectedDistrict, setSelectedDistrict] = useState('select district');
  const [searchValue, setSearchValue] = useState('');
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    mainDataHelper.getAllCities(setServerError, setServerErrorMsg, setCities);
    mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
  }, []);
  useEffect(() => {
    if (!firstLoad) {
      if (selectedArea !== 'select city') mainDataHelper.getAllDistrict(setServerError, setServerErrorMsg, selectedArea, setDistrict);
      else {
        setSelectedDistrict('select district');
        setDistrict([]);
      }
    }
  }, [selectedArea]);

  let getAllDeals = async () => {
    let query = '';
    query += `${`&type=${option.toLocaleLowerCase()}`}`;
    query += `${
      selectedDealType && selectedDealType !== ' ' && selectedDealType !== 'select type' ? `${`&dealType=${selectedDealType.toLowerCase()}`}` : ''
    }`;
    query += `${
      selectedCategory && selectedSubCategory !== ' ' && selectedCategory !== 'select category'
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ''
    }`;
    query += `${
      selectedDistrict && selectedDistrict !== ' ' && selectedDistrict !== 'select district' ? `&district=${selectedDistrict.toLowerCase()}` : ''
    }`;
    query += `${selectedArea && selectedArea !== ' ' && selectedArea !== 'select city' ? `&area=${selectedArea}` : ''}`;
    query += `${searchValue ? `&search=${searchValue}` : ''}`;
    let response = await originalDealsSupplierService.getAllDeals(page, 6, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setDeals(response.data.deals);
      setTotalPages(response.data.totalPages);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
    setLoadingSearch(false);
  };

  let getAllRequests = async () => {
    let query = '';
    query += `${
      selectedDealType && selectedDealType !== ' ' && selectedDealType !== 'select type' ? `${`&dealType=${selectedDealType.toLowerCase()}`}` : ''
    }`;
    query += `${
      selectedCategory && selectedSubCategory !== ' ' && selectedCategory !== 'select category'
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ''
    }`;
    query += `${
      selectedDistrict && selectedDistrict !== ' ' && selectedDistrict !== 'select district' ? `&district=${selectedDistrict.toLowerCase()}` : ''
    }`;
    query += `${selectedArea && selectedArea !== ' ' && selectedArea !== 'select city' ? `&area=${selectedArea}` : ''}`;
    query += `${searchValue ? `&search=${searchValue}` : ''}`;

    let response = await originalDealsSupplierService.getDealRequests(page, 6, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setDeals(response.data.deals);
      setTotalPages(response.data.totalPages);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
    setLoadingSearch(false);
  };
  useEffect(() => {
    option === 'Requests' ? getAllRequests() : getAllDeals();
    setFirstLoad(false);
  }, [page]);
  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
      page === 1 ? (option === 'Requests' ? getAllRequests() : getAllDeals()) : setPage(1);
    }
  }, [selectedArea, selectedCategory, selectedDistrict, selectedDealType]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstLoad) {
        setLoadingSearch(true);
        page === 1 ? (option === 'Requests' ? getAllRequests() : getAllDeals()) : setPage(1);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    if (!firstLoad) {
      setSelectedDealType('select type');
      setSelectedCategory('select category');
      setSelectedSubCategory('select sub-category');
      setSelectedArea('select city');
      setSelectedDistrict('select district');
      setLoading(true);
      page === 1 ? (option === 'Requests' ? getAllRequests() : getAllDeals()) : setPage(1);
    }
  }, [option]);

  useEffect(() => {
    serverError &&
      setTimeout(() => {
        setServerError(false);
      }, 2000);
  }, [serverError]);

  return (
    <div className={`w-100 ${classes['original-deals']}`}>
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className={'backdrop'}
            onClick={() => {
              setShowFilters(false);
            }}
          ></div>,
          document.getElementById('overlays'),
        )}
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle onClick={() => history('/account/original-deal/new')} text={'Original Deals'} icon={<DealsAvtiveL />} />
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
              {/* <div className={`row px-0 mx-0   ${classes.options}`}> */}
              <div className="row px-0 mx-0">
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
              </div>
              {/* </div> */}
            </div>

            <div className={`row   mx-0 px-0  mt-0  align-items-end  ${classes['table-option']}`}>
              <div className="col-xl-3  col-12 px-0 mb-xl-0 mb-2 ">
                <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
              </div>
              <div className="col-xl-9 px-0 ps-xl-2 pe-xl-4">
                <div className="row px-0 mx-0 mb-xl-0 mb-2">
                  <div className="col-xl-3 col-3 pe-1 px-0">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>
                  <div className="col-xl-3 col-3 pe-1 px-0">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>

                  <div className="col-xl-3 col-3 px-0 ps-1">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>
                  <div className="col-xl-3 col-3 px-0 ps-1">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="row px-0 mx-0">
                {[0, 1, 2, 3, 4, 5].map((item, index) => (
                  <div key={index} className={`col-xl-4 col-lg-6 px-0 pe-lg-3`}>
                    <Skeleton
                      variant="rounded"
                      sx={{
                        borderRaduis: '10px',
                        fontSize: '2rem',
                        width: '100%',
                        height: '400px',
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          </section>
        ) : (
          <section className={`${classes['main-section']}`}>
            {!firstLoad && (
              <>
                {' '}
                <div className={`row px-0 mx-0   ${classes.options}`}>
                  <SelectOption
                    onSelect={(val) => {
                      setSearchValue('');

                      setOption(val);
                    }}
                    default={option}
                    options={['On Going', 'Requests', 'Scheduled', 'History']}
                  />
                </div>
                <div className={`row   mx-0 px-0  mt-0  align-items-end  ${classes['table-option']}`}>
                  <div className="col-xl-4  col-12 px-0 mb-xl-0 mb-2 ">
                    <SearchInput
                      onChange={(val) => {
                        setLoadingSearch(true);
                        setSearchValue(val.target.value);
                      }}
                      placeholder={`Search by Deal Name or Deal Code`}
                    />
                  </div>
                  <div className="col-xl-8 px-0 ps-xl-2 pe-xl-4">
                    <div className="row px-0 mx-0 mb-xl-0 mb-2">
                      {/* {categoriesName.length > 0 && ( */}
                      {true && (
                        <div className="col-xl-3  col-3 pe-1 px-0">
                          <InputGroup
                            options={['select type', 'Original', 'Big area deal', 'Big deal', 'Area deal']}
                            filter={true}
                            onChange={(e) => setSelectedDealType(e.target.value)}
                            type="dropdown"
                            value={'Deal Type'}
                            default={selectedDealType}
                          />
                        </div>
                      )}
                      {categories && (
                        <div className="col-xl-3  col-3 pe-1 px-0">
                          <InputGroup
                            options={['select category', ...categoriesName]}
                            filter={true}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            type="dropdown"
                            value={'Category'}
                            default={selectedCategory}
                          />
                        </div>
                      )}

                      {cities.length > 0 && (
                        <div className="col-xl-3 col-3 px-0 ps-1">
                          <InputGroup
                            options={['select city', ...cities]}
                            filter={true}
                            onChange={(e) => setSelectedArea(e.target.value)}
                            type="dropdown"
                            value="Area"
                          />
                        </div>
                      )}
                      {district.length > 0 && (
                        <div className="col-xl-3 col-3 px-0 ps-1">
                          <InputGroup
                            options={['select District', ...district]}
                            filter={true}
                            onChange={(e) => setSelectedDistrict(e.target.value)}
                            type="dropdown"
                            value="District"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === 'On Going' && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: 'عنوان العرض',
                          value: item.title_ar,
                        },
                        {
                          title: 'Deal Name',
                          value: item.title_en,
                        },
                        {
                          title: 'Deal Code',
                          value: item.uuid,
                        },
                        {
                          title: 'Time Left',
                        },
                        {
                          title: 'Product Name',
                          value: item.product,
                        },
                        {
                          title: 'Market Price',
                          value: item.price,
                        },

                        {
                          title: 'Progress',
                          value: item.sold,
                          total: item.quantity,
                        },
                      ]}
                      type="On Going"
                      side={'SupplierDeal'}
                      timeLeft={item.endDate}
                      progress={true}
                      index={index}
                      status={true}
                      statusValue={
                        item.dealType === 'Big area deal'
                          ? 'Big Area Deal'
                          : item.dealType === 'Area deal'
                          ? 'Area Deal'
                          : item.dealType === 'Big deal'
                          ? 'Big Deal'
                          : 'Original Deal'
                      }
                    />
                  </div>
                ))}
              </div>
            )}
            {option === 'Scheduled' && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: 'عنوان العرض',
                          value: item.title_ar,
                        },
                        {
                          title: 'Deal Name',
                          value: item.title_en,
                        },
                        {
                          title: 'Deal Code',
                          value: item.uuid,
                        },
                        {
                          title: 'Product Name',
                          value: item.product,
                        },
                        {
                          title: 'Market Price',
                          value: item.price,
                        },
                        {
                          title: 'Starting Date',
                          value: new Date(item?.startDate).toLocaleDateString(),
                        },
                        {
                          title: 'Ending Date',
                          value: new Date(item?.endDate).toLocaleDateString(),
                        },
                      ]}
                      type="Scheduled"
                      side={'SupplierDeal'}
                      status={true}
                      statusValue={
                        item.dealType === 'Big area deal'
                          ? 'Big Area Deal'
                          : item.dealType === 'Area deal'
                          ? 'Area Deal'
                          : item.dealType === 'Big deal'
                          ? 'Big Deal'
                          : 'Original Deal'
                      }
                      progress={false}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            )}
            {option === 'Requests' && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: 'Deal Code',
                          value: item.uuid,
                        },
                        {
                          title: 'Product Name',
                          value: item.product,
                        },
                        {
                          title: 'Market Price',
                          value: item.price,
                        },
                      ]}
                      type="Requests"
                      side={'SupplierDeal'}
                      status={true}
                      statusValue={
                        item.dealType === 'Big area deal'
                          ? 'Big Area Deal'
                          : item.dealType === 'Area deal'
                          ? 'Area Deal'
                          : item.dealType === 'Big deal'
                          ? 'Big Deal'
                          : 'Original Deal'
                      }
                      progress={false}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            )}
            {option === 'History' && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: 'عنوان العرض',
                          value: item.title_ar,
                        },
                        {
                          title: 'Deal Name',
                          value: item.title_en,
                        },
                        {
                          title: 'Deal Code',
                          value: item.uuid,
                        },
                        {
                          title: 'Product Name',
                          value: item.product,
                        },
                        {
                          title: 'Market Price',
                          value: item.price,
                        },
                      ]}
                      type="History"
                      side={'SupplierDeal'}
                      status={true}
                      statusValue={
                        item.dealType === 'Big area deal'
                          ? 'Big Area Deal'
                          : item.dealType === 'Area deal'
                          ? 'Area Deal'
                          : item.dealType === 'Big deal'
                          ? 'Big Deal'
                          : 'Original Deal'
                      }
                      progress={false}
                      index={index}
                      po={item.purchasingOrder && item.purchasingOrder}
                      invoice={item.invoice && item.invoice}
                    />
                  </div>
                ))}
              </div>
            )}
            {loadingSearch ? (
              <div className="w-100 row justify-content-center pt-5 pb-4">
                <div className="no-found-data mx-auto w-auto  text-center w-100 ">Searching</div>
              </div>
            ) : (
              deals.length === 0 && (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Deals Found</div>
                </div>
              )
            )}
            {totalPages > 1 && (
              <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                <div className="w-auto d-inline mx-auto">
                  <UsePagination
                    page={page}
                    // selected={page1}
                    pages={totalPages}
                    onChangePage={(page) => {
                      setLoading(true);
                      // //console.log(page);
                      setPage(page);
                    }}
                  />
                </div>
              </div>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
