import React from 'react';

export default function CategoryActiveL() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.667 13.3335C24.8761 13.3335 26.667 11.5426 26.667 9.3335C26.667 7.12436 24.8761 5.3335 22.667 5.3335C20.4579 5.3335 18.667 7.12436 18.667 9.3335C18.667 11.5426 20.4579 13.3335 22.667 13.3335Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 26.667C11.5426 26.667 13.3335 24.8761 13.3335 22.667C13.3335 20.4579 11.5426 18.667 9.3335 18.667C7.12436 18.667 5.3335 20.4579 5.3335 22.667C5.3335 24.8761 7.12436 26.667 9.3335 26.667Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6668 18.6668H26.6668V25.3335C26.6668 25.6871 26.5264 26.0263 26.2763 26.2763C26.0263 26.5264 25.6871 26.6668 25.3335 26.6668H20.0002C19.6465 26.6668 19.3074 26.5264 19.0574 26.2763C18.8073 26.0263 18.6668 25.6871 18.6668 25.3335V18.6668ZM5.3335 5.3335H13.3335V12.0002C13.3335 12.3538 13.193 12.6929 12.943 12.943C12.6929 13.193 12.3538 13.3335 12.0002 13.3335H6.66683C6.31321 13.3335 5.97407 13.193 5.72402 12.943C5.47397 12.6929 5.3335 12.3538 5.3335 12.0002V5.3335Z"
        stroke="#6E7980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
