import React from "react";
import classes from "./Button.module.scss";
export default function Button(props) {
  return (
    <button
      disabled={props.disabled}
      style={{
        background: props.color && props.color,
        borderColor: props.borderColor
          ? props.borderColor
          : props.color && props.color,
        color: props.fontColor && props.fontColor,
      }}
      onClick={props.onClick}
      className={`row   justify-content-center px-0 mx-0    ${
        props.type === "normal" && classes.button
      }  ${props.type === "reverse" && classes["button-reverse"]} ${
        props.className
      }`}
    >
      {props.type === "normal" && (
        <div className="w-auto  h-auto mx-0 px-0 py-0 my-0 align-items-center  d-inline w-auto">
          {props.icon}
        </div>
      )}
      <div className="w-auto d-inline text-center">{props.text}</div>
      {props.type === "reverse" && (
        <div className="w-auto h-auto mx-0 px-0 py-0 my-0 align-items-center  d-inline">
          {props.icon}
        </div>
      )}
    </button>
  );
}
