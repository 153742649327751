import React from 'react';
import classes from './Area.module.scss';
import MainDataOption from './MainDataOption';
import { useState } from 'react';
import { useEffect } from 'react';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import AreaActiveL from '../../components/Reusable/SVG/Area';
import Plus from '../../components/Reusable/SVG/Plus';
import Close from '../../components/Reusable/SVG/Close';
import { areaService } from '../../services/areaService';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import Button from '../../components/Reusable/UI/Button/Button';
import RemoveCard from '../../components/Reusable/Common/AddMemberCard/RemoveMember';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import { Skeleton } from '@mui/material';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function Area() {
  const [firstLoad, setFirstLoad] = useState(true);
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ valeu_en: null, value_ar: null });
  const [editData, setEditData] = useState({});
  const [id, setId] = useState();
  const [showAddCity, setShowAddCity] = useState(false);
  const [showAddDistrict, setShowAddDistrict] = useState(false);
  const [showEditCity, setShowEditCity] = useState(false);
  const [showEditDistrict, setShowEditDistrict] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDeleteCity, setShowDeleteCity] = useState(false);
  const [showDeleteDistrict, setShowDeleteDistrict] = useState(false);
  let getCities = async () => {
    let query = '';
    query += `${searchValue && `?search=${searchValue}`}`;
    let response = await areaService.getAllCities(query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setCities(response.data.areas);
      setLoading(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  let handleAddCity = async () => {
    setShowError(true);
    if (data.value_en && data.value_ar) {
      let response = await areaService.addCity({
        city_en: data.value_en,
        city_ar: data.value_ar,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setLoading(true);
        getCities();
        setShowAddCity(false);
        setConfirmationMsg('City Added successfully');
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
        }, 2000);
      }
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  let handleAddDistrict = async () => {
    setShowError(true);
    if (data.value_en && data.value_ar) {
      let response = await areaService.addDistrict(id, {
        district_en: data.value_en,
        district_ar: data.value_ar,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setLoading(true);
        getCities();
        setShowAddDistrict(false);
        setConfirmationMsg('District Added successfully');
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
        }, 2000);
      }
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };
  let handleEditCity = async () => {
    setShowError(true);
    if (data.value_en && data.value_ar) {
      let response = await areaService.updateCity(editData._id, {
        city_en: data.value_en,
        city_ar: data.value_ar,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setLoading(true);
        getCities();
        setShowEditCity(false);
        setConfirmationMsg('City Edited successfully');
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
        }, 2000);
      }
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  let handleEditDistrict = async (id) => {
    setShowError(true);

    if (data.value_en && data.value_ar) {
      let response = await areaService.updatedistrict({
        areaId: id,
        districtId: editData.districtId,
        district_en: data.value_en,
        district_ar: data.value_ar,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setLoading(true);
        getCities();
        setShowEditDistrict(false);
        setConfirmationMsg('District Edited successfully');
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
        }, 2000);
      }
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };
  let handleDeleteCity = async () => {
    setShowError(true);
    let response = await areaService.deleteCity(editData._id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setLoading(true);
      getCities();
      setShowDeleteCity(false);
      setConfirmationMsg('City Deleted successfully');
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  let handleDeleteDistrict = async () => {
    setShowError(true);
    let response = await areaService.deleteDistrict({
      districtId: editData.districtId._id,
      areaId: editData._id,
    });
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setLoading(true);
      getCities();
      setShowDeleteDistrict(false);
      setConfirmationMsg('District Deleted successfully');
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };
  useEffect(() => {
    getCities();
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!showConfirmation || showAddCity || showAddDistrict) {
      setEditData(null);
      setData({ value_en: null, value_ar: null });
    }
  }, [confirmationMsg, showAddCity, showAddDistrict]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (!firstLoad) {
        setLoading(true)
        getCities();
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);
  return (
    <div className={`w-100 ${classes['area']}`}>
      <>
        {serverError && (
          <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
            <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
          </Modal>
        )}
        {showConfirmation && (
          <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
            <SuccessPopup setShowForm={setShowConfirmation} message1={confirmationMsg} />
          </Modal>
        )}
        {(showDeleteCity || showDeleteDistrict) && (
          <Modal
            style={{ padding: '24px' }}
            onClose={() => {
              setShowDeleteCity(false);
              setShowDeleteDistrict(false);
            }}
          >
            <RemoveCard
              setShowForm={() => {
                setShowDeleteCity(false);
                setShowDeleteDistrict(false);
              }}
              message1={`Are you sure you want to remove this ${showDeleteCity ? 'City' : 'District'}?`}
              message2={`By removing this ${showDeleteCity ? 'City' : 'District'}, it won’t show anymore.`}
              buttonText={`Remove ${showDeleteCity ? 'City' : 'District'}`}
              onClick={() => {
                showDeleteCity ? handleDeleteCity() : handleDeleteDistrict();
              }}
            />
          </Modal>
        )}
        {(showAddCity || showAddDistrict) && (
          <Modal
            style={{ padding: '24px' }}
            onClose={() => {
              setShowAddCity(false);
              setShowAddDistrict(false);
            }}
          >
            <div className="row  mx-0 px-0 py-0 d-flex align-items-center ">
              <p className={`col-10  py-0  px-0 d-inline ${classes['popup-title']}`}>Add New {showAddCity ? 'City' : 'District'}</p>
              <p
                onClick={() => {
                  setShowAddCity(false);
                  setShowAddDistrict(false);
                }}
                className="col-2 text-end h-100   px-0  cursor "
              >
                <Close color="#134074" />
              </p>
            </div>
            <div className="row px-0 mx-0">
              <div className="col-6 px-0 pe-2">
                <div className={classes.input}>
                  <InputGroup
                    default={data.value_en}
                    name="vatRegistration"
                    onChange={(e) => setData((prev) => ({ ...prev, value_en: e.target.value }))}
                    error={showError && !data.value_en}
                    errorMsg={`Provide a ${showAddCity ? 'City' : 'District'} Name`}
                    value={`${showAddCity ? 'City' : 'District'} Name`}
                  />
                </div>
              </div>
              <div className="col-6 px-0 ps-2">
                <div className={classes.input}>
                  <InputGroup
                    default={data.value_ar}
                    name="vatRegistration"
                    onChange={(e) => setData((prev) => ({ ...prev, value_ar: e.target.value }))}
                    error={showError && !data.value_ar}
                    errorMsg={`من فضلك سجل الاسم `}
                    value={`اسم ${showAddCity ? 'المدينة' : 'المنطقة'} `}
                  />
                </div>
              </div>
            </div>
            <div className="row mx-0 px-0 mt-4">
              <Button
                type="normal"
                text={`Add ${showAddCity ? 'City' : 'District'}`}
                className="w-auto ms-auto px-2"
                onClick={() => {
                  showAddCity ? handleAddCity() : handleAddDistrict();
                }}
                color={'#D1E6FF'}
                borderColor={'#134074'}
                fontColor={'#134074'}
              />
            </div>
          </Modal>
        )}
        {(showEditCity || showEditDistrict) && (
          <Modal
            style={{ padding: '24px' }}
            onClose={() => {
              setShowEditCity(false);
              setShowEditDistrict(false);
            }}
          >
            <div className="row mx-0 px-0 align-items-center mb-3">
              <div className={`col-11 ${classes['popup-title']}`}>Edit New {showEditCity ? 'City' : 'District'}</div>
              <div
                onClick={() => {
                  setShowEditCity(false);
                  setShowEditDistrict(false);
                }}
                className="col-1 cursor close-btn"
              >
                <Close />
              </div>
            </div>
            <div className="row px-0 mx-0">
              <div className="col-6 px-0 pe-2">
                <div className={classes.input}>
                  <InputGroup
                    default={showEditCity ? editData.city_en : editData.district_en}
                    name="vatRegistration"
                    onChange={(e) => setData((prev) => ({ ...prev, value_en: e.target.value }))}
                    error={showError && !data.value_en}
                    errorMsg={`Provide a ${showEditCity ? 'City' : 'District'} Name`}
                    value={`${showEditCity ? 'City' : 'District'} Name`}
                  />
                </div>
              </div>
              <div className="col-6 px-0 ps-2">
                <div className={classes.input}>
                  <InputGroup
                    default={showEditCity ? editData.city_ar : editData.district_ar}
                    name="vatRegistration"
                    onChange={(e) => setData((prev) => ({ ...prev, value_ar: e.target.value }))}
                    error={showError && !data.value_ar}
                    errorMsg={`من فضلك سجل الاسم `}
                    value={`اسم ${showAddCity ? 'المدينة' : 'المنطقة'} `}
                  />
                </div>
              </div>
            </div>
            <div className="row mx-0 px-0 mt-4">
              <Button
                type="normal"
                text={`Edit ${showEditCity ? 'City' : 'District'}`}
                className="w-auto ms-auto px-2"
                onClick={() => {
                  showEditCity ? handleEditCity() : handleEditDistrict(editData._id);
                }}
                color={'#D1E6FF'}
                borderColor={'#134074'}
                fontColor={'#134074'}
              />
            </div>
          </Modal>
        )}
        <>
          <div>
            <CompanyNav />
            <section className="custom-container">
              <div className={classes['title']}>
                <PageTitle
                  onClick={() => {
                    setEditData({ value_en: null, value_ar: null });

                    setShowAddCity(true);
                  }}
                  icon={
                    <div className="mb-2 w-auto d-inline">
                      <AreaActiveL color="gray" />
                    </div>
                  }
                  text="Working Areas Serve"
                  buttonText={`Add City`}
                  buttonType="normal"
                  buttonIcon={<Plus />}
                />
              </div>
              <section className={`${classes['main-section']}`}>
                {!firstLoad && (
                  <div className={`row   mx-0 px-0  ${classes['table-option']}`}>
                    <div className="col-xl-3 col-lg-6  px-0 mb-xl-0 ">
                      <SearchInput
                        onChange={(val) => {
                          setLoading(true);
                          setSearchValue(val.target.value);
                        }}
                        placeholder={`Search for a Area`}
                      />
                    </div>
                  </div>
                )}
                <div className={``}>
                  <div className="row   ">
                    <section className={`  col-12 mb-5 mb-lg-0 pb-5 pb-lg-0 mx-auto mx-lg-0 `}>
                      <div className={`row  mx-0 px-0 ${classes['page-title']} mb-4`}></div>
                      {loading ? (
                        [0, 1, 2, 3, 4, 5].map((item, index) => (
                          <div key={index} className="mb-2">
                            <Skeleton variant="rounded" width={'100%'} height={'80px'} style={{ borderRadius: '10px' }} />
                          </div>
                        ))
                      ) : cities.length > 0 ? (
                        cities.map((item, index) => (
                          <MainDataOption
                            type="emirates"
                            key={index}
                            cities={item.district}
                            titleEN={item.city_en}
                            titleAR={item.city_ar}
                            setShowAddCity={() => {
                              setShowError(false);
                              setData({ value_en: null, value_ar: null });
                              setShowAddCity(true);
                            }}
                            setShowAddDistrict={() => {
                              // setEditData({ ...item });
                              setId(item._id);
                              //console.log(item);
                              setShowError(false);
                              setData({ value_en: null, value_ar: null });
                              setShowAddDistrict(true);
                            }}
                            setShowEditCity={() => {
                              setEditData(item);
                              setShowError(false);
                              setData({
                                value_en: item.city_en,
                                value_ar: item.city_ar,
                              });
                              setShowEditCity(true);
                            }}
                            setShowDeleteCity={() => {
                              setEditData(item);
                              setShowError(false);
                              setShowDeleteCity(true);
                            }}
                            setShowDeleteDistrict={(data) => {
                              setEditData({ ...item, districtId: data });
                              setShowError(false);
                              setShowDeleteDistrict(true);
                            }}
                            setShowEditDistrict={(data) => {
                              setShowError(false);
                              setEditData({
                                _id: item._id,
                                districtId: data._id,
                                district_en: data.district_en,
                                district_ar: data.district_ar,
                              });
                              setData({
                                value_en: data.district_en,
                                value_ar: data.district_ar,
                              });
                              // setEditData(data);
                              setShowEditDistrict(true);
                            }}
                          />
                        ))
                      ) : (
                        <div className="w-100 row justify-content-center pt-5 pb-4">
                          <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Cities Found</div>
                        </div>
                      )}
                    </section>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </>
      </>
    </div>
  );
}
