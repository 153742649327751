import React from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';
import { useEffect } from 'react';
export const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onCloseCart}></div>;
};

const ModelOverlay = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={props.style}
      className={`overflow-hidden ${classes.modal} ${props.size === 2 && classes.modal1} `}
    >
      <div> {props.children}</div>
    </div>
  );
};

export default function Modal(props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onCloseCart={props.onClose} />,
        document.getElementById('overlays')
      )}

      {ReactDOM.createPortal(
        <ModelOverlay onClick={props.onClick} size={props.size} style={props.style && props.style}>
          {props.children}
        </ModelOverlay>,
        document.getElementById('overlays')
      )}
    </>
  );
}
