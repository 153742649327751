import React, { useState } from 'react';
import classes from './Manager.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import Img from '../../components/Reusable/Common/Img';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import TextAreaGroup from '../../components/Reusable/UI/InputGroup/TextAreaGroup';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import Button from '../../components/Reusable/UI/Button/Button';
import Close from '../../components/Reusable/SVG/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import avatar from '../../assests/avatar.jpg';

import { Pagination, Navigation } from 'swiper/modules';
import UploadImageGroup2 from '../../components/Reusable/UI/InputGroup/UploadImageGroup2';
import { managerAccountsService } from '../../services/managerAccountsService';
import { managerService } from '../../services/manager';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { TaxiAlert } from '@mui/icons-material';
import { categoryService } from '../../services/categoryService';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { Fab, Skeleton } from '@mui/material';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';

// import Img from "../../componen

export default function ManagerDetailsData(props) {
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const [showError, setShowError] = useState(false);
  const [contarct, setContract] = useState([]);
  const [profilePicture, setProfilePicture] = useState([]);
  const [TaxCard, setTaxCard] = useState([]);
  const [governmentDocuments, setGovernmentDocuments] = useState([]);
  const [data1, setData1] = useState({});
  const [media, setMedia] = useState({});
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [data, setData] = useState({
    firstName: null,
    lastName: null,
    phone: null,
    address: null,
    nationalId: null,
    birthDate: null,
    password: null,
    email: null,
    categories: null,
    jobDescription: null,
    createdAt: null,
  });
  const [dataErr, setDataErr] = useState({
    firstName: true,
    lastName: true,
    phone: true,
    address: true,
    nationalId: true,
    birthDate: true,
    password: 'Provide a Password',
    jobDescription: true,
    categories: true,
    email: true,
  });

  let getManagerData = async () => {
    let response =
      localStorage.getItem('side') === 'Top Manager'
        ? await managerAccountsService.getSingleManagerData(props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager', id)
        : await managerService.getManagerData(props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager');
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    let arr = [];
    if (Valid === true) {
      setData1(props.type === 'Marketing Manager' ? response.data.marketingManager : response.data.categoryManager);

      setData({
        createdAt: props.type === 'Marketing Manager' ? response.data.marketingManager.createdAt : response.data.categoryManager.createdAt,
        firstName: props.type === 'Marketing Manager' ? response.data.marketingManager.firstName : response.data.categoryManager.firstName,
        lastName: props.type === 'Marketing Manager' ? response.data.marketingManager.lastName : response.data.categoryManager.lastName,
        phone: props.type === 'Marketing Manager' ? response.data.marketingManager.phone : response.data.categoryManager.phone,
        address: props.type === 'Marketing Manager' ? response.data.marketingManager.address : response.data.categoryManager.address,
        nationalId: props.type === 'Marketing Manager' ? response.data.marketingManager.nationalId : response.data.categoryManager.nationalId,
        birthDate: props.type === 'Marketing Manager' ? response.data.marketingManager.birthDate : response.data.categoryManager.birthDate,
        password: null,
        email: props.type === 'Marketing Manager' ? response.data.marketingManager.email : response.data.categoryManager.email,
        jobDescription:
          props.type === 'Marketing Manager' ? response.data.marketingManager.jobDescription : response.data.categoryManager.jobDescription,
      });

      setProfilePicture(props.type === 'Marketing Manager' ? response.data.marketingManager.pic : response.data.categoryManager.pic);
      if (props.type === 'Category Manager') arr = response.data.categoryManager.categories.map((item) => item.item.name_en);

      setSelectedCategories(arr);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };
  let getManagerMedia = async () => {
    let response =
      localStorage.getItem('side') === 'Top Manager'
        ? await managerAccountsService.getSingleManagerMedia(props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager', id)
        : await managerService.getManagerMedia(props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager');
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setMedia(props.type === 'Marketing Manager' ? response.data.marketingManager : response.data.categoryManager);
      setContract(props.type === 'Marketing Manager' ? response.data.marketingManager.contract : response.data.categoryManager.contract);
      setTaxCard(props.type === 'Marketing Manager' ? response.data.marketingManager.cv : response.data.categoryManager.cv);
      setGovernmentDocuments(
        props.type !== 'Marketing Manager' ? response.data.categoryManager.more : response.data.marketingManager.governmentRegistrationNumber,
      );
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  useEffect(() => {
    if (id) {
      getManagerData();
      getManagerMedia();
      mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
    }
  }, [id]);

  let handleSubmit = async () => {
    setShowError(true);
    if (
      dataErr.address &&
      dataErr.birthDate &&
      dataErr.firstName &&
      dataErr.jobDescription &&
      dataErr.lastName &&
      dataErr.nationalId &&
      dataErr.phone &&
      ((dataErr.categories && props.type === 'Category Manager') || props.type === 'Marketing Manager') &&
      (!data.password || !dataErr.password)
    ) {
      let fd = new FormData();

      fd.append('firstName', data.firstName);
      fd.append('lastName', data.lastName);
      data.phone && fd.append('phone', data.phone);
      data.password && fd.append('password', data.password);
      fd.append('address', data.address);
      fd.append('jobDescription', data.jobDescription);
      let categoriesSelected = [];
      if (props.type === 'Category Manager') {
        categories.map((item, index) => {
          if (selectedCategories.includes(item.name_en)) categoriesSelected.push({ item: item._id });
        });
        localStorage.getItem('Top Manager') && fd.append('categories[]', JSON.stringify(categoriesSelected));
      }
      if (contarct)
        for (let i = 0; i < contarct.length; i++)
          contarct[i].name
            ? fd.append('contract', contarct[i], contarct[i].name)
            : localStorage.getItem('side') === 'Top Manager' && fd.append('contractExists', contarct[i]);
      if (TaxCard)
        for (let i = 0; i < TaxCard.length; i++)
          TaxCard[i].name
            ? fd.append('cv', TaxCard[i], TaxCard[i].name)
            : localStorage.getItem('side') === 'Top Manager' && fd.append('cvExists', TaxCard[i]);

      if (governmentDocuments) {
        for (let i = 0; i < governmentDocuments.length; i++) {
          if (props.type === 'Category Manager')
            governmentDocuments[i].name
              ? fd.append('more', governmentDocuments[i], governmentDocuments[i].name)
              : localStorage.getItem('side') === 'Top Manager' && fd.append('moreExists', TaxCard[i]);
          else
            governmentDocuments[i].name
              ? fd.append('governmentRegistrationNumber', governmentDocuments[i], governmentDocuments[i].name)
              : localStorage.getItem('side') === 'Top Manager' && fd.append('governmentRegistrationNumberExists', governmentDocuments[i]);
        }
      }
      profilePicture && profilePicture.name && fd.append('pic', profilePicture, profilePicture.name);
      let response =
        localStorage.getItem('side') === 'Top Manager'
          ? await managerAccountsService.updateManager(props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager', fd, id)
          : await managerService.updateManager(props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager', fd);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          history(`/account/${props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager'}/${id}`);
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
      }
    }
    setLoadingButton(false);
  };
  let handleChange = (e) => {
    //console.log(e.target.value);
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    e.target.value.replace(/\s/g, '').length === 0
      ? setDataErr((prev) => ({ ...prev, [e.target.name]: false }))
      : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === 'password') {
      if (!e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
        setDataErr((prev) => ({
          ...prev,
          [e.target.name]: `The Password must be eight characters or longer and must contain atleast:
        [ 1 lower case character
       ,  1 upper case character
       ,  1 numeric 
       ,  1 special character ]`,
        }));
        // setValidationPasswordErr(true);
      } else {
        setDataErr((prev) => ({
          ...prev,
          [e.target.name]: null,
        }));
      }
    }
  };

  const [option, setOption] = useState('Data');
  return (
    <div className={`w-100 ${classes['managers']}`}>
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={`${props.type} Data Edited Successfully`} />
        </Modal>
      )}
      {serverError && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}
      <CompanyNav />

      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle text={`${props.type} Profile`} />
        </div>
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <div className="row mx-0 px-0">
              <div className={`col-xl-3 px-0  mb-xl-0 mb-4 ${classes['manager-edit-left']}`}>
                <div className="row px-0 mx-0">
                  <div className="col-xl-12 col-lg-6 mx-auto">
                    <Skeleton variant="rounded" sx={{ fontSize: '20rem', height: '200px', width: '100%' }} />
                  </div>
                </div>
                <p className="text-center position-relative">Change profile picture</p>

                <div className={`${classes.options}`}>
                  <div className="row px-0 mx-0">
                    <div className="w-auto d-inline">
                      <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100px' }} />
                    </div>
                    <div className="w-auto d-inline">
                      <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100px' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-xl-9 px-0 `}>
                <div className="row px-0 ps-xl-4 mx-0">
                  {option === 'Data' && (
                    <div className={`col-12 ms-auto  ${classes['manager-edit-right']}`}>
                      <h1>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />
                      </h1>
                      <div className="row mx-0 px-0">
                        <div className="col-lg-6 px-0 pe-lg-2">
                          <p>
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '200px' }} />
                          </p>
                        </div>
                        <div className="col-lg-6  px-0 ps-lg-2">
                          <p>
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '200px' }} />
                          </p>
                        </div>

                        {[0, 1, 2, 3, 4].map((item, index) => (
                          <div key={index} className="row mx-0 px-0">
                            <div className="col-lg-6 px-0 mx-0">
                              <div className="row  px-0 mx-0">
                                <div className="col-12 px-0 pe-lg-2">
                                  <div className={classes.input}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '150px' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '90%' }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 px-0 mx-0">
                              <div className="row  px-0 mx-0">
                                <div className="col-12 px-0 ps-lg-2">
                                  <div className={classes.input}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '150px' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '90%' }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`mt-4 row mx-0 px-0 ${classes.input}  justify-content-end mb-pb-0`}>
              {/* <div className="w-auto  ms-auto"> */}
              <div className="w-auto d-md-inline mb-md-0 mb-3 px-0 pe-2 mb-pb-0">
                <div className={`  mb-pb-0`}>
                  <Skeleton variant="text" sx={{ fontSize: '3rem', width: '300px' }} />
                </div>
              </div>
              <div className="w-auto d-inline  px-0 ps-md-2 pe-md-0 pe-2 mb-pb-0">
                <div className={`  mb-pb-0`}>
                  <Skeleton variant="text" sx={{ fontSize: '3rem', width: '300px' }} />
                </div>
              </div>
              {/* </div> */}
            </div>
          </section>
        ) : (
          data.firstName && (
            <section className={`${classes['main-section']}`}>
              <div className="row mx-0 px-0">
                <div className={`col-xl-3 px-0  mb-xl-0 mb-4 ${classes['manager-edit-left']}`}>
                  <div className="row px-0 mx-0">
                    <div className="col-xl-12 col-lg-6 mx-auto">
                      <Img
                        src={
                          profilePicture
                            ? profilePicture.name
                              ? URL.createObjectURL(profilePicture)
                              : `${process.env.REACT_APP_IMAGE_BASE_URL}/${
                                  props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager'
                                }/${profilePicture}`
                            : avatar
                        }
                      />
                    </div>
                  </div>
                  <p className="text-center cursor position-relative">
                    <div className="position-absolute w-100 ">
                      <input
                        className="cursor"
                        type="file"
                        onChange={(e) => {
                          setProfilePicture(e.target.files[0]);
                        }}
                        accept={`"image/*"`}
                      />
                    </div>
                    Change profile picture
                  </p>

                  <div className={`${classes.options}`}>
                    <SelectOption
                      className={` px-0 mx-0  d-flex flex-column  w-auto  `}
                      onSelect={(val) => {
                        setOption(val);
                      }}
                      default={option}
                      options={['Data', 'Media']}
                    />
                  </div>
                </div>
                <div className={`col-xl-9 px-0 `}>
                  <div className="row px-0 ps-xl-4 mx-0">
                    {option === 'Data' && (
                      <div className={`col-12 ms-auto  ${classes['manager-edit-right']}`}>
                        <h1>Data</h1>
                        <div className="row mx-0 px-0">
                          <div className="col-lg-6 px-0 pe-lg-2">
                            <p>Code:{data1.uuid}</p>
                          </div>
                          <div className="col-lg-6  px-0 ps-lg-2">
                            <p>Joined At:{data.createdAt.slice(0, 10)}</p>
                          </div>

                          <div className="row mx-0 px-0">
                            <div className="col-lg-6 px-0 mx-0">
                              <div className="row  px-0 mx-0">
                                <div className="col-12 px-0 pe-lg-2">
                                  <div className={classes.input}>
                                    <InputGroup
                                      default={data.firstName}
                                      onChange={(e) => handleChange(e)}
                                      error={showError && !dataErr.firstName}
                                      errorMsg="provide a First Name"
                                      name="firstName"
                                      value="First Name"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 px-0 mx-0">
                              <div className="row  px-0 mx-0">
                                <div className="col-12 px-0 ps-lg-2">
                                  <div className={classes.input}>
                                    <InputGroup
                                      default={data.lastName}
                                      onChange={(e) => handleChange(e)}
                                      error={showError && !dataErr.lastName}
                                      errorMsg="provide a Last Name"
                                      name="lastName"
                                      value="Last Name"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mx-0 px-0">
                            <div className="col-lg-6 px-0 mx-0">
                              <div className="row  px-0 mx-0">
                                <div className="col-12 px-0 pe-lg-2">
                                  <div className={classes.input}>
                                    <InputGroup
                                      disable={localStorage.getItem('side') === 'Top Manager' ? false : true}
                                      default={data.phone}
                                      onChange={(e) => handleChange(e)}
                                      error={showError && !dataErr.phone}
                                      errorMsg="provide a Valid Phone Number"
                                      name="phone"
                                      value="Phone Number"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 px-0 mx-0">
                              <div className="row  px-0 mx-0">
                                <div className="col-12 px-0 ps-lg-2">
                                  <div className={classes.input}>
                                    <InputGroup
                                      disable={true}
                                      default={data.email}
                                      onChange={(e) => handleChange(e)}
                                      error={showError && !dataErr.email}
                                      errorMsg="provide an Email"
                                      name="email"
                                      value="Email Address"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mx-0 px-0">
                            <div className="col-lg-6 px-0 mx-0">
                              <div className="row  px-0 mx-0">
                                <div className="col-12 px-0 pe-lg-2">
                                  <div className={classes.input}>
                                    <InputGroup
                                      disable={true}
                                      default={data.nationalId}
                                      onChange={(e) => handleChange(e)}
                                      error={showError && !dataErr.nationalId}
                                      errorMsg="provide a National ID"
                                      name="nationalId"
                                      value="National ID"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 px-0 mx-0">
                              <div className="row  px-0 mx-0">
                                <div className="col-12 px-0 ps-lg-2">
                                  <div className={classes.input}>
                                    <InputGroup
                                      type="date"
                                      disable={true}
                                      default={data.birthDate}
                                      onChange={(e) => handleChange(e)}
                                      error={showError && !dataErr.birthDate}
                                      errorMsg="provide a Birth Date"
                                      name="birthDate"
                                      value="Birth Date"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {localStorage.getItem('side') === 'Top Manager' && (
                            <div className="row mx-0 px-0">
                              <div className="col-12 px-0 mx-0">
                                <div className={classes.input}>
                                  <InputGroup
                                    default={data.Password}
                                    onChange={(e) => handleChange(e)}
                                    error={showError && dataErr.password && data.password}
                                    errorMsg={dataErr.password}
                                    name="password"
                                    type="password"
                                    value="Password"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {props.type === 'Category Manager' && (
                            <div className={`${classes.input} px-0 `}>
                              {localStorage.getItem('side') === 'Top Manager' ? (
                                <InputGroup
                                  type="dropdown"
                                  default={'No Selection'}
                                  name="category"
                                  options={['No Selection', ...categoriesName]}
                                  onChange={(e) => {
                                    if (!selectedCategories.includes(e.target.value) && e.target.value !== 'No Selection') {
                                      categories.map((item) => {
                                        if (item.name_en === e.target.value) {
                                          setSelectedCategories((prev) => [...prev, e.target.value]);
                                          setDataErr((prev) => ({
                                            ...prev,
                                            categories: true,
                                          }));
                                        }
                                      });
                                    }
                                  }}
                                  error={showError && selectedCategories.length === 0}
                                  errorMsg="Select a  Category"
                                  value={` Category`}
                                />
                              ) : (
                                <p style={{ color: '#134074' }}>Catgeories</p>
                              )}

                              <div className="row px-0 mx-0 ">
                                {selectedCategories.map((item, index) => (
                                  <div className="w-auto d-inline mt-2 pe-lg-2 position-relative">
                                    {localStorage.getItem('side') === 'Top Manager' && (
                                      <div
                                        onClick={() => {
                                          let arr = [];
                                          arr = selectedCategories.filter((item1, index) => {
                                            //console.log(item1);

                                            if (item1.toLowerCase() !== item.toLowerCase()) return item1;
                                          });
                                          if (arr.length > 0) {
                                            setSelectedCategories(arr);
                                          } else {
                                            setSelectedCategories([]);
                                            setDataErr((prev) => ({
                                              ...prev,
                                              categories: false,
                                            }));
                                          }
                                        }}
                                        style={{
                                          right: '10px',
                                          zIndex: 2,
                                          // top: "10px",
                                        }}
                                        className="text-end  cursor  h-100 align-items-center d-flex position-absolute"
                                      >
                                        <Close />
                                      </div>
                                    )}
                                    <Fab style={{ zIndex: 1 }} variant="extended" className=" pe-5">
                                      {item}
                                    </Fab>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div className="row mx-0 px-0">
                            <div className="col-12 px-0 mx-0">
                              <div className={classes.input}>
                                <InputGroup
                                  default={data.address}
                                  onChange={(e) => handleChange(e)}
                                  error={showError && !dataErr.address}
                                  errorMsg="provide an Address"
                                  name="address"
                                  value="Address"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mx-0 px-0">
                            <div className="col-12 px-0 mx-0">
                              <div className={classes.input}>
                                <TextAreaGroup
                                  disable={localStorage.getItem('side') === 'Top Manager' ? false : true}
                                  default={data.jobDescription}
                                  onChange={(e) => handleChange(e)}
                                  error={showError && !dataErr.jobDescription}
                                  errorMsg="provide a Job Description"
                                  name="jobDescription"
                                  value="Job Description"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {option === 'Media' && (
                      <>
                        <div className={classes.input}>
                          <UploadImageGroup2
                            imageType={'manager'}
                            formType={props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager'}
                            default={contarct}
                            noDelete={localStorage.getItem('side') === 'Top Manager' ? false : true}
                            onChange={(e) => {
                              setContract((prev) => [...prev, e]);
                            }}
                            onRemove={(data) => setContract(data)}
                            title="Contract"
                            type="Both"
                          />
                        </div>
                        <div className={classes.input}>
                          <UploadImageGroup2
                            default={TaxCard}
                            imageType={'manager'}
                            noDelete={localStorage.getItem('side') === 'Top Manager' ? false : true}
                            formType={props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager'}
                            onChange={(e) => {
                              setTaxCard((prev) => [...prev, e]);
                            }}
                            onRemove={(data) => setTaxCard(data)}
                            title={
                              // props.type === "Marketing Manager"
                              // ? "Tax Card"
                              // :
                              'CV'
                            }
                            type="Both"
                          />
                        </div>
                        <div className={classes.input}>
                          <UploadImageGroup2
                            imageType={'manager'}
                            noDelete={localStorage.getItem('side') === 'Top Manager' ? false : true}
                            formType={props.type === 'Marketing Manager' ? 'marketing-manager' : 'category-manager'}
                            default={governmentDocuments}
                            onChange={(e) => setGovernmentDocuments((prev) => [...prev, e])}
                            onRemove={(data) => setGovernmentDocuments(data)}
                            title={'More'}
                            type="Both"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={`mt-4 row mx-0 px-0 ${classes.input}  justify-content-end mb-pb-0`}>
                <div className="w-lg-auto d-lg-inline mb-lg-0 mb-3 px-0 pe-lg-2 mb-pb-0">
                  <div className={`mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setLoading(true);
                        getManagerData();
                        getManagerMedia();
                      }}
                      className={'w-lg-auto d-inline px-5   '}
                      type="reverse"
                      text={'Canel Edits'}
                    />
                  </div>
                </div>
                <div className="w-lg-auto d-inline  px-0 ps-lg-2  mb-pb-0 pt-lg-0 pt-3">
                  <div className={`  mb-pb-0`}>
                    {loadingButton ? (
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setLoadingButton(true);
                          handleSubmit();
                        }}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-lg-auto d-inline px-5  ms-auto text-center'}
                        type="normal"
                        text={'Save Changes'}
                      />
                    )}
                  </div>
                </div>
                {/* </div> */}
              </div>
            </section>
          )
        )}
      </section>
    </div>
  );
}
