import React from "react";

export default function Svg401() {
  return (
    <svg
      // width="350"
      height="500"
      viewBox="0 0 350 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M350 267.68H0V267.855H350V267.68Z" fill="#EBEBEB" />
      <path
        d="M78.1625 273.553H54.9785V273.728H78.1625V273.553Z"
        fill="#EBEBEB"
      />
      <path d="M114.449 278.6H93.3301V278.775H114.449V278.6Z" fill="#EBEBEB" />
      <path
        d="M66.5699 280.049H39.7949V280.224H66.5699V280.049Z"
        fill="#EBEBEB"
      />
      <path
        d="M314.931 279.671H303.801V279.846H314.931V279.671Z"
        fill="#EBEBEB"
      />
      <path
        d="M301.553 279.671H274.029V279.846H301.553V279.671Z"
        fill="#EBEBEB"
      />
      <path d="M196.231 273.462H175V273.637H196.231V273.462Z" fill="#EBEBEB" />
      <path d="M249.74 273.462H199.27V273.637H249.74V273.462Z" fill="#EBEBEB" />
      <path
        d="M165.899 236.46H30.7361C29.6772 236.458 28.6624 236.036 27.9143 235.287C27.1662 234.537 26.7461 233.522 26.7461 232.463V42.462C26.7553 41.4092 27.1795 40.4026 27.9266 39.6608C28.6737 38.9189 29.6833 38.5018 30.7361 38.5H165.899C166.959 38.5 167.976 38.9211 168.725 39.6707C169.475 40.4203 169.896 41.4369 169.896 42.497V232.463C169.896 233.523 169.475 234.54 168.725 235.289C167.976 236.039 166.959 236.46 165.899 236.46ZM30.7361 38.64C29.7237 38.6419 28.7533 39.0453 28.0381 39.7619C27.3228 40.4785 26.9211 41.4496 26.9211 42.462V232.463C26.9211 233.475 27.3228 234.447 28.0381 235.163C28.7533 235.88 29.7237 236.283 30.7361 236.285H165.899C166.912 236.283 167.883 235.88 168.6 235.164C169.316 234.447 169.719 233.476 169.721 232.463V42.462C169.719 41.4489 169.316 40.4778 168.6 39.7615C167.883 39.0451 166.912 38.6419 165.899 38.64H30.7361Z"
        fill="#EBEBEB"
      />
      <path
        d="M317.317 236.46H182.147C181.088 236.458 180.072 236.036 179.323 235.287C178.574 234.538 178.152 233.522 178.15 232.463V42.462C178.161 41.4086 178.587 40.402 179.335 39.6603C180.084 38.9187 181.094 38.5018 182.147 38.5H317.317C318.369 38.5036 319.377 38.9216 320.122 39.6632C320.868 40.4048 321.291 41.4104 321.3 42.462V232.463C321.3 233.521 320.881 234.535 320.135 235.284C319.388 236.034 318.375 236.456 317.317 236.46ZM182.147 38.64C181.134 38.6419 180.163 39.0451 179.447 39.7615C178.731 40.4778 178.327 41.4489 178.325 42.462V232.463C178.327 233.476 178.731 234.447 179.447 235.164C180.163 235.88 181.134 236.283 182.147 236.285H317.317C318.33 236.283 319.302 235.88 320.018 235.164C320.734 234.447 321.138 233.476 321.139 232.463V42.462C321.138 41.4489 320.734 40.4778 320.018 39.7615C319.302 39.0451 318.33 38.6419 317.317 38.64H182.147Z"
        fill="#EBEBEB"
      />
      <path
        d="M300.494 165.276L289.768 154.55L190.936 253.382L201.662 264.108L300.494 165.276Z"
        fill="#EBEBEB"
      />
      <path
        d="M253.351 212.415L242.627 201.684L228.004 216.307L237.839 231.994L253.351 212.415Z"
        fill="#E0E0E0"
      />
      <path
        d="M201.661 264.108L205.234 267.682L304.066 168.85L300.492 165.276L201.661 264.108Z"
        fill="#E0E0E0"
      />
      <path
        d="M201.666 158.12L190.939 168.846L289.771 267.677L300.497 256.951L201.666 158.12Z"
        fill="#EBEBEB"
      />
      <path
        d="M300.497 256.953L304.07 253.379L205.239 154.548L201.665 158.121L300.497 256.953Z"
        fill="#E0E0E0"
      />
      <path
        d="M123.34 187.984L114.99 179.634L38.0712 256.553L46.4214 264.903L123.34 187.984Z"
        fill="#EBEBEB"
      />
      <path
        d="M86.6459 224.665L78.2949 216.321L66.9199 227.703L74.5709 239.904L86.6459 224.665Z"
        fill="#E0E0E0"
      />
      <path
        d="M46.4155 264.9L49.1973 267.682L126.116 190.763L123.335 187.981L46.4155 264.9Z"
        fill="#E0E0E0"
      />
      <path
        d="M46.4264 182.414L38.0762 190.765L114.995 267.684L123.345 259.333L46.4264 182.414Z"
        fill="#EBEBEB"
      />
      <path
        d="M123.332 259.329L126.113 256.547L49.1942 179.628L46.4124 182.41L123.332 259.329Z"
        fill="#E0E0E0"
      />
      <path
        d="M223.21 192.577H210.883V267.673H223.21V192.577Z"
        fill="#F5F5F5"
      />
      <path
        d="M223.21 223.783H210.883V232.792L223.21 230.132V223.783Z"
        fill="#EBEBEB"
      />
      <path
        d="M223.202 267.68H227.311V192.584H223.202V267.68Z"
        fill="#EBEBEB"
      />
      <path
        d="M94.0809 192.577H81.7539V267.673H94.0809V192.577Z"
        fill="#F5F5F5"
      />
      <path
        d="M94.0739 223.783H81.7539V232.792L94.0739 230.132V223.783Z"
        fill="#EBEBEB"
      />
      <path
        d="M94.0726 267.68H98.1816L98.1816 192.584H94.0726L94.0726 267.68Z"
        fill="#EBEBEB"
      />
      <path
        d="M229.677 203.588H72.3516V226.702H229.677V203.588Z"
        fill="#F5F5F5"
      />
      <path
        d="M229.676 226.695H231.986V203.581H229.676V226.695Z"
        fill="#EBEBEB"
      />
      <path
        d="M169.911 180.019H139.153L117.404 201.768V232.526L139.153 254.275H169.911L191.66 232.526V201.768L169.911 180.019Z"
        fill="#FAFAFA"
      />
      <path
        d="M168.504 183.414H140.56L120.799 203.175V231.119L140.56 250.88H168.504L188.265 231.119V203.175L168.504 183.414Z"
        fill="#E0E0E0"
      />
      <path
        d="M127.533 220.374L131.467 220.024C131.5 220.751 131.676 221.465 131.985 222.124C132.147 222.471 132.406 222.764 132.729 222.969C133.053 223.174 133.429 223.281 133.812 223.279C134.076 223.3 134.34 223.258 134.584 223.155C134.827 223.053 135.043 222.894 135.212 222.691C135.538 222.298 135.71 221.801 135.695 221.291C135.699 220.808 135.536 220.338 135.233 219.961C134.643 219.413 133.919 219.031 133.133 218.855C131.644 218.478 130.296 217.675 129.255 216.545C128.441 215.495 128.027 214.19 128.086 212.863C128.077 211.897 128.28 210.941 128.681 210.063C129.063 209.215 129.689 208.501 130.48 208.012C131.485 207.46 132.625 207.2 133.77 207.263C134.48 207.214 135.193 207.309 135.865 207.543C136.538 207.776 137.156 208.143 137.683 208.621C138.579 209.524 139.083 210.966 139.286 212.94L135.387 213.269C135.35 212.595 135.125 211.945 134.736 211.393C134.562 211.196 134.346 211.041 134.103 210.941C133.861 210.84 133.598 210.796 133.336 210.812C133.134 210.798 132.932 210.83 132.744 210.906C132.557 210.981 132.388 211.097 132.251 211.246C132.006 211.543 131.877 211.918 131.887 212.303C131.884 212.604 131.991 212.895 132.188 213.122C132.581 213.48 133.066 213.723 133.588 213.822C134.962 214.178 136.273 214.745 137.473 215.502C138.225 216.031 138.817 216.758 139.181 217.602C139.545 218.49 139.726 219.442 139.713 220.402C139.73 221.564 139.491 222.716 139.013 223.776C138.596 224.74 137.901 225.559 137.018 226.128C136.042 226.693 134.925 226.97 133.798 226.926C131.526 226.926 129.951 226.301 129.073 225.05C128.133 223.666 127.6 222.046 127.533 220.374Z"
        fill="white"
      />
      <path
        d="M140.818 207.62H153.418V212.331H149.218V226.674H145.081V212.331H140.881L140.818 207.62Z"
        fill="white"
      />
      <path
        d="M154.098 217.161C154.098 214.053 154.707 211.631 155.925 209.895C156.514 209.094 157.283 208.441 158.17 207.99C159.056 207.539 160.036 207.302 161.031 207.298C162.026 207.294 163.008 207.523 163.899 207.967C164.789 208.41 165.563 209.056 166.159 209.853C167.386 211.556 168 213.939 168 217C168.058 218.859 167.79 220.714 167.209 222.481C166.772 223.78 165.982 224.932 164.927 225.806C163.863 226.622 162.549 227.042 161.21 226.996C159.885 227.049 158.577 226.689 157.465 225.967C156.365 225.14 155.528 224.012 155.057 222.719C154.379 220.947 154.053 219.059 154.098 217.161ZM158.235 217.161C158.235 219.088 158.487 220.47 158.991 221.305C159.187 221.681 159.482 221.995 159.844 222.215C160.206 222.435 160.622 222.551 161.045 222.551C161.469 222.551 161.884 222.435 162.247 222.215C162.609 221.995 162.904 221.681 163.1 221.305C163.59 220.479 163.8 219.002 163.8 216.874C163.893 215.519 163.633 214.164 163.044 212.94C162.841 212.561 162.538 212.245 162.168 212.026C161.797 211.808 161.374 211.695 160.944 211.701C160.526 211.706 160.117 211.826 159.762 212.049C159.408 212.271 159.122 212.587 158.935 212.961C158.468 213.838 158.235 215.248 158.235 217.189V217.161Z"
        fill="white"
      />
      <path
        d="M170.176 207.62H177.05C177.694 207.577 178.338 207.694 178.925 207.96C179.513 208.227 180.025 208.634 180.417 209.146C181.237 210.434 181.627 211.948 181.53 213.472C181.625 215.067 181.197 216.649 180.312 217.98C179.872 218.533 179.303 218.97 178.655 219.253C178.008 219.536 177.3 219.656 176.595 219.604H174.299V226.674H170.148L170.176 207.62ZM174.299 215.747H175.314C175.621 215.781 175.932 215.745 176.222 215.641C176.513 215.538 176.777 215.371 176.994 215.152C177.33 214.721 177.501 214.185 177.477 213.64C177.511 213.1 177.379 212.563 177.099 212.1C176.911 211.872 176.667 211.695 176.392 211.586C176.117 211.477 175.818 211.44 175.524 211.477H174.299V215.747Z"
        fill="white"
      />
      <path
        d="M287.944 71.9248C287.213 71.928 286.485 72.0149 285.774 72.1838C284.85 70.0838 282.799 68.7748 280.034 68.7748C277.917 68.8266 275.861 69.4957 274.119 70.6998C272.943 66.7518 269.422 64.1128 264.508 64.1128C257.508 64.1128 250.193 69.4608 247.547 76.2928C246.9 76.1411 246.238 76.0659 245.573 76.0688H244.915C238.888 76.4748 239.315 85.5748 245.384 85.5748H288.399C289.144 85.5773 289.878 85.4043 290.544 85.0699C291.209 84.7354 291.786 84.2489 292.228 83.6498C292.971 82.6419 293.536 81.5152 293.901 80.3178C295.231 75.6838 292.599 71.9248 287.944 71.9248Z"
        fill="#F0F0F0"
      />
      <path
        d="M119.952 31.591C119.014 25.375 114.058 21.042 106.813 21.042C100.877 21.042 94.7588 23.947 90.2018 28.42C88.8727 27.2675 87.1583 26.6577 85.3998 26.712C80.3738 26.712 75.1028 30.786 73.6188 35.812C73.3986 36.5538 73.274 37.3206 73.2478 38.094L73.1428 38.164C68.9848 40.964 71.0428 47.537 76.1108 47.537H120.4C121.245 47.5438 122.078 47.3393 122.824 46.9419C123.57 46.5446 124.204 45.9671 124.67 45.262C125.362 44.2061 125.897 43.0555 126.259 41.846C127.806 36.498 125.027 32.095 119.952 31.591Z"
        fill="#F0F0F0"
      />
      <path
        d="M172.816 101.01C171.528 92.4629 164.717 86.5059 154.756 86.5059C146.587 86.5059 138.18 90.5029 131.922 96.6489C130.1 95.0601 127.744 94.2198 125.328 94.2969C118.426 94.2969 111.167 99.8969 109.13 106.806C108.827 107.825 108.656 108.879 108.619 109.942L108.479 110.033C102.746 113.932 105.588 122.92 112.518 122.92H170.8C173.164 122.937 175.47 122.193 177.378 120.799C179.286 119.405 180.696 117.434 181.398 115.178V115.094C183.624 107.751 179.795 101.696 172.816 101.01Z"
        fill="#F5F5F5"
      />
      <path
        d="M175 299.292C249.958 299.292 310.723 295.744 310.723 291.368C310.723 286.992 249.958 283.444 175 283.444C100.043 283.444 39.2773 286.992 39.2773 291.368C39.2773 295.744 100.043 299.292 175 299.292Z"
        fill="#F5F5F5"
      />
      <path
        d="M90.1185 90.2438H54.9785V74.3818L90.1185 32.6128H106.919V75.3128H115.634V90.2788H106.933V103.257H90.1325L90.1185 90.2438ZM90.1185 75.2778V53.4308L71.5545 75.2778H90.1185Z"
        fill="#263238"
      />
      <path
        d="M255.437 32.6128V103.222H235.9V56.9518C233.05 59.1655 230.004 61.1148 226.8 62.7758C223.222 64.502 219.506 65.9279 215.691 67.0388V51.2258C222.257 49.1025 227.356 46.5591 230.986 43.5958C234.621 40.6255 237.536 36.8705 239.512 32.6128H255.437Z"
        fill="#263238"
      />
      <path
        d="M218.274 121.233C217.966 121.786 217.728 122.185 217.455 122.633C217.182 123.081 216.909 123.501 216.636 123.935C216.083 124.789 215.495 125.615 214.9 126.441C213.699 128.097 212.412 129.688 211.043 131.208C209.649 132.767 208.149 134.229 206.556 135.583C204.899 137.018 203.1 138.28 201.187 139.349C200.584 139.7 199.955 140.002 199.304 140.252C198.623 140.511 197.92 140.708 197.204 140.84C195.926 141.061 194.626 141.122 193.333 141.022C191.096 140.827 188.897 140.318 186.802 139.51C184.868 138.773 182.995 137.883 181.202 136.85C179.439 135.844 177.754 134.707 176.162 133.448C175.711 133.082 175.405 132.568 175.298 131.997C175.191 131.427 175.29 130.837 175.577 130.333C175.865 129.828 176.322 129.442 176.867 129.244C177.413 129.045 178.011 129.047 178.556 129.248L178.682 129.297C181.978 130.615 185.38 131.648 188.853 132.384C190.383 132.703 191.946 132.832 193.508 132.769C194.117 132.743 194.72 132.632 195.3 132.44C195.516 132.369 195.724 132.278 195.923 132.167C196.119 132.055 196.154 132.02 196.553 131.747C199.232 129.778 201.636 127.459 203.7 124.852C204.799 123.508 205.87 122.115 206.885 120.652C207.403 119.952 207.893 119.203 208.39 118.475L209.09 117.383L209.748 116.354L209.86 116.179C210.553 115.119 211.629 114.369 212.863 114.085C214.097 113.802 215.393 114.007 216.479 114.658C217.565 115.308 218.357 116.354 218.689 117.576C219.021 118.798 218.867 120.101 218.26 121.212L218.274 121.233Z"
        fill="#FF8B7B"
      />
      <path
        d="M179.718 129.822L174.349 126.266L172.074 133.406C173.15 133.973 174.359 134.24 175.573 134.178C176.788 134.117 177.963 133.729 178.976 133.056L179.718 129.822Z"
        fill="#FF8B7B"
      />
      <path
        d="M169.399 127.337L168.979 129.612C168.903 130.015 168.943 130.432 169.094 130.813C169.245 131.195 169.501 131.526 169.833 131.768L172.052 133.406L174.327 126.266L172.171 125.636C171.877 125.549 171.568 125.526 171.264 125.566C170.96 125.606 170.668 125.71 170.407 125.87C170.146 126.031 169.921 126.244 169.748 126.497C169.574 126.749 169.455 127.036 169.399 127.337Z"
        fill="#FF8B7B"
      />
      <path
        d="M218.441 109.858C212.974 110.719 207.444 117.138 204.119 123.123C206.302 126.929 209.611 129.966 213.59 131.817C213.59 131.817 220.072 125.685 222.69 121.149C225.308 116.613 223.586 109.039 218.441 109.858Z"
        fill="#134074"
      />
      <path
        opacity="0.2"
        d="M218.441 109.858C212.974 110.719 207.444 117.138 204.119 123.123C206.302 126.929 209.611 129.966 213.59 131.817C213.59 131.817 220.072 125.685 222.69 121.149C225.308 116.613 223.586 109.039 218.441 109.858Z"
        fill="black"
      />
      <path
        d="M204.329 121.751C203.823 122.325 203.426 122.987 203.16 123.704C204.525 125.804 207.766 131.404 213.814 132.594C214.402 132.266 214.852 131.736 215.081 131.103C215.081 131.103 208.123 127.673 204.329 121.751Z"
        fill="#263238"
      />
      <path
        d="M240.471 109.669C233.863 108.563 227.133 108.37 220.472 109.095C215.502 109.732 211.792 114.303 211.827 119.308C211.852 124.53 212.32 129.74 213.227 134.883C211.4 144.508 210.742 157.591 212.667 160.475L247.1 160.958C248.577 156.842 246.722 139.027 248.689 120.428C248.956 117.924 248.253 115.412 246.724 113.411C245.195 111.41 242.958 110.07 240.471 109.669Z"
        fill="#134074"
      />
      <path
        opacity="0.2"
        d="M227.499 160.517C225.532 145.026 227.499 119.119 227.499 118.853L228.199 118.909C228.199 119.168 226.246 145.005 228.199 160.426L227.499 160.517Z"
        fill="black"
      />
      <path
        d="M223.754 109.578C224.937 109.137 224.496 108.479 224.496 108.479C224.496 108.479 219.12 108.479 215.396 109.48C214.829 110.082 215.438 110.88 215.438 110.88C215.438 110.88 220.233 110.894 223.754 109.578Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M225.953 108.5C225.953 108.5 223.734 112.602 226.702 116.333C224.35 116.333 222.831 120.344 222.831 120.344C222.831 120.344 218.239 113.96 224.112 108.857C224.441 108.346 225.953 108.5 225.953 108.5Z"
        fill="black"
      />
      <path
        d="M223.581 110.488C223.007 112.994 228.054 117.719 228.054 117.719C228.054 117.719 236.979 113.407 237.546 110.936L223.581 110.488Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M243.726 125.153C242.774 126.987 244.041 134.953 247.716 138.32C247.758 135.03 247.856 131.537 248.066 127.946L243.726 125.153Z"
        fill="black"
      />
      <path
        d="M235.683 96.0747L226.471 102.123C227.563 104.797 228.074 107.583 224.924 108.871C224.52 109.033 224.173 109.313 223.929 109.674C223.685 110.035 223.555 110.461 223.555 110.897C223.555 111.333 223.685 111.759 223.929 112.12C224.173 112.481 224.52 112.761 224.924 112.924C225.852 113.261 226.823 113.466 227.808 113.533C230.769 113.82 233.754 113.338 236.474 112.133C236.761 111.99 237.013 111.786 237.212 111.535C237.412 111.285 237.555 110.994 237.631 110.683C237.707 110.372 237.714 110.048 237.652 109.734C237.59 109.42 237.461 109.123 237.272 108.864C234.857 105.448 235.151 99.5117 235.683 96.0747Z"
        fill="#FF8B7B"
      />
      <path
        opacity="0.2"
        d="M231.931 98.5391L226.479 102.116C226.785 102.848 227.019 103.607 227.179 104.384C229.356 104.258 232.198 102.536 232.254 100.737C232.308 99.9897 232.198 99.2395 231.931 98.5391Z"
        fill="black"
      />
      <path
        d="M225.455 84.539C227.8 84.49 228.437 87.857 227.555 88.998C229.333 89.383 230.194 91.462 229.655 92.498C229.116 93.534 227.058 94.703 225.224 94.241C222.081 93.457 221.542 90.846 222.424 89.656C220.744 88.27 221.598 84 225.455 84.539Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M225.455 84.539C227.8 84.49 228.437 87.857 227.555 88.998C229.333 89.383 230.194 91.462 229.655 92.498C229.116 93.534 227.058 94.703 225.224 94.241C222.081 93.457 221.542 90.846 222.424 89.656C220.744 88.27 221.598 84 225.455 84.539Z"
        fill="white"
      />
      <path
        d="M238.699 90.09C237.999 95.613 237.866 98.882 234.877 101.528C230.376 105.497 223.677 102.445 222.725 96.803C221.857 91.728 223.152 83.545 228.731 81.739C229.967 81.3457 231.282 81.271 232.555 81.5218C233.828 81.7727 235.016 82.3411 236.011 83.1741C237.005 84.0071 237.773 85.0779 238.243 86.2869C238.713 87.496 238.87 88.8042 238.699 90.09Z"
        fill="#FF8B7B"
      />
      <path
        d="M238.035 94.3109C235.69 94.3109 235.172 90.8949 236.068 89.7889C234.304 89.3339 233.52 87.2269 234.066 86.1909C234.612 85.1549 236.733 84.0909 238.553 84.6019C241.668 85.4979 242.123 88.1019 241.22 89.2849C242.9 90.7549 241.871 94.9689 238.035 94.3109Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M238.035 94.3109C235.69 94.3109 235.172 90.8949 236.068 89.7889C234.304 89.3339 233.52 87.2269 234.066 86.1909C234.612 85.1549 236.733 84.0909 238.553 84.6019C241.668 85.4979 242.123 88.1019 241.22 89.2849C242.9 90.7549 241.871 94.9689 238.035 94.3109Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M232.4 91.0001C232.682 90.9235 232.976 90.9033 233.265 90.9405C233.555 90.9777 233.834 91.0717 234.087 91.2171C233.516 91.2535 232.944 91.1798 232.4 91.0001Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M232.4 91.3779C232.667 91.3629 232.934 91.4065 233.182 91.5057C233.429 91.6048 233.653 91.7572 233.835 91.9519C233.322 91.8634 232.833 91.6678 232.4 91.3779Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M223.629 90.5731C223.565 90.535 223.494 90.5104 223.419 90.5007C223.345 90.4911 223.27 90.4965 223.198 90.5168C223.126 90.5371 223.059 90.5718 223.001 90.6187C222.942 90.6657 222.894 90.724 222.859 90.7901C223.135 90.8222 223.411 90.7442 223.629 90.5731Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M223.635 90.9998C223.635 90.9998 223.208 90.9368 222.984 91.5738C223.252 91.4491 223.478 91.2498 223.635 90.9998Z"
        fill="black"
      />
      <path
        d="M240.365 95.7181C239.723 96.8735 238.668 97.7436 237.411 98.1541C235.815 98.6161 235.178 97.0201 235.752 95.3541C236.277 93.8631 237.726 91.8191 239.252 92.0921C240.778 92.3651 241.205 94.2411 240.365 95.7181Z"
        fill="#FF8B7B"
      />
      <path
        d="M231.279 91.0001C231.279 91.4481 230.992 91.7981 230.691 91.7771C230.39 91.7561 230.18 91.3711 230.215 90.9161C230.25 90.4611 230.502 90.1181 230.803 90.1391C231.104 90.1601 231.314 90.5591 231.279 91.0001Z"
        fill="#263238"
      />
      <path
        d="M229.04 99.2251C228.466 99.3371 227.941 99.1131 227.871 98.7351C227.801 98.3571 228.221 97.9651 228.795 97.8531C229.369 97.7411 229.894 97.9651 229.964 98.3431C230.034 98.7211 229.6 99.1201 229.04 99.2251Z"
        fill="#263238"
      />
      <path
        d="M225.155 90.5167C225.155 90.9647 224.861 91.3147 224.567 91.2937C224.273 91.2727 224.049 90.8877 224.084 90.4327C224.119 89.9777 224.378 89.6347 224.672 89.6557C224.966 89.6767 225.183 90.0617 225.155 90.5167Z"
        fill="#263238"
      />
      <path
        d="M226.548 95.0251C223.272 95.2491 222.642 96.8451 223.216 97.5451C223.79 98.2451 225.19 97.5451 225.19 97.5451C225.19 97.5451 227.843 98.112 228.62 97.265C229.873 97.797 231.784 96.8031 231.245 96.0471C230.706 95.2911 229.355 94.8291 226.548 95.0251Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M226.548 95.0251C223.272 95.2491 222.642 96.8451 223.216 97.5451C223.79 98.2451 225.19 97.5451 225.19 97.5451C225.19 97.5451 227.843 98.112 228.62 97.265C229.873 97.797 231.784 96.8031 231.245 96.0471C230.706 95.2911 229.355 94.8291 226.548 95.0251Z"
        fill="white"
      />
      <path
        d="M226.645 91C226.016 92.3403 225.203 93.5866 224.23 94.703C224.524 94.9462 224.867 95.1228 225.236 95.2206C225.604 95.3185 225.99 95.3354 226.365 95.27L226.645 91Z"
        fill="#FF5652"
      />
      <path
        d="M232.267 88.6479C232.311 88.6425 232.354 88.6282 232.393 88.6059C232.422 88.5868 232.448 88.562 232.468 88.5329C232.488 88.5038 232.501 88.4711 232.509 88.4365C232.516 88.402 232.516 88.3664 232.509 88.3318C232.503 88.2972 232.489 88.2643 232.47 88.2349C232.246 87.8725 231.939 87.5694 231.573 87.3513C231.207 87.1331 230.795 87.0064 230.37 86.9819C230.299 86.9819 230.231 87.01 230.181 87.0598C230.132 87.1097 230.104 87.1774 230.104 87.2479C230.104 87.3185 230.132 87.3861 230.181 87.436C230.231 87.4859 230.299 87.5139 230.37 87.5139C230.704 87.5382 231.028 87.6425 231.315 87.8178C231.601 87.9932 231.841 88.2347 232.015 88.5219C232.041 88.5644 232.078 88.5988 232.122 88.621C232.167 88.6433 232.217 88.6526 232.267 88.6479Z"
        fill="#263238"
      />
      <path
        d="M223.145 88.1229C223.189 88.1221 223.232 88.1109 223.271 88.0902C223.31 88.0695 223.344 88.0399 223.369 88.0039C223.548 87.7235 223.791 87.4897 224.079 87.3217C224.366 87.1538 224.689 87.0565 225.021 87.0379C225.092 87.0379 225.159 87.0098 225.209 86.9599C225.259 86.9101 225.287 86.8424 225.287 86.7719C225.287 86.7013 225.259 86.6337 225.209 86.5838C225.159 86.5339 225.092 86.5059 225.021 86.5059C224.6 86.5227 224.189 86.641 223.824 86.8506C223.458 87.0602 223.148 87.355 222.921 87.7099C222.902 87.7386 222.888 87.771 222.882 87.8051C222.875 87.8392 222.875 87.8742 222.883 87.9082C222.89 87.9421 222.904 87.9743 222.924 88.0027C222.943 88.0312 222.969 88.0554 222.998 88.0739C223.04 88.1066 223.092 88.1239 223.145 88.1229Z"
        fill="#263238"
      />
      <path
        d="M232.182 217.56C233.75 192.304 233.4 166.39 230.677 160.748L212.652 160.475C212.652 160.475 214.899 202.699 215.599 219.52C216.334 237.02 217.902 282.52 217.902 282.52L228.332 282.646C228.332 282.646 234.45 237.097 232.182 217.56Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M232.96 178.955C226.807 185.675 229.684 204.281 232.365 214.529C233.1 201.712 233.296 188.951 232.96 178.955Z"
        fill="black"
      />
      <path
        d="M228.2 283.962L219.03 283.843C218.449 283.814 217.876 283.984 217.406 284.326C214.746 286.426 212.723 286.489 209.706 287.028C206.087 287.679 207.879 291.319 210.546 291.361C214.956 291.431 214.585 291.27 218.351 291.326L220.955 291.361L222.131 290.164L223.174 291.361H227.99C228.115 291.364 228.239 291.34 228.354 291.291C228.469 291.242 228.573 291.169 228.657 291.077C228.742 290.985 228.806 290.876 228.846 290.757C228.885 290.638 228.899 290.513 228.886 290.388L228.2 283.962Z"
        fill="#263238"
      />
      <path
        d="M228.899 284.9C228.738 284.9 216.866 284.522 216.866 284.522L216.383 280.259L229.55 280.553L228.899 284.9Z"
        fill="#134074"
      />
      <path
        d="M253.351 217.56C251.419 192.304 250.6 166.6 247.1 160.958L225.365 160.678C225.365 160.678 234.01 202.678 237.048 219.52C240.205 237.02 248.703 282.52 248.703 282.52L259.154 282.646C259.154 282.646 258.3 237.097 253.351 217.56Z"
        fill="#263238"
      />
      <path
        d="M259.651 283.962L250.474 283.843C249.895 283.814 249.325 283.985 248.857 284.326C246.197 286.426 247.415 286.489 244.398 287.028C240.779 287.679 242.564 291.319 245.231 291.361C249.641 291.431 249.277 291.27 253.036 291.326L257.124 291.361L257.663 290.164L258.363 291.361H259.441C259.564 291.361 259.686 291.334 259.798 291.284C259.911 291.234 260.012 291.16 260.094 291.069C260.177 290.977 260.239 290.869 260.277 290.752C260.315 290.635 260.328 290.511 260.316 290.388L259.651 283.962Z"
        fill="#263238"
      />
      <path
        d="M260.344 284.9C260.176 284.9 248.255 284.522 248.255 284.522L247.1 280.231L260.309 280.525L260.344 284.9Z"
        fill="#134074"
      />
      <path
        d="M226.317 108.003C226.317 108.003 223.734 112.602 226.702 116.333C224.35 116.333 222.81 118.881 222.81 118.881C222.81 118.881 219.681 112.581 224.308 108.381C224.63 107.905 226.317 108.003 226.317 108.003Z"
        fill="#134074"
      />
      <path
        d="M248.032 162.876C235.733 163.387 221.628 163.541 212.808 162.876C212.759 162.874 212.712 162.861 212.67 162.838C212.627 162.815 212.59 162.782 212.563 162.743C211.984 161.763 211.718 160.63 211.8 159.495C211.807 159.411 211.845 159.334 211.908 159.278C211.97 159.222 212.052 159.192 212.136 159.194C223.777 159.509 235.348 159.495 247.514 159.194C247.58 159.193 247.646 159.211 247.702 159.247C247.758 159.284 247.802 159.336 247.829 159.397C248.235 160.405 248.412 161.49 248.347 162.575C248.341 162.655 248.306 162.731 248.248 162.787C248.189 162.843 248.112 162.874 248.032 162.876Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M248.032 162.876C235.733 163.387 221.628 163.541 212.808 162.876C212.759 162.874 212.712 162.861 212.67 162.838C212.627 162.815 212.59 162.782 212.563 162.743C211.984 161.763 211.718 160.63 211.8 159.495C211.807 159.411 211.845 159.334 211.908 159.278C211.97 159.222 212.052 159.192 212.136 159.194C223.777 159.509 235.348 159.495 247.514 159.194C247.58 159.193 247.646 159.211 247.702 159.247C247.758 159.284 247.802 159.336 247.829 159.397C248.235 160.405 248.412 161.49 248.347 162.575C248.341 162.655 248.306 162.731 248.248 162.787C248.189 162.843 248.112 162.874 248.032 162.876Z"
        fill="black"
      />
      <path
        d="M224 158.998C223.855 160.546 223.98 162.107 224.371 163.611C227.038 164.024 232.512 163.716 232.512 163.716C232.592 162.165 232.535 160.61 232.344 159.068C228.144 158.9 224 158.998 224 158.998Z"
        fill="white"
      />
      <path
        d="M248.59 116.704L248.989 117.061L249.304 117.355L249.899 117.929C250.284 118.307 250.634 118.685 250.991 119.07C251.691 119.833 252.356 120.624 252.986 121.422C254.245 123.002 255.413 124.652 256.486 126.364C258.576 129.745 260.341 133.317 261.757 137.032C261.946 137.529 262.093 137.83 262.359 138.677C262.57 139.362 262.72 140.065 262.807 140.777C262.936 142.023 262.92 143.28 262.758 144.522C262.457 146.662 261.893 148.757 261.078 150.759C260.333 152.617 259.458 154.42 258.46 156.156C257.483 157.861 256.391 159.498 255.191 161.056C254.835 161.513 254.328 161.829 253.76 161.948C253.193 162.066 252.601 161.98 252.092 161.703C251.582 161.427 251.187 160.979 250.976 160.439C250.766 159.899 250.754 159.301 250.942 158.753L250.984 158.634C252.198 155.347 253.188 151.982 253.945 148.561C254.289 147.033 254.467 145.472 254.477 143.906C254.479 143.299 254.404 142.695 254.253 142.107C254.082 141.574 253.846 141.064 253.553 140.588C252.01 137.493 250.258 134.507 248.31 131.649C247.351 130.249 246.329 128.849 245.279 127.575C244.754 126.917 244.208 126.294 243.669 125.678L242.857 124.789L242.465 124.376L242.283 124.187L242.164 124.075C241.183 123.222 240.581 122.014 240.49 120.717C240.4 119.42 240.828 118.14 241.681 117.159C242.534 116.178 243.742 115.576 245.039 115.485C246.336 115.394 247.616 115.823 248.597 116.676L248.59 116.704Z"
        fill="#FF8B7B"
      />
      <path
        d="M242.556 110.642C248.009 111.587 253.441 118.083 256.682 124.117C254.447 127.889 251.099 130.877 247.099 132.671C247.099 132.671 240.701 126.441 238.146 121.877C235.591 117.313 237.425 109.753 242.556 110.642Z"
        fill="#134074"
      />
      <path
        d="M256.494 122.738C256.991 123.322 257.377 123.99 257.635 124.712C256.235 126.812 252.917 132.363 246.848 133.448C246.267 133.109 245.827 132.572 245.609 131.936C245.609 131.936 252.616 128.604 256.494 122.738Z"
        fill="#263238"
      />
      <path
        d="M238.609 110.152C237.482 109.669 237.909 109.032 237.909 109.032C237.909 109.032 243.096 109.214 246.645 110.341C247.17 110.964 246.554 111.741 246.554 111.741C246.554 111.741 241.955 111.587 238.609 110.152Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M237.251 108.892C237.251 108.892 236.075 112.994 230.699 116.34C233.051 116.34 234.626 120.631 234.626 120.631C234.626 120.631 239.526 114.877 238.392 109.2C238.063 108.696 237.251 108.892 237.251 108.892Z"
        fill="black"
      />
      <path
        d="M237.097 108.15C237.097 108.15 235.697 112.588 230.699 116.34C233.051 116.34 234.682 118.881 234.682 118.881C234.682 118.881 239.498 114.877 238.392 109.2C238.026 108.776 237.588 108.421 237.097 108.15Z"
        fill="#134074"
      />
      <path
        d="M242.137 124.999C241.815 131.047 237.986 132.489 237.447 132.489C236.908 132.489 233.247 131.047 233.569 124.999L237.944 123.277L242.137 124.999Z"
        fill="white"
      />
      <path
        d="M251.558 158.27L247.246 161.98L252.965 165.697C252.965 165.697 256.465 161.861 254.729 159.677L251.558 158.27Z"
        fill="#FF8B7B"
      />
      <path
        d="M246.763 167.867L248.982 168.826C249.411 169.012 249.891 169.041 250.338 168.906C250.786 168.772 251.171 168.483 251.425 168.091L252.965 165.697L247.246 161.98L245.755 165.235C245.646 165.474 245.586 165.732 245.577 165.994C245.569 166.256 245.613 166.518 245.707 166.763C245.801 167.008 245.942 167.232 246.124 167.421C246.305 167.611 246.523 167.762 246.763 167.867Z"
        fill="#FF8B7B"
      />
      <path
        d="M222.389 83.3907C218.546 83.9157 219.834 85.1337 228.402 86.2257C236.97 87.3177 239.119 86.4567 239.119 86.4567C239.119 86.4567 229.277 83.3697 222.389 83.3907Z"
        fill="#263238"
      />
      <path
        d="M241.773 88.123C241.773 88.123 234.248 85.064 222.39 83.391C222.243 81.648 223.678 79.8 223.678 79.8C223.678 79.8 221.032 78.477 221.851 75.95C222.67 73.423 226.457 73.752 230.874 71.75C232.491 73.528 236.936 76.419 239.974 76.79C240.723 78.337 242.683 82.208 245.861 83.699C245.812 85.386 242.655 85.799 242.655 85.799C242.669 86.2255 242.598 86.6506 242.447 87.0495C242.296 87.4485 242.066 87.8134 241.773 88.123Z"
        fill="#134074"
      />
      <path
        opacity="0.3"
        d="M242.647 85.7987C239.896 83.2997 230.124 78.7357 223.677 79.7997C223.677 79.7997 222.242 81.6547 222.389 83.3907C234.247 85.0637 241.772 88.1227 241.772 88.1227C242.065 87.8125 242.293 87.4474 242.443 87.0484C242.593 86.6495 242.663 86.2247 242.647 85.7987Z"
        fill="black"
      />
      <path
        d="M232.007 76.888C231.16 81.2 228.22 81.977 227.807 81.935C227.394 81.893 224.874 80.619 225.707 76.335L229.088 75.397L232.007 76.888Z"
        fill="white"
      />
      <path
        d="M176.225 147.546C175.798 147.546 175.385 147.389 175.066 147.105C174.746 146.821 174.541 146.43 174.489 146.006L169.491 105.273C169.435 104.809 169.565 104.341 169.853 103.973C170.141 103.605 170.564 103.366 171.028 103.309C171.492 103.253 171.96 103.383 172.328 103.671C172.696 103.959 172.935 104.382 172.991 104.846L177.961 145.6C177.991 145.828 177.975 146.06 177.914 146.282C177.854 146.503 177.75 146.711 177.609 146.893C177.467 147.074 177.292 147.226 177.092 147.339C176.891 147.453 176.671 147.525 176.442 147.553L176.225 147.546Z"
        fill="#263238"
      />
      <path
        d="M175.112 129.22C174.685 127.652 175.49 127.421 174.713 125.195C174.3 124.012 175.658 123.095 175.826 124.047C176.456 127.512 181.055 129.451 179.459 131.642C177.863 133.833 175.532 130.788 175.112 129.22Z"
        fill="#FF8B7B"
      />
      <path
        d="M206.31 70.5505C207.683 48.6695 191.057 29.8185 169.176 28.4457C147.295 27.0729 128.444 43.6981 127.072 65.5791C125.699 87.4602 142.324 106.311 164.205 107.684C186.086 109.057 204.937 92.4315 206.31 70.5505Z"
        fill="#134074"
      />
      <path
        d="M196.899 78.7644C202.813 62.0747 194.077 43.7514 177.387 37.838C160.698 31.9246 142.374 40.6605 136.461 57.3501C130.548 74.0397 139.284 92.3631 155.973 98.2765C172.663 104.19 190.986 95.454 196.899 78.7644Z"
        fill="white"
      />
      <path
        d="M187.509 62.6432C184.877 59.0872 179.809 62.6432 178.29 66.3812C177.877 67.3085 177.606 68.2924 177.485 69.3002L175.812 57.8832L174.489 47.0612C174.349 46.1325 173.853 45.2949 173.105 44.7266C172.358 44.1582 171.418 43.904 170.486 44.018C169.554 44.132 168.703 44.6053 168.114 45.337C167.526 46.0688 167.246 47.0013 167.335 47.9362L167.006 45.2412C166.948 44.7719 166.798 44.3186 166.565 43.9072C166.332 43.4958 166.02 43.1344 165.647 42.8435C165.274 42.5526 164.848 42.338 164.392 42.2119C163.936 42.0858 163.46 42.0507 162.991 42.1087C162.522 42.1666 162.068 42.3163 161.657 42.5494C161.246 42.7825 160.884 43.0944 160.593 43.4671C160.302 43.8399 160.088 44.2663 159.962 44.7221C159.836 45.1778 159.801 45.6539 159.859 46.1232L160.321 49.8752C160.258 49.3673 160.088 48.8787 159.822 48.4417C159.555 48.0047 159.199 47.6294 158.777 47.3406C158.354 47.0519 157.875 46.8565 157.371 46.7673C156.867 46.678 156.35 46.6971 155.855 46.8232C155.005 47.041 154.264 47.5603 153.77 48.2841C153.275 49.0079 153.061 49.8869 153.167 50.7572L153.741 55.4402C153.679 54.9318 153.509 54.4425 153.244 54.005C152.978 53.5674 152.621 53.1916 152.199 52.9027C151.776 52.6138 151.296 52.4184 150.792 52.3297C150.288 52.241 149.77 52.2609 149.275 52.3882C148.426 52.6046 147.686 53.1228 147.193 53.8457C146.699 54.5685 146.486 55.4465 146.594 56.3152L147.742 65.6742L148.561 72.3872L149.646 81.2002C150.416 87.5002 152.873 91.0562 156.52 92.6592C162.12 95.1162 172.375 95.1442 176.981 91.4062C181.013 88.1372 183.603 80.2622 184.226 75.3832C184.849 70.5042 188.58 64.0782 187.509 62.6432Z"
        fill="#263238"
      />
      <path
        d="M193.559 40.421L187.023 35.313L139.81 95.7223L146.345 100.83L193.559 40.421Z"
        fill="#134074"
      />
      <path
        d="M56.3086 126.056V113.316H65.5276V114.8H57.9956V118.706H65.0446V120.204H57.9956V124.537H65.8006V126.042L56.3086 126.056Z"
        fill="#263238"
      />
      <path
        d="M67.9355 126.056V116.823H69.3355V118.223C69.5714 117.726 69.9102 117.284 70.3295 116.928C70.6221 116.721 70.9722 116.611 71.3305 116.613C71.9045 116.629 72.4625 116.806 72.9405 117.124L72.4015 118.573C72.0566 118.359 71.6596 118.242 71.2535 118.237C70.9216 118.231 70.5979 118.34 70.3365 118.545C70.0554 118.76 69.8496 119.059 69.7485 119.399C69.5724 119.992 69.4851 120.607 69.4895 121.226V126.056H67.9355Z"
        fill="#263238"
      />
      <path
        d="M73.8633 126.056V116.823H75.2633V118.223C75.508 117.712 75.8616 117.26 76.2993 116.9C76.5923 116.694 76.942 116.584 77.3003 116.585C77.8743 116.601 78.4322 116.778 78.9103 117.096L78.3713 118.545C78.0263 118.331 77.6294 118.214 77.2233 118.209C76.8914 118.204 76.5676 118.312 76.3063 118.517C76.0251 118.733 75.8193 119.031 75.7183 119.371C75.5421 119.964 75.4549 120.58 75.4593 121.198V126.028L73.8633 126.056Z"
        fill="#263238"
      />
      <path
        d="M79.2259 121.443C79.2259 119.735 79.6926 118.468 80.6259 117.642C81.4306 116.951 82.4636 116.584 83.5239 116.613C84.1019 116.592 84.6777 116.693 85.2143 116.909C85.7508 117.125 86.2361 117.451 86.6389 117.866C87.0578 118.332 87.3804 118.876 87.5883 119.467C87.7961 120.058 87.885 120.684 87.8499 121.31C87.8933 122.273 87.7086 123.232 87.3109 124.11C86.9634 124.781 86.422 125.333 85.7569 125.692C85.0743 126.071 84.3048 126.267 83.5239 126.259C82.9448 126.282 82.3671 126.184 81.8282 125.971C81.2893 125.757 80.8011 125.433 80.3949 125.02C79.5515 124.027 79.1317 122.742 79.2259 121.443ZM80.8359 121.443C80.7647 122.391 81.0381 123.333 81.6059 124.096C81.8484 124.373 82.1471 124.594 82.4821 124.746C82.8171 124.898 83.1806 124.976 83.5484 124.976C83.9162 124.976 84.2798 124.898 84.6148 124.746C84.9498 124.594 85.2485 124.373 85.4909 124.096C86.0668 123.316 86.3426 122.354 86.2679 121.387C86.3345 120.458 86.058 119.536 85.4909 118.797C85.2479 118.521 84.949 118.301 84.614 118.149C84.2791 117.998 83.9159 117.92 83.5484 117.92C83.181 117.92 82.8177 117.998 82.4828 118.149C82.1479 118.301 81.849 118.521 81.6059 118.797C81.0398 119.558 80.7664 120.497 80.8359 121.443Z"
        fill="#263238"
      />
      <path
        d="M89.6914 126.056V116.823H91.0914V118.223C91.3248 117.724 91.664 117.282 92.0854 116.928C92.378 116.721 92.728 116.611 93.0864 116.613C93.6604 116.629 94.2183 116.806 94.6964 117.124L94.1574 118.573C93.812 118.359 93.4153 118.243 93.0094 118.237C92.6753 118.232 92.3495 118.34 92.0854 118.545C91.8065 118.761 91.6031 119.06 91.5044 119.399C91.3249 119.991 91.2376 120.607 91.2454 121.226V126.056H89.6914Z"
        fill="#263238"
      />
      <path
        d="M62.1252 141.883V140.525C61.8132 141.018 61.3793 141.422 60.8655 141.698C60.3517 141.973 59.7753 142.112 59.1922 142.1C58.6693 142.104 58.1515 141.996 57.6732 141.785C57.258 141.621 56.8934 141.351 56.6162 141.001C56.3794 140.65 56.2152 140.255 56.1332 139.839C56.0591 139.355 56.0286 138.865 56.0422 138.376V132.65H57.6032V137.767C57.5856 138.319 57.6184 138.872 57.7012 139.419C57.7856 139.807 58.0067 140.153 58.3242 140.392C58.671 140.632 59.0856 140.755 59.5072 140.742C59.9722 140.743 60.429 140.62 60.8302 140.385C61.2223 140.161 61.5297 139.814 61.7052 139.398C61.8986 138.819 61.984 138.209 61.9572 137.599V132.65H63.5252V141.883H62.1252Z"
        fill="#263238"
      />
      <path
        d="M65.9746 141.883V132.65H67.3746V133.959C67.6899 133.472 68.127 133.075 68.6427 132.809C69.1584 132.542 69.7347 132.415 70.3146 132.44C70.841 132.432 71.3633 132.535 71.8476 132.741C72.2625 132.902 72.6255 133.173 72.8976 133.525C73.1371 133.876 73.3037 134.271 73.3876 134.687C73.4576 135.19 73.4857 135.698 73.4716 136.206V141.883H71.9106V136.283C71.9293 135.809 71.8654 135.335 71.7216 134.883C71.5991 134.565 71.372 134.299 71.0776 134.127C70.7471 133.933 70.3687 133.836 69.9856 133.847C69.3513 133.836 68.7354 134.061 68.2566 134.477C67.7736 134.904 67.5566 135.709 67.5566 136.892V141.932L65.9746 141.883Z"
        fill="#263238"
      />
      <path
        d="M81.9 140.742C81.4045 141.184 80.8361 141.537 80.22 141.785C79.6698 141.993 79.086 142.097 78.498 142.093C77.652 142.148 76.8166 141.88 76.16 141.344C75.8928 141.107 75.6809 140.815 75.5394 140.487C75.3978 140.159 75.3301 139.804 75.341 139.447C75.3369 139.026 75.437 138.611 75.6325 138.239C75.8279 137.866 76.1125 137.548 76.461 137.312C76.8048 137.077 77.1858 136.902 77.588 136.794C78.0498 136.69 78.5173 136.613 78.988 136.563C79.9368 136.477 80.8748 136.296 81.788 136.024C81.788 135.807 81.788 135.674 81.788 135.611C81.8138 135.369 81.7879 135.123 81.7118 134.892C81.6358 134.66 81.5114 134.447 81.347 134.267C80.8379 133.876 80.2022 133.686 79.562 133.735C78.9918 133.694 78.4226 133.828 77.931 134.12C77.5171 134.482 77.2424 134.977 77.154 135.52L75.628 135.31C75.7313 134.734 75.9714 134.192 76.328 133.728C76.6949 133.289 77.1812 132.966 77.728 132.797C78.4037 132.567 79.1142 132.455 79.828 132.468C80.4812 132.445 81.1332 132.54 81.753 132.748C82.1694 132.883 82.5434 133.124 82.838 133.448C83.0847 133.758 83.2528 134.123 83.328 134.512C83.3912 134.99 83.417 135.472 83.405 135.954V138.054C83.3814 138.989 83.4141 139.924 83.503 140.854C83.5749 141.242 83.7097 141.616 83.902 141.96H82.264C82.0646 141.582 81.941 141.168 81.9 140.742ZM81.767 137.242C80.9405 137.535 80.0833 137.732 79.212 137.83C78.7344 137.879 78.2643 137.984 77.812 138.145C77.5597 138.249 77.345 138.427 77.196 138.656C77.051 138.876 76.9754 139.135 76.979 139.398C76.9751 139.596 77.0152 139.792 77.0963 139.972C77.1774 140.153 77.2975 140.313 77.448 140.441C77.846 140.751 78.3453 140.9 78.848 140.861C79.4103 140.871 79.9655 140.734 80.458 140.462C80.908 140.217 81.2702 139.838 81.494 139.377C81.6877 138.881 81.7736 138.348 81.746 137.816L81.767 137.242Z"
        fill="#263238"
      />
      <path
        d="M91.8327 141.883V140.525C91.5207 141.018 91.0868 141.422 90.573 141.698C90.0592 141.973 89.4828 142.112 88.8997 142.1C88.3746 142.103 87.8546 141.996 87.3737 141.785C86.9615 141.619 86.5996 141.349 86.3237 141.001C86.0811 140.653 85.9164 140.256 85.8407 139.839C85.7634 139.355 85.7306 138.866 85.7427 138.376V132.65H87.3107V137.767C87.2931 138.319 87.3259 138.872 87.4087 139.419C87.4931 139.807 87.7141 140.153 88.0317 140.392C88.3785 140.632 88.7931 140.755 89.2147 140.742C89.6796 140.743 90.1365 140.62 90.5377 140.385C90.9297 140.161 91.2372 139.814 91.4127 139.398C91.606 138.819 91.6914 138.209 91.6647 137.599V132.65H93.2327V141.883H91.8327Z"
        fill="#263238"
      />
      <path
        d="M99.0993 140.483L99.3303 141.883C98.9422 141.972 98.5455 142.019 98.1473 142.023C97.691 142.046 97.2361 141.955 96.8243 141.757C96.5339 141.6 96.301 141.355 96.1593 141.057C95.9946 140.458 95.9307 139.836 95.9703 139.216V133.903H94.8223V132.65H95.9703V130.361L97.5243 129.423V132.65H99.0993V133.868H97.5243V139.3C97.5053 139.59 97.5337 139.88 97.6083 140.161C97.6635 140.289 97.7595 140.394 97.8813 140.462C98.0444 140.549 98.2284 140.59 98.4133 140.581C98.644 140.565 98.8732 140.532 99.0993 140.483Z"
        fill="#263238"
      />
      <path
        d="M100.633 141.883V129.136H102.201V133.7C102.537 133.294 102.96 132.97 103.438 132.75C103.917 132.531 104.439 132.422 104.966 132.433C105.585 132.416 106.199 132.556 106.751 132.839C107.225 133.08 107.607 133.471 107.836 133.952C108.087 134.622 108.197 135.337 108.158 136.052V141.904H106.597V136.052C106.649 135.438 106.466 134.828 106.086 134.344C105.896 134.159 105.669 134.017 105.421 133.925C105.173 133.834 104.908 133.795 104.644 133.812C104.183 133.812 103.731 133.935 103.335 134.169C102.945 134.391 102.64 134.735 102.467 135.149C102.268 135.693 102.178 136.271 102.201 136.85V141.904L100.633 141.883Z"
        fill="#263238"
      />
      <path
        d="M109.95 137.263C109.95 135.555 110.417 134.29 111.35 133.469C112.157 132.777 113.192 132.411 114.255 132.44C114.837 132.419 115.417 132.52 115.957 132.737C116.497 132.954 116.986 133.282 117.391 133.7C117.811 134.167 118.134 134.712 118.342 135.304C118.55 135.896 118.638 136.524 118.602 137.151C118.645 138.113 118.463 139.072 118.07 139.951C117.722 140.626 117.178 141.18 116.509 141.54C115.828 141.918 115.061 142.113 114.283 142.107C113.702 142.131 113.122 142.033 112.582 141.818C112.042 141.603 111.553 141.277 111.147 140.861C110.287 139.867 109.857 138.573 109.95 137.263ZM111.56 137.263C111.489 138.213 111.762 139.157 112.33 139.923C112.569 140.206 112.869 140.432 113.207 140.584C113.545 140.737 113.912 140.812 114.283 140.805C114.652 140.812 115.018 140.736 115.354 140.582C115.69 140.428 115.986 140.2 116.222 139.916C116.794 139.136 117.067 138.178 116.992 137.214C117.059 136.282 116.783 135.358 116.215 134.617C115.973 134.34 115.674 134.119 115.339 133.967C115.004 133.815 114.64 133.736 114.272 133.736C113.905 133.736 113.541 133.815 113.206 133.967C112.871 134.119 112.572 134.34 112.33 134.617C111.761 135.376 111.487 136.317 111.56 137.263Z"
        fill="#263238"
      />
      <path
        d="M120.4 141.883V132.65H121.8V134.05C122.032 133.55 122.371 133.108 122.794 132.755C123.087 132.548 123.437 132.438 123.795 132.44C124.369 132.453 124.927 132.628 125.405 132.944L124.866 134.4C124.523 134.181 124.126 134.062 123.718 134.057C123.387 134.054 123.064 134.162 122.801 134.365C122.522 134.582 122.317 134.88 122.213 135.219C122.038 135.812 121.951 136.428 121.954 137.046V141.883H120.4Z"
        fill="#263238"
      />
      <path
        d="M126.371 130.935V129.136H127.939V130.935H126.371ZM126.371 141.883V132.65H127.939V141.883H126.371Z"
        fill="#263238"
      />
      <path
        d="M129.5 141.883V140.616L135.38 133.868C134.68 133.868 134.12 133.917 133.616 133.917H129.85V132.65H137.396V133.7L132.398 139.559L131.432 140.63C132.132 140.574 132.79 140.546 133.406 140.546H137.676V141.897L129.5 141.883Z"
        fill="#263238"
      />
      <path
        d="M145.544 138.908L147.161 139.111C146.955 139.98 146.462 140.755 145.761 141.309C144.992 141.854 144.064 142.13 143.122 142.093C142.528 142.123 141.934 142.027 141.381 141.811C140.827 141.595 140.325 141.264 139.909 140.84C139.066 139.876 138.639 138.618 138.719 137.34C138.638 136.023 139.069 134.727 139.923 133.721C140.318 133.295 140.8 132.96 141.336 132.738C141.872 132.516 142.45 132.412 143.031 132.433C143.597 132.414 144.16 132.516 144.683 132.734C145.206 132.951 145.676 133.278 146.062 133.693C146.892 134.682 147.311 135.953 147.231 137.242C147.231 137.333 147.231 137.473 147.231 137.662H140.343C140.345 138.514 140.65 139.338 141.204 139.986C141.451 140.249 141.75 140.457 142.083 140.597C142.416 140.737 142.775 140.805 143.136 140.798C143.662 140.812 144.179 140.652 144.606 140.343C145.051 139.966 145.377 139.467 145.544 138.908ZM140.406 136.381H145.558C145.541 135.752 145.336 135.142 144.97 134.631C144.738 134.341 144.443 134.108 144.106 133.952C143.77 133.795 143.402 133.718 143.031 133.728C142.697 133.716 142.364 133.772 142.053 133.893C141.741 134.013 141.457 134.195 141.218 134.428C140.706 134.951 140.416 135.65 140.406 136.381Z"
        fill="#263238"
      />
      <path
        d="M155.114 141.883V140.7C154.85 141.145 154.47 141.51 154.015 141.757C153.561 142.003 153.048 142.122 152.531 142.1C151.809 142.103 151.102 141.891 150.501 141.491C149.872 141.07 149.375 140.48 149.066 139.79C148.717 139.002 148.545 138.146 148.562 137.284C148.55 136.424 148.707 135.571 149.024 134.771C149.296 134.063 149.786 133.46 150.424 133.049C151.035 132.65 151.751 132.441 152.482 132.447C153 132.438 153.512 132.559 153.973 132.797C154.388 133.021 154.749 133.334 155.03 133.714V129.143H156.591V141.89L155.114 141.883ZM150.172 137.277C150.098 138.219 150.361 139.157 150.914 139.923C151.122 140.193 151.389 140.413 151.695 140.566C152 140.719 152.336 140.8 152.678 140.805C153.013 140.807 153.344 140.732 153.646 140.586C153.948 140.441 154.213 140.228 154.421 139.965C154.951 139.221 155.199 138.313 155.121 137.403C155.212 136.417 154.966 135.43 154.421 134.603C154.21 134.326 153.938 134.101 153.626 133.947C153.314 133.792 152.97 133.713 152.622 133.714C152.285 133.711 151.952 133.786 151.649 133.935C151.346 134.083 151.083 134.3 150.879 134.568C150.34 135.364 150.091 136.32 150.172 137.277Z"
        fill="#263238"
      />
    </svg>
  );
}
