import React, { useEffect } from 'react';
import classes from './OriginalDeals.module.scss';
import ManagerHeader from '../../components/Reusable/Common/MemberCard/ManagerHeader';
import DealsAvtiveL from '../../components/Reusable/SVG/DealsAvtiveL';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import { useState } from 'react';
import Table from '../../components/Reusable/Common/Table/Table';
import { Skeleton } from '@mui/material';
import Section from '../../components/Reusable/Common/Section/Section';
import Button from '../../components/Reusable/UI/Button/Button';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import CreateDeal from '../../components/Reusable/Common/AddMemberCard/CreateDeal';
import { useParams } from 'react-router-dom';
import { originalDealsEshtreanaService } from '../../services/OriginalDealsEshtreana';
import StoreLocationCard from '../../components/Reusable/Common/StoreLocationCard/StoreLocationCard';
import LoadingTable from '../../components/Reusable/Common/Table/LoadingTable';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';

export default function DealResponseDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [quantites, setQuantities] = useState([]);
  const [data, setData] = useState(null);
  const [option, setOption] = useState('Main Data');
  const [showCreateDeal, setShowCreateDeal] = useState(false);
  const [tableOptions, setTableOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [wholeQuantities, setWholeQuantities] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [otherPolices, setOtherPolicies] = useState([]);
  let getOfferDetails = async () => {
    let response = await originalDealsEshtreanaService.getSupplierDealOffer(id);
    let Valid = false;

    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setOtherPolicies(response.data.offer.otherPolices);
      let arr2 = [];
      response.data.offer.quantities.map((item) => {
        arr2.push(item.quantity);
      });
      setQuantities(arr2);
      setWholeQuantities(response.data.offer.quantities);

      let options = [];
      options.push({
        title: 'Quantity',
        icon: false,
      });

      arr2.map((item) => {
        options.push({
          title: item,
          icon: false,
        });
      });

      let arr = [[], []];
      arr[0][0] = 'Unit Price';
      arr[1][0] = 'Total Price';
      response.data.offer.quantities.map((item, index) => {
        arr[0][index + 1] = `${item.price} SAR`;
        arr[1][index + 1] = `${item.price * item.quantity} SAR`;
      });
      setTableData(arr);
      setTableOptions(options);
      setData(response.data.offer);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getOfferDetails();
  }, [id]);
  useEffect(() => {
    if (serverError) setTimeout(() => setServerError(false), 2000);
  }, [serverError]);
  return (
    <div className={`w-100 ${classes['original-deals']}`}>
      {showCreateDeal && (
        <Modal onClose={() => setShowCreateDeal(false)} style={{ padding: '24px' }}>
          <CreateDeal supplier={data.supplier.name_en} quantities={quantites} id={data._id} dealId={id} setShowForm={setShowCreateDeal} />
        </Modal>
      )}
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle text="Response Details" icon={<DealsAvtiveL />} />
        </div>
        <section className={`${classes['main-section']}`}>
          {loading ? (
            <>
              <ManagerHeader loading={true} />
              <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
                <div className="row px-0 mx-0">
                  <div className="col-xl-2 col-lg-4">
                    <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                  </div>
                  <div className="col-xl-2 col-lg-4">
                    <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                  </div>
                  <div className="col-xl-2 col-lg-4">
                    <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <ManagerHeader
                type="supplier"
                profilePicture={data.supplier.pic}
                data={{
                  name: data.supplier.name_en,
                  categories: data.supplier.categories,
                }}
              />

              <div className={`row px-0 mx-0   ${classes.options}`}>
                <div className="col-xl-9">
                  <SelectOption onSelect={(val) => setOption(val)} default={option} options={['Main Data', 'Delivery', 'Other Policies']} />
                </div>
                <div className="w-lg-auto pt-lg-0 pt-4  d-inline ms-auto">
                  <Button
                    onClick={() => setShowCreateDeal(true)}
                    color={'#D1E6FF'}
                    borderColor={'#134074'}
                    fontColor={'#134074'}
                    className={' px-2 ms-auto text-center'}
                    type="normal"
                    text={`Create Deal`}
                  />
                </div>
              </div>
            </>
          )}

          {option === 'Main Data' &&
            (loading ? (
              <LoadingTable />
            ) : (
              <>
                <Table
                  type="Supplier Offer Response Details"
                  options={tableOptions || []}
                  wholeData={wholeQuantities}
                  data={tableData}
                  unit={data.unit}
                  customStyleIndex={[]}
                  filterOption={[]}
                  specialSpecification={data.specialSpecification}
                  customStyleFn={[]}
                />
              </>
            ))}

          {option === 'Delivery' && (
            <>
              {(data.deliveryResponsibility === 'Pick From supplier location' && (
                <div className="">
                  {data.branches.length >= 0 &&
                    data.branches.map((item, index) => (
                      <div key={index} className="row px-0 d-flex justify-content-center align-items-cenetr">
                        <div className={` ${'col-12'}`}>
                          <StoreLocationCard item={item.branch} />
                        </div>
                      </div>
                    ))}
                </div>
              )) ||
                (data.deliveryResponsibility === 'Eshtarena' && (
                  <>
                    <div className="row px-0 mx-0">
                      <div className="col-12 px-0 pe-2">
                        <div className={`${classes.input} row d-flex align-items-center`}>
                          <p className="w-auto d-inline">Eshtarena delivery</p>
                        </div>
                      </div>
                    </div>
                    {data.deliveryTerms.map((item, index) => (
                      <div key={index} className="row mt-0 px-0 mx-0">
                        <div className="col-lg-6 pe-lg-3 px-0">
                          <Section border={true} title={item.title_en} text={item.desc_en} />
                        </div>

                        <div className="col-lg-6 px-0 ps-lg-2 ms-auto">
                          <Section
                            // Checkbox={true}
                            border={true}
                            title={item.title_ar}
                            text={item.desc_ar}
                          />
                        </div>
                        {/* <div className="col-6 px-0"></div> */}
                      </div>
                    ))}
                    <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                      <div className="col-md-6 col-12 px-0">
                        <div className={classes['single-item']}>
                          <p>Delivery Fees :</p>
                          <span>{data.deliveryFees} SAR</span>
                        </div>
                      </div>
                    </div>
                  </>
                )) ||
                (data.deliveryResponsibility === 'Supplier' && (
                  <>
                    <div className="row px-0 mx-0">
                      <div className="col-12 px-0 pe-2">
                        <div className={`${classes.input} row d-flex align-items-center`}>
                          <p className="w-auto d-inline">Supplier delivery terms</p>
                        </div>
                      </div>
                    </div>

                    {data.deliveryTerms.length > 0 ? (
                      data.deliveryTerms.map((item, index) => (
                        <div key={index} className="row mt-0 px-0 mx-0">
                          <div className="col-lg-5 col-10 px-0">
                            <Section title={item.title_en} text={item.desc_en} />
                          </div>

                          <div className="d-lg-none d-block col-1"></div>
                          <div
                            // dir="rtl"
                            className="col-lg-6 col-10  px-0  ms-lg-auto me-auto"
                          >
                            <Section title={item.title_ar} text={item.desc_ar} />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-100 row justify-content-center pt-5 pb-4">
                        <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Delivery Terms Added yet.</div>
                      </div>
                    )}
                  </>
                ))}
            </>
          )}
          {option === 'Other Policies' && (
            <>
              {otherPolices.length > 0 ? (
                otherPolices.map((item, index) => (
                  <div key={index} className="row mt-0 px-0 mx-0">
                    <div className="col-lg-6 pe-lg-3 px-0">
                      <Section border={true} title={item.title_en} text={item.desc_en} />
                    </div>

                    <div className="col-lg-6 px-0 ps-lg-2 ms-auto">
                      <Section border={true} title={item.title_ar} text={item.desc_ar} />
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Other Policies Added</div>
                </div>
              )}
            </>
          )}
        </section>
      </section>
    </div>
  );
}
