import React, { useEffect, useState } from 'react';
import classes from './Login.module.scss';
import LoginSVG1 from '../../components/Reusable/SVG/LoginSVG1';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import BaseNavBar from '../../components/Reusable/Common/BaseNavBar/BaseNavBar';
import Button from '../../components/Reusable/UI/Button/Button';
import Login2 from '../../components/Reusable/SVG/Login2';
import { useNavigate } from 'react-router-dom';
// import { CompanyAuthService } from "../../services/CompanyAuth";
import ReactInputVerificationCode from 'react-input-verification-code';
import { authService } from '../../services/authService';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/reducers/authSlice';
import { errorActions } from '../../store/reducers/errorSlice';
// import { authActions, authReducer } from '../../store/reducers/authSlice';
// import { useDispatch } from 'react-redux';
// import { membersActions } from '../../store/reducers/memberSlice';
// import { HaaiOwnerService } from '../../services/HaaiOwnerService';
// import { HaaiSupportService } from '../../services/HaaiSupportService';
// import { HaaiSalesService } from '../../services/HaaiSalesService';
// import { HaaiAdminService } from '../../services/HaaiAdminsService';
// import { ChatService } from '../../services/Chat';
// import { roomsActions } from '../../store/reducers/roomsSlice';
export default function Login1() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [otp, setOTP] = useState(null);
  // const dispatch = useDispatch();
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showError, setShowError] = useState(false);
  const [change, setChange] = useState('1');
  useEffect(() => {
    // //console.log("////////////////////");

    if (localStorage.getItem('token')) {
      //console.log("////////////////////");
      if (localStorage.getItem('side') === 'Top Manager') history(`/account/product`);
      else if (localStorage.getItem('side') === 'Marketing Manager') history(`/account/ad`);
      else if (localStorage.getItem('side') === 'Category Manager') history(`/account/product`);
      else if (localStorage.getItem('side') === 'Supplier') history(`/account/suppliers/${localStorage.getItem('id')}`);
      else if (localStorage.getItem('side') === 'Cashier') history(`/cashier`);
    }
    dispatch(errorActions.setError({ error: false, errorMsg: '' }));
    setServerError(true);
  }, []);
  let handleLogin = async () => {
    dispatch(errorActions.setError({ error: false, errorMsg: '' }));
    setShowError(true);
    setServerError(false);
    if (formData.password.length < 8 || !formData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_.@#\$%\^&\*])(?=.{8,})/)) {
      setLoading(false);
      setServerErrorMsg('The Password or Email is Incorrect');
      setServerError(true);
    } else if (formData.email && formData.password) {
      let response = await authService.eshtreanaSignin(formData);
      let res = response;
      //console.log(response);

      if (response.response) {
        if (response.response.data.message === 'Please verify your email address') setShowVerify(true);
        else if (response.response.data.message === 'Incorrect password') {
          setLoading(false);
          setServerErrorMsg('The Password or Email is Incorrect');
          setServerError(true);
        } else if (response.response.data.message === 'not found') {
          setLoading(false);
          setServerErrorMsg('The Password or Email is Incorrect');
          setServerError(true);
        }
      } else {
        if (response.message) {
          setServerErrorMsg('There is an Error happend , try later . ');
          setServerError(true);
        } else if (response.data.message === 'Sign-in successfully') {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('side', response.data.side);
          localStorage.setItem('id', response.data._id);
          dispatch(authActions.updateUser(response.data));
          if (response.data.side === 'Top Manager') history(`/account/product`);
          else if (response.data.side === 'Marketing Manager') history(`/account/ad`);
          else if (response.data.side === 'Category Manager') history(`/account/product`);
          else if (response.data.side === 'Supplier') history(`/account/suppliers/${response.data._id}`);
          else if (response.data.side === 'Cashier') history(`/cashier`);
        } else if (response.data.message === 'Please verify your email') setShowVerify(true);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  let handleChange = (e) => {
    setServerError(false);
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return <></>;
}
