import React from 'react';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import classes from './Cashier.module.scss';
import EshtreanaMainLogo from '../../components/Reusable/SVG/EshtreanaMainLogo';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import Button from '../../components/Reusable/UI/Button/Button';
// import {
//   BasisProvider,
//   defaultTheme,
//   Container,
//   Text,
//   Link as BasisLink,
// } from "basis";
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

import useScanDetection from 'use-scan-detection';

import Scan from '../../components/Reusable/SVG/Scan';
// import BarcodeScanner from "./BarCodeScanner";
import { useState } from 'react';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export default function CashierMainPage() {
  const history = useNavigate();
  const [showScanner, setShowScanner] = useState(false);
  const [data, setData] = React.useState('');
  const [torchOn, setTorchOn] = React.useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // //console.log(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    //console.log(data);
  }, [data]);
  useScanDetection({
    onComplete: setData,
    // minLength: 3,
  });
  return (
    <div className={` row px-0 mx-0 overflow-hidden  ${classes['cashier']}`}>
      {/* {showScanner && (
        <Modal
          style={{ height: "510px" }}
          onClose={() => {
            setShowScanner(false);
            // window.MediaDevices.
          }}
        >
          <BarcodeScanner
            setBarCode={(val) => {
              setShowScanner(false);
              setData(val?.codeResult?.code);
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }}
          />
        </Modal>
      )} */}
      <CompanyNav />
      <div className=" ">
        <section className=" custom-container">
          <section className={`row px-0 mx-0 ${classes['main-section']}`}>
            <div className="row px-0 mx-0 mb-5">
              <div className="w-auto d-inline mx-auto">
                <EshtreanaMainLogo />
              </div>
            </div>
            <div className="row  px-0 mx-0 mt-5  align-items-center  d-flex ">
              <div className="mx-auto  row align-iitems-center justify-content-center">
                <div className="col-lg-4 col-7  px-0  me-lg-0 me-2">
                  {!loading && (
                    <div className={classes.input}>
                      <InputGroup
                        default={data}
                        //   name="name"
                        onChange={(e) => setData(e.target.value)}
                        //   error={showError && !dataErr.commercailName_en}
                        //   errorMsg="Provide an Employee Name"
                        value={'Search For Voucher'}
                      />
                    </div>
                  )}
                </div>
                <div className="col-2 px-0 align-self-center mt-3">
                  <Button
                    onClick={() => {
                      history(`/cashier/${data}`);
                    }}
                    color={'#D1E6FF'}
                    borderColor={'#134074'}
                    fontColor={'#134074'}
                    className={'w-auto px-5  ms-auto text-end'}
                    type="normal"
                    text={'Search'}
                  />
                </div>
              </div>
            </div>
            <div className={`row mx-0 px-0 justify-content-center mb-pb-0 ${classes['input']}`}>
              <div className="mx-auto w-auto  text-center col-6  px-0   mb-pb-0">
                <div className={`  mb-pb-0`}>
                  <Button
                    onClick={() => {
                      // setOption("Communication Data");
                      // setShowScanner(true);
                      history('/scanner');
                    }}
                    icon={<Scan color="#134074" />}
                    color={'#D1E6FF'}
                    borderColor={'#134074'}
                    fontColor={'#134074'}
                    className={'w-auto px-5  mx-auto text-end'}
                    type="normal"
                    text={'Scan Code'}
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
}
