import ReactDOM from 'react-dom';
import classes from './Suppliers.module.scss';
import { Radio, Skeleton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Edit from '../../components/Reusable/SVG/Edit';
import Eye2 from '../../components/Reusable/SVG/Eye2';
import Cross from '../../components/Reusable/SVG/Cross';
import { useNavigate, useParams } from 'react-router-dom';
import Filters from '../../components/Reusable/SVG/Filters';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { vouchersSupplierService } from '../../services/VoucherSuppliers';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import { coldDealsSupplierService } from '../../services/ColdDealsSuppliers';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import VoucherCard from '../../components/Reusable/Common/Voucher/VoucherCard';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import { supplierTopManagerService } from '../../services/supplierTopManagerService';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import ManagerHeader from '../../components/Reusable/Common/MemberCard/ManagerHeader';
import { originalDealsSupplierService } from '../../services/OriginalDealsSuppliers';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';

export default function SuppierDetails() {
  //----------------------Declarations---------------------------------
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(true);
  const [showToggleActivation, setShowToggleActivation] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [coldDeals, setColdDeals] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [serverError, setServerError] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [originalDeals, setOriginalDeals] = useState([]);
  const [option, setOption] = useState('Original Deals');
  const [filterOptions, setFilterOptions] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [filterOption, setFilterOption] = useState('On Going');
  const [showOptions, setShowOptions] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  //----------------------Functions---------------------------------
  let getOriginalDeals = async () => {
    let query = '';
    query += localStorage.getItem('side') === 'Supplier' ? `${`&type=${filterOption.toLowerCase()}`}` : `${`&status=${filterOption.toLowerCase()}`}`;
    let response =
      localStorage.getItem('side') === 'Supplier'
        ? await originalDealsSupplierService.getAllDeals(page, 6, query)
        : await supplierTopManagerService.getSuppliersOriginalDeal(id, page, 6, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setOriginalDeals(response.data.deals);
      setTotalPages(response.data.totalPages);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading1(false);
  };

  let getColdDeals = async () => {
    let query = '';
    query += localStorage.getItem('side') === 'Supplier' ? `${`&type=${filterOption.toLowerCase()}`}` : `${`&status=${filterOption.toLowerCase()}`}`;
    let response =
      localStorage.getItem('side') === 'Supplier'
        ? filterOption === 'On Going'
          ? await coldDealsSupplierService.getOnGoingDeals(page, 6, query)
          : await coldDealsSupplierService.getHistoryDeals(page, 6, query)
        : await supplierTopManagerService.getSuppliersColdDeal(id, page, 6, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setColdDeals(response.data.deals);
      setTotalPages(response.data.totalPages);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading2(false);
  };

  let getVouchers = async () => {
    let query = '';
    query += localStorage.getItem('side') === 'Supplier' ? `${`&type=${filterOption.toLowerCase()}`}` : `${`&status=${filterOption.toLowerCase()}`}`;
    let response =
      localStorage.getItem('side') === 'Supplier'
        ? await vouchersSupplierService.getVouchers(page, 6, query)
        : await supplierTopManagerService.getSuppliersVoucher(id, page, 6, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setVouchers(localStorage.getItem('side') === 'Supplier' ? response.data.vouchers : response.data.deals);
      setTotalPages(response.data.totalPages);
      setLoading3(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading3(false);
  };

  let handleActivate = async () => {
    let response = await supplierTopManagerService.toggelActivateSupplier(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setActive((prev) => !prev);
      setShowToggleActivation(true);
      setTimeout(() => {
        setShowToggleActivation(false);
        setShowOptions(true);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
      setShowOptions(true);
    }
  };
  let getSupplierData = async () => {
    let response = await supplierTopManagerService.getSingleSupplierData(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setActive(response.data.supplier.activated);
      setData(response.data.supplier);
      setActive(response.data.supplier.activated);
      setLoading(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
      setLoading(false);
    }
  };
  //----------------------Use Effects---------------------------------
  useEffect(() => {
    setFilterOption('On Going');
    if (localStorage.getItem('side') === 'Top Manager') {
      if (option === 'Original Deals' || option === 'Vouchers') setFilterOptions(['On Going', 'Scheduled', 'History']);
      else setFilterOptions(['On Going', 'History']);
    } else {
      if (option === 'Original Deals' || option === 'Vouchers') setFilterOptions(['On Going', 'Scheduled', , 'History']);
      else setFilterOptions(['On Going', 'History']);
    }
  }, [option]);

  useEffect(() => {
    getSupplierData();
    getOriginalDeals();
    setLoading2(false);
    setLoading3(false);
    setFirstLoad(false);
  }, [id]);
  useEffect(() => {
    if (!firstLoad) {
      if (option === 'Original Deals')
        if (page === 1) {
          setLoading1(true);
          getOriginalDeals();
        } else setPage(1);
      if (option === 'Cold Deals')
        if (page === 1) {
          setLoading2(true);
          getColdDeals();
        } else setPage(1);
      if (option === 'Vouchers')
        if (page === 1) {
          setLoading3(true);
          getVouchers();
        } else setPage(1);
    }
  }, [filterOption, option]);
  useEffect(() => {
    if (!firstLoad) {
      if (option === 'Original Deals') {
        setLoading1(true);
        getOriginalDeals();
      }
      if (option === 'Cold Deals') {
        setLoading2(true);
        getColdDeals();
      }
      if (option === 'Vouchers') {
        setLoading3(true);
        getVouchers();
      }
    }
  }, [page]);

  return (
    <div className={`w-100 ${classes['suppliers']}`}>
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className={'backdrop'}
            onClick={() => {
              setShowFilters(false);
            }}
          ></div>,
          document.getElementById('overlays'),
        )}

      {showToggleActivation && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowToggleActivation(false);
          }}
        >
          <SuccessPopup message1={`Supplier ${active ? 'Activated' : 'Deactivated'}  Successfully`} setShowForm={setShowToggleActivation} />
        </Modal>
      )}
      {serverError && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle text="Supplier Profile" />
        </div>
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <ManagerHeader loading={true} />
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <div className="row px-0 mx-0">
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className={`${classes['main-section']} mb-5`}>
            <ManagerHeader
              data={{
                name: data.name_en,
                categories: data.categories,
              }}
              type="supplier"
              profilePicture={data.pic}
              closeOption={showOptions}
              optionComponent={
                <>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      history(`/account/suppliers/${data._id}/data`);
                    }}
                    className="row mx-0 mb-2 mx-0"
                  >
                    <div className="w-auto d-inline">
                      <Eye2 color="#6E7980" />
                    </div>
                    &nbsp; Show Supplier Data
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      history(`/account/suppliers/${data._id}/data/edit`);
                    }}
                    className={`row mx-0 ${localStorage.getItem('side') !== 'Top Manager' ? 'mb-0' : 'mb-2'} mx-0`}
                  >
                    <div className="w-auto d-inline">
                      <Edit color="#6E7980" />
                    </div>
                    &nbsp; Edit Supplier Profile
                  </div>
                  {localStorage.getItem('side') === 'Top Manager' && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleActivate();
                        setShowOptions(false);
                      }}
                      className="row mx-0 mx-0 mb-0"
                    >
                      <div className="w-auto d-inline">
                        <Cross />
                      </div>
                      &nbsp; {!active ? 'Activate' : 'Deactive'} Supplier
                    </div>
                  )}
                </>
              }
            />

            <div className={`row px-0 mx-0 align-items-center  ${classes.options}`}>
              <div className="col-10">
                <SelectOption
                  onSelect={(val) => {
                    setLoading1(true);
                    setLoading2(true);
                    setLoading3(true);
                    setOption(val);
                  }}
                  default={option}
                  options={['Original Deals', 'Cold Deals', 'Vouchers']}
                />
              </div>
              <div
                style={{
                  color: '#134074',
                  cursor: 'pointer',
                  fontWeight: '500',
                }}
                className="col-2 ms-auto text-end position-relative"
                onClick={() => {
                  setShowFilters(true);
                }}
              >
                <Filters color="#134074" /> Filter
                {showFilters && (
                  <div style={{ zIndex: '50000' }} className={`position-absolute ${classes['table-options']}`}>
                    {filterOptions.map((item, index) => (
                      <p key={index} className="row  cursor  py-0  h-auto py-0 px-0 mx-0">
                        <div className=" h-auto  col-2 py-0 px-0">
                          <Radio
                            style={{ lineHeight: 0, height: '0px' }}
                            checked={filterOption === item && true}
                            id={`${index}`}
                            value={item}
                            onChange={() => {
                              option === 'Original Deals' && setLoading1(true);
                              option === 'Cold Deals' && setLoading2(true);
                              option === 'Vouchers' && setLoading3(true);
                              setFilterOption(item);
                            }}
                          />
                        </div>
                        <div
                          onClick={() => {
                            option === 'Original Deals' && setLoading1(true);
                            option === 'Cold Deals' && setLoading2(true);
                            option === 'Vouchers' && setLoading3(true);
                            setFilterOption(item);
                          }}
                          className="col-9  py-0 "
                        >
                          {item}
                        </div>
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {option === 'Original Deals' &&
              (loading1 ? (
                <div className="row px-0 mx-0">
                  {[0, 1, 2, 3, 4, 5].map((item, index) => (
                    <div key={index} className={`col-xl-4 col-lg-6 px-0 pe-lg-3`}>
                      <Skeleton
                        variant="rounded"
                        sx={{
                          borderRaduis: '20px',
                          fontSize: '2rem',
                          width: '100%',
                          height: '400px',
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="row px-0 mx-0">
                    {originalDeals.length === 0 ? (
                      <div className="w-100 row justify-content-center pt-5 pb-4 ">
                        <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Deals Found</div>
                      </div>
                    ) : (
                      <>
                        {filterOption === 'On Going' && (
                          <div className="row px-0 mx-0 ">
                            {originalDeals.map((item, index) => (
                              <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                <VoucherCard
                                  id={item._id}
                                  data={[
                                    {
                                      title: 'Deal Name',
                                      value: item.title_en,
                                    },
                                    {
                                      title: 'Deal Code',
                                      value: item.uuid,
                                    },
                                    {
                                      title: 'Product Name',
                                      value: item.product || item.productName,
                                    },
                                    {
                                      title: 'Supplier Name',
                                      value: data.name_en,
                                    },
                                    {
                                      title: 'Time Left',
                                    },
                                    {
                                      title: 'Deal Creation Date',
                                      value: item?.createdAt?.slice(0, 10),
                                    },
                                    {
                                      title: 'Progress',
                                      value: item.sold,
                                      total: item.quantity,
                                    },
                                  ]}
                                  type="On Going"
                                  side={localStorage.getItem('side') === 'Top Manager' ? 'ManagerDeal' : 'SupplierDeal'}
                                  timeLeft={item.endDate}
                                  status={true}
                                  statusValue={
                                    item.dealType === 'Big area deal'
                                      ? 'Big Area Deal'
                                      : item.dealType === 'Area deal'
                                      ? 'Area Deal'
                                      : item.dealType === 'Big deal'
                                      ? 'Big Deal'
                                      : 'Original Deal'
                                  }
                                  progress={true}
                                  index={index}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {filterOption === 'Requests' && (
                          <div className="row px-0 mx-0 ">
                            {originalDeals.map((item, index) => (
                              <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                <VoucherCard
                                  id={item._id}
                                  data={[
                                    {
                                      title: 'Deal Code',
                                      value: item.uuid,
                                    },
                                    {
                                      title: 'Product Name',
                                      value: item.product || item.productName,
                                    },
                                    {
                                      title: 'Market Price',
                                      value: item.price,
                                    },
                                  ]}
                                  type="Requests"
                                  side={localStorage.getItem('side') === 'Top Manager' ? 'ManagerDeal' : 'SupplierDeal'}
                                  status={true}
                                  statusValue={
                                    item.dealType === 'Big area deal'
                                      ? 'Big Area Deal'
                                      : item.dealType === 'Area deal'
                                      ? 'Area Deal'
                                      : item.dealType === 'Big deal'
                                      ? 'Big Deal'
                                      : 'Original Deal'
                                  }
                                  progress={false}
                                  index={index}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {filterOption === 'Scheduled' && (
                          <div className="row px-0 mx-0 ">
                            {originalDeals.map((item, index) => (
                              <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                <VoucherCard
                                  id={item._id}
                                  data={[
                                    {
                                      title: 'Deal Name',
                                      value: item.title_en,
                                    },
                                    {
                                      title: 'Deal Code',
                                      value: item.uuid,
                                    },
                                    {
                                      title: 'Product Name',
                                      value: item.product || item.productName,
                                    },
                                    {
                                      title: 'Supplier Name',
                                      value: data.name_en,
                                    },
                                    {
                                      title: 'Starts At',
                                      value: new Date(item?.startDate).toLocaleDateString(),
                                    },
                                    {
                                      title: 'Ends At',
                                      value: new Date(item?.endDate).toLocaleDateString(),
                                    },
                                  ]}
                                  status={true}
                                  statusValue={
                                    item.dealType === 'Big area deal'
                                      ? 'Big Area Deal'
                                      : item.dealType === 'Area deal'
                                      ? 'Area Deal'
                                      : item.dealType === 'Big deal'
                                      ? 'Big Deal'
                                      : 'Original Deal'
                                  }
                                  type="Scheduled"
                                  side={localStorage.getItem('side') === 'Top Manager' ? 'ManagerDeal' : 'SupplierDeal'}
                                  progress={false}
                                  index={index}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {filterOption === 'Responses' && (
                          <div className="row px-0 mx-0 ">
                            {originalDeals.map((item, index) => (
                              <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                <VoucherCard
                                  id={item._id}
                                  data={[
                                    {
                                      title: 'Request Code',
                                      value: item.uuid,
                                    },
                                    {
                                      title: 'Total Number of Supplier',
                                      value: item.suppliers,
                                    },
                                    {
                                      title: 'Joined Supplier',
                                      value: item.joinedSupplier,
                                    },
                                    {
                                      title: 'Request Date',
                                      value: item?.createdAt?.slice(0, 10),
                                    },
                                    {
                                      title: 'Product Name',
                                      value: item.product || item.productName,
                                    },
                                  ]}
                                  type="Responses"
                                  status={true}
                                  statusValue={
                                    item.dealType === 'Big area deal'
                                      ? 'Big Area Deal'
                                      : item.dealType === 'Area deal'
                                      ? 'Area Deal'
                                      : item.dealType === 'Big deal'
                                      ? 'Big Deal'
                                      : 'Original Deal'
                                  }
                                  side={localStorage.getItem('side') === 'Top Manager' ? 'ManagerDeal' : 'SupplierDeal'}
                                  progress={false}
                                  index={index}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {filterOption === 'History' && (
                          <div className="row px-0 mx-0 ">
                            {originalDeals.map((item, index) => (
                              <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                <VoucherCard
                                  id={item._id}
                                  data={[
                                    {
                                      title: 'Deal Name',
                                      value: item.title_en,
                                    },
                                    {
                                      title: 'Deal Code',
                                      value: item.uuid,
                                    },
                                    {
                                      title: 'Product Name',
                                      value: item.product || item.productName,
                                    },
                                    {
                                      title: 'Supplier Name',
                                      value: data.name_en,
                                    },
                                  ]}
                                  type="History"
                                  status={true}
                                  statusValue={
                                    item.dealType === 'Big area deal'
                                      ? 'Big Area Deal'
                                      : item.dealType === 'Area deal'
                                      ? 'Area Deal'
                                      : item.dealType === 'Big deal'
                                      ? 'Big Deal'
                                      : 'Original Deal'
                                  }
                                  side={localStorage.getItem('side') === 'Top Manager' ? 'ManagerDeal' : 'SupplierDeal'}
                                  progress={false}
                                  index={index}
                                  po={item.purchasingOrder && item.purchasingOrder}
                                  invoice={item.invoice && item.invoice}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              ))}

            {option === 'Cold Deals' &&
              (loading2 ? (
                <div className="row px-0 mx-0">
                  {[0, 1, 2, 3, 4, 5].map((item, index) => (
                    <div key={index} className={`col-xl-4 col-lg-6 px-0 pe-lg-3`}>
                      <Skeleton
                        variant="rounded"
                        sx={{
                          borderRaduis: '20px',
                          fontSize: '2rem',
                          width: '100%',
                          height: '400px',
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="row px-0 mx-0">
                    {coldDeals.length === 0 ? (
                      <div className="w-100 row justify-content-center pt-5 pb-4 ">
                        <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Deals Found</div>
                      </div>
                    ) : (
                      <>
                        {filterOption === 'On Going' && (
                          <div className="row px-0 mx-0 ">
                            {coldDeals.map((item, index) => (
                              <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                <VoucherCard
                                  id={item._id}
                                  data={[
                                    {
                                      title: 'Deal Name',
                                      value: item.title_en,
                                    },
                                    {
                                      title: 'Deal Code',
                                      value: item.uuid,
                                    },
                                    {
                                      title: 'Product Name',
                                      value: item.product || item.productName,
                                    },
                                    {
                                      title: 'Current Supplier',
                                      value: data.name_en,
                                    },

                                    {
                                      title: 'Time Left',
                                    },
                                  ]}
                                  type="On Going"
                                  side={localStorage.getItem('side') === 'Top Manager' ? 'ManagerColdDeal' : 'SupplierColdDeal'}
                                  timeLeft={item.endDate}
                                  status={true}
                                  statusValue={
                                    item.dealType === 'Big area deal'
                                      ? 'Big Area Deal'
                                      : item.dealType === 'Area deal'
                                      ? 'Area Deal'
                                      : item.dealType === 'Big deal'
                                      ? 'Big Deal'
                                      : 'Cold Deal'
                                  }
                                  progress={true}
                                  index={index}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {filterOption === 'History' && (
                          <div className="row px-0 mx-0 ">
                            {coldDeals.map((item, index) => (
                              <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                <VoucherCard
                                  id={item._id}
                                  data={[
                                    {
                                      title: 'Deal Name',
                                      value: item.title_en,
                                    },
                                    {
                                      title: 'Deal Code',
                                      value: item.uuid,
                                    },
                                    {
                                      title: 'Product Name',
                                      value: item.product || item.productName,
                                    },
                                    {
                                      title: 'Supplier Name',
                                      value: data.name_en,
                                    },
                                  ]}
                                  type="History"
                                  status={true}
                                  statusValue={
                                    item.dealType === 'Big area deal'
                                      ? 'Big Area Deal'
                                      : item.dealType === 'Area deal'
                                      ? 'Area Deal'
                                      : item.dealType === 'Big deal'
                                      ? 'Big Deal'
                                      : 'Cold Deal'
                                  }
                                  side={localStorage.getItem('side') === 'Top Manager' ? 'ManagerColdDeal' : 'SupplierColdDeal'}
                                  progress={false}
                                  index={index}
                                  po={item.purchasingOrder && item.purchasingOrder}
                                  invoice={item.invoice && item.invoice}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              ))}

            {option === 'Vouchers' &&
              (loading3 ? (
                <div className="row px-0 mx-0">
                  {[0, 1, 2, 3, 4, 5].map((item, index) => (
                    <div key={index} className={`col-xl-4 col-lg-6 px-0 pe-lg-3`}>
                      <Skeleton
                        variant="rounded"
                        sx={{
                          borderRaduis: '20px',
                          fontSize: '2rem',
                          width: '100%',
                          height: '400px',
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="row px-0 mx-0">
                    {vouchers.length === 0 ? (
                      <div className="w-100 row justify-content-center pt-5 pb-4 ">
                        <div className="no-found-data mx-auto w-auto  text-center w-100 ">There are No Vouchers Found</div>
                      </div>
                    ) : (
                      <>
                        {filterOption === 'On Going' && (
                          <>
                            <div className="row px-0 mx-0 ">
                              {vouchers.map((item, index) => (
                                <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                  <VoucherCard
                                    id={item._id}
                                    data={[
                                      {
                                        title: 'Voucher Name',
                                        value: item.title_en,
                                      },
                                      {
                                        title: 'Voucher Code',
                                        value: item.uuid,
                                      },
                                      {
                                        title: 'Supplier Name',
                                        value: data.name_en,
                                      },
                                      {
                                        title: 'Voucher Time',
                                      },
                                      {
                                        title: 'Voucher Value',
                                        value: item.voucherPrice,
                                      },
                                      {
                                        title: 'Starting Date',
                                        value: new Date(item.startDate).toLocaleDateString,
                                      },

                                      {
                                        title: 'Progress',
                                        value: item.sold,
                                        total: item.quantity,
                                      },
                                    ]}
                                    type="On Going"
                                    side={localStorage.getItem('side') === 'Top Manager' ? 'Manager' : 'Supplier'}
                                    timeLeft={item.endDate}
                                    progress={true}
                                    index={index}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                        {filterOption === 'Responses' && (
                          <>
                            <div className="row px-0 mx-0">
                              {vouchers.map((item, index) => (
                                <div key={index} className={`col-xl-4 col-lg-6 px-0`}>
                                  <VoucherCard
                                    id={item._id}
                                    data={[
                                      {
                                        title: 'Voucher Name',
                                        value: item.title_en,
                                      },
                                      {
                                        title: 'Voucher Code',
                                        value: item.uuid,
                                      },
                                      {
                                        title: 'Supplier Name',
                                        value: data.name_en,
                                      },
                                      {
                                        title: 'Voucher Value',
                                        value: item.voucherPrice,
                                      },
                                      {
                                        title: 'Request Date',
                                        value: item?.createdAt?.slice(0, 10),
                                      },
                                      {
                                        title: 'Catgory',
                                        // value: item.categories,
                                        // list: true,
                                      },
                                    ]}
                                    type="Responses"
                                    side={localStorage.getItem('side') === 'Top Manager' ? 'Manager' : 'Supplier'}
                                    progerss={false}
                                    status={true}
                                    statusValue={item.status}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                        {filterOption === 'Scheduled' && (
                          <>
                            <div className="row px-0 mx-0">
                              {vouchers.map((item, index) => (
                                <div key={index} className={`col-xl-4 col-lg-6 px-0`}>
                                  <VoucherCard
                                    id={item._id}
                                    data={[
                                      {
                                        title: 'Voucher Name',
                                        value: item.title_en,
                                      },
                                      {
                                        title: 'Voucher Code',
                                        value: item.uuid,
                                      },
                                      {
                                        title: 'Supplier Name',
                                        value: data.name_en,
                                      },

                                      {
                                        title: 'Start At',
                                        value: item?.startDate?.slice(0, 10),
                                      },
                                      {
                                        title: 'End At',
                                        value: item?.endDate?.slice(0, 10),
                                      },
                                    ]}
                                    type="Scheduled"
                                    side={localStorage.getItem('side') === 'Top Manager' ? 'Manager' : 'Supplier'}
                                    progerss={false}
                                    status={true}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                        {filterOption === 'Requests' && (
                          <>
                            <div className="row px-0 mx-0">
                              {vouchers.map((item, index) => (
                                <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                                  <VoucherCard
                                    id={item._id}
                                    data={[
                                      {
                                        title: 'عنوان العرض',
                                        value: item.title_ar,
                                      },
                                      {
                                        title: 'Voucher Name',
                                        value: item.title_en,
                                      },
                                      {
                                        title: 'Voucher Code',
                                        value: item.uuid,
                                      },
                                      {
                                        title: 'Voucher Value',
                                        value: item.voucherPrice,
                                      },

                                      {
                                        title: 'Quantity',
                                        value: item.quantity,
                                      },
                                      {
                                        title: 'Expiry Date',
                                        value: item.expireDate,
                                      },
                                    ]}
                                    type="Requests"
                                    side={localStorage.getItem('side') === 'Top Manager' ? 'Manager' : 'Supplier'}
                                    index={index}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                        {filterOption === 'History' && (
                          <>
                            <div className="row px-0 mx-0">
                              {vouchers.map((item, index) => (
                                <div key={index} className={`col-xl-4 col-lg-6 px-0`}>
                                  <VoucherCard
                                    type="History"
                                    id={item._id}
                                    data={[
                                      {
                                        title: 'Voucher Name',
                                        value: item.title_en,
                                      },
                                      {
                                        title: 'Voucher Code',
                                        value: item.uuid,
                                      },
                                      {
                                        title: 'Supplier Name',
                                        value: data.name_en,
                                      },
                                      {
                                        title: 'Expiry Date',
                                        value: item?.expireDate?.slice(0, 10),
                                      },
                                      {
                                        title: 'Voucher Value',
                                        value: item.voucherPrice,
                                      },
                                    ]}
                                    po={item.purchasingOrder && item.purchasingOrder}
                                    invoice={item.invoice && item.invoice}
                                    side={localStorage.getItem('side') === 'Top Manager' ? 'Manager' : 'Supplier'}
                                    progerss={false}
                                    status={false}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              ))}
            {totalPages > 1 && (
              <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                <div className="w-auto d-inline mx-auto">
                  <UsePagination
                    page={page}
                    pages={totalPages}
                    onChangePage={(page) => {
                      option === 'Original Deals' && setLoading1(true);
                      option === 'Cold Deals' && setLoading2(true);
                      option === 'Vouchers' && setLoading3(true);
                      setPage(page);
                    }}
                  />
                </div>
              </div>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
//Done
