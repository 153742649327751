import React, { useState } from 'react';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import Cross from '../../components/Reusable/SVG/Cross';
import Edit from '../../components/Reusable/SVG/Edit';
import Eye2 from '../../components/Reusable/SVG/Eye2';
import FilterDots from '../../components/Reusable/SVG/FilterDots';
import classes from './Cashier.module.scss';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import ManagerHeader from '../../components/Reusable/Common/MemberCard/ManagerHeader';
import Img from '../../components/Reusable/Common/Img';
import { useNavigate, useParams } from 'react-router-dom';
import { managerAccountsService } from '../../services/managerAccountsService';
import { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import pdf from '../../assests/pdf.png';
import { managerService } from '../../services/manager';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import PeopleActiveL from '../../components/Reusable/SVG/PeopleActiveL';
import { cashierService } from '../../services/CashierService';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function CashierDetails(props) {
  const history = useNavigate();
  const [option, setOption] = useState('Data');
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [close, setClose] = useState(true);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [active, setActive] = useState('true');

  let getCashier = async () => {
    let response = await cashierService.getSingleCashier(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setActive(response.data.cashier.activated);
      setData(response.data.cashier);
      setLoading(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  let handleAcctivateCashier = async () => {
    let response = await cashierService.toggleCashierActivation(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setShowConfirmation(true);
      setActive((prev) => !prev);
      setClose(false);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  useEffect(() => {
    getCashier();
  }, []);

  return (
    <div className={`w-100 ${classes['cashier']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={`Cashier ${active ? 'activated' : 'de-activated'} successfully`} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle text={`Cashier Profile`} icon={<PeopleActiveL />} />
        </div>
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <ManagerHeader loading={true} />
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <div className="row px-0 mx-0">
                <div className="w-auto d-inline">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '150px' }} />
                </div>
                <div className="w-auto d-inline">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '150px' }} />
                </div>
              </div>
            </div>

            <>
              {[0, 1, 2, 3].map((item, index) => (
                <div key={index} className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-6 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="rounded" sx={{ height: '5rem', width: '250px' }} />
                      </span>
                    </div>
                  </div>
                  <div dir="auto" className="col-6 px-0">
                    <div className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="rounded" sx={{ height: '5rem', width: '250px' }} />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </section>
        ) : (
          <section className={`${classes['main-section']}`}>
            <ManagerHeader
              type={'cashier'}
              profilePicture={data.pic}
              closeOption={close}
              setClose={() => setClose(true)}
              data={{
                name: data.name,
                // categories: data.categories,
              }}
              optionComponent={
                <>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      history(`/supplier/cashiers/${id}/edit`);
                    }}
                    className={`row mx-0 ${localStorage.getItem('side') === 'Top Manager' && 'mb-2'} mx-0`}
                  >
                    <div className="w-auto d-inline">
                      <Edit color="#6E7980" />
                    </div>
                    &nbsp; Edit Cashier Profile
                  </div>
                  {localStorage.getItem('side') === 'Supplier' && (
                    <div onClick={handleAcctivateCashier} className="row mx-0 mx-0">
                      <div className="w-auto d-inline">
                        <Cross />
                      </div>
                      &nbsp; {active ? 'Deactive' : 'Activate'} Cashier
                    </div>
                  )}
                </>
              }
            />

            {option === 'Data' && (
              <>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-6 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Phone Number</p>
                      <span>{data.phone}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-6 px-0">
                    <div className={classes['single-item']}>
                      <p>Email Address</p>
                      <span>{data.email}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-6 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Branch Title</p>
                      <span>{data.branchTitle}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-6 px-0">
                    <div className={classes['single-item']}>
                      <p>National ID</p>
                      <span>{data.nationalId}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Address</p>
                      <span>{data.address}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
