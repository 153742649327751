import React from 'react';
import Label from '../Label/Label';
import InputFeild from '../InputFeild/InputFeild';
import classes from './InputGroup.module.scss';
import DropDownFeild from '../InputFeild/DropDownFeild';
export default function InputGroup(props) {
  return (
    <div dir="auto" className="row px-0 ">
      <div className="row px-0 mx-0 ">
        <div className="w-auto  d-inline">
          <Label for={props.name} filter={props.filter} value={props.value} />
        </div>
        {props.linkText && (
          <div
            style={{ textDecoration: 'underline' }}
            onClick={() => props.linkText.fn()}
            className={`w-auto d-inline cursor text-end ms-auto ${classes.gray}`}
          >
            {props.linkText.text}
          </div>
        )}
      </div>
      <div className="col-12 ">
        {props.type === 'dropdown' ? (
          <>
            <DropDownFeild
              district={props.district}
              selected={props.selected}
              city={props.city}
              filter={props.filter}
              disable={props.disable}
              options={props.options}
              onClick={props.onClick}
              onChange={(e) => props.onChange(e)}
              type={props.type}
              default={props.default}
              error={props.error}
              name={props.name}
              placeholder={props.value}
              loading={props.loading}
              pagination={props.pagination}
            />
          </>
        ) : (
          <InputFeild
            disable={props.disable}
            handleClear={props.handleClear}
            clear={props.clear}
            default={props.default}
            onChange={(e) => props.onChange(e)}
            type={props.type}
            className={props.className}
            error={props.error}
            //   error={props.showErr && !props.fieldErr && true}
            name={props.name}
            placeholder={props.value}
          />
        )}
      </div>
      {props.underLineText && (
        <span className={`${classes.underLineText}  ${props.underLineTextNoWrap && 'text-nowrap'} px-0  ps-3 `}>{props.underLineText}</span>
      )}
      {props.error && (
        <span className={`errorMsg ${props.noWrap && 'text-nowrap'} ${props.underLineText && 'mt-0 pt-0'}  ps-3`}>{props.errorMsg}</span>
      )}
    </div>
  );
}
