import { useEffect, useState } from 'react';

import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import DocsActiveL from '../../components/Reusable/SVG/DocsActiveL';
import Section from '../../components/Reusable/Common/Section/Section';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import AddSection from '../../components/Reusable/Common/AddMemberCard/AddSection';
import classes from './About.module.scss';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import Edit from '../../components/Reusable/SVG/Edit';
import Recycle from '../../components/Reusable/SVG/Recycle';
import RemoveCard from '../../components/Reusable/Common/AddMemberCard/RemoveMember';
import { aboutService } from '../../services/aboutService';
import Plus from '../../components/Reusable/SVG/Plus';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { Skeleton } from '@mui/material';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';

export default function About() {
  const [option, setOption] = useState('Eshtarena');
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();

  let getAbout = async () => {
    let response =
      (option === 'Eshtarena' && (await aboutService.getAllAbout('eshtarena'))) ||
      (option === 'Original Deal' && (await aboutService.getAllAbout('original_deal'))) ||
      (option === 'Cold Deal' && (await aboutService.getAllAbout('cold_deal'))) ||
      (option === 'Vouchers Deal' && (await aboutService.getAllAbout('voucher'))) ||
      (option === 'Big Deal' && (await aboutService.getAllAbout('big_deal')));
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setData(response.data.about);
    }
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    getAbout();
  }, [option]);
  let handleDelete = async (id) => {
    let response = await aboutService.deleteAbout(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setConfirmationMsg(`Section Deleted Successfully`);
      setShowConfirmation(true);
      setShowDeleteForm(false);
      setLoading(true);
      getAbout();
      setLoading(false);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };
  return (
    //  TODO: remove title input from modal
    <div className={`w-100 ${classes['about-page']}`}>
      {serverError && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showConfirmation && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup message1={confirmationMsg} />
        </Modal>
      )}
      {(showAddForm || showEditForm) && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            showAddForm && setShowAddForm(false);
            showEditForm && setShowEditForm(false);
          }}
        >
          <AddSection
            add={showAddForm}
            edit={showEditForm}
            formType={'About'}
            option={option}
            onSuccess={() => {
              setConfirmationMsg(`Section ${showAddForm ? 'Added' : 'Edited'} Successfully`);
              setShowConfirmation(true);
              setShowAddForm(false);
              setShowEditForm(false);
              setLoading(true);
              getAbout();
              setLoading(false);
              setTimeout(() => {
                setShowConfirmation(false);
              }, 2000);
            }}
            about={showEditForm && editData}
          />
        </Modal>
      )}

      {showDeleteForm && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowDeleteForm(false);
          }}
        >
          <RemoveCard
            onClick={() => handleDelete(editData._id)}
            setShowForm={setShowDeleteForm}
            message1="Are you sure you want to remove this section?"
            message2="By removing this section, it won’t show on the about anymore."
            buttonText="Remove Section"
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          text="About"
          onClick={() => {
            setShowAddForm(true);
          }}
          icon={<DocsActiveL />}
          buttonText={`Add About`}
          buttonType="normal"
          buttonIcon={<Plus />}
        />
        <section className={`${classes['main-section']}`}>
          {/* tabs */}
          <div className={`row px-0 mx-0   ${classes.options}`}>
            <SelectOption
              onSelect={(val) => setOption(val)}
              default={option}
              options={['Eshtarena', 'Original Deal', 'Cold Deal', 'Vouchers Deal', 'Big Deal']}
            />
          </div>

          {loading ? (
            [0, 1, 2].map((item, index) => (
              <div key={index} className="row px-0 mx-0 mb-3">
                <div className="col-5 px-0">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />

                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </div>

                {/* arabic section */}
                <div className="col-5 px-0  ms-auto">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />

                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </div>
              </div>
            ))
          ) : data.length > 0 ? (
            data.map((item, index) => (
              <div key={index} className="row px-0 mx-0">
                {/* english section */}
                <div className="col-5 px-0">
                  <Section text={item.content_en} />
                </div>

                {/* settings */}
                <div className="col-2 justify-content-center">
                  <div className="row px-0 mx-0 mt-4 pt-2">
                    <div
                      onClick={() => {
                        setEditData(item);
                        setShowEditForm(true);
                      }}
                      className="w-auto cursor mx-auto"
                    >
                      <Edit />
                    </div>
                  </div>
                  <div className="row px-0 mx-0 mt-3">
                    <div
                      onClick={() => {
                        setEditData(item);
                        setShowDeleteForm(true);
                      }}
                      className="w-auto cursor mx-auto"
                    >
                      <Recycle />
                    </div>
                  </div>
                </div>

                {/* arabic section */}
                <div className="col-5 px-0  ms-auto">
                  <Section text={item.content_ar} />
                </div>

                {/* <div className="col-6 px-0"></div> */}
              </div>
            ))
          ) : (
            <div className="w-100 row justify-content-center pt-5 pb-4">
              <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Data Found</div>
            </div>
          )}
        </section>
      </section>
    </div>
  );
}
