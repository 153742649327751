import React, { useEffect } from 'react';
import classes from './AdAdvise.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import TextAreaGroup from '../../components/Reusable/UI/InputGroup/TextAreaGroup';
import Button from '../../components/Reusable/UI/Button/Button';
import AdActiveL from '../../components/Reusable/SVG/AdActiveL';
import AdviceActiveL from '../../components/Reusable/SVG/AdviceActiveL';
import UploadImageGroup from '../../components/Reusable/UI/InputGroup/UploadImageGroup';
import { useState } from 'react';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { Fab, Skeleton } from '@mui/material';
import Close from '../../components/Reusable/SVG/Close';
import { adsService } from '../../services/adsService';
import { adviceService } from '../../services/adviceService';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function AdandAdviseForm(props) {
  const arabic = /[\u0600-\u06FF]/;
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [SubCategoriesName, setSubCategoriesName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedCategoryFinal, setSelectedCategoryFinal] = useState([]);
  const [selectedSubCategoryFinal, setSelectedSubCategoryFinal] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [data, setData] = useState({
    title_EN: null,
    title_AR: null,
    link: null,
    startDate: null,
    endDate: null,
    media: null,
    advice_EN: null,
    advice_AR: null,
  });
  const [dataErr, setDataErr] = useState({
    title_EN: false,
    title_AR: false,
    link: false,
    startDate: false,
    endDate: false,
    media: false,
    advice_EN: false,
    advice_Ar: false,
  });

  let getAd = async () => {
    let response = props.type === 'Ad' ? await adsService.getSingleAds(id) : await adviceService.getSingleAdvice(id);

    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      if (props.type === 'Ad') {
        setData({
          title_EN: response.data.ads.title_en,
          title_AR: response.data.ads.title_ar,
          link: response.data.ads.link,
          startDate: response.data.ads.startDate,
          endDate: response.data.ads.endDate,
          media: response.data.ads.pic,
        });
        setDataErr({
          title_EN: true,
          title_AR: true,
          link: true,
          startDate: true,
          endDate: true,
          media: true,
          advice_EN: true,
          advice_Ar: true,
        });
        setSelectedCategoryFinal(response.data.ads.categories);
        setSelectedSubCategoryFinal(response.data.ads.subCategories);
        let arr = response.data.ads.subCategories.map((item) => item.item.name_en);
        setSelectedSubCategory(arr);
        setLoading(false);
      } else {
        setData({
          title_EN: response.data.advice.title_en,
          title_AR: response.data.advice.title_ar,
          link: response.data.advice.link,
          startDate: response.data.advice.startDate,
          endDate: response.data.advice.endDate,
          media: response.data.advice.pic,
          advice_EN: response.data.advice.advice_en,
          advice_AR: response.data.advice.advice_ar,
        });
        setDataErr({
          title_EN: true,
          title_AR: true,
          link: true,
          startDate: true,
          endDate: true,
          media: true,
          advice_EN: true,
          advice_AR: true,
        });
        setSelectedCategoryFinal(response.data.advice.categories);
        setSelectedSubCategoryFinal(response.data.advice.subCategories);
        let arr = response.data.advice.subCategories.map((item) => item.item.name_en);
        setSelectedSubCategory(arr);
        setLoading(false);
      }
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    id && getAd();
  }, [id]);
  let handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    e.target.value.replace(/\s/g, '').length === 0
      ? setDataErr((prev) => ({ ...prev, [e.target.name]: false }))
      : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
    if (e.target.name === 'title_EN' || e.target.name === 'title_AR') {
      if (e.target.value && !(e.target.value[0].toLowerCase() !== e.target.value[0].toUpperCase())) {
        if (!arabic.test(e.target.value[0])) {
          if (isNaN(e.target.value[0])) {
            setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
          }
        }
      }
    }
    if (e.target.name === 'startDate') {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000) setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      if (data.endDate)
        if (new Date(e.target.value) > new Date(data.endDate)) {
          setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
        } else {
          if (new Date(data.endDate) > new Date(e.target.value)) setDataErr((prev) => ({ ...prev, endDate: true }));
        }
    } else if (e.target.name === 'endDate') {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000) setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      if (data.startDate) {
        if (new Date(e.target.value) < new Date(data.startDate)) {
          setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
        } else {
          if (new Date(data.startDate) > Date.now() - 1 * 24 * 60 * 60 * 1000) setDataErr((prev) => ({ ...prev, startDate: true }));
        }
      }
    } else {
    }
  };
  let handleSubmit = async () => {
    setShowError(true);
    if (props.type === 'Ad') {
      if (
        dataErr.title_EN &&
        dataErr.title_AR &&
        dataErr.endDate &&
        dataErr.link &&
        dataErr.media &&
        dataErr.startDate &&
        selectedSubCategoryFinal.length > 0
      ) {
        let arr1 = [];
        let arr2 = [];
        arr1 = selectedCategoryFinal.map((item) => ({
          item: item.item ? item.item._id : item._id,
        }));
        arr2 = selectedSubCategoryFinal.map((item) => ({
          item: item.item ? item.item._id : item._id,
        }));
        let fd = new FormData();
        fd.append('title_en', data.title_EN);
        fd.append('title_ar', data.title_AR);
        fd.append('startDate', data.startDate);
        fd.append('endDate', data.endDate);
        fd.append('link', data.link);
        data.media.name && fd.append('pic', data.media, data.media.name);
        fd.append('categories[]', JSON.stringify(arr1));
        fd.append('subCategories[]', JSON.stringify(arr2));
        let response = id ? await adsService.updateAd(fd, id) : await adsService.addAd(fd);

        let Valid = false;
        Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

        if (Valid === true) {
          setShowConfirmation(true);
          setTimeout(() => {
            setShowConfirmation(false);
            history(localStorage.getItem('side') === 'Top Manager' ? `/account/marketing-manager` : `/account/ad`, {
              replace: true,
              state: { openTap: 'Ads' },
            });
          }, 2000);
        } else {
          dispatch(
            errorActions.setError({
              error: true,
              errorMsg: localStorage.getItem('errorMsg'),
            }),
          );
        }
      }
    } else {
      if (
        dataErr.title_EN &&
        dataErr.title_AR &&
        dataErr.endDate &&
        dataErr.advice_EN &&
        dataErr.advice_AR &&
        dataErr.media &&
        dataErr.startDate &&
        selectedSubCategoryFinal.length > 0
      ) {
        let arr1 = [];
        let arr2 = [];
        arr1 = selectedCategoryFinal.map((item) => ({
          item: item.item ? item.item._id : item._id,
        }));
        arr2 = selectedSubCategoryFinal.map((item) => ({
          item: item.item ? item.item._id : item._id,
        }));
        let fd = new FormData();
        fd.append('title_en', data.title_EN);
        fd.append('title_ar', data.title_AR);
        fd.append('startDate', data.startDate);
        fd.append('endDate', data.endDate);
        fd.append('advice_en', data.advice_EN);
        fd.append('advice_ar', data.advice_AR);
        data.media.name && fd.append('pic', data.media, data.media.name);
        fd.append('categories[]', JSON.stringify(arr1));
        fd.append('subCategories[]', JSON.stringify(arr2));
        let response = id ? await adviceService.updateAdvice(fd, id) : await adviceService.addAdvice(fd);

        let Valid = false;
        Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

        if (Valid === true) {
          setShowConfirmation(true);
          setTimeout(() => {
            setShowConfirmation(false);
            history(localStorage.getItem('side') === 'Top Manager' ? `/account/marketing-manager` : `/account/advice`, {
              replace: true,
              state: { openTap: 'Advice' },
            });
          }, 2000);
        } else {
          dispatch(
            errorActions.setError({
              error: true,
              errorMsg: localStorage.getItem('errorMsg'),
            }),
          );
        }
      }
    }
    setLoadingButton(false);
  };

  useEffect(() => {
    mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
  }, []);
  useEffect(() => {
    setSubCategoriesName([]);
    selectedCategory &&
      mainDataHelper.getAllSubCategories(setServerError, setServerErrorMsg, selectedCategory, categories, setSubCategories, setSubCategoriesName);
  }, [selectedCategory]);
  return (
    <div className={`w-100 ${classes['form-page']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={`the ${props.type} is ${id ? 'edited' : 'Added'} successfully`} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle
            text={`${id ? 'Edit' : 'Add New'} ${props.type}`}
            icon={
              props.type === 'Ad' ? (
                <div className="w-auto  d-inline">
                  <AdActiveL />
                </div>
              ) : (
                <div className="w-auto d-inline">
                  <AdviceActiveL />
                </div>
              )
            }
          />
        </div>
        {loading && id
          ? [0, 1, 2, 3, 4].map((item, index) => (
              <div key={index} className="row px-0 mx-0">
                <div className="col-lg-6 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '20%' }} />
                    <Skeleton variant="text" sx={{ height: '4rem', width: '90%' }} />
                  </div>
                </div>
                <div className="col-lg-6 px-0 ps-lg-2">
                  <div className={classes.input}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '20%' }} />
                    <Skeleton variant="text" sx={{ height: '4rem', width: '90%' }} />
                  </div>
                </div>
              </div>
            ))
          : ((id && !loading) || !id) && (
              <section className={`${classes['main-section']}`}>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.title_EN}
                        name="title_EN"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.title_EN}
                        errorMsg={data.title_EN ? 'Provide a Valid Title must not start with special Charcter' : 'Provide a Title'}
                        value={`${props.type} title`}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.title_AR}
                        name="title_AR"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.title_AR}
                        errorMsg={data.title_AR ? 'من فضلك سجل عنوان صحيح .يجب  يبدأ باحرف وارقام فقط' : 'من فضلك سجل العنوان'}
                        value={'العنوان'}
                      />
                    </div>
                  </div>
                </div>
                {props.type === 'Ad' ? (
                  <div className="row px-0 mx-0">
                    <div className="col-12 px-0 ">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.link}
                          name="link"
                          onChange={(e) => handleChange(e)}
                          error={showError && !dataErr.link}
                          errorMsg="Provide an Ad Link"
                          value={'Ad Link'}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row px-0 mx-0">
                    <div className="col-lg-6 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <TextAreaGroup
                          default={data.advice_EN}
                          name="advice_EN"
                          errorMsg="Provide an Advice Content"
                          onChange={(e) => handleChange(e)}
                          error={showError && !dataErr.advice_EN}
                          value={'Advice'}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 px-0 ps-lg-2">
                      <div className={classes.input}>
                        <TextAreaGroup
                          default={data.advice_AR}
                          name="advice_AR"
                          onChange={(e) => handleChange(e)}
                          error={showError && !dataErr.advice_AR}
                          errorMsg="من فضلك سجل النصيحة"
                          value={'النصيحة'}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        type="dropdown"
                        default={selectedCategory}
                        options={['select Category', ...categoriesName]}
                        error={showError && selectedSubCategoryFinal.length === 0}
                        errorMsg="Please Select Category & Sub Category"
                        name="category"
                        onChange={(e) => {
                          if (e.target.value !== 'select Category') setSelectedCategory(e.target.value);
                          else setSubCategoriesName([]);
                        }}
                        value={'Category'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        type="dropdown"
                        options={['select sub category', ...SubCategoriesName]}
                        error={showError && selectedSubCategoryFinal.length === 0}
                        onChange={(e) => {
                          if (e.target.value !== 'select sub category' && !selectedSubCategory.includes(e.target.value)) {
                            categories.map((item) => {
                              if (item.name_en === selectedCategory) setSelectedCategoryFinal((prev) => [...prev, item]);
                            });

                            setSelectedSubCategory((prev) => [...prev, e.target.value]);
                            subCategories.map((item) => {
                              if (item.name_en === e.target.value) {
                                setSelectedSubCategoryFinal((prev) => [...prev, item]);
                              }
                            });
                          }
                        }}
                        name="subCategory"
                        value={'Sub Category'}
                      />
                    </div>
                  </div>
                  <div className="row px-0 mx-0 mb-2 ">
                    {selectedSubCategory.map((item, index) => (
                      <div key={index} className="w-auto d-inline mt-2 pe-lg-2 position-relative">
                        <div
                          onClick={() => {
                            let arr = [];
                            let arr1 = [];

                            arr = selectedSubCategory.filter((item1) => {
                              if (item1.toLowerCase() !== item.toLowerCase()) return item1;
                            });

                            arr1 = selectedSubCategoryFinal.filter((item1) => {
                              if (item1.item && item1.item.name_en.toLowerCase() !== item.toLowerCase()) return item1;
                              else if (item1.name_en && item1.name_en.toLowerCase() !== item.toLowerCase()) return item1;
                            });
                            setSelectedSubCategoryFinal(arr1);
                            if (arr.length > 0) setSelectedSubCategory(arr);
                            else setSelectedSubCategory([]);
                          }}
                          style={{
                            right: '10px',
                            zIndex: 2,
                          }}
                          className="text-end  cursor  h-100 align-items-center d-flex position-absolute"
                        >
                          <Close />
                        </div>
                        <Fab style={{ zIndex: 1 }} variant="extended" className=" pe-5">
                          {item}
                        </Fab>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        type="date"
                        default={data.startDate}
                        name="startDate"
                        errorMsg={data.startDate ? 'Provide a Valid Start Date' : 'Provide a Start Date'}
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.startDate}
                        value={'Start Date'}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        type="date"
                        default={data.endDate}
                        name="endDate"
                        errorMsg={data.endDate ? 'Provide a Valid End Date' : 'Provide a End Date'}
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.endDate}
                        value={'End Date'}
                      />
                    </div>
                  </div>
                </div>
                {
                  <div className="row px-0 mx-0">
                    <div className="col-12  ">
                      <div className={classes.input}>
                        <UploadImageGroup
                          default={data.media}
                          video={props.type === 'Ad' ? true : false}
                          name="media"
                          type={props.type === 'Ad' ? 'ads' : 'advice'}
                          onChange={(e) => {
                            setData((prev) => ({
                              ...prev,
                              media: e.target.files[0],
                            }));
                            setDataErr((prev) => ({ ...prev, media: true }));
                          }}
                          onRemove={(e) => {
                            setData((prev) => ({
                              ...prev,
                              media: null,
                            }));
                            setDataErr((prev) => ({ ...prev, media: false }));
                          }}
                          errorMsg={props.type === 'Ad' ? 'Provide an Image / Video' : 'Provide an Image'}
                          error={showError && !dataErr.media}
                          value={props.type === 'Ad' ? 'Ad Image / Video' : 'Advice Image'}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="row mx-0 px-0  mb-pb-0">
                  <div className="col-4 ms-auto px-0  mb-pb-0">
                    <div className={`${classes.input}  mb-pb-0`}>
                      {loadingButton ? (
                        <div className="text-center">
                          <span className="spinner-border spinner-border-sm"></span>
                        </div>
                      ) : (
                        <Button
                          onClick={() => {
                            setLoadingButton(true);
                            handleSubmit(true);
                          }}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          className={'w-auto px-5  ms-auto text-end'}
                          type="normal"
                          text={'Done'}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            )}
      </section>
    </div>
  );
}
