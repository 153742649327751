import { Policy } from "@mui/icons-material";
import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const managerAccountsService = {
  getAllManagers,
  addNewManagers,
  updateManager,
  getSingleManagerData,
  getSingleManagerMedia,
  toggleManagerActivation,
};

async function addNewManagers(type, data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/accounts/new-${type}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function updateManager(type, data, id) {
  try {
    var config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/accounts/${type}/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getAllManagers(type, page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/top-manger/${type}?page=${page}&size=${size}${query ? query : ""}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSingleManagerData(type, id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/top-manger/${type}-data/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSingleManagerMedia(type, id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/top-manger/${type}-media/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function toggleManagerActivation(type, id) {
  try {
    var config = {
      method: "Patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/top-manger/${type}/activation/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}

//---------------------------------------

// async function editManager(type, id, data) {
//   try {
//     var config = {
//       method: "put",
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_BASE_URL}/accounts/new-${type}/id`,
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//       data,
//     };
//     let response = await axios(config);
//     return response;
//   } catch (e) {
//     return e;
//   }
// }
// async function getAllManagers(type) {
//   try {
//     var config = {
//       method: "get",
//       maxBodyLength: Infinity,
//       url: `${process.env.REACT_APP_API_BASE_URL}/${type}/data`,
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     };
//     let response = await axios(config);
//     return response;
//   } catch (e) {
//     return e;
//   }
// }
