import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const cashierService = {
  createCashier,
  deleteCashier,
  getCashiers,
  getMyCashierProfile,
  getSingleCashier,
  getSingleVoucherOrder,
  toggleCashierActivation,
  updateCashier,
  useVoucher,
};

async function createCashier(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/new-cashier`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function updateCashier(data, id) {
  try {
    var config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/cashiers/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function deleteCashier(id) {
  try {
    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/cashiers/:id`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      //   data
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getCashiers(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/supplier/cashiers?page=${page}&size=${size}${query ? query : " "}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      //   data
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSingleCashier(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/cashiers/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      //   data
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getMyCashierProfile() {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/cashier`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      //   data
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function toggleCashierActivation(id) {
  try {
    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/supplier/cashiers/activation/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      //   data
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getSingleVoucherOrder(id) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/order/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      //   data
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function useVoucher(data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/voucher/use-voucher`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
