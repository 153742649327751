import React from 'react';
import classes from './PageTitle.module.scss';
import Button from '../../UI/Button/Button';
export default function PageTitle(props) {
  return (
    <div className={`${classes['Page-title']} row mx-0  py-0   text-wrap`}>
      <div className=" d-flex align-items-center row mx-0 p-0">
        <div className="col-xl-6 col-lg-8  p-0 align-items-center  text-start">
          <div className="row mx-0 d-flex align-items-center text-nowrap  p-0">
            {/* <div className="d-inline w-auto">{props.text}</div> */}
            <div className={`d-inline w-auto px-0  `}>
              {props.icon && (
                <div className="w-auto d-inline " style={{ color: '#D1E6FF' }}>
                  {props.icon}
                </div>
              )}
            </div>
            <div className={`d-inline w-auto px-0 `}>&nbsp; {props.text}</div>
          </div>
        </div>
        {props.buttonText && (
          <div className="col-xl-6 col-lg-4   mt-lg-0 mt-3 px-0 text-end">
            <div className="row mx-0 px-0 text-end ">
              <div className="  text-end px-0 col-12 ">
                <Button
                  onClick={props.onClick}
                  icon={props.buttonIcon}
                  className={'w-lg-auto   px-2 ms-auto text-end'}
                  type={'normal'}
                  text={props.buttonText}
                  color={props.color || '#D1E6FF'}
                  borderColor={props.borderColor || '#134074'}
                  fontColor={props.fontColor || '#134074'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
