import React, { useEffect, useState } from 'react';
import classes from './Login.module.scss';
import LoginSVG1 from '../../components/Reusable/SVG/LoginSVG1';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import BaseNavBar from '../../components/Reusable/Common/BaseNavBar/BaseNavBar';
import Button from '../../components/Reusable/UI/Button/Button';
import Login2 from '../../components/Reusable/SVG/Login2';
import { useNavigate } from 'react-router-dom';
// import { CompanyAuthService } from "../../services/CompanyAuth";
import ReactInputVerificationCode from 'react-input-verification-code';
import { authService } from '../../services/authService';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/reducers/authSlice';
import { errorActions } from '../../store/reducers/errorSlice';
// import { authActions, authReducer } from '../../store/reducers/authSlice';
// import { useDispatch } from 'react-redux';
// import { membersActions } from '../../store/reducers/memberSlice';
// import { HaaiOwnerService } from '../../services/HaaiOwnerService';
// import { HaaiSupportService } from '../../services/HaaiSupportService';
// import { HaaiSalesService } from '../../services/HaaiSalesService';
// import { HaaiAdminService } from '../../services/HaaiAdminsService';
// import { ChatService } from '../../services/Chat';
// import { roomsActions } from '../../store/reducers/roomsSlice';
export default function Login() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [otp, setOTP] = useState(null);
  // const dispatch = useDispatch();
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showError, setShowError] = useState(false);
  const [change, setChange] = useState('1');
  useEffect(() => {
    // //console.log("////////////////////");

    // if (localStorage.getItem("token")) {
    //   //console.log("////////////////////");
    //   history(-1);
    // }
    dispatch(errorActions.setError({ error: false, errorMsg: '' }));
    setServerError(true);
  }, []);
  let handleLogin = async () => {
    dispatch(errorActions.setError({ error: false, errorMsg: '' }));
    setShowError(true);
    setServerError(false);
    if (formData.password.length < 8 || !formData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_.@#\$%\^&\*])(?=.{8,})/)) {
      setLoading(false);
      setServerErrorMsg('The Password or Email is Incorrect');
      setServerError(true);
    } else if (formData.email && formData.password) {
      let response = await authService.eshtreanaSignin(formData);
      let res = response;
      //console.log(response);

      if (response.response) {
        if (response.response.data.message === 'Please verify your email address') setShowVerify(true);
        else if (response.response.data.message === 'Incorrect password') {
          setLoading(false);
          setServerErrorMsg('The Password or Email is Incorrect');
          setServerError(true);
        } else if (response.response.data.message === 'not found') {
          setLoading(false);
          setServerErrorMsg('The Password or Email is Incorrect');
          setServerError(true);
        }
      } else {
        if (response.message) {
          setServerErrorMsg('There is an Error happend , try later . ');
          setServerError(true);
        } else if (response.data.message === 'Sign-in successfully') {
          dispatch(errorActions.setError({ error: false, errorMsg: '' }));

          localStorage.setItem('token', response.data.token);
          localStorage.setItem('side', response.data.side);
          localStorage.setItem('id', response.data._id);
          dispatch(authActions.updateUser(response.data));
          if (response.data.side === 'Top Manager') history(`/account/product`, { replace: true });
          else if (response.data.side === 'Marketing Manager') history(`/account/ad`, { replace: true });
          else if (response.data.side === 'Category Manager') history(`/account/product`, { replace: true });
          else if (response.data.side === 'Supplier') history(`/account/suppliers/${response.data._id}`, { replace: true });
          else if (response.data.side === 'Cashier') history(`/cashier`, { replace: true });
        } else if (response.data.message === 'Please verify your email') setShowVerify(true);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  let handleChange = (e) => {
    setServerError(false);
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <BaseNavBar />
      <div className="row mx-0 px-0 ">
        <div className={`col-xl-6 col-lg-8 col-10 mx-auto  ${classes['right-side']}`}>
          {!showVerify ? (
            <div style={{ height: `${window.innerHeight - 140}px` }} className="row mx-0 px-0 align-items-center d-flex  ">
              <div className={`col-12   px-0  mx-auto ${classes['login-form']}`}>
                {/* <div className="container"> */}
                <h1 className="px-0">Login to your account</h1>
                <span className={`${classes['sub-tex']} px-0`}>Please enter the username and password</span>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setLoading(true);
                    handleLogin();
                  }}
                >
                  <div className={classes.input}>
                    <InputGroup
                      errorMsg="Provide Email Address"
                      error={showError && !formData.email}
                      onChange={(e) => handleChange(e)}
                      name="email"
                      value="Email"
                    />
                  </div>
                  <div className={classes.input}>
                    <InputGroup
                      errorMsg="provide Password"
                      error={showError && !formData.password}
                      onChange={(e) => handleChange(e)}
                      type={'password'}
                      name="password"
                      value="Password"
                    />
                  </div>
                  {serverError && formData.email && formData.password && <span className="errorMsg text-cenetr">{serverErrorMsg}</span>}
                  <div className={classes.button}>
                    {loading ? (
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    ) : (
                      <Button
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        onClick={() => {
                          setLoading(true);
                          handleLogin();
                        }}
                        type="normal"
                        text="Login"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <>
              <div style={{ height: `${window.innerHeight - 140}px` }} className="row mx-0 px-0 align-items-center d-flex  ">
                <div className={`col-12   px-0  mx-auto ${classes['login-form']}`}>
                  <h1 className="px-0">Verify your email</h1>
                  <span className={`${classes['sub-tex']} px-0`}>Please enter your email address to reset your password </span>
                  <div className={`row px-0 mx-0 ${classes.email}`}>
                    <div className="col-6 px-0">
                      <span>{formData.email}</span>
                    </div>
                  </div>
                  <div className={`${classes.input} ${classes['input-verification-code']} justify-content-center`}>
                    <ReactInputVerificationCode
                      autoFocus={true}
                      value={otp}
                      length={4}
                      onChange={(e) => setOTP(e)}
                      className={classes.verfication}
                      placeholder=""
                    />
                  </div>
                  <div className={classes.button}>
                    <Button type="normal" text="Verify" />
                  </div>
                  <div className="row px-0 mx-0">
                    <span style={{ textDecoration: 'underline' }} className="text-center ">
                      Resend code (01:54)
                      <span className={classes.green}></span>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div style={{ height: `${window.innerHeight - 140}px` }} className="col-xl-6 d-none  d-xl-flex  align-items-center  justify-content-center">
          <Login2 />
        </div>
      </div>
    </>
  );
}
