import React from "react";

export default function VoucherActieveL() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9C0 8.20435 0.31607 7.44129 0.87868 6.87868C1.44129 6.31607 2.20435 6 3 6H29C29.7956 6 30.5587 6.31607 31.1213 6.87868C31.6839 7.44129 32 8.20435 32 9V12C32 12.2652 31.8946 12.5196 31.7071 12.7071C31.5196 12.8946 31.2652 13 31 13C30.2044 13 29.4413 13.3161 28.8787 13.8787C28.3161 14.4413 28 15.2044 28 16C28 16.7956 28.3161 17.5587 28.8787 18.1213C29.4413 18.6839 30.2044 19 31 19C31.2652 19 31.5196 19.1054 31.7071 19.2929C31.8946 19.4804 32 19.7348 32 20V23C32 23.7956 31.6839 24.5587 31.1213 25.1213C30.5587 25.6839 29.7956 26 29 26H3C2.20435 26 1.44129 25.6839 0.87868 25.1213C0.31607 24.5587 0 23.7956 0 23L0 20C0 19.7348 0.105357 19.4804 0.292893 19.2929C0.48043 19.1054 0.734784 19 1 19C1.79565 19 2.55871 18.6839 3.12132 18.1213C3.68393 17.5587 4 16.7956 4 16C4 15.2044 3.68393 14.4413 3.12132 13.8787C2.55871 13.3161 1.79565 13 1 13C0.734784 13 0.48043 12.8946 0.292893 12.7071C0.105357 12.5196 0 12.2652 0 12V9ZM3 8C2.73478 8 2.48043 8.10536 2.29289 8.29289C2.10536 8.48043 2 8.73478 2 9V11.1C3.13029 11.3295 4.14647 11.9427 4.87638 12.8357C5.60628 13.7288 6.00501 14.8466 6.00501 16C6.00501 17.1534 5.60628 18.2712 4.87638 19.1643C4.14647 20.0573 3.13029 20.6705 2 20.9V23C2 23.2652 2.10536 23.5196 2.29289 23.7071C2.48043 23.8946 2.73478 24 3 24H29C29.2652 24 29.5196 23.8946 29.7071 23.7071C29.8946 23.5196 30 23.2652 30 23V20.9C28.8697 20.6705 27.8535 20.0573 27.1236 19.1643C26.3937 18.2712 25.995 17.1534 25.995 16C25.995 14.8466 26.3937 13.7288 27.1236 12.8357C27.8535 11.9427 28.8697 11.3295 30 11.1V9C30 8.73478 29.8946 8.48043 29.7071 8.29289C29.5196 8.10536 29.2652 8 29 8H3Z"
        fill="#6E7980"
      />
    </svg>
  );
}
