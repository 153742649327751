import React, { useEffect } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import classes from './Voucher.module.scss';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
export default function VoucherCard(props) {
  let history = useNavigate();
  let handleNavigate = () => {
    if (props.side === 'ManagerDeal') {
      history(
        `/account/original-deal/${
          (props.type === 'On Going' && 'onGoing') ||
          (props.type === 'History' && 'history') ||
          (props.type === 'Scheduled' && 'scheduled') ||
          (props.type === 'Responses' && 'response')
        }/${props.id}`,
      );
      return;
    } else if (props.side === 'SupplierDeal') {
      history(
        `/supplier/original-deal/${
          (props.type === 'On Going' && 'onGoing') ||
          (props.type === 'History' && 'history') ||
          (props.type === 'Scheduled' && 'scheduled') ||
          (props.type === 'Requests' && 'request')
        }/${props.id}`,
      );
      return;
    } else if (props.side === 'ManagerColdDeal') {
      history(
        `/account/cold-deal/${
          (props.type === 'On Going' && 'onGoing') ||
          (props.type === 'History' && 'history') ||
          (props.type === 'Scheduled' && 'scheduled') ||
          (props.type === 'Responses' && 'response')
        }/${props.id}`,
      );
      return;
    } else if (props.side === 'SupplierColdDeal') {
      history(`/supplier/cold-deal/${(props.type === 'On Going' && 'onGoing') || (props.type === 'History' && 'history')}/${props.id}`);
      return;
    } else {
      props.side === 'Manager'
        ? (props.type === 'On Going' && history(`/account/voucher/on-going/${props.id}`)) ||
          (props.type === 'Scheduled' && history(`/account/voucher/scheduled/${props.id}`)) ||
          (props.type === 'Responses' && history(`/account/voucher/response/${props.id}`)) ||
          (props.type === 'History' && history(`/account/voucher/history/${props.id}`))
        : (props.type === 'On Going' && history(`/supplier/voucher/on-going/${props.id}`)) ||
          (props.type === 'Scheduled' && history(`/supplier/voucher/scheduled/${props.id}`)) ||
          (props.type === 'Requests' && history(`/supplier/voucher/requests/${props.id}`)) ||
          (props.type === 'History' && history(`/supplier/voucher/history/${props.id}`));
    }
  };
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) + 1 * 22 * 60 * 60 * 1000 - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  let timeinterval;

  function initializeClock(endtime) {
    const clock = document.getElementsByClassName(`clock`);
    timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      // //console.log(clock);
      if (clock[props.index])
        clock[props.index].innerHTML = endtime
          ? t.total > 0
            ? (t.days > 0 ? t.days : 0) +
              'D: ' +
              (t.hours > 0 ? t.hours : 0) +
              'H: ' +
              (t.minutes > 0 ? t.minutes : 0) +
              'M: ' +
              (t.seconds > 0 ? t.seconds : 0) +
              'S '
            : 'Finished'
          : '-';

      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  }
  useEffect(() => {
    if (props.timeLeft) {
      initializeClock(props.timeLeft);
    }

    return () => {
      clearInterval(timeinterval);
    };
  }, []);
  return (
    <div
      onClick={() => {
        handleNavigate();
      }}
      className="row px-0 mx-0 h-100 cursor"
    >
      <div className={`col-lg-11  ${classes['deal-card']}`}>
        {props.status && (
          <div
            className={`row text-center px-0 mx-0 ${
              classes[
                (props.statusValue === 'Accepted' && 'Accepted') ||
                  (props.statusValue === 'Rejected' && 'Rejected') ||
                  (props.statusValue === 'Need review' && 'NeedReview') ||
                  ((props.statusValue === 'Original Deal' ||
                    props.statusValue === 'Big Area Deal' ||
                    props.statusValue === 'Cold Deal' ||
                    props.statusValue === 'Big Deal' ||
                    props.statusValue === 'Area Deal') &&
                    'DealReview')
              ]
            }`}
          >
            {props.statusValue === 'Need review' ? 'Need Review' : props?.statusValue}
          </div>
        )}
        {props?.data.map((item, index) => {
          if (!item.total) {
            if (item.list) {
              return (
                <div onClick={(e) => e.stopPropagation()} key={index}>
                  <div className={`row px-0 mx-0 align-items-center ${classes['single-item']}`}>
                    <p className="col-6 d-inline">{item.title}</p>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={10}
                      pagination={{
                        clickable: true,
                      }}
                      loop={false}
                      style={{
                        width: '100%',
                      }}
                      navigation={true}
                      modules={[Navigation]}
                      className="my-swiper  justify-content-center    align-items-center d-flex p-0 "
                    >
                      {item.value &&
                        item.value.length > 0 &&
                        [...item.value].map((item, ind) => (
                          <SwiperSlide key={ind} className={`row ms-auto  justify-content-center mt-3`}>
                            <div className={`w-auto text-black ${classes['category-option']}`}>
                              {item.length > 16 ? item.slice(0, 15) + '...' : item}
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>
              );
            } else {
              return item.title === 'Time Left' || item.title === 'Voucher Time' ? (
                <div key={index} className={`row px-0 mx-0 row-cols-2 ${classes['single-item']}`}>
                  <p className="w-auto col d-inline">{item.title}</p>
                  <p className="d-inline  d-flex flex-grow-1 ms-auto  text-end justify-content-end  clock">
                    {!props.endDate && '-'} {/* {`${item.value.days}D :${item.value.hours}H :${item.value.minutes}M:${item.value.seconds}S`} */}
                  </p>
                </div>
              ) : (
                <div key={index} className={`row px-0 mx-0 row-cols-2 overflowX-hidden ${classes['single-item']}`}>
                  <p className="w-auto col-1 d-inline">{item.title}</p>
                  <p style={{ overflow: 'hidden' }} className={`d-inline   w-auto ms-auto  justify-content-end   ${classes['cojntainer']}`}>
                    {item?.value ? (item?.value?.length > 16 ? item?.value?.slice(0, 12) + '...' : item?.value) : '-'}
                  </p>
                </div>
              );
            }
          } else {
            return (
              <div key={index} className={`row px-0 mx-0 ${classes['single-item']} overflow-hidden`}>
                <p className="w-auto d-inline mb-0 ">{item.title}</p>
                <div className="row mt-2 align-items-center justify-content-center d-flex mx-0 px-0">
                  <div className="col-10 ">
                    <ProgressBar height="30px" bgColor="#2DA840" completed={Math.ceil((item.value / item.total) * 100)} />
                  </div>
                  <div className="col-2">
                    <p data-bs-toggle="tooltip" data-bs-placement="right" title={item.total} className=" fw-semibold">
                      {item.total}
                    </p>
                  </div>
                </div>
              </div>
            );
          }
        })}

        {(props.po || props.invoice) && (
          <div className="row align-items-center  d-flex mx-0 px-2 ">
            {['PO', 'Invoice'].map(
              (item, index) =>
                ((item === 'PO' && props.po) || (item === 'Invoice' && props.invoice)) && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    key={index}
                    className={`w-auto d-inline cursor ${classes['category-option1']}`}
                  >
                    <a
                      style={{ textDecoration: 'none', color: 'unset' }}
                      href={`${process.env.REACT_APP_IMAGE_BASE_URL}/${props.side === 'ManagerDeal' ? 'original-deal' : 'voucher'}/${
                        (item === 'PO' && props.po) || (item === 'Invoice' && props.invoice)
                      }`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {item}
                    </a>
                  </div>
                ),
            )}
          </div>
        )}
        {/* <p className="w-auto d-inline ms-auto">Deal Name</p> */}
        {/* <div className={`row px-0 mx-0 ${classes["single-item"]}`}> */}
      </div>
    </div>
  );
}
