import React from "react";

export default function CommingSoon() {
  return (
    <svg
      width="604"
      height="514"
      viewBox="0 0 604 514"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M552.589 52.7758H485.812V123.855H552.589V52.7758Z"
        fill="#EBEBEB"
      />
      <path
        d="M521.606 57.6043C521.606 58.0312 521.465 58.4485 521.201 58.8034C520.937 59.1584 520.562 59.435 520.123 59.5984C519.684 59.7618 519.201 59.8045 518.735 59.7212C518.269 59.6379 517.841 59.4324 517.505 59.1305C517.169 58.8286 516.94 58.444 516.847 58.0253C516.754 57.6066 516.802 57.1727 516.984 56.7782C517.166 56.3838 517.474 56.0467 517.869 55.8096C518.264 55.5724 518.728 55.4458 519.203 55.4458C519.52 55.4442 519.833 55.4988 520.125 55.6067C520.418 55.7146 520.683 55.8735 520.907 56.0743C521.13 56.2751 521.307 56.5137 521.427 56.7763C521.547 57.0389 521.608 57.3203 521.606 57.6043Z"
        fill="#C7C7C7"
      />
      <path
        d="M498.527 67.0115C505.277 66.5374 512.041 66.5125 518.791 66.4501C525.541 66.3877 532.29 66.5374 539.04 67.0115C532.29 67.4732 525.541 67.5106 518.791 67.573C512.041 67.6354 505.277 67.4732 498.527 67.0115Z"
        fill="#DBDBDB"
      />
      <path
        d="M498.527 72.6261C502.182 72.2343 505.86 72.0468 509.541 72.0647C513.222 72.034 516.901 72.2216 520.555 72.6261C516.901 73.0246 513.222 73.2121 509.541 73.1876C505.86 73.2055 502.182 73.018 498.527 72.6261Z"
        fill="#DBDBDB"
      />
      <path
        d="M498.527 78.2406C503.222 77.8039 507.916 77.7041 512.596 77.6791C517.277 77.6542 521.985 77.8039 526.666 78.2406C521.99 78.6457 517.294 78.8331 512.596 78.802C507.899 78.8264 503.204 78.639 498.527 78.2406Z"
        fill="#DBDBDB"
      />
      <path
        d="M498.527 83.855C504.402 83.4184 510.263 83.3185 516.138 83.2936C522.013 83.2686 527.874 83.4184 533.735 83.855C527.874 84.3042 521.999 84.404 516.138 84.429C510.277 84.4539 504.402 84.3666 498.527 83.855Z"
        fill="#DBDBDB"
      />
      <path
        d="M498.527 89.4819C503.624 89.0328 508.722 88.933 513.805 88.908C518.888 88.8831 524.013 89.0328 529.082 89.4819C523.985 89.9186 518.874 90.0184 513.805 90.0434C508.735 90.0684 503.624 89.9186 498.527 89.4819Z"
        fill="#DBDBDB"
      />
      <path
        d="M498.527 95.0963C505.055 94.6347 511.583 94.5973 518.11 94.5349C524.638 94.4725 531.152 94.6222 537.679 95.0963C531.152 95.5705 524.638 95.5954 518.11 95.6578C511.583 95.7202 505.055 95.5954 498.527 95.0963Z"
        fill="#DBDBDB"
      />
      <path
        d="M498.527 100.711C506.937 99.9622 515.409 99.9622 523.818 100.711C519.621 101.115 515.401 101.303 511.18 101.272C506.954 101.297 502.73 101.109 498.527 100.711Z"
        fill="#DBDBDB"
      />
      <path
        d="M498.527 106.325C505.277 105.864 512.041 105.839 518.791 105.764C525.541 105.689 532.29 105.864 539.04 106.325C532.29 106.799 525.541 106.824 518.791 106.899C512.041 106.974 505.277 106.824 498.527 106.325Z"
        fill="#DBDBDB"
      />
      <path
        d="M498.527 111.952C505.277 111.478 512.041 111.453 518.791 111.378C525.541 111.303 532.29 111.478 539.04 111.952C532.29 112.414 525.541 112.451 518.791 112.514C512.041 112.576 505.277 112.414 498.527 111.952Z"
        fill="#DBDBDB"
      />
      <path
        d="M106.645 131.952H27.6328V216.057H106.645V131.952Z"
        fill="#EBEBEB"
      />
      <path
        d="M67.1527 145.464C68.4797 145.464 69.5554 144.498 69.5554 143.306C69.5554 142.114 68.4797 141.147 67.1527 141.147C65.8257 141.147 64.75 142.114 64.75 143.306C64.75 144.498 65.8257 145.464 67.1527 145.464Z"
        fill="#C7C7C7"
      />
      <path
        d="M38.707 152.988C44.5151 152.239 50.4124 152.239 56.2205 152.988C53.3216 153.392 50.3903 153.58 47.4568 153.549C44.5282 153.573 41.6021 153.385 38.707 152.988Z"
        fill="#DBDBDB"
      />
      <path
        d="M38.707 159.226C42.4687 158.834 46.2533 158.646 50.0401 158.664C53.8316 158.646 57.6208 158.833 61.3871 159.226C57.6217 159.629 53.832 159.82 50.0401 159.8C46.2528 159.82 42.4678 159.628 38.707 159.226Z"
        fill="#DBDBDB"
      />
      <path
        d="M38.707 165.626C42.189 165.228 45.6968 165.041 49.2068 165.065C52.7216 165.034 56.2345 165.222 59.7204 165.626C52.7407 166.375 45.6867 166.375 38.707 165.626Z"
        fill="#DBDBDB"
      />
      <path
        d="M38.707 171.965C42.1896 171.572 45.697 171.385 49.2068 171.403C52.7214 171.378 56.2339 171.566 59.7204 171.965C56.2345 172.369 52.7216 172.557 49.2068 172.526C45.697 172.544 42.1896 172.357 38.707 171.965Z"
        fill="#DBDBDB"
      />
      <path
        d="M38.707 178.303C44.7617 177.554 50.9019 177.554 56.9566 178.303C50.9032 179.068 44.7604 179.068 38.707 178.303Z"
        fill="#DBDBDB"
      />
      <path
        d="M38.707 184.653C42.584 184.251 46.4846 184.059 50.3873 184.079C54.2947 184.059 58.1998 184.251 62.0815 184.653C58.199 185.045 54.2942 185.233 50.3873 185.215C46.485 185.233 42.5849 185.045 38.707 184.653Z"
        fill="#DBDBDB"
      />
      <path
        d="M38.707 190.991C41.9007 190.595 45.1217 190.403 48.3457 190.417C51.5975 190.384 54.8475 190.571 58.0677 190.979C54.8468 191.38 51.5973 191.568 48.3457 191.54C45.1224 191.563 41.9014 191.379 38.707 190.991Z"
        fill="#DBDBDB"
      />
      <path
        d="M38.707 197.329C42.7197 196.937 46.7538 196.75 50.7901 196.768C54.831 196.75 58.8698 196.937 62.887 197.329C58.8709 197.734 54.8313 197.922 50.7901 197.891C46.7537 197.915 42.7191 197.728 38.707 197.329Z"
        fill="#DBDBDB"
      />
      <path
        d="M38.707 203.68C42.7188 203.277 46.7534 203.086 50.7901 203.106C54.8314 203.086 58.8706 203.277 62.887 203.68C58.8698 204.072 54.831 204.26 50.7901 204.241C46.7538 204.26 42.7197 204.072 38.707 203.68Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 152.988C80.8604 152.239 88.5584 152.239 96.1883 152.988C92.3767 153.392 88.5404 153.58 84.7024 153.549C80.8692 153.574 77.038 153.386 73.2305 152.988Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 159.226C78.2282 158.476 83.3296 158.476 88.3273 159.226C83.3311 159.991 78.2267 159.991 73.2305 159.226Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 165.626C76.8101 165.228 80.415 165.041 84.0219 165.065C87.6336 165.034 91.2435 165.222 94.8272 165.626C87.6524 166.375 80.4053 166.375 73.2305 165.626Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 171.965C76.7131 171.572 80.2204 171.385 83.7302 171.403C87.2448 171.378 90.7573 171.566 94.2439 171.965C90.7579 172.369 87.245 172.557 83.7302 172.526C80.2204 172.544 76.7131 172.357 73.2305 171.965Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 178.303C80.8604 177.554 88.5584 177.554 96.1883 178.303C88.5594 179.068 80.8594 179.068 73.2305 178.303Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 184.653C79.2838 183.888 85.4267 183.888 91.48 184.653C85.4254 185.402 79.2851 185.402 73.2305 184.653Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 190.991C76.8108 190.599 80.4151 190.411 84.0219 190.43C87.6336 190.399 91.2435 190.586 94.8272 190.991C91.2429 191.39 87.6334 191.577 84.0219 191.553C80.4151 191.571 76.8108 191.383 73.2305 190.991Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 197.329C78.755 196.585 84.3723 196.585 89.8967 197.329C87.1422 197.732 84.3541 197.92 81.5636 197.891C78.7733 197.914 75.9857 197.726 73.2305 197.329Z"
        fill="#DBDBDB"
      />
      <path
        d="M73.2305 203.68C78.539 202.915 83.9494 202.915 89.2579 203.68C83.9479 204.428 78.5404 204.428 73.2305 203.68Z"
        fill="#DBDBDB"
      />
      <path
        d="M126.742 205.327H86.5625V241.421H126.742V205.327Z"
        fill="#C7C7C7"
      />
      <path
        d="M109.044 208.995C109.044 209.422 108.902 209.84 108.638 210.195C108.373 210.551 107.997 210.827 107.557 210.99C107.117 211.153 106.634 211.195 106.167 211.111C105.701 211.027 105.273 210.82 104.937 210.517C104.602 210.214 104.374 209.828 104.283 209.408C104.192 208.989 104.241 208.555 104.425 208.16C104.609 207.766 104.919 207.43 105.316 207.194C105.713 206.959 106.179 206.834 106.655 206.837C107.29 206.84 107.897 207.069 108.345 207.473C108.793 207.878 109.044 208.425 109.044 208.995Z"
        fill="white"
      />
      <path
        d="M515.025 290.978C515.543 298.027 514.534 305.099 512.052 311.802C510.881 314.904 509.422 317.913 507.691 320.797C505.511 324.54 503.261 328.096 503.358 332.313C503.789 332.613 502.247 332.463 502.233 332.051C490.331 324.815 474.456 322.544 465.776 311.465C461.609 306.225 459.915 299.824 459.359 293.499C458.762 286.612 459.081 279.051 461.623 272.476C463.748 266.924 467.915 261.696 474.123 259.338C480.498 256.842 488.497 257.691 494.789 259.937C508.33 264.778 514.094 278.751 515.025 290.978Z"
        fill="#EBEBEB"
      />
      <path
        d="M514.305 374.21C513.041 369.007 511.791 363.804 510.471 358.601C507.916 348.421 505.208 338.277 502.138 328.221C500.286 322.199 498.3 316.219 496.18 310.28C496.774 306.675 497.581 303.102 498.597 299.575C499.986 295.308 501.874 291.228 503.652 287.098C503.763 286.836 503.305 286.674 503.166 286.911C499.861 292.563 495.569 299.787 494.833 306.711C492.347 299.986 489.652 293.349 486.694 286.749C486.757 281.378 487.403 276.025 488.625 270.766C488.625 270.722 488.61 270.678 488.58 270.643C488.55 270.607 488.508 270.582 488.46 270.57C488.412 270.559 488.361 270.562 488.315 270.58C488.27 270.597 488.232 270.628 488.208 270.667C486.748 275.244 485.932 279.968 485.778 284.728C483.514 279.737 481.097 274.909 478.486 270.08C478.25 269.644 477.583 270.08 477.778 270.454C481.764 278.876 485.389 287.435 488.75 296.094C483.399 291.23 477.069 287.323 470.097 284.578C469.792 284.466 469.528 284.852 469.833 284.99C477.394 288.55 484.182 293.299 489.875 299.013C492.263 305.335 494.523 311.706 496.652 318.127C489.444 310.729 479.167 305.476 469.07 302.345C468.722 302.232 468.583 302.706 468.917 302.831C480.569 306.874 489.319 313.598 497.777 321.546C498.611 324.133 499.425 326.728 500.222 329.331C503.249 339.171 506.083 349.065 508.721 359.013C509.999 363.779 511.249 368.545 512.471 373.311C513.777 378.452 514.694 383.841 516.541 388.845C516.721 389.319 517.652 389.281 517.582 388.72C516.766 383.836 515.672 378.993 514.305 374.21Z"
        fill="#DBDBDB"
      />
      <path
        d="M501.605 280.249C499.127 283.145 497.31 286.452 496.258 289.98C496.258 290.068 496.383 290.13 496.424 290.043C498.109 286.768 500.016 283.589 502.133 280.523C502.355 280.161 501.841 279.924 501.605 280.249Z"
        fill="#DBDBDB"
      />
      <path
        d="M477.388 301.958C474.074 299.99 470.389 298.583 466.513 297.803C466.416 297.803 466.333 297.915 466.444 297.965C468.18 298.751 470.027 299.35 471.791 300.111C473.555 300.872 475.277 301.758 477.068 302.457C477.142 302.495 477.23 302.505 477.312 302.485C477.394 302.465 477.463 302.417 477.506 302.351C477.548 302.285 477.56 302.206 477.537 302.132C477.515 302.059 477.461 301.996 477.388 301.958Z"
        fill="#DBDBDB"
      />
      <path
        d="M473.61 273.499C472.558 272.649 471.413 271.897 470.193 271.253C469.999 271.166 469.804 271.378 469.957 271.515C470.994 272.382 472.119 273.159 473.318 273.836C473.471 274.01 473.763 273.648 473.61 273.499Z"
        fill="#DBDBDB"
      />
      <path
        d="M553.501 312.6C544.418 314.684 535.293 316.58 526.196 318.639C525.405 318.814 511.35 292.139 517.433 278.851C522.988 266.562 535.071 257.641 549.113 253.898C555.612 252.164 563.571 250.804 570.293 252.376C576.265 253.786 580.334 258.365 582.07 263.53C584.431 270.542 583.181 278.764 581.431 285.788C579.988 292.383 576.667 298.53 571.793 303.63C566.807 308.545 560.598 310.978 553.501 312.6Z"
        fill="#91C8E4"
      />
      <path
        d="M567.608 288.209C561.819 290.498 555.837 292.375 549.72 293.823C545.643 294.651 541.651 295.791 537.79 297.229C541.956 289.743 546.401 282.469 551.151 275.245C557.025 273.998 563.234 271.802 567.817 268.271C567.87 268.235 567.905 268.181 567.914 268.121C567.924 268.061 567.906 268.001 567.865 267.953C567.825 267.905 567.765 267.873 567.698 267.865C567.632 267.857 567.565 267.873 567.511 267.909C562.595 270.791 557.234 272.426 551.845 274.297C553.123 272.351 554.419 270.413 555.734 268.483C556.095 267.947 555.275 267.36 554.845 267.884C549.855 273.882 545.308 280.167 541.234 286.699C540.523 280.825 540.472 274.902 541.081 269.02C541.072 268.995 541.055 268.973 541.031 268.957C541.007 268.942 540.979 268.934 540.949 268.934C540.92 268.934 540.892 268.942 540.868 268.957C540.844 268.973 540.827 268.995 540.817 269.02C539.416 275.411 539.205 281.967 540.192 288.421C536.804 293.96 533.679 299.65 530.845 305.414C529.97 301.858 528.79 298.352 528.068 294.771C526.928 289.636 526.721 284.37 527.457 279.176C527.457 279.026 527.221 279.013 527.193 279.176C525.179 288.773 526.052 298.679 529.72 307.872C528.068 311.353 526.484 314.846 525.012 318.352C517.445 336.174 512.591 354.833 510.582 373.823C510.583 373.907 510.618 373.988 510.681 374.05C510.743 374.113 510.829 374.154 510.922 374.164C511.015 374.175 511.109 374.155 511.187 374.108C511.265 374.062 511.321 373.991 511.346 373.91C514.509 355.175 519.801 336.78 527.137 319.013C530.054 312.064 533.345 305.289 536.859 298.739C542.234 297.491 547.595 295.794 552.887 294.359C558.162 293.131 563.178 291.133 567.733 288.446C567.747 288.423 567.755 288.398 567.755 288.373C567.756 288.347 567.75 288.322 567.738 288.298C567.726 288.275 567.708 288.255 567.685 288.24C567.663 288.224 567.636 288.213 567.608 288.209Z"
        fill="#DBDBDB"
      />
      <path
        d="M576.379 280.897C570.824 282.469 565.491 284.74 559.935 286.325C559.671 286.399 559.796 286.774 560.06 286.736C565.921 285.863 571.754 284.453 576.588 281.197C576.699 281.072 576.504 280.847 576.379 280.897Z"
        fill="#DBDBDB"
      />
      <path
        d="M531.936 268.67C531.936 268.421 531.589 268.246 531.491 268.521C529.783 273.012 528.533 277.803 529.797 282.507C529.797 282.553 529.817 282.598 529.854 282.63C529.89 282.663 529.94 282.682 529.991 282.682C530.043 282.682 530.092 282.663 530.129 282.63C530.165 282.598 530.186 282.553 530.186 282.507C530.63 277.828 530.547 273.249 531.936 268.67Z"
        fill="#DBDBDB"
      />
      <path
        d="M555.072 258.964C553.189 261.148 552.208 263.85 552.294 266.612C552.294 266.786 552.6 266.899 552.655 266.699C553.433 264.116 554.655 261.783 555.655 259.288C555.665 259.223 555.654 259.157 555.623 259.098C555.593 259.039 555.543 258.989 555.481 258.955C555.42 258.921 555.348 258.903 555.275 258.905C555.203 258.906 555.132 258.927 555.072 258.964Z"
        fill="#DBDBDB"
      />
      <path
        d="M601.986 332.176C599.348 339.662 593.084 350.092 583.167 350.167C576.737 350.23 570.265 349.544 563.807 349.456C556.862 349.369 549.988 349.381 543.099 349.743C542.529 349.743 543.932 331.178 560.515 322.12C565.168 319.625 570.848 318.776 576.126 317.753C582.166 316.467 588.365 315.884 594.57 316.019C598.375 316.156 601.514 316.892 602.903 320.398C604.292 323.904 603.195 328.62 601.986 332.176Z"
        fill="#91C8E4"
      />
      <path
        d="M595.221 324.544C591.193 325.479 587.124 326.552 583.082 327.7C584.138 326.673 585.25 325.695 586.415 324.768C588.165 323.521 590.068 322.435 591.971 321.3C592.137 321.212 592.04 320.938 591.846 321.013C587.944 322.483 584.577 324.902 582.124 328C577.082 329.459 572.082 331.081 567.277 332.853C563.954 334.076 560.769 335.58 557.763 337.345C558.737 335.466 559.889 333.667 561.208 331.967C563.416 329.41 566.388 327.975 569.277 326.166C569.322 326.129 569.351 326.079 569.36 326.025C569.369 325.971 569.357 325.916 569.326 325.869C569.295 325.823 569.247 325.787 569.19 325.768C569.133 325.749 569.07 325.749 569.013 325.766C565.998 326.942 563.289 328.67 561.06 330.84C558.831 333.01 557.131 335.574 556.069 338.368C552.618 340.594 549.464 343.169 546.666 346.041C535.68 357.27 527.042 372.154 527 387.363C527 387.725 527.653 387.825 527.736 387.45C530.55 372.541 537.779 358.586 548.666 347.051C549.458 346.228 550.263 345.442 551.083 344.681C554.089 344.312 557.118 344.112 560.152 344.082C563.68 344.082 567.194 344.544 570.707 344.806C576.499 345.23 582.901 345.292 588.124 342.672C588.166 342.642 588.196 342.6 588.21 342.553C588.224 342.506 588.22 342.457 588.2 342.412C588.179 342.367 588.143 342.329 588.097 342.304C588.051 342.279 587.997 342.268 587.943 342.273C576.291 346.078 564.082 341.262 552.402 343.52C557.388 339.244 563.232 335.857 569.624 333.539L570.693 333.152C578.165 333.963 586.068 335.648 593.346 333.527C593.389 333.513 593.426 333.486 593.447 333.449C593.469 333.412 593.474 333.369 593.461 333.329C593.449 333.29 593.42 333.255 593.38 333.234C593.341 333.213 593.294 333.206 593.248 333.215C586.207 334.799 579.471 332.816 572.527 332.491C580.082 329.846 587.804 327.75 595.373 325.118C595.887 324.918 595.901 324.394 595.221 324.544Z"
        fill="#DBDBDB"
      />
      <path
        d="M578.29 328.421C574.679 328.85 571.044 329.1 567.402 329.169C567.387 329.175 567.374 329.185 567.365 329.197C567.356 329.209 567.352 329.223 567.352 329.238C567.352 329.252 567.356 329.267 567.365 329.279C567.374 329.291 567.387 329.301 567.402 329.307C571.061 329.914 574.831 329.695 578.374 328.67C578.41 328.66 578.441 328.638 578.46 328.607C578.478 328.577 578.482 328.541 578.471 328.508C578.46 328.475 578.434 328.447 578.401 328.431C578.367 328.414 578.327 328.411 578.29 328.421Z"
        fill="#DBDBDB"
      />
      <path
        d="M553.7 321.983C551.687 322.791 549.859 323.929 548.311 325.339C548.13 325.489 548.311 325.801 548.589 325.663C549.505 325.064 550.45 324.503 551.366 323.954C552.272 323.501 553.149 323.001 553.991 322.457C554.269 322.282 554.005 321.883 553.7 321.983Z"
        fill="#DBDBDB"
      />
      <path
        d="M495.122 346.175C495.233 346.424 494.844 346.661 494.692 346.399C494.628 346.477 494.533 346.53 494.428 346.549C487.752 347.98 480.985 349.043 474.164 349.73C468.678 350.167 462.345 350.442 457.498 347.572C450.998 343.704 446.72 333.336 450.234 326.749C454.401 318.938 466.901 323.18 473.234 325.963C482.934 330.196 490.678 337.347 495.122 346.175Z"
        fill="#EBEBEB"
      />
      <path
        d="M499.999 349.793C496.339 346.622 492.279 343.846 487.902 341.521C484.745 337.036 480.559 333.208 475.625 330.292C475.389 330.142 475.18 330.479 475.402 330.629C479.324 333.455 482.8 336.745 485.735 340.411C480.853 337.999 475.779 335.914 470.555 334.172C468.687 331.068 465.855 328.519 462.403 326.836C462.333 326.836 462.25 326.836 462.319 326.936C465.174 328.881 467.66 331.227 469.68 333.885C466.597 332.85 463.472 331.889 460.361 330.953C460.083 330.879 459.972 331.253 460.25 331.353C465.805 333.299 471.458 335.345 476.916 337.716C470.218 338.67 463.393 338.67 456.694 337.716C456.676 337.711 456.657 337.709 456.638 337.711C456.619 337.713 456.601 337.718 456.584 337.726C456.567 337.734 456.553 337.745 456.54 337.758C456.528 337.771 456.519 337.787 456.514 337.803C456.508 337.819 456.507 337.837 456.509 337.854C456.51 337.871 456.516 337.887 456.525 337.902C456.534 337.917 456.546 337.931 456.561 337.941C456.576 337.952 456.593 337.96 456.611 337.965C463.604 339.824 471.031 339.923 478.083 338.252C481.582 339.787 484.981 341.499 488.263 343.38C485.5 343.177 482.718 343.379 480.027 343.979C475.288 344.972 470.436 345.467 465.569 345.451C465.389 345.451 465.333 345.726 465.569 345.751C473.902 346.998 481.888 343.617 490.208 344.503C493.293 346.389 496.188 348.514 498.86 350.854C504.046 355.512 508.319 360.924 511.499 366.861C514.999 373.261 516.888 380.049 519.721 386.661C519.741 386.722 519.788 386.774 519.85 386.804C519.913 386.835 519.986 386.842 520.054 386.823C520.122 386.805 520.18 386.763 520.213 386.707C520.247 386.651 520.255 386.585 520.235 386.524C517.79 373.461 510.554 359.088 499.999 349.793Z"
        fill="#DBDBDB"
      />
      <path
        d="M474.678 331.914C474.119 331.028 473.396 330.235 472.539 329.568C471.827 328.886 470.985 328.325 470.053 327.909C469.969 327.909 469.928 327.984 469.969 328.034C470.664 328.732 471.622 329.281 472.358 329.918C473.094 330.554 473.747 331.39 474.483 332.051C474.505 332.07 474.533 332.081 474.563 332.082C474.593 332.083 474.623 332.075 474.646 332.058C474.67 332.041 474.686 332.018 474.692 331.991C474.698 331.965 474.693 331.937 474.678 331.914Z"
        fill="#DBDBDB"
      />
      <path
        d="M460.844 335.956C459.594 335.682 458.288 335.707 457.025 335.47C455.77 335.196 454.532 334.862 453.316 334.472C453.15 334.472 453.052 334.634 453.191 334.721C455.482 335.984 458.168 336.545 460.844 336.318C461.108 336.281 461.025 336.006 460.844 335.956Z"
        fill="#DBDBDB"
      />
      <path
        d="M562 364L560.036 377.942L553.411 425H494.732L486.44 377.942L484 364H562Z"
        fill="#134074"
      />
      <path
        d="M539.883 363.604L538.508 377.566H486.982L485.273 363.604H539.883Z"
        fill="#A6A6A6"
      />
      <path d="M562 360H481V371H562V360Z" fill="#C7C7C7" />
      <path
        d="M12.9297 424.69L84.6919 424.378L156.468 424.278L299.992 424.066L443.517 424.266L515.293 424.378L587.055 424.69L515.293 424.989L443.517 425.101L299.992 425.314L156.468 425.101L84.6919 424.989L12.9297 424.69Z"
        fill="#263238"
      />
      <path
        d="M480.631 466.985C540.376 466.985 588.809 460.07 588.809 451.539C588.809 443.009 540.376 436.093 480.631 436.093C420.886 436.093 372.453 443.009 372.453 451.539C372.453 460.07 420.886 466.985 480.631 466.985Z"
        fill="#EBEBEB"
      />
      <path
        d="M258.88 513.398C395.192 513.398 505.694 499.668 505.694 482.731C505.694 465.793 395.192 452.063 258.88 452.063C122.569 452.063 12.0664 465.793 12.0664 482.731C12.0664 499.668 122.569 513.398 258.88 513.398Z"
        fill="#EBEBEB"
      />
      <path
        d="M464.808 33.2998H134.996V229.12H464.808V33.2998Z"
        fill="#EBEBEB"
      />
      <path
        d="M472.833 23.7054H127.133V33.2999H472.833V23.7054Z"
        fill="#455A64"
      />
      <path
        d="M209.697 66.7995H157.629V122.233H209.697V66.7995Z"
        fill="white"
      />
      <path
        d="M185.535 70.5674C185.535 70.9005 185.425 71.2262 185.219 71.5032C185.013 71.7801 184.72 71.996 184.378 72.1235C184.035 72.251 183.658 72.2844 183.294 72.2194C182.931 72.1544 182.597 71.994 182.334 71.7584C182.072 71.5228 181.894 71.2227 181.821 70.896C181.749 70.5693 181.786 70.2306 181.928 69.9228C182.07 69.6151 182.31 69.352 182.618 69.1669C182.927 68.9818 183.289 68.8831 183.66 68.8831C184.157 68.8831 184.634 69.0605 184.986 69.3764C185.338 69.6923 185.535 70.1207 185.535 70.5674Z"
        fill="#455A64"
      />
      <path
        d="M167.543 77.9037C172.807 77.5418 178.07 77.5169 183.334 77.467C188.598 77.4171 193.876 77.467 199.139 77.9037C193.876 78.2655 188.612 78.2904 183.334 78.3403C178.057 78.3902 172.807 78.2655 167.543 77.9037Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.543 82.2828C173.251 81.7005 179.015 81.7005 184.723 82.2828C181.871 82.5961 178.999 82.742 176.126 82.7195C173.258 82.7357 170.391 82.5899 167.543 82.2828Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.543 86.6622C174.843 86.0799 182.187 86.0799 189.487 86.6622C185.84 86.9757 182.178 87.1215 178.515 87.0989C174.862 87.0864 171.196 86.9991 167.543 86.6622Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.543 91.0413C172.126 90.7045 176.696 90.6171 181.279 90.6047C185.862 90.5922 190.431 90.692 195.001 91.0413C190.431 91.3907 185.848 91.4655 181.279 91.478C176.709 91.4905 172.126 91.3907 167.543 91.0413Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.543 95.4207C171.515 95.0838 175.501 94.9965 179.473 94.984C183.445 94.9715 187.417 95.0838 191.404 95.4207C187.417 95.77 183.445 95.8449 179.473 95.8573C175.501 95.8698 171.515 95.77 167.543 95.4207Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.543 99.8C172.64 99.4382 177.723 99.4133 182.82 99.3634C187.917 99.3135 193.001 99.4382 198.098 99.8C193.015 100.174 187.931 100.187 182.82 100.249C177.709 100.312 172.64 100.162 167.543 99.8Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.543 104.179C170.819 103.878 174.111 103.732 177.404 103.743C180.697 103.726 183.989 103.872 187.265 104.179C180.708 104.778 174.1 104.778 167.543 104.179Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.543 108.571C172.807 108.197 178.07 108.172 183.334 108.122C188.598 108.072 193.876 108.197 199.139 108.571C193.876 108.933 188.612 108.958 183.334 109.008C178.057 109.058 172.807 108.933 167.543 108.571Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.543 112.95C172.807 112.576 178.07 112.563 183.334 112.501C188.598 112.439 193.876 112.576 199.139 112.95C193.876 113.312 188.612 113.337 183.334 113.387C178.057 113.437 172.807 113.312 167.543 112.95Z"
        fill="#EBEBEB"
      />
      <path
        d="M236.276 142.919H174.652V208.508H236.276V142.919Z"
        fill="white"
      />
      <path
        d="M207.32 151.74C207.32 152.073 207.21 152.399 207.004 152.676C206.798 152.953 206.505 153.168 206.163 153.296C205.82 153.423 205.443 153.457 205.079 153.392C204.716 153.327 204.382 153.166 204.119 152.931C203.857 152.695 203.679 152.395 203.606 152.068C203.534 151.742 203.571 151.403 203.713 151.095C203.855 150.788 204.095 150.524 204.404 150.339C204.712 150.154 205.074 150.055 205.445 150.055C205.692 150.054 205.937 150.096 206.165 150.18C206.393 150.264 206.601 150.388 206.775 150.545C206.95 150.702 207.088 150.888 207.181 151.093C207.275 151.298 207.322 151.518 207.32 151.74Z"
        fill="#455A64"
      />
      <path
        d="M183.328 159.288C187.861 158.706 192.462 158.706 196.994 159.288C194.734 159.601 192.448 159.747 190.161 159.725C187.874 159.741 185.59 159.595 183.328 159.288Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.328 164.216C189.208 163.634 195.142 163.634 201.022 164.216C195.143 164.815 189.207 164.815 183.328 164.216Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.328 169.207C188.771 168.608 194.274 168.608 199.717 169.207C194.273 169.789 188.772 169.789 183.328 169.207Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.328 174.123C186.046 173.816 188.783 173.67 191.522 173.686C194.262 173.664 197 173.81 199.717 174.123C194.273 174.705 188.772 174.705 183.328 174.123Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.328 179.064C185.688 178.763 188.069 178.617 190.453 178.627C192.832 178.611 195.209 178.757 197.564 179.064C192.842 179.662 188.05 179.662 183.328 179.064Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.328 184.017C189.385 183.435 195.493 183.435 201.55 184.017C195.493 184.599 189.385 184.599 183.328 184.017Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.328 188.957C188.321 188.375 193.377 188.375 198.369 188.957C193.377 189.54 188.321 189.54 183.328 188.957Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.328 193.911C189.597 193.312 195.92 193.312 202.189 193.911C199.057 194.218 195.909 194.364 192.758 194.347C189.608 194.357 186.46 194.212 183.328 193.911Z"
        fill="#EBEBEB"
      />
      <path
        d="M183.328 198.851C189.598 198.269 195.919 198.269 202.189 198.851C199.058 199.165 195.909 199.311 192.758 199.288C189.608 199.304 186.46 199.159 183.328 198.851Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 159.288C216.153 158.706 222.156 158.706 228.105 159.288C225.133 159.602 222.141 159.747 219.147 159.725C216.159 159.742 213.172 159.596 210.203 159.288Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 164.216C214.098 163.634 218.072 163.634 221.967 164.216C218.074 164.815 214.096 164.815 210.203 164.216Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 169.207C215.799 168.608 221.454 168.608 227.05 169.207C221.453 169.789 215.8 169.789 210.203 169.207Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 174.123C212.921 173.816 215.658 173.67 218.397 173.686C221.137 173.664 223.874 173.81 226.592 174.123C221.148 174.705 215.647 174.705 210.203 174.123Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 179.063C213.173 178.762 216.159 178.616 219.147 178.627C222.14 178.61 225.132 178.756 228.105 179.063C222.157 179.662 216.152 179.662 210.203 179.063Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 184.017C214.926 183.435 219.716 183.435 224.439 184.017C219.716 184.599 214.926 184.599 210.203 184.017Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 188.957C215.8 188.375 221.453 188.375 227.05 188.957C221.453 189.54 215.8 189.54 210.203 188.957Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 193.911C214.52 193.312 218.914 193.312 223.231 193.911C221.076 194.217 218.898 194.363 216.717 194.347C214.536 194.358 212.358 194.212 210.203 193.911Z"
        fill="#EBEBEB"
      />
      <path
        d="M210.203 198.851C214.344 198.269 218.561 198.269 222.703 198.851C220.638 199.164 218.547 199.31 216.453 199.288C214.36 199.304 212.269 199.158 210.203 198.851Z"
        fill="#EBEBEB"
      />
      <path
        d="M236.247 51.5531H204.914V79.7003H236.247V51.5531Z"
        fill="#134074"
      />
      <path
        d="M222.445 54.4228C222.448 54.7564 222.34 55.0833 222.136 55.3619C221.932 55.6405 221.64 55.8583 221.297 55.9877C220.955 56.1171 220.578 56.1523 220.213 56.0887C219.848 56.0251 219.513 55.8657 219.249 55.6307C218.986 55.3956 218.806 55.0955 218.732 54.7684C218.659 54.4414 218.695 54.1021 218.837 53.7936C218.978 53.4851 219.218 53.2213 219.527 53.0356C219.836 52.85 220.199 52.7509 220.57 52.7509C220.816 52.7493 221.059 52.7913 221.287 52.8746C221.514 52.9578 221.721 53.0807 221.895 53.2361C222.069 53.3916 222.208 53.5764 222.302 53.7801C222.397 53.9837 222.445 54.2022 222.445 54.4228Z"
        fill="#455A64"
      />
      <path
        d="M464.808 33.2998H134.996V45.7639H464.808V33.2998Z"
        fill="#DBDBDB"
      />
      <path
        d="M251.348 67.1238L346.901 66.9366L442.454 67.1238L346.901 67.3109L251.348 67.1238Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 74.1231L346.901 73.936L442.454 74.1231L346.901 74.3103L251.348 74.1231Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 81.1226L346.901 80.9355L442.454 81.1226L346.901 81.3098L251.348 81.1226Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 88.1219L346.901 87.9348L442.454 88.1219L346.901 88.3091L251.348 88.1219Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 95.1213L346.901 94.9467L442.454 95.1213L346.901 95.3085L251.348 95.1213Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 102.133L346.901 101.946L442.454 102.133L346.901 102.308L251.348 102.133Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 109.132L346.901 108.945L442.454 109.132L346.901 109.307L251.348 109.132Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 116.132L346.901 115.945L442.454 116.132L346.901 116.306L251.348 116.132Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 123.131L346.901 122.944L442.454 123.131L346.901 123.318L251.348 123.131Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 130.13L346.901 129.943L442.454 130.13L346.901 130.318L251.348 130.13Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 137.13L346.901 136.943L442.454 137.13L346.901 137.317L251.348 137.13Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 144.129L346.901 143.942L442.454 144.129L346.901 144.316L251.348 144.129Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 151.129L346.901 150.942L442.454 151.129L346.901 151.316L251.348 151.129Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 158.128L346.901 157.941L442.454 158.128L346.901 158.315L251.348 158.128Z"
        fill="#C7C7C7"
      />
      <path
        d="M251.348 165.127L346.901 164.953L442.454 165.127L346.901 165.314L251.348 165.127Z"
        fill="#C7C7C7"
      />
      <path
        d="M286.044 116.132H271.559V173.299H286.044V116.132Z"
        fill="#134074"
      />
      <path
        d="M312.927 91.9523H298.441V173.299H312.927V91.9523Z"
        fill="white"
      />
      <path
        d="M339.81 80.9728H325.324V173.299H339.81V80.9728Z"
        fill="#134074"
      />
      <path
        d="M366.705 95.2834H352.219V173.287H366.705V95.2834Z"
        fill="white"
      />
      <path
        d="M393.587 99.5505H379.102V173.299H393.587V99.5505Z"
        fill="#134074"
      />
      <path
        d="M420.451 141.335H405.965V173.299H420.451V141.335Z"
        fill="white"
      />
      <path
        d="M256.537 62.2082L256.857 89.981L257.079 117.754C257.236 136.269 257.31 154.784 257.301 173.299L256.537 172.613H303.022L349.493 172.701L395.978 172.925L442.464 173.337L395.978 173.736L349.493 173.961L303.022 174.048H256.537H255.773V173.362C255.773 154.847 255.848 136.331 255.996 117.816L256.218 90.0434L256.537 62.2082Z"
        fill="#C7C7C7"
      />
      <path
        d="M286.044 179.163H271.559V180.336H286.044V179.163Z"
        fill="#C7C7C7"
      />
      <path
        d="M312.927 179.163H298.441V180.336H312.927V179.163Z"
        fill="#C7C7C7"
      />
      <path
        d="M339.81 179.163H325.324V180.336H339.81V179.163Z"
        fill="#C7C7C7"
      />
      <path
        d="M366.705 179.163H352.219V180.336H366.705V179.163Z"
        fill="#C7C7C7"
      />
      <path
        d="M393.587 179.163H379.102V180.336H393.587V179.163Z"
        fill="#C7C7C7"
      />
      <path
        d="M420.451 179.163H405.965V180.336H420.451V179.163Z"
        fill="#C7C7C7"
      />
      <path
        d="M301.753 194.347H269.906V196.805H301.753V194.347Z"
        fill="#C7C7C7"
      />
      <path
        d="M312.933 199.475H269.906V201.933H312.933V199.475Z"
        fill="#C7C7C7"
      />
      <path
        d="M292.739 204.616H269.906V207.074H292.739V204.616Z"
        fill="#C7C7C7"
      />
      <path
        d="M262.815 194.347H256.176V200.311H262.815V194.347Z"
        fill="#134074"
      />
      <path
        d="M381.739 194.347H343.879V196.805H381.739V194.347Z"
        fill="#C7C7C7"
      />
      <path
        d="M373.823 199.475H343.879V201.933H373.823V199.475Z"
        fill="#C7C7C7"
      />
      <path
        d="M386.906 204.616H343.879V207.074H386.906V204.616Z"
        fill="#C7C7C7"
      />
      <path
        d="M336.764 194.347H330.125V200.311H336.764V194.347Z"
        fill="white"
      />
      <path
        d="M398.179 449.93C400.679 450.653 473.678 449.655 476.358 448.083C476.677 447.909 476.719 441.982 476.636 433.847L480.191 385.588L433.998 385.713L430.345 427.472C430.345 427.472 419.984 431.764 411.429 435.519C407.688 437.049 404.052 438.778 400.54 440.697C397.804 442.743 395.693 449.181 398.179 449.93Z"
        fill="#F7A9A0"
      />
      <path
        d="M398.18 449.93C400.68 450.653 473.678 449.655 476.358 448.083C476.678 447.909 476.72 441.982 476.636 433.847C458.581 437.466 410.513 443.305 409.11 439.911C408.554 438.564 409.693 437.016 411.416 435.582C407.674 437.111 404.038 438.84 400.527 440.759C397.805 442.743 395.694 449.181 398.18 449.93Z"
        fill="#263238"
      />
      <path
        d="M499.692 209.931C499.692 209.931 486.637 334.696 486.318 336.156C486.318 336.356 486.179 338.651 486.054 342.394C485.332 362.981 483.54 427.135 483.54 427.135H428.652C428.652 427.135 432.222 351.14 434.291 333.411C436.902 310.804 453.027 209.893 453.027 209.893L499.692 209.931Z"
        fill="#455A64"
      />
      <path
        d="M479.778 340.373C481.473 314.472 484.019 288.637 487.417 262.869C489.333 248.346 491.653 233.873 493.958 219.388C493.958 219.263 494.208 219.275 494.181 219.388C489.931 245.152 486.931 271.116 484.334 297.054C481.736 322.993 479.931 348.919 478.778 374.908C478.112 389.63 477.612 404.365 477.084 419.1C477.084 419.173 477.052 419.243 476.994 419.294C476.937 419.346 476.859 419.375 476.778 419.375C476.697 419.375 476.619 419.346 476.562 419.294C476.505 419.243 476.473 419.173 476.473 419.1C477.223 392.849 478.056 366.586 479.778 340.373Z"
        fill="#263238"
      />
      <path
        d="M432.112 419.2C433.501 419.025 435.112 419.088 436.57 419.038C438.028 418.988 439.875 418.975 441.514 418.913C444.699 418.813 447.88 418.738 451.056 418.688C457.458 418.601 463.889 418.688 470.291 418.813L475.569 418.975C477.458 419.038 479.333 419.2 481.208 419.187C481.361 419.187 481.347 419.337 481.208 419.337C477.986 419.337 474.722 419.587 471.486 419.699C468.25 419.811 465.139 419.849 461.958 419.886C455.556 419.973 449.125 419.886 442.723 419.699L437.431 419.574L434.653 419.474C433.82 419.474 432.987 419.474 432.153 419.399C432.139 419.402 432.124 419.402 432.109 419.399C432.095 419.396 432.081 419.391 432.069 419.383C432.056 419.376 432.046 419.366 432.038 419.355C432.03 419.344 432.024 419.331 432.021 419.318C432.019 419.305 432.019 419.292 432.022 419.279C432.025 419.266 432.031 419.253 432.039 419.242C432.048 419.231 432.058 419.222 432.071 419.215C432.083 419.207 432.097 419.202 432.112 419.2Z"
        fill="#263238"
      />
      <path
        d="M460.047 209.931L485.046 372.376C485.407 360.261 485.755 349.069 485.991 342.432C486.116 338.689 486.213 336.381 486.255 336.194C486.56 334.684 499.629 209.968 499.629 209.968L460.047 209.931Z"
        fill="#263238"
      />
      <path
        d="M490.127 458.551C492.738 458.351 559.709 432.226 561.514 429.855C561.737 429.581 559.237 424.091 555.667 416.63L540.904 369.868L498.182 385.264L510.238 426.761C510.238 426.761 502.502 434.247 496.224 440.722C493.422 443.42 490.806 446.27 488.391 449.256C486.71 452.076 487.488 458.751 490.127 458.551Z"
        fill="#F7A9A0"
      />
      <path
        d="M490.127 458.551C492.738 458.352 559.709 432.226 561.514 429.855C561.737 429.581 559.237 424.091 555.667 416.63C540.57 426.187 498.724 448.196 495.946 445.538C494.849 444.49 495.252 442.668 496.224 440.76C493.422 443.458 490.806 446.308 488.391 449.294C486.71 452.076 487.488 458.751 490.127 458.551Z"
        fill="#263238"
      />
      <path
        d="M560.481 411.801L506.927 426.773C506.927 426.773 480.788 344.341 478.775 324.977C476.761 305.613 474.886 266.599 474.025 238.165C474.025 238.165 465.08 233.411 462.817 230.816C460.553 228.221 458.511 218.864 454.039 209.943H519.551C535.204 237.254 523.426 249.406 523.426 255.37C523.426 284.129 528.26 322.294 529.801 327.946C533.009 339.762 560.481 411.801 560.481 411.801Z"
        fill="#455A64"
      />
      <path
        d="M507.046 416.243C508.435 415.744 509.907 415.445 511.296 415.058C512.685 414.671 514.477 414.234 516.046 413.81C519.088 412.962 522.129 412.151 525.199 411.315C531.351 409.743 537.573 408.283 543.809 406.973L548.962 405.912C550.795 405.526 552.656 405.239 554.517 404.802C554.656 404.802 554.684 404.914 554.517 404.952C551.406 405.688 548.323 406.699 545.24 407.547C542.156 408.395 539.143 409.169 536.087 409.942C529.921 411.514 523.699 412.962 517.449 414.234C515.726 414.584 514.018 414.983 512.31 415.345L509.532 415.894C508.713 416.056 507.921 416.305 507.088 416.405C507.071 416.4 507.055 416.39 507.043 416.378C507.031 416.365 507.023 416.35 507.018 416.334C507.014 416.318 507.015 416.301 507.02 416.286C507.024 416.27 507.034 416.255 507.046 416.243Z"
        fill="#263238"
      />
      <path
        d="M461.284 230.18C464.417 231.472 467.391 233.052 470.159 234.896C473.104 236.705 475.798 238.789 478.617 240.723C478.728 240.798 478.617 240.96 478.506 240.885C475.534 239.176 472.409 237.679 469.465 235.894C466.507 234.282 463.718 232.432 461.132 230.367C461.122 230.346 461.119 230.322 461.122 230.299C461.126 230.276 461.135 230.255 461.151 230.236C461.166 230.217 461.186 230.202 461.21 230.193C461.233 230.183 461.259 230.179 461.284 230.18Z"
        fill="#263238"
      />
      <path
        d="M460.892 213.636C461.267 215.47 461.767 217.279 462.197 219.101C462.628 220.922 462.919 222.557 463.419 224.241C463.828 225.881 464.753 227.379 466.086 228.558C466.836 229.169 467.655 229.731 468.447 230.292C468.878 230.604 469.35 230.904 469.836 231.216C469.836 230.966 469.725 230.729 469.683 230.479C469.544 229.781 469.419 229.082 469.28 228.396C468.989 226.849 468.725 225.302 468.461 223.755C467.933 220.71 467.461 217.641 466.753 214.634C466.753 214.509 466.933 214.46 466.961 214.634C467.808 217.616 468.739 220.573 469.475 223.58C469.836 225.052 471.128 232.314 471.183 232.85C471.239 233.387 471.28 233.911 471.308 234.447C471.385 234.99 471.385 235.539 471.308 236.081C471.308 236.219 471.1 236.194 471.03 236.081C470.779 235.576 470.602 235.043 470.503 234.497C470.364 233.973 470.225 233.449 470.128 232.912C470.128 232.725 470.058 232.526 470.03 232.339C469.225 231.902 468.489 231.328 467.739 230.841C466.989 230.355 466.114 229.806 465.35 229.232C463.946 228.161 462.934 226.735 462.447 225.14C461.955 223.426 461.634 221.677 461.489 219.912C461.225 217.841 461.044 215.757 460.725 213.674C460.656 213.561 460.864 213.511 460.892 213.636Z"
        fill="#263238"
      />
      <path
        d="M463.551 136.157C462.703 147.71 462.037 159.613 463.176 171.153C464.065 180.149 468.176 187.585 478.55 189.868C488.161 192.002 501.911 191.977 510.133 186.313C513.966 183.667 515.327 179.9 515.119 175.658C514.841 169.769 510.022 137.492 508.591 127.423C507.943 121.238 506.684 115.117 504.827 109.133C500.48 96.6559 493.647 96.6559 492.689 96.4563C491.883 95.9822 486.661 93.8238 479.161 95.8949C477.204 96.4333 475.395 97.336 473.851 98.544C472.308 99.7521 471.065 101.239 470.203 102.907C464.717 112.851 464.342 125.34 463.551 136.157Z"
        fill="#263238"
      />
      <path
        d="M494.815 98.2278C502.995 101.746 504.857 109.844 506.759 117.08C509.426 127.173 511.884 137.317 513.801 147.535C514.717 152.451 515.523 157.392 516.148 162.345C516.759 167.086 517.731 172.151 517.315 176.93C516.634 184.84 508.537 188.359 500.648 189.407C500.61 189.413 500.571 189.413 500.532 189.406C500.494 189.399 500.458 189.385 500.426 189.365C500.394 189.345 500.366 189.32 500.345 189.291C500.323 189.262 500.308 189.229 500.301 189.194C500.294 189.16 500.294 189.125 500.302 189.09C500.31 189.056 500.325 189.024 500.347 188.995C500.369 188.966 500.397 188.941 500.43 188.922C500.462 188.902 500.499 188.889 500.537 188.883C504.995 188.134 509.551 187.061 512.898 184.129C517.176 180.386 516.745 175.395 516.301 170.405C515.551 161.996 514.259 153.624 512.634 145.314C511.009 137.005 509.065 128.721 506.968 120.486C504.968 112.651 503.579 102.345 494.69 98.2777C494.676 98.3027 494.718 98.1904 494.815 98.2278Z"
        fill="#263238"
      />
      <path
        d="M466.332 111.341C467.957 105.315 471.527 99.4505 478.638 97.7662C469.443 100.262 467.11 109.27 465.805 116.768C464.425 124.653 463.379 132.58 462.666 140.548C461.957 148.209 461.43 155.932 461.513 163.618C461.596 171.902 463.18 179.687 468.554 186.574C468.666 186.712 468.443 186.886 468.318 186.749C463.013 181.172 461.374 173.536 460.971 166.4C460.698 156.957 461.12 147.508 462.235 138.115C463.11 129.282 463.971 120.024 466.332 111.341Z"
        fill="#263238"
      />
      <path
        d="M476.899 153.674C468.969 163.468 436.692 184.416 423.345 182.37C414.637 181.06 387.109 164.067 381.304 158.664C374.499 152.426 388.72 135.907 394.012 137.928C400.609 140.424 422.484 155.608 428.622 157.616C432.15 158.764 453.427 148.621 474.343 141.185C482.44 138.378 481.635 147.822 476.899 153.674Z"
        fill="#F7A9A0"
      />
      <path
        d="M391.76 133.162C387.427 131.777 382.58 129.893 377.761 129.968C372.941 130.043 368.941 135.77 368.941 137.454C368.941 139.139 370.816 149.245 375.58 149.245C380.344 149.245 377.483 141.409 377.663 140.511C377.844 139.613 378.025 138.465 378.941 138.552C379.858 138.639 395.746 145.414 395.746 145.414L391.76 133.162Z"
        fill="#F7A9A0"
      />
      <path
        d="M391.456 133.749C391.179 133.749 378.276 129.182 373.401 129.257C368.526 129.332 364.582 135.059 364.582 136.743C364.582 138.427 366.401 148.583 371.221 148.583C376.04 148.583 373.123 140.748 373.304 139.85C373.485 138.951 373.679 137.804 374.582 137.878C375.485 137.953 391.401 144.741 391.401 144.741L391.456 133.749Z"
        fill="#F7A9A0"
      />
      <path
        d="M406.734 145.115C406.734 145.115 393.388 134.185 390.61 132.014C385.054 127.66 361.555 119.039 358.277 118.727C355 118.415 353.833 124.441 369.763 130.704C378.166 133.998 379.652 138.328 379.485 140.686C379.318 143.044 376.777 147.535 372.291 147.435C367.805 147.336 364.902 141.447 360.777 140.785C356.652 140.124 359.388 146.924 362.291 150.842C365.338 154.629 369.518 157.562 374.346 159.301C378.235 160.548 390.235 165.252 390.235 165.252L406.734 145.115Z"
        fill="#F7A9A0"
      />
      <path
        d="M366.755 144.641C368.436 145.814 370.352 147.336 372.575 147.411C373.613 147.394 374.624 147.108 375.485 146.585C376.345 146.062 377.018 145.326 377.422 144.466C378.604 142.814 379.027 140.815 378.602 138.889C378.081 137.151 377.016 135.583 375.533 134.373C373.709 132.913 371.666 131.691 369.463 130.742C369.366 130.742 369.463 130.58 369.547 130.617C371.616 131.441 378.033 133.799 379.269 138.902C379.679 140.864 379.231 142.893 378.019 144.566C377.618 145.42 376.986 146.17 376.181 146.75C375.376 147.329 374.423 147.719 373.408 147.885C370.755 148.072 368.477 146.138 366.575 144.691C366.533 144.628 366.658 144.578 366.755 144.641Z"
        fill="#263238"
      />
      <path
        d="M428.653 156.905C425.306 156.519 412.265 146.288 406.737 143.181L387.293 171.029C391.223 174.41 417.459 182.37 424.695 183.505C440.195 185.876 467.749 165.838 477.846 155.396C486.735 146.225 484.43 140.049 478.249 140.012C477.309 140.021 476.375 140.142 475.472 140.374C467.277 142.258 431.931 157.292 428.653 156.905Z"
        fill="#134074"
      />
      <path
        d="M439.54 172.476C438.91 170.903 438.723 169.214 438.998 167.56C439.631 169.133 439.817 170.822 439.54 172.476Z"
        fill="white"
      />
      <path
        d="M394.267 171.615C393.618 170.046 393.431 168.352 393.725 166.699C394.358 168.272 394.544 169.961 394.267 171.615Z"
        fill="white"
      />
      <path
        d="M461.449 147.198C462.313 146.9 463.221 146.715 464.144 146.649C465.068 146.551 466.004 146.572 466.921 146.712C466.057 147.011 465.15 147.196 464.227 147.261C463.302 147.36 462.367 147.339 461.449 147.198Z"
        fill="white"
      />
      <path
        d="M417.734 163.306C418.6 163.01 419.507 162.821 420.429 162.744C421.353 162.647 422.289 162.668 423.206 162.807C422.314 163.108 421.379 163.297 420.429 163.368C419.531 163.448 418.626 163.427 417.734 163.306Z"
        fill="white"
      />
      <path
        d="M413.543 175.57C412.699 175.225 411.909 174.78 411.196 174.248C410.472 173.73 409.832 173.126 409.293 172.451C410.143 172.787 410.934 173.232 411.64 173.774C412.37 174.284 413.012 174.89 413.543 175.57Z"
        fill="white"
      />
      <path
        d="M443.127 157.878C442.279 157.539 441.489 157.094 440.78 156.556C440.049 156.042 439.403 155.437 438.863 154.759C439.714 155.094 440.505 155.54 441.21 156.082C441.942 156.595 442.587 157.2 443.127 157.878Z"
        fill="white"
      />
      <path
        d="M430.373 177.841C429.988 178.599 429.493 179.308 428.901 179.949C428.324 180.603 427.645 181.178 426.887 181.659C427.669 180.141 428.869 178.827 430.373 177.841Z"
        fill="white"
      />
      <path
        d="M455.781 157.978C454.993 159.493 453.8 160.809 452.309 161.808C452.681 161.044 453.177 160.334 453.781 159.7C454.353 159.043 455.026 158.463 455.781 157.978Z"
        fill="white"
      />
      <path
        d="M406.728 154.41C405.946 155.928 404.746 157.242 403.242 158.228C403.627 157.469 404.122 156.76 404.714 156.119C405.295 155.469 405.973 154.894 406.728 154.41Z"
        fill="white"
      />
      <path
        d="M409.279 147.436C407.89 149.931 406.293 152.301 404.779 154.709C403.266 157.117 401.71 159.525 400.155 161.908C397.016 166.687 396.155 167.697 392.905 172.414C392.886 172.443 392.855 172.465 392.819 172.475C392.783 172.484 392.743 172.48 392.71 172.463C392.677 172.447 392.653 172.419 392.642 172.386C392.632 172.354 392.636 172.319 392.655 172.289C395.432 167.361 396.21 166.263 399.321 161.472C400.84 159.134 402.391 156.818 403.974 154.522C405.654 152.114 407.252 149.644 409.113 147.348C409.127 147.331 409.148 147.32 409.171 147.316C409.194 147.311 409.218 147.315 409.239 147.326C409.259 147.336 409.275 147.354 409.282 147.374C409.29 147.394 409.289 147.416 409.279 147.436Z"
        fill="#263238"
      />
      <path
        d="M477.759 155.308C469.175 164.142 448.176 179.837 432.523 183.031L474.772 140.436L475.356 140.287C476.26 140.055 477.194 139.933 478.133 139.925C484.383 139.962 486.689 146.138 477.759 155.308Z"
        fill="#263238"
      />
      <path
        d="M457.114 181.983L449.586 220.261C449.586 220.261 515.501 220.71 530.14 219.874C530.14 219.874 515.501 163.256 498.974 144.429C498.599 143.992 498.21 143.58 497.835 143.181C497.768 143.097 497.693 143.018 497.613 142.944C489.849 135.134 480.03 137.155 473.294 141.946C471.627 143.193 455.627 158.864 453.683 166.899C452.03 174.085 457.114 181.983 457.114 181.983Z"
        fill="#134074"
      />
      <path
        d="M530.181 219.874C524.529 220.199 511.265 220.323 497.099 220.373C474.488 220.436 449.586 220.261 449.586 220.261L457.114 181.983C457.114 181.983 452.03 174.085 453.725 167.011C455.669 158.939 471.669 143.206 473.335 142.058C478.974 138.041 486.78 135.982 493.724 139.999C495.183 140.852 496.506 141.881 497.654 143.056C497.735 143.13 497.809 143.209 497.877 143.293C498.252 143.667 498.64 144.079 499.015 144.541C515.543 163.256 530.181 219.874 530.181 219.874Z"
        fill="#134074"
      />
      <path
        d="M499.052 202.37C498.407 200.8 498.216 199.108 498.496 197.454C498.824 198.232 499.034 199.046 499.121 199.874C499.216 200.706 499.193 201.544 499.052 202.37Z"
        fill="white"
      />
      <path
        d="M484.711 168.858C484.384 168.08 484.174 167.266 484.086 166.437C483.978 165.607 484.001 164.767 484.156 163.942C484.485 164.724 484.695 165.542 484.781 166.375C484.875 167.202 484.852 168.036 484.711 168.858Z"
        fill="white"
      />
      <path
        d="M462.614 198.901C461.984 197.328 461.798 195.639 462.073 193.986C462.705 195.558 462.891 197.248 462.614 198.901Z"
        fill="white"
      />
      <path
        d="M477.254 190.841C478.124 190.546 479.035 190.357 479.962 190.28C480.887 190.181 481.822 190.202 482.74 190.342C481.874 190.638 480.968 190.827 480.045 190.904C479.116 190.996 478.177 190.975 477.254 190.841Z"
        fill="white"
      />
      <path
        d="M455.535 172.226C456.428 171.925 457.363 171.736 458.313 171.665C459.237 171.566 460.173 171.587 461.091 171.727C460.225 172.023 459.318 172.212 458.396 172.289C457.444 172.393 456.48 172.372 455.535 172.226Z"
        fill="white"
      />
      <path
        d="M484.402 145.152C485.295 144.851 486.23 144.662 487.18 144.591C488.101 144.471 489.037 144.471 489.958 144.591C489.092 144.885 488.185 145.074 487.263 145.152C486.314 145.264 485.352 145.264 484.402 145.152Z"
        fill="white"
      />
      <path
        d="M458.211 214.796C459.081 214.501 459.992 214.312 460.919 214.235C461.84 214.116 462.776 214.116 463.697 214.235C462.831 214.531 461.925 214.719 461.003 214.796C460.076 214.909 459.137 214.909 458.211 214.796Z"
        fill="white"
      />
      <path
        d="M495.812 218.203C496.678 217.907 497.585 217.718 498.507 217.641C499.431 217.542 500.367 217.563 501.285 217.703C500.419 218 499.513 218.189 498.59 218.265C497.665 218.357 496.731 218.337 495.812 218.203Z"
        fill="white"
      />
      <path
        d="M484.397 212.426C483.552 212.081 482.763 211.636 482.049 211.103C481.318 210.59 480.673 209.985 480.133 209.307C480.989 209.64 481.784 210.085 482.494 210.629C483.217 211.147 483.857 211.751 484.397 212.426Z"
        fill="white"
      />
      <path
        d="M498.062 180.561C496.378 179.857 494.917 178.784 493.812 177.442C494.659 177.782 495.449 178.227 496.16 178.764C496.886 179.279 497.527 179.884 498.062 180.561Z"
        fill="white"
      />
      <path
        d="M475.701 159.438C474.851 159.103 474.06 158.657 473.354 158.115C472.623 157.602 471.978 156.997 471.438 156.319C472.288 156.654 473.079 157.099 473.785 157.641C474.513 158.158 475.157 158.763 475.701 159.438Z"
        fill="white"
      />
      <path
        d="M517.949 211.627C517.103 211.291 516.313 210.85 515.602 210.317C514.878 209.796 514.238 209.187 513.699 208.508C515.384 209.212 516.845 210.285 517.949 211.627Z"
        fill="white"
      />
      <path
        d="M516.152 184.004C515.779 184.768 515.283 185.479 514.68 186.113C514.108 186.769 513.434 187.349 512.68 187.834C513.467 186.32 514.66 185.004 516.152 184.004Z"
        fill="white"
      />
      <path
        d="M473.369 178.29C472.991 179.052 472.495 179.761 471.897 180.398C471.316 181.053 470.639 181.632 469.883 182.12C470.261 181.359 470.756 180.649 471.355 180.012C471.935 179.358 472.613 178.778 473.369 178.29Z"
        fill="white"
      />
      <path
        d="M501.802 155.333C501.418 156.092 500.923 156.801 500.33 157.442C499.749 158.092 499.072 158.667 498.316 159.151C498.701 158.393 499.196 157.684 499.789 157.042C500.369 156.392 501.047 155.817 501.802 155.333Z"
        fill="white"
      />
      <path
        d="M475.578 140.973C475.203 141.733 474.712 142.443 474.119 143.081C473.539 143.731 472.861 144.306 472.105 144.79C472.889 143.277 474.083 141.964 475.578 140.973Z"
        fill="white"
      />
      <path
        d="M530.18 219.874C524.527 220.199 511.264 220.324 497.098 220.374L493.723 139.912C495.182 140.765 496.505 141.793 497.653 142.969C497.734 143.043 497.808 143.122 497.875 143.206C498.25 143.58 498.639 143.992 499.014 144.454C515.542 163.256 530.18 219.874 530.18 219.874Z"
        fill="#263238"
      />
      <path
        d="M475.907 201.971C475.907 201.971 462.935 206.038 454.699 207.622C450.102 208.508 442.38 210.654 439.338 212.688C434.783 215.732 417.908 231.078 421.964 234.834C425.63 238.24 438.269 223.393 438.269 223.393C438.269 223.393 424.033 237.354 429.297 240.723C434.561 244.091 446.352 225.614 446.352 225.614C446.352 225.614 432.088 240.249 438.116 242.956C442.671 244.99 454.407 228.808 454.407 228.808C454.407 228.808 442.255 241.459 446.838 243.78C451.907 246.275 463.782 231.964 463.782 231.964C476.532 235.994 486.448 230.604 491.809 228.034C493.115 227.373 475.907 201.971 475.907 201.971Z"
        fill="#F7A9A0"
      />
      <path
        d="M441.988 219.113C436.66 223.863 432.252 229.379 428.946 235.433C428.828 235.668 428.726 235.91 428.641 236.156C431.418 232.413 440.488 220.885 442.071 219.163C442.099 219.113 442.029 219.113 441.988 219.113Z"
        fill="#263238"
      />
      <path
        d="M449.852 223.281C444.185 227.023 439.824 234.023 437.991 236.331C437.852 236.506 437.991 236.518 438.144 236.331C440.088 234.098 447.866 225.451 449.907 223.305C449.991 223.293 449.893 223.256 449.852 223.281Z"
        fill="#263238"
      />
      <path
        d="M456.205 227.136C452.683 229.835 449.842 233.178 447.872 236.942C447.761 237.129 447.872 237.117 448.08 236.942C450.955 233.461 452.664 231.253 456.316 227.26C456.372 227.186 456.275 227.086 456.205 227.136Z"
        fill="#263238"
      />
      <path
        d="M491.044 142.894C491.432 151.553 505.515 192.114 505.279 193.761C505.043 195.408 473.738 201.946 466.766 204.603C462.03 206.412 471.155 234.859 480.266 232.126C494.432 227.847 537.306 219.725 540.945 207.884C544.028 197.753 515.668 158.278 509.001 151.004C502.335 143.73 490.724 135.732 491.044 142.894Z"
        fill="#F7A9A0"
      />
      <path
        d="M542.351 208.396C537.17 223.542 486.282 235.033 486.282 235.033L460.047 205.689C460.047 205.689 485.63 197.691 502.518 193.212C504.324 192.738 493.532 157.292 491.116 147.847C488.699 138.402 492.685 136.219 505.907 147.049C519.129 157.878 545.406 199.463 542.351 208.396Z"
        fill="#134074"
      />
      <path
        d="M527.763 186.063C527.118 184.497 526.927 182.81 527.208 181.16C527.538 181.937 527.749 182.751 527.833 183.58C527.927 184.407 527.904 185.241 527.763 186.063Z"
        fill="white"
      />
      <path
        d="M510.849 211.765C510.514 210.963 510.304 210.123 510.224 209.269C510.114 208.439 510.138 207.599 510.294 206.774C510.623 207.552 510.833 208.366 510.919 209.194C511.028 210.05 511.005 210.915 510.849 211.765Z"
        fill="white"
      />
      <path
        d="M474.126 209.307C473.477 207.737 473.291 206.044 473.584 204.391C474.217 205.964 474.403 207.653 474.126 209.307Z"
        fill="white"
      />
      <path
        d="M534.309 197.704C535.174 197.408 536.081 197.219 537.003 197.142C537.928 197.051 538.862 197.072 539.781 197.204C538.888 197.506 537.953 197.695 537.003 197.766C536.106 197.858 535.199 197.837 534.309 197.704Z"
        fill="white"
      />
      <path
        d="M510.965 172.351C512.715 171.783 514.596 171.616 516.437 171.865C514.686 172.431 512.806 172.598 510.965 172.351Z"
        fill="white"
      />
      <path
        d="M496.449 150.031C498.197 149.452 500.08 149.28 501.921 149.531C501.051 149.827 500.14 150.016 499.213 150.093C498.293 150.178 497.364 150.157 496.449 150.031Z"
        fill="white"
      />
      <path
        d="M516.812 219.55C517.678 219.253 518.584 219.064 519.507 218.989C520.428 218.869 521.363 218.869 522.285 218.989C520.542 219.568 518.663 219.74 516.826 219.488L516.812 219.55Z"
        fill="white"
      />
      <path
        d="M497.328 199.625C498.194 199.333 499.101 199.148 500.023 199.076C500.947 198.977 501.882 198.998 502.8 199.138C501.935 199.435 501.028 199.624 500.106 199.7C499.18 199.788 498.246 199.763 497.328 199.625Z"
        fill="white"
      />
      <path
        d="M476.441 217.866C477.308 217.575 478.214 217.391 479.136 217.317C480.06 217.219 480.996 217.24 481.914 217.379C480.167 217.962 478.282 218.13 476.441 217.866Z"
        fill="white"
      />
      <path
        d="M491.996 209.544C491.152 209.198 490.362 208.754 489.649 208.221C488.925 207.7 488.285 207.091 487.746 206.412C489.436 207.115 490.899 208.193 491.996 209.544Z"
        fill="white"
      />
      <path
        d="M494.332 230.692C492.642 229.989 491.179 228.911 490.082 227.56C490.926 227.905 491.716 228.35 492.429 228.883C493.153 229.404 493.793 230.013 494.332 230.692Z"
        fill="white"
      />
      <path
        d="M515.834 158.877C514.99 158.531 514.201 158.086 513.487 157.554C512.756 157.036 512.11 156.427 511.57 155.745C512.421 156.087 513.215 156.532 513.931 157.067C514.652 157.592 515.292 158.201 515.834 158.877Z"
        fill="white"
      />
      <path
        d="M521.686 199.388C520.838 199.048 520.048 198.603 519.339 198.065C518.61 197.544 517.966 196.935 517.422 196.256C518.27 196.596 519.06 197.041 519.769 197.579C520.497 198.1 521.142 198.709 521.686 199.388Z"
        fill="white"
      />
      <path
        d="M510.73 184.279C510.357 185.043 509.861 185.753 509.258 186.387C508.686 187.044 508.013 187.624 507.258 188.109C507.63 187.345 508.127 186.635 508.73 186.001C509.302 185.344 509.975 184.764 510.73 184.279Z"
        fill="white"
      />
      <path
        d="M530.98 206.412C530.602 207.174 530.107 207.884 529.508 208.521C528.936 209.178 528.263 209.757 527.508 210.243C527.876 209.479 528.367 208.769 528.966 208.134C529.546 207.48 530.224 206.901 530.98 206.412Z"
        fill="white"
      />
      <path
        d="M502.849 217.017C502.476 217.777 501.98 218.483 501.377 219.113C500.797 219.767 500.119 220.347 499.363 220.835C499.741 220.074 500.237 219.364 500.835 218.727C501.416 218.076 502.094 217.501 502.849 217.017Z"
        fill="white"
      />
      <path
        d="M501.443 161.147C500.661 162.665 499.461 163.979 497.957 164.965C498.341 164.207 498.837 163.498 499.429 162.857C500.006 162.203 500.684 161.628 501.443 161.147Z"
        fill="white"
      />
      <path
        d="M467.707 204.765C469.915 206.861 471.873 209.145 473.97 211.341C476.068 213.536 478.137 215.77 480.123 218.003C484.179 222.482 488.22 226.974 492.178 231.528C492.202 231.554 492.214 231.588 492.21 231.622C492.206 231.656 492.187 231.687 492.158 231.709C492.128 231.73 492.09 231.74 492.053 231.737C492.015 231.733 491.98 231.716 491.956 231.69C487.637 227.423 483.47 223.031 479.387 218.564C477.387 216.394 475.429 214.193 473.512 211.964C471.498 209.631 469.346 207.336 467.554 204.89C467.485 204.79 467.623 204.678 467.707 204.765Z"
        fill="#263238"
      />
      <path
        d="M496.024 194.759C493.066 195.545 490.163 196.181 487.233 197.042C481.386 198.764 475.566 200.586 469.761 202.432C466.428 203.505 463.192 204.541 459.9 205.676C459.83 205.676 460.455 206.35 460.497 206.387C460.518 206.405 460.545 206.415 460.573 206.415C460.602 206.415 460.629 206.405 460.65 206.387C460.65 206.387 460.275 205.826 460.261 205.813C463.039 204.94 465.816 204.191 468.594 203.318L477.372 200.723C483.233 199.026 490.08 196.893 495.899 195.083C496.732 194.884 496.843 194.547 496.024 194.759Z"
        fill="#263238"
      />
      <path
        d="M501.109 143.343C501.332 142.894 487.929 159.251 475.249 156.618C468.902 155.371 469.846 142.058 471.082 141.534C479.249 138.016 489.887 134.984 501.109 143.343Z"
        fill="#F7A9A0"
      />
      <path
        d="M490.974 140.187C490.544 141.172 484.03 147.074 478.933 146.188C477.266 145.901 478.627 137.616 478.627 137.616V136.993L477.863 128.134L490.099 123.144L491.863 122.495C491.863 122.495 491.863 124.928 491.863 127.96C491.863 128.097 491.863 128.234 491.863 128.384C491.863 128.534 491.863 128.721 491.863 128.883C491.863 129.332 491.863 129.794 491.863 130.255C491.863 130.717 491.863 130.942 491.863 131.316C491.863 131.69 491.863 132.039 491.863 132.414C491.763 135.02 491.466 137.617 490.974 140.187Z"
        fill="#F7A9A0"
      />
      <path
        d="M491.82 127.947C490.47 130.227 488.585 132.217 486.295 133.779C484.005 135.341 481.365 136.437 478.557 136.993L477.793 128.134L490.029 123.144L491.793 122.495C491.793 122.495 491.82 124.915 491.82 127.947Z"
        fill="#263238"
      />
      <path
        d="M466.993 112.763C462.993 127.91 472.632 131.79 476.271 132.563C479.576 133.262 490.923 135.271 496.645 120.573C502.368 105.876 495.812 99.3632 488.312 97.4543C480.813 95.5454 471.007 97.6165 466.993 112.763Z"
        fill="#F7A9A0"
      />
      <path
        d="M470.718 111.129C471.135 111.129 471.51 111.129 471.912 111.129C472.341 111.164 472.77 111.072 473.135 110.867C473.232 110.783 473.295 110.673 473.315 110.553C473.335 110.434 473.311 110.311 473.246 110.206C473.087 110.015 472.875 109.865 472.631 109.77C472.387 109.676 472.12 109.641 471.857 109.669C471.3 109.655 470.758 109.834 470.343 110.168C470.268 110.246 470.217 110.341 470.195 110.442C470.174 110.544 470.182 110.649 470.22 110.746C470.258 110.844 470.325 110.93 470.412 110.998C470.499 111.065 470.605 111.11 470.718 111.129Z"
        fill="#263238"
      />
      <path
        d="M482.494 113.212C482.105 113.1 481.758 112.95 481.383 112.813C480.97 112.725 480.599 112.52 480.328 112.227C480.265 112.117 480.244 111.992 480.267 111.871C480.289 111.75 480.354 111.638 480.453 111.553C480.677 111.414 480.938 111.333 481.21 111.317C481.482 111.302 481.753 111.353 481.994 111.466C482.53 111.613 482.982 111.939 483.258 112.376C483.564 112.813 483.022 113.337 482.494 113.212Z"
        fill="#263238"
      />
      <path
        d="M479.511 116.805C479.511 116.805 479.594 116.868 479.581 116.918C479.331 117.953 479.219 119.176 480.206 119.7C478.928 119.301 479.15 117.691 479.511 116.805Z"
        fill="#263238"
      />
      <path
        d="M480.776 115.832C482.665 116.019 482.165 119.413 480.36 119.238C478.554 119.064 479.151 115.67 480.776 115.832Z"
        fill="#263238"
      />
      <path
        d="M481.481 116.107C481.787 116.344 482.051 116.731 482.44 116.818C482.829 116.905 483.301 116.606 483.648 116.306H483.717C483.62 116.955 483.231 117.554 482.44 117.554C481.648 117.554 481.301 116.905 481.231 116.232C481.217 116.182 481.37 116.019 481.481 116.107Z"
        fill="#263238"
      />
      <path
        d="M472.28 115.283C472.28 115.283 472.183 115.283 472.183 115.371C472.044 116.419 471.711 117.617 470.586 117.866V117.928C471.905 117.891 472.35 116.307 472.28 115.283Z"
        fill="#263238"
      />
      <path
        d="M471.404 114.061C469.542 113.699 468.876 117.055 470.598 117.404C472.32 117.754 472.973 114.373 471.404 114.061Z"
        fill="#263238"
      />
      <path
        d="M470.578 114.148C470.244 114.285 469.883 114.56 469.522 114.522C469.161 114.485 468.883 114.073 468.689 113.674C468.689 113.674 468.689 113.674 468.62 113.674C468.481 114.323 468.62 115.034 469.244 115.258C469.869 115.483 470.411 114.996 470.717 114.385C470.814 114.31 470.703 114.098 470.578 114.148Z"
        fill="#263238"
      />
      <path
        d="M473.416 120.224C473.416 120.224 472.888 121.759 472.749 122.495C472.749 122.557 472.93 122.62 473.208 122.682C473.89 122.898 474.625 122.941 475.333 122.807C476.041 122.673 476.694 122.366 477.221 121.921C477.291 121.921 477.221 121.771 477.124 121.796C475.965 122.29 474.66 122.434 473.402 122.208C473.402 121.996 474.388 119.613 474.263 119.588C473.612 119.547 472.958 119.585 472.319 119.7C473.305 116.481 474.86 113.462 475.777 110.23C475.777 110.106 475.624 110.043 475.569 110.143C473.812 113.363 472.416 116.731 471.402 120.199C471.263 120.611 473.055 120.299 473.416 120.224Z"
        fill="#263238"
      />
      <path
        d="M473.367 122.121C473.683 122.81 474.184 123.419 474.826 123.892C475.188 124.133 475.624 124.269 476.075 124.279C477.075 124.279 477.325 123.543 477.367 122.857C477.381 122.489 477.348 122.12 477.27 121.759C476.044 122.247 474.678 122.374 473.367 122.121Z"
        fill="#263238"
      />
      <path
        d="M474.828 123.892C475.191 124.134 475.627 124.269 476.078 124.279C477.078 124.279 477.328 123.543 477.37 122.857C476.878 122.747 476.36 122.79 475.899 122.978C475.437 123.166 475.06 123.488 474.828 123.892Z"
        fill="#FFA2BE"
      />
      <path
        d="M496.511 120.636C494.566 120.237 490.358 114.647 491.761 106.138C491.761 106.138 491.094 113.861 491.525 115.071C491.955 116.281 489.177 111.927 490.136 105.09C487.451 106.285 484.588 107.125 481.636 107.585C477.872 107.947 475.678 107.585 475.469 107.323C475.261 107.061 480.817 106.338 482.525 105.065C482.525 105.065 472.22 107.648 467.747 107.298C467.234 107.298 468.359 97.7288 475.914 94.7345C483.469 91.7401 493.483 97.6665 495.927 100.623C495.927 100.623 499.261 101.559 502.136 106.65C505.01 111.74 498.594 121.06 496.511 120.636Z"
        fill="#263238"
      />
      <path
        d="M493.66 121.172C493.66 121.172 498.202 116.855 500.424 118.378C502.646 119.9 498.702 126.5 495.688 127.111C495.307 127.208 494.909 127.235 494.517 127.191C494.124 127.146 493.746 127.03 493.405 126.851C493.064 126.671 492.766 126.432 492.531 126.146C492.296 125.861 492.127 125.535 492.035 125.19L493.66 121.172Z"
        fill="#F7A9A0"
      />
      <path
        d="M185.257 303.605L184.993 322.307L182.091 464.802H178.827L175.924 322.307L175.66 303.605H185.257Z"
        fill="#37474F"
      />
      <path
        d="M175.66 303.605H185.257L184.993 322.307H175.924L175.66 303.605Z"
        fill="#263238"
      />
      <path
        d="M431.187 303.605L430.923 322.307L428.02 464.802H424.756L421.854 322.307L421.59 303.605H431.187Z"
        fill="#37474F"
      />
      <path
        d="M431.187 303.605L430.923 322.307H421.854L421.59 303.605H431.187Z"
        fill="#263238"
      />
      <path
        d="M151.949 303.605L151.685 322.307L148.782 464.802H145.518L142.615 322.307L142.352 303.605H151.949Z"
        fill="#37474F"
      />
      <path
        d="M142.352 303.605H151.949L151.685 322.307H142.615L142.352 303.605Z"
        fill="#263238"
      />
      <path
        d="M397.867 303.605L397.603 322.307L394.7 464.802H391.436L388.533 322.307L388.27 303.605H397.867Z"
        fill="#37474F"
      />
      <path
        d="M388.27 303.605H397.867L397.603 322.307H388.533L388.27 303.605Z"
        fill="#263238"
      />
      <path
        d="M452.901 295.869H121.742V308.021H452.901V295.869Z"
        fill="#37474F"
      />
      <path d="M421.794 293H350.406V295.882H421.794V293Z" fill="#455A64" />
      <path d="M386.336 293H350.406V295.882H386.336V293Z" fill="#263238" />
      <path
        d="M382.47 295.869H312.902L303.805 253.424H373.373L382.47 295.869Z"
        fill="#455A64"
      />
      <path
        d="M347.374 274.647C347.431 275.131 347.373 275.622 347.203 276.085C347.034 276.548 346.757 276.973 346.39 277.333C346.024 277.693 345.577 277.979 345.078 278.171C344.58 278.364 344.042 278.46 343.499 278.452C342.348 278.43 341.246 278.031 340.399 277.33C339.552 276.63 339.02 275.675 338.902 274.647C338.843 274.162 338.899 273.672 339.067 273.209C339.235 272.746 339.51 272.32 339.876 271.96C340.241 271.601 340.687 271.314 341.185 271.121C341.683 270.929 342.221 270.833 342.763 270.841C343.917 270.857 345.024 271.254 345.874 271.956C346.725 272.657 347.258 273.615 347.374 274.647Z"
        fill="white"
      />
      <path
        d="M178.949 380.71L181.88 396.555L201.81 493.211H205.018L190.532 396.555L188.393 380.71H178.949Z"
        fill="#A6A6A6"
      />
      <path
        d="M188.393 380.71H178.949L181.88 396.555H190.532L188.393 380.71Z"
        fill="#263238"
      />
      <path
        d="M116.379 493.211H119.587L139.517 396.555L142.448 380.71H133.004L130.865 396.555L116.379 493.211Z"
        fill="#A6A6A6"
      />
      <path
        d="M133.002 380.71L130.863 396.555H139.516L142.446 380.71H133.002Z"
        fill="#263238"
      />
      <path
        d="M56.6289 493.211H59.8372L79.7534 396.555L82.6978 380.71H73.2535L71.1147 396.555L56.6289 493.211Z"
        fill="#A6A6A6"
      />
      <path
        d="M71.1094 396.555H79.7481L82.6924 380.71H73.2482L71.1094 396.555Z"
        fill="#263238"
      />
      <path
        d="M61.7109 388.158H207.694V374.334H153.042L134.681 278.352H67.2803L61.7109 388.158Z"
        fill="#A6A6A6"
      />
      <path
        d="M61.7109 388.158H69.6691L74.8634 278.352H67.2803L61.7109 388.158Z"
        fill="#DBDBDB"
      />
      <path
        d="M363.783 482.082C361.075 482.918 283.688 483.155 280.729 481.483C279.591 480.859 278.896 470.728 278.452 458.476C278.452 458.476 275.827 455.657 271.938 451.539C260.633 439.587 238.605 416.368 238.605 416.368L297.937 416.068L324.062 451.539L328.395 457.441V458.189C328.395 458.189 358.061 470.005 361.103 472.151C364.144 474.297 366.478 481.271 363.783 482.082Z"
        fill="#F7A9A0"
      />
      <path
        d="M363.786 482.082C361.078 482.918 283.691 483.155 280.733 481.483C279.594 480.859 278.9 470.728 278.455 458.476C278.455 458.476 275.83 455.657 271.941 451.539H324.037L328.371 457.441V458.189C328.371 458.189 358.037 470.005 361.078 472.151C364.12 474.297 366.481 481.271 363.786 482.082Z"
        fill="#37474F"
      />
      <path
        d="M363.788 482.082C361.08 482.905 283.693 483.155 280.735 481.483C279.61 480.859 278.901 470.741 278.457 458.476C278.457 458.352 278.457 458.214 278.457 458.09L328.456 457.428V458.189C328.456 458.189 358.122 470.005 361.163 472.151C364.205 474.297 366.483 481.271 363.788 482.082Z"
        fill="#455A64"
      />
      <path
        d="M362.899 479.387C349.844 479.15 297.192 479.25 284.276 479.899C284.179 479.899 284.179 479.973 284.276 479.973C297.206 480.472 349.858 479.973 362.899 479.587C363.177 479.574 363.177 479.399 362.899 479.387Z"
        fill="#263238"
      />
      <path
        d="M333.925 457.54C328.8 456.692 322.522 457.428 318.648 460.722C318.495 460.847 318.648 461.046 318.87 460.996C323.769 459.56 328.826 458.598 333.953 458.127C334.027 458.11 334.093 458.071 334.14 458.016C334.186 457.96 334.209 457.892 334.206 457.823C334.202 457.754 334.173 457.689 334.122 457.637C334.07 457.585 334.001 457.551 333.925 457.54Z"
        fill="#263238"
      />
      <path
        d="M337.649 459.537C332.511 458.688 326.233 459.424 322.372 462.718C322.233 462.843 322.372 463.043 322.594 462.993C327.495 461.559 332.551 460.597 337.677 460.123C337.752 460.107 337.818 460.067 337.864 460.012C337.91 459.957 337.933 459.889 337.93 459.82C337.927 459.751 337.897 459.685 337.846 459.633C337.795 459.582 337.725 459.548 337.649 459.537Z"
        fill="#263238"
      />
      <path
        d="M341.355 461.533C336.216 460.685 329.938 461.421 326.077 464.715C325.924 464.839 326.077 465.026 326.299 464.989C331.196 463.541 336.253 462.579 341.382 462.119C341.457 462.103 341.523 462.064 341.569 462.008C341.615 461.953 341.639 461.885 341.635 461.816C341.632 461.747 341.602 461.681 341.551 461.63C341.5 461.578 341.431 461.544 341.355 461.533Z"
        fill="#263238"
      />
      <path
        d="M340.561 447.435C338.533 444.939 334.784 446.087 332.645 447.709C329.036 450.56 326.325 454.213 324.784 458.302C324.777 458.32 324.776 458.339 324.779 458.358C324.782 458.377 324.79 458.395 324.802 458.41C324.814 458.426 324.83 458.439 324.848 458.448C324.867 458.457 324.888 458.463 324.909 458.464C324.905 458.554 324.925 458.643 324.967 458.724C325.009 458.806 325.072 458.878 325.15 458.933C325.229 458.989 325.321 459.028 325.418 459.046C325.516 459.064 325.618 459.061 325.714 459.038C329.95 457.615 334.672 456.542 338.283 454.047C340.408 452.55 342.478 449.818 340.561 447.435ZM331.645 455.694C329.575 456.443 327.478 457.091 325.45 457.902C326.918 455.802 328.503 453.77 330.2 451.814C331.037 450.879 331.942 449.996 332.909 449.169C334.436 447.921 340.464 445.239 339.575 450.304C339.089 453.111 334.103 454.833 331.645 455.719V455.694Z"
        fill="#263238"
      />
      <path
        d="M311.736 457.154C315.972 458.763 320.833 458.663 325.346 459.05C325.805 459.05 326.041 458.638 325.957 458.301C325.977 458.295 325.996 458.285 326.01 458.271C326.025 458.258 326.036 458.241 326.043 458.223C326.05 458.205 326.052 458.186 326.049 458.167C326.046 458.148 326.039 458.13 326.027 458.114C323.34 454.535 319.658 451.649 315.347 449.743C312.805 448.682 308.861 448.495 307.624 451.352C306.388 454.209 309.263 456.168 311.736 457.154ZM309.402 453.872C307.083 449.194 313.68 450.366 315.513 451.215C316.69 451.786 317.822 452.428 318.902 453.136C321.112 454.621 323.235 456.208 325.263 457.89C323.069 457.59 320.86 457.453 318.652 457.216C316.013 456.942 310.694 456.455 309.402 453.872Z"
        fill="#263238"
      />
      <path
        d="M333.528 457.715L303.376 460.373L273.613 463.005C273.613 463.005 213.183 392.675 209.489 389.394C201.156 381.908 134.866 363.817 116.769 347.66C97.6027 330.554 105.964 308.92 105.964 308.92H155.962C155.962 308.92 155.837 326.449 158.504 327.198C187.365 335.258 241.835 352.9 254.335 362.819C276.696 380.56 333.528 457.715 333.528 457.715Z"
        fill="#134074"
      />
      <path
        d="M324.131 449.456C315.645 450.167 318.062 449.855 309.604 450.703C305.437 451.09 278.229 453.698 274.438 454.683C274.341 454.683 274.438 454.833 274.438 454.833C278.382 455.008 305.548 451.976 309.687 451.514C318.145 450.554 315.701 450.753 324.145 449.718C324.184 449.716 324.22 449.701 324.246 449.675C324.272 449.649 324.286 449.615 324.284 449.58C324.282 449.546 324.265 449.513 324.236 449.49C324.208 449.466 324.17 449.454 324.131 449.456Z"
        fill="#263238"
      />
      <path
        d="M303.376 460.373L273.613 463.005C273.613 463.005 213.183 392.675 209.489 389.394C201.156 381.908 134.866 363.817 116.769 347.66C97.6027 330.554 105.964 308.92 105.964 308.92H125.699L128.852 309.693L303.376 460.373Z"
        fill="#263238"
      />
      <path
        d="M265.355 496.517C262.647 497.303 185.259 496.405 182.329 494.683C181.204 494.059 180.704 483.916 180.468 471.651V471.265C180.468 468.769 180.385 466.137 180.371 463.504C180.371 463.142 180.371 462.768 180.371 462.406C180.273 444.715 180.579 426.349 180.579 426.349L232.342 426.811L230.786 462.544V463.953L230.481 471.352V472.113C230.481 472.113 259.938 484.353 262.924 486.548C265.91 488.744 268.049 495.744 265.355 496.517Z"
        fill="#F7A9A0"
      />
      <path
        d="M265.363 496.517C262.655 497.303 185.268 496.405 182.337 494.683C181.212 494.059 180.712 483.916 180.476 471.651V471.265C180.476 468.769 180.393 466.137 180.379 463.504C180.379 463.142 180.379 462.768 180.379 462.406L230.794 462.544V463.953L230.489 471.352V472.113C230.489 472.113 259.947 484.353 262.933 486.548C265.919 488.744 268.057 495.744 265.363 496.517Z"
        fill="#455A64"
      />
      <path
        d="M265.356 496.517C262.647 497.303 185.26 496.405 182.33 494.683C181.205 494.059 180.705 483.916 180.469 471.651V471.265L230.468 471.352V472.113C230.468 472.113 259.925 484.353 262.911 486.549C265.897 488.744 268.05 495.744 265.356 496.517Z"
        fill="#455A64"
      />
      <path
        d="M264.516 493.81C251.475 493.373 198.823 492.712 185.893 493.161C185.796 493.161 185.796 493.236 185.893 493.236C198.81 493.922 251.461 494.209 264.516 494.01C264.78 493.997 264.794 493.822 264.516 493.81Z"
        fill="#263238"
      />
      <path
        d="M235.981 471.539C230.857 470.616 224.565 471.252 220.593 474.496C220.44 474.621 220.593 474.821 220.815 474.771C225.736 473.404 230.807 472.517 235.94 472.126C236.016 472.117 236.087 472.084 236.14 472.033C236.193 471.983 236.224 471.917 236.229 471.848C236.234 471.779 236.212 471.71 236.167 471.654C236.122 471.597 236.056 471.557 235.981 471.539Z"
        fill="#263238"
      />
      <path
        d="M239.648 473.635C234.523 472.724 228.245 473.348 224.273 476.592C224.12 476.717 224.273 476.917 224.481 476.867C229.409 475.506 234.484 474.619 239.62 474.222C239.696 474.211 239.765 474.177 239.816 474.125C239.867 474.074 239.897 474.008 239.9 473.939C239.904 473.87 239.88 473.802 239.834 473.746C239.788 473.691 239.722 473.652 239.648 473.635Z"
        fill="#263238"
      />
      <path
        d="M243.314 475.644C238.189 474.721 231.897 475.357 227.939 478.588C227.772 478.713 227.939 478.913 228.147 478.863C233.075 477.502 238.15 476.616 243.286 476.218C243.371 476.221 243.453 476.194 243.516 476.143C243.578 476.091 243.616 476.02 243.619 475.943C243.623 475.867 243.593 475.793 243.536 475.737C243.478 475.681 243.398 475.647 243.314 475.644Z"
        fill="#263238"
      />
      <path
        d="M242.793 461.558C240.807 459.063 237.029 460.123 234.863 461.708C231.204 464.509 228.427 468.122 226.807 472.188C226.801 472.206 226.799 472.225 226.802 472.244C226.805 472.263 226.813 472.281 226.825 472.297C226.838 472.312 226.853 472.325 226.872 472.335C226.89 472.344 226.911 472.349 226.932 472.35C226.927 472.44 226.945 472.529 226.986 472.611C227.026 472.693 227.087 472.765 227.164 472.822C227.241 472.879 227.332 472.919 227.429 472.939C227.526 472.959 227.627 472.958 227.724 472.937C231.988 471.577 236.724 470.591 240.39 468.096C242.529 466.648 244.668 463.941 242.793 461.558ZM233.71 469.693C231.64 470.404 229.543 471.028 227.488 471.801C228.985 469.722 230.603 467.715 232.335 465.788C233.196 464.864 234.124 463.993 235.113 463.18C236.654 461.932 242.737 459.362 241.765 464.428C241.21 467.185 236.182 468.832 233.71 469.693Z"
        fill="#263238"
      />
      <path
        d="M213.772 470.828C217.939 472.5 222.841 472.475 227.341 472.912C227.8 472.912 228.05 472.525 227.966 472.175C227.986 472.169 228.004 472.159 228.019 472.145C228.034 472.132 228.045 472.115 228.052 472.097C228.059 472.079 228.061 472.06 228.058 472.041C228.055 472.022 228.048 472.004 228.036 471.988C225.42 468.367 221.789 465.429 217.508 463.467C214.994 462.406 211.106 462.094 209.717 464.902C208.328 467.709 211.314 469.892 213.772 470.828ZM211.508 467.509C209.258 462.806 215.842 464.066 217.661 464.939C218.825 465.527 219.943 466.186 221.008 466.91C223.258 468.383 225.175 470.104 227.286 471.764C225.091 471.427 222.883 471.252 220.675 470.99C218.05 470.678 212.744 470.104 211.508 467.509Z"
        fill="#263238"
      />
      <path
        d="M232.526 471.502L176.167 472.625C176.167 472.625 183.028 398.227 181.722 391.714C181.153 388.695 113.668 379.824 92.0158 359.412C73.4468 341.845 90.613 308.945 90.613 308.945L141.667 307.697C141.667 307.697 143.348 321.821 139.695 330.579C139.695 330.579 219.374 347.759 228.318 363.467C237.874 380.223 232.526 471.502 232.526 471.502Z"
        fill="#134074"
      />
      <path
        d="M233.334 384.865C232.973 380.747 232.445 376.642 231.695 372.563C231.306 370.541 230.89 368.52 230.306 366.524C229.913 364.68 229.22 362.897 228.251 361.234C226.237 358.09 222.529 356.094 219.14 354.322C215.444 352.422 211.606 350.754 207.654 349.331C199.501 346.337 190.988 344.041 182.655 341.621C177.599 340.173 172.558 338.714 167.488 337.304C162.419 335.894 157.127 334.621 151.989 333.112C151.877 333.112 151.85 333.237 151.989 333.274C156.475 334.621 160.877 336.181 165.335 337.616C169.794 339.051 174.071 340.386 178.446 341.721L191.502 345.726C195.557 346.973 199.64 348.221 203.654 349.556C207.559 350.873 211.376 352.394 215.084 354.11C218.722 355.603 222.077 357.599 225.029 360.024C228.001 362.756 228.626 366.724 229.417 370.304C230.32 374.384 230.945 378.489 231.431 382.619C232.487 391.739 233.264 400.323 233.681 409.468C233.681 410.08 234.042 410.591 234.056 409.98C234.167 401.608 233.987 393.211 233.334 384.865Z"
        fill="#263238"
      />
      <path
        d="M188.603 391.065C188.454 390.514 188.15 390.006 187.72 389.591C187.289 389.176 186.747 388.867 186.145 388.695C182.951 387.372 179.659 386.2 176.423 385.152C163.382 380.81 149.91 377.666 136.771 373.636C130.094 371.635 123.567 369.251 117.23 366.499C114.133 365.127 111.091 363.629 108.147 361.995C105.007 360.326 102.082 358.351 99.4248 356.106C88.3139 346.324 89.0362 331.278 91.5361 318.564C91.8833 316.83 92.2444 315.108 92.6194 313.374C92.6122 313.358 92.5997 313.344 92.5836 313.334C92.5675 313.324 92.5486 313.319 92.5291 313.319C92.5097 313.319 92.4907 313.324 92.4746 313.334C92.4585 313.344 92.4461 313.358 92.4388 313.374C89.7445 325.913 86.8834 340.024 94.7027 351.615C98.3137 356.967 104.175 360.573 110.077 363.679C116.158 366.815 122.512 369.502 129.077 371.714C142.077 376.193 155.59 379.35 168.798 383.293C172.187 384.291 175.548 385.351 178.867 386.499C180.506 387.073 182.145 387.659 183.77 388.271C185.395 388.882 187.645 389.356 188.131 390.953C188.27 391.709 188.27 392.48 188.131 393.236C188.131 394.185 187.978 395.133 187.909 396.081L187.478 401.621C187.191 405.272 186.904 408.919 186.617 412.563C185.46 427.252 184.316 441.937 183.187 456.617C183.048 458.414 182.909 460.211 182.784 462.007C182.784 462.219 183.117 462.194 183.145 462.007C184.349 447.376 185.515 432.741 186.645 418.102C187.228 410.758 187.807 403.413 188.381 396.069L188.589 393.573C188.707 392.741 188.712 391.899 188.603 391.065Z"
        fill="#263238"
      />
      <path
        d="M109.562 317.067C109.562 316.98 109.367 316.992 109.381 317.067C109.718 319.285 109.533 321.542 108.84 323.692C108.139 325.352 106.93 326.801 105.354 327.872C101.659 330.542 97.0205 330.891 92.5484 331.926C92.34 331.926 92.3678 332.276 92.5484 332.263C97.2982 332.263 102.534 331.278 106.298 328.52C107.94 327.344 109.169 325.767 109.84 323.979C110.464 321.696 110.367 319.303 109.562 317.067Z"
        fill="#263238"
      />
      <path
        d="M230.307 463.454C221.793 463.38 224.238 463.292 215.724 463.317C211.557 463.317 184.169 463.405 180.294 464.028C180.272 464.028 180.251 464.036 180.235 464.05C180.22 464.064 180.211 464.083 180.211 464.103C180.211 464.123 180.22 464.142 180.235 464.156C180.251 464.17 180.272 464.178 180.294 464.178C184.197 464.715 211.571 464.178 215.738 464.178C224.252 464.016 221.807 463.978 230.321 463.729C230.361 463.727 230.4 463.711 230.427 463.684C230.454 463.657 230.469 463.622 230.467 463.585C230.465 463.549 230.447 463.515 230.417 463.49C230.387 463.466 230.348 463.453 230.307 463.454Z"
        fill="#263238"
      />
      <path
        d="M142.151 310.791C141.901 313.599 141.721 316.418 141.346 319.213C141.165 320.535 141.026 321.87 140.748 323.168C140.677 323.736 140.398 324.266 139.957 324.678C139.404 325.033 138.747 325.233 138.068 325.252C137.766 325.32 137.451 325.326 137.146 325.268C136.842 325.21 136.556 325.091 136.311 324.919C136.066 324.746 135.867 324.526 135.731 324.275C135.595 324.023 135.525 323.747 135.526 323.467C135.262 320.91 136.096 312.014 136.11 311.29C136.11 311.141 135.596 311.141 135.568 311.29C134.804 316.905 133.776 324.054 135.096 325.277C135.805 325.908 136.744 326.29 137.741 326.352C138.738 326.414 139.726 326.152 140.526 325.613C141.216 325.007 141.636 324.195 141.707 323.33C141.915 322.083 141.971 320.835 142.068 319.587C142.318 316.655 142.596 313.723 142.665 310.779C142.665 310.542 142.165 310.529 142.151 310.791Z"
        fill="#263238"
      />
      <path
        d="M140.561 326.162C140.462 326.512 140.383 326.866 140.325 327.223C140.242 327.585 140.159 327.934 140.062 328.283C139.964 328.633 139.881 328.994 139.77 329.356C139.659 329.718 139.603 330.055 139.52 330.417C139.518 330.447 139.527 330.476 139.545 330.501C139.563 330.526 139.59 330.545 139.622 330.555C139.653 330.565 139.688 330.565 139.719 330.556C139.751 330.547 139.779 330.529 139.798 330.504C139.98 330.179 140.142 329.846 140.284 329.506C140.409 329.144 140.534 328.795 140.645 328.433C140.756 328.071 140.839 327.722 140.923 327.36C141.041 326.999 141.111 326.626 141.131 326.25C141.131 326 140.659 325.9 140.561 326.162Z"
        fill="#263238"
      />
      <path
        d="M144.191 267.66C144.858 280.136 150.635 321.496 162.454 327.984C170.218 332.239 205.245 339.887 213.842 334.509C217.606 332.164 221.773 308.932 216.925 308.396C212.078 307.859 183.593 302.582 180.649 300.66C177.704 298.739 162.593 265.065 155.802 255.745C144.01 239.613 143.83 260.947 144.191 267.66Z"
        fill="#F7A9A0"
      />
      <path
        d="M207.305 303.605L195.291 336.717C195.291 336.717 166.736 332.725 156.792 325.139C144.723 315.944 141.098 270.018 141.07 262.669C141.07 250.604 145.029 244.703 150.862 248.521C153.126 249.993 155.681 252.937 158.362 257.579C161.139 262.419 176.194 292.738 182.139 299.55C185.458 303.368 207.305 303.605 207.305 303.605Z"
        fill="#455A64"
      />
      <path
        d="M207.305 303.605L195.291 336.718C195.291 336.718 190.555 336.056 184.264 334.759C175.111 332.862 162.681 329.631 156.792 325.139C144.723 315.944 141.098 270.018 141.07 262.669C141.07 253.936 143.126 248.433 146.501 247.51C147.265 247.354 148.059 247.365 148.818 247.54C149.576 247.716 150.277 248.052 150.862 248.521C153.126 249.993 155.681 252.937 158.362 257.579C161.139 262.42 176.194 292.738 182.139 299.55C185.458 303.368 207.305 303.605 207.305 303.605Z"
        fill="#455A64"
      />
      <path
        d="M189.029 333.199C190.64 328.209 198.07 308.246 199.987 304.029C200.126 303.742 200.362 303.742 200.264 304.029C195.834 319.001 191.112 328.433 189.251 333.212C189.209 333.361 188.987 333.324 189.029 333.199Z"
        fill="#263238"
      />
      <path
        d="M184.264 334.759C175.111 332.862 162.681 329.631 156.792 325.139C144.723 315.944 141.098 270.018 141.07 262.669C141.07 253.936 143.126 248.433 146.501 247.51L184.264 334.759Z"
        fill="#263238"
      />
      <path
        d="M161.11 327.373C159.193 328.795 80.0285 329.643 79.6952 329.144C79.3618 328.645 101.32 253.262 130.277 243.929C134.874 242.457 146.86 243.53 148.152 245.09C158.943 258.065 162.346 326.412 161.11 327.373Z"
        fill="#455A64"
      />
      <path
        d="M143.059 255.42C138.601 255.607 132.518 248.521 130.559 243.305C130.434 242.944 131.698 239.563 133.032 235.495C133.823 233 134.643 230.193 135.198 227.598L152.545 234.622C150.824 237.703 149.532 240.964 148.698 244.329C148.625 244.687 148.593 245.05 148.601 245.414C148.607 245.472 148.607 245.531 148.601 245.589C148.531 247.685 147.295 255.295 143.059 255.42Z"
        fill="#F7A9A0"
      />
      <path
        d="M152.543 234.622C150.822 237.703 149.53 240.964 148.696 244.329C148.623 244.687 148.59 245.05 148.598 245.414C148.605 245.472 148.605 245.531 148.598 245.589C147.962 245.57 147.329 245.494 146.709 245.364C136.182 243.368 135.599 231.103 135.71 227.797L152.543 234.622Z"
        fill="#263238"
      />
      <path
        d="M143.174 207.236C138.438 211.69 133.021 228.296 135.285 233.561C138.577 241.209 148.201 246.15 156.493 241.733C164.548 237.442 171.187 215.707 168.256 210.542C163.951 202.894 150.174 200.661 143.174 207.236Z"
        fill="#F7A9A0"
      />
      <path
        d="M168.057 217.579C160.613 219.563 154.322 211.465 154.322 211.465C153.374 212.509 152.076 213.249 150.622 213.575C149.167 213.901 147.634 213.796 146.252 213.274C146.252 213.274 141.711 222.183 139.002 221.372C134.836 220.124 138.141 207.148 140.391 205.04C141.197 204.402 142.141 203.92 143.164 203.626C144.187 203.332 145.267 203.231 146.336 203.331C147.267 201.932 148.757 200.906 150.502 200.461C154.03 199.351 162.849 204.803 162.849 204.803C163.832 203.796 165.064 203.01 166.446 202.507C166.78 202.377 167.139 202.309 167.503 202.308C167.866 202.307 168.227 202.372 168.561 202.501C168.895 202.629 169.197 202.818 169.447 203.055C169.697 203.292 169.891 203.573 170.016 203.879C169.249 204.413 168.627 205.095 168.196 205.876C166.738 208.446 169.196 209.157 170.071 206.687C170.446 205.781 170.446 204.785 170.071 203.879C171.863 202.632 174.043 202.632 174.946 203.879C176.39 206.088 174.224 215.944 168.057 217.579Z"
        fill="#263238"
      />
      <path
        d="M154.79 224.366C154.79 224.366 154.679 224.366 154.665 224.441C154.36 225.576 153.818 226.811 152.582 226.936V226.999C153.999 227.136 154.707 225.476 154.79 224.366Z"
        fill="#263238"
      />
      <path
        d="M154.032 222.931C152.074 222.307 150.838 225.876 152.644 226.45C154.449 227.024 155.685 223.455 154.032 222.931Z"
        fill="#263238"
      />
      <path
        d="M162.017 226.387C162.017 226.387 162.1 226.475 162.017 226.512C161.6 227.61 161.322 228.92 162.294 229.606V229.669C161.031 229.095 161.419 227.36 162.017 226.387Z"
        fill="#263238"
      />
      <path
        d="M163.518 225.551C165.546 225.976 164.449 229.569 162.574 229.182C160.699 228.795 161.824 225.19 163.518 225.551Z"
        fill="#263238"
      />
      <path
        d="M153.047 220.86C153.611 220.891 154.177 220.891 154.741 220.86C155.347 220.918 155.957 220.795 156.477 220.511C156.618 220.392 156.711 220.235 156.744 220.064C156.777 219.893 156.746 219.717 156.658 219.563C156.431 219.278 156.123 219.055 155.765 218.916C155.408 218.778 155.015 218.729 154.63 218.777C153.833 218.753 153.055 218.998 152.449 219.463C152.337 219.579 152.263 219.72 152.233 219.872C152.203 220.023 152.22 220.179 152.281 220.322C152.343 220.466 152.446 220.592 152.581 220.686C152.716 220.781 152.877 220.841 153.047 220.86Z"
        fill="#263238"
      />
      <path
        d="M165.679 224.179C165.196 223.937 164.732 223.666 164.291 223.368C163.749 223.056 163.263 222.856 162.999 222.295C162.94 222.129 162.943 221.949 163.009 221.785C163.074 221.62 163.197 221.48 163.36 221.384C163.716 221.243 164.107 221.191 164.493 221.232C164.879 221.274 165.246 221.408 165.554 221.621C166.254 221.96 166.788 222.52 167.054 223.193C167.119 223.357 167.125 223.535 167.072 223.702C167.019 223.869 166.909 224.018 166.758 224.126C166.606 224.235 166.421 224.298 166.228 224.307C166.035 224.317 165.843 224.272 165.679 224.179Z"
        fill="#263238"
      />
      <path
        d="M150.08 233.761C150.137 234.166 150.348 234.54 150.677 234.821C151.107 235.024 151.581 235.139 152.066 235.158C152.066 235.158 152.135 235.158 152.066 235.233C151.782 235.354 151.467 235.404 151.154 235.377C150.841 235.351 150.542 235.249 150.288 235.083C150.091 234.916 149.954 234.7 149.892 234.463C149.83 234.225 149.847 233.977 149.941 233.748C149.941 233.748 150.066 233.711 150.08 233.761Z"
        fill="#263238"
      />
      <path
        d="M152.785 231.016C152.939 231.549 153.217 232.046 153.6 232.476C153.982 232.906 154.462 233.259 155.007 233.511C155.465 233.743 155.962 233.907 156.479 233.998L157.062 234.098C157.133 234.102 157.203 234.087 157.263 234.053C157.323 234.019 157.369 233.969 157.396 233.911L158.146 231.528C158.437 231.777 159.951 232.987 160.076 232.538C161.304 228.673 162.09 224.706 162.423 220.698C162.423 220.573 162.215 220.536 162.187 220.698C161.034 224.266 160.576 227.984 159.409 231.59C158.878 231.149 158.28 230.78 157.632 230.492C157.548 230.492 156.798 233.436 156.784 233.461C155.984 233.311 155.225 233.019 154.552 232.601C153.879 232.183 153.307 231.648 152.868 231.028C152.785 230.866 152.701 230.916 152.785 231.016Z"
        fill="#263238"
      />
      <path
        d="M160.957 226.924C161.846 224.428 164.054 222.881 165.874 223.405C167.693 223.929 168.443 226.375 167.541 228.845C166.638 231.315 164.443 232.887 162.638 232.351C160.832 231.815 160.068 229.382 160.957 226.924ZM161.624 227.111C160.846 229.244 161.402 231.328 162.846 231.752C164.291 232.176 166.11 230.779 166.874 228.645C167.638 226.512 167.096 224.428 165.652 224.004C164.207 223.58 162.402 224.977 161.624 227.111Z"
        fill="#455A64"
      />
      <path
        d="M148.441 223.268C148.796 222.471 149.403 221.785 150.186 221.293C150.97 220.802 151.896 220.527 152.851 220.502C153.806 220.477 154.749 220.704 155.563 221.154C156.376 221.604 157.026 222.257 157.432 223.035C157.838 223.812 157.982 224.679 157.846 225.528C157.711 226.378 157.302 227.174 156.67 227.818C156.038 228.461 155.21 228.925 154.289 229.152C153.367 229.379 152.392 229.359 151.483 229.095C150.222 228.681 149.195 227.836 148.625 226.745C148.056 225.653 147.989 224.403 148.441 223.268ZM149.108 223.455C148.927 224.161 148.978 224.9 149.255 225.58C149.532 226.261 150.023 226.855 150.668 227.29C151.313 227.724 152.085 227.982 152.89 228.03C153.695 228.079 154.498 227.916 155.202 227.562C155.906 227.209 156.481 226.679 156.856 226.037C157.232 225.396 157.392 224.671 157.317 223.949C157.242 223.227 156.936 222.541 156.435 221.973C155.935 221.404 155.261 220.979 154.497 220.748C153.383 220.471 152.192 220.599 151.183 221.106C150.174 221.613 149.428 222.457 149.108 223.455Z"
        fill="#455A64"
      />
      <path
        d="M157.668 225.514C160.446 225.514 161.307 226.512 161.307 226.524L161.891 226.175C161.891 226.175 160.849 224.815 157.641 224.927L157.668 225.514Z"
        fill="#455A64"
      />
      <path
        d="M148.915 222.969L149.249 222.42C148.846 222.22 139.291 217.566 138.138 217.18C136.985 216.793 133.791 217.367 133.43 217.429L133.569 218.04C134.969 217.742 136.412 217.646 137.846 217.753C138.777 218.065 145.485 221.297 148.915 222.969Z"
        fill="#455A64"
      />
      <path
        d="M140.011 222.482C140.011 222.482 138.803 215.857 135.637 216.019C132.47 216.181 131.539 224.628 133.956 226.874C134.245 227.181 134.599 227.432 134.998 227.613C135.398 227.793 135.834 227.9 136.28 227.925C136.726 227.951 137.173 227.895 137.595 227.762C138.017 227.628 138.404 227.42 138.734 227.148L140.011 222.482Z"
        fill="#F7A9A0"
      />
      <path
        d="M135.434 218.452C135.434 218.452 135.351 218.452 135.434 218.515C136.158 219.342 136.679 220.298 136.964 221.319C137.249 222.341 137.291 223.404 137.087 224.441C137.07 224.193 136.991 223.952 136.855 223.736C136.719 223.52 136.53 223.335 136.302 223.194C136.075 223.053 135.814 222.961 135.541 222.924C135.268 222.887 134.989 222.907 134.726 222.981C134.707 222.981 134.69 222.988 134.677 222.999C134.664 223.011 134.656 223.027 134.656 223.043C134.656 223.06 134.664 223.076 134.677 223.088C134.69 223.099 134.707 223.106 134.726 223.106C135.144 223.157 135.534 223.324 135.843 223.584C136.151 223.843 136.363 224.182 136.448 224.553C136.578 225.174 136.639 225.805 136.628 226.437C136.628 226.649 137.017 226.699 137.045 226.437C138.42 224.104 138.142 219.899 135.434 218.452Z"
        fill="#263238"
      />
      <path
        d="M148.418 245.227L143.64 254.709L130.863 241.983L126.891 245.726C126.891 245.726 139.029 265.688 139.154 264.765L143.987 256.605L150.098 262.644L150.279 248.084L148.418 245.227Z"
        fill="#EBEBEB"
      />
      <path
        d="M150.646 255.495C150.646 253.087 150.646 250.667 150.41 248.259C150.41 248.184 150.257 248.134 150.229 248.259C149.59 250.554 149.868 259.75 149.757 261.983C149.507 261.721 144.077 256.655 144.021 256.73C142.993 257.978 139.452 263.954 139.202 264.491C137.285 261.334 129.091 248.845 126.785 245.776C126.771 245.762 126.752 245.753 126.732 245.75C126.711 245.746 126.69 245.749 126.671 245.758C126.652 245.766 126.637 245.78 126.628 245.796C126.618 245.813 126.615 245.832 126.619 245.851C128.299 249.182 138.854 265.664 139.021 265.626C140.868 262.831 142.556 259.954 144.077 257.005C144.979 258.09 150.438 263.78 150.465 263.43C150.68 260.79 150.74 258.141 150.646 255.495Z"
        fill="#263238"
      />
      <path
        d="M138.54 271.977C138.54 271.977 134.041 266.487 138.457 264.029C142.874 261.571 146.332 267.647 146.332 267.647L138.54 271.977Z"
        fill="#134074"
      />
      <path
        d="M174.233 308.184L174.775 314.834L174.955 317.029L172.844 315.907L166.442 312.5L138.387 271.727L146.164 267.41L174.233 308.171V308.184Z"
        fill="#134074"
      />
      <path
        d="M174.773 314.846L174.953 317.042L172.828 315.919L174.773 314.846Z"
        fill="#37474F"
      />
      <path
        d="M146.67 266.848L137.574 271.9L138.692 273.524L147.788 268.472L146.67 266.848Z"
        fill="#263238"
      />
      <path
        d="M147.897 268.66L138.801 273.712L139.918 275.336L149.014 270.283L147.897 268.66Z"
        fill="#263238"
      />
      <path
        d="M139.934 275.308L142.864 273.673L151.406 289.319L150.281 289.868L139.934 275.308Z"
        fill="#263238"
      />
      <path
        d="M108.821 258.901C86.0163 279.974 60.8086 316.431 71.0861 324.64C80.4748 332.126 116.043 326.412 138.543 319.313C142.834 317.965 127.015 283.854 122.627 285.713C117.994 287.727 113.226 289.477 108.349 290.953C107.127 291.203 114.655 277.728 122.627 262.133C131.529 244.728 121.849 246.861 108.821 258.901Z"
        fill="#F7A9A0"
      />
      <path
        d="M176.658 284.915C176.519 288.82 165.839 287.709 165.839 287.709C167.366 288.687 168.467 290.112 168.95 291.739C169.644 294.634 164.88 296.892 164.88 296.892C166.229 298.202 167.11 299.845 167.408 301.608C167.769 304.44 163.325 307.335 163.325 307.335C163.825 309.152 163.579 311.071 162.631 312.737C159.853 317.104 151.353 316.917 145.964 317.728C139.993 318.77 134.127 320.247 128.423 322.145L119.965 286.774C119.965 286.774 143.381 275.258 149.784 275.744C155.228 276.169 176.852 279.001 176.658 284.915Z"
        fill="#F7A9A0"
      />
      <path
        d="M165.766 287.597C160.062 286.132 154.181 285.295 148.252 285.102C148.231 285.109 148.213 285.122 148.2 285.139C148.187 285.155 148.18 285.175 148.18 285.196C148.18 285.216 148.187 285.236 148.2 285.253C148.213 285.269 148.231 285.282 148.252 285.289C154.071 285.601 159.932 287.323 165.724 287.784C165.742 287.776 165.757 287.764 165.769 287.749C165.781 287.734 165.789 287.717 165.793 287.7C165.797 287.682 165.797 287.663 165.792 287.646C165.787 287.628 165.778 287.612 165.766 287.597Z"
        fill="#263238"
      />
      <path
        d="M164.749 296.468C163.433 296.199 162.083 296.086 160.735 296.131C159.346 296.131 157.874 296.131 156.443 296.131C153.582 296.131 150.726 296.223 147.874 296.406C147.749 296.406 147.721 296.593 147.874 296.593C150.652 296.593 153.582 296.593 156.429 296.668L160.707 296.817C162.045 296.954 163.396 296.954 164.735 296.817C164.775 296.805 164.809 296.782 164.835 296.752C164.86 296.721 164.874 296.685 164.875 296.647C164.877 296.609 164.866 296.572 164.843 296.54C164.82 296.508 164.787 296.483 164.749 296.468Z"
        fill="#263238"
      />
      <path
        d="M163.218 307.335C162.026 307.106 160.8 307.064 159.593 307.21L155.676 307.298C153.065 307.298 150.468 307.398 147.857 307.36C147.732 307.36 147.718 307.547 147.857 307.547C150.51 307.547 153.148 307.672 155.801 307.697H159.732C160.92 307.798 162.119 307.71 163.273 307.435L163.218 307.335Z"
        fill="#263238"
      />
      <path
        d="M120.304 249.955C104.096 258.04 61.5551 308.021 68.2911 322.482C75.027 336.942 126.623 323.467 126.623 323.467L124.109 284.877C124.109 284.877 110.387 291.116 109.971 290.454C109.554 289.793 121.72 266.749 125.248 259.787C128.776 252.825 128.929 245.651 120.304 249.955Z"
        fill="#455A64"
      />
      <path
        d="M120.329 321.035C119.648 315.919 117.718 294.909 117.648 290.342C117.648 290.03 117.871 289.943 117.912 290.342C120.371 305.614 120.176 316.019 120.551 321.059C120.547 321.086 120.532 321.11 120.509 321.126C120.485 321.143 120.456 321.15 120.426 321.147C120.397 321.144 120.37 321.13 120.352 321.109C120.333 321.088 120.325 321.061 120.329 321.035Z"
        fill="#263238"
      />
      <path
        d="M123.825 284.902C122.325 285.332 120.862 285.861 119.45 286.487C118.172 286.999 116.881 287.51 115.603 288.047C112.825 289.194 110.047 290.405 107.353 291.627C104.659 292.85 101.895 294.123 99.2004 295.37C96.506 296.618 93.895 298.09 91.2006 299.375C91.0756 299.438 91.2006 299.6 91.3117 299.537C94.0894 298.29 96.8671 297.204 99.6448 296.019L107.784 292.526L115.908 289.045L119.88 287.31C121.319 286.746 122.711 286.091 124.047 285.352C124.083 285.312 124.107 285.265 124.114 285.214C124.122 285.163 124.114 285.112 124.091 285.065C124.068 285.019 124.031 284.979 123.984 284.95C123.937 284.921 123.882 284.905 123.825 284.902Z"
        fill="#263238"
      />
      <path
        d="M119.9 273.686C119.025 274.597 118.178 275.507 117.358 276.456C117.206 276.63 117.067 276.817 116.928 276.992C117.164 276.493 117.4 276.007 117.622 275.507C118.532 273.803 119.334 272.053 120.025 270.267C120.025 270.18 119.914 270.143 119.858 270.267C118.772 271.978 117.789 273.739 116.914 275.545C115.983 277.279 115.039 279.013 114.136 280.76C113.233 282.507 112.331 284.254 111.483 286.025C110.56 287.732 109.785 289.501 109.164 291.315C109.164 291.415 109.303 291.465 109.359 291.378C110.517 289.697 111.534 287.942 112.4 286.125C113.317 284.391 114.164 282.619 115.053 280.872C115.428 280.136 115.789 279.4 116.136 278.652L116.581 278.165C116.983 277.716 117.372 277.242 117.747 276.768C118.511 275.794 119.233 274.784 119.942 273.773C119.983 273.698 119.928 273.661 119.9 273.686Z"
        fill="#263238"
      />
      <path
        d="M126.593 322.669C126.556 322.951 126.556 323.236 126.593 323.517C120.649 324.578 114.593 325.875 108.621 326.824C102.553 327.82 96.428 328.516 90.2745 328.907C86.8015 329.095 83.3171 329.019 79.8581 328.683C79.7192 328.683 79.7192 328.87 79.8581 328.882C85.9983 329.47 92.1846 329.567 98.3437 329.169C104.471 328.564 110.555 327.643 116.566 326.412C119.982 325.738 123.413 325.164 126.801 324.291C126.968 324.254 126.663 322.669 126.593 322.669Z"
        fill="#263238"
      />
      <path
        d="M194.35 338.489L149.379 335.694L174.364 285.177L219.322 287.959L194.35 338.489Z"
        fill="#EBEBEB"
      />
      <path
        d="M197.436 338.676L152.465 335.881L177.436 285.364L222.408 288.159L197.436 338.676Z"
        fill="#C7C7C7"
      />
      <path
        d="M182.207 285.663C182.024 286.103 181.746 286.506 181.388 286.849C181.03 287.191 180.601 287.467 180.124 287.66C179.68 287.834 179.596 287.323 180.041 287.161C180.648 286.899 181.138 286.459 181.43 285.913C181.477 285.819 181.519 285.724 181.555 285.626C181.697 285.319 181.75 284.984 181.707 284.653C181.677 284.508 181.594 284.376 181.471 284.279H181.388C181.284 284.247 181.172 284.247 181.069 284.279C180.865 284.32 180.678 284.411 180.527 284.541C180.339 284.69 180.175 284.862 180.041 285.052C179.959 285.143 179.885 285.239 179.819 285.339V285.401V285.501C179.819 285.501 179.819 285.501 179.819 285.576H179.18C179.358 285.23 179.587 284.907 179.86 284.615C180.074 284.362 180.346 284.153 180.657 284.003C180.969 283.852 181.313 283.763 181.666 283.742C181.919 283.755 182.157 283.853 182.332 284.018C182.508 284.182 182.607 284.4 182.61 284.628C182.567 284.996 182.429 285.351 182.207 285.663Z"
        fill="#A6A6A6"
      />
      <path
        d="M185.732 285.888C185.545 286.326 185.263 286.727 184.904 287.068C184.544 287.408 184.113 287.681 183.635 287.872C183.191 288.059 183.121 287.547 183.552 287.373C184.158 287.109 184.647 286.67 184.94 286.125L185.065 285.851C185.204 285.538 185.252 285.199 185.204 284.865C185.182 284.72 185.104 284.588 184.982 284.491H184.899C184.795 284.459 184.683 284.459 184.579 284.491C184.38 284.543 184.196 284.632 184.038 284.753C183.857 284.908 183.694 285.079 183.552 285.264C183.468 285.351 183.413 285.451 183.329 285.551V285.626C183.303 285.657 183.28 285.691 183.26 285.726C183.26 285.726 183.26 285.726 183.26 285.788H182.621C182.801 285.443 183.03 285.12 183.302 284.827C183.514 284.573 183.786 284.363 184.097 284.212C184.409 284.062 184.754 283.974 185.107 283.954C185.361 283.964 185.602 284.061 185.778 284.226C185.954 284.392 186.052 284.611 186.052 284.84C186.036 285.207 185.927 285.565 185.732 285.888Z"
        fill="#A6A6A6"
      />
      <path
        d="M189.252 286.1C188.877 286.986 188.128 287.703 187.169 288.096C186.724 288.271 186.641 287.76 187.085 287.597C187.447 287.454 187.77 287.243 188.034 286.978C188.297 286.714 188.495 286.401 188.613 286.063C188.758 285.756 188.806 285.419 188.752 285.09C188.733 284.944 188.653 284.811 188.53 284.715H188.433C188.334 284.685 188.226 284.685 188.127 284.715C187.923 284.757 187.736 284.847 187.585 284.977C187.393 285.123 187.229 285.296 187.099 285.489C187.017 285.579 186.943 285.675 186.877 285.776V285.838C186.875 285.871 186.875 285.905 186.877 285.938C186.877 285.938 186.877 285.938 186.877 286.013H186.238C186.422 285.666 186.655 285.343 186.933 285.052C187.147 284.799 187.418 284.59 187.73 284.439C188.041 284.289 188.385 284.2 188.738 284.179C188.991 284.188 189.23 284.286 189.404 284.452C189.578 284.617 189.673 284.837 189.669 285.065C189.628 285.436 189.485 285.792 189.252 286.1Z"
        fill="#A6A6A6"
      />
      <path
        d="M192.762 286.325C192.58 286.764 192.301 287.165 191.944 287.506C191.586 287.847 191.156 288.12 190.678 288.308C190.234 288.496 190.164 287.984 190.595 287.809C191.299 287.489 191.848 286.947 192.137 286.287C192.275 285.975 192.323 285.635 192.276 285.302C192.247 285.159 192.17 285.028 192.053 284.927H191.97C191.866 284.896 191.754 284.896 191.651 284.927C191.449 284.974 191.263 285.064 191.109 285.189C190.928 285.344 190.765 285.516 190.623 285.701C190.541 285.791 190.467 285.887 190.401 285.988V286.063C190.394 286.096 190.394 286.13 190.401 286.163C190.393 286.183 190.393 286.205 190.401 286.225H189.762C189.937 285.877 190.166 285.554 190.442 285.264C190.654 285.009 190.926 284.799 191.237 284.648C191.549 284.497 191.894 284.409 192.248 284.391C192.502 284.401 192.742 284.498 192.918 284.663C193.094 284.828 193.192 285.048 193.192 285.277C193.142 285.652 192.995 286.011 192.762 286.325Z"
        fill="#A6A6A6"
      />
      <path
        d="M196.292 286.487C195.911 287.373 195.158 288.09 194.195 288.483C193.75 288.658 193.681 288.146 194.195 287.984C194.556 287.841 194.88 287.63 195.143 287.365C195.407 287.1 195.604 286.788 195.723 286.449C195.868 286.143 195.916 285.806 195.861 285.476C195.842 285.331 195.763 285.197 195.639 285.102H195.556C195.452 285.072 195.34 285.072 195.237 285.102C195.038 285.147 194.856 285.237 194.709 285.364C194.517 285.513 194.349 285.685 194.209 285.876C194.125 285.963 194.07 286.063 193.987 286.162C193.982 286.196 193.982 286.229 193.987 286.262C193.987 286.262 193.987 286.262 193.987 286.337H193.348C193.531 285.991 193.764 285.668 194.042 285.376C194.251 285.122 194.52 284.912 194.83 284.761C195.139 284.61 195.482 284.522 195.834 284.503C196.088 284.513 196.328 284.61 196.504 284.775C196.68 284.941 196.778 285.16 196.778 285.389C196.729 285.787 196.561 286.167 196.292 286.487Z"
        fill="#A6A6A6"
      />
      <path
        d="M199.809 286.761C199.625 287.199 199.346 287.6 198.988 287.941C198.631 288.281 198.202 288.554 197.726 288.745C197.281 288.932 197.198 288.421 197.642 288.246C198.25 287.984 198.74 287.544 199.031 286.998C199.079 286.909 199.121 286.818 199.156 286.724C199.299 286.412 199.351 286.073 199.309 285.738C199.276 285.594 199.193 285.463 199.073 285.364H198.99C198.886 285.333 198.774 285.333 198.67 285.364C198.469 285.411 198.283 285.501 198.128 285.626C197.943 285.778 197.78 285.95 197.642 286.138C197.56 286.228 197.486 286.324 197.42 286.425V286.499C197.413 286.532 197.413 286.566 197.42 286.599H196.781C196.956 286.252 197.185 285.928 197.462 285.638C197.675 285.384 197.946 285.173 198.258 285.02C198.569 284.867 198.913 284.776 199.267 284.753C199.522 284.768 199.76 284.869 199.935 285.036C200.11 285.202 200.209 285.422 200.212 285.651C200.181 286.045 200.043 286.427 199.809 286.761Z"
        fill="#A6A6A6"
      />
      <path
        d="M203.338 286.973C202.956 287.86 202.203 288.576 201.24 288.97C200.796 289.144 200.727 288.633 201.157 288.471C201.763 288.207 202.253 287.768 202.546 287.223L202.671 286.936C202.811 286.624 202.859 286.284 202.81 285.95C202.787 285.809 202.708 285.681 202.588 285.588H202.504C202.401 285.558 202.289 285.558 202.185 285.588C201.984 285.636 201.798 285.726 201.643 285.85C201.459 286.003 201.296 286.175 201.157 286.362C201.074 286.449 201.018 286.549 200.935 286.649V286.711L200.865 286.811C200.865 286.811 200.865 286.811 200.865 286.886H200.227C200.41 286.543 200.638 286.22 200.907 285.925C201.12 285.671 201.391 285.461 201.703 285.31C202.015 285.16 202.359 285.071 202.713 285.052C202.967 285.062 203.207 285.159 203.383 285.324C203.559 285.489 203.657 285.709 203.657 285.938C203.64 286.3 203.531 286.655 203.338 286.973Z"
        fill="#A6A6A6"
      />
      <path
        d="M206.844 287.198C206.468 288.08 205.719 288.793 204.761 289.182C204.316 289.369 204.233 288.857 204.677 288.683C205.282 288.417 205.771 287.978 206.066 287.435C206.114 287.346 206.156 287.254 206.191 287.161C206.337 286.85 206.385 286.509 206.33 286.175C206.308 286.03 206.229 285.898 206.108 285.801H206.011C205.912 285.769 205.804 285.769 205.705 285.801C205.504 285.848 205.318 285.937 205.164 286.063C204.972 286.208 204.807 286.381 204.677 286.574C204.596 286.665 204.521 286.761 204.455 286.861V286.936C204.448 286.969 204.448 287.003 204.455 287.036C204.449 287.056 204.449 287.078 204.455 287.098H203.816C203.996 286.75 204.23 286.427 204.511 286.138C204.724 285.883 204.995 285.672 205.307 285.519C205.618 285.366 205.962 285.275 206.316 285.252C206.571 285.265 206.809 285.365 206.983 285.533C207.156 285.7 207.251 285.921 207.247 286.15C207.211 286.524 207.073 286.884 206.844 287.198Z"
        fill="#A6A6A6"
      />
      <path
        d="M210.357 287.41C210.173 287.85 209.895 288.253 209.537 288.595C209.18 288.938 208.75 289.214 208.273 289.406C207.829 289.581 207.76 289.069 208.19 288.907C208.798 288.646 209.288 288.205 209.579 287.66C209.579 287.56 209.676 287.46 209.718 287.373C209.858 287.06 209.905 286.721 209.857 286.387C209.829 286.248 209.75 286.121 209.634 286.025H209.551C209.447 285.995 209.335 285.995 209.232 286.025C209.028 286.067 208.841 286.157 208.69 286.287C208.506 286.44 208.342 286.611 208.204 286.799C208.121 286.886 208.065 286.986 207.982 287.086V287.148C207.954 287.178 207.93 287.212 207.912 287.248C207.906 287.273 207.906 287.298 207.912 287.323H207.273C207.452 286.977 207.68 286.654 207.954 286.362C208.166 286.107 208.437 285.897 208.749 285.746C209.061 285.595 209.406 285.507 209.759 285.489C210.014 285.498 210.254 285.596 210.43 285.761C210.606 285.926 210.704 286.146 210.704 286.375C210.678 286.739 210.559 287.093 210.357 287.41Z"
        fill="#A6A6A6"
      />
      <path
        d="M213.887 287.635C213.505 288.518 212.752 289.23 211.79 289.619C211.345 289.806 211.276 289.294 211.72 289.119C212.325 288.854 212.814 288.415 213.109 287.872C213.157 287.783 213.199 287.691 213.234 287.597C213.38 287.286 213.428 286.945 213.373 286.612C213.351 286.467 213.272 286.334 213.151 286.237H213.068C212.964 286.206 212.852 286.206 212.748 286.237C212.553 286.289 212.373 286.378 212.22 286.499C212.029 286.649 211.86 286.821 211.72 287.011C211.637 287.098 211.582 287.198 211.498 287.298V287.373C211.491 287.406 211.491 287.44 211.498 287.473C211.492 287.493 211.492 287.515 211.498 287.535H210.859C211.039 287.187 211.273 286.864 211.554 286.574C211.764 286.32 212.033 286.109 212.342 285.956C212.651 285.803 212.994 285.712 213.345 285.688C213.601 285.701 213.841 285.801 214.017 285.969C214.193 286.136 214.29 286.357 214.29 286.587C214.254 286.961 214.116 287.321 213.887 287.635Z"
        fill="#A6A6A6"
      />
      <path
        d="M217.403 287.847C217.219 288.287 216.941 288.689 216.583 289.032C216.226 289.375 215.796 289.65 215.319 289.843C214.875 290.018 214.792 289.506 215.236 289.344C215.844 289.082 216.334 288.642 216.625 288.096C216.625 287.996 216.722 287.897 216.75 287.809C216.894 287.498 216.946 287.159 216.903 286.824C216.864 286.693 216.781 286.575 216.667 286.487H216.583C216.48 286.455 216.367 286.455 216.264 286.487C216.06 286.528 215.873 286.619 215.722 286.749C215.534 286.898 215.37 287.07 215.236 287.26C215.154 287.351 215.08 287.447 215.014 287.547V287.61V287.71C215.014 287.71 215.014 287.71 215.014 287.784H214.375C214.553 287.438 214.782 287.115 215.056 286.824C215.267 286.569 215.539 286.358 215.851 286.208C216.163 286.057 216.507 285.969 216.861 285.95C217.114 285.963 217.352 286.061 217.528 286.226C217.703 286.39 217.802 286.608 217.805 286.836C217.759 287.196 217.621 287.542 217.403 287.847Z"
        fill="#A6A6A6"
      />
      <path
        d="M220.927 288.071C220.546 288.954 219.792 289.667 218.83 290.055C218.386 290.242 218.316 289.731 218.747 289.556C219.353 289.293 219.843 288.853 220.136 288.308L220.261 288.034C220.399 287.721 220.447 287.382 220.4 287.048C220.378 286.904 220.299 286.771 220.177 286.674H220.094C219.991 286.643 219.878 286.643 219.775 286.674C219.576 286.726 219.391 286.816 219.233 286.936C219.049 287.088 218.885 287.26 218.747 287.447C218.664 287.535 218.608 287.635 218.525 287.734V287.809C218.498 287.841 218.475 287.874 218.455 287.909C218.455 287.909 218.455 287.909 218.455 287.971H217.816C217.996 287.626 218.225 287.303 218.497 287.011C218.711 286.757 218.983 286.547 219.294 286.394C219.605 286.242 219.949 286.15 220.302 286.125C220.558 286.138 220.798 286.238 220.974 286.405C221.15 286.572 221.247 286.793 221.247 287.023C221.23 287.39 221.121 287.748 220.927 288.071Z"
        fill="#A6A6A6"
      />
      <path
        d="M218.715 300.61C220.215 305.277 225.02 311.515 224.867 315.121C224.862 316.013 224.536 316.88 223.938 317.593C223.34 318.306 222.502 318.826 221.548 319.076C221.548 319.076 225.173 321.421 224.423 324.678C224.034 326.349 220.715 327.273 220.715 327.273C221.498 327.845 222.092 328.598 222.435 329.451C222.777 330.304 222.855 331.224 222.659 332.114C221.964 334.397 218.742 334.971 218.742 334.971C218.742 334.971 220.451 338.003 218.909 340.124C215.812 344.416 193.535 341.184 191.424 333.311C190.437 329.643 195.826 330.704 195.826 330.704C195.826 330.704 188.479 327.497 189.104 323.355C189.729 319.213 196.646 320.96 196.646 320.96C196.646 320.96 189.549 317.803 190.687 313.573C191.826 309.344 198.437 311.976 198.437 311.976C198.437 311.976 194.035 308.234 195.66 305.177C198.437 300.111 206.618 308.184 211.701 307.847L215.715 300.985L218.715 300.61Z"
        fill="#F7A9A0"
      />
      <path
        d="M222.025 307.56C221.025 303.018 227.886 298.602 227.58 294.135C227.219 288.096 218.886 292.75 215.733 300.985L214.539 307.759L222.025 307.56Z"
        fill="#F7A9A0"
      />
      <path
        d="M218.231 335.145C218.314 335.145 218.314 335.245 218.231 335.245C210.313 335.838 202.383 334.249 195.495 330.691C195.287 330.566 195.398 330.205 195.662 330.292C202.884 332.881 210.486 334.516 218.231 335.145Z"
        fill="#263238"
      />
      <path
        d="M220.829 327.323C220.852 327.323 220.873 327.33 220.888 327.344C220.904 327.359 220.913 327.378 220.913 327.397C220.913 327.417 220.904 327.436 220.888 327.45C220.873 327.464 220.852 327.472 220.829 327.472C212.098 328.035 203.429 325.779 196.386 321.109C196.136 320.935 196.108 320.573 196.386 320.747C201.302 323.717 213.524 326.836 220.829 327.323Z"
        fill="#263238"
      />
      <path
        d="M198.064 311.44C205.008 315.944 212.841 318.003 221.23 319.076C221.341 319.076 221.313 319.225 221.23 319.238C216.966 319.724 211.688 318.539 207.675 317.304C203.941 316.213 200.59 314.264 197.953 311.652C197.772 311.59 197.911 311.44 198.064 311.44Z"
        fill="#263238"
      />
    </svg>
  );
}
