import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import classes from './NavBar.module.css';
import Lang from '../Reusable/SVG/Lang';
import EshtreanaMainLogo2 from '../Reusable/SVG/EshtarenaMainLogo2';
import { useNavigate } from 'react-router-dom';
const NavBar = () => {
  const [activeSection, setActiveSection] = useState('');
  const history = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = [];
      document.querySelectorAll('section').forEach((sec) => {
        const { id, offsetTop } = sec;
        sectionOffsets.push({ id, offsetTop });
      });

      const scrollPosition = window.scrollY + 180; // considering some offset
      const currentSection = sectionOffsets.find(
        (section) =>
          section.offsetTop <= scrollPosition && section.offsetTop + document.getElementById(section.id).offsetHeight - 90 > scrollPosition,
      );

      setActiveSection(currentSection ? currentSection.id : '');
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    setActiveSection(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveSection(id);
  };

  return (
    <Navbar expand="lg" variant="dark" className={`px-0 ${classes['nav-bar']} sticky-top`}>
      <Container>
        <Navbar.Brand
          onClick={() => {
            history('/');
          }}
        >
          <EshtreanaMainLogo2 />
        </Navbar.Brand>
        <Navbar.Toggle
          bg={'light'}
          color="light"
          // iconColor={"light"}
          className="text-light"
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse className=" jsutify-content-lg-end jsutify-content-center" id="basic-navbar-nav">
          <Nav className="ms-auto ">
            <Nav.Link
              className={`${classes['nav-link']} ${activeSection === 'about-us' && classes['active']}`}
              onClick={() => scrollToSection('about-us')}
            >
              About us
            </Nav.Link>
            <Nav.Link className={`${classes['nav-link']} ${activeSection === 'deals' && classes['active']}`} onClick={() => scrollToSection('deals')}>
              Deals
            </Nav.Link>
            <Nav.Link
              className={`${classes['nav-link']} ${activeSection === 'partners' && classes['active']}`}
              onClick={() => scrollToSection('partners')}
            >
              Partners
            </Nav.Link>
            <Nav.Link
              className={`${classes['nav-link']} ${activeSection === 'contact-us' && classes['active']}`}
              onClick={() => scrollToSection('contact-us')}
            >
              Contact us
            </Nav.Link>
            <Nav.Link className={`${classes['nav-link']} `} onClick={() => history('/login')}>
              Login
            </Nav.Link>
            {/* <Nav.Link className={`${classes["nav-link1"]}`}>
              <Lang color="white" />
              &nbsp; العربية
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
