import { Policy } from "@mui/icons-material";
import axios from "axios";
// import verifyAccount from '../common/VerifyAccount';

export const coldDealsSupplierService = {
  getHistoryDeals,
  getOnGoingDeals,
  joinDeal,
};

async function getOnGoingDeals(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/cold-deal/supplier/on-going?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function getHistoryDeals(page, size, query) {
  try {
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/cold-deal/supplier/history?page=${page}&size=${size}${
        query ? query : ""
      }`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function joinDeal(id, data) {
  try {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/cold-deal/join-deal/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
