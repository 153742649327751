import React from "react";

export default function DocsActiveL() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1469 20.9125C20.1238 20.8803 20.0932 20.8541 20.0579 20.8362C20.0225 20.8182 19.9834 20.809 19.9438 20.8094H18.5625C18.3594 20.8094 18.2406 21.0406 18.3594 21.2062L20.6656 24.3969C20.7656 24.5344 20.9688 24.5344 21.0688 24.3969L24.6375 19.4594C24.7563 19.2938 24.6375 19.0625 24.4344 19.0625H23.05C22.9688 19.0625 22.8937 19.1 22.8469 19.1656L20.8625 21.9094L20.1469 20.9125ZM21.5 9.5625V8.0625C21.5 7.925 21.3875 7.8125 21.25 7.8125H9.25C9.1125 7.8125 9 7.925 9 8.0625V9.5625C9 9.7 9.1125 9.8125 9.25 9.8125H21.25C21.3875 9.8125 21.5 9.7 21.5 9.5625ZM9.25 12.3125C9.1125 12.3125 9 12.425 9 12.5625V14.0625C9 14.2 9.1125 14.3125 9.25 14.3125H15C15.1375 14.3125 15.25 14.2 15.25 14.0625V12.5625C15.25 12.425 15.1375 12.3125 15 12.3125H9.25ZM15 26.625H6.5V4.625H24V13.875C24 14.0125 24.1125 14.125 24.25 14.125H26C26.1375 14.125 26.25 14.0125 26.25 13.875V3.375C26.25 2.82187 25.8031 2.375 25.25 2.375H5.25C4.69687 2.375 4.25 2.82187 4.25 3.375V27.875C4.25 28.4281 4.69687 28.875 5.25 28.875H15C15.1375 28.875 15.25 28.7625 15.25 28.625V26.875C15.25 26.7375 15.1375 26.625 15 26.625ZM27.5812 16.6L21.5812 14.5156C21.5531 14.5063 21.5281 14.5031 21.5 14.5031C21.4719 14.5031 21.4438 14.5063 21.4188 14.5156L15.4187 16.6C15.3697 16.6166 15.3271 16.6482 15.2969 16.6902C15.2666 16.7322 15.2502 16.7826 15.25 16.8344V24.6812C15.25 24.7594 15.2844 24.8312 15.3469 24.8781L21.3469 29.5719C21.3906 29.6062 21.4469 29.625 21.5 29.625C21.5531 29.625 21.6094 29.6062 21.6531 29.5719L27.6531 24.8781C27.7125 24.8312 27.75 24.7594 27.75 24.6812V16.8344C27.75 16.7281 27.6812 16.6344 27.5812 16.6ZM25.8125 23.8656L21.5 27.2375L17.1875 23.8656V18.0312L21.5 16.5312L25.8125 18.0312V23.8656Z"
        fill="#6E7980"
      />
    </svg>
  );
}
