import Img from '../Img';
import React from 'react';
import Map from '../Map/Map';
import { useState } from 'react';
import Close from '../../SVG/Close';
import Modal from '../../UI/Modal/Modal';
import { useDispatch } from 'react-redux';
import Button from '../../UI/Button/Button';
import classes from './AddMember.module.scss';
import InputGroup from '../../UI/InputGroup/InputGroup';
import TextAreaGroup from '../../UI/InputGroup/TextAreaGroup';
import { errorActions } from '../../../../store/reducers/errorSlice';
import { serverErrorCatcher } from '../../../../Helper/ServerErrorCatcher';
import { supplierTopManagerService } from '../../../../services/supplierTopManagerService';
export default function AddBranche(props) {
  //--------------------Declarations----------------------------
  const dispatch = useDispatch();
  const [showMap, setShowMap] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [serverErrorMsg, setServerErrorMsg] = useState(null);
  const [data, setData] = useState({
    titleEN: null,
    titleAR: null,
    noEmployee: 0,
    locationDetailsEN: null,
    locationDetailsAR: null,
    location: { lng: 0, lat: 0 },
    openAt: null,
    closeAt: null,
  });
  const [dataErr, setDataErr] = useState({
    titleEN: false,
    titleAR: false,
    noEmployee: 0,
    locationDetailsEN: false,
    locationDetailsAR: false,
    location: true,
    openAt: false,
    closeAt: false,
  });

  //--------------------Functions----------------------------
  let handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    e.target.value.replace(/\s/g, '').length === 0
      ? setDataErr((prev) => ({ ...prev, [e.target.name]: false }))
      : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === 'noEmployee') {
      var isANumber = isNaN(e.target.value) === false;

      isANumber ? setDataErr((prev) => ({ ...prev, [e.target.name]: true })) : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      ((e.target.value.startsWith('0') && e.target.value.length > 1) ||
        e.target.value.startsWith('-') ||
        e.target.value === '' ||
        e.target.value.includes('.') ||
        e.target.value.startsWith('+')) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
  };
  let handleSubmit = async () => {
    setShowError(true);
    if (
      dataErr.closeAt &&
      dataErr.location &&
      dataErr.locationDetailsAR &&
      dataErr.locationDetailsEN &&
      dataErr.noEmployee &&
      dataErr.openAt &&
      dataErr.titleAR &&
      dataErr.titleEN
    ) {
      let response = await supplierTopManagerService.addBranch({
        title_en: data.titleEN,
        title_ar: data.titleAR,
        NumberOfEmployeePerBranch: data.noEmployee,
        location_en: data.locationDetailsEN,
        location_ar: data.locationDetailsAR,
        openAt: data.openAt,
        closeAt: data.closeAt,
        lat: data.location.lat.toString(),
        lng: data.location.lng.toString(),
        supplier: props.id,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        props.onSucess();
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem('errorMsg'),
          }),
        );
      }
    }
    setLoadingButton(false);
  };
  return (
    <div
      style={{
        height: `${window.innerHeight - (64 + 24 + 24)}px`,
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {showMap && (
        <Modal
          onClose={() => setShowMap(false)}
          style={{
            // position: "absolute",
            left: '7%',
            padding: 0,
            top: '75px',
            width: '87%',
            height: `${window.innerHeight - 150}px`,
          }}
        >
          <Map
            type={'pick'}
            location={data.location}
            setLocation={(location) => {
              setData((prev) => ({ ...prev, location: location }));
              setDataErr((prev) => ({ ...prev, location: true }));
            }}
            setShowForm={setShowMap}
          />
        </Modal>
      )}

      <div
        style={{
          overflowY: 'auto',
        }}
        className=" row mx-0 px-0 "
      >
        <div className={`col-10 px-0 ${classes.title}`}>Add Branch</div>
        <div onClick={() => props.setShowForm(false)} className="cursor col-2 px-0 text-end">
          <Close color="#134074" />
        </div>
      </div>

      <div className="row px-0 mx-0">
        <div className="col-6 px-0 pe-2">
          <div className={classes.input}>
            <InputGroup
              default={data.titleEN}
              name="titleEN"
              onChange={(e) => handleChange(e)}
              error={showError && !dataErr.titleEN}
              errorMsg="Provide a Branch Title"
              value={'Branch Title'}
            />
          </div>
        </div>
        <div className="col-6 px-0 ps-2">
          <div className={classes.input}>
            <InputGroup
              default={data.titleAR}
              name="titleAR"
              onChange={(e) => handleChange(e)}
              error={showError && !dataErr.titleAR}
              errorMsg="من فضلك سجل اسم الفرع"
              value={'اسم الفرع'}
            />
          </div>
        </div>
      </div>

      <div className={classes.input}>
        <InputGroup
          default={data.noEmployee}
          name="noEmployee"
          onChange={(e) => handleChange(e)}
          error={showError && !dataErr.noEmployee}
          errorMsg={data.noEmployee ? 'Provide a Valid Number od Employee Per Branch' : 'Provide a Number of Employee per Branch'}
          value={`Number of Employee per Branch`}
        />
      </div>
      <div className="row px-0 mx-0">
        <div className="col-6 px-0 pe-2">
          <div className={classes.input}>
            <TextAreaGroup
              default={data.locationDetailsEN}
              name="locationDetailsEN"
              onChange={(e) => handleChange(e)}
              error={showError && !dataErr.locationDetailsEN}
              errorMsg="Provide a Location Details"
              value={'Location Description'}
            />
          </div>
        </div>
        <div className="col-6 px-0 ps-2">
          <div className={classes.input}>
            <TextAreaGroup
              default={data.locationDetailsAR}
              name="locationDetailsAR"
              onChange={(e) => handleChange(e)}
              error={showError && !dataErr.locationDetailsAR}
              errorMsg="من فضلك سجل العنوان بالتفاصيل"
              value={'العنوان بالتفاصيل'}
            />
          </div>
        </div>
      </div>

      <div className="row px-0 mx-0">
        <div className="col-12 px-0 pe-2">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowMap(true);
            }}
            className={classes.input}
          >
            <Img
              onClick={(e) => {
                e.stopPropagation();
                setShowMap(true);
              }}
              noShow={true}
              className={`${classes['map']} cursor`}
              src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg"
            />
          </div>
          {showError && !dataErr.location && <span className="errorMsg mb-3">&nbsp;&nbsp;Provide Branch Location</span>}
        </div>
      </div>

      <div className="row px-0 mx-0">
        <div className="col-6 px-0 pe-2">
          <div className={classes.input}>
            <InputGroup
              default={data.openAt}
              name="openAt"
              onChange={(e) => handleChange(e)}
              error={showError && !dataErr.openAt}
              errorMsg="Provide an Open Time"
              value={'Open At'}
              type={'time'}
            />
          </div>
        </div>
        <div className="col-6 px-0 ps-2">
          <div className={classes.input}>
            <InputGroup
              default={data.closeAt}
              name="closeAt"
              onChange={(e) => handleChange(e)}
              error={showError && !dataErr.closeAt}
              errorMsg="Provide a Close Time"
              value={'Close At'}
              type={'time'}
            />
          </div>
        </div>
      </div>

      {serverError && (
        <div className="text-center">
          <span className="errorMsg text-cenetr">{serverErrorMsg}</span>
        </div>
      )}

      <div className="row mx-0 px-0  mb-pb-0">
        <div className="col-4 ms-auto px-0  mb-pb-0">
          <div className={`  mb-pb-0`}>
            {loadingButton ? (
              <div className="text-center">
                <span className="spinner-border spinner-border-sm"></span>
              </div>
            ) : (
              <Button
                type="normal"
                color={'#D1E6FF'}
                text={'Save Changes'}
                fontColor={'#134074'}
                onClick={() => {
                  setLoadingButton(true);
                  handleSubmit();
                }}
                borderColor={'#134074'}
                className={'w-auto px-2 ms-auto text-end'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
//Done
