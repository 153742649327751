import axios from 'axios';
// import verifyAccount from '../common/VerifyAccount';

export const aboutService = {
  getAllAbout,
  addAbout,
  editAbout,
  deleteAbout,
};

async function getAllAbout(type) {
  try {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/about?type=${type}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function addAbout(data) {
  try {
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/about`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };
    let response = await axios(config);
    //console.log(response);
    return response;
  } catch (e) {
    return e;
  }
}

async function editAbout(data, id) {
  try {
    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/about/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data,
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
async function deleteAbout(id) {
  try {
    var config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/about/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    let response = await axios(config);
    return response;
  } catch (e) {
    return e;
  }
}
