import React from "react";

export default function Filters() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_329_41650)">
        <path
          d="M0.75 4.23092H12.169C12.5131 5.79743 13.9121 6.97348 15.5805 6.97348C17.2488 6.97348 18.6478 5.79748 18.9919 4.23092H23.25C23.6642 4.23092 24 3.89511 24 3.48092C24 3.06673 23.6642 2.73092 23.25 2.73092H18.9915C18.6467 1.1652 17.2459 -0.0115967 15.5805 -0.0115967C13.9141 -0.0115967 12.5139 1.16501 12.1693 2.73092H0.75C0.335812 2.73092 0 3.06673 0 3.48092C0 3.89511 0.335812 4.23092 0.75 4.23092ZM13.588 3.48289L13.588 3.47483C13.5913 2.3795 14.4851 1.48845 15.5805 1.48845C16.6743 1.48845 17.5681 2.37828 17.5728 3.47309L17.573 3.48411C17.5712 4.58131 16.6781 5.47353 15.5805 5.47353C14.4833 5.47353 13.5904 4.5822 13.5879 3.48565L13.588 3.48289ZM23.25 19.7691H18.9915C18.6467 18.2034 17.2459 17.0266 15.5805 17.0266C13.9141 17.0266 12.5139 18.2032 12.1693 19.7691H0.75C0.335812 19.7691 0 20.1049 0 20.5191C0 20.9333 0.335812 21.2691 0.75 21.2691H12.169C12.5131 22.8356 13.9121 24.0117 15.5805 24.0117C17.2488 24.0117 18.6478 22.8356 18.9919 21.2691H23.25C23.6642 21.2691 24 20.9333 24 20.5191C24 20.1049 23.6642 19.7691 23.25 19.7691ZM15.5805 22.5117C14.4833 22.5117 13.5904 21.6203 13.5879 20.5238L13.588 20.5211L13.588 20.513C13.5913 19.4177 14.4851 18.5266 15.5805 18.5266C16.6743 18.5266 17.5681 19.4164 17.5728 20.5112L17.573 20.5222C17.5714 21.6195 16.6782 22.5117 15.5805 22.5117ZM23.25 11.25H11.831C11.4869 9.68351 10.0879 8.50751 8.41955 8.50751C6.75117 8.50751 5.35223 9.68351 5.00808 11.25H0.75C0.335812 11.25 0 11.5858 0 12C0 12.4143 0.335812 12.75 0.75 12.75H5.00845C5.35331 14.3157 6.75413 15.4925 8.41955 15.4925C10.0859 15.4925 11.4861 14.3159 11.8307 12.75H23.25C23.6642 12.75 24 12.4143 24 12C24 11.5858 23.6642 11.25 23.25 11.25ZM10.412 11.9981L10.412 12.0061C10.4087 13.1014 9.51492 13.9925 8.41955 13.9925C7.32572 13.9925 6.43191 13.1027 6.42717 12.0079L6.42703 11.9969C6.42867 10.8996 7.32188 10.0075 8.41955 10.0075C9.5167 10.0075 10.4096 10.8988 10.4121 11.9954L10.412 11.9981Z"
          fill="#91C8E4"
        />
      </g>
      <defs>
        <clipPath id="clip0_329_41650">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
