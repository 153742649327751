import React from 'react';

export default function Voucher(props) {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1H4C3.068 1 2.602 1 2.234 1.152C1.99137 1.25262 1.77093 1.40005 1.58528 1.58588C1.39963 1.7717 1.2524 1.99227 1.152 2.235C1 2.602 1 3.068 1 4C1.79565 4 2.55871 4.31607 3.12132 4.87868C3.68393 5.44129 4 6.20435 4 7C4 7.79565 3.68393 8.55871 3.12132 9.12132C2.55871 9.68393 1.79565 10 1 10C1 10.932 1 11.398 1.152 11.765C1.2524 12.0077 1.39963 12.2283 1.58528 12.4141C1.77093 12.5999 1.99137 12.7474 2.234 12.848C2.602 13 3.068 13 4 13H12M12 1H16C16.932 1 17.398 1 17.765 1.152C18.0078 1.25251 18.2284 1.3999 18.4143 1.58572C18.6001 1.77155 18.7475 1.99218 18.848 2.235C19 2.602 19 3.068 19 4C18.2044 4 17.4413 4.31607 16.8787 4.87868C16.3161 5.44129 16 6.20435 16 7C16 7.79565 16.3161 8.55871 16.8787 9.12132C17.4413 9.68393 18.2044 10 19 10C19 10.932 19 11.398 18.848 11.765C18.7475 12.0078 18.6001 12.2284 18.4143 12.4143C18.2284 12.6001 18.0078 12.7475 17.765 12.848C17.398 13 16.932 13 16 13H12M12 1V13"
        stroke={props.color || '#134074'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
