import React from 'react';

export default function AdviceManager(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 19H8C4 19 2 18 2 13V7.99997C2 3.99997 4 1.99997 8 1.99997H16C20 1.99997 22 3.99997 22 7.99997V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.9965 11H16.0054" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9955 11H12.0045" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.99451 11H8.00349" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
