import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import classes from "./InputGroup.module.scss";
import Label from "../Label/Label";
import Recycle from "../../SVG/Recycle";
import InputFeild from "../InputFeild/InputFeild";
export default function PhoneGroup(props) {
  return (
    <div className="row px-0 ">
      <div className="row px-0 mx-0 ">
        <div className="w-auto  d-inline">
          <Label for={props.name} value={props.value} />
        </div>
        {props.linkText && (
          <div className={`w-auto d-inline text-end ms-auto ${classes.green}`}>
            {props.linkText.text}
          </div>
        )}
      </div>
      <div className="row mx-0 px-0 align-items-center">
        <div className={props.name === "phone1" ? "col-12" : "col-11  "}>
          {/* <PhoneInput
            onChange={(e) => {
              props.onChange(e);
            }}
            className={classes.phone}
            placeholder={props.value}
          />
        */}
          <InputFeild
            handleClear={props.handleClear}
            clear={props.clear}
            default={props.default}
            onChange={(e) => props.onChange(e)}
            type={props.type}
            error={props.error}
            //   error={props.showErr && !props.fieldErr && true}
            name={props.name}
            placeholder={props.value}
          />
        </div>
        {props.remove && (
          <div onClick={props.onDelete} className="cursor col-1">
            <Recycle />
          </div>
        )}
      </div>
      {props.error && <span className="errorMsg">{props.errorMsg}</span>}
    </div>
  );
}
