import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  scan: false,
  counter: 0,
};

const scanSlice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    setScan(state, action) {
      state.scan = action.payload.scan;
      state.counter = state.counter + 1;
    },
  },
});

export const scanReducer = scanSlice.reducer;
export const scanActions = scanSlice.actions;
