import React, { useEffect, useState } from 'react';
import './index.css';
import five from '../../../src/assests/7.png';
export default function Section42() {
  const [width, setWidth] = useState(1400);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);
  return (
    <div className="">
      <div className="container">
        <div
          style={{
            height: `${window.innerWidth > 1200 ? `${window.innerHeight}px` : 'auto'}`,
            paddingBottom: `${window.innerWidth < 1200 && '100px'}`,
          }}
          className="row   d-flex align-items-center   px-0 mx-0  d-flex"
        >
          <div
            style={{ height: `${window.innerWidth > 1200 ? `${window.innerHeight}px` : 'auto'}` }}
            className="col-xl-8   order-2 d-flex flex-column text-xl-start text-center  pt-xl-0 pt-4 justify-content-xl-center position-relative "
          >
            <h1 className={'main-title  text-xl-start text-center'}>What is Cold deal? </h1>
            <p className="sub-title text-xl-start text-center">
              Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <ul className="px-0 mx-0">
              <div className="row px-0 text-start justify-content-start mx-0 align-items-center">
                <div className="w-auto d-inline text-end px-0 mx-0 d-xl-inline d-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className="w-auto d-inline mx-xl-0 mx-auto">
                  <li className="text-xl-start text-center">Choose your product easily</li>
                </div>
              </div>
              <div className="row px-0 text-start justify-content-start mx-0 align-items-center">
                <div className="w-auto d-inline text-end px-0 mx-0 d-xl-inline d-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className="w-auto d-inline mx-xl-0 mx-auto">
                  <li className="text-xl-start text-center">Choose your product easily</li>
                </div>
              </div>
              <div className="row px-0 text-start justify-content-start mx-0 align-items-center">
                <div className="w-auto d-inline text-end px-0 mx-0 d-xl-inline d-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className="w-auto d-inline mx-xl-0 mx-auto">
                  <li className="text-xl-start text-center">Choose your product easily</li>
                </div>
              </div>
            </ul>
          </div>
          <div
            // style={{ height: `${window.innerWidth > 1200 ? window.innerHeight : window.innerHeight * 0.5}px` }}
            className="col-xl-4   order-1 pt-xl-0 pt-5 text-xl-start text-center justify-content-center align-items-center d-flex mb-xl-0  mb-5"
          >
            <img
              alt="image_feature"
              style={{
                width: `100%`,
                objectFit: 'contain',
                height: '100%',
                maxwidth: `${window.innerWidth}px`,
                maxHeight: `${window.innerHeight - 200}px`,
                // height: `${window.innerWidth > 1200 ? window.innerHeight - 200 : window.innerHeight * 0.4}px`,
              }}
              src={five}
              // alt="feature_image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
