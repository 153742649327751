import React, { useState } from 'react';
import classes from './Product.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import ProductActiveL from '../../components/Reusable/SVG/ProductActiveL';
import Plus from '../../components/Reusable/SVG/Plus';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import Table from '../../components/Reusable/Common/Table/Table';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { productService } from '../../services/ProductServices';
import { useEffect } from 'react';
import Edit from '../../components/Reusable/SVG/Edit';
import Recycle from '../../components/Reusable/SVG/Recycle';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import RemoveCard from '../../components/Reusable/Common/AddMemberCard/RemoveMember';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import Eye2 from '../../components/Reusable/SVG/Eye2';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';

export default function Products() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [sort, setSort] = useState('Default');
  const [searchValue, setSearchValue] = useState('');
  const [wholeProducts, setWholeProducts] = useState([]);
  const [editData, setEditData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('select category');
  const [selectedSubCategory, setSelectedSubCategory] = useState('select sub-category');
  const [showDeleteForm, setShowDeleteForm] = useState(false);

  let getProducts = async () => {
    let query = '';
    query += `${searchValue ? `&search=${searchValue}` : ''}`;
    query += `${
      selectedCategory && selectedSubCategory !== ' ' && selectedCategory !== 'select category'
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ''
    }`;
    query += `${
      selectedSubCategory && selectedSubCategory !== ' ' && selectedSubCategory !== 'select sub-category'
        ? `&subcategory=${selectedSubCategory.toLowerCase()}`
        : ''
    }`;
    query += `${sort && sort !== 'Default' ? `&date=${sort.toLowerCase()}` : ''}`;

    let response = await productService.getProducts(page, 5, query ? query : false);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setWholeProducts(response.data.products);
      let arr = response.data.products.map((item) => [
        item.uuid,
        item.name_en,
        item.category,
        item.subcategory,
        item.price,
        item.createdAt.slice(0, 10),
        <>
          <div className="row  px-0  mx-0">
            <div
              onClick={(e) => {
                e.stopPropagation();
                history(`/account/product/${item._id}`);
              }}
              className=" col-xl-4 col-12 justify-content-center text-center  "
            >
              <Eye2 color="#91C8E4" />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                history(`/account/product/${item._id}/edit`);
              }}
              className=" col-xl-4 col-12 justify-content-center text-center  "
            >
              <Edit color="#91C8E4" />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setEditData(item);
                setShowDeleteForm(true);
              }}
              className="  col-xl-4 col-12 justify-content-center text-center  "
            >
              <Recycle color="#91C8E4" />
            </div>
          </div>
        </>,
      ]);
      setProducts(arr);
      setTotalPages(response.data.totalPages);
    } else {
    }
    setLoading(false);
  };

  let deleteProduct = async () => {
    let response = await productService.deleteProduct(editData._id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setLoading(true);
      setShowConfirmation(true);
      setShowDeleteForm(false);
      setTimeout(() => {
        setShowConfirmation(false);
        getProducts();
      }, 2000);
    } else {
      setShowDeleteForm(false);
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  useEffect(() => {
    mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
  }, []);
  useEffect(() => {
    if (!firstLoad) {
      if (selectedCategory !== 'select category') {
        mainDataHelper.getAllSubCategories(setServerError, setServerErrorMsg, selectedCategory, categories, setSubCategories, setSubCategoriesName);
      } else {
        setSelectedSubCategory('select sub-category');
        setSubCategories([]);
        setSubCategoriesName([]);
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (!firstLoad) {
      !loading && setLoading(true);
      setPage(1);
      selectedCategory === 'select category' && setSelectedSubCategory('select sub-category');
      getProducts();
    }
  }, [sort, selectedCategory, selectedSubCategory]);
  useEffect(() => {
    if (!firstLoad) {
      !loading && setLoading(true);
      let timer = setTimeout(() => {
        page === 1 ? getProducts() : setPage(1);
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchValue]);

  useEffect(() => {
    if (serverError) {
      setTimeout(() => {
        setServerError(false);
      }, 2000);
    }
  }, [serverError]);
  useEffect(() => {
    !loading && setLoading(true);
    getProducts();
    setFirstLoad(false);
  }, [page]);
  return (
    <div className={`w-100 ${classes['product-page']}`}>
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className={'backdrop'}
            onClick={() => {
              setShowFilters(false);
            }}
          ></div>,
          document.getElementById('overlays'),
        )}
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup setShowForm={setShowConfirmation} message1={`Product Deleted Successfully`} />
        </Modal>
      )}
      {showDeleteForm && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowDeleteForm(false);
          }}
        >
          <RemoveCard
            setShowForm={setShowDeleteForm}
            message1="Are you sure you want to remove this Prduct?"
            message2="By removing this Product, it won’t show anymore."
            buttonText="Remove Product"
            onClick={() => deleteProduct()}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          onClick={() => history('/account/product/new')}
          text={'Products'}
          icon={
            <div className=" w-auto d-inline">
              <ProductActiveL />
            </div>
          }
          buttonText={`Add Product`}
          buttonType="normal"
          buttonIcon={<Plus />}
        />
        <section className={`${classes['main-section']}`}>
          {!firstLoad && (
            <div className={`row   mx-0 px-0  align-items-end  ${classes['table-option']}`}>
              <div className="col-xl-3  col-12 px-0 mb-xl-0 mb-2 ">
                <SearchInput onChange={(val) => setSearchValue(val.target.value)} placeholder={`Search for a Product`} />
              </div>
              <div className="col-xl-9 px-0 ps-xl-2">
                <div className="row px-0 mx-0 mb-xl-0 mb-2">
                  <div className="col-xl-3  col-4 pe-1 px-0">
                    <InputGroup
                      options={['select category', ...categoriesName]}
                      filter={true}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      type="dropdown"
                      value={'Category'}
                      default={selectedCategory}
                    />
                  </div>
                  {selectedCategory !== 'select category' && (
                    <div className="col-xl-3 col-4 ps-1 pe-1 px-0">
                      <InputGroup
                        options={['select sub-category', ...subCategoriesName]}
                        filter={true}
                        onChange={(e) => setSelectedSubCategory(e.target.value)}
                        type="dropdown"
                        value={'Sub Category'}
                        default={selectedSubCategory}
                      />
                    </div>
                  )}
                  <div className="col-xl-3 col-4 px-0 ps-1">
                    <InputGroup
                      options={['Latest', 'Earliest', 'Default']}
                      filter={true}
                      onChange={(e) => setSort(e.target.value)}
                      // error={showError && formDataErr.password}
                      // errorMsg={formDataErr.password}
                      // name="password"
                      type="dropdown"
                      value="Order By"
                    />
                  </div>
                </div>
              </div>
              {/* <div
              onClick={() => {
                setShowFilters(true);
              }}
              className="w-auto ms-auto  cursor position-relative"
            >
              <Filters />
              &nbsp;&nbsp;Filter
              {showFilters && (
                <div
                  style={{ zIndex: "50000" }}
                  className={`position-absolute ${classes["table-options"]}`}
                >
                  {["Latest", "Earliest", "Default"].map((item, index) => (
                    <p
                      key={index}
                      className="row    py-0  h-auto py-0 px-0 mx-0"
                    >
                      {" "}
                      <div className=" h-auto  col-2 py-0 px-0">
                        <Radio
                          style={{ lineHeight: 0, height: "0px" }}
                          // style={{ position: 'absolute', left: '0' }}
                          checked={sort === item && true}
                          id={`sortt${index}`}
                          value={item}
                          name="sort"
                          //   color="success"
                          onChange={() => {
                            setLoading(true);
                            setSort(item);
                          }}
                        />
                      </div>
                      <div
                        // style={{ position: 'relative', top: '10px' }}
                        className="col-9  py-0 "
                      >
                        {item}
                      </div>
                    </p>
                  ))}
                </div>
              )}
            </div> */}
            </div>
          )}
          {loading ? (
            <>
              <Table
                options={[
                  {
                    title: 'Product ID',
                    icon: false,
                  },
                  {
                    title: 'Product',
                    icon: false,
                  },
                  {
                    title: 'Category',
                    icon: false,
                  },
                  {
                    title: 'Sub Category',
                    icon: false,
                  },

                  {
                    title: 'Market Price',
                    icon: false,
                  },

                  {
                    title: 'Date',
                    icon: false,
                  },
                  {
                    title: 'Actions',
                    icon: false,
                  },
                ]}
                data={[
                  [
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                  ],
                ]}
                customStyleIndex={[]}
                customStyleFn={[false, false, false, false, false, false]}
              />
              <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                <p className="w-auto d-inline me-auto px-0">
                  <Skeleton variant="text" sx={{ width: '200px', fontSize: '1rem' }} />
                </p>
                <div className="w-auto d-inline ms-auto">
                  <Skeleton variant="text" sx={{ width: '200px', fontSize: '1rem' }} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Table
                type="Product"
                options={[
                  {
                    title: 'Product ID',
                    icon: false,
                  },
                  {
                    title: 'Product',
                    icon: false,
                  },
                  {
                    title: 'Category',
                    icon: false,
                  },
                  {
                    title: 'Sub Category',
                    icon: false,
                  },

                  {
                    title: 'Market Price',
                    icon: false,
                  },

                  {
                    title: 'Date',
                    icon: false,
                  },
                  {
                    title: 'Actions',
                    icon: false,
                  },
                ]}
                wholeData={wholeProducts}
                data={products}
                customStyleIndex={[]}
                customStyleFn={[false, false, false, false, false, false]}
              />
              {totalPages > 1 && (
                <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                  <div className="w-auto d-inline ms-auto">
                    <UsePagination
                      page={page}
                      pages={totalPages}
                      onChangePage={(page) => {
                        setLoading(true);
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </section>
      </section>
    </div>
  );
}
