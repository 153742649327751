import React from 'react';

export default function FilterDots(props) {
  return (
    <svg
      //   onClick={() => setShowOptions(!showOptions)}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66699 10C4.56699 10 3.66699 10.9 3.66699 12C3.66699 13.1 4.56699 14 5.66699 14C6.76699 14 7.66699 13.1 7.66699 12C7.66699 10.9 6.76699 10 5.66699 10Z"
        stroke={props.color || '#292D32'}
        strokeWidth="1.5"
      />
      <path
        d="M19.667 10C18.567 10 17.667 10.9 17.667 12C17.667 13.1 18.567 14 19.667 14C20.767 14 21.667 13.1 21.667 12C21.667 10.9 20.767 10 19.667 10Z"
        stroke={props.color || '#292D32'}
        strokeWidth="1.5"
      />
      <path
        d="M12.667 10C11.567 10 10.667 10.9 10.667 12C10.667 13.1 11.567 14 12.667 14C13.767 14 14.667 13.1 14.667 12C14.667 10.9 13.767 10 12.667 10Z"
        stroke={props.color || '#292D32'}
        strokeWidth="1.5"
      />
    </svg>
  );
}
