import React, { useState } from 'react';
import classes from './AddMember.module.scss';
import Close from '../../SVG/Close';
import Button from '../../UI/Button/Button';
export default function SingleVoucher(props) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('');
  return (
    <>
      <div className={`row mx-0 justify-content-cenetr text-cenetr px-0 mb-2 ${classes.remove}`}>
        <div className="row mx-0 px-0 ">
          {/* <div className={`col-10 px-0 ${classes.title}`}>{props.type} Member</div> */}
          <div
            onClick={() => {
              props.setShowForm(false);
            }}
            className="cursor col-2 ms-auto   px-0 text-end"
          >
            <Close color="#134074" />
          </div>
        </div>
        <p className="text-center">Are you sure you want to Change the status?</p>
        <span className="text-center mb-0">By Change this status, it Will change on the user view.</span>

        <div className="row px-0 mx-0 d-flex justify-content-center mt-0 gap-2">
          {['Valid', 'Used', 'Expired'].map((item, index) => (
            <p
              key={index}
              onClick={() => setSelected(item)}
              className={`w-auto d-inline cursor ${classes['option']}  ${item === selected && classes[item]}`}
            >
              {item}
            </p>
          ))}
        </div>

        <div className="row mx-0 px-0 justify-content-center">
          <div className={`w-auto ${classes.button}  order-3 d-inline text-end position-relative px-0 `}>
            <Button onClick={() => props.setShowForm(false)} text="Cancel" type="normal" fontColor={'#134074'} color={'transparent'} />
          </div>
          {/* <div className="row mx-0 px-0 justify-content-center"> */}
          <div className="w-auto  order-3 d-inline text-end position-relative px-0 ">
            {loading ? (
              <div className="text-center">
                <span className="spinner-border spinner-border-sm"></span>
              </div>
            ) : (
              <Button
                onClick={() => {
                  setLoading(true);
                  props.onClick();
                }}
                text={'Change'}
                type="normal"
                fontColor={'white'}
                color={'Red'}
              />
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
