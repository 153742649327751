import React, { useState } from 'react';
import classes from './Requests.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import Table from '../../components/Reusable/Common/Table/Table';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import RequestsSVG from '../../components/Reusable/SVG/RequestsSVG';
import LoadingTable from '../../components/Reusable/Common/Table/LoadingTable';
import { contactUsService } from '../../services/contactRequest';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
export default function ProductRequest() {
  const [showFilters, setShowFilters] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [data, setDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wholeData, setWholeData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [selectedType, setSelectedType] = useState('select type');
  let getContactRequest = async () => {
    let query = '';
    query += `${selectedType !== 'select type' ? `&type=${selectedType}` : ''}`;
    query += `${searchValue ? `&search=${searchValue}` : ''}`;
    let response = await contactUsService.getProductRequest(page, 5, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setWholeData(response.data.products);
      let arr = [];
      response.data.products.map((item) => {
        arr.push([item.userId, item.userName, item._id, item.name, item.createdAt.slice(0, 10)]);
      });

      setDate(arr);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
      getContactRequest();
    }
  }, [page]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstLoad) {
        page === 1 ? getContactRequest() : setPage(1);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);
  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
      page === 1 ? getContactRequest() : setPage(1);
    }
  }, [selectedType]);

  useEffect(() => {
    getContactRequest();
    setFirstLoad(false);
  }, []);
  return (
    <div className={`w-100 ${classes['request-page']}`}>
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className={'backdrop'}
            onClick={() => {
              setShowFilters(false);
            }}
          ></div>,
          document.getElementById('overlays'),
        )}
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          text={'Products Requests'}
          icon={
            <div className="w-auto d-inline">
              <RequestsSVG color="gray" />
            </div>
          }
          buttonText={false}
        />
        <section className={`${classes['main-section']}`}>
          {!firstLoad && (
            <div className={`row   mx-0 px-0  align-items-end  ${classes['table-option']}`}>
              <div className="col-xl-3  col-12 px-0 mb-xl-0 mb-2 ">
                <SearchInput onChange={(val) => setSearchValue(val.target.value)} placeholder={`Search for a Request`} />
              </div>
              <div className="col-xl-9 d-inline mt-lg-0 mt-3">
                <div className="row px-0 mx-0 mb-xl-0 mb-2">
                  <div className="col-xl-3 ms-auto col-4 pe-1 px-0">
                    <InputGroup
                      options={['select type', 'personal', 'organization']}
                      // options={['select category', ...categoriesName]}
                      filter={true}
                      onChange={(e) => {
                        console.log(e);
                        setSelectedType(e.target.value);
                      }}
                      // error={showError && formDataErr.password}
                      // errorMsg={formDataErr.password}
                      // name="password"
                      type="dropdown"
                      value={'Type'}
                      default={selectedType}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {loading ? (
            <LoadingTable />
          ) : (
            <>
              <Table
                type="Product Request"
                options={[
                  {
                    title: 'User ID',
                    icon: false,
                  },
                  {
                    title: 'User Name',
                    icon: false,
                  },
                  {
                    title: 'Product ID',
                    icon: false,
                  },
                  {
                    title: 'Product Name',
                    icon: false,
                  },

                  {
                    title: 'Date',
                    icon: false,
                  },
                ]}
                wholeData={wholeData}
                data={data}
                customStyleIndex={[]}
                customStyleFn={[false, false, false, false, false, false]}
              />
              {totalPages > 1 && (
                <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                  <div className="w-auto d-inline ms-auto">
                    <UsePagination
                      page={page}
                      pages={totalPages}
                      onChangePage={(page) => {
                        setLoading(true);
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </section>
      </section>
    </div>
  );
}
