import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        height: `${window.innerHeight}px`,
        overflow: "hidden",
        objectFit: "contain",
      }}
      className="blanking-loader-container"
    >
      <div
        style={{
          transform: `scale(${(window.innerHeight - 200) / 100})`,
          objectFit: "contain",
        }}
      >
        <svg
          className="blanking-loader"
          width="200"
          height="103"
          viewBox="0 0 300 103"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M182.304 9.18806C184.597 9.18806 186.455 7.67864 186.455 5.81669C186.455 3.95473 184.597 2.44531 182.304 2.44531C180.012 2.44531 178.153 3.95473 178.153 5.81669C178.153 7.67864 180.012 9.18806 182.304 9.18806Z"
            fill="#134074"
          />
          <path
            d="M169.685 9.18806C171.978 9.18806 173.836 7.67864 173.836 5.81669C173.836 3.95473 171.978 2.44531 169.685 2.44531C167.393 2.44531 165.534 3.95473 165.534 5.81669C165.534 7.67864 167.393 9.18806 169.685 9.18806Z"
            fill="#134074"
          />
          <path
            d="M116.083 12.4556C118.375 12.4556 120.234 10.9462 120.234 9.08426C120.234 7.22231 118.375 5.71289 116.083 5.71289C113.79 5.71289 111.932 7.22231 111.932 9.08426C111.932 10.9462 113.79 12.4556 116.083 12.4556Z"
            fill="#134074"
          />
          <path
            d="M195.416 9.18806C197.708 9.18806 199.567 7.67864 199.567 5.81669C199.567 3.95473 197.708 2.44531 195.416 2.44531C193.123 2.44531 191.265 3.95473 191.265 5.81669C191.265 7.67864 193.123 9.18806 195.416 9.18806Z"
            fill="#134074"
          />
          <path
            d="M195.416 53.7291C197.708 53.7291 199.567 52.2197 199.567 50.3577C199.567 48.4957 197.708 46.9863 195.416 46.9863C193.123 46.9863 191.265 48.4957 191.265 50.3577C191.265 52.2197 193.123 53.7291 195.416 53.7291Z"
            fill="#134074"
          />
          <path
            d="M192.82 14.1871C190.229 13.7866 188.709 15.4499 187.889 17.2602C184.719 24.2686 181.821 31.3668 179.159 38.5182C178.073 41.4401 176.779 43.9697 180.201 44.9259C184.05 46.0047 184.307 43.9737 185.65 40.8067C188.493 34.1048 191.436 26.9207 194.304 19.7325C195.628 16.4224 195.935 14.6652 192.82 14.1871Z"
            fill="#134074"
          />
          <path
            d="M180.113 14.1871C177.522 13.7866 176.002 15.4499 175.182 17.2602C172.012 24.2686 169.114 31.3668 166.452 38.5182C165.366 41.4401 164.072 43.9697 167.494 44.9259C171.343 46.0047 171.6 43.9737 172.943 40.8067C175.786 34.1048 178.729 26.9207 181.597 19.7325C182.921 16.4224 183.228 14.6652 180.113 14.1871Z"
            fill="#134074"
          />
          <path
            d="M167.41 14.1871C164.819 13.7866 163.299 15.4499 162.479 17.2602C159.309 24.2686 156.411 31.3668 153.749 38.5182C152.662 41.4401 151.369 43.9697 154.791 44.9259C158.64 46.0047 158.897 43.9737 160.24 40.8067C163.083 34.1048 166.026 26.9207 168.894 19.7325C170.218 16.4224 170.524 14.6652 167.41 14.1871Z"
            fill="#134074"
          />
          <path
            d="M152.168 17.3587C149.844 16.999 148.48 18.4947 147.746 20.1171C144.898 26.4103 142.296 32.7812 139.906 39.2011C138.93 41.8246 137.773 44.0926 140.837 44.9549C144.294 45.9234 144.525 44.1008 145.728 41.2566C148.279 35.2413 150.92 28.7886 153.497 22.3401C154.689 19.3692 154.961 17.7918 152.168 17.3587Z"
            fill="#134074"
          />
          <path
            d="M208.059 2.52816C205.468 1.97648 203.949 4.26901 203.128 6.7577C199.959 16.4019 197.06 26.1686 194.399 36.0008C193.312 40.0178 192.019 43.4996 195.44 44.8195C199.289 46.307 199.546 43.5118 200.889 39.1556C203.732 29.9364 206.676 20.0511 209.544 10.1659C210.867 5.60531 211.174 3.19018 208.059 2.52816Z"
            fill="#134074"
          />
          <path
            d="M137.678 26.6455C135.892 26.1715 134.674 27.5895 133.945 29.1751C131.102 35.3049 128.445 41.5286 125.95 47.8055C124.928 50.3718 123.801 52.5745 126.121 53.5879C128.737 54.728 129.094 52.9218 130.312 50.147C132.898 44.2788 135.595 37.9815 138.242 31.6801C139.459 28.7746 139.832 27.2176 137.678 26.6455Z"
            fill="#134074"
          />
          <path
            d="M127.927 17.3587C125.602 16.999 124.239 18.4947 123.504 20.1171C120.656 26.4103 118.055 32.7812 115.665 39.2011C114.689 41.8246 113.532 44.0926 116.596 44.9549C120.053 45.9234 120.284 44.1008 121.487 41.2566C124.038 35.2413 126.679 28.7886 129.255 22.3401C130.448 19.3692 130.725 17.7918 127.927 17.3587Z"
            fill="#134074"
          />
          <path
            d="M119.06 46.8379C118.139 46.8379 117.39 47.4468 117.39 48.1946C117.39 48.9424 118.139 49.5513 119.06 49.5513C119.981 49.5513 120.731 48.9424 120.731 48.1946C120.731 47.4427 119.981 46.8379 119.06 46.8379Z"
            fill="#134074"
          />
          <path
            d="M113.734 46.8379C112.813 46.8379 112.063 47.4468 112.063 48.1946C112.063 48.9424 112.813 49.5513 113.734 49.5513C114.655 49.5513 115.404 48.9424 115.404 48.1946C115.404 47.4427 114.66 46.8379 113.734 46.8379Z"
            fill="#134074"
          />
          <path
            d="M114.211 17.3587C111.887 16.999 110.523 18.4947 109.788 20.1171C106.941 26.4103 104.339 32.7812 101.949 39.2011C100.973 41.8246 99.8159 44.0926 102.88 44.9549C106.337 45.9234 106.568 44.1008 107.771 41.2566C110.322 35.2413 112.963 28.7886 115.54 22.3401C116.732 19.3692 117.004 17.7918 114.211 17.3587Z"
            fill="#134074"
          />
          <path
            d="M104.334 2.52816C101.742 1.97648 100.223 4.26901 99.4028 6.7577C96.233 16.4019 93.3348 26.1686 90.6731 36.0008C89.5863 40.0178 88.2932 43.4996 91.7146 44.8195C95.5638 46.307 95.8204 43.5118 97.1638 39.1556C100.007 29.9364 102.95 20.0511 105.818 10.1659C107.141 5.60531 107.448 3.19018 104.334 2.52816Z"
            fill="#134074"
          />
          <path
            d="M153.573 11.1712C153.573 11.919 154.323 12.5279 155.244 12.5279C156.164 12.5279 156.914 11.919 156.914 11.1712C156.914 10.4233 156.164 9.81445 155.244 9.81445C154.318 9.81445 153.573 10.4233 153.573 11.1712Z"
            fill="#134074"
          />
          <path
            d="M148.245 11.1712C148.245 11.919 148.995 12.5279 149.916 12.5279C150.836 12.5279 151.586 11.919 151.586 11.1712C151.586 10.4233 150.836 9.81445 149.916 9.81445C148.995 9.81445 148.245 10.4233 148.245 11.1712Z"
            fill="#134074"
          />
          <path
            d="M148.826 4.28873C149.566 4.11301 150.245 3.92502 150.834 3.74113C151.11 3.65531 151.362 3.56949 151.588 3.48776L158.99 0.986819L159.191 0.917356C160.147 0.590434 160.731 0.292118 160.67 0.124571C160.57 -0.157399 158.628 0.0550932 156.333 0.602686C155.76 0.737541 155.216 0.884671 154.728 1.0277H154.723C154.149 1.19525 153.697 1.36278 153.259 1.52215C151.81 2.05748 148.484 3.16902 146.738 3.72479C146.28 3.8719 145.827 4.01492 145.48 4.14978C144.816 4.40723 144.439 4.6279 144.489 4.76275C144.589 5.04472 146.532 4.83224 148.826 4.28873Z"
            fill="#134074"
          />
          <path
            d="M15.6833 74.7488C15.8997 74.7488 16.2368 74.7325 16.6947 74.6957C17.1525 74.6589 17.4897 74.6426 17.706 74.6426C21.7111 74.6426 25.4496 75.6152 28.9113 77.5604C32.373 79.5055 34.3001 82.0024 34.6925 85.0428L6.13848 85.149C5.96238 86.3545 5.87685 87.3598 5.87685 88.173C5.87685 93.6204 7.85928 97.4412 11.8241 99.6357C14.1336 100.804 16.5488 101.389 19.0796 101.389C21.6507 101.389 24.1565 100.813 26.5968 99.6643C29.0371 98.516 30.9289 97.004 32.2824 95.1283C32.3277 95.0588 33.0019 93.8001 34.3101 91.3605L35.2913 92.1574C34.5919 94.2088 33.4044 96.0396 31.7289 97.6496C30.0534 99.2597 28.0157 100.506 25.6206 101.393L19.6079 102.615H18.496C14.4003 102.615 10.7826 101.81 7.64794 100.2C4.51329 98.5895 2.28935 96.2643 0.981143 93.2199C0.327041 91.0254 0 89.4889 0 88.6021C0 86.4076 0.654098 84.295 1.9623 82.2599C3.2705 80.2248 5.12212 78.5003 7.51714 77.0863C10.2593 75.7746 12.9814 74.9981 15.6833 74.7488ZM16.0758 75.7582C13.5047 76.0075 11.3713 76.9188 9.67064 78.4921C7.96997 80.0654 6.9687 81.9166 6.6668 84.0375H28.2974V83.1875C28.2974 80.993 27.2207 79.2072 25.0621 77.826C22.9036 76.4447 20.413 75.7541 17.5802 75.7541H16.0758V75.7582Z"
            fill="#134074"
          />
          <path
            d="M60.0504 74.9061C62.4455 75.2248 64.8203 75.8092 67.1751 76.6592C67.1298 77.8974 66.5663 79.5075 65.4745 81.4895C64.604 79.7568 63.014 78.3306 60.7045 77.219C58.3951 76.1034 56 75.5477 53.5145 75.5477C50.2892 75.5477 47.8087 76.3609 46.0627 77.9873C44.9709 79.1193 44.4275 80.288 44.4275 81.4895C44.4275 82.9034 45.3634 84.1253 47.2351 85.151C51.7635 86.2135 56.2315 86.8142 60.6291 86.9572C62.9838 87.0635 64.9512 87.7909 66.5411 89.1353C68.1311 90.4798 68.9261 92.0368 68.9261 93.8062C68.9261 94.7952 68.6443 95.7881 68.0758 96.7771C65.0266 100.672 59.819 102.617 52.4578 102.617C51.2402 102.617 49.5395 102.547 47.3609 102.404C42.4803 101.979 39.0387 101.166 37.0361 99.9646L39.3255 95.0281C40.5884 98.5302 44.4224 100.672 50.8276 101.448L57.2982 101.289C58.9989 101.076 60.5888 100.353 62.0681 99.1146C63.5474 97.8764 64.292 96.585 64.292 95.2405C64.292 94.0391 63.7033 93.0134 62.526 92.1634C61.6102 91.5995 60.3674 91.1909 58.8026 90.9416L48.3471 89.7197C45.9923 89.2947 43.9595 88.4733 42.2388 87.2514C40.518 86.0296 39.6576 84.5707 39.6576 82.8748C39.6576 82.4498 39.8337 81.7428 40.1809 80.7498C40.835 79.2623 41.9671 78.0527 43.5771 77.1128C45.1872 76.177 46.9181 75.5313 48.7697 75.1758C50.6213 74.8243 52.6138 74.6445 54.7472 74.6445C55.4415 74.6445 56.4227 74.6895 57.6856 74.7753C58.9636 74.8611 59.7486 74.9061 60.0504 74.9061Z"
            fill="#134074"
          />
          <path
            d="M79.5277 64.9258V81.75C80.3126 79.5555 81.9882 77.8228 84.5593 76.5478C87.1304 75.2728 89.9179 74.6394 92.9217 74.6394C95.4475 74.6394 97.7922 75.0889 99.9457 75.992C102.104 76.8952 103.704 78.211 104.751 79.9437C105.576 81.8562 106.034 83.5194 106.124 84.9333V99.1054C106.341 99.6734 106.733 100.213 107.302 100.724C107.865 101.239 108.479 101.582 109.133 101.758L97.8275 101.864C99.3973 101.226 100.373 100.27 100.766 98.9991C101.244 94.79 101.485 91.7987 101.485 90.0292C101.485 86.2451 100.897 82.9187 99.7193 80.05C98.8489 78.8118 97.6061 77.8392 95.996 77.1322C94.3859 76.4252 92.7053 76.0697 90.9644 76.0697C88.3531 76.0697 86.2147 76.6704 84.5593 77.8759C83.2511 78.9384 82.2699 79.8906 81.6209 80.7406C80.1416 83.3233 79.3969 86.5271 79.3969 90.348C79.3969 91.3042 79.6384 94.0299 80.1164 98.521C80.1164 100.078 81.0523 101.12 82.924 101.651L82.7932 101.97L71.4873 101.864V101.439C71.8345 101.406 72.403 101.247 73.188 100.961C74.2345 99.9717 74.8232 98.9787 74.954 97.9898V69.8092C74.954 68.8202 74.5415 68.0397 73.7113 67.4758C73.4496 67.4063 72.9465 67.2306 72.2068 66.9445L72.1414 66.8383C72.0961 66.7688 72.076 66.7157 72.076 66.6789L79.5277 64.9258Z"
            fill="#134074"
          />
          <path
            d="M120.955 69.1172L121.217 69.5422V76.6527H127.491L126.968 77.9808L121.217 78.0871V95.655C121.478 97.3182 122.394 98.9814 123.959 100.645C125.046 101.106 125.81 101.372 126.248 101.442L125.987 101.867C124.895 102.079 123.677 102.185 122.329 102.185C121.151 102.185 119.974 102.079 118.801 101.867L117.82 101.388C116.99 100.256 116.578 99.1245 116.578 97.9925V78.0912L111.808 77.9849V77.4005C115.858 76.3013 118.907 73.5429 120.955 69.1172Z"
            fill="#134074"
          />
          <path
            d="M142.978 74.6426H145.071C146.812 74.6426 148.533 74.7856 150.233 75.0676C151.934 75.3496 153.574 75.795 155.169 76.3957C156.759 76.9964 158.032 77.8546 158.993 78.9702C159.949 80.0858 160.432 81.3854 160.432 82.8728C160.171 86.8 160.04 88.7819 160.04 88.8187C160.04 91.5771 160.342 94.8136 160.956 98.5324C161.127 99.7338 161.957 100.886 163.436 101.981H157.423C156.422 100.281 155.919 98.6549 155.919 97.098C154.611 98.9042 152.835 100.273 150.596 101.213C148.352 102.149 145.881 102.619 143.179 102.619L136.905 101.663C134.681 101.205 132.854 100.31 131.415 98.9819C129.976 97.6537 129.257 96.1785 129.257 94.5521C129.257 93.2444 129.846 91.8795 131.023 90.4656C134.379 87.2454 140.543 85.6353 149.514 85.6353C150.168 85.6353 151.215 85.6516 152.649 85.6884C154.088 85.7252 155.089 85.7415 155.652 85.7415C155.567 83.4408 155.194 81.4957 154.54 79.9019C153.886 78.3082 152.578 77.1231 150.621 76.3467C148.92 75.6765 147.069 75.3373 145.066 75.3373C142.455 75.3373 140.024 75.9462 137.78 77.1681C135.536 78.3899 133.956 79.9918 133.041 81.9697L131.144 77.4051L131.274 76.9801C135.29 75.6683 139.189 74.8919 142.978 74.6426ZM155.265 86.849H151.476C141.584 86.849 136.226 89.2029 135.4 93.9064C135.4 94.0495 135.355 94.2783 135.27 94.597C135.184 94.9158 135.139 95.1283 135.139 95.2346C135.139 97.147 136.095 98.659 138.012 99.7706C139.929 100.886 142.148 101.442 144.679 101.442C145.202 101.442 146.223 101.356 147.748 101.176C148.965 100.858 150.123 100.416 151.209 99.8482C153.695 98.4343 155.174 96.2562 155.652 93.322V87.3761L155.265 86.849Z"
            fill="#134074"
          />
          <path
            d="M186.037 75.1177L183.879 79.948C183.049 79.241 182.007 78.8855 180.744 78.8855C179.698 78.8855 178.706 79.1756 177.77 79.76C176.835 80.3444 176.105 81.1126 175.582 82.0689C174.404 84.0876 173.796 85.146 173.75 85.2523V96.6619C173.75 97.757 174.022 98.7746 174.565 99.7145C175.109 100.65 175.969 101.226 177.147 101.439H177.735L177.604 101.97H165.317L165.187 101.545C166.057 101.406 166.731 101.21 167.214 100.961C168.13 99.9719 168.739 98.2351 169.046 95.7587V79.9439C169.046 79.8377 169.036 79.6456 169.016 79.3595C168.995 79.0776 168.98 78.9018 168.98 78.8283C168.412 77.9088 167.345 77.3244 165.78 77.0752L173.881 75.3221V81.8482C174.882 80.1156 176.417 78.5586 178.49 77.1773C180.558 75.7961 182.706 75.1055 184.925 75.1055H186.037V75.1177Z"
            fill="#134074"
          />
          <path
            d="M204.008 74.7488C204.224 74.7488 204.561 74.7325 205.019 74.6957C205.477 74.6589 205.814 74.6426 206.03 74.6426C210.035 74.6426 213.774 75.6152 217.236 77.5604C220.697 79.5055 222.624 82.0024 223.017 85.0428L194.463 85.149C194.287 86.3545 194.201 87.3598 194.201 88.173C194.201 93.6204 196.183 97.4412 200.148 99.6357C202.458 100.804 204.873 101.389 207.404 101.389C209.975 101.389 212.476 100.813 214.921 99.6643C217.361 98.516 219.253 97.004 220.607 95.1283C220.647 95.0588 221.326 93.8001 222.634 91.3605L223.616 92.1574C222.916 94.2088 221.729 96.0396 220.053 97.6496C218.378 99.2597 216.34 100.506 213.945 101.393L207.932 102.615H206.82C202.725 102.615 199.107 101.81 195.972 100.2C192.838 98.5895 190.614 96.2643 189.305 93.2199C188.651 91.0254 188.324 89.4889 188.324 88.6021C188.324 86.4076 188.978 84.295 190.287 82.2599C191.595 80.2248 193.446 78.5003 195.841 77.0863C198.584 75.7746 201.306 74.9981 204.008 74.7488ZM204.4 75.7582C201.829 76.0075 199.696 76.9188 197.995 78.4921C196.294 80.0654 195.293 81.9166 194.991 84.0375H216.622V83.1875C216.622 80.993 215.545 79.2072 213.386 77.826C211.228 76.4447 208.737 75.7541 205.904 75.7541H204.4V75.7582Z"
            fill="#134074"
          />
          <path
            d="M247.133 74.6426C250.313 74.6426 253.156 75.3332 255.661 76.7104C258.167 78.0916 259.681 79.9837 260.205 82.3906C260.683 88.8637 260.924 93.2526 260.924 95.5533V99.1086C261.402 100.31 262.403 101.266 263.928 101.973H252.361C254.147 101.091 255.234 99.5867 255.626 97.4617C255.797 95.0547 255.888 93.1627 255.888 91.7814C255.888 88.7043 255.646 85.5004 255.168 82.174C254.776 80.2615 253.674 78.77 251.867 77.6911C250.061 76.6123 248.003 76.0729 245.694 76.0729C242.906 76.0729 240.486 76.8534 238.438 78.4063C235.52 81.2709 234.061 85.4106 234.061 90.8252C234.061 92.3822 234.257 94.9485 234.649 98.5201C234.78 99.7951 235.671 100.837 237.331 101.65C237.372 101.687 237.432 101.74 237.492 101.81C237.558 101.879 237.613 101.932 237.658 101.969H226.025C228.42 100.731 229.618 98.9615 229.618 96.6607V80.5272C229.618 80.3882 229.563 80.1226 229.457 79.7303C229.346 79.3421 229.291 79.0928 229.291 78.9865C228.637 78.2101 227.832 77.6053 226.876 77.1803L227.007 76.6491L234.262 75.321V81.9534C235.132 79.6527 236.733 77.8587 239.062 76.5673C241.392 75.2883 244.084 74.6426 247.133 74.6426Z"
            fill="#134074"
          />
          <path
            d="M279.547 74.6426H281.64C283.381 74.6426 285.102 74.7856 286.803 75.0676C288.504 75.3496 290.144 75.795 291.734 76.3957C293.324 76.9964 294.597 77.8546 295.558 78.9702C296.514 80.0858 296.997 81.3854 296.997 82.8728C296.735 86.8 296.604 88.7819 296.604 88.8187C296.604 91.5771 296.906 94.8136 297.52 98.5324C297.691 99.7338 298.521 100.886 300.001 101.981H293.988C292.987 100.281 292.483 98.6549 292.483 97.098C291.175 98.9042 289.399 100.273 287.16 101.213C284.916 102.149 282.446 102.619 279.744 102.619L273.469 101.663C271.245 101.205 269.419 100.31 267.98 98.9819C266.541 97.6537 265.821 96.1785 265.821 94.5521C265.821 93.2444 266.41 91.8795 267.587 90.4656C270.943 87.2454 277.107 85.6353 286.078 85.6353C286.732 85.6353 287.779 85.6516 289.213 85.6884C290.652 85.7252 291.653 85.7415 292.217 85.7415C292.131 83.4408 291.759 81.4957 291.105 79.9019C290.451 78.3082 289.143 77.1231 287.185 76.3467C285.485 75.6765 283.633 75.3373 281.63 75.3373C279.019 75.3373 276.589 75.9462 274.345 77.1681C272.101 78.3899 270.521 79.9918 269.605 81.9697L267.708 77.4051L267.839 76.9801C271.854 75.6683 275.754 74.8919 279.547 74.6426ZM291.829 86.849H288.041C278.149 86.849 272.795 89.2029 271.965 93.9064C271.965 94.0495 271.92 94.2783 271.834 94.597C271.748 94.9158 271.703 95.1283 271.703 95.2346C271.703 97.147 272.659 98.659 274.576 99.7706C276.493 100.886 278.712 101.442 281.243 101.442C281.766 101.442 282.788 101.356 284.312 101.176C285.53 100.858 286.687 100.416 287.774 99.8482C290.26 98.4343 291.739 96.2562 292.217 93.322V87.3761L291.829 86.849Z"
            fill="#134074"
          />
        </svg>
      </div>
    </div>
  );
};

export default Loading;
