import React, { useState } from "react";
import classes from "./CompanyNav.module.scss";
import Button from "../../UI/Button/Button";
import Plus from "../../SVG/Plus";
import Dropdown from "../../SVG/Dropdown";
import Ring from "../../SVG/Ring";
import Company from "../../SVG/Company";
import Logout from "../../SVG/Logout";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import Profile from "../../SVG/Profile";
// import { useDispatch, useSelector } from "react-redux";
// import { roomsActions } from "../../../../store/reducers/roomsSlice";
// import { selectedRoomActions } from "../../../../store/reducers/selectedRoom";
// import PeopleActive from "../../SVG/PeopleActive";

export default function CompanyNav(props) {
  // const { profile } = useSelector((state) => state.profile);
  const [showOptions, setShowOptions] = useState(false);
  // const dispatch = useDispatch();
  const history = useNavigate();
  return (
    <div className={`${classes.nav}  `}>
      {showOptions &&
        ReactDOM.createPortal(
          <div
            className={classes.backdrop}
            onClick={() => {
              setShowOptions(false);
            }}
          ></div>,
          document.getElementById("overlays")
        )}
      <div
        // style={{ height: 'fit-content' }}
        className="row mx-0 px-0 d-flex  align-items-center "
      >
        <h1 className="col-md-6 col-4   d-inline px-0">Welcome to Eshtreana</h1>
        <div className="w-auto  ms-auto d-inline px-0 text-end ">
          <div className="row d-flex   align-items-center mx-0  text-end ms-auto px-0">
            {/* <div className="order-2 w-auto   ms-auto text-end justify-conetent-end">
              <div className="row align-items-center ms-auto d-flex  mx-md-3 px-0 ">
                {(localStorage.getItem("side") === "company" ||
                  localStorage.getItem("side") === "admin") && (
                  <div
                    onClick={() => {
                      history("/account/notifications");
                    }}
                    style={{ borderRadius: "50%" }}
                    className={`${
                      window.location.pathname.startsWith(
                        "/account/notifications"
                      ) && classes.active
                    } w-auto d-inline  align-items-center cursor   justify-content-center text-center ms-auto`}
                  >
                    <Ring />
                  </div>
                )}
              </div>
            </div> */}
            <div className="w-auto  order-3 d-inline text-end position-relative px-0 ">
              <Button
                onClick={() => {
                  setShowOptions((prev) => !prev);
                }}
                icon={<Dropdown />}
                className="w-auto px-2 ms-auto"
                type="reverse"
                text={localStorage.getItem("side")}
              />

              {showOptions && (
                <div className="w-100">
                  <div className={` px-0 ${classes.options}`}>
                    {localStorage.getItem("side") !== "Top Manager" && (
                      <div
                        onClick={() => {
                          // localStorage.clear();
                          // dispatch(roomsActions.updaterooms([]));
                          // dispatch(selectedRoomActions.updateRoom({}));
                          localStorage.getItem("side") === "Marketing Manager"
                            ? history(
                                `/account/marketing-manager/${localStorage.getItem(
                                  "id"
                                )}`
                              )
                            : localStorage.getItem("side") ===
                              "Category Manager"
                            ? history(
                                `/account/category-manager/${localStorage.getItem(
                                  "id"
                                )}`
                              )
                            : localStorage.getItem("side") === "Cashier"
                            ? history(
                                `/account/cashier/${localStorage.getItem("id")}`
                              )
                            : history(
                                `/account/suppliers/${localStorage.getItem(
                                  "id"
                                )}`
                              );
                        }}
                        className="row mx-0 px-0 mb-0 pb-0  h-auto  align-items-center  cursor d-flex  mb-2 pb-0"
                      >
                        <div className="w-auto h-auto d-inline  pb-0 mb-0">
                          <Profile />
                        </div>
                        <div className="w-auto d-inline">
                          &nbsp;&nbsp;Profile
                        </div>
                      </div>
                    )}{" "}
                    {/* <div className={classes.options}> */}
                    <div
                      onClick={() => {
                        localStorage.clear();
                        // dispatch(roomsActions.updaterooms([]));
                        // dispatch(selectedRoomActions.updateRoom({}));
                        history("/login");
                      }}
                      className="row mx-0 px-0 mb-0 pb-0  h-auto  align-items-center cursor  d-flex  mb-0 pb-0"
                    >
                      <div className="w-auto h-auto d-inline  pb-0 mb-0">
                        <Logout />
                      </div>
                      <div className="w-auto d-inline">&nbsp;&nbsp;Logout</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
