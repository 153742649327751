import React from 'react';
import classes from './Voucher.module.scss';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PeopleActiveL from '../../components/Reusable/SVG/PeopleActiveL';
import Plus from '../../components/Reusable/SVG/Plus';
import VoucherActieveL from '../../components/Reusable/SVG/VoucherActieveL';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import { useState } from 'react';
// import { ProgressBar } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import VoucherCard from '../../components/Reusable/Common/Voucher/VoucherCard';
import { errorActions } from '../../store/reducers/errorSlice';
import Filter from '../../components/Reusable/UI/Filter/Filter';
import Filters from '../../components/Reusable/SVG/Filters';
import ReactDOM from 'react-dom';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import { Skeleton } from '@mui/material';
import { vouchersEshtreanaService } from '../../services/VouchersEshtreana';
import { useEffect } from 'react';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import { categoryService } from '../../services/categoryService';
import { subcategoryService } from '../../services/subCategoryService';
import { areaService } from '../../services/areaService';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { useDispatch } from 'react-redux';
export default function Voucher() {
  dayjs.extend(relativeTime);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const [option, setOption] = useState('On Going');
  const [loading, setLoading] = useState(true);
  const [vouchers, setVouchers] = useState([]);
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('select category');
  const [selectedSubCategory, setSelectedSubCategory] = useState('select sub-category');
  const [selectedArea, setSelectedArea] = useState('select city');
  const [selectedDistrict, setSelectedDistrict] = useState('select district');
  const [searchValue, setSearchValue] = useState('');
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);

  let getVouchers = async () => {
    let query = '';
    query += `${`&type=${
      option.toLocaleLowerCase() === 'responses' ? 'response' : option.toLocaleLowerCase() === 'requests' ? 'request' : option.toLocaleLowerCase()
    }`}`;
    query += `${
      selectedCategory && selectedSubCategory !== ' ' && selectedCategory !== 'select category'
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ''
    }`;
    query += `${
      selectedDistrict && selectedDistrict !== ' ' && selectedDistrict !== 'select district' ? `&district=${selectedDistrict.toLowerCase()}` : ''
    }`;
    query += `${selectedArea && selectedArea !== ' ' && selectedArea !== 'select city' ? `&area=${selectedArea}` : ''}`;
    query += `${searchValue ? `&search=${searchValue}` : ''}`;
    let response = await vouchersEshtreanaService.getVouchers(page, 6, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setVouchers(response.data.vouchers);
      setTotalPages(response.data.totalPages);
      setLoading(false);
      setLoadingSearch(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
      setLoading(false);
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    mainDataHelper.getAllCities(setServerError, setServerErrorMsg, setCities);
    mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
  }, []);
  useEffect(() => {
    if (!firstLoad) {
      if (selectedArea !== 'select city') mainDataHelper.getAllDistrict(setServerError, setServerErrorMsg, selectedArea, setDistrict);
      else {
        setSelectedDistrict('select district');
        setDistrict([]);
      }
    }
  }, [selectedArea]);
  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
      setLoadingSearch(true);
      page === 1 ? getVouchers() : setPage(1);
    }
  }, [option]);
  useEffect(() => {
    if (!firstLoad) {
      setLoading(false);
      page === 1 ? getVouchers() : setPage(1);
    }
  }, [selectedArea, selectedDistrict, selectedCategory]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstLoad) {
        setLoadingSearch(false);
        page === 1 ? getVouchers() : setPage(1);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);
  useEffect(() => {
    if (!firstLoad) {
      setLoadingSearch(true);

      getVouchers();
    }
  }, [page]);
  useEffect(() => {
    getVouchers();
    setFirstLoad(false);
  }, []);

  return (
    <div className={`w-100 ${classes['voucher']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className={'backdrop'}
            onClick={() => {
              setShowFilters(false);
            }}
          ></div>,
          document.getElementById('overlays'),
        )}
      <CompanyNav />
      <section className="custom-container">
        <div className={`${classes['title']} `}>
          <PageTitle
            onClick={() => {
              history(`/account/voucher/new`);
            }}
            text="Vouchers"
            icon={<VoucherActieveL />}
            buttonText={`Add Voucher`}
            buttonType="normal"
            buttonIcon={<Plus />}
          />
        </div>
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
              <div className="row px-0 mx-0">
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
              </div>
            </div>

            <div className={`row   mx-0 px-0  mt-0  align-items-end  ${classes['table-option']}`}>
              <div className="col-xl-4  col-12 px-0 mb-xl-0 mb-2 ">
                <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
              </div>
              <div className="col-xl-8 px-0 ps-xl-2 pe-xl-4">
                <div className="row px-0 mx-0 mb-xl-0 mb-2">
                  <div className="col-xl-4  col-4 pe-1 px-0">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>

                  <div className="col-xl-4 col-4 px-0 ps-1">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>
                  <div className="col-xl-4 col-4 px-0 ps-1">
                    <Skeleton variant="text" sx={{ fontSize: '3rem', width: '100%' }} />
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="row px-0 mx-0">
                {[0, 1, 2, 3, 4, 5].map((item, index) => (
                  <div key={index} className={`col-xl-4 col-lg-6 px-0 pe-lg-3`}>
                    <Skeleton
                      variant="rounded"
                      sx={{
                        borderRaduis: '10px',
                        fontSize: '2rem',
                        width: '100%',
                        height: '400px',
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          </section>
        ) : (
          <section className={`${classes['main-section']}`}>
            {!firstLoad && (
              <>
                {' '}
                <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
                  <SelectOption
                    onSelect={(val) => {
                      // setLoading(true);
                      setSearchValue('');

                      setOption(val);
                    }}
                    default={option}
                    options={['On Going', 'Scheduled', 'Responses', 'History', 'Requests']}
                  />
                </div>
                <div className={`row   mx-0 px-0  mt-0  align-items-end  ${classes['table-option']}`}>
                  <div className="col-xl-4  col-12 px-0 mb-xl-0 mb-2 ">
                    <SearchInput
                      onChange={(val) => {
                        setLoadingSearch(true);
                        setSearchValue(val.target?.value);
                      }}
                      placeholder={`Search by Voucher Name or Voucher Code`}
                    />
                  </div>
                  <div className="col-xl-8  px-0 ps-xl-2 pe-xl-4">
                    <div className="row px-0 mx-0 mb-xl-0 mb-2">
                      {categoriesName.length > 0 && (
                        <div className="col-xl-4  col-4 pe-1 px-0">
                          <InputGroup
                            options={['select category', ...categoriesName]}
                            filter={true}
                            onChange={(e) => {
                              setLoadingSearch(true);
                              setSelectedCategory(e.target?.value);
                            }}
                            type="dropdown"
                            value={'Category'}
                          />
                        </div>
                      )}
                      {cities.length > 0 && (
                        <div className="col-xl-4 col-4 px-0 ps-1">
                          <InputGroup
                            options={['select city', ...cities]}
                            filter={true}
                            onChange={(e) => {
                              setLoadingSearch(true);
                              setSelectedArea(e.target?.value);
                            }}
                            type="dropdown"
                            value="Area"
                          />
                        </div>
                      )}
                      {district.length > 0 && (
                        <div className="col-xl-4 col-4 px-0 ps-1">
                          <InputGroup
                            options={['select District', ...district]}
                            filter={true}
                            onChange={(e) => {
                              setLoadingSearch(true);
                              setSelectedDistrict(e.target?.value);
                            }}
                            type="dropdown"
                            value="District"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === 'On Going' && (
              <>
                <div className="row px-0 mx-0 ">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                      <VoucherCard
                        id={item._id}
                        data={[
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },
                          {
                            title: 'Supplier Name',
                            value: item.supplier,
                          },
                          {
                            title: 'Voucher Time',
                            // value: dayjs(new Date(item.endDate) - Date.now()),
                            // value: getTimeRemaining(item.endDate),
                            // value: dayjs(item.endDate).fromNow(true),
                          },
                          {
                            title: 'Voucher Value',
                            value: item.voucherPrice,
                          },
                          {
                            title: 'Starting Date',
                            value: new Date(item.startDate).toLocaleDateString(),
                          },
                          {
                            title: 'Catgory',
                            value: item?.categories,
                            list: true,
                          },
                          {
                            title: 'Progress',
                            value: item.sold,
                            total: item.quantity,
                          },
                        ]}
                        type="On Going"
                        side={'Manager'}
                        timeLeft={item.endDate}
                        progress={true}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {option === 'Responses' && (
              <>
                <div className="row px-0 mx-0">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4 col-lg-6 px-0`}>
                      <VoucherCard
                        id={item._id}
                        data={[
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },
                          {
                            title: 'Supplier Name',
                            value: item.supplier,
                          },
                          {
                            title: 'Voucher Value',
                            value: item.voucherPrice,
                          },
                          {
                            title: 'Request Date',
                            value: item?.createdAt?.slice(0, 10),
                          },
                          {
                            title: 'Catgory',
                            value: item.categories,
                            list: true,
                          },
                        ]}
                        type="Responses"
                        side={'Manager'}
                        progerss={false}
                        status={true}
                        statusValue={item.status}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {option === 'Scheduled' && (
              <>
                <div className="row px-0 mx-0">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4 col-lg-6 px-0`}>
                      <VoucherCard
                        id={item._id}
                        data={[
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },
                          {
                            title: 'Supplier Name',
                            value: item.supplier,
                          },

                          {
                            title: 'Start At',
                            value: item?.startDate?.slice(0, 10),
                          },
                          {
                            title: 'End At',
                            value: item?.endDate?.slice(0, 10),
                          },
                        ]}
                        type="Scheduled"
                        side="Manager"
                        progerss={false}
                        status={true}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {option === 'History' && (
              <>
                <div className="row px-0 mx-0">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4 col-lg-6 px-0`}>
                      <VoucherCard
                        type="History"
                        id={item._id}
                        data={[
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },
                          {
                            title: 'Supplier Name',
                            value: item.supplier,
                          },
                          {
                            title: 'Expiry Date',
                            value: item?.expireDate?.slice(0, 10),
                          },
                          {
                            title: 'Voucher Value',
                            value: item.voucherPrice,
                          },
                          {
                            title: 'Catgory',
                            value: item.categories,
                            list: true,
                          },
                        ]}
                        po={item.purchasingOrder && item.purchasingOrder}
                        invoice={item.invoice && item.invoice}
                        side="Manager"
                        progerss={false}
                        status={false}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {option === 'Requests' && (
              <>
                <div className="row px-0 mx-0">
                  {vouchers.map((item, index) => (
                    <div key={index} className={`col-xl-4 col-lg-6 px-0`}>
                      <VoucherCard
                        id={item._id}
                        data={[
                          {
                            title: 'Voucher Name',
                            value: item.title_en,
                          },
                          {
                            title: 'Voucher Code',
                            value: item.uuid,
                          },
                          {
                            title: 'Supplier Name',
                            value: item.supplier,
                          },
                          {
                            title: 'Voucher Value',
                            value: item.voucherPrice,
                          },
                          {
                            title: 'Request Date',
                            value: item?.createdAt?.slice(0, 10),
                          },
                          {
                            title: 'Catgory',
                            value: item.categories,
                            list: true,
                          },
                        ]}
                        type="Responses"
                        side={'Manager'}
                        progerss={false}
                        status={false}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {loadingSearch ? (
              <div className="w-100 row justify-content-center pt-5 pb-4">
                <div className="no-found-data mx-auto w-auto  text-center w-100 ">Searching</div>
              </div>
            ) : (
              vouchers.length === 0 && (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Data Found</div>
                </div>
              )
            )}

            {totalPages > 1 && (
              <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                <div className="w-auto d-inline mx-auto">
                  <UsePagination
                    page={page}
                    // selected={page1}
                    pages={totalPages}
                    onChangePage={(page) => {
                      setLoading(true);
                      // //console.log(page);
                      setPage(page);
                    }}
                  />
                </div>
              </div>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
