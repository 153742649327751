import React, { useEffect, useState } from 'react';
import classes from './Manager.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import PeopleActiveL from '../../components/Reusable/SVG/PeopleActiveL';
import Plus from '../../components/Reusable/SVG/Plus';
import Img from '../../components/Reusable/Common/Img';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import ManagerCard from '../../components/Reusable/Common/ManagerCard/ManagerCard';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import AddMemberManager from '../../components/Reusable/Common/AddMemberCard/AddMemberManager';
import Table from '../../components/Reusable/Common/Table/Table';
import { Radio, Skeleton } from '@mui/material';
import SearchInput from '../../components/Reusable/UI/InputFeild/SearchInput';
import Dropdown from '../../components/Reusable/SVG/Dropdown';
import SingleAd from '../../components/Reusable/Common/AddMemberCard/SingleAd';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import { useNavigate } from 'react-router-dom';
import AdActiveL from '../../components/Reusable/SVG/AdActiveL';
import AdviceActiveL from '../../components/Reusable/SVG/AdviceActiveL';
import { managerAccountsService } from '../../services/managerAccountsService';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import { adsService } from '../../services/adsService';
import AdsCard from '../../components/Reusable/Common/Ads/AdsCard';
import { adviceService } from '../../services/adviceService';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import { subcategoryService } from '../../services/subCategoryService';
import { categoryService } from '../../services/categoryService';
import InputGroup from '../../components/Reusable/UI/InputGroup/InputGroup';
import { mainDataHelper } from '../../Helper/MainData/MainData';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
export default function Ads(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [option, setOption] = useState('Ads');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  const [showAddMemberForm, setShowAddMemberForm] = useState(false);
  const [roleFilter, setRoleFilter] = useState('Default');
  const [showSingleAd, setShowSingleAd] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [wholeAdvice, setWholwAdvice] = useState([]);
  const [ads, setAds] = useState([]);
  const [advices, setAdvices] = useState([]);
  const [sort, setSort] = useState('default');
  const [role, setRole] = useState('select role');
  const [status, setStatus] = useState('select status');
  const [searchValue, setSearchValue] = useState('');
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('select category');
  const [selectedSubCategory, setSelectedSubCategory] = useState('select sub-category');

  useEffect(() => {
    mainDataHelper.getAllCategories(setServerError, setServerErrorMsg, setCategories, setCategoriesName);
  }, []);
  useEffect(() => {
    selectedCategory !== 'select category' &&
      mainDataHelper.getAllSubCategories(setServerError, setServerErrorMsg, selectedCategory, categories, setSubCategories, setSubCategoriesName);
  }, [selectedCategory]);

  useEffect(() => {
    //console.log(roleFilter);
  }, [roleFilter]);
  let getAds = async () => {
    let response = await adsService.getAds(page, 8);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      //console.log(response);
      setTotalPages(response.data.totalPages);
      setAds(response.data.ads);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };
  let getAdvices = async () => {
    let query = '';
    query += `${searchValue ? `&search=${searchValue}` : ''}`;
    query += `${
      selectedCategory && selectedSubCategory !== ' ' && selectedCategory !== 'select category'
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ''
    }`;
    query += `${
      selectedSubCategory && selectedSubCategory !== ' ' && selectedSubCategory !== 'select sub-category'
        ? `&subcategory=${selectedSubCategory.toLowerCase()}`
        : ''
    }`;

    query += `${role && role !== ' ' && role !== 'select role' ? `&role=${role}` : ''}`;
    query += `${status && status !== ' ' && status !== 'select status' ? `&status=${status}` : ''}`;
    query += `${sort && sort !== ' ' && sort !== 'default' ? `&date=${sort.toLowerCase()}` : ''}`;

    let response = await adviceService.getAdvices(page, 8, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      let arr = [];
      setWholwAdvice(response.data.advices);
      response.data.advices.map((item) => {
        let categories = item.categories.map((item) => item.item.name_en);
        arr.push([
          item.uuid,
          `${item.topManager.name}`,
          item.role,
          categories,
          item.status,
          new Date(item.startDate).toLocaleDateString(),
          new Date(item.endDate).toLocaleDateString(),
        ]);
      });
      setAdvices(arr);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };
  let getAllManagers = async () => {
    let query = '';
    let response = await managerAccountsService.getAllManagers(
      props.type === 'Category Managers' ? 'category-managers' : 'marketing-managers',
      page,
      8,
      query,
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      setData(props.type === 'Category Managers' ? response.data.categoryManagers : response.data.marketingManagers);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    // setLoading(true);
  });
  useEffect(() => {
    setPage(1);
    setLoading(true);
    setData([]);

    option === 'Member' && getAllManagers();
    option === 'Ads' && getAds();
    option === 'Advices' && getAdvices();
    // setLoading(false);
  }, [props.type, option]);
  useEffect(() => {
    setLoading(true);
    setData([]);

    option === 'Member' && getAllManagers();
    option === 'Ads' && getAds();
    option === 'Advices' && getAdvices();
  }, [page]);

  useEffect(() => {
    setPage(1);
    setLoading(true);
    setData([]);

    // option === "Member" && getAllManagers();
    // option === "Ads" && getAds();
    option === 'Advices' && getAdvices();
    // setLoading(false);
  }, [role, sort, searchValue, selectedCategory, selectedSubCategory, status]);

  return (
    <div className={`w-100 ${classes['managers']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={confirmationMsg} />
        </Modal>
      )}

      <CompanyNav />
      <section className="custom-container">
        <div className={classes['title']}>
          <PageTitle
            onClick={() => {
              history(`/account/ad/new-ad`);
            }}
            text={'Ads'}
            icon={<AdActiveL />}
            buttonText={`Add Ad`}
            buttonType="normal"
            buttonIcon={<Plus />}
          />
        </div>
        <section className={`${classes['main-section']}`}>
          {option === 'Ads' &&
            (loading ? (
              <div className="row mx-0 px-0 ">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                  <AdsCard key={index} loading={true} />
                ))}
              </div>
            ) : (
              <>
                <div className="row mx-0 px-0">
                  {ads.length > 0 ? (
                    ads.map((item, index) => (
                      <AdsCard
                        onSuccess={() => {
                          setLoading(true);
                          getAds();
                        }}
                        key={index}
                        item={item}
                      />
                    ))
                  ) : (
                    <div className="w-100 row justify-content-center pt-5 pb-4">
                      <div className="no-found-data mx-auto w-auto  text-center w-100 ">There is No Ads Found</div>
                    </div>
                  )}
                </div>
                {totalPages > 1 && (
                  <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                    <div className="w-auto d-inline mx-auto">
                      <UsePagination
                        page={page}
                        // selected={page1}
                        pages={totalPages}
                        onChangePage={(page) => {
                          setPage(page);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            ))}
        </section>
      </section>
    </div>
  );
}
