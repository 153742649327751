import React, { useEffect } from 'react';
import classes from './Voucher.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import VoucherActieveL from '../../components/Reusable/SVG/VoucherActieveL';
import ManagerHeader from '../../components/Reusable/Common/MemberCard/ManagerHeader';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import { useState } from 'react';
import DataPopup from '../../components/Reusable/UI/DataPopup/DataPopup';
import PieChart from '../../components/Reusable/SVG/PieChart';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Avatar, Radio, Skeleton } from '@mui/material';
import Button from '../../components/Reusable/UI/Button/Button';
import Section from '../../components/Reusable/Common/Section/Section';
import Table from '../../components/Reusable/Common/Table/Table';
import Docs from '../../components/Reusable/SVG/Docs';
import Modal from '../../components/Reusable/UI/Modal/Modal';
import SendPOInvoiceFile from '../../components/Reusable/Common/AddMemberCard/SendPOInvoiceFile';
import UploadImageGroup2 from '../../components/Reusable/UI/InputGroup/UploadImageGroup2';
import { vouchersEshtreanaService } from '../../services/VouchersEshtreana';
import { useParams } from 'react-router-dom';
import { serverErrorCatcher } from '../../Helper/ServerErrorCatcher';
import SuccessPopup from '../../components/Reusable/Common/AddMemberCard/SuccessPopup';
import FailPop from '../../components/Reusable/Common/AddMemberCard/FailPop';
import UsePagination from '../../components/Reusable/UI/Pagination/Pagination';
import Download from '../../components/Reusable/SVG/Download';
import { reportService } from '../../services/reportService';
import { errorActions } from '../../store/reducers/errorSlice';
import { useDispatch } from 'react-redux';
import TermsSections from '../../components/Reusable/Common/TermsSections/TermsSections';
import ShowAreaDistrict from '../../components/Reusable/Common/AddMemberCard/ShowAreaDistrict';
export default function VoucherDetails(props) {
  const { id } = useParams();
  const [showIndexArea, setShowIndexArea] = useState(null);
  const [showAreaDistrict, setShowAreaDistrict] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState('Main Data');
  const [showSendPO, setShowSendPO] = useState(false);
  const [showSendInvoice, setShowSendInvoice] = useState(false);
  const [downloadOption, setDownloadOption] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [closeOption, setCloseOption] = useState(true);
  const [type, setType] = useState(false);
  const [voucherOrders, setvOucherOrders] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [voucher, setVoucher] = useState(false);
  const [doc, setDoc] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingTable, setLoadingTable] = useState(true);
  const [showDistrict, setShowDistrict] = useState(false);
  const [purchasingDone, setPurchasingDoen] = useState(false);
  let getSingleVoucher = async () => {
    let response = await vouchersEshtreanaService.getSingleVoucher(id);
    console.log(response);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      response.data.voucher.type === 'On going' ? setType('On Going') : setType(response.data.voucher.type);
      setVoucher(response.data.voucher);

      setPurchasingDoen(response.data.voucher.isPurchasingDone);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoading(false);
  };

  let sendPO = async () => {
    let fd = new FormData();
    fd.append('doc', doc[0], doc[0].name);
    let response = await vouchersEshtreanaService.sendPoInvoice(id, fd);
    //console.log(response);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setDoc([]);
      setVoucher((prev) => ({
        ...prev,
        purchasingOrder: doc[0],
      }));
      setShowConfirmation(true);
      setLoading(true);

      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) + 1 * 22 * 60 * 60 * 1000 - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  let timeinterval;

  function initializeClock(endtime) {
    const clock = document.getElementsByClassName(`clock`);
    timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      // //console.log(clock);
      clock[0].innerHTML =
        t.total > 0
          ? (t.days > 0 ? t.days : 0) +
            'D: ' +
            (t.hours > 0 ? t.hours : 0) +
            'H: ' +
            (t.minutes > 0 ? t.minutes : 0) +
            'M: ' +
            (t.seconds > 0 ? t.seconds : 0) +
            'S '
          : 'Finished';
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  }
  useEffect(() => {
    if (doc.length > 0) {
      sendPO();
    }
  }, [doc]);
  useEffect(() => {
    if (voucher && voucher.type !== 'Scheduled' && type !== 'History') {
      // //console.log(props);
      if (voucher.endDate) {
        if (option !== 'Main Data') {
          clearInterval(timeinterval);
        } else {
          initializeClock(voucher.endDate);
        }
      }

      return () => {
        clearInterval(timeinterval);
      };
    }
  }, [voucher.endDate, option]);

  let handleToggleAgreement = async () => {
    let response = await vouchersEshtreanaService.toggleAgreements(id);
    //console.log(response);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setPurchasingDoen((prev) => !prev);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  let getVoucherOrders = async () => {
    let response = await vouchersEshtreanaService.getVoucherOrders(id, page, 5);
    let arr = [];
    //console.log(response);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      arr = response.data.orders.map((item) => [
        item.personal ? item.personal.uuid : item.organization.uuid,
        item.personal ? 'Personal' : 'organization',
        item.personal ? item.personal.name : item.organization.commercialName,
        item.personal ? item.personal.phone : 'NAN',
        item.status,
      ]);

      setvOucherOrders(arr);
      setTotalPages(response.data.totalPages);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
    setLoadingTable(false);
  };
  useEffect(() => {
    getSingleVoucher();
    getVoucherOrders();
    setFirstLoad(false);
  }, [id]);

  useEffect(() => {
    if (!firstLoad) getVoucherOrders();
  }, [page]);

  useEffect(() => {
    !showConfirmation && voucher.title_en && setLoading(false);
  }, [showConfirmation]);

  const downloadFile = (file) => {
    const element = document.createElement('a');
    element.setAttribute('href', file);
    element.setAttribute('download', file);

    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  };
  let handleDownloadCSV = async () => {
    let response = await reportService.downloadService(
      id,
      (downloadOption === 'Download Financial CSV' && 'financial') ||
        (downloadOption === 'Download Reports CSV' && 'compliants') ||
        (downloadOption === 'Download Orders CSV' && 'orders'),
      'voucher',
    );
    // axios.get(response.data.excel);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (response.data && response.data.message) {
      setServerErrorMsg(response.data.message);
      setServerError(true);
      Valid = false;
    }
    if (Valid === true) {
      downloadFile(response.data.excel);

      //console.log(response);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem('errorMsg'),
        }),
      );
    }
  };

  useEffect(() => {
    showIndexArea !== null && setShowDistrict(true);
  }, [showIndexArea]);
  return (
    <div className={`w-100 ${classes['voucher']}`}>
      {serverError && (
        <Modal style={{ padding: '24px' }} onClose={() => setServerError(false)}>
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowConfirmation(false)}>
          <SuccessPopup setShowForm={setShowConfirmation} message1={`Agreements Files Sent Successfully`} />
        </Modal>
      )}
      {(showSendInvoice || showSendPO) && (
        <Modal
          onClose={() => {
            setShowSendInvoice(false);
            setShowSendPO(false);
            setCloseOption(true);
          }}
          style={{ padding: '24px' }}
        >
          <SendPOInvoiceFile
            title="PO"
            setShowForm={() => {
              setShowSendInvoice(false);
              setShowSendPO(false);
              setCloseOption(true);
            }}
          />
        </Modal>
      )}
      {showAreaDistrict && (
        <Modal style={{ padding: '24px' }} onClose={() => setShowAreaDistrict(false)}>
          <ShowAreaDistrict setShowForm={setShowAreaDistrict} />
        </Modal>
      )}
      {showDistrict && (
        <Modal
          style={{ padding: '24px' }}
          onClose={() => {
            setShowDistrict(false);
            setShowIndexArea(null);
          }}
        >
          <ShowAreaDistrict
            setShowForm={() => {
              setShowDistrict(false);
              setShowIndexArea(null);
            }}
            area={voucher.areas[showIndexArea]}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container ">
        <div className={classes['title']}>
          <PageTitle text="Voucher Details" icon={<VoucherActieveL />} />
        </div>
        {loading ? (
          <section className={`${classes['main-section']}`}>
            <ManagerHeader loading={true} />
            <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
              <div className="row px-0 mx-0">
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                </div>
              </div>
            </div>

            <>
              {[0, 1, 2, 3, 4].map((item, index) => (
                <div key={index} className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '250px' }} />
                      </p>
                      <span>
                        <Skeleton variant="text" sx={{ fontSize: '2rem', width: '90%' }} />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </section>
        ) : (
          <section className={`${classes['main-section']} mb-5`}>
            <ManagerHeader
              id={voucher.supplier._id}
              profilePicture={voucher.supplier.pic}
              type="supplier"
              data={{
                name: voucher.supplier.name_en,
                categories: voucher.categories,
              }}
              // response={type === "Scheduled" && "Accepted"}

              optionComponent={
                type === 'In Use' ? (
                  <>
                    <div
                      onClick={() => {
                        setCloseOption(false);
                        setShowSendPO(true);
                      }}
                      className="row mx-0 mb-2 mx-0"
                    >
                      <div className="w-auto d-inline">
                        <Docs color="#6E7980" />
                      </div>
                      &nbsp; PO
                    </div>
                    <div
                      onClick={() => {
                        setCloseOption(false);
                        setShowSendInvoice(true);
                      }}
                      className="row mx-0 mb-2 mx-0"
                    >
                      <div className="w-auto d-inline">
                        <Docs color="#6E7980" />
                      </div>
                      &nbsp; Invoice
                    </div>
                  </>
                ) : (
                  option === 'Customers' && (
                    <>
                      <div className="row px-0 mx-0 mb-2">
                        <div className={`w-auto d-inline ${classes['title']}`}>Download</div>
                        {/* <div/ */}
                      </div>

                      {['Download Financial CSV', 'Download Reports CSV', 'Download Orders CSV'].map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            // setCloseOption(false);
                            // setShowSendPO(true);
                          }}
                          className={`row mx-0 mb-2   rounded-3 mx-0 py-0 align-items-center ${classes['header-option']}`}
                        >
                          <div className="w-auto h-auto align-items-center  d-inline">
                            <div className=" w-auto py-0 my-0 h-auto">
                              <Radio
                                checked={downloadOption === item ? true : false}
                                onClick={() => {
                                  setDownloadOption(item);
                                }}
                                className="h-auto py-0 my-0"
                              />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setDownloadOption(item);
                            }}
                            className="w-auto d-inline h-100"
                          >
                            {item}
                          </div>
                        </div>
                      ))}

                      <div className="row px-0 mx-0 mt-5">
                        <Button
                          onClick={handleDownloadCSV}
                          color={'#D1E6FF'}
                          borderColor={'#D1E6FF'}
                          fontColor={'#134074'}
                          className={'w-auto px-5 mx-auto'}
                          type="normal"
                          text={'Download'}
                        />
                      </div>
                    </>
                  )
                )
              }
              button={
                option === 'Customers' && (
                  <Button
                    icon={<Download color={'#134074'} />}
                    color={'#D1E6FF'}
                    borderColor={'#D1E6FF'}
                    fontColor={'#134074'}
                    className={' px-2 ms-auto text-center'}
                    type="normal"
                    text={'Download'}
                  />
                )
              }
              closeOption={closeOption}
            />

            {type && (
              <div className={`row px-0 mx-0    ${classes.options}`}>
                <SelectOption
                  onSelect={(val) => {
                    setOption(val);
                  }}
                  default={option}
                  options={
                    (type === 'On Going' && ['Main Data', 'Supplier Payment Terms', 'Customer Payment Terms', 'Voucher Usage Terms']) ||
                    (type === 'Scheduled' && ['Main Data', 'Supplier Payment Terms', 'Customer Payment Terms', 'Voucher Usage Terms']) ||
                    (type === 'History' && [
                      'Main Data',
                      'Supplier Payment Terms',
                      'Customer Payment Terms',
                      'Voucher Usage Terms',
                      'Customers',
                      'Agreements',
                    ])
                  }
                />
              </div>
            )}
            {option === 'Main Data' && (
              <>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className={`${type !== 'Scheduled' && 'col-xl-4'} col-md-6 col-12 px-0`}>
                    <div dir="auto" className={classes['single-item']}>
                      <p>Voucher Code</p>
                      <span>{voucher.uuid}</span>
                    </div>
                  </div>
                  <div dir="auto" className={`${type !== 'Scheduled' && 'col-xl-4'} col-md-6 col-12 px-0`}>
                    <div className={classes['single-item']}>
                      <p>Customer Type</p>
                      <span>{voucher.customerType}</span>
                    </div>
                  </div>
                  {type !== 'Scheduled' && type !== 'History' && (
                    <div dir="auto" className="col-xl-4 col-md-6 col-12 px-0">
                      <div className={classes['single-item']}>
                        <p>Time Left</p>
                        <span className="clock"></span>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Voucher Title</p>
                      <span>{voucher.title_en}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div className={classes['single-item']}>
                      <p>عنوان الكوبون</p>
                      <span>{voucher.title_ar}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Quantity</p>
                      <span>{voucher.quantity}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-md-6 col-12 px-0">
                    <div className={classes['single-item']}>
                      <p>Voucher Status</p>
                      <span>{voucher.status}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Eshtreana Buying Price</p>
                      <span>{voucher.buyingPrice}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-md-6 col-12 px-0">
                    <div className={classes['single-item']}>
                      <p>Eshtreana Selling Price</p>
                      <span>{voucher.sellingPrice}</span>
                    </div>
                  </div>
                </div>
                <div className={`row  px-0 mx-0 mt-2 ${classes['company-profile--data']}`}>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes['single-item']}>
                      <p>Voucher Expiry Date</p>
                      <span>{voucher.expireDate.slice(0, 10)}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-md-6 col-12 px-0">
                    <div className={classes['single-item']}>
                      <p>Voucher Value</p>
                      <span>{voucher.voucherPrice}</span>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0 mb-3 ">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p className="mb-4">Areas</p>
                      {voucher.areas.length > 0 ? (
                        voucher.areas.map((item, index) => (
                          <span
                            onClick={() => {
                              item.district.length > 0 && setShowIndexArea(index);
                            }}
                            key={index}
                            className={`${classes['area']} w-auto  d-inline`}
                          >
                            {item.city.city_en}
                          </span>
                        ))
                      ) : (
                        <span
                          // onClick={() => setShowIndexArea(index)}
                          // key={index}
                          className={`${classes['area']} w-auto  d-inline`}
                        >
                          All KSA
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {type !== 'Scheduled' && (
                  <div className={`row  px-0 mx-0 mt-4 ${classes['company-profile--data']}`}>
                    <div className="col-12 px-0">
                      <div dir="auto" className={classes['single-item']}>
                        <p>Progress</p>
                        <div className="row  px-0 mx-0">
                          <div className="col-lg-2 col-6">
                            <CircularProgressbar
                              // color="green"
                              // style={{ color: "#949494" }}
                              value={Math.ceil((voucher.sold / voucher.quantity) * 100)}
                              text={Math.ceil((voucher.sold / voucher.quantity) * 100)}
                              // text={`${60}`}
                            />
                          </div>
                          <div className="col-lg-6 mt-lg-0 mt-4  d-flex flex-column  justify-content-center ms-auto d-inline">
                            <div className="row px-0 mx-0">
                              <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                <Avatar
                                  sx={{
                                    bgcolor: '#949494',
                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></Avatar>

                                {/* <div className={`${classes["single-item-progess1"]}`} style={{ width: 30 }}></div> */}
                              </div>
                              <div className={`w-auto d-inline pe-5 ${classes['single-item']}`}>
                                <p>Available Vouchers</p>
                              </div>
                              <div className={`w-auto d-inline  ${classes['single-item']}`}>
                                <p>{voucher.quantity - voucher.sold}</p>
                              </div>
                            </div>
                            <div className="row px-0 mx-0">
                              <div className={`w-auto d-inline pe-2 ${classes['single-item']}`}>
                                <Avatar
                                  sx={{
                                    bgcolor: '#3e98c7',

                                    width: '30px',
                                    height: '30px',
                                  }}
                                ></Avatar>
                              </div>
                              <div className={`w-auto d-inline pe-5 ${classes['single-item']}`}>
                                <p>Sold vouchers</p>
                              </div>
                              <div className={`w-auto d-inline text-center ${classes['single-item']}`}>
                                <p>{voucher.sold}</p>
                              </div>
                            </div>
                            {/* <div className="row px-0 mx-">Sold Vouchers 600</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {type === 'In Use' && (
                  <div className={`row mx-0 px-0  justify-content-end mb-pb-0  `}>
                    {/* <div className="w-auto  ms-auto"> */}
                    <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            // setOption("Main Data");
                          }}
                          // icon={<Plus />}

                          className={'w-auto d-inline px-5   '}
                          type="reverse"
                          text={'Cancel'}
                        />
                      </div>
                    </div>
                    <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            setOption('Supplier Payment Terms');
                          }}
                          color={'#D1E6FF'}
                          borderColor={'#134074'}
                          fontColor={'#134074'}
                          // icon={<Plus />}

                          className={'w-auto d-inline px-5  ms-auto text-end'}
                          type="normal"
                          text={'Next'}
                        />
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                )}
              </>
            )}

            {option === 'Supplier Payment Terms' && (
              <>
                <TermsSections terms={voucher.supplierPaymentTerms || []} border={true} />
              </>
            )}
            {option === 'Customer Payment Terms' && <TermsSections terms={voucher.customerPaymentTerms || []} border={true} />}

            {option === 'Voucher Usage Terms' && (
              <>
                <TermsSections terms={voucher.terms || []} border={true} />
              </>
            )}
            {option === 'Customers' &&
              (loadingTable ? (
                <>
                  <Table
                    options={[
                      {
                        title: 'Customer ID',
                        icon: false,
                      },
                      {
                        title: 'Customer Type',
                        icon: false,
                      },
                      {
                        title: 'Customer Name',
                        icon: false,
                      },
                      {
                        title: 'Customer Phone',
                        icon: false,
                      },
                      {
                        title: 'Status',
                        icon: false,
                      },
                    ]}
                    data={[
                      [
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      ],
                      [
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      ],
                      [
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      ],
                      [
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      ],
                      [
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />,
                      ],
                    ]}
                    customStyleIndex={[]}
                    customStyleFn={[false, false, false, false, false]}
                  />
                  <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                    <div className="w-auto d-inline ms-auto">
                      <Skeleton variant="text" sx={{ width: '200px', fontSize: '1rem' }} />
                    </div>
                  </div>
                </>
              ) : (
                <div className="">
                  <Table
                    // type="Voucher"
                    options={[
                      {
                        title: 'Customer ID',
                        icon: false,
                      },
                      {
                        title: 'Customer Type',
                        icon: false,
                      },
                      {
                        title: 'Customer Name',
                        icon: false,
                      },
                      {
                        title: 'Customer Phone',
                        icon: false,
                      },
                      {
                        title: 'Status',
                        icon: false,
                      },
                    ]}
                    // wholeData={wholeDataCompanies}
                    data={voucherOrders}
                    customStyleIndex={[5]}
                    filterOption={[]}
                    customStyleFn={[
                      false,
                      false,
                      false,
                      false,
                      (val) => {
                        return (
                          (val === 'Used' && {
                            borderRadius: '10px',
                            color: '#134074',
                            fontWeight: '600',
                            width: 'auto',
                            // height: "90%",
                            marginTop: '8px',
                            padding: '8px',
                            background: '#134074BD',
                            textAlign: 'center',
                            // PaddingTop: "8px",
                          }) ||
                          (val === 'Valid' && {
                            borderRadius: '10px',
                            color: '#2CD6C2',
                            fontWeight: '600',
                            width: 'auto',
                            // height: "90%",
                            marginTop: '8px',
                            padding: '8px',
                            background: '#E3FDF8',
                            textAlign: 'center',
                          }) ||
                          (val === 'Expired' && {
                            borderRadius: '10px',
                            color: '#F2055C',
                            fontWeight: '600',
                            width: 'auto',
                            // height: "90%",
                            marginTop: '8px',
                            padding: '8px',
                            background: '#F2055C69',
                            textAlign: 'center',
                          })
                        );
                      },
                    ]}
                  />
                  {totalPages > 1 && (
                    <div className={`row mx-0 px-0 align-items-center ${classes.pagination}`}>
                      <div className="w-auto d-inline ms-auto">
                        <UsePagination
                          page={page}
                          pages={totalPages}
                          onChangePage={(page) => {
                            setLoadingTable(true);
                            setPage(page);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}

            {option === 'Agreements' && (
              <>
                {!purchasingDone && (
                  <div className="row px-0 mx-0">
                    <div className="col-12 ">
                      <div className={classes.input}>
                        <UploadImageGroup2
                          type="supplier"
                          onChange={(e) => {
                            setDoc([e]);
                          }}
                          errorMsg="Provide a File"
                          title={'Purshacing Order'}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row px-0 mx-0">
                  <div className="col-12 ">
                    <div className={classes.input}>
                      <UploadImageGroup2
                        noDeleteAll={true}
                        default={
                          (voucher.purchasingOrder && voucher.invoice && [voucher.purchasingOrder, voucher.invoice]) ||
                          (voucher.purchasingOrder && !voucher.invoice && [voucher.purchasingOrder]) ||
                          (!voucher.purchasingOrder && voucher.invoice && [voucher.invoice])
                        }
                        imageType="invoice/po"
                        // onChange={(e) => {
                        //   setDataErr((prev) => ({
                        //     ...prev,
                        //     profilePicture: true,
                        //   }));
                        //   setData((prev) => ({
                        //     ...prev,
                        //     profilePicture: e.target.files[0],
                        //   }));
                        // }}
                        // onRemove={() => {
                        //   setData((prev) => ({
                        //     ...prev,
                        //     profilePicture: null,
                        //   }));
                        //   setDataErr((prev) => ({
                        //     ...prev,
                        //     profilePicture: false,
                        //   }));
                        // }}
                        // name="profilePicture"
                        // error={showError && !dataErr.profilePicture}
                        errorMsg="Provide a File"
                        title={'Invoice'}
                        subTitle="click on invoice to download"
                      />
                    </div>
                  </div>
                </div>

                <div className={`row mx-0 px-0  justify-content-end mb-pb-0  `}>
                  {/* <div className="w-auto  ms-auto"> */}

                  <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => {
                          handleToggleAgreement();
                        }}
                        // icon={<Plus />}
                        color={'#D1E6FF'}
                        borderColor={'#134074'}
                        fontColor={'#134074'}
                        className={'w-auto d-inline px-5  ms-auto text-end'}
                        type="normal"
                        text={`${!purchasingDone ? 'Stop Agreements' : 'Start Agreements'}`}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            )}
          </section>
        )}

        {/* {type === "Scheduled" && (
          <div
            className={` row mx-0 px-0 ${classes.input}  justify-content-end mb-pb-0`}
          >
            <div className="w-auto d-md-inline mb-md-0 mb-3 px-0 pe-2 mb-pb-0">
              <div className={`  mb-pb-0`}>
                <Button
                  onClick={() => {
                    // setOption("Description");
                    // setLoading(true);
                    // getManagerData();
                    // getManagerMedia();
                    // setReload((prev) => prev + 1);
                  }}
                  // icon={<Plus />}

                  className={"w-auto d-inline px-5   "}
                  type="reverse"
                  text={"Ignore"}
                />
              </div>
            </div>
            <div className="w-auto d-inline  px-0 ps-md-2 pe-md-0 pe-2 mb-pb-0">
              <div className={`  mb-pb-0`}>
                <Button
                  onClick={() => {
                    // setOption("Description");
                    // setShowConfirmation(true);
                    // handleSubmit();
                    // setShowStartDealForm(true);
                  }}
                  // icon={<Plus />}
                  color={"#D1E6FF"}
                  borderColor={"#134074"}
                  fontColor={"#134074"}
                  className={"w-auto d-inline px-5  ms-auto text-end"}
                  type="normal"
                  text={"Start Deal"}
                />
              </div>
            </div>
          </div>
        )}
         */}
      </section>
    </div>
  );
}
