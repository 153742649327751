import React, { useEffect, useState } from 'react';
import classes from './AddMember.module.scss';
import InputGroup from '../../UI/InputGroup/InputGroup';
import Close from '../../SVG/Close';
import UploadImageGroup from '../../UI/InputGroup/UploadImageGroup';
import Button from '../../UI/Button/Button';
import { serverErrorCatcher } from '../../../../Helper/ServerErrorCatcher';
import { MainDataService } from '../../../../services/mainData';
export default function AddSocial(props) {
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formData, setFormData] = useState({
    fullName: null,
    email: null,
    title: null,
    pic: null,
  });
  const [formData1, setFormData1] = useState({
    title: null,
    link: null,
    pic: null,
  });
  const [formDataErr1, setFormDataErr1] = useState({
    title: false,
    link: false,
    pic: false,
  });
  useEffect(() => {
    if (props.socialMedia) {
      if (props.item) {
        setFormData1({
          title: props.item.title,
          link: props.item.link,
          pic: props.item.pic,
        });
        setFormDataErr1({
          title: true,
          link: true,
          pic: true,
        });
      }
    }
  }, []);
  let handleChange1 = (e) => {
    setFormData1((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    e.target.value === ''
      ? setFormDataErr1((prev) => ({ ...prev, [e.target.name]: false }))
      : setFormDataErr1((prev) => ({ ...prev, [e.target.name]: true }));
    if (e.target.name === 'pic') setFormData1((prev) => ({ ...prev, pic: e.target.files[0] }));

    if (e.target.name === 'link')
      if (!e.target.value.match('^(http|https)://')) {
        setFormDataErr1((prev) => ({ ...prev, [e.target.name]: false }));
      } else {
        setFormDataErr1((prev) => ({ ...prev, [e.target.name]: true }));
      }
  };
  let handleSubmit = async () => {
    if (props.socialMedia) {
      setShowError(true);
      if (formDataErr1.title && formDataErr1.link && formDataErr1.pic) {
        let fd = new FormData();
        fd.append('title', formData1.title);
        fd.append('link', formData1.link);
        formData1.pic.name && fd.append('logo', formData1.pic, formData1.pic.name);
        let response = props.type === 'Edit' ? await MainDataService.updateSocial(fd, props.item.id) : await MainDataService.addSocial(fd);
        let vaild = false;
        vaild = serverErrorCatcher(response, setServerError, setServerErrorMsg);
        if (vaild === true) props.onSubmit();
      }
    }
    setLoading(false);
  };
  return (
    <div
      style={{
        maxHeight: `${window.innerHeight - 100}px`,
        overflow: 'hidden',
        overflowY: 'scroll',
      }}
    >
      {((props.type === 'Edit' && formData.fullName) || (props.type === 'Edit' && formData1.link && props.socialMedia) || props.type === 'Add') && (
        <>
          <div className="row mx-0 px-0 mb-2">
            <div className={`col-10 px-0 ${classes.title}`}>
              {props.type} {props.socialMedia && 'Social Media'}
            </div>
            <div onClick={() => props.setShowForm(false)} className="cursor col-2 px-0 text-end">
              <Close />
            </div>
          </div>

          {props.socialMedia && (
            <>
              <div className="row mx-0 px-0">
                <div className="col-12 px-0 mx-0">
                  <div className="row  px-0 mx-0">
                    <div className="col-12 px-0 pe-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={formData1.title}
                          onChange={(e) => handleChange1(e)}
                          error={showError && !formDataErr1.title}
                          errorMsg="provide a Social Media Name"
                          name="title"
                          value="Title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 px-0">
                <div className="col-12 px-0">
                  <div className={classes.input}>
                    <InputGroup
                      default={formData1.link}
                      onChange={(e) => handleChange1(e)}
                      error={showError && !formDataErr1.link}
                      errorMsg={formData1.link ? 'provide a Vaild Social Media link' : 'provide a Social Media link'}
                      name="link"
                      value="link"
                    />
                  </div>
                </div>
              </div>
              <div className="row mx-0 px-0">
                <div className={classes.input}>
                  <UploadImageGroup
                    socialMedia={props.socialMedia}
                    onRemove={() => setFormData1((prev) => ({ ...prev, pic: null }))}
                    default={formData1.pic}
                    onChange={(e) => handleChange1(e)}
                    error={showError && !formDataErr1.pic}
                    errorMsg="provide Logo"
                    uploadText="Logo"
                    name="pic"
                    type="logo"
                    value="Logo"
                    // descText={`Please upload a photo of your tax card (this won’t be shown to anyone but you and HAAI team)`}
                  />
                </div>
              </div>
            </>
          )}
          {serverError && (
            <div className="text-center">
              <span className="errorMsg text-cenetr">{serverErrorMsg}</span>
            </div>
          )}
          <div className="row mx-0 px-0 pb-0 mb-0">
            <div className="col-4 px-0 ms-auto">
              <div className={classes.input}>
                {loading ? (
                  <div className="text-center">
                    <span className="spinner-border spinner-border-sm"></span>
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      setLoading(true);
                      handleSubmit();
                    }}
                    className={'w-auto px-2 ms-auto text-end'}
                    type="normal"
                    text={`${props.type} Socail Media `}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
