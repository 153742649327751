import React from 'react';
import classes from './ManagerCard.module.scss';
import Img from '../Img';
import { useNavigate } from 'react-router-dom';
import Close from '../../SVG/Close';
import { Skeleton } from '@mui/material';
import avatar from '../../../../assests/avatar.jpg';

export default function ManagerCard(props) {
  const history = useNavigate();
  let handleNavigation = () => {
    if (!props.loading && !props.delete) {
      props.type === 'marketing-manager' && history(`/account/marketing-manager/${props.id}`);
      props.type === 'category-manager' && history(`/account/category-manager/${props.id}`);
      props.type === 'cashier' && history(`/supplier/cashiers/${props.id}`);
      props.type === 'supplier' && history(`/account/suppliers/${props.id}`);
    }
  };
  return (
    <div onClick={handleNavigation} className="w-auto   cursor d-inline ">
      {props.loading ? (
        <div className={` ${classes['suppliers-card']} position-relative`}>
          <div className="justify-content-center d-flex">
            <Skeleton variant="circular" width={80} height={80} />
          </div>

          {[0, 1].map((item, index) => (
            <div key={index} className="row mx-0 px-0 ">
              <div className={`w-100 d-inline px-0 ${classes.info}`}>
                <p>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={` ${classes['suppliers-card']} h-100  position-relative`}>
          {!props.show && (
            <div onClick={() => props.onRemove()} style={{ right: 16 }} className="position-absolute">
              <Close color="#134074" />
            </div>
          )}
          <div className="justify-content-center d-flex">
            <Img
              noShow={true}
              style={{ ObjectFit: 'fit' }}
              src={props.profilePicture ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${props.type}/${props.profilePicture}` : avatar}
            />
          </div>

          {props.options.map((item, index) => (
            <div key={index} className="row text-nowrap mx-0 px-0 overflow-hidden ">
              <div className={`w-auto d-inline ${!item.title && 'mx-auto'} px-0 ${classes.info}`}>
                {item.list ? (
                  <p
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title={item.value.map((item1) => (item1.item && item1.item.name_en ? item1.item.name_en : item1))}
                  >
                    {item.title} {item.title && ':'}
                    <span className=" ">
                      {item.value.map((item1, index1) => (
                        <div className="w-auto d-inline" key={index1}>
                          {item1.item && item1.item.name_en ? item1.item.name_en : item1}
                          &nbsp;
                        </div>
                      ))}
                    </span>
                  </p>
                ) : (
                  <p data-bs-toggle="tooltip" data-bs-placement="right" title={item.value}>
                    {item.title} {item.title && ':'}
                    <span>
                      {/* {item.value.length > 10 */}
                      {/* // ? item.value.slice(0, 7) + "..." */}
                      {item.value}
                    </span>
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
