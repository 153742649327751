import React from 'react';

export default function Login2() {
  return (
    <svg
      // width="600"
      className="w-auto d-inline "
      height="450"
      viewBox="0 0 600 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M600 527.27H0V527.672H600V527.27Z" fill="#EBEBEB" />
      <path d="M539.88 553.18H500.136V553.582H539.88V553.18Z" fill="#EBEBEB" />
      <path d="M397.464 557.562H387.036V557.965H397.464V557.562Z" fill="#EBEBEB" />
      <path d="M498.936 538.233H475.908V538.635H498.936V538.233Z" fill="#EBEBEB" />
      <path d="M114.78 540.94H62.9521V541.342H114.78V540.94Z" fill="#EBEBEB" />
      <path d="M133.068 540.94H125.472V541.342H133.068V540.94Z" fill="#EBEBEB" />
      <path d="M270.18 547.737H157.764V548.139H270.18V547.737Z" fill="#EBEBEB" />
      <path
        d="M284.4 455.439H52.6916C50.8764 455.435 49.1366 454.464 47.8542 452.74C46.5718 451.016 45.8516 448.68 45.8516 446.243V9.11523C45.8674 6.69313 46.5946 4.37721 47.8753 2.6705C49.156 0.963795 50.8867 0.00414918 52.6916 0H284.4C286.217 0 287.96 0.968835 289.245 2.69337C290.53 4.41791 291.252 6.75688 291.252 9.19575V446.243C291.252 448.682 290.53 451.021 289.245 452.746C287.96 454.47 286.217 455.439 284.4 455.439ZM52.6916 0.322094C50.9559 0.32636 49.2925 1.25466 48.0664 2.90323C46.8402 4.5518 46.1516 6.78593 46.1516 9.11523V446.243C46.1516 448.573 46.8402 450.807 48.0664 452.455C49.2925 454.104 50.9559 455.032 52.6916 455.037H284.4C286.136 455.032 287.801 454.105 289.029 452.456C290.257 450.808 290.948 448.574 290.952 446.243V9.11523C290.948 6.78445 290.257 4.55035 289.029 2.90224C287.801 1.25413 286.136 0.326351 284.4 0.322094H52.6916Z"
        fill="#EBEBEB"
      />
      <path
        d="M543.972 455.439H312.252C310.436 455.435 308.695 454.465 307.411 452.741C306.127 451.018 305.404 448.681 305.4 446.243V9.11523C305.419 6.69166 306.149 4.37575 307.432 2.66949C308.714 0.963232 310.446 0.00410393 312.252 0H543.972C545.775 0.00838165 547.503 0.969901 548.781 2.67615C550.059 4.38241 550.785 6.69591 550.8 9.11523V446.243C550.8 448.677 550.082 451.011 548.802 452.734C547.522 454.458 545.785 455.431 543.972 455.439ZM312.252 0.322094C310.516 0.326351 308.851 1.25413 307.623 2.90224C306.395 4.55035 305.704 6.78445 305.7 9.11523V446.243C305.704 448.574 306.395 450.808 307.623 452.456C308.851 454.105 310.516 455.032 312.252 455.037H543.972C545.709 455.032 547.374 454.105 548.602 452.456C549.83 450.808 550.521 448.574 550.524 446.243V9.11523C550.521 6.78445 549.83 4.55035 548.602 2.90224C547.374 1.25413 545.709 0.326351 543.972 0.322094H312.252Z"
        fill="#EBEBEB"
      />
      <path
        d="M379.848 366.302H524.028C524.027 369.721 523.043 373.007 521.282 375.476C519.52 377.945 517.116 379.407 514.572 379.556C499.08 380.507 475.236 383.792 467.688 395.291C453.588 416.742 454.956 492.079 471.84 509.521C477.12 514.996 492.552 525.851 492.552 525.851H411.324C411.324 525.851 425.724 514.771 430.752 509.521C447.552 491.935 448.02 416.645 433.86 395.291C426.6 384.339 404.256 380.796 389.268 379.685C386.72 379.496 384.323 378.002 382.571 375.513C380.818 373.023 379.844 369.727 379.848 366.302Z"
        fill="#F5F5F5"
      />
      <path
        d="M506.16 380.265C491.52 381.875 473.976 385.724 467.688 395.291C465.635 398.595 464.012 402.341 462.888 406.371H438.708C437.568 402.351 435.947 398.608 433.908 395.291C427.596 385.773 409.908 381.859 395.508 380.265H506.16Z"
        fill="#E6E6E6"
      />
      <path d="M441.264 366.399L443.232 336.332H414.804L412.848 366.399H441.264Z" fill="#EBEBEB" />
      <path d="M441.264 366.399L443.232 336.332H444.756L442.8 366.399H441.264Z" fill="#E0E0E0" />
      <path d="M438.564 363.338L440.124 339.39H417.504L415.944 363.338H438.564Z" fill="#F0F0F0" />
      <path d="M436.836 361.356L438.132 341.371H419.244L417.936 361.356H436.836Z" fill="#FAFAFA" />
      <path d="M450.48 366.298H479.028L475.86 317.711H447.312L450.48 366.298Z" fill="#E6E6E6" />
      <path d="M450.48 366.298H449.124L445.956 317.711H447.312L450.48 366.298Z" fill="#E0E0E0" />
      <path d="M453.072 361.357H475.8L473.28 322.674H450.552L453.072 361.357Z" fill="#F0F0F0" />
      <path d="M454.728 358.167H473.724L471.612 325.861H452.628L454.728 358.167Z" fill="#FAFAFA" />
      <path
        d="M464.328 340.391C464.021 340.805 463.656 341.133 463.255 341.358C462.853 341.582 462.423 341.697 461.988 341.697C461.553 341.697 461.122 341.582 460.721 341.358C460.319 341.133 459.954 340.805 459.648 340.391C459.031 339.558 458.686 338.432 458.686 337.259C458.686 336.085 459.031 334.959 459.648 334.126C460.27 333.298 461.111 332.834 461.988 332.834C462.864 332.834 463.705 333.298 464.328 334.126C464.944 334.959 465.29 336.085 465.29 337.259C465.29 338.432 464.944 339.558 464.328 340.391Z"
        fill="#EBEBEB"
      />
      <path
        d="M462.924 345.83C463.147 345.525 463.414 345.284 463.707 345.119C464.001 344.954 464.316 344.869 464.634 344.869C464.952 344.869 465.267 344.954 465.561 345.119C465.854 345.284 466.121 345.525 466.344 345.83C466.691 346.278 466.929 346.857 467.028 347.489C467.127 348.122 467.081 348.78 466.897 349.378C466.713 349.976 466.4 350.485 465.997 350.84C465.595 351.196 465.122 351.38 464.64 351.37C464.157 351.383 463.683 351.201 463.278 350.847C462.874 350.494 462.558 349.984 462.373 349.386C462.187 348.787 462.141 348.128 462.239 347.493C462.338 346.859 462.576 346.279 462.924 345.83Z"
        fill="#F0F0F0"
      />
      <path d="M98.0639 310.914H232.692L232.692 111.087L98.0639 111.087L98.0639 310.914Z" fill="#E0E0E0" />
      <path d="M93.7684 310.914H233.4L233.4 111.087L93.7684 111.087L93.7684 310.914Z" fill="#F0F0F0" />
      <path d="M99.6841 301.721H227.724L227.724 117.451L99.6841 117.451L99.6841 301.721Z" fill="white" />
      <path d="M99.6719 301.721H103.38L103.38 117.451H99.6719L99.6719 301.721Z" fill="#E0E0E0" />
      <path d="M102.984 249.958V254.934H228.048V249.958H102.984Z" fill="#E0E0E0" />
      <path d="M99.6721 249.959L229.704 249.959V241.617L99.6721 241.617V249.959Z" fill="#F0F0F0" />
      <path
        d="M454.068 145.297C454.068 145.297 455.268 134.668 449.496 127.855C443.724 121.043 444.228 116.985 445.896 116.453C447.564 115.922 449.064 122.75 451.608 124.618C451.608 124.618 451.692 114.956 449.112 111.332C446.532 107.709 445.176 98.545 447.636 98.4484C450.096 98.3517 450.336 103.924 450.852 107.918C451.368 111.912 454.092 117.581 454.092 117.581C454.092 117.581 454.62 108.884 456.288 105.309C457.956 101.734 453.516 93.5526 455.232 89.9773C456.948 86.4021 460.032 93.1983 459.732 100.88C459.432 108.562 455.676 116.727 456.564 124.731C456.564 124.731 458.244 119.111 459.744 118.595C461.244 118.08 462.78 114.376 463.788 112.991C464.796 111.606 466.08 116.389 463.788 120.818C461.496 125.246 455.676 137.1 456.588 146.666L454.068 145.297Z"
        fill="#E6E6E6"
      />
      <path
        d="M450.612 140.466L448.716 156.393C448.572 157.556 448.62 158.747 448.856 159.882C449.092 161.017 449.511 162.068 450.084 162.962C450.656 163.856 451.367 164.57 452.168 165.055C452.968 165.539 453.838 165.782 454.716 165.766H456.732C457.61 165.782 458.48 165.539 459.28 165.055C460.081 164.57 460.792 163.856 461.365 162.962C461.937 162.068 462.356 161.017 462.592 159.882C462.828 158.747 462.876 157.556 462.732 156.393L460.8 140.466H450.612Z"
        fill="#F5F5F5"
      />
      <path d="M479.208 159.884H345.528V169.096H479.208V159.884Z" fill="#E6E6E6" />
      <path d="M357.576 125.905H351.684V159.886H357.576V125.905Z" fill="#F0F0F0" />
      <path d="M386.052 125.905H380.16V159.886H386.052V125.905Z" fill="#F0F0F0" />
      <path d="M418.386 125.043L412.58 126.386L416.881 159.873L422.687 158.53L418.386 125.043Z" fill="#F0F0F0" />
      <path d="M364.872 130.382H358.98V159.886H364.872V130.382Z" fill="#F0F0F0" />
      <path d="M378.408 130.382H372.516V159.886H378.408V130.382Z" fill="#F0F0F0" />
      <path d="M412.584 130.382H406.692V159.886H412.584V130.382Z" fill="#F0F0F0" />
      <path d="M393.786 129.34L388.034 131.056L392.804 159.857L398.555 158.141L393.786 129.34Z" fill="#F0F0F0" />
      <path d="M369.96 124.218H367.02V159.89H369.96V124.218Z" fill="#F0F0F0" />
      <path d="M404.988 124.218H402.048V159.89H404.988V124.218Z" fill="#F0F0F0" />
      <path d="M345.54 237.912L479.22 237.912V228.7L345.54 228.7V237.912Z" fill="#E6E6E6" />
      <path d="M467.172 228.702H473.064V194.721H467.172V228.702Z" fill="#F0F0F0" />
      <path d="M438.696 228.702H444.588V194.721H438.696V228.702Z" fill="#F0F0F0" />
      <path d="M402.075 227.329L407.882 228.672L412.183 195.185L406.376 193.842L402.075 227.329Z" fill="#F0F0F0" />
      <path d="M459.876 228.702H465.768V199.198H459.876V228.702Z" fill="#F0F0F0" />
      <path d="M446.34 228.702H452.232V199.198H446.34V228.702Z" fill="#F0F0F0" />
      <path d="M412.164 228.702H418.056V199.198H412.164V228.702Z" fill="#F0F0F0" />
      <path d="M426.206 226.951L431.958 228.667L436.727 199.866L430.975 198.15L426.206 226.951Z" fill="#F0F0F0" />
      <path d="M454.788 228.702H457.728V193.03H454.788V228.702Z" fill="#F0F0F0" />
      <path d="M419.76 228.702H422.7V193.03H419.76V228.702Z" fill="#F0F0F0" />
      <path d="M358.368 228.397H387.06V201.808H358.368V228.397Z" fill="#F5F5F5" />
      <path
        d="M300 600C428.499 600 532.668 591.838 532.668 581.769C532.668 571.701 428.499 563.539 300 563.539C171.501 563.539 67.332 571.701 67.332 581.769C67.332 591.838 171.501 600 300 600Z"
        fill="#F5F5F5"
      />
      <path
        d="M398.964 541.488C398.964 541.488 392.964 524.095 399.804 506.928C406.644 489.76 408.564 485.734 404.244 482.223C399.924 478.712 397.92 496.025 397.92 496.025C395.771 488.237 395.548 479.727 397.284 471.755C400.296 459.339 402.192 439.127 398.076 436.953C393.96 434.779 397.296 462.173 393.72 471.514C391.9 464.961 390.726 458.118 390.228 451.157C389.736 441.189 390.504 425.261 387.252 421.187C384 417.112 381.924 419.914 385.116 441.929C387.035 455.072 388.095 468.414 388.284 481.805C388.284 481.805 381.24 452.945 376.5 452.108C371.76 451.27 371.772 460.611 375.828 468.711C379.884 476.812 387.696 491.338 388.968 498.811C388.968 498.811 381.228 477.102 374.268 483.753C367.308 490.404 380.328 503.53 386.136 508.264C391.944 512.999 395.976 542.712 395.976 542.712L398.964 541.488Z"
        fill="url(#paint0_linear_1097_80129)"
      />
      <path
        opacity="0.2"
        d="M398.964 541.488C398.964 541.488 392.964 524.095 399.804 506.928C406.644 489.76 408.564 485.734 404.244 482.223C399.924 478.712 397.92 496.025 397.92 496.025C395.771 488.237 395.548 479.727 397.284 471.755C400.296 459.339 402.192 439.127 398.076 436.953C393.96 434.779 397.296 462.173 393.72 471.514C391.9 464.961 390.726 458.118 390.228 451.157C389.736 441.189 390.504 425.261 387.252 421.187C384 417.112 381.924 419.914 385.116 441.929C387.035 455.072 388.095 468.414 388.284 481.805C388.284 481.805 381.24 452.945 376.5 452.108C371.76 451.27 371.772 460.611 375.828 468.711C379.884 476.812 387.696 491.338 388.968 498.811C388.968 498.811 381.228 477.102 374.268 483.753C367.308 490.404 380.328 503.53 386.136 508.264C391.944 512.999 395.976 542.712 395.976 542.712L398.964 541.488Z"
        fill="black"
      />
      <path
        d="M394.272 548.626C394.272 548.626 400.056 531.104 393.072 514.098C386.088 497.091 384.12 493.065 388.416 489.474C392.712 485.882 394.848 503.066 394.848 503.066C396.929 495.242 397.077 486.726 395.268 478.78C392.148 466.444 390.072 446.265 394.176 444.01C398.28 441.756 395.184 469.214 398.832 478.474C400.598 471.886 401.712 465.015 402.144 458.038C402.552 448.069 401.64 432.157 404.856 428.018C408.072 423.88 410.172 426.65 407.172 448.713C405.368 461.89 404.429 475.255 404.364 488.652C404.364 488.652 411.156 459.664 415.884 458.73C420.612 457.796 420.684 467.137 416.7 475.318C412.716 483.499 405.048 498.186 403.824 505.675C403.824 505.675 411.372 483.805 418.392 490.327C425.412 496.85 412.512 510.217 406.752 515.08C400.992 519.944 397.224 549.721 397.224 549.721L394.272 548.626Z"
        fill="url(#paint1_linear_1097_80129)"
      />
      <path d="M420.96 536.142H373.152L377.028 582.056H417.096L420.96 536.142Z" fill="#263238" />
      <path
        opacity="0.2"
        d="M514.86 79.5654H262.188C260.544 79.5654 259.212 81.3536 259.212 83.5594V384.748C259.212 386.954 260.544 388.742 262.188 388.742H514.86C516.503 388.742 517.836 386.954 517.836 384.748V83.5594C517.836 81.3536 516.503 79.5654 514.86 79.5654Z"
        fill="black"
      />
      <path
        d="M505.08 73.8467H252.408C250.765 73.8467 249.432 75.6348 249.432 77.8406V379.03C249.432 381.235 250.765 383.024 252.408 383.024H505.08C506.724 383.024 508.056 381.235 508.056 379.03V77.8406C508.056 75.6348 506.724 73.8467 505.08 73.8467Z"
        fill="url(#paint2_linear_1097_80129)"
      />
      <path
        opacity="0.6"
        d="M249.432 101.468V379.047C249.371 380 249.586 380.948 250.033 381.692C250.481 382.436 251.125 382.92 251.832 383.041H505.584C506.304 382.943 506.966 382.47 507.429 381.723C507.891 380.976 508.116 380.015 508.056 379.047V101.468H249.432Z"
        fill="white"
      />
      <path d="M504.984 117.751H252.492V362.976H504.984V117.751Z" fill="white" />
      <path
        opacity="0.6"
        d="M499.608 92.9446C501.702 92.9446 503.4 90.1975 503.4 86.8087C503.4 83.42 501.702 80.6729 499.608 80.6729C497.514 80.6729 495.816 83.42 495.816 86.8087C495.816 90.1975 497.514 92.9446 499.608 92.9446Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M487.188 92.9446C489.283 92.9446 490.98 90.1975 490.98 86.8087C490.98 83.42 489.283 80.6729 487.188 80.6729C485.094 80.6729 483.396 83.42 483.396 86.8087C483.396 90.1975 485.094 92.9446 487.188 92.9446Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M478.548 86.8087C478.548 90.1907 476.856 92.9285 474.756 92.9285C472.656 92.9285 470.976 90.1907 470.976 86.8087C470.976 83.4268 472.668 80.6729 474.756 80.6729C476.844 80.6729 478.548 83.4106 478.548 86.8087Z"
        fill="white"
      />
      <path d="M306.288 146.379V209.622L294.168 229.238H267.324V146.379H306.288Z" fill="url(#paint3_linear_1097_80129)" />
      <path opacity="0.7" d="M306.288 146.379V209.622L294.168 229.238H267.324V146.379H306.288Z" fill="white" />
      <path opacity="0.3" d="M306.288 209.625L294.168 229.24V209.625H306.288Z" fill="url(#paint4_linear_1097_80129)" />
      <path opacity="0.3" d="M300.252 158.333H273.372V160.7H300.252V158.333Z" fill="url(#paint5_linear_1097_80129)" />
      <path opacity="0.3" d="M300.252 167.315H273.372V169.682H300.252V167.315Z" fill="url(#paint6_linear_1097_80129)" />
      <path opacity="0.3" d="M300.252 176.321H273.372V178.704H300.252V176.321Z" fill="url(#paint7_linear_1097_80129)" />
      <path opacity="0.3" d="M300.252 185.321H273.372V187.688H300.252V185.321Z" fill="url(#paint8_linear_1097_80129)" />
      <path opacity="0.3" d="M293.304 194.326H273.372V196.71H293.304V194.326Z" fill="url(#paint9_linear_1097_80129)" />
      <path d="M368.088 146.379V209.622L355.968 229.238H329.124V146.379H368.088Z" fill="url(#paint10_linear_1097_80129)" />
      <path opacity="0.7" d="M368.088 146.379V209.622L355.968 229.238H329.124V146.379H368.088Z" fill="white" />
      <path opacity="0.3" d="M368.088 209.625L355.968 229.24V209.625H368.088Z" fill="url(#paint11_linear_1097_80129)" />
      <path opacity="0.3" d="M362.04 158.333H335.16V160.7H362.04V158.333Z" fill="url(#paint12_linear_1097_80129)" />
      <path opacity="0.3" d="M362.04 167.315H335.16V169.682H362.04V167.315Z" fill="url(#paint13_linear_1097_80129)" />
      <path opacity="0.3" d="M362.04 176.321H335.16V178.704H362.04V176.321Z" fill="url(#paint14_linear_1097_80129)" />
      <path opacity="0.3" d="M362.04 185.321H335.16V187.688H362.04V185.321Z" fill="url(#paint15_linear_1097_80129)" />
      <path opacity="0.3" d="M355.092 194.326H335.16V196.71H355.092V194.326Z" fill="url(#paint16_linear_1097_80129)" />
      <path d="M429.888 146.379V209.622L417.768 229.238H390.924V146.379H429.888Z" fill="url(#paint17_linear_1097_80129)" />
      <path opacity="0.7" d="M429.888 146.379V209.622L417.768 229.238H390.924V146.379H429.888Z" fill="white" />
      <path opacity="0.3" d="M429.888 209.625L417.768 229.24V209.625H429.888Z" fill="url(#paint18_linear_1097_80129)" />
      <path opacity="0.3" d="M423.84 158.333H396.96V160.7H423.84V158.333Z" fill="url(#paint19_linear_1097_80129)" />
      <path opacity="0.3" d="M423.84 167.315H396.96V169.682H423.84V167.315Z" fill="url(#paint20_linear_1097_80129)" />
      <path opacity="0.3" d="M423.84 176.321H396.96V178.704H423.84V176.321Z" fill="url(#paint21_linear_1097_80129)" />
      <path opacity="0.3" d="M423.84 185.321H396.96V187.688H423.84V185.321Z" fill="url(#paint22_linear_1097_80129)" />
      <path opacity="0.3" d="M416.892 194.326H396.96V196.71H416.892V194.326Z" fill="url(#paint23_linear_1097_80129)" />
      <path d="M491.688 146.379V209.622L479.556 229.238H452.724V146.379H491.688Z" fill="url(#paint24_linear_1097_80129)" />
      <path opacity="0.7" d="M491.688 146.379V209.622L479.556 229.238H452.724V146.379H491.688Z" fill="white" />
      <path opacity="0.3" d="M491.688 209.625L479.556 229.24V209.625H491.688Z" fill="url(#paint25_linear_1097_80129)" />
      <path opacity="0.3" d="M485.64 158.333H458.76V160.7H485.64V158.333Z" fill="url(#paint26_linear_1097_80129)" />
      <path opacity="0.3" d="M485.64 167.315H458.76V169.682H485.64V167.315Z" fill="url(#paint27_linear_1097_80129)" />
      <path opacity="0.3" d="M485.64 176.321H458.76V178.704H485.64V176.321Z" fill="url(#paint28_linear_1097_80129)" />
      <path opacity="0.3" d="M485.64 185.321H458.76V187.688H485.64V185.321Z" fill="url(#paint29_linear_1097_80129)" />
      <path opacity="0.3" d="M478.692 194.326H458.76V196.71H478.692V194.326Z" fill="url(#paint30_linear_1097_80129)" />
      <path d="M306.288 255.89V319.133L294.168 338.749H267.324V255.89H306.288Z" fill="url(#paint31_linear_1097_80129)" />
      <path opacity="0.7" d="M306.288 255.89V319.133L294.168 338.749H267.324V255.89H306.288Z" fill="white" />
      <path opacity="0.3" d="M306.288 319.136L294.168 338.751V319.136H306.288Z" fill="url(#paint32_linear_1097_80129)" />
      <path opacity="0.3" d="M300.252 267.844H273.372V270.211H300.252V267.844Z" fill="url(#paint33_linear_1097_80129)" />
      <path opacity="0.3" d="M300.252 276.832H273.372V279.199H300.252V276.832Z" fill="url(#paint34_linear_1097_80129)" />
      <path opacity="0.3" d="M300.252 285.832H273.372V288.215H300.252V285.832Z" fill="url(#paint35_linear_1097_80129)" />
      <path opacity="0.3" d="M300.252 294.837H273.372V297.221H300.252V294.837Z" fill="url(#paint36_linear_1097_80129)" />
      <path opacity="0.3" d="M293.304 303.837H273.372V306.205H293.304V303.837Z" fill="url(#paint37_linear_1097_80129)" />
      <path d="M368.088 255.89V319.133L355.968 338.749H329.124V255.89H368.088Z" fill="url(#paint38_linear_1097_80129)" />
      <path opacity="0.7" d="M368.088 255.89V319.133L355.968 338.749H329.124V255.89H368.088Z" fill="white" />
      <path opacity="0.3" d="M368.088 319.136L355.968 338.751V319.136H368.088Z" fill="url(#paint39_linear_1097_80129)" />
      <path opacity="0.3" d="M362.04 267.844H335.16V270.211H362.04V267.844Z" fill="url(#paint40_linear_1097_80129)" />
      <path opacity="0.3" d="M362.04 276.832H335.16V279.199H362.04V276.832Z" fill="url(#paint41_linear_1097_80129)" />
      <path opacity="0.3" d="M362.04 285.832H335.16V288.215H362.04V285.832Z" fill="url(#paint42_linear_1097_80129)" />
      <path opacity="0.3" d="M362.04 294.837H335.16V297.221H362.04V294.837Z" fill="url(#paint43_linear_1097_80129)" />
      <path opacity="0.3" d="M355.092 303.837H335.16V306.205H355.092V303.837Z" fill="url(#paint44_linear_1097_80129)" />
      <path d="M429.888 255.89V319.133L417.768 338.749H390.924V255.89H429.888Z" fill="url(#paint45_linear_1097_80129)" />
      <path opacity="0.7" d="M429.888 255.89V319.133L417.768 338.749H390.924V255.89H429.888Z" fill="white" />
      <path opacity="0.3" d="M429.888 319.136L417.768 338.751V319.136H429.888Z" fill="url(#paint46_linear_1097_80129)" />
      <path opacity="0.3" d="M423.84 267.844H396.96V270.211H423.84V267.844Z" fill="url(#paint47_linear_1097_80129)" />
      <path opacity="0.3" d="M423.84 276.832H396.96V279.199H423.84V276.832Z" fill="url(#paint48_linear_1097_80129)" />
      <path opacity="0.3" d="M423.84 285.832H396.96V288.215H423.84V285.832Z" fill="url(#paint49_linear_1097_80129)" />
      <path opacity="0.3" d="M423.84 294.837H396.96V297.221H423.84V294.837Z" fill="url(#paint50_linear_1097_80129)" />
      <path opacity="0.3" d="M416.892 303.837H396.96V306.205H416.892V303.837Z" fill="url(#paint51_linear_1097_80129)" />
      <path d="M491.688 255.89V319.133L479.556 338.749H452.724V255.89H491.688Z" fill="url(#paint52_linear_1097_80129)" />
      <path opacity="0.7" d="M491.688 255.89V319.133L479.556 338.749H452.724V255.89H491.688Z" fill="white" />
      <path opacity="0.3" d="M491.688 319.136L479.556 338.751V319.136H491.688Z" fill="url(#paint53_linear_1097_80129)" />
      <path opacity="0.3" d="M485.64 267.844H458.76V270.211H485.64V267.844Z" fill="url(#paint54_linear_1097_80129)" />
      <path opacity="0.3" d="M485.64 276.832H458.76V279.199H485.64V276.832Z" fill="url(#paint55_linear_1097_80129)" />
      <path opacity="0.3" d="M485.64 285.832H458.76V288.215H485.64V285.832Z" fill="url(#paint56_linear_1097_80129)" />
      <path opacity="0.3" d="M485.64 294.837H458.76V297.221H485.64V294.837Z" fill="url(#paint57_linear_1097_80129)" />
      <path opacity="0.3" d="M478.692 303.837H458.76V306.205H478.692V303.837Z" fill="url(#paint58_linear_1097_80129)" />
      <path
        opacity="0.1"
        d="M395.952 203.484H333.756C330.873 203.484 328.536 206.621 328.536 210.49V273.04C328.536 276.909 330.873 280.046 333.756 280.046H395.952C398.835 280.046 401.172 276.909 401.172 273.04V210.49C401.172 206.621 398.835 203.484 395.952 203.484Z"
        fill="black"
      />
      <g opacity="0.1">
        <path
          d="M391.5 199.929H384.3V189.478C384.3 182.874 382.346 176.541 378.866 171.872C375.387 167.203 370.668 164.58 365.748 164.58C360.828 164.58 356.109 167.203 352.63 171.872C349.151 176.541 347.196 182.874 347.196 189.478V199.929H339.996V189.478C339.996 180.312 342.709 171.521 347.539 165.04C352.368 158.558 358.918 154.917 365.748 154.917C372.578 154.917 379.128 158.558 383.958 165.04C388.787 171.521 391.5 180.312 391.5 189.478V199.929Z"
          fill="black"
        />
      </g>
      <path
        d="M365.952 200.267H303.756C300.873 200.267 298.536 203.404 298.536 207.273V269.823C298.536 273.692 300.873 276.829 303.756 276.829H365.952C368.835 276.829 371.172 273.692 371.172 269.823V207.273C371.172 203.404 368.835 200.267 365.952 200.267Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M352.812 200.267H303.756C300.873 200.267 298.536 203.404 298.536 207.273V269.823C298.536 273.692 300.873 276.829 303.756 276.829H352.812C355.695 276.829 358.032 273.692 358.032 269.823V207.273C358.032 203.404 355.695 200.267 352.812 200.267Z"
        fill="white"
      />
      <path
        d="M331.933 228.999C331.933 223.717 329.533 219.433 326.581 219.433C323.629 219.433 321.241 223.717 321.241 228.999C321.169 230.74 321.441 232.476 322.029 234.031C322.617 235.586 323.5 236.906 324.589 237.857L323.593 257.827H329.593L328.609 237.857C329.691 236.899 330.568 235.577 331.151 234.023C331.734 232.469 332.004 230.737 331.933 228.999Z"
        fill="#263238"
      />
      <path
        d="M361.5 196.706H354.3V186.254C354.3 179.651 352.346 173.318 348.866 168.649C345.387 163.979 340.668 161.356 335.748 161.356C330.828 161.356 326.109 163.979 322.63 168.649C319.151 173.318 317.196 179.651 317.196 186.254V196.706H309.996V186.254C309.996 177.088 312.709 168.297 317.539 161.816C322.368 155.335 328.918 151.693 335.748 151.693C342.578 151.693 349.128 155.335 353.958 161.816C358.787 168.297 361.5 177.088 361.5 186.254V196.706Z"
        fill="#263238"
      />
      <g opacity="0.4">
        <path
          d="M361.5 196.707H354.3V186.255C354.3 179.652 352.346 173.319 348.866 168.649C345.387 163.98 340.668 161.357 335.748 161.357C330.828 161.357 326.109 163.98 322.63 168.649C319.151 173.319 317.196 179.652 317.196 186.255V196.707H309.996V186.255C309.996 177.089 312.709 168.298 317.539 161.817C322.368 155.336 328.918 151.694 335.748 151.694C342.578 151.694 349.128 155.336 353.958 161.817C358.787 168.298 361.5 177.089 361.5 186.255V196.707Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.2"
        d="M361.501 186.258V196.71H356.617V185.066C356.617 180.919 356.008 176.813 354.825 172.983C353.643 169.152 351.909 165.672 349.724 162.74C347.538 159.809 344.944 157.484 342.089 155.899C339.234 154.314 336.174 153.499 333.085 153.501C328.363 153.481 323.752 155.422 319.885 159.057C323.683 155.06 328.243 152.577 333.043 151.892C337.843 151.208 342.689 152.35 347.029 155.187C351.368 158.024 355.025 162.442 357.582 167.936C360.139 173.431 361.493 179.78 361.489 186.258H361.501Z"
        fill="white"
      />
      <path
        d="M259.752 371.424H230.664V369.926H237.228C237.67 369.921 238.096 369.709 238.433 369.326C238.77 368.944 238.998 368.416 239.076 367.833L242.016 344.352L242.592 339.73H252.552L261.468 367.946C261.589 368.326 261.638 368.741 261.611 369.153C261.583 369.565 261.479 369.961 261.309 370.306C261.139 370.651 260.908 370.934 260.636 371.129C260.364 371.324 260.06 371.425 259.752 371.424Z"
        fill="url(#paint59_linear_1097_80129)"
      />
      <path
        opacity="0.4"
        d="M259.752 371.424H230.664V369.926H237.228C237.67 369.921 238.096 369.709 238.433 369.326C238.77 368.944 238.998 368.416 239.076 367.833L242.016 344.352L242.592 339.73H252.552L261.468 367.946C261.589 368.326 261.638 368.741 261.611 369.153C261.583 369.565 261.479 369.961 261.309 370.306C261.139 370.651 260.908 370.934 260.636 371.129C260.364 371.324 260.06 371.425 259.752 371.424Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M259.788 369.927H237.228C237.67 369.922 238.096 369.709 238.433 369.327C238.77 368.945 238.998 368.417 239.076 367.834L242.016 344.353H252.456L260.4 368.687C260.444 368.823 260.462 368.971 260.453 369.118C260.443 369.266 260.406 369.407 260.345 369.531C260.285 369.654 260.202 369.755 260.104 369.824C260.007 369.893 259.898 369.929 259.788 369.927Z"
        fill="black"
      />
      <path
        d="M275.389 348.377H217.201C217.068 348.384 216.936 348.35 216.815 348.278C216.695 348.205 216.588 348.096 216.504 347.958C216.42 347.821 216.361 347.659 216.332 347.485C216.303 347.312 216.305 347.132 216.337 346.959L224.616 288.274C224.677 287.896 224.826 287.555 225.042 287.3C225.258 287.045 225.53 286.889 225.817 286.857H283.993C284.125 286.848 284.256 286.882 284.377 286.954C284.498 287.027 284.604 287.137 284.687 287.275C284.77 287.413 284.827 287.575 284.855 287.749C284.882 287.923 284.879 288.103 284.844 288.274L276.589 346.943C276.53 347.324 276.382 347.669 276.166 347.927C275.949 348.185 275.677 348.343 275.389 348.377Z"
        fill="url(#paint60_linear_1097_80129)"
      />
      <path
        opacity="0.4"
        d="M275.389 348.377H217.201C217.068 348.384 216.936 348.35 216.815 348.278C216.695 348.205 216.588 348.096 216.504 347.958C216.42 347.821 216.361 347.659 216.332 347.485C216.303 347.312 216.305 347.132 216.337 346.959L224.616 288.274C224.677 287.896 224.826 287.555 225.042 287.3C225.258 287.045 225.53 286.889 225.817 286.857H283.993C284.125 286.848 284.256 286.882 284.377 286.954C284.498 287.027 284.604 287.137 284.687 287.275C284.77 287.413 284.827 287.575 284.855 287.749C284.882 287.923 284.879 288.103 284.844 288.274L276.589 346.943C276.53 347.324 276.382 347.669 276.166 347.927C275.949 348.185 275.677 348.343 275.389 348.377Z"
        fill="white"
      />
      <path
        d="M274.188 348.377H216C215.868 348.384 215.736 348.35 215.615 348.278C215.494 348.205 215.388 348.096 215.304 347.958C215.22 347.821 215.161 347.659 215.132 347.485C215.103 347.312 215.104 347.132 215.136 346.959L223.416 288.274C223.477 287.896 223.626 287.555 223.842 287.3C224.058 287.045 224.33 286.889 224.616 286.857H282.792C282.924 286.848 283.056 286.882 283.177 286.954C283.297 287.027 283.404 287.137 283.487 287.275C283.57 287.413 283.627 287.575 283.655 287.749C283.682 287.923 283.678 288.103 283.644 288.274L275.388 346.943C275.33 347.324 275.182 347.669 274.965 347.927C274.749 348.185 274.477 348.343 274.188 348.377Z"
        fill="#263238"
      />
      <path d="M273.42 344.771H217.788L225.444 290.482H281.064L273.42 344.771Z" fill="url(#paint61_linear_1097_80129)" />
      <path
        d="M176.46 371.421V369.585L198.852 363.513H242.664C243.444 363.513 244.191 363.929 244.743 364.669C245.294 365.409 245.604 366.412 245.604 367.459C245.604 368.507 245.295 369.512 244.744 370.254C244.193 370.997 243.445 371.416 242.664 371.421H176.46Z"
        fill="url(#paint62_linear_1097_80129)"
      />
      <path
        opacity="0.4"
        d="M245.604 367.475C245.606 368.518 245.299 369.52 244.752 370.261C244.197 371.001 243.446 371.418 242.664 371.421H176.46V369.601L198.864 363.513H242.664C243.051 363.513 243.434 363.616 243.792 363.815C244.149 364.014 244.474 364.306 244.747 364.675C245.02 365.043 245.237 365.479 245.384 365.96C245.531 366.441 245.606 366.955 245.604 367.475Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M245.604 367.475C245.606 368.518 245.299 369.52 244.752 370.261C244.197 371.001 243.447 371.418 242.664 371.421H216.06V369.601L238.464 363.513H242.664C243.051 363.513 243.434 363.616 243.792 363.815C244.149 364.014 244.474 364.306 244.747 364.675C245.02 365.043 245.237 365.479 245.384 365.96C245.531 366.441 245.606 366.955 245.604 367.475Z"
        fill="white"
      />
      <path d="M261.612 582.056H267.612L278.376 380.845H270.42L261.612 582.056Z" fill="#263238" />
      <path opacity="0.1" d="M261.612 582.057H267.612L277.848 390.83L278.376 381.167H270.42L270 390.83L261.612 582.057Z" fill="white" />
      <path opacity="0.2" d="M270 390.347H277.848L278.376 381.167H270.42L270 390.347Z" fill="black" />
      <path d="M217.632 582.056H223.62L224.604 380.845H216.648L217.632 582.056Z" fill="#263238" />
      <path opacity="0.1" d="M216.648 381.167L216.804 413.634L217.62 582.057H223.62L224.436 413.634L224.592 381.167H216.648Z" fill="white" />
      <path opacity="0.2" d="M216.648 381.167L216.804 413.988H224.436L224.592 381.167H216.648Z" fill="black" />
      <path d="M153.996 582.056H159.984L170.76 380.845H162.804L153.996 582.056Z" fill="#263238" />
      <path opacity="0.1" d="M153.984 582.057H159.984L170.232 390.83L170.76 381.167H162.804L162.372 390.83L153.984 582.057Z" fill="white" />
      <path opacity="0.2" d="M162.372 390.347H170.232L170.76 381.167H162.804L162.372 390.347Z" fill="black" />
      <path d="M330.18 582.056H336.168L327.36 380.845H319.416L330.18 582.056Z" fill="#263238" />
      <path opacity="0.1" d="M319.416 381.167L319.944 390.83L330.18 582.057H336.168L327.792 390.83L327.36 381.167H319.416Z" fill="white" />
      <path opacity="0.2" d="M327.36 381.167H319.416L319.944 390.347H327.792L327.36 381.167Z" fill="black" />
      <path
        d="M331.068 385.872L159.096 385.872C158.756 385.872 158.419 385.782 158.104 385.608C157.79 385.433 157.504 385.177 157.263 384.853C157.023 384.53 156.832 384.147 156.701 383.725C156.571 383.303 156.504 382.851 156.504 382.394V375.791H333.66V382.394C333.66 382.851 333.593 383.303 333.463 383.725C333.333 384.147 333.142 384.53 332.901 384.853C332.66 385.177 332.375 385.433 332.06 385.608C331.746 385.782 331.409 385.872 331.068 385.872Z"
        fill="url(#paint63_linear_1097_80129)"
      />
      <path
        opacity="0.4"
        d="M331.068 385.872L159.096 385.872C158.756 385.872 158.419 385.782 158.104 385.608C157.79 385.433 157.504 385.177 157.263 384.853C157.023 384.53 156.832 384.147 156.701 383.725C156.571 383.303 156.504 382.851 156.504 382.394V375.791H333.66V382.394C333.66 382.851 333.593 383.303 333.463 383.725C333.333 384.147 333.142 384.53 332.901 384.853C332.66 385.177 332.375 385.433 332.06 385.608C331.746 385.782 331.409 385.872 331.068 385.872Z"
        fill="white"
      />
      <path opacity="0.2" d="M156.492 380.827L333.66 380.827V375.787L156.492 375.787V380.827Z" fill="black" />
      <path
        d="M152.784 377.104H337.38C337.94 377.104 338.477 376.805 338.873 376.274C339.269 375.742 339.492 375.021 339.492 374.269C339.492 373.518 339.269 372.797 338.873 372.265C338.477 371.734 337.94 371.435 337.38 371.435H152.784C152.224 371.435 151.687 371.734 151.29 372.265C150.894 372.797 150.672 373.518 150.672 374.269C150.672 375.021 150.894 375.742 151.29 376.274C151.687 376.805 152.224 377.104 152.784 377.104Z"
        fill="url(#paint64_linear_1097_80129)"
      />
      <path
        opacity="0.4"
        d="M152.784 377.104H337.38C337.94 377.104 338.477 376.805 338.873 376.274C339.269 375.742 339.492 375.021 339.492 374.269C339.492 373.518 339.269 372.797 338.873 372.265C338.477 371.734 337.94 371.435 337.38 371.435H152.784C152.224 371.435 151.687 371.734 151.29 372.265C150.894 372.797 150.672 373.518 150.672 374.269C150.672 375.021 150.894 375.742 151.29 376.274C151.687 376.805 152.224 377.104 152.784 377.104Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M228.528 320.904C228.264 320.901 228.005 320.82 227.767 320.668C227.529 320.515 227.32 320.294 227.152 320.021C226.985 319.747 226.865 319.428 226.8 319.085C226.735 318.743 226.727 318.385 226.776 318.038L227.52 312.9C227.625 312.199 227.906 311.569 228.317 311.114C228.728 310.659 229.243 310.409 229.776 310.404H260.976C261.241 310.403 261.502 310.482 261.741 310.636C261.98 310.789 262.19 311.013 262.356 311.29C262.526 311.56 262.649 311.879 262.713 312.222C262.778 312.566 262.783 312.924 262.728 313.271L261.984 318.408C261.884 319.109 261.605 319.741 261.196 320.197C260.786 320.652 260.272 320.902 259.74 320.904H228.528Z"
        fill="white"
      />
      <path
        d="M259.74 320.097H228.54C228.362 320.101 228.186 320.052 228.025 319.953C227.863 319.855 227.72 319.71 227.605 319.528C227.49 319.346 227.407 319.132 227.361 318.902C227.315 318.672 227.308 318.431 227.34 318.196L228.096 313.043C228.169 312.526 228.374 312.059 228.677 311.723C228.979 311.388 229.36 311.205 229.752 311.207H260.952C261.129 311.203 261.305 311.252 261.467 311.351C261.629 311.449 261.772 311.594 261.887 311.776C262.002 311.958 262.085 312.172 262.131 312.402C262.177 312.632 262.184 312.873 262.152 313.107L261.408 318.261C261.333 318.78 261.125 319.247 260.82 319.582C260.516 319.917 260.134 320.099 259.74 320.097Z"
        fill="white"
      />
      <path
        d="M233.817 316.847C234.429 315.674 234.361 314.194 233.666 313.541C232.971 312.889 231.911 313.311 231.3 314.484C230.688 315.658 230.756 317.138 231.451 317.791C232.146 318.443 233.206 318.021 233.817 316.847Z"
        fill="#263238"
      />
      <path
        d="M239.076 315.648C238.991 316.292 238.749 316.879 238.389 317.319C238.028 317.759 237.57 318.026 237.084 318.08C236.869 318.071 236.659 317.996 236.469 317.859C236.279 317.722 236.116 317.529 235.992 317.293C235.867 317.057 235.785 316.786 235.752 316.501C235.719 316.215 235.735 315.924 235.8 315.648C235.888 315.006 236.13 314.42 236.49 313.981C236.85 313.541 237.308 313.273 237.792 313.217C238.008 313.222 238.22 313.296 238.411 313.431C238.602 313.567 238.767 313.761 238.892 313.997C239.017 314.234 239.099 314.507 239.131 314.793C239.163 315.08 239.144 315.373 239.076 315.648Z"
        fill="#263238"
      />
      <path
        d="M243.96 315.648C243.873 316.291 243.63 316.877 243.271 317.316C242.911 317.755 242.453 318.024 241.968 318.08C241.752 318.074 241.54 318 241.349 317.864C241.158 317.729 240.993 317.535 240.867 317.299C240.741 317.063 240.658 316.791 240.625 316.505C240.591 316.218 240.607 315.925 240.672 315.648C240.762 315.005 241.007 314.418 241.369 313.979C241.731 313.54 242.19 313.272 242.676 313.217C242.892 313.225 243.102 313.301 243.292 313.438C243.481 313.574 243.645 313.768 243.769 314.004C243.894 314.239 243.976 314.51 244.009 314.796C244.042 315.081 244.025 315.373 243.96 315.648Z"
        fill="#263238"
      />
      <path
        d="M248.46 316.836C249.071 315.662 249.003 314.182 248.308 313.529C247.613 312.877 246.554 313.299 245.942 314.473C245.331 315.646 245.398 317.126 246.094 317.779C246.789 318.431 247.848 318.009 248.46 316.836Z"
        fill="#263238"
      />
      <path
        d="M253.716 315.648C253.626 316.292 253.382 316.878 253.02 317.317C252.658 317.757 252.199 318.024 251.712 318.08C251.497 318.071 251.287 317.996 251.097 317.859C250.907 317.722 250.744 317.529 250.62 317.293C250.495 317.057 250.413 316.786 250.38 316.501C250.347 316.215 250.363 315.924 250.428 315.648C250.518 315.007 250.761 314.423 251.12 313.984C251.48 313.545 251.936 313.276 252.42 313.217C252.636 313.223 252.849 313.297 253.04 313.432C253.231 313.568 253.396 313.761 253.522 313.997C253.647 314.233 253.73 314.505 253.764 314.792C253.798 315.079 253.782 315.372 253.716 315.648Z"
        fill="#263238"
      />
      <path
        d="M258.209 316.865C258.82 315.692 258.752 314.211 258.057 313.559C257.362 312.906 256.303 313.329 255.691 314.502C255.08 315.675 255.148 317.156 255.843 317.808C256.538 318.461 257.597 318.038 258.209 316.865Z"
        fill="#263238"
      />
      <path
        opacity="0.3"
        d="M264.096 320.9C263.894 320.903 263.695 320.846 263.512 320.731C263.329 320.617 263.168 320.449 263.04 320.24C262.903 320.019 262.807 319.758 262.759 319.478C262.711 319.199 262.713 318.908 262.764 318.629L263.712 312.187C263.796 311.66 264.009 311.185 264.318 310.84C264.627 310.495 265.014 310.301 265.416 310.287H270.216C270.418 310.284 270.617 310.341 270.8 310.456C270.983 310.57 271.144 310.738 271.272 310.947C271.409 311.168 271.505 311.429 271.553 311.708C271.601 311.988 271.599 312.279 271.548 312.558L270.6 319C270.517 319.527 270.303 320.002 269.994 320.347C269.685 320.691 269.298 320.886 268.896 320.9H264.096Z"
        fill="white"
      />
      <path
        d="M264.096 320.094H268.896C269.154 320.076 269.4 319.948 269.599 319.727C269.798 319.507 269.939 319.206 270 318.87L270.948 312.428C270.978 312.278 270.982 312.12 270.958 311.967C270.934 311.814 270.884 311.671 270.812 311.549C270.74 311.427 270.647 311.329 270.541 311.264C270.436 311.198 270.32 311.167 270.204 311.172H265.404C265.141 311.188 264.89 311.32 264.688 311.547C264.486 311.774 264.346 312.084 264.288 312.428L263.34 318.87C263.314 319.02 263.314 319.176 263.341 319.326C263.367 319.476 263.419 319.615 263.492 319.734C263.565 319.852 263.658 319.946 263.763 320.009C263.867 320.072 263.982 320.101 264.096 320.094Z"
        fill="#263238"
      />
      <path
        d="M266.532 318.469C266.496 318.471 266.46 318.461 266.426 318.442C266.393 318.422 266.364 318.393 266.34 318.356C266.297 318.263 266.282 318.151 266.297 318.044C266.313 317.936 266.358 317.841 266.424 317.776L268.332 315.65L267.06 313.524C267.014 313.433 266.997 313.32 267.013 313.211C267.029 313.103 267.076 313.007 267.144 312.945C267.201 312.875 267.276 312.836 267.354 312.836C267.432 312.836 267.507 312.875 267.564 312.945L269.004 315.36C269.05 315.452 269.067 315.565 269.051 315.674C269.035 315.782 268.988 315.878 268.92 315.94L266.772 318.356C266.704 318.43 266.619 318.47 266.532 318.469Z"
        fill="url(#paint65_linear_1097_80129)"
      />
      <path
        d="M268.692 316.055H265.452C265.415 316.057 265.378 316.047 265.344 316.026C265.31 316.005 265.28 315.973 265.257 315.934C265.234 315.895 265.218 315.85 265.21 315.801C265.202 315.752 265.203 315.701 265.212 315.653C265.231 315.542 265.276 315.442 265.341 315.369C265.406 315.296 265.488 315.254 265.572 315.25H268.8C268.838 315.248 268.875 315.258 268.908 315.279C268.942 315.3 268.972 315.332 268.995 315.371C269.019 315.41 269.035 315.456 269.043 315.504C269.05 315.553 269.05 315.604 269.04 315.653C269.022 315.761 268.979 315.859 268.916 315.931C268.853 316.004 268.775 316.047 268.692 316.055Z"
        fill="url(#paint66_linear_1097_80129)"
      />
      <path
        d="M163.524 217.734H189.396C192.126 217.734 194.34 214.763 194.34 211.099V176.377C194.34 172.713 192.126 169.742 189.396 169.742H163.524C160.793 169.742 158.58 172.713 158.58 176.377V211.099C158.58 214.763 160.793 217.734 163.524 217.734Z"
        fill="url(#paint67_linear_1097_80129)"
      />
      <path d="M173.664 215.929L176.46 232.066L179.256 215.929H173.664Z" fill="url(#paint68_linear_1097_80129)" />
      <path
        opacity="0.3"
        d="M176.148 208.115C173.104 205.68 170.564 202.262 168.757 198.172C166.95 194.082 165.935 189.45 165.804 184.698V184.183L176.448 178.095L187.08 184.183V184.698C186.956 189.45 185.946 194.085 184.14 198.176C182.335 202.267 179.793 205.685 176.748 208.115L176.436 208.372L176.148 208.115Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M176.459 178.986L186.503 184.719C186.394 189.337 185.415 193.842 183.659 197.815C181.903 201.788 179.426 205.1 176.459 207.443V178.986Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M176.46 178.986L166.404 184.719C166.515 189.338 167.495 193.844 169.253 197.817C171.012 201.791 173.491 205.102 176.46 207.443V178.986Z"
        fill="white"
      />
      <path
        d="M166.8 360.921L175.2 362.612C177.107 361.798 179.068 361.232 181.056 360.921C183.212 360.683 185.379 360.683 187.536 360.921C187.536 360.921 191.34 362.837 191.34 367.717C191.34 367.717 182.472 370.938 179.076 368.989L166.8 367.717V360.921Z"
        fill="#E4897B"
      />
      <path
        opacity="0.2"
        d="M166.8 360.921L175.2 362.612C177.107 361.798 179.068 361.232 181.056 360.921C183.212 360.683 185.379 360.683 187.536 360.921C187.536 360.921 191.34 362.837 191.34 367.717C191.34 367.717 182.472 370.938 179.076 368.989L166.8 367.717V360.921Z"
        fill="black"
      />
      <path
        d="M187.608 284.793C187.739 287.11 188.077 289.398 188.616 291.606C188.176 291.969 187.669 292.161 187.152 292.161C186.635 292.161 186.128 291.969 185.688 291.606L187.608 284.793Z"
        fill="#AA473D"
      />
      <path
        d="M190.884 281.572C190.56 282.618 189.984 283.295 189.588 283.069C189.192 282.844 189.156 281.845 189.468 280.799C189.78 279.752 190.368 279.092 190.764 279.301C191.16 279.51 191.196 280.541 190.884 281.572Z"
        fill="#263238"
      />
      <path
        d="M192.852 277.96C192.782 277.989 192.707 277.989 192.636 277.96C191.998 277.457 191.284 277.152 190.547 277.069C189.81 276.985 189.068 277.124 188.376 277.477C188.273 277.547 188.153 277.56 188.042 277.511C187.932 277.463 187.841 277.358 187.788 277.219C187.736 277.08 187.726 276.919 187.762 276.771C187.798 276.623 187.877 276.501 187.98 276.43C188.802 275.981 189.692 275.795 190.577 275.887C191.462 275.979 192.319 276.347 193.08 276.962C193.129 277.001 193.172 277.054 193.206 277.116C193.24 277.178 193.265 277.249 193.278 277.324C193.292 277.399 193.294 277.476 193.284 277.552C193.275 277.629 193.255 277.701 193.224 277.767C193.18 277.839 193.122 277.895 193.057 277.929C192.992 277.962 192.921 277.973 192.852 277.96Z"
        fill="#263238"
      />
      <path
        d="M185.268 293.553C184.992 294.004 184.716 294.439 184.416 294.842C182.736 297.185 180.563 298.786 178.188 299.431C177.586 299.595 176.977 299.703 176.364 299.754C174.75 299.889 173.134 299.54 171.633 298.732C170.133 297.924 168.785 296.678 167.688 295.083C166.698 293.641 165.939 291.942 165.456 290.091C163.308 282.361 165.456 276.273 168.564 265.563C169.255 263.053 170.412 260.821 171.926 259.078C173.44 257.336 175.26 256.142 177.214 255.608C179.168 255.074 181.192 255.218 183.092 256.028C184.993 256.837 186.709 258.285 188.076 260.233C194.292 269.026 190.116 285.179 185.268 293.553Z"
        fill="#E4897B"
      />
      <path
        opacity="0.2"
        d="M178.188 299.431C177.586 299.594 176.977 299.702 176.364 299.753C174.75 299.888 173.134 299.539 171.633 298.731C170.133 297.924 168.785 296.677 167.688 295.083C168.459 294.042 169.326 293.138 170.268 292.393C173.357 293.668 176.107 296.111 178.188 299.431Z"
        fill="black"
      />
      <path
        d="M152.316 299.836C153.108 307.373 165.396 310.191 165.396 310.191C165.396 310.191 171.396 313.895 171.708 310.674C169.2 306.777 168.924 303.379 169.788 300.4C170.362 298.582 171.202 296.939 172.26 295.568C172.824 294.782 173.421 294.04 174.048 293.346L170.292 272.7C167.592 280.591 158.4 298.709 152.316 299.836Z"
        fill="#E4897B"
      />
      <path
        d="M187.38 254.451C187.38 254.451 190.008 254.016 192.024 260.587C193.716 266.111 189.72 267.318 189.9 273.197C190.08 279.075 186.06 282.344 186.06 282.344C186.06 282.344 182.7 286.241 179.832 284.132C179.832 284.132 176.088 286.596 175.104 290.896C174.12 295.196 168.324 287.433 163.104 288.609C163.104 288.609 159.576 284.18 159.84 273.422C160.04 268.751 160.895 264.172 162.36 259.926C163.052 257.678 164.071 255.638 165.356 253.924C166.642 252.209 168.169 250.856 169.848 249.941C175.812 246.302 186.264 249.007 187.38 254.451Z"
        fill="#263238"
      />
      <path
        d="M179.112 280.893C178.931 281.965 178.929 283.077 179.108 284.15C179.286 285.222 179.64 286.228 180.144 287.094C181.416 288.962 183.468 286.594 184.296 283.325C185.016 280.41 185.268 275.853 183.396 275.273C181.524 274.693 179.508 277.753 179.112 280.893Z"
        fill="#E4897B"
      />
      <path
        d="M180.432 306.07C178.712 312.512 177.176 318.686 175.824 324.591C174.96 328.262 174.168 331.854 173.424 335.316C172.816 338.226 172.248 341.05 171.72 343.787C171.468 345.108 171.216 346.412 170.976 347.701C169.656 354.803 168.576 361.277 167.748 367.026C166.35 376.001 165.312 385.07 164.64 394.195L117.6 386.239C119.232 375.385 120.504 365.899 122.28 355.64C123.264 350.036 124.38 344.206 125.88 337.812C126.888 333.223 128.064 328.343 129.48 323.045C134.208 304.846 138.564 299.806 138.564 299.806C156.552 295.94 180.432 306.07 180.432 306.07Z"
        fill="#263238"
      />
      <path
        d="M196.092 362.092L204.492 363.783C206.399 362.97 208.36 362.404 210.348 362.092C212.508 361.855 214.679 361.855 216.84 362.092C216.84 362.092 220.632 364.009 220.632 368.889C220.632 368.889 211.776 372.11 208.368 370.161L196.104 368.889L196.092 362.092Z"
        fill="#E4897B"
      />
      <path d="M144.096 570.28L152.868 570.489L157.236 543.353L148.476 543.143L144.096 570.28Z" fill="#EBB376" />
      <path opacity="0.2" d="M148.476 543.143L157.236 543.369L154.74 558.878L146.064 558.104L148.476 543.143Z" fill="black" />
      <path
        d="M172.284 503.827C168.941 521.097 164.67 538.019 159.504 554.46L143.508 552.849C143.508 552.849 150.072 518.434 152.448 486.901C153.924 467.446 153.792 451.342 149.544 450.102C146.88 449.313 140.628 449.329 132.612 449.586V409.486C155.088 415.686 177.456 427.362 179.16 436.735C181.536 449.925 177.384 477.85 172.284 503.827Z"
        fill="url(#paint69_linear_1097_80129)"
      />
      <path d="M141.804 553.822L160.86 555.529L163.668 544.465L142.668 543.161L141.804 553.822Z" fill="url(#paint70_linear_1097_80129)" />
      <path opacity="0.2" d="M141.804 553.822L160.86 555.529L163.668 544.465L142.668 543.161L141.804 553.822Z" fill="white" />
      <path
        d="M154.188 569.908H154.368C154.368 569.908 154.572 569.988 154.596 570.021H154.668C155.409 570.336 156.174 570.541 156.948 570.633C157.525 570.777 158.121 570.621 158.616 570.198C158.773 570.014 158.887 569.774 158.945 569.505C159.002 569.237 159.001 568.951 158.94 568.684C158.921 568.509 158.871 568.343 158.793 568.2C158.716 568.057 158.614 567.941 158.496 567.863C157.668 567.685 156.819 567.901 156.096 568.475C156.613 567.744 156.919 566.791 156.96 565.785C156.925 565.547 156.834 565.331 156.7 565.169C156.566 565.006 156.396 564.906 156.216 564.883C155.875 564.816 155.527 564.925 155.244 565.189C154.32 566.252 154.176 569.586 154.176 569.779L154.188 569.908ZM155.136 569.232L154.956 569.36C154.899 568.088 155.157 566.826 155.688 565.769C155.746 565.691 155.816 565.631 155.892 565.592C155.969 565.553 156.051 565.537 156.132 565.544H156.24C156.528 565.544 156.588 565.801 156.6 565.962C156.415 567.25 155.896 568.408 155.136 569.232ZM155.256 569.811C156.312 569.022 157.812 568.201 158.376 568.507C158.432 568.546 158.48 568.604 158.516 568.674C158.552 568.744 158.574 568.825 158.58 568.909C158.616 569.079 158.617 569.258 158.583 569.428C158.549 569.598 158.482 569.752 158.388 569.876C157.956 570.391 156.804 570.359 155.256 569.811Z"
        fill="url(#paint71_linear_1097_80129)"
      />
      <path
        d="M153.984 568.49L144.156 567.669C143.992 567.646 143.828 567.712 143.7 567.85C143.572 567.989 143.49 568.19 143.472 568.41L142.512 578.797C142.486 579.343 142.621 579.881 142.888 580.294C143.155 580.707 143.533 580.962 143.94 581.004C147.372 581.213 149.016 581.084 153.336 581.406C157.171 581.936 161.028 582.097 164.88 581.889C168.48 581.422 168.084 576.575 166.512 576.349C162.756 575.77 157.8 572.082 155.448 569.279C155.04 568.802 154.524 568.524 153.984 568.49Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M172.284 503.827C166.284 498.754 159.312 492.779 152.448 486.869C153.924 467.414 153.792 451.31 149.544 450.07C146.88 449.28 140.628 449.297 132.612 449.554V409.486C155.088 415.686 177.456 427.362 179.16 436.735C181.536 449.925 177.384 477.85 172.284 503.827Z"
        fill="black"
      />
      <path d="M195.372 570.28L204.144 570.489L208.512 543.353L199.752 543.143L195.372 570.28Z" fill="#EBB376" />
      <path opacity="0.2" d="M199.752 543.143L208.512 543.369L206.016 558.878L197.34 558.104L199.752 543.143Z" fill="black" />
      <path
        d="M163.524 405.563L164.94 390.36L119.136 380.23C119.136 380.23 104.148 420.073 111.48 439.656C118.212 457.565 189.804 446.887 200.82 450.124C211.836 453.361 194.82 552.888 194.82 552.888L210.828 554.499C210.828 554.499 235.548 464.957 230.472 436.79C228.216 424.47 190.476 408.188 163.524 405.563Z"
        fill="url(#paint72_linear_1097_80129)"
      />
      <path d="M193.08 553.822L212.136 555.529L214.944 544.465L193.944 543.161L193.08 553.822Z" fill="url(#paint73_linear_1097_80129)" />
      <path opacity="0.2" d="M193.08 553.822L212.136 555.529L214.944 544.465L193.944 543.161L193.08 553.822Z" fill="white" />
      <path
        d="M205.464 569.908H205.644C205.644 569.908 205.848 569.988 205.872 570.021H205.944C206.685 570.336 207.45 570.541 208.224 570.633C208.801 570.777 209.397 570.621 209.892 570.198C210.049 570.014 210.163 569.774 210.221 569.505C210.278 569.237 210.277 568.951 210.216 568.684C210.197 568.509 210.147 568.343 210.069 568.2C209.992 568.057 209.89 567.941 209.772 567.863C208.943 567.685 208.095 567.901 207.372 568.475C207.888 567.744 208.195 566.791 208.236 565.785C208.201 565.547 208.109 565.331 207.975 565.169C207.841 565.006 207.672 564.906 207.492 564.883C207.151 564.816 206.803 564.925 206.52 565.189C205.596 566.252 205.452 569.586 205.452 569.779L205.464 569.908ZM206.412 569.232L206.232 569.36C206.175 568.088 206.433 566.826 206.964 565.769C207.022 565.691 207.091 565.631 207.168 565.592C207.245 565.553 207.326 565.537 207.408 565.544H207.516C207.804 565.544 207.864 565.801 207.876 565.962C207.693 567.255 207.169 568.415 206.4 569.232H206.412ZM206.532 569.811C207.588 569.022 209.088 568.201 209.652 568.507C209.708 568.546 209.756 568.604 209.792 568.674C209.827 568.744 209.85 568.825 209.856 568.909C209.892 569.079 209.892 569.258 209.859 569.428C209.825 569.598 209.758 569.752 209.664 569.876C209.232 570.391 208.08 570.359 206.532 569.811Z"
        fill="url(#paint74_linear_1097_80129)"
      />
      <path
        d="M205.26 568.49L195.432 567.669C195.269 567.646 195.105 567.712 194.977 567.85C194.849 567.989 194.767 568.19 194.748 568.41L193.788 578.797C193.762 579.343 193.897 579.881 194.164 580.294C194.432 580.707 194.81 580.962 195.216 581.004C198.648 581.213 200.292 581.084 204.612 581.406C208.447 581.936 212.305 582.097 216.156 581.889C219.816 581.422 219.372 576.575 217.788 576.349C214.032 575.77 209.076 572.082 206.724 569.279C206.317 568.802 205.801 568.524 205.26 568.49Z"
        fill="#263238"
      />
      <path d="M117.456 387.155L138.672 390.891L139.368 386.688L118.104 382.533L117.456 387.155Z" fill="black" />
      <path d="M141.132 386.717L140.868 390.888L160.488 394.511V390.372L141.132 386.717Z" fill="black" />
      <path d="M161.7 390.613V394.51L164.556 395.01V391.241L161.7 390.613Z" fill="black" />
      <path
        opacity="0.2"
        d="M175.824 324.585C174.96 328.257 174.168 331.848 173.424 335.31C172.816 338.22 172.248 341.044 171.72 343.781C170.244 332.991 172.92 320.108 172.92 320.108L175.824 324.585Z"
        fill="black"
      />
      <path
        d="M180.432 305.987C184.26 307.033 185.52 346.957 185.688 351.208C185.856 355.46 204.48 363.722 204.48 363.722V369.535C204.48 369.535 184.512 367.667 179.844 365.107C175.176 362.546 174 325.57 174 325.57L180.432 305.987Z"
        fill="#E4897B"
      />
      <path
        d="M180.432 305.987C183.948 307.452 185.688 333.783 185.688 333.783L174.816 339.806L172.116 325.167C172.116 325.167 171.348 322.188 172.86 315.907C175.056 306.743 180.432 305.987 180.432 305.987Z"
        fill="#263238"
      />
      <path
        d="M99.3357 437.271H186C188.517 437.271 190.932 438.613 192.712 441.002C194.492 443.391 195.492 446.631 195.492 450.01V456.822H89.8438V450.01C89.8438 446.631 90.8438 443.391 92.6239 441.002C94.404 438.613 96.8183 437.271 99.3357 437.271Z"
        fill="url(#paint75_linear_1097_80129)"
      />
      <path
        d="M127.524 388.843H185.712C186.413 388.843 187.106 389.055 187.737 389.464C188.369 389.874 188.923 390.469 189.359 391.206C189.796 391.943 190.102 392.803 190.256 393.721C190.41 394.639 190.407 395.592 190.248 396.509L179.844 456.821H177.444L186.3 403.965C186.455 403.052 186.455 402.103 186.299 401.19C186.143 400.276 185.836 399.422 185.4 398.69C184.964 397.958 184.411 397.367 183.781 396.961C183.152 396.556 182.462 396.346 181.764 396.348H128.712L127.524 388.843Z"
        fill="#263238"
      />
      <path
        d="M149.556 458.979V509.951C149.556 510.737 149.323 511.49 148.909 512.046C148.495 512.602 147.934 512.914 147.348 512.914H138C137.414 512.914 136.853 512.602 136.439 512.046C136.025 511.49 135.792 510.737 135.792 509.951V458.979H149.556Z"
        fill="#263238"
      />
      <path
        d="M98.148 576.644C98.148 575.574 97.9115 574.527 97.4685 573.638C97.0254 572.748 96.3957 572.054 95.659 571.645C94.9222 571.235 94.1115 571.128 93.3294 571.337C92.5473 571.545 91.8288 572.061 91.2649 572.818C90.701 573.574 90.317 574.539 90.1615 575.588C90.0059 576.638 90.0857 577.726 90.3909 578.715C90.6961 579.703 91.2129 580.548 91.8759 581.143C92.539 581.738 93.3185 582.055 94.116 582.055C95.1853 582.055 96.2109 581.485 96.967 580.47C97.7232 579.455 98.148 578.079 98.148 576.644Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M96.1316 576.639C96.1316 576.104 96.0134 575.581 95.7919 575.136C95.5703 574.691 95.2555 574.345 94.8871 574.14C94.5187 573.935 94.1134 573.881 93.7223 573.986C93.3312 574.09 92.972 574.348 92.6901 574.726C92.4081 575.105 92.2161 575.587 92.1383 576.112C92.0606 576.636 92.1005 577.18 92.2531 577.675C92.4057 578.169 92.6641 578.592 92.9956 578.889C93.3271 579.186 93.7169 579.345 94.1156 579.345C94.6493 579.341 95.1603 579.054 95.5377 578.548C95.9151 578.041 96.1285 577.356 96.1316 576.639Z"
        fill="white"
      />
      <path
        d="M195.288 576.641C195.291 575.566 195.055 574.515 194.612 573.62C194.168 572.725 193.537 572.026 192.797 571.614C192.058 571.201 191.244 571.092 190.458 571.301C189.673 571.51 188.951 572.027 188.385 572.787C187.818 573.547 187.433 574.516 187.277 575.57C187.122 576.624 187.203 577.717 187.51 578.709C187.818 579.701 188.338 580.549 189.005 581.144C189.672 581.739 190.455 582.055 191.256 582.052C192.325 582.048 193.348 581.476 194.104 580.462C194.859 579.449 195.285 578.075 195.288 576.641Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M193.272 576.639C193.272 576.104 193.154 575.581 192.932 575.136C192.711 574.691 192.396 574.345 192.028 574.14C191.659 573.935 191.254 573.881 190.863 573.986C190.472 574.09 190.113 574.348 189.831 574.726C189.549 575.105 189.357 575.587 189.279 576.112C189.201 576.636 189.241 577.18 189.394 577.675C189.546 578.169 189.805 578.592 190.136 578.889C190.468 579.186 190.858 579.345 191.256 579.345C191.79 579.341 192.301 579.054 192.678 578.548C193.056 578.041 193.269 577.356 193.272 576.639Z"
        fill="white"
      />
      <path
        d="M146.016 552.871V494.604H139.344V552.871C113.352 553.773 97.5482 565.964 94.3922 568.637C94.2058 568.785 94.0528 568.998 93.9493 569.253C93.8458 569.508 93.7956 569.796 93.8042 570.087V574.918C93.8026 575.144 93.8343 575.367 93.8975 575.576C93.9607 575.785 94.0542 575.975 94.1724 576.136C94.2907 576.296 94.4315 576.423 94.5866 576.51C94.7417 576.597 94.9081 576.641 95.0762 576.641C95.3008 576.639 95.5209 576.557 95.7147 576.404C95.9084 576.252 96.0689 576.034 96.1802 575.772L97.7282 572.132C111.195 566.369 125.212 563.255 139.344 562.888V572.551C139.344 572.842 139.387 573.13 139.47 573.399C139.554 573.668 139.676 573.911 139.83 574.117C139.984 574.322 140.166 574.484 140.367 574.594C140.568 574.704 140.783 574.759 141 574.757H144.372C144.808 574.757 145.226 574.525 145.535 574.111C145.843 573.697 146.016 573.136 146.016 572.551V562.888C160.152 563.254 174.174 566.368 187.644 572.132L189.18 575.772C189.292 576.036 189.455 576.256 189.651 576.408C189.847 576.561 190.069 576.641 190.296 576.641C190.635 576.637 190.958 576.454 191.196 576.131C191.435 575.808 191.568 575.372 191.568 574.918V570.087C191.577 569.796 191.527 569.508 191.423 569.253C191.319 568.998 191.166 568.785 190.98 568.637C187.812 565.964 172.02 553.773 146.016 552.871Z"
        fill="#263238"
      />
      <path d="M144.876 571.215H140.496V582.054H144.876V571.215Z" fill="#263238" />
      <path opacity="0.2" d="M149.556 458.933H135.828V480.11H149.556V458.933Z" fill="black" />
      <path
        d="M198.42 453.685V459.773C198.42 460.182 198.359 460.588 198.242 460.966C198.125 461.344 197.953 461.688 197.737 461.976C197.521 462.265 197.264 462.494 196.982 462.649C196.699 462.804 196.397 462.883 196.092 462.881H89.2916C88.987 462.883 88.6851 462.804 88.4034 462.649C88.1217 462.493 87.8658 462.265 87.6504 461.975C87.435 461.686 87.2645 461.343 87.1487 460.965C87.0328 460.587 86.974 460.182 86.9756 459.773V453.685C86.9756 452.861 87.2196 452.07 87.6539 451.488C88.0883 450.905 88.6774 450.577 89.2916 450.577H196.092C196.707 450.577 197.297 450.904 197.734 451.487C198.17 452.069 198.416 452.86 198.42 453.685Z"
        fill="#263238"
      />
      <path
        d="M149.28 456.827H86.9642C86.9642 456.827 67.2002 412.636 67.2002 339.618V312.804C67.2034 307.51 68.7725 302.435 71.5627 298.693C74.3529 294.952 78.1359 292.85 82.0802 292.85H108.48C112.344 292.847 116.056 294.863 118.831 298.47C121.607 302.078 123.227 306.993 123.348 312.175C124.104 349.86 128.232 431.334 149.28 456.827Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M135.6 456.827H89.7722C88.9057 456.833 88.055 456.516 87.3139 455.914C86.5728 455.312 85.9701 454.446 85.5722 453.413C81.0602 441.753 67.2002 400.799 67.2002 339.618V312.804C67.2002 307.511 68.7666 302.436 71.5549 298.694C74.3432 294.952 78.125 292.85 82.0682 292.85H104.868C108.732 292.847 112.444 294.863 115.219 298.47C117.995 302.078 119.615 306.993 119.736 312.175C120.42 345.786 123.78 414.408 139.5 446.826C139.975 447.787 140.249 448.902 140.294 450.054C140.338 451.205 140.151 452.352 139.753 453.374C139.354 454.396 138.759 455.255 138.028 455.863C137.298 456.47 136.459 456.803 135.6 456.827Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M198.42 453.685V459.773C198.42 460.597 198.176 461.388 197.742 461.971C197.307 462.554 196.718 462.881 196.104 462.881H148.236C147.825 462.883 147.418 462.776 147.038 462.567C146.658 462.358 146.313 462.049 146.022 461.66C145.731 461.271 145.5 460.809 145.343 460.3C145.185 459.791 145.104 459.245 145.104 458.694V454.78C145.104 453.666 145.434 452.597 146.021 451.808C146.609 451.02 147.405 450.577 148.236 450.577H196.092C196.707 450.577 197.298 450.904 197.734 451.487C198.17 452.069 198.417 452.86 198.42 453.685Z"
        fill="black"
      />
      <path
        d="M86.9399 290.479L90.8999 283.522C90.8999 283.522 107.808 284.552 108.216 292.846C108.624 301.14 86.9399 290.479 86.9399 290.479Z"
        fill="url(#paint76_linear_1097_80129)"
      />
      <path
        d="M75.8281 311.301C75.8281 311.301 76.8601 348.18 75.8281 443.359C75.8281 446.58 66.8761 446.451 66.2281 443.359C64.0801 432.52 66.2281 345.974 66.2281 345.974"
        fill="url(#paint77_linear_1097_80129)"
      />
      <path d="M66.4321 443.839L75.5401 444.258V450.361L66.4321 449.733V443.839Z" fill="url(#paint78_linear_1097_80129)" />
      <path
        opacity="0.2"
        d="M75.8281 311.301C75.8281 311.301 76.8601 348.18 75.8281 443.359C75.8281 446.58 66.8761 446.451 66.2281 443.359C64.0801 432.52 66.2281 345.974 66.2281 345.974"
        fill="white"
      />
      <path
        d="M61.9323 423.909C61.4403 406.355 59.6523 325.671 67.1643 298.937C67.1643 298.937 74.2323 286.891 98.1483 286.891C122.064 286.891 129.264 309.679 129.264 309.679C129.264 309.679 130.296 346.542 129.264 441.737C129.264 444.958 120.312 444.845 119.664 441.737C117.516 430.915 119.664 344.352 119.664 344.352L114.864 423.104C114.847 423.368 114.789 423.624 114.695 423.858C114.601 424.091 114.473 424.296 114.318 424.46C114.163 424.625 113.985 424.745 113.794 424.813C113.603 424.882 113.404 424.898 113.208 424.859C105.66 423.249 72.4083 416.984 63.6123 425.036C63.4529 425.174 63.2696 425.254 63.0804 425.267C62.8912 425.281 62.7026 425.229 62.5331 425.115C62.3636 425.001 62.2191 424.83 62.1138 424.619C62.0084 424.407 61.9459 424.163 61.9323 423.909Z"
        fill="url(#paint79_linear_1097_80129)"
      />
      <path d="M119.868 442.216L128.976 442.635V448.738L119.868 448.11V442.216Z" fill="url(#paint80_linear_1097_80129)" />
      <path
        d="M113.7 424.932L112.98 431.374C112.98 431.374 75.8641 420.52 63.8401 432.083L63.1201 424.949C63.1201 424.949 72.4081 413.45 113.7 424.932Z"
        fill="url(#paint81_linear_1097_80129)"
      />
      <path
        opacity="0.4"
        d="M61.9323 423.909C61.4403 406.355 59.6523 325.671 67.1643 298.937C67.1643 298.937 74.2323 286.891 98.1483 286.891C122.064 286.891 129.264 309.679 129.264 309.679C129.264 309.679 130.296 346.542 129.264 441.737C129.264 444.958 120.312 444.845 119.664 441.737C117.516 430.915 119.664 344.352 119.664 344.352L114.864 423.104C114.847 423.368 114.789 423.624 114.695 423.858C114.601 424.091 114.473 424.296 114.318 424.46C114.163 424.625 113.985 424.745 113.794 424.813C113.603 424.882 113.404 424.898 113.208 424.859C105.66 423.249 72.4083 416.984 63.6123 425.036C63.4529 425.174 63.2696 425.254 63.0804 425.267C62.8912 425.281 62.7026 425.229 62.5331 425.115C62.3636 425.001 62.2191 424.83 62.1138 424.619C62.0084 424.407 61.9459 424.163 61.9323 423.909Z"
        fill="white"
      />
      <path opacity="0.2" d="M119.7 344.351C119.7 344.351 120.228 319.357 129.3 309.678C129.264 309.678 117.96 316.2 119.7 344.351Z" fill="black" />
      <path
        opacity="0.2"
        d="M92.4839 289.768C92.4839 289.768 81.5639 297.82 79.8239 407.541C79.8239 407.541 75.0719 296.854 92.4839 289.768Z"
        fill="black"
      />
      <defs>
        <linearGradient id="paint0_linear_1097_80129" x1="389.409" y1="419.612" x2="389.409" y2="542.712" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_1097_80129" x1="403.292" y1="426.403" x2="403.292" y2="549.721" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_1097_80129" x1="378.744" y1="73.8467" x2="378.744" y2="383.024" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_1097_80129" x1="286.806" y1="146.379" x2="286.806" y2="229.238" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint4_linear_1097_80129" x1="300.228" y1="209.625" x2="300.228" y2="229.24" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint5_linear_1097_80129" x1="286.812" y1="158.333" x2="286.812" y2="160.7" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint6_linear_1097_80129" x1="286.812" y1="167.315" x2="286.812" y2="169.682" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint7_linear_1097_80129" x1="286.812" y1="176.321" x2="286.812" y2="178.704" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint8_linear_1097_80129" x1="286.812" y1="185.321" x2="286.812" y2="187.688" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint9_linear_1097_80129" x1="283.338" y1="194.326" x2="283.338" y2="196.71" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint10_linear_1097_80129" x1="348.606" y1="146.379" x2="348.606" y2="229.238" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint11_linear_1097_80129" x1="362.028" y1="209.625" x2="362.028" y2="229.24" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint12_linear_1097_80129" x1="348.6" y1="158.333" x2="348.6" y2="160.7" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint13_linear_1097_80129" x1="348.6" y1="167.315" x2="348.6" y2="169.682" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint14_linear_1097_80129" x1="348.6" y1="176.321" x2="348.6" y2="178.704" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint15_linear_1097_80129" x1="348.6" y1="185.321" x2="348.6" y2="187.688" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint16_linear_1097_80129" x1="345.126" y1="194.326" x2="345.126" y2="196.71" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint17_linear_1097_80129" x1="410.406" y1="146.379" x2="410.406" y2="229.238" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint18_linear_1097_80129" x1="423.828" y1="209.625" x2="423.828" y2="229.24" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint19_linear_1097_80129" x1="410.4" y1="158.333" x2="410.4" y2="160.7" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint20_linear_1097_80129" x1="410.4" y1="167.315" x2="410.4" y2="169.682" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint21_linear_1097_80129" x1="410.4" y1="176.321" x2="410.4" y2="178.704" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint22_linear_1097_80129" x1="410.4" y1="185.321" x2="410.4" y2="187.688" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint23_linear_1097_80129" x1="406.926" y1="194.326" x2="406.926" y2="196.71" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint24_linear_1097_80129" x1="472.206" y1="146.379" x2="472.206" y2="229.238" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint25_linear_1097_80129" x1="485.622" y1="209.625" x2="485.622" y2="229.24" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint26_linear_1097_80129" x1="472.2" y1="158.333" x2="472.2" y2="160.7" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint27_linear_1097_80129" x1="472.2" y1="167.315" x2="472.2" y2="169.682" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint28_linear_1097_80129" x1="472.2" y1="176.321" x2="472.2" y2="178.704" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint29_linear_1097_80129" x1="472.2" y1="185.321" x2="472.2" y2="187.688" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint30_linear_1097_80129" x1="468.726" y1="194.326" x2="468.726" y2="196.71" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint31_linear_1097_80129" x1="286.806" y1="255.89" x2="286.806" y2="338.749" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint32_linear_1097_80129" x1="300.228" y1="319.136" x2="300.228" y2="338.751" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint33_linear_1097_80129" x1="286.812" y1="267.844" x2="286.812" y2="270.211" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint34_linear_1097_80129" x1="286.812" y1="276.832" x2="286.812" y2="279.199" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint35_linear_1097_80129" x1="286.812" y1="285.832" x2="286.812" y2="288.215" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint36_linear_1097_80129" x1="286.812" y1="294.837" x2="286.812" y2="297.221" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint37_linear_1097_80129" x1="283.338" y1="303.837" x2="283.338" y2="306.205" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint38_linear_1097_80129" x1="348.606" y1="255.89" x2="348.606" y2="338.749" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint39_linear_1097_80129" x1="362.028" y1="319.136" x2="362.028" y2="338.751" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint40_linear_1097_80129" x1="348.6" y1="267.844" x2="348.6" y2="270.211" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint41_linear_1097_80129" x1="348.6" y1="276.832" x2="348.6" y2="279.199" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint42_linear_1097_80129" x1="348.6" y1="285.832" x2="348.6" y2="288.215" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint43_linear_1097_80129" x1="348.6" y1="294.837" x2="348.6" y2="297.221" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint44_linear_1097_80129" x1="345.126" y1="303.837" x2="345.126" y2="306.205" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint45_linear_1097_80129" x1="410.406" y1="255.89" x2="410.406" y2="338.749" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint46_linear_1097_80129" x1="423.828" y1="319.136" x2="423.828" y2="338.751" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint47_linear_1097_80129" x1="410.4" y1="267.844" x2="410.4" y2="270.211" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint48_linear_1097_80129" x1="410.4" y1="276.832" x2="410.4" y2="279.199" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint49_linear_1097_80129" x1="410.4" y1="285.832" x2="410.4" y2="288.215" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint50_linear_1097_80129" x1="410.4" y1="294.837" x2="410.4" y2="297.221" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint51_linear_1097_80129" x1="406.926" y1="303.837" x2="406.926" y2="306.205" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint52_linear_1097_80129" x1="472.206" y1="255.89" x2="472.206" y2="338.749" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint53_linear_1097_80129" x1="485.622" y1="319.136" x2="485.622" y2="338.751" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint54_linear_1097_80129" x1="472.2" y1="267.844" x2="472.2" y2="270.211" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint55_linear_1097_80129" x1="472.2" y1="276.832" x2="472.2" y2="279.199" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint56_linear_1097_80129" x1="472.2" y1="285.832" x2="472.2" y2="288.215" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint57_linear_1097_80129" x1="472.2" y1="294.837" x2="472.2" y2="297.221" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint58_linear_1097_80129" x1="468.726" y1="303.837" x2="468.726" y2="306.205" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint59_linear_1097_80129" x1="246.141" y1="339.73" x2="246.141" y2="371.424" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint60_linear_1097_80129" x1="250.592" y1="286.855" x2="250.592" y2="348.378" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint61_linear_1097_80129" x1="249.426" y1="290.482" x2="249.426" y2="344.771" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint62_linear_1097_80129" x1="211.032" y1="363.513" x2="211.032" y2="371.421" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint63_linear_1097_80129" x1="245.082" y1="385.872" x2="245.082" y2="375.791" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint64_linear_1097_80129" x1="245.082" y1="371.435" x2="245.082" y2="377.104" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint65_linear_1097_80129" x1="267.674" y1="312.836" x2="267.674" y2="318.469" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint66_linear_1097_80129" x1="267.126" y1="315.25" x2="267.126" y2="316.055" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint67_linear_1097_80129" x1="176.46" y1="217.734" x2="176.46" y2="169.742" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint68_linear_1097_80129" x1="176.46" y1="215.929" x2="176.46" y2="232.066" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint69_linear_1097_80129" x1="156.229" y1="409.486" x2="156.229" y2="554.46" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint70_linear_1097_80129" x1="152.736" y1="543.161" x2="152.736" y2="555.529" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint71_linear_1097_80129" x1="156.582" y1="564.864" x2="156.582" y2="570.685" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint72_linear_1097_80129" x1="170.31" y1="380.23" x2="170.31" y2="554.499" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint73_linear_1097_80129" x1="204.012" y1="543.161" x2="204.012" y2="555.529" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint74_linear_1097_80129" x1="207.857" y1="564.864" x2="207.857" y2="570.685" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint75_linear_1097_80129" x1="142.668" y1="437.271" x2="142.668" y2="456.822" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint76_linear_1097_80129" x1="97.5808" y1="283.522" x2="97.5808" y2="296.005" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint77_linear_1097_80129" x1="70.7801" y1="311.301" x2="70.7801" y2="445.726" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint78_linear_1097_80129" x1="70.9861" y1="443.839" x2="70.9861" y2="450.361" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint79_linear_1097_80129" x1="95.5568" y1="286.891" x2="95.5568" y2="444.111" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint80_linear_1097_80129" x1="124.422" y1="442.216" x2="124.422" y2="448.738" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint81_linear_1097_80129" x1="88.4101" y1="419.833" x2="88.4101" y2="432.083" gradientUnits="userSpaceOnUse">
          <stop stop-color="#407BFF" />
          <stop offset="1" stop-color="#407BFF" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
