import React from 'react';
import classes from './InputFeild.module.scss';

export default function DropDownFeild(props) {
  return (
    <div>
      <select
        disable={props.disable}
        value={props.loading ? '....' : `${props.default}`}
        autocomplete="on"
        onChange={(e) => props.onChange(e)}
        className={`px-0  ${props.filter ? classes['select-feild-filter'] : classes['select-feild']} ${classes.padding} ${
          props.error && classes.error
        }`}
        name={props.name}
        id={props.id || props.name}
      >
        {props.district
          ? props.options &&
            props.options.map((item, index) => (
              <option key={index} value={item.district_en || 'select District'}>
                {item.district_en || 'select District'}
              </option>
            ))
          : props.options.map((item, index) => (
              <option
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title={index === 0 ? item : props.city ? item.city_en : item}
                key={index}
                value={index === 0 ? item : props.city ? item.city_en : item}
              >
                &nbsp;{index === 0 ? item : props.city ? item.city_en : item}
              </option>
            ))}
        {props.pagination &&
          (!props.loading && props.pagination ? (
            <option
              style={{
                color: 'unset',
                textDecoration: 'unset',
                background: 'unset',
                textAlign: 'center',
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              value="loadMore"
            >
              <div>{props.loading ? '....' : 'load More Products'}</div>
            </option>
          ) : (
            <option value="null">....</option>
          ))}
      </select>
    </div>
  );
}
