import React from 'react';

export default function UploadImageSVG() {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 15C15.6569 15 17 13.6569 17 12C17 10.3431 15.6569 9 14 9C12.3431 9 11 10.3431 11 12C11 13.6569 12.3431 15 14 15Z"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 3H14C6.5 3 3.5 6 3.5 13.5V22.5C3.5 30 6.5 33 14 33H23C30.5 33 33.5 30 33.5 22.5V15"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.125 7.5H32.375" stroke="#949494" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M28.25 11.625V3.375" stroke="#949494" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M4.50488 28.4248L11.8999 23.4598C13.0849 22.6648 14.7949 22.7548 15.8599 23.6698L16.3549 24.1048C17.5249 25.1098 19.4149 25.1098 20.5849 24.1048L26.8249 18.7498C27.9949 17.7448 29.8849 17.7448 31.0549 18.7498L33.4999 20.8498"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
