import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  user: {},
};

const authSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
