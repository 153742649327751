import React, { useState } from 'react';
import classes from './Product.module.scss';
import CompanyNav from '../../components/Reusable/Common/CompanyNav/CompanyNav';
import PageTitle from '../../components/Reusable/Common/PageTitle/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import ProductActiveL from '../../components/Reusable/SVG/ProductActiveL';
import Plus from '../../components/Reusable/SVG/Plus';
import Edit from '../../components/Reusable/SVG/Edit';
import Img from '../../components/Reusable/Common/Img';
import SelectOption from '../../components/Reusable/Common/SelectOption/SelectOption';
import Section from '../../components/Reusable/Common/Section/Section';
import { productService } from '../../services/ProductServices';
import { useEffect } from 'react';
import { Skeleton } from '@mui/material';

export default function ProductDetails() {
  const history = useNavigate();
  const { id } = useParams();
  const [option, setOption] = useState('Description');
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  let getProduct = async () => {
    let response = await productService.getSingleProduct(id);
    //console.log(response);
    setProduct(response.data.product);
    setLoading(false);
  };
  useEffect(() => {
    getProduct();
  }, []);
  return (
    <div className={`w-100 ${classes['product-page']}`}>
      <CompanyNav />
      <section className="custom-container">
        <div className={`${classes.title} `}>
          <PageTitle
            onClick={() => {
              history(`/account/product/${product._id}/edit`);
              //   setShowAddForm(true);
            }}
            text={'Product Details'}
            icon={<ProductActiveL />}
            buttonText={`Edit Product`}
            buttonType="normal"
            buttonIcon={<Edit color="#134074" />}
          />
        </div>
        {loading ? (
          <div className={classes['main-section']}>
            <div className="row px-0 mx-0">
              <div className="col-8 px-0 mx-0">
                <div className={`row mx-0 px-0 ${classes.info}`}>
                  <h1 className="w-auto d-inline px-0">
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                  </h1>
                  <h1 className="w-auto d-inline px-0 ms-auto">
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                  </h1>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>

                  <div className={`w-auto d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`w-auto d-inline px-0 ${classes.info}`}>
                    <p>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 p-5 mx-0">
                <div className={`${classes.info}`}>
                  <Skeleton variant="rounded" sx={{ height: '223px', width: '284px' }} />
                </div>
              </div>
            </div>
            <div className="br"></div>
            <div className="row mx-0 px-0">
              <div className={`row px-0 mx-0   ${classes.options}`}>
                <div className="w-auto d-inline">
                  <Skeleton variant="text" sx={{ fontSize: '3rem', width: '200px' }} />
                </div>
                <div className="w-auto d-inline">
                  <Skeleton variant="text" sx={{ fontSize: '3rem', width: '200px' }} />
                </div>
              </div>
              {option === 'Description' && (
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0">
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                  </div>

                  <div className="col-lg-6 px-0  ms-auto">
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '250px' }} />
                  </div>
                  {/* <div className="col-lg-6 px-0"></div> */}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={`${classes['main-section']} mb-5`}>
            <div className="row px-0 mx-0">
              <div className="col-xl-8 order-2 order-xl-1 px-0 mx-0">
                <div className="row mx-0 px-0">
                  <div className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p>
                      Product Code : <span>{product.uuid}</span>
                    </p>
                  </div>
                  <div className={`col-lg-6 d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      Model : <span>{product.model}</span>
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p>
                      Product Name : <span>{product.name_en}</span>
                    </p>
                  </div>
                  <div className={`col-lg-6 d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      Contry of Origin : <span>{product.country_en}</span>
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p>
                      Product BarCode : <span>{product.barcode}</span>
                    </p>
                  </div>
                  <div className={`col-lg-6 d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      Category : <span>{product.category.name_en}</span>
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div dir="ltr" className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p dir="ltr">
                      اسم المنتج : <span dir="ltr">{product.name_ar}</span>
                    </p>
                  </div>
                  <div className={`col-lg-6 d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      Sub-Category : <span>{product.subcategory.name_en}</span>
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p>
                      Market Price: <span>{product.price}</span>
                    </p>
                  </div>
                  <div className={`col-lg-6 d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      <span>{product.country_ar}</span> : بلد المنشأ
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p>
                      Factory Name: <span>{product.factory_en}</span>
                    </p>
                  </div>
                  <div className={`col-lg-6 d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      اسم المصنع : <span>{product.factory_ar}</span>
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p>
                      Batch Number: <span>{product.patchNumber}</span>
                    </p>
                  </div>
                  <div className={`col-lg-6 d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      Has Expiry Date : <span>{product.variants.includes('ExpireDate') ? 'true' : 'false'}</span>
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p>
                      Has Colors: <span> {product.variants.includes('colors') ? 'true' : 'false'}</span>
                    </p>
                  </div>
                  <div className={`col-lg-6 d-inline ms-auto px-0 ${classes.info}`}>
                    <p>
                      Has Sizes : <span>{product.variants.includes('sizes') ? 'true' : 'false'}</span>
                    </p>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className={`col-lg-6 d-inline px-0 ${classes.info}`}>
                    <p>
                      Last Modification Date: <span> {product.createdAt.slice(0, 10)}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 order-1 order-xl-2 p-5 mx-0">
                <div className={`${classes.info}`}>
                  <Img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/product/${product.defaultImg}`} />
                </div>
              </div>
            </div>
            <div className="br"></div>
            <div className="row mx-0 px-0">
              <div className={`row px-0 mx-0   ${classes.options}`}>
                <SelectOption onSelect={(val) => setOption(val)} default={option} options={['Description', 'Media']} />
              </div>
              {option === 'Description' && (
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0">
                    <Section text={product.description_en} />
                  </div>

                  <div className="col-lg-6 px-0  ms-auto">
                    <Section text={product.description_ar} />
                  </div>
                  {/* <div className="col-lg-6 px-0"></div> */}
                </div>
              )}
              {option === 'Media' && (
                <div className="row px-0 mx-0">
                  <div className={`${classes.info}`}>
                    <p>Product Images</p>
                  </div>
                  <div className={`${classes['info-image']}`}>
                    {product.productImgs.map((item, index) => (
                      <div key={index} className="w-auto d-inline">
                        <Img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/product/${item}`} className="d-inline" />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
